import React, { useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, BrowserRouter, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import 'assets/vendors/style';
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import SignIn from './SignIn';
import RTL from 'util/RTL';
import { Creators as AuthActions } from '../store/ducks/Auth';
import { Creators as DropsActions } from '../store/ducks/Drops';
import { Creators as NotificacoesActions } from '../store/ducks/Notificacoes';
import AppLayout from './AppLayout';
import socket from 'util/socket';
import ResourcesModal from '../components/Modals/ResourcesModal';
import { isAuth } from 'util/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Redirect
            to={{
              pathname: '/app',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, isDirectionRTL } = useSelector(
    ({ settings }) => settings
  );
  const { authUser } = useSelector(({ auth }) => auth);
  const { getDropsLoading } = useSelector(({ drops }) => drops);
  const isDarkTheme = darkTheme;

  useEffect(() => {
    const refreshToken = localStorage.getItem('EpromApp:refreshToken');
    if (refreshToken) {
      dispatch(AuthActions.refresh({ refreshToken }));
    }
  }, []);

  useEffect(() => {
    if (authUser) {
      dispatch(NotificacoesActions.getNotificacoes(authUser?.storedId));
      if (socket.sk != null){
        socket.sk.emit('joinNotifications', { sender: authUser?.storedId });
        socket.sk.on('recieveNotifications', () => {
          dispatch(NotificacoesActions.getNotificacoes(authUser?.storedId));
          });
      }
    }
  }, [authUser]);

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case 'indigo': {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case 'cyan': {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case 'amber': {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case 'deep-orange': {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case 'pink': {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case 'blue': {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case 'deep-purple': {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case 'green': {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case 'dark-indigo': {
        applyTheme = createMuiTheme({ ...indigoTheme, direction: 'rtl' });
        break;
      }
      case 'dark-cyan': {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case 'dark-amber': {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case 'dark-deep-orange': {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case 'dark-pink': {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case 'dark-blue': {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case 'dark-deep-purple': {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case 'dark-green': {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createMuiTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }

  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl');
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <RTL>
          <div className="app-main">
            <BrowserRouter>
              <AuthRoute exact path="/" component={SignIn} />
              <RestrictedRoute
                path="/app"
                authUser={authUser}
                component={AppLayout}
              />
            </BrowserRouter>
            <ToastContainer />
            <ResourcesModal open={getDropsLoading} />
          </div>
        </RTL>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
