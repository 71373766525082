import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loading from 'components/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';

const ViewModal = ({ open, setOpen, boxes, objeto, rotina }) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(boxes);
  const { visibleLoading, visibleSuccess } = useSelector(
    ({ tables }) => tables
  );

  useEffect(() => {
    if (visibleSuccess) {
      dispatch(TablesActions.setVisibleHeaderReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [visibleSuccess]);

  function onChange(id) {
    let newData = columns.map((e) => {
      if (e.id === id) {
        return { ...e, check: !e.check };
      } else {
        return e;
      }
    });
    setColumns(newData);
  }

  function onSubmit(itens) {
    let campos = [];
    itens.map((e) => {
      if (e.check) {
        campos.push(e.id);
      }
    });
    dispatch(TablesActions.setVisibleHeader({ rotina_id: rotina, campos }));
  }

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Visualização</DialogTitle>
      {visibleLoading ? (
        <Loading />
      ) : (
        <DialogContent>
          <DialogContentText>
            Selecione as colunas a serem exibidas na tabela.
          </DialogContentText>
          {columns?.map((b) => {
            const isId = b?.campo?.includes('_id') || b?.campo === 'id';
            if (!isId) {
              return (
                <FormControlLabel
                  className="d-flex"
                  control={
                    <Checkbox
                      color="primary"
                      checked={b.check}
                      onChange={() => onChange(b.id)}
                    />
                  }
                  label={b.label}
                />
              );
            }
          })}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onSubmit([])}
          color="primary"
          disabled={visibleLoading}
        >
          Padrão
        </Button>
        <Button
          onClick={() => onSubmit(columns)}
          color="primary"
          disabled={visibleLoading}
        >
          Confirmar
        </Button>
        <Button
          onClick={() => setOpen(!open)}
          color="secondary"
          disabled={visibleLoading}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ViewModal;
