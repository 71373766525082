export const Types = {
  SET_STEP: 'SET_STEP',
};

const INIT_STATE = {
  step: null,
};

export default function Step(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.SET_STEP: {
      return {
        ...state,
        step: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  setStep: (payload) => ({
    type: Types.SET_STEP,
    payload,
  }),
};
