import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const ExportInutilizadasModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [ano, setAno] = useState(moment().format('YYYY'));
  const { postExportInutLoading } = useSelector(({ documento }) => documento);
  const { authUser } = useSelector(({ auth }) => auth);

  const submitForm = () => {
    dispatch(
      DocumentoActions.postExportaInutilizadas({
        cnpj: authUser?.storedcnpj,
        ano,
      })
    );
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Exportar Notas Inutilizadas</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12">
            <TextField
              name="ano"
              label="Ano"
              value={ano}
              onChange={(e) => setAno(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={submitForm}
          disabled={postExportInutLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(ExportInutilizadasModal);
