import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
} from '@material-ui/core';
import { Formik } from 'formik';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Creators as FiscalActions } from '../../../store/ducks/Fiscal';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import api from 'util/api';
import { transformData } from 'util/index';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const tribDrop = [
  { value: 'ISENTO', label: 'ISENTO' },
  { value: 'OUTROS', label: 'OUTROS' },
  { value: 'NENHUM', label: 'NENHUM' },
];

const ManutencaoRegraModal = ({
  open,
  setOpen,
  filterString,
  filtros,
  rotina,
  classes,
}) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const { putTribSuccess, putTribLoading } = useSelector(
    ({ fiscal }) => fiscal
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    if (open !== 'EXCLUIR') {
      loadItems([621, 880, 610]);
    }
  }, [open]);

  useEffect(() => {
    if (putTribSuccess) {
      dispatch(FiscalActions.putTributacaoReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [putTribSuccess]);

  const onSubmit = (values) => {
    const Campos = {
      percentual: values?.percentual
        ? transformData(values?.percentual, 'number')
        : undefined,
      filial_id: values?.filial?.value,
      situacao_tributaria_id: values?.situacao?.value,
      dadosadc_id: values?.dadosadc?.value,
      reducao_pc: values?.reducao
        ? transformData(values?.reducao, 'number')
        : undefined,
      diferido_pc: values?.diferido
        ? transformData(values?.diferido, 'number')
        : undefined,
      icmsint: values?.icms ? transformData(values?.icms, 'number') : undefined,
      simplesnac: values?.simples
        ? transformData(values?.simples, 'number')
        : undefined,
      ntributado: values?.naoTributado?.value,
      recuperavel: values?.recuperavel?.value,
      incipi: values?.ipi?.value,
      incdesc: values?.desconto?.value,
      incfrete: values?.frete?.value,
      incoutros: values?.outros?.value,
    };
    const Filtro = {
      uforg_id:
        filtros.find((f) => f?.expressao === 't.uforg_id')?.conteudoi ||
        undefined,
      ufdest_id:
        filtros.find((f) => f?.expressao === 't.ufdest_id')?.conteudoi ||
        undefined,
      'natureza_operacao_id':
        filtros.find((f) => f?.expressao === 't.natureza_operacao_id')
          ?.conteudoi || undefined,
      'contribuinte_id':
        filtros.find((f) => f?.expressao === 't.contribuinte_id')
          ?.conteudoi || undefined,
      'usofinal':
        filtros.find((f) => f?.expressao === 't.usofinal')
          ?.conteudoi || undefined,
      tributo_id:
        filtros.find((f) => f?.expressao === 't.tributo_id')?.conteudoi ||
        undefined,
      'origem_tributaria_id':
        filtros.find((f) => f?.expressao === 't.origem_tributaria_id')
          ?.conteudoi || undefined,
      cfiscal_id:
        filtros.find((f) => f?.expressao === 't.cfiscal_id')?.conteudoi ||
        undefined,
      filial_id:
        filtros.find((f) => f?.expressao === 't.filial_id')?.conteudoi ||
        undefined,
      produto_id:
        filtros.find((f) => f?.expressao === 't.produto_id')?.conteudoi ||
        undefined,
      entidade_id:
        filtros.find((f) => f?.expressao === 't.entidade_id')?.conteudoi ||
        undefined,
    };
    if (open === 'EXCLUIR') {
      dispatch(FiscalActions.putTributacao({ Filtro }));
    } else {
      dispatch(FiscalActions.putTributacao({ Campos, Filtro }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          percentual: null,
          filial: null,
          situacao: null,
          dadosadc: null,
          reducao: null,
          diferido: null,
          icms: null,
          simples: null,
          naoTributado: null,
          recuperavel: null,
          ipi: null,
          desconto: null,
          frete: null,
          outros: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogTitle>
              {open === 'EXCLUIR' ? 'Excluir' : 'Editar'} Regras
            </DialogTitle>
            {open === 'EXCLUIR' ? (
              <DialogContent>
                <DialogContentText>
                  Excluir os registros filtrados por:
                </DialogContentText>
                <DialogContentText>{filterString}</DialogContentText>
              </DialogContent>
            ) : (
              <DialogContent>
                {localLoading ? (
                  <Loading />
                ) : (
                  <Grid container>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <DialogContentText>
                        Informe apenas os dados para alteração os registros filtrados por:
                      </DialogContentText>
                      <DialogContentText>{filterString}</DialogContentText>
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="percentual"
                        label="Percentual"
                        value={values.percentual}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="9" md="9" lg="9" className={classes.grid}>
                      <SelectAuto
                        label="Filial"
                        items={items[0]}
                        value={values.filial}
                        onChange={(v) => setFieldValue('filial', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Situação Tributária"
                        items={items[1]}
                        value={values.situacao}
                        onChange={(v) => setFieldValue('situacao', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Dados Adicionais"
                        items={items[2]}
                        value={values.dadosadc}
                        onChange={(v) => setFieldValue('dadosadc', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="reducao"
                        label="% Redução"
                        value={values.reducao}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="diferido"
                        label="% Diferido"
                        value={values.diferido}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="icms"
                        label="% ICMS Interno"
                        value={values.icms}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="simples"
                        label="% Simples Nacional"
                        value={values.simples}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Não Tributado"
                        items={tribDrop}
                        value={values.naoTributado}
                        onChange={(v) => setFieldValue('naoTributado', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Recuperável"
                        items={yn}
                        value={values.recuperavel}
                        onChange={(v) => setFieldValue('recuperavel', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Índice IPI no Valor Contábil"
                        items={yn}
                        value={values.ipi}
                        onChange={(v) => setFieldValue('ipi', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Índice Desconto no Valor Contábil"
                        items={yn}
                        value={values.desconto}
                        onChange={(v) => setFieldValue('desconto', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Índice Frete no Valor Contábil"
                        items={yn}
                        value={values.frete}
                        onChange={(v) => setFieldValue('frete', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Índice Outros no Valor Contábil"
                        items={yn}
                        value={values.outros}
                        onChange={(v) => setFieldValue('outros', v)}
                      />
                    </Grid>
                  </Grid>
                )}
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={() => onSubmit(values)}
                color="primary"
                disabled={putTribLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(null)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(ManutencaoRegraModal);
