import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as DropsActions,
  Types as DropsTypes,
} from 'store/ducks/Drops';
import api from '../../util/api';

function* getDrops({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/getdrops`, payload);
    if (status === 200 || status === 201) {
      yield put(DropsActions.getDropsSuccess(data?.drops || []));
    } else {
      yield put(DropsActions.getDropsReset());
    }
  } catch (error) {
    yield put(DropsActions.getDropsReset());
  }
}

function* getDropsWatcher() {
  yield takeLatest(DropsTypes.GET_DROPS, getDrops);
}

export default function* rootSaga() {
  yield all([fork(getDropsWatcher)]);
}
