import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { setDecimal, transformData, useQuery, findOnArray } from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  button: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
  },
});

const Manutenção = ({ match, classes }) => {
  const query = useQuery(useLocation);
  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [tributoDrop, setTributoDrop] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [openFiscal, setOpenFiscal] = useState(false);
  const [fill, setFill] = useState(false);
  const [selected, setSelected] = useState(false);
  const id = query.get('id');
  const docId = query.get('doc');
  const itemId = query.get('documento_item_id');
  const descricao = query.get('descricao');
  const {
    saveLoading,
    saveSuccess,
    data,
    editLoading,
    editSuccess,
  } = useSelector(({ tables }) => tables);
  const { infoTributo } = useSelector(({ documento }) => documento);

  const ntributado = [
    { value: 'ISENTO', label: 'ISENTO' },
    { value: 'OUTROS', label: 'OUTROS' },
    { value: 'NENHUM', label: 'NENHUM' },
  ];

  async function loadItems(ids) {
    const promises = ids.map(async (id) => {
      if (id !== null) {
        const { data } = await api.post(
          `/csql/${id}`,
          id === 619 && {
            Parametros: [
              {
                nome: ':natureza_operacao_id',
                valor: infoTributo[0]?.natureza_operacao_id,
              },
              {
                nome: ':uforg_id',
                valor: infoTributo[0]?.uforg_id,
              },
              {
                nome: ':ufdest_id',
                valor: infoTributo[0]?.ufdest_id,
              },
              {
                nome: ':cfiscal_id',
                valor: infoTributo[0]?.cfiscal_id,
              },
            ],
          }
        );
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setItems(results);
    setLocalLoading(false);
  }

  useEffect(() => {
    dispatch(DocumentoActions.getInfoTributoReset());
    setLocalLoading(true);
    if (itemId)
      dispatch(
        DocumentoActions.getInfoTributo({
          documento_item_id: Number(itemId),
          documento_id: Number(docId),
        })
      );
    if (id) {
      let finder = data.find((d) => d?.id === Number(id));
      setSelected(finder);
    }
  }, []);

  useEffect(() => {
    if (infoTributo) {
      loadItems([611, 614, 619]);
    }
  }, [infoTributo]);

  useEffect(() => {
    if (saveSuccess) {
      dispatch(TablesActions.saveDataReset());
      history.goBack();
    }
    if (editSuccess) {
      dispatch(TablesActions.editDataReset());
      history.goBack();
    }
  }, [saveSuccess, editSuccess]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();

    useEffect(() => {
      if (selected && !fill && !!items.length) {
        if (selected.tributo_id) {
          getSituacao(selected.tributo_id);
        }
        setFill(true);
        setValues({
          ...values,
          tributacao: findOnArray(selected.tributacao_id, items[2]),
          tributo: findOnArray(selected.tributo_id, items[1]),
          basecalculo: setDecimal(selected?.basecalculo, 2),
          percentual: setDecimal(selected?.percentual, 2),
          valor: setDecimal(selected?.valor, 2),
          origem_tributaria: findOnArray(
            infoTributo
              ? infoTributo[0]?.origem_tributaria_id
              : selected.origem_tributaria_id,
            items[0]
          ),
          desoneracao_mot: setDecimal(selected.desoneracao_mot, 0),
          desoneracao_vlr: setDecimal(selected?.desoneracao_vlr, 2),
          reducao_pc: setDecimal(selected?.reducao_pc, 2),
          reducao_vl: setDecimal(selected?.reducao_vl, 2),
          ntributado: findOnArray(selected?.ntributado, ntributado),
        });
      }
    }, [selected, items]);

    useEffect(() => {
      if (infoTributo && !!infoTributo.length && !selected) {
        setValues({
          ...values,
          basecalculo: setDecimal(infoTributo[0]?.vlrcontabil || 0, 2),
        });
      }
    }, [infoTributo]);

    useEffect(() => {
      if (!!tributoDrop.length && selected) {
        setValues({
          ...values,
          situacao_tributaria: findOnArray(
            selected.situacao_tributaria_id,
            tributoDrop
          ),
        });
      }
    }, [tributoDrop, selected]);

    return null;
  };

  async function getSituacao(id) {
    const { data } = await api.post(`/csql/615`, {
      Parametros: [
        {
          nome: ':tributo',
          valor: id,
        },
      ],
    });
    if (data) {
      const { dados } = data;
      setTributoDrop(dados || []);
    }
  }

  function handleSubmit(values) {
    let data = {
      documento_item_id: transformData(itemId, 'number'),
      tributacao_id: transformData(values?.tributacao),
      tributo_id: transformData(values?.tributo),
      basecalculo: transformData(values?.basecalculo, 'number'),
      percentual: transformData(values?.percentual, 'number'),
      valor: transformData(values?.valor, 'number'),
      situacao_tributaria_id: transformData(values?.situacao_tributaria),
      origem_tributaria_id: transformData(values?.origem_tributaria),
      desoneracao_mot: transformData(values?.desoneracao_mot, 'number'),
      desoneracao_vlr: transformData(values?.desoneracao_vlr, 'number'),
      reducao_pc: transformData(values?.reducao_pc, 'number'),
      reducao_vl: transformData(values?.reducao_vl, 'number'),
      ntributado: transformData(values?.ntributado),
    };

    if (id) {
      dispatch(
        TablesActions.editData({
          table: 'documento_item_imposto',
          campos: data,
          id,
        })
      );
    } else {
      dispatch(
        TablesActions.saveData({
          table: 'documento_item_imposto',
          campos: data,
        })
      );
    }
  }

  const renderTitle = () => {
    let title = '';
    if (selected.cfiscal) title = title + `N.C.M.: ${selected.cfiscal}`;
    if (selected.cfiscal && (selected.cfo || selected?.vlrcontabil))
      title = title + '     -     ';
    if (selected.cfo) title = title + `CFO: ${selected.cfo}`;
    if (selected.cfo && selected.vlrcontabil) title = title + '     -     ';
    if (selected.vlrcontabil)
      title = title + `VALOR CONTÁBIL: ${setDecimal(selected?.vlrcontabil, 2)}`;
    return title;
  };

  if (localLoading) {
    return <Loading />;
  } else {
    return (
      <div className="app-wrapper">
        <HeaderPage title={descricao} size={18} history={history} />
        {selected && <HeaderPage title={renderTitle()} size={14} />}
        <Card className="shadow">
          <CardContent>
            <Formik
              initialValues={{
                tributacao: '',
                tributo: '',
                basecalculo: '',
                percentual: '',
                valor: '',
                situacao_tributaria: '',
                origem_tributaria: '',
                desoneracao_mot: '',
                desoneracao_vlr: '',
                reducao_pc: '',
                reducao_vl: '',
                ntributado: { value: 'NENHUM', label: 'NENHUM' },
              }}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, handleChange, submitForm, setFieldValue }) => (
                <>
                  <SetFields />
                  <Grid container>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Regra para tributação"
                        items={items[2]}
                        value={values.tributacao}
                        onChange={(r) => setFieldValue('tributacao', r)}
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Tributo"
                        items={items[1]}
                        value={values.tributo}
                        onChange={(t) => {
                          setFieldValue('tributo', t);
                          getSituacao(t?.value);
                        }}
                      />
                    </Grid>
                    <Grid xs="12" sm="5" md="5" lg="5" className={classes.grid}>
                      <TextField
                        name="basecalculo"
                        label="Base de Cálculo"
                        value={values.basecalculo}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                      <TextField
                        name="percentual"
                        label="Pecentual"
                        value={values.percentual}
                        onChange={(e) => {
                          setFieldValue('percentual', e.target.value);
                          const trb =
                            (transformData(values.basecalculo, 'number') *
                              transformData(e.target.value, 'number')) /
                            100;
                          setFieldValue('valor', setDecimal(trb, 2));
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="5" md="5" lg="5" className={classes.grid}>
                      <TextField
                        name="valor"
                        label="Valor"
                        value={values.valor}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Situação Tributária"
                        items={tributoDrop}
                        value={values.situacao_tributaria}
                        onChange={(s) =>
                          setFieldValue('situacao_tributaria', s)
                        }
                      />
                    </Grid>
                    <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                      <SelectAuto
                        label="Origem Tributária"
                        items={items[0]}
                        value={values.origem_tributaria}
                        onChange={(r) => setFieldValue('origem_tributaria', r)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Não Tributado"
                        value={values.ntributado}
                        items={ntributado}
                        onChange={(r) => setFieldValue('ntributado', r)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="desoneracao_mot"
                        label="Motivo da desoneração"
                        value={values.desoneracao_mot}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="desoneracao_vlr"
                        label="Valor desoneração"
                        value={values.desoneracao_vlr}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="reducao_pc"
                        label="% de Redução"
                        value={values.reducao_pc}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="reducao_vlc"
                        label="Valor da Redução"
                        value={values.reducao_vl}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>

                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <button
                        onClick={() => setOpenFiscal(!openFiscal)}
                        className={classes.button}
                        type="button"
                      ></button>
                    </Grid>
                  </Grid>
                  <div
                    className="d-flex justify-content-end"
                    style={{ flex: 1, marginTop: '1rem', padding: '0 0.5rem' }}
                  >
                    {saveLoading || editLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Button
                        color="primary"
                        onClick={submitForm}
                        variant="contained"
                      >
                        Finalizar
                      </Button>
                    )}
                  </div>
                </>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    );
  }
};

export default withStyles(styles)(Manutenção);
