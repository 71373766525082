const styles = (theme) => ({
  titles: {
    margin: 0,
    fontWeight: 600,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '5px',
    padding: '15px',
  },
  valor: {
    margin: '15px 0 0 0',
    textAlign: 'center',
  },
});

export default styles;
