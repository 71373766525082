import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  Edit,
  Delete,
  ArrowDownward,
  ArrowUpward,
  Check,
  Clear,
} from '@material-ui/icons';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';
import { Creators as MidiaActions } from 'store/ducks/Midia';
import { findOnArray, setDecimal, transformData } from 'util/index';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import Loading from 'components/Loading';
import HaverModal from './HaverModal';
import DinheiroModal from './DinheiroModal';
import CartaoModal from './CartaoModal';
import ChequeModal from './ChequeModal';
import ChequeListaModal from './ChequeListaModal';
import CompensacaoModal from './CompensacaoModal';
import BoletoCrediarioModal from './BoletoCrediarioModal';
import api from 'util/api';
import moment from 'moment';

const summarizer = (movimento = []) => {
  let total = 0;
  movimento?.map((i) => {
    total = total + i?.valor;
  });
  return parseFloat(total.toFixed(2));
};

const FinalizarVenda = ({ match }) => {
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [opModal, setOpModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [movimento, setMovimento] = useState([]);
  const [drop, setDrop] = useState([]);
  const [descontos, setDescontos] = useState({
    totalMovimento: summarizer(movimento) || '',
    vDesc: '',
    pDesc: '',
    totalMovimentoDesc: summarizer(movimento) || '',
  });
  const { deleteSuccess } = useSelector(({ midia }) => midia);
  const { getMovimentoLoading, movimentoFinanceiro } = useSelector(
    ({ lancamento }) => lancamento
  );
  const [pagamentos, setPagamentos] = useState({
    dtlanc: moment().format('DD/MM/YYYY'),
    formaPagto: null,
    valor: '',
    lancamentos: [],
    valorLançado: 0,
    troco: 0,
    haver: 0,
  });

  const isPagamento = state?.state?.operacao === 'PAGAMENTOS';
  const isAtendimento = state?.state?.operacao === 'ATENDIMENTO';
  const isRecebimento = state?.state?.operacao === 'RECEBIMENTOS';
  const isCheque = state?.state?.operacao === 'CHEQUES';

  async function loadItems() {
    const { data } = await api.post(`/csql/848`, {
      Parametros: [
        {
          nome: ':operacao',
          valor: state?.state?.operacao,
        },
        {
          nome: ':entidade_id',
          valor: movimentoFinanceiro[0]?.cadastro_id,
        },
      ],
    });
    if (data) {
      setDrop(data.dados || []);
    }
  }

  useEffect(() => {
    if (state?.state?.financeiro_id?.length && !!state?.state?.operacao) {
      dispatch(LancamentoActions.getMovimento(state?.state));
    } else {
      history?.go(-1);
    }
  }, []);

  useEffect(() => {
    if (movimentoFinanceiro) {
      setMovimento(movimentoFinanceiro);
      loadItems();
    }
  }, [movimentoFinanceiro]);

  useEffect(() => {
    const dif = descontos?.totalMovimentoDesc > 0 ? descontos?.totalMovimentoDesc - pagamentos?.valorLançado : 0;
    setPagamentos((prev) => ({
      ...prev,
      valor: setDecimal(dif > 0 ? dif : 0, 2),
      troco: dif < 0 ? -dif : 0,
    }));
  }, [descontos?.totalMovimentoDesc]);

  useEffect(() => {
    if (movimento?.length) {
      setDescontos({
        totalMovimento: summarizer(movimento) || '',
        vDesc: '',
        pDesc: '',
        totalMovimentoDesc: summarizer(movimento) || '',
      });
    }
  }, [movimento]);

  useEffect(() => {
    if (movimento?.length && drop?.length) {
      setPagamentos((prev) => ({
        ...prev,
        formaPagto: findOnArray(movimento[0]?.forma_pagto_id, drop),
      }));
    }
  }, [movimento, drop]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(MidiaActions.deleteMidiaReset());
    }
  }, [deleteSuccess]);

  useEffect(() => {
    const lancado = pagamentos?.lancamentos?.length
      ? pagamentos?.lancamentos
          ?.map((i) => transformData(i?.valor, 'number'))
          ?.reduce((prev, next) => prev + next)
      : 0;
    const dif = descontos?.totalMovimentoDesc > 0 ? descontos?.totalMovimentoDesc - lancado : 0;
    setPagamentos((prev) => ({
      ...prev,
      formaPagto: null,
      valorLançado: lancado,
      valor: setDecimal(dif > 0 ? dif : 0, 2),
      troco: dif < 0 ? -dif : 0,
      haver: 0,
    }));
  }, [pagamentos?.lancamentos]);

  const Modal = () => {
    const [value, setvalue] = useState(setDecimal(selected?.valor, 2));

    const onSubmit = () => {
      let newValue = [...movimento];

      newValue[selected?.index].valor = transformData(value, 'number');
      setMovimento(newValue);
      setEditModal(false);
    };

    return (
      <Dialog open={editModal} fullWidth onClose={() => setEditModal(false)}>
        <DialogTitle>Editar Valor</DialogTitle>
        <DialogContent>
          <TextField
            label="Valor"
            value={value}
            onChange={(e) => setvalue(e?.target?.value)}
            onBlur={(e) => setvalue(setDecimal(e.target.value, 2))}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onSubmit}>
            Confirmar
          </Button>
          <Button onClick={() => setEditModal(false)} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onSubmitPagto = (values = {}) => {
    if (!!pagamentos?.formaPagto) {
      const newLancamentos = [
        ...pagamentos?.lancamentos,
        {
          lancado: !!Object.keys(values)?.length,
          forma_pagto_id: pagamentos?.formaPagto?.value,
          forma_pagto: pagamentos?.formaPagto?.label,
          valor: pagamentos?.valor,
          ...values,
        },
      ];
      setPagamentos((prev) => ({
        ...prev,
        lancamentos: newLancamentos,
      }));
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const form = {
        operacao: state?.state?.operacao,
        dtlanc: moment(pagamentos?.dtlanc, 'DD/MM/YYYY').isValid()
          ? moment(pagamentos?.dtlanc, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        outros: transformData(descontos?.vDesc || '0', 'number'),
        troco: pagamentos?.troco,
        havern: pagamentos?.haver,
        financeiro: movimento?.map((m) => ({
          financeiro_id: m?.financeiro_id,
          valor: m?.valor,
        })),
        formapagamento: pagamentos?.lancamentos?.map((l) => {
          let parseData = { ...l };
          if (Boolean(l?.valor)) {
            parseData = {
              ...parseData,
              valor: transformData(l?.valor, 'number'),
            };
          }
          if (!!parseData?.cheques && !!parseData?.cheques?.length) {
            parseData = {
              ...parseData,
              cheques: parseData?.cheques?.map((c) => ({
                ...c,
                valor: transformData(c?.valor, 'number'),
              })),
            };
          }
          if (!!parseData?.compensacao && !!parseData?.compensacao?.length) {
            parseData = {
              ...parseData,
              compensacao: parseData?.compensacao?.map((c) => ({
                ...c,
                valor: transformData(c?.valor, 'number'),
              })),
            };
          }
          if (!!parseData?.crediario && !!parseData?.crediario?.length) {
            parseData = {
              ...parseData,
              crediario: parseData?.crediario?.map((c) => ({
                ...c,
                valor: transformData(c?.valor, 'number'),
              })),
            };
          }

          return parseData;
        }),
      };
      const { data } = await api.post(`/financeiro-registramov`, form);

      if (data?.midiaurl && data?.midiaurl !== '') {
        window.open(data?.midiaurl);
        dispatch(MidiaActions.deleteMidia(data?.midiaid));
      }

      if (isAtendimento) {
        if (state?.state?.retornavel) {
          if (movimento[0]?.documento_id) {
            history.push(
              `/app/Comercial/Retornáveis?doc=${movimento[0]?.documento_id}`
            );
          } else {
            history.push(`/app/Comercial/Retornáveis`);
          }
        } else {
          if (state?.state?.backTo === 'Caixa') {
            history.push('/app/Comercial/Atendimento-Caixa');
          } else if (state?.state?.backTo === 'Orçamentos') {
            history.push('/app/Comercial/OrçamentosdeVenda');
          } else if (state?.state?.backTo === 'Pedidos') {
            history.push('/app/Comercial/PedidosdeVenda');
          } else if (state?.state?.backTo === 'Faturamentos') {
            history.push('/app/Comercial/Faturamentos');
          } else {
            history.push('/app/Comercial/Atendimento');
          }
        }
      } else {
        history.goBack();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-wrapper">
      <HeaderPage
        title={'Movimento Financeiro - ' + state?.state?.operacao}
        history={history}
      />
      {getMovimentoLoading ? (
        <Loading />
      ) : (
        <>
          {!isAtendimento && (
            <Card className="shadow mb-4">
              <CardContent>
                <Box sx={{ overflow: 'auto' }} component={Paper}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'table',
                      tableLayout: 'fixed',
                    }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Entidade</TableCell>
                          <TableCell>Documento</TableCell>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell align="center">Ação</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {movimento?.map((item, index) => (
                          <TableRow key={item?.id?.toString()}>
                            <TableCell>{item?.cadastro}</TableCell>
                            <TableCell>{item?.documento_id}</TableCell>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>{item?.forma_pagto}</TableCell>
                            <TableCell>{setDecimal(item?.valor, 2)}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  setSelected({ ...item, index });
                                  setEditModal(true);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
                <div className="text-center">
                  <h3 className="m-0 mt-4">
                    Subtotal do Movimento: R$:{' '}
                    {setDecimal(descontos?.totalMovimento, 2)}
                  </h3>
                </div>
              </CardContent>
            </Card>
          )}
          <Card className="shadow">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    label={
                      isPagamento ? 'Data de Pagamento' : 'Data de Recebimento'
                    }
                    mask="99/99/9999"
                    value={pagamentos.dtlanc}
                    fullWidth
                    onChange={(e) => {
                      const { value } = e?.target;
                      setPagamentos((prev) => ({ ...prev, dtlanc: value }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="% Desconto (-) / Acréscimo (+)"
                    value={descontos?.pDesc}
                    fullWidth
                    onChange={(e) => {
                      const { value } = e?.target;
                      setDescontos((prev) => ({
                        ...prev,
                        pDesc: value,
                      }));
                    }}
                    onBlur={(e) => {
                      const { value } = e?.target;
                      const vDesc =
                        (descontos?.totalMovimento *
                          transformData(value, 'number')) /
                        100;
                      if (Boolean(value)) {
                        setDescontos((prev) => ({
                          ...prev,
                          pDesc: setDecimal(value, 3),
                          vDesc: setDecimal(vDesc, 2),
                          totalMovimentoDesc: prev?.totalMovimento + vDesc,
                        }));
                      } else {
                        setDescontos((prev) => ({
                          ...prev,
                          pDesc: setDecimal(value, 3),
                          vDesc: '',
                          totalMovimentoDesc: prev?.totalMovimento + vDesc,
                        }));
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Valor Desconto (-) / Acréscimo (+)"
                    value={descontos?.vDesc}
                    fullWidth
                    onChange={(e) => {
                      const { value } = e?.target;
                      setDescontos((prev) => ({
                        ...prev,
                        vDesc: value,
                      }));
                    }}
                    onBlur={(e) => {
                      const { value } = e?.target;
                      const pDesc =
                        (transformData(value, 'number') * 100) /
                        descontos?.totalMovimento;
                      if (Boolean(value)) {
                        setDescontos((prev) => ({
                          ...prev,
                          vDesc: setDecimal(value, 2),
                          pDesc: setDecimal(pDesc, 3),
                          totalMovimentoDesc:
                            prev?.totalMovimento +
                            transformData(setDecimal(value, 2), 'number'),
                        }));
                      } else {
                        setDescontos((prev) => ({
                          ...prev,
                          vDesc: setDecimal(value, 2),
                          pDesc: '',
                          totalMovimentoDesc:
                            prev?.totalMovimento +
                            transformData(setDecimal(value, 2), 'number'),
                        }));
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="text-center">
                    <h3 className="m-0 mt-4">
                      Total do Movimento: R$:{' '}
                      {setDecimal(descontos?.totalMovimentoDesc, 2)}
                    </h3>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Card className="shadow mt-4">
                <CardContent>
                  <Grid container spacing={2}>
                    {!!movimento?.length &&
                      movimento.every(
                        (m) => movimento[0]?.cadastro_id === m?.cadastro_id
                      ) && (
                        <Grid item xs={12}>
                          <div className="h-100 d-flex align-items-center justify-content-start">
                            <Button
                              color="primary"
                              onClick={() => setOpModal(1)}
                              variant="outlined"
                            >
                              Haver Disponível: R$:{' '}
                              {setDecimal(movimento[0]?.haverdisp, 2)}
                            </Button>
                            {!!movimento[0]?.ucondicao_pagamento && (
                              <Button
                                color="primary"
                                onClick={() => {}}
                                variant="outlined"
                              >
                                Última Condição:{' '}
                                {movimento[0]?.ucondicao_pagamento}
                              </Button>
                            )}
                            {!!movimento[0]?.uforma_pagto && (
                              <Button
                                color="primary"
                                onClick={() => {}}
                                variant="outlined"
                              >
                                Última Forma: {movimento[0]?.uforma_pagto}
                              </Button>
                            )}
                          </div>
                        </Grid>
                      )}
                    <Grid item xs={12} sm={6}>
                      <SelectAuto
                        label="Forma de Pagamento"
                        items={drop}
                        value={pagamentos?.formaPagto}
                        onChange={(v) =>
                          setPagamentos((prev) => ({ ...prev, formaPagto: v }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        label="Valor"
                        value={pagamentos?.valor}
                        fullWidth
                        onChange={(e) => {
                          const { value } = e?.target;
                          setPagamentos((prev) => ({
                            ...prev,
                            valor: value,
                          }));
                        }}
                        onBlur={(e) => {
                          const { value } = e?.target;
                          setPagamentos((prev) => ({
                            ...prev,
                            valor: setDecimal(value, 2),
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            setOpModal(pagamentos?.formaPagto?.modalidade)
                          }
                        >
                          Lançar
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (
                              Boolean(movimento[0]?.cxconta_id) &&
                              pagamentos?.formaPagto?.value === 1
                            ) {
                              onSubmitPagto({
                                cxconta_id: movimento[0]?.cxconta_id,
                              });
                            } else {
                              onSubmitPagto();
                            }
                          }}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Grid>
                    {!!pagamentos?.lancamentos?.length && (
                      <Grid item xs={12}>
                        <Box sx={{ overflow: 'auto' }} component={Paper}>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'table',
                              tableLayout: 'fixed',
                            }}
                          >
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Forma de Pagamento</TableCell>
                                  <TableCell>Valor</TableCell>
                                  <TableCell align="center">Lançado</TableCell>
                                  <TableCell align="center">Ação</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pagamentos?.lancamentos?.map((item, index) => (
                                  <TableRow key={item?.id?.toString()}>
                                    <TableCell>{item?.forma_pagto}</TableCell>
                                    <TableCell>R$ {item?.valor}</TableCell>
                                    <TableCell align="center">
                                      {item?.lancado ? <Check /> : <Clear />}
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton
                                        onClick={() =>
                                          setPagamentos((prev) => ({
                                            ...prev,
                                            lancamentos: prev?.lancamentos?.filter(
                                              (f, i) => i !== index
                                            ),
                                          }))
                                        }
                                      >
                                        <Delete />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className="shadow mt-4">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center">
                        <Grid item xs={6} sm={4}>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            style={{ fontWeight: 'bold' }}
                          >
                            Total Informado:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            style={{ fontWeight: 'bold' }}
                          >
                            R$ {setDecimal(pagamentos?.valorLançado, 2)}
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center">
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle1" component="div">
                            Troco:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                          <div className="d-flex align-items-center">
                            <Typography variant="subtitle1" component="div">
                              R$ {setDecimal(pagamentos?.troco, 2)}
                            </Typography>
                            {!!movimento?.length &&
                              !!pagamentos?.troco &&
                              movimento.every(
                                (m) =>
                                  movimento[0]?.cadastro_id === m?.cadastro_id
                              ) && (
                                <IconButton
                                  onClick={() => {
                                    setPagamentos((prev) => ({
                                      ...prev,
                                      troco: 0,
                                      haver: prev?.troco,
                                    }));
                                  }}
                                  size="small"
                                  className="mx-2"
                                >
                                  <ArrowDownward fontSize="small" />
                                </IconButton>
                              )}
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                    {!!movimento?.length &&
                      movimento.every(
                        (m) => movimento[0]?.cadastro_id === m?.cadastro_id
                      ) && (
                        <Grid item xs={12}>
                          <div className="d-flex align-items-center">
                            <Grid item xs={6} sm={4}>
                              <Typography variant="subtitle1" component="div">
                                Haver à gerar:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                              <div className="d-flex align-items-center">
                                <Typography variant="subtitle1" component="div">
                                  R$ {setDecimal(pagamentos?.haver, 2)}
                                </Typography>
                                {!!movimento?.length &&
                                  !!pagamentos?.haver &&
                                  movimento.every(
                                    (m) =>
                                      movimento[0]?.cadastro_id ===
                                      m?.cadastro_id
                                  ) && (
                                    <IconButton
                                      onClick={() => {
                                        setPagamentos((prev) => ({
                                          ...prev,
                                          haver: 0,
                                          troco: prev?.haver,
                                        }));
                                      }}
                                      size="small"
                                      className="mx-2"
                                    >
                                      <ArrowUpward fontSize="small" />
                                    </IconButton>
                                  )}
                              </div>
                            </Grid>
                          </div>
                        </Grid>
                      )}
                    <div className="d-flex w-100 align-items-center justify-content-center">
                      {!!loading && <CircularProgress size={25} />}
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-2"
                        onClick={onSubmit}
                        disabled={loading}
                      >
                        Finalizar
                      </Button>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Modal />
          <HaverModal
            open={opModal === 1}
            setOpen={setOpModal}
            movimento={movimento}
          />
          <DinheiroModal
            open={opModal === '01-DINHEIRO'}
            setOpen={setOpModal}
            onSubmit={onSubmitPagto}
            movimento={movimento}
          />
          <CartaoModal
            open={
              opModal === '03-CARTAO DE CREDITO' ||
              opModal === '04-CARTAO DE DEBITO' ||
              opModal === '10-VALE ALIMENTAÇÃO' ||
              opModal === '11-VALE REFEIÇÃO' ||
              opModal === '12-VALE PRESENTE' ||
              opModal === '13-VALE COMBUSTIVEL'
            }
            formaPagto={opModal}
            setOpen={setOpModal}
            onSubmit={onSubmitPagto}
          />
          {isPagamento ? (
            <ChequeListaModal
              open={opModal === '02-CHEQUE'}
              setOpen={setOpModal}
              onSubmit={onSubmitPagto}
            />
          ) : (
            <ChequeModal
              open={opModal === '02-CHEQUE'}
              setOpen={setOpModal}
              onSubmit={onSubmitPagto}
              pagamentos={pagamentos}
            />
          )}
          <CompensacaoModal
            open={opModal === '90-SEM PAGAMENTO'}
            setOpen={setOpModal}
            onSubmit={onSubmitPagto}
            isRecebimento={isRecebimento}
            movimento={movimento}
            teste={descontos?.totalMovimentoDesc - pagamentos?.valorLançado}
          />
          <BoletoCrediarioModal
            open={opModal === '15-BOLETO BANCÁRIO' || opModal === '99-OUTROS'}
            setOpen={setOpModal}
            onSubmit={onSubmitPagto}
            formaPagto={opModal}
            isPagamento={isPagamento}
            isAtendimento={isAtendimento}
            isRecebimento={isRecebimento}
            isCheque={isCheque}
            pagamentos={pagamentos}
          />
        </>
      )}
    </div>
  );
};

export default FinalizarVenda;
