import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@material-ui/core/styles';

const TwoSimplePieChart = ({ values }) => {
  const theme = useTheme();
  const data = values.map((v) => ({ name: v?.indicador, value: v?.valor }));

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill={theme.palette.primary.main}
            label
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TwoSimplePieChart;
