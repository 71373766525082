import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDecimal, transformData } from 'util/index';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import moment from 'moment';

const OfxBaixa = ({ open, setOpen, selected, conta, callback }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [pagamento, setPagamento] = useState(null);
  const [loading, setLoading] = useState(false);

  async function loadItems() {
    const { data } = await api.post(`/csql/991`);
    setItems(data?.dados || []);
  }

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        operacao: 'PAGAMENTOS',
        dtlanc: moment(selected?.data).format('YYYY-MM-DD'),
        financeiro: [
          {
            financeiro_id: pagamento?.value,
            valor: ((selected?.valor < 0 ? selected?.valor * -1 : selected?.valor) >transformData(setDecimal(pagamento?.valor, 2),'number')) 
              ? transformData(setDecimal(pagamento?.valor, 2),'number')
              : (selected?.valor < 0 ? selected?.valor * -1 : selected?.valor)
          },
        ],
        formapagamento: [
          {
            lancado: false,
            forma_pagto_id: pagamento?.forma_pagto_id,
            forma_pagto: pagamento?.forma_pagto,
            valor: ((selected?.valor < 0 ? selected?.valor * -1 : selected?.valor) > transformData(setDecimal(pagamento?.valor, 2),'number')) 
            ? transformData(setDecimal(pagamento?.valor, 2),'number')
            : (selected?.valor < 0 ? selected?.valor * -1 : selected?.valor),
            cxconta_id: conta,
            documentocx: selected?.documento,
          },
        ],
      };
      await api.post(`/financeiro-registramov`, data);
      if (callback) {
        callback();
      }
      setOpen(null);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      PaperProps={{ style: { minWidth: '90%' } }}
    >
      <DialogTitle>Baixar Pagamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectAuto
              label="Pagamento"
              items={items || []}
              value={pagamento}
              onChange={(v) => setPagamento(v)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="primary" disabled={loading}>
          Confirmar
        </Button>
        <Button
          onClick={() => setOpen(null)}
          color="secondary"
          disabled={loading}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OfxBaixa;
