import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import {
  Creators as UsuariosActions,
  Types as UsuariosTypes,
} from 'store/ducks/Usuarios';
import api from '../../util/api';

function* postCopiaPerfil({ payload }) {
  const { data, copia } = payload;
  try {
    const { status } = yield call(api.post, `/copia-perfil-${copia}`, data);
    if (status === 200 || status === 201) {
      yield put(UsuariosActions.postCopiaPerfilSuccess());
    } else {
      yield put(UsuariosActions.postCopiaPerfilError());
    }
  } catch (error) {
    yield put(UsuariosActions.postCopiaPerfilError());
  }
}

function* postCopiaPerfilWatcher() {
  yield takeLatest(UsuariosTypes.POST_COPIA_PERFIL, postCopiaPerfil);
}

export default function* rootSaga() {
  yield all([fork(postCopiaPerfilWatcher)]);
}
