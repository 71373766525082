export const Types = {
  GET_HEADER: 'GET_HEADER',
  GET_HEADER_SUCCESS: 'GET_HEADER_SUCCESS',
  GET_HEADER_ERROR: 'GET_HEADER_ERROR',

  SET_VISIBLE_HEADER: 'SET_VISIBLE_HEADER',
  SET_VISIBLE_HEADER_SUCCESS: 'SET_VISIBLE_HEADER_SUCCESS',
  SET_VISIBLE_HEADER_ERROR: 'SET_VISIBLE_HEADER_ERROR',
  SET_VISIBLE_HEADER_RESET: 'SET_VISIBLE_HEADER_RESET',

  GET_DATA_TABLE: 'GET_DATA_TABLE',
  GET_DATA_TABLE_SUCCESS: 'GET_DATA_TABLE_SUCCESS',
  GET_DATA_TABLE_ERROR: 'GET_DATA_TABLE_ERROR',
  GET_DATA_TABLE_RESET: 'GET_DATA_TABLE_RESET',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_SUCCESS: 'DELETE_DATA_SUCCESS',
  DELETE_DATA_ERROR: 'DELETE_DATA_ERROR',
  DELETE_DATA_RESET: 'DELETE_DATA_RESET',

  EXPORT_PDF: 'EXPORT_PDF',
  EXPORT_PDF_SUCCESS: 'EXPORT_PDF_SUCCESS',
  EXPORT_PDF_ERROR: 'EXPORT_PDF_ERROR',
  EXPORT_PDF_RESET: 'EXPORT_PDF_RESET',

  EXPORT_XLS: 'EXPORT_XLS',
  EXPORT_XLS_SUCCESS: 'EXPORT_XLS_SUCCESS',
  EXPORT_XLS_ERROR: 'EXPORT_XLS_ERROR',
  EXPORT_XLS_RESET: 'EXPORT_XLS_RESET',

  SET_FILTROS: 'SET_FILTROS',
  RESET_FILTROS: 'RESET_FILTROS',

  SAVE_DATA: 'SAVE_DATA',
  SAVE_DATA_SUCCESS: 'SAVE_DATA_SUCCESS',
  SAVE_DATA_ERROR: 'SAVE_DATA_ERROR',
  SAVE_DATA_RESET: 'SAVE_DATA_RESET',

  EDIT_DATA: 'EDIT_DATA',
  EDIT_DATA_SUCCESS: 'EDIT_DATA_SUCCESS',
  EDIT_DATA_ERROR: 'EDIT_DATA_ERROR',
  EDIT_DATA_RESET: 'EDIT_DATA_RESET',

  GET_REPORT: 'GET_REPORT',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_RESET: 'GET_REPORT_RESET',

  POST_REPORT: 'POST_REPORT',
  POST_REPORT_SUCCESS: 'POST_REPORT_SUCCESS',
  POST_REPORT_ERROR: 'POST_REPORT_ERROR',
  POST_REPORT_RESET: 'POST_REPORT_RESET',

  GET_TOTAIS: 'GET_TOTAIS',
  GET_TOTAIS_SUCCESS: 'GET_TOTAIS_SUCCESS',
  GET_TOTAIS_ERROR: 'GET_TOTAIS_ERROR',

  GET_RESULTS: 'GET_RESULTS',
  GET_RESULTS_SUCCESS: 'GET_RESULTS_SUCCESS',
  GET_RESULTS_ERROR: 'GET_RESULTS_ERROR',

  POST_BOLETO: 'POST_BOLETO',
  POST_BOLETO_SUCCESS: 'POST_BOLETO_SUCCESS',
  POST_BOLETO_ERROR: 'POST_BOLETO_ERROR',
  POST_BOLETO_RESET: 'POST_BOLETO_RESET',

  POST_CSQL: 'POST_CSQL',
  POST_CSQL_SUCCESS: 'POST_CSQL_SUCCESS',
  POST_CSQL_RESET: 'POST_CSQL_RESET',

  POST_CUSTOM_ROTINA: 'POST_CUSTOM_ROTINA',
  POST_CUSTOM_ROTINA_SUCCESS: 'POST_CUSTOM_ROTINA_SUCCESS',
  POST_CUSTOM_ROTINA_RESET: 'POST_CUSTOM_ROTINA_RESET',
};

const initialSettings = {
  headerLoading: false,
  headerError: false,
  headerData: [],
  paginacao: null,
  filtros: [],
  filtrosChanged: false,
  renderRotina: null,

  visibleLoading: false,
  visibleSuccess: false,
  visibleError: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  exportLoading: false,
  exportData: null,
  exportPdfSuccess: false,
  exportXlsSuccess: false,
  exportError: false,

  dataLoading: false,
  dataError: false,
  data: [],

  saveLoading: false,
  saveSuccess: false,
  saveError: false,

  editLoading: false,
  editSuccess: false,
  editError: false,

  reportLoading: false,
  reportData: [],
  reportFiltros: [],

  postReportLoading: false,
  postReportSuccess: false,
  postReportError: false,
  reportId: null,
  reportUrl: null,
  reportLink: null,

  totaisLoading: false,
  totaisError: false,
  totais: [],

  resultsLoading: false,
  resultsError: false,
  results: null,

  postBoletoLoading: false,
  boleto: null,
  postBoletoError: false,

  postCsqlLoading: false,
  postCsqlSuccess: false,

  postCustomRotinaLoading: false,
  postCustomRotinaSuccess: false,
};

export default function Tables(state = initialSettings, action) {
  switch (action.type) {
    case Types.GET_HEADER:
      return {
        ...state,
        headerLoading: true,
        headerError: false,
        headerData: [],
        filtros: [],
        data: [],
        paginacao: null,
        renderRotina: null,
      };
    case Types.GET_HEADER_SUCCESS:
      return {
        ...state,
        headerLoading: false,
        headerError: false,
        headerData: action.payload.headerData,
        filtros: action.payload.filtros,
        paginacao: action.payload.paginacao,
        renderRotina: action.payload.renderRotina,
      };
    case Types.GET_HEADER_ERROR:
      return {
        ...state,
        headerLoading: false,
        headerError: true,
        headerData: [],
        filtros: [],
        paginacao: null,
        renderRotina: null,
      };

    case Types.SET_VISIBLE_HEADER:
      return {
        ...state,
        visibleLoading: true,
        visibleSuccess: false,
        visibleError: false,
      };
    case Types.SET_VISIBLE_HEADER_SUCCESS:
      return {
        ...state,
        visibleLoading: false,
        visibleSuccess: true,
        visibleError: false,
      };
    case Types.SET_VISIBLE_HEADER_ERROR:
      return {
        ...state,
        visibleLoading: false,
        visibleSuccess: false,
        visibleError: true,
      };
    case Types.SET_VISIBLE_HEADER_RESET:
      return {
        ...state,
        visibleLoading: false,
        visibleSuccess: false,
        visibleError: false,
      };

    case Types.GET_DATA_TABLE:
      return {
        ...state,
        dataLoading: true,
        dataError: false,
        data: [],
      };
    case Types.GET_DATA_TABLE_SUCCESS:
      return {
        ...state,
        dataLoading: false,
        dataError: false,
        data: action.payload,
      };
    case Types.GET_DATA_TABLE_ERROR:
      return {
        ...state,
        dataLoading: false,
        dataError: true,
        data: [],
      };
    case Types.GET_DATA_TABLE_RESET:
      return {
        ...state,
        dataLoading: false,
        dataError: false,
        data: [],
      };

    case Types.DELETE_DATA:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      };
    case Types.DELETE_DATA_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      };
    case Types.DELETE_DATA_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
      };
    case Types.DELETE_DATA_RESET:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
      };

    case Types.EXPORT_PDF:
      return {
        ...state,
        exportLoading: true,
        exportData: null,
        exportPdfSuccess: false,
        exportError: false,
      };
    case Types.EXPORT_PDF_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        exportData: action.payload,
        exportPdfSuccess: true,
        exportError: false,
      };
    case Types.EXPORT_PDF_ERROR:
      return {
        ...state,
        exportLoading: false,
        exportData: null,
        exportPdfSuccess: false,
        exportError: true,
      };
    case Types.EXPORT_PDF_RESET:
      return {
        ...state,
        exportLoading: false,
        exportPdfSuccess: false,
        exportError: false,
      };

    case Types.EXPORT_XLS:
      return {
        ...state,
        exportLoading: true,
        exportData: null,
        exportXlsSuccess: false,
        exportError: false,
      };
    case Types.EXPORT_XLS_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        exportData: action.payload,
        exportXlsSuccess: true,
        exportError: false,
      };
    case Types.EXPORT_XLS_ERROR:
      return {
        ...state,
        exportLoading: false,
        exportData: null,
        exportXlsSuccess: false,
        exportError: true,
      };
    case Types.EXPORT_XLS_RESET:
      return {
        ...state,
        exportLoading: false,
        exportXlsSuccess: false,
        exportError: false,
      };

    case Types.SET_FILTROS:
      return {
        ...state,
        filtros: action.payload,
        filtrosChanged: true,
      };
    case Types.RESET_FILTROS:
      return {
        ...state,
        filtrosChanged: false,
      };

    case Types.SAVE_DATA:
      return {
        ...state,
        saveLoading: true,
        saveSuccess: false,
        saveError: false,
      };
    case Types.SAVE_DATA_SUCCESS:
      return {
        ...state,
        saveLoading: false,
        saveSuccess: true,
        saveError: false,
      };
    case Types.SAVE_DATA_ERROR:
      return {
        ...state,
        saveLoading: false,
        saveSuccess: false,
        saveError: true,
      };
    case Types.SAVE_DATA_RESET:
      return {
        ...state,
        saveLoading: false,
        saveSuccess: false,
        saveError: false,
      };

    case Types.EDIT_DATA:
      return {
        ...state,
        editLoading: true,
        editSuccess: false,
        editError: false,
      };
    case Types.EDIT_DATA_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editSuccess: true,
        editError: false,
      };
    case Types.EDIT_DATA_ERROR:
      return {
        ...state,
        editLoading: false,
        editSuccess: false,
        editError: true,
      };
    case Types.EDIT_DATA_RESET:
      return {
        ...state,
        editLoading: false,
        editSuccess: false,
        editError: false,
      };

    case Types.GET_REPORT:
      return {
        ...state,
        reportLoading: true,
        reportData: [],
        reportFiltros: [],
      };
    case Types.GET_REPORT_SUCCESS:
      return {
        ...state,
        reportLoading: false,
        reportData: action.payload.definicao,
        reportFiltros: action.payload.filtro,
      };
    case Types.GET_REPORT_RESET:
      return {
        ...state,
        reportLoading: false,
        reportData: [],
        reportFiltros: [],
      };

    case Types.POST_REPORT:
      return {
        ...state,
        postReportLoading: true,
        postReportSuccess: false,
        postReportError: false,
        reportId: null,
        reportUrl: null,
        reportLink: null,
      };
    case Types.POST_REPORT_SUCCESS:
      return {
        ...state,
        postReportLoading: false,
        postReportSuccess: true,
        postReportError: false,
        reportId: action.payload.id,
        reportUrl: action.payload.url,
        reportLink: action.payload.link,
      };
    case Types.POST_REPORT_ERROR:
      return {
        ...state,
        postReportLoading: false,
        postReportSuccess: false,
        postReportError: true,
        reportId: null,
        reportUrl: null,
        reportLink: null,
      };
    case Types.POST_REPORT_RESET:
      return {
        ...state,
        postReportLoading: false,
        postReportSuccess: false,
        postReportError: false,
        reportId: null,
        reportUrl: null,
        reportLink: null,
      };

    case Types.GET_TOTAIS:
      return {
        ...state,
        totaisLoading: true,
        totaisError: false,
        totais: [],
      };
    case Types.GET_TOTAIS_SUCCESS:
      return {
        ...state,
        totaisLoading: false,
        totaisError: false,
        totais: action.payload,
      };
    case Types.GET_TOTAIS_ERROR:
      return {
        ...state,
        totaisLoading: false,
        totaisError: true,
        totais: [],
      };

    case Types.GET_RESULTS:
      return {
        ...state,
        resultsLoading: true,
        resultsError: false,
        results: null,
      };
    case Types.GET_RESULTS_SUCCESS:
      return {
        ...state,
        resultsLoading: false,
        resultsError: false,
        results: action.payload,
      };
    case Types.GET_RESULTS_ERROR:
      return {
        ...state,
        resultsLoading: false,
        resultsError: true,
        results: null,
      };

    case Types.POST_BOLETO:
      return {
        ...state,
        postBoletoLoading: true,
        boleto: null,
        postBoletoError: false,
      };
    case Types.POST_BOLETO_SUCCESS:
      return {
        ...state,
        postBoletoLoading: false,
        boleto: action.payload,
        postBoletoError: false,
      };
    case Types.POST_BOLETO_ERROR:
      return {
        ...state,
        postBoletoLoading: false,
        boleto: null,
        postBoletoError: true,
      };
    case Types.POST_BOLETO_RESET:
      return {
        ...state,
        postBoletoLoading: false,
        boleto: null,
        postBoletoError: false,
      };

    case Types.POST_CSQL:
      return {
        ...state,
        postCsqlLoading: true,
        postCsqlSuccess: false,
      };
    case Types.POST_CSQL_SUCCESS:
      return {
        ...state,
        postCsqlLoading: false,
        postCsqlSuccess: true,
      };
    case Types.POST_CSQL_RESET:
      return {
        ...state,
        postCsqlLoading: false,
        postCsqlSuccess: false,
      };

    case Types.POST_CUSTOM_ROTINA:
      return {
        ...state,
        postCustomRotinaLoading: true,
        postCustomRotinaSuccess: false,
      };
    case Types.POST_CUSTOM_ROTINA_SUCCESS:
      return {
        ...state,
        postCustomRotinaLoading: false,
        postCustomRotinaSuccess: true,
      };
    case Types.POST_CUSTOM_ROTINA_RESET:
      return {
        ...state,
        postCustomRotinaLoading: false,
        postCustomRotinaSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getHeader: (payload) => ({
    type: Types.GET_HEADER,
    payload,
  }),
  getHeaderSuccess: (payload) => ({
    type: Types.GET_HEADER_SUCCESS,
    payload,
  }),
  getHeaderError: () => ({
    type: Types.GET_HEADER_ERROR,
  }),

  setVisibleHeader: (payload) => ({
    type: Types.SET_VISIBLE_HEADER,
    payload,
  }),
  setVisibleHeaderSuccess: () => ({
    type: Types.SET_VISIBLE_HEADER_SUCCESS,
  }),
  setVisibleHeaderError: () => ({
    type: Types.SET_VISIBLE_HEADER_ERROR,
  }),
  setVisibleHeaderReset: () => ({
    type: Types.SET_VISIBLE_HEADER_RESET,
  }),

  getDataTable: (payload) => ({
    type: Types.GET_DATA_TABLE,
    payload,
  }),
  getDataTableSuccess: (payload) => ({
    type: Types.GET_DATA_TABLE_SUCCESS,
    payload,
  }),
  getDataTableError: () => ({
    type: Types.GET_DATA_TABLE_ERROR,
  }),
  getDataTableReset: () => ({
    type: Types.GET_DATA_TABLE_RESET,
  }),

  deleteData: (payload) => ({
    type: Types.DELETE_DATA,
    payload,
  }),
  deleteDataSuccess: () => ({
    type: Types.DELETE_DATA_SUCCESS,
  }),
  deleteDataError: () => ({
    type: Types.DELETE_DATA_ERROR,
  }),
  deleteDataReset: () => ({
    type: Types.DELETE_DATA_RESET,
  }),

  exportPdf: (payload) => ({
    type: Types.EXPORT_PDF,
    payload,
  }),
  exportPdfSuccess: (payload) => ({
    type: Types.EXPORT_PDF_SUCCESS,
    payload,
  }),
  exportPdfError: () => ({
    type: Types.EXPORT_PDF_ERROR,
  }),
  exportPdfReset: () => ({
    type: Types.EXPORT_PDF_RESET,
  }),

  exportXls: (payload) => ({
    type: Types.EXPORT_XLS,
    payload,
  }),
  exportXlsSuccess: (payload) => ({
    type: Types.EXPORT_XLS_SUCCESS,
    payload,
  }),
  exportXlsError: () => ({
    type: Types.EXPORT_XLS_ERROR,
  }),
  exportXlsReset: () => ({
    type: Types.EXPORT_XLS_RESET,
  }),

  setFiltros: (payload) => ({
    type: Types.SET_FILTROS,
    payload,
  }),
  resetFiltros: () => ({
    type: Types.RESET_FILTROS,
  }),

  saveData: (payload) => ({
    type: Types.SAVE_DATA,
    payload,
  }),
  saveDataSuccess: () => ({
    type: Types.SAVE_DATA_SUCCESS,
  }),
  saveDataError: () => ({
    type: Types.SAVE_DATA_ERROR,
  }),
  saveDataReset: () => ({
    type: Types.SAVE_DATA_RESET,
  }),

  editData: (payload) => ({
    type: Types.EDIT_DATA,
    payload,
  }),
  editDataSuccess: () => ({
    type: Types.EDIT_DATA_SUCCESS,
  }),
  editDataError: () => ({
    type: Types.EDIT_DATA_ERROR,
  }),
  editDataReset: () => ({
    type: Types.EDIT_DATA_RESET,
  }),

  getReport: (payload) => ({
    type: Types.GET_REPORT,
    payload,
  }),
  getReportSuccess: (payload) => ({
    type: Types.GET_REPORT_SUCCESS,
    payload,
  }),
  getReportReset: () => ({
    type: Types.GET_REPORT_RESET,
  }),

  postReport: (payload) => ({
    type: Types.POST_REPORT,
    payload,
  }),
  postReportSuccess: (payload) => ({
    type: Types.POST_REPORT_SUCCESS,
    payload,
  }),
  postReportError: () => ({
    type: Types.POST_REPORT_ERROR,
  }),
  postReportReset: () => ({
    type: Types.POST_REPORT_RESET,
  }),

  getTotais: (payload) => ({
    type: Types.GET_TOTAIS,
    payload,
  }),
  getTotaisSuccess: (payload) => ({
    type: Types.GET_TOTAIS_SUCCESS,
    payload,
  }),
  getTotaisError: () => ({
    type: Types.GET_TOTAIS_ERROR,
  }),

  getResults: (payload) => ({
    type: Types.GET_RESULTS,
    payload,
  }),
  getResultsSuccess: (payload) => ({
    type: Types.GET_RESULTS_SUCCESS,
    payload,
  }),
  getResultsError: () => ({
    type: Types.GET_RESULTS_ERROR,
  }),

  postBoleto: (payload) => ({
    type: Types.POST_BOLETO,
    payload,
  }),
  postBoletoSuccess: (payload) => ({
    type: Types.POST_BOLETO_SUCCESS,
    payload,
  }),
  postBoletoError: () => ({
    type: Types.POST_BOLETO_ERROR,
  }),
  postBoletoReset: () => ({
    type: Types.POST_BOLETO_RESET,
  }),

  postCsql: (payload) => ({
    type: Types.POST_CSQL,
    payload,
  }),
  postCsqlSuccess: () => ({
    type: Types.POST_CSQL_SUCCESS,
  }),
  postCsqlReset: () => ({
    type: Types.POST_CSQL_RESET,
  }),

  postCustomRotina: (payload) => ({
    type: Types.POST_CUSTOM_ROTINA,
    payload,
  }),
  postCustomRotinaSuccess: () => ({
    type: Types.POST_CUSTOM_ROTINA_SUCCESS,
  }),
  postCustomRotinaReset: () => ({
    type: Types.POST_CUSTOM_ROTINA_RESET,
  }),
};
