import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Button,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import HeaderPage from 'components/HeaderPage';
import ProdutoModal from 'components/Modals/ProdutoModal';
import { setDecimal, useQuery, transformData } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import BarcodeReader from 'react-barcode-reader';
import CustomScrollbars from 'util/CustomScrollbars';
import api from 'util/api';

const styles = (theme) => ({
  cell: {
    padding: '0 16px',
  },
  gridContainer: {
    padding: '0.5rem',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  grPlus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageContainer: {
    height: '200px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  titles: {
    margin: 0,
    fontWeight: 600,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '15px 0',
    padding: '10px',
  },
  valor: {
    margin: 0,
    textAlign: 'center',
  },
  subtitle: {
    margin: 0,
    fontWeight: 600,
    textAlign: 'center',
  },
});

const Vendas = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const query = useQuery(useLocation);
  const param = query.get('id');
  const [produtoModal, setProdutoModal] = useState(false);
  const {
    documento,
    prodDoc,
    postDocItemLoading,
    postDocItemSuccess,
    deleteDocItemLoading,
    deleteDocItemSuccess,
    deleteDocLoading,
    deleteDocSuccess,
  } = useSelector(({ documento }) => documento);

  useEffect(() => {
    if (param) {
      dispatch(DocumentoActions.getDocumento(param));
      dispatch(DocumentoActions.getProdDoc());
    }
  }, []);

  useEffect(() => {
    if (documento) {
    }
  }, [documento]);

  useEffect(() => {
    if (deleteDocSuccess) {
      dispatch(DocumentoActions.deleteDocumentoReset());
      history.goBack();
    }
  }, [deleteDocSuccess]);

  const onAdd = (values) => {
    let data = {
      ...documento?.Documento,
      id: undefined,
      documento_id: documento?.Documento?.id,
      produto_id: values?.produto?.value,
      quantidade: transformData(values?.quantidade, 'number'),
      desconto1_pc: transformData(values?.desconto, 'number'),
      preco: transformData(values?.preco, 'number'),
      infadc: values?.infadc,
    };
    dispatch(DocumentoActions.postDocItem(data));
  };

  const onSubmit = async () => {
    const { data } = await api.post(`/documento-finalizaatend/${param}`);
    if (data?.entidade_id) {
      window.open(
        `/app/Comercial/Prospecção/Orçamentos?id=${data?.entidade_id}&name=${data?.entidade}`
      );
    }
    if (data && data?.recebimento === 'SIM' && data?.financeiro_id) {
      if (data?.retornavel === 'SIM') {
        history.push(`/app/Finalizar_Venda`, {
          state: {
            financeiro_id: data?.financeiro_id,
            operacao: 'ATENDIMENTO',
            retornavel: true,
            backTo: state?.state?.backTo,
          },
        });
      } else {
        history.push(`/app/Finalizar_Venda`, {
          state: {
            financeiro_id: data?.financeiro_id,
            operacao: 'ATENDIMENTO',
            backTo: state?.state?.backTo,
          },
        });
      }
    } else if (data && data?.midiaurl && data?.recebimento === 'NAO') {
      window.open(data?.midiaurl, '_blank');
      if (state?.state?.backTo === 'Caixa') {
        history.push('/app/Comercial/Atendimento-Caixa');
      } else if (state?.state?.backTo === 'Orçamentos') {
        history.push('/app/Comercial/OrçamentosdeVenda');
      } else if (state?.state?.backTo === 'Pedidos') {
        history.push('/app/Comercial/PedidosdeVenda');
      } else if (state?.state?.backTo === 'Faturamentos') {
        history.push('/app/Comercial/Faturamentos');
      } else if (data?.operacao === 'DEVOLUCAO') {
        history.go(-2);
      } else {
        history.go(-1);
      }
    } else if (data && data?.recebimento === 'NAO') {
      history.go(-2);
    }
  };

  const getTotalPagar = () => {
    let total = 0;
    if (documento?.Itens) {
      documento.Itens.map((i) => {
        total = total + i?.preco * i?.quantidade + i?.desconto_vlr;
      });
    }
    return setDecimal(total, 2);
  };

  const SetFields = () => {
    const { setValues, values } = useFormikContext();

    // useEffect(() => {
    //   if (values?.produto) {
    //     if (Boolean(values?.codBarra)) {
    //       onAdd(values);
    //     }
    //   }
    // }, [values?.produto]);

    useEffect(() => {
      if (postDocItemSuccess) {
        dispatch(DocumentoActions.postDocItemReset());
        dispatch(DocumentoActions.getDocumento(param));
        setValues({
          produto: null,
          quantidade: null,
          desconto: null,
          preco: null,
          codBarra: '',
          infadc: null,
        });
      }
      if (deleteDocItemSuccess) {
        dispatch(DocumentoActions.deleteDocItemReset());
        dispatch(DocumentoActions.getDocumento(param));
      }
    }, [deleteDocItemSuccess]);

    return null;
  };

  const onScannning = async (v, values) => {
    if (Boolean(v) && !isNaN(Number(v))) {
      let quantidade = transformData(values?.quantidade, 'number') || 1;
      let produto = prodDoc?.find((p) => p?.cdbarra == v);

      const { data } = await api.post(`/csql/981`, {
        Parametros: [
          { nome: ':produto_id', valor: produto?.value },
          { nome: ':documento_id', valor: documento?.Documento?.id },
        ],
      });
      if (data) {
        const { dados } = data;
        if (Boolean(dados[0])) {
          produto = { ...produto, ...dados[0] };
        }
      }
      if (produto?.lotemin && produto?.lotemin !== '0.000') {
        quantidade = setDecimal(produto?.lotemin || '', 3);
      }
      if (
        documento?.Documento?.doc_operacao === 'SAIDA' &&
        documento?.Documento?.doc_indvndcmp === 'SIM' &&
        produto?.lotemult &&
        produto?.lotemult !== '0.000'
      ) {
        quantidade = setDecimal(produto?.lotemult || '', 3);
      }
      if (
        documento?.Documento?.doc_operacao === 'ENTRADA' &&
        documento?.Documento?.doc_indvndcmp === 'SIM' &&
        produto?.loterepos &&
        produto?.loterepos !== '0.000'
      ) {
        quantidade = setDecimal(produto?.loterepos || '', 3);
      }
      const form = {
        produto,
        quantidade,
        desconto: values?.desconto,
        preco: setDecimal(produto?.preco, 2),
        infadc: values?.infadc,
      };
      onAdd(form);
    }
  };

  return (
    <div className="app-wrapper">
      <HeaderPage
        title={
          documento
            ? documento?.Documento?.doc_naturezaop +
              ' - ' +
              (documento?.Documento?.doc_nome || 'Consumidor')
            : ''
        }
      />
      <Formik
        initialValues={{
          produto: null,
          quantidade: null,
          desconto: null,
          preco: null,
          codBarra: null,
          infadc: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <BarcodeReader
              onScan={(v) => onScannning(v, values)}
              onError={(v) => onScannning(v, values)}
            />
            <Card className="shadow">
              <Grid container>
                <Grid xs="12" sm="11" md="11" lg="11" className={classes.grid}>
                  <TextField
                    label="Produto"
                    value={values.produto?.label || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid xs="2" sm="1" md="1" lg="1" className={classes.grPlus}>
                  <IconButton
                    className="icon-btn"
                    onClick={() => setProdutoModal(true)}
                  >
                    <AddIcon color="primary" />
                  </IconButton>
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="quantidade"
                    label="Quantidade"
                    value={values.quantidade || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="desconto"
                    label="% Desconto"
                    value={values.desconto || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('desconto', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="preco"
                    label="Preço Unitário"
                    value={values.preco || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('preco', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="infadc"
                    label="Informações Adicionais"
                    value={values.infadc || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <div className="d-flex w-100 justify-content-end">
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      disabled={deleteDocLoading}
                      onClick={() =>
                        dispatch(DocumentoActions.deleteDocumento(param))
                      }
                    >
                      Cancelar Venda
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className="ml-2"
                      color="primary"
                      onClick={() =>
                        window.open(`/app/Cadastros/Documento?id=${param}`)
                      }
                    >
                      Documento
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className="ml-2"
                      color="primary"
                      onClick={() =>
                        history.push(
                          `/app/Comercial/Atendimento/Historico?id=${param}`
                        )
                      }
                    >
                      HISTÓRICO
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="ml-2"
                      onClick={() => history.push(`/app/Catalogo?id=${param}`)}
                    >
                      <MenuBookIcon />
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      className="ml-2"
                      onClick={() => {
                        if (documento?.Documento?.doc_vinculo === 'SIM') {
                          history.goBack();
                        } else {
                          if (!!values?.produto) {
                            onAdd(values);
                          }
                        }
                      }}
                      disabled={postDocItemLoading}
                    >
                      ADICIONAR PRODUTO
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => onSubmit()}
                      className="ml-2"
                    >
                      FINALIZAR
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Card>
            <Grid container className="mt-2">
              <Grid xs="12" sm="3" md="3" lg="3">
                <Card className="shadow mr-1 px-2">
                  <Paper
                    elevation={5}
                    className={`${classes.paper} bg-gradient-primary-x text-white`}
                  >
                    <h6 className={`${classes.titles} text-center`}>
                      TOTAL A PAGAR
                    </h6>
                    <h4 className={classes.valor}>R$ {getTotalPagar()}</h4>
                  </Paper>
                  {!!values.produto && values?.produto !== '' && (
                    <>
                      <div className={classes.imageContainer}>
                        <img
                          src={values?.produto?.url}
                          className={classes.image}
                        />
                      </div>
                      <div className={classes.infoContainer}>
                        <span>
                          Disponibilidade:{' '}
                          {setDecimal(values?.produto?.saldo || 0, 2)}{' '}
                          {values?.produto?.unidade}
                        </span>
                        <span>Referência: {values?.produto?.referencia}</span>
                      </div>
                    </>
                  )}
                </Card>
              </Grid>
              <Grid xs="12" sm="9" md="9" lg="9">
                <Card className="shadow ml-1 px-2">
                  <Paper
                    elevation={5}
                    className={`${classes.paper} bg-gradient-primary-x text-white`}
                  >
                    <h3 className={classes.subtitle}>Carrinho de Compra</h3>
                  </Paper>

                  <CustomScrollbars
                    className="scrollbar"
                    style={{ minHeight: '50vh' }}
                  >
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Produto</TableCell>
                            <TableCell>Referência</TableCell>
                            <TableCell align="right">Quantidade</TableCell>
                            <TableCell>Unidade</TableCell>
                            <TableCell align="right">Preço Unitário</TableCell>
                            <TableCell align="right">Desconto (%)</TableCell>
                            <TableCell align="right">Preço Total</TableCell>
                            <TableCell align="right">Ação</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {documento?.Itens &&
                            documento.Itens.map((i) => (
                              <TableRow>
                                <TableCell classes={{ root: classes.cell }}>
                                  {i?.descricao}
                                </TableCell>
                                <TableCell classes={{ root: classes.cell }}>
                                  {i?.referencia}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  classes={{ root: classes.cell }}
                                >
                                  {i?.quantidade}
                                </TableCell>
                                <TableCell classes={{ root: classes.cell }}>
                                  {i?.unidade_medida}
                                </TableCell>
                                <TableCell
                                  classes={{ root: classes.cell }}
                                  align="right"
                                >
                                  {setDecimal(i?.preco, 2)}
                                </TableCell>
                                <TableCell
                                  classes={{ root: classes.cell }}
                                  align="right"
                                >
                                  {setDecimal(
                                    i?.desconto1_pc +
                                      i?.desconto2_pc +
                                      i?.desconto3_pc +
                                      i?.desconto4_pc +
                                      i?.desconto5_pc,
                                    2
                                  )}
                                </TableCell>
                                <TableCell
                                  classes={{ root: classes.cell }}
                                  align="right"
                                >
                                  {setDecimal(
                                    i?.preco * i?.quantidade + i?.desconto_vlr,
                                    2
                                  )}
                                </TableCell>
                                <TableCell
                                  classes={{ root: classes.cell }}
                                  align="right"
                                >
                                  <IconButton
                                    onClick={() =>
                                      dispatch(
                                        DocumentoActions.deleteDocItem(i?.id)
                                      )
                                    }
                                    disabled={deleteDocItemLoading}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomScrollbars>
                </Card>
              </Grid>
            </Grid>
            <ProdutoModal
              open={produtoModal}
              setOpen={setProdutoModal}
              documento={documento}
              onSubmit={(v) => {
                setFieldValue('quantidade', '1');
                setFieldValue('produto', v);
                setFieldValue('preco', setDecimal(v?.preco, 2));
                if (v?.lotemin && v?.lotemin !== '0.000') {
                  setFieldValue('quantidade', setDecimal(v?.lotemin || '', 3));
                }
                if (
                  documento?.Documento?.doc_operacao === 'SAIDA' &&
                  documento?.Documento?.doc_indvndcmp === 'SIM' &&
                  v?.lotemult &&
                  v?.lotemult !== '0.000'
                ) {
                  setFieldValue('quantidade', setDecimal(v?.lotemult || '', 3));
                }
                if (
                  documento?.Documento?.doc_operacao === 'ENTRADA' &&
                  documento?.Documento?.doc_indvndcmp === 'SIM' &&
                  v?.loterepos &&
                  v?.loterepos !== '0.000'
                ) {
                  setFieldValue(
                    'quantidade',
                    setDecimal(v?.loterepos || '', 3)
                  );
                }
                setProdutoModal(false);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(Vendas);
