import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Box,
  Checkbox,
  Button,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import SearchBox from 'components/SearchBox';
import { setDecimal, useQuery, transformData } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';
import moment from 'moment';

const Historico = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const param = query.get('id');
  const [historico, setHistorico] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState(
    moment()
      .add(-1, 'year')
      .format('DD/MM/YYYY')
  );
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const { capaSuccess, capaLoading } = useSelector(
    ({ documento }) => documento
  );

  const equals = (a, b) =>
    a.length === b.length && a.every((v, i) => v?.id === b[i]?.id);

  const loadHistorico = async () => {
    setSelected([]);
    setLoading(true);
    const { data } = await api.post(`/csql/961`, {
      Parametros: [
        { nome: ':documento', valor: param },
        {
          nome: ':dtbase',
          valor: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
        { nome: 'likevalue', valor: search },
      ],
    });
    if (data) {
      const { dados } = data;
      setHistorico(dados || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHistorico();
  }, []);

  const onSubmit = () => {
    let Itens = [];

    selected.map((s) => {
      Itens.push({
        documento_id: Number(param),
        preco: transformData(s?.precoatual, 'number'),
        produto_id: s?.produto_id,
        quantidade: transformData(s?.quantidade, 'number'),
        desconto1_pc: transformData(s?.descontopc, 'number'),
        desconto2_pc: 0,
        desconto3_pc: 0,
        desconto4_pc: 0,
        desconto5_pc: 0,
      });
    });

    dispatch(DocumentoActions.postCapa({ Itens }));
  };

  useEffect(() => {
    if (capaSuccess) {
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
      history.goBack();
    }
  }, [capaSuccess]);

  return (
    <div className="app-wrapper">
      <HeaderPage title="Histórico" />
      {tab === 1 && (
        <Card className="shadow">
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={5} alignItems="center">
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt?.target?.value)}
                  value={search}
                  onSubmit={() => loadHistorico()}
                />
              </Grid>
              <Grid item xs={12} sm={4} alignItems="center">
                <SearchBox
                  placeholder="Data Inicial"
                  onChange={(evt) => setDate(evt?.target?.value)}
                  value={date}
                  onSubmit={() => loadHistorico()}
                />
              </Grid>
              <Grid item xs={12} sm={3} alignItems="center">
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (!!selected?.length) setTab(2);
                    }}
                  >
                    CONFIRMAR
                  </Button>
                </div>
              </Grid>
              {loading ? (
                <Loading />
              ) : (
                <Grid item xs={12}>
                  <Box sx={{ overflow: 'auto' }} component={Paper}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'table',
                        tableLayout: 'fixed',
                      }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                checked={equals(selected, historico)}
                                onChange={() => {
                                  if (equals(selected, historico)) {
                                    setSelected([]);
                                  } else {
                                    setSelected(
                                      historico?.map((p) => ({
                                        ...p,
                                        quantidade: setDecimal(
                                          p?.quantidade,
                                          2
                                        ),
                                        precoatual: setDecimal(
                                          p?.precoatual,
                                          2
                                        ),
                                        descontopc: setDecimal(
                                          p?.descontopc,
                                          2
                                        ),
                                      }))
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell>Data de Emissão</TableCell>
                            <TableCell>Documento</TableCell>
                            <TableCell>Produto</TableCell>
                            <TableCell>Referência</TableCell>
                            <TableCell>Unidade</TableCell>
                            <TableCell>Preço</TableCell>
                            <TableCell>Desconto %</TableCell>
                            <TableCell>Desconto</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Vendedor</TableCell>
                            <TableCell>Saldo</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {historico?.map((item) => (
                            <TableRow key={item?.id?.toString()}>
                              <TableCell>
                                <Checkbox
                                  color="primary"
                                  checked={selected?.some(
                                    (c) => c?.id === item?.id
                                  )}
                                  onChange={() => {
                                    if (
                                      selected?.some((c) => c?.id === item?.id)
                                    ) {
                                      setSelected((prev) =>
                                        prev?.filter((f) => f?.id !== item?.id)
                                      );
                                    } else {
                                      setSelected((prev) => [
                                        ...prev,
                                        {
                                          ...item,
                                          quantidade: setDecimal(
                                            item?.quantidade,
                                            2
                                          ),
                                          precoatual: setDecimal(
                                            item?.precoatual,
                                            2
                                          ),
                                          descontopc: setDecimal(
                                            item?.descontopc,
                                            2
                                          ),
                                        },
                                      ]);
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                {item?.dtemissao &&
                                  moment(item?.dtemissao).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell>{item?.documento}</TableCell>
                              <TableCell>{item?.descricao}</TableCell>
                              <TableCell>{item?.referencia}</TableCell>
                              <TableCell>{item?.unidade}</TableCell>
                              <TableCell>
                                {setDecimal(item?.preco, 2)}
                              </TableCell>
                              <TableCell>
                                {setDecimal(item?.descontopc, 2)}
                              </TableCell>
                              <TableCell>
                                {setDecimal(item?.descontovlr, 2)}
                              </TableCell>
                              <TableCell>
                                {setDecimal(item?.valor, 2)}
                              </TableCell>
                              <TableCell>{item?.vendedor}</TableCell>
                              <TableCell>
                                {item?.saldo ? parseFloat(item?.saldo) : ''}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
      {tab === 2 && (
        <Card className="shadow">
          <CardContent>
            {selected?.map((s, i) => {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={2} className="d-flex">
                    <div className="d-flex align-items-end">
                      <Typography variant="subtitle2" component="div">
                        {s?.descricao}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Último Desconto"
                      value={setDecimal(s?.descontopcant, 2)}
                      disabled
                      onChange={(e) => {}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Último Preço"
                      value={setDecimal(s?.preco, 2)}
                      disabled
                      onChange={(e) => {}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Quantidade"
                      value={s?.quantidade}
                      onChange={(e) => {
                        let newSelected = [...selected];
                        newSelected[i].quantidade = e?.target?.value;
                        setSelected(newSelected);
                      }}
                      onBlur={(e) => {
                        let newSelected = [...selected];
                        newSelected[i].quantidade = setDecimal(
                          e?.target?.value,
                          2
                        );
                        setSelected(newSelected);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="% Desconto"
                      value={s?.descontopc}
                      onChange={(e) => {
                        let newSelected = [...selected];
                        newSelected[i].descontopc = e?.target?.value;
                        setSelected(newSelected);
                      }}
                      onBlur={(e) => {
                        let newSelected = [...selected];
                        newSelected[i].descontopc = setDecimal(
                          e?.target?.value,
                          2
                        );
                        setSelected(newSelected);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Valor"
                      value={s?.precoatual}
                      onChange={(e) => {
                        let newSelected = [...selected];
                        newSelected[i].precoatual = e?.target?.value;
                        setSelected(newSelected);
                      }}
                      onBlur={(e) => {
                        let newSelected = [...selected];
                        newSelected[i].precoatual = setDecimal(
                          e?.target?.value,
                          2
                        );
                        setSelected(newSelected);
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              );
            })}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="mr-2"
                    onClick={() => setTab(1)}
                  >
                    VOLTAR
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onSubmit}
                    disabled={capaLoading}
                  >
                    CONFIRMAR
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Historico;
