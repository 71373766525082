import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as FiscalActions,
  Types as FiscalTypes,
} from 'store/ducks/Fiscal';
import api from '../../util/api';

function* postTributacao({ payload }) {
  try {
    const { status } = yield call(api.post, `/fiscal-tributacao`, payload);
    if (status === 200 || status === 201) {
      yield put(FiscalActions.postTributacaoSuccess());
    } else {
      yield put(FiscalActions.postTributacaoError());
    }
  } catch (error) {
    yield put(FiscalActions.postTributacaoError());
  }
}

function* postCopia({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/fiscal-tributacao-copia`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(FiscalActions.postCopiaRegraSuccess());
    } else {
      yield put(FiscalActions.postCopiaRegraError());
    }
  } catch (error) {
    yield put(FiscalActions.postCopiaRegraError());
  }
}

function* putTributacao({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/fiscal-tributacao-manutencao`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(FiscalActions.putTributacaoSuccess());
    } else {
      yield put(FiscalActions.putTributacaoError());
    }
  } catch (error) {
    yield put(FiscalActions.putTributacaoError());
  }
}

function* importIbpt({ payload }) {
  try {
    const { status } = yield call(api.post, `/fiscal-tabela-ibpt`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (status === 200 || status === 201) {
      yield put(FiscalActions.importIbptSuccess());
    } else {
      yield put(FiscalActions.importIbptError());
    }
  } catch (error) {
    yield put(FiscalActions.importIbptError());
  }
}

function* getApuracao({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      `/fiscal-getapuracao`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(FiscalActions.getApuracaoSuccess(data?.apuracao));
    } else {
      yield put(FiscalActions.getApuracaoError());
    }
  } catch (error) {
    yield put(FiscalActions.getApuracaoError());
  }
}

function* postApuracao({ payload }) {
  try {
    const { status } = yield call(api.post, `/fiscal-postapuracao`, payload);
    if (status === 200 || status === 201) {
      yield put(FiscalActions.postApuracaoSuccess());
    } else {
      yield put(FiscalActions.postApuracaoError());
    }
  } catch (error) {
    yield put(FiscalActions.postApuracaoError());
  }
}

function* postApr({ payload }) {
  try {
    const { status } = yield call(api.post, `/fiscal-apuracao`, payload);
    if (status === 200 || status === 201) {
      yield put(FiscalActions.postAprSuccess());
    } else {
      yield put(FiscalActions.postAprError());
    }
  } catch (error) {
    yield put(FiscalActions.postAprError());
  }
}

function* postGnre({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/fiscal-gnre`, payload, {
      responseType: 'blob',
    });
    if (status === 200 || status === 201) {
      yield put(FiscalActions.postGnreSuccess(data));
    } else {
      yield put(FiscalActions.postGnreReset());
    }
  } catch (error) {
    yield put(FiscalActions.postGnreReset());
  }
}

function* postTributacaoWatcher() {
  yield takeLatest(FiscalTypes.POST_TRIBUTACAO, postTributacao);
}

function* postCopiaWatcher() {
  yield takeLatest(FiscalTypes.POST_COPIA_REGRA, postCopia);
}

function* putTributacaoWatcher() {
  yield takeLatest(FiscalTypes.PUT_TRIBUTACAO, putTributacao);
}

function* importIbptWatcher() {
  yield takeLatest(FiscalTypes.IMPORT_IBPT, importIbpt);
}

function* getApuracaoWatcher() {
  yield takeLatest(FiscalTypes.GET_APURACAO, getApuracao);
}

function* postApuracaoWatcher() {
  yield takeLatest(FiscalTypes.POST_APURACAO, postApuracao);
}

function* postAprWatcher() {
  yield takeLatest(FiscalTypes.POST_APR, postApr);
}

function* postGnreWatcher() {
  yield takeLatest(FiscalTypes.POST_GNRE, postGnre);
}

export default function* rootSaga() {
  yield all([
    fork(postTributacaoWatcher),
    fork(postCopiaWatcher),
    fork(putTributacaoWatcher),
    fork(importIbptWatcher),
    fork(getApuracaoWatcher),
    fork(postApuracaoWatcher),
    fork(postAprWatcher),
    fork(postGnreWatcher),
  ]);
}
