import moment from 'moment';
import { transformData } from 'util/index';

const isSaida = (values) => {
  if (
    values?.especie?.grupo === 'SAIDA' &&
    values?.especie?.especie !== 'MDF-e' &&
    values?.especie?.natureza !== 'ESTOQUE' &&
    values?.naturezaOperacao?.natureza !== 'IMPORTACAO'
  ) {
    return true;
  }
  return false;
};

const isEntrada = (values) => {
  if (
    values?.especie?.grupo === 'ENTRADA' ||
    values?.especie?.natureza === 'FATURA' ||
      values?.especie?.natureza === 'ESTOQUE' ||
      values?.especie?.natureza === 'COMPRA' ||
      values?.especie?.natureza === 'DESPESA' ||
      values?.especie?.natureza === 'SERVICO' ||
      values?.especie?.natureza === 'PRODUCAO' ||
      values?.naturezaOperacao?.natureza === 'IMPORTACAO' ||
      values?.especie?.natureza === 'OPERACIONAL'
  ) {
    return true;
  }
  return false;
};

const isServico = (values) => {
  if (values?.especie?.natureza === 'SERVICO') {
    return true;
  }
  return false;
};

const isCompras = (values) => {
  if (
    values?.especie?.especie === 'REQUISIÇÃO DE COMPRAS' ||
    values?.especie?.especie === 'COTAÇÃO DE COMPRAS' ||
    values?.especie?.especie === 'PEDIDO DE COMPRAS'
  ) {
    return true;
  }
  return false;
};

const isContrato = (values) => {
  if (values?.especie?.natureza === 'CONTRATO') {
    return true;
  }
  return false;
};

const isExportacao = (values) => {
  if (values?.naturezaOperacao?.label?.includes('EXPORTACAO')) {
    return true;
  }
  return false;
};

const isImportacao = (values) => {
  if (values?.naturezaOperacao?.label?.includes('IMPORTACAO')) {
    return true;
  }
  return false;
};

const isDocumento = (values) => {
  if (
    values?.especie?.grupo === 'SAIDA' &&
    values?.especie?.especie !== 'MDF-e' &&
    values?.especie?.natureza !== 'ESTOQUE'
  ) {
    return true;
  }
  return false;
};

const isReqCompra = (values) => {
  if (values?.especie?.especie === 'REQUISIÇÃO DE COMPRAS') {
    return true;
  }
  return false;
};

const isCotCompra = (values) => {
  if (values?.especie?.especie === 'COTAÇÃO DE COMPRAS') {
    return true;
  }
  return false;
};

const isPedCompra = (values) => {
  if (values?.especie?.especie === 'PEDIDO DE COMPRAS') {
    return true;
  }
  return false;
};

const isFiscal = (values) => {
  if (values?.especie?.fiscal === 'SIM') {
    return true;
  }
  return false;
};

const isMoeda = (values) => {
  if (
    (values?.moeda && values?.moeda !== '') ||
    (values?.valor && values?.valor !== '')
  ) {
    return true;
  }
  return false;
};

const capaForm = (values, user, param) => {
  const paramDocumento = param?.Documento;
  const paramMoeda = param?.Moeda;
  const paramSaida = param?.Saida;
  const paramEntrada = param?.Entrada;
  const paramReqCompra = param?.ReqCompra;
  const paramCotCompra = param?.CotCompra;
  const paramPedCompra = param?.PedCompra;
  const paramContrato = param?.Contrato;
  const paramExportacao = param?.Exportacao;
  const paramImportacao = param?.Importacao;
  const paramServico = param?.Servico;
  const Documento = {
    ...paramDocumento,
    celocal_id: values?.celocal_id?.value,
    natureza_operacao_id: values?.naturezaOperacao?.value,
    especie_id: values?.especie?.value,
    filial_id: values?.filial?.value,
    documento: Number(values?.documento) || null,
    dtemissao: moment(values?.dtemissao, 'DD/MM/YYYY HH:mm:ss').isValid()
      ? moment(values?.dtemissao, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      : null,
    cadastro_id: values?.entidade?.value,
    contribuinte_id: values?.contribuinte?.value,
    usofinal: values?.usoFinal?.value,
    indpres: values?.indpres?.value,
    observacao: values?.observacoes,
    dadosadc: values?.adicionais,
    users_id: user,
  };

  const Moeda = {
    ...paramMoeda,
    moeda_id: values?.moeda?.value,
    cotacao: values?.valor && transformData(values?.valor, 'number'),
  };

  const Saida = {
    ...paramSaida,
    cpf: values?.numDocumento,
    ped_cliente: values?.numPedido,
    dtatend: moment(values?.dtAtendimento, 'DD/MM/YYYY').isValid()
      ? moment(values?.dtAtendimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
    vendedor_id: values?.vendedor?.value,
    condicao_pagamento_id: values?.condPag?.value,
    entidade_endereco_id: values?.localForn?.value,
  };

  const Entrada = {
    ...paramEntrada,
    serie: values?.serie,
    dtentrada: moment(values?.dtEntrada, 'DD/MM/YYYY').isValid()
      ? moment(values?.dtEntrada, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
  };

  const ReqCompra = { ...paramReqCompra, users_id: values?.requisitante?.value };

  const CotCompra = {
    ...paramCotCompra,
    entrega: values?.entrega,
    licitacao: values?.licitacao,
  };

  const PedCompra = {
    ...paramPedCompra,
    contato: values?.contato,
    entrega: values?.entrega,
    inspecao: values?.inspecionar?.value,
  };

  const Contrato = {
    ...paramContrato,
    tipocont: values?.tipo?.value,
    reajuste: values?.reajuste?.value,
    dtinicont: moment(values?.dtInicio, 'DD/MM/YYYY').isValid()
      ? moment(values?.dtInicio, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
  };

  const Exportacao = {
    ...paramExportacao,
    ufembarque_id: values?.ufEmbarque?.value,
    locembarque: values?.localEmbarque,
  };

  const Importacao = {
    ...paramImportacao,
    numero: values?.numeroDi,
    data: moment(values?.dataDi, 'DD/MM/YYYY').isValid()
      ? moment(values?.dataDi, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
    tpviatransp: values?.viaTransporte?.value,
    desembaraco_loc: values?.localDesembaraco,
    desembaraco_uf: values?.ufDesembaraco?.value,
    desembaraco_dat: moment(values?.dataDesembaraco, 'DD/MM/YYYY').isValid()
      ? moment(values?.dataDesembaraco, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
    tpintermedio: values?.intermedio?.value,
    vafrmm: values?.afrmm && transformData(values?.afrmm, 'number'),
  };
  const loadDefeitos = () => {
    let newDefeitos = !!paramServico?.defeitos?.length
      ? paramServico?.defeitos?.map((v) => {
          let finder = values?.defeitos?.find((f) => f.value === v);
          if (!finder) {
            return -v;
          }
          return v;
        })
      : [];
    values.defeitos.map((p) => {
      let finder = newDefeitos?.find((f) => p.value === f);
      if (!finder) {
        newDefeitos.push(p.value);
      }
    });
    return newDefeitos;
  };

  const Servico = {
    ...paramServico,
    solicitacao: values?.solicitacao,
    defeito: values?.defeito,
    mail_contato: values?.mail_contato,
    garantia: transformData(values?.garantia, 'number'),
    contrato_item_id: values?.contrato?.value,
    defeitos: loadDefeitos(),
  };

  let data = { Documento };
  if (isMoeda(values)) {
    data = { ...data, Moeda };
  }
  if (isSaida(values)) {
    data = { ...data, Saida };
  }
  if (isEntrada(values)) {
    data = { ...data, Entrada };
  }
  if (isReqCompra(values)) {
    data = { ...data, ReqCompra };
  }
  if (isCotCompra(values)) {
    data = { ...data, CotCompra };
  }
  if (isPedCompra(values)) {
    data = { ...data, PedCompra };
  }
  if (isContrato(values)) {
    data = { ...data, Contrato };
  }
  if (isExportacao(values)) {
    data = { ...data, Exportacao };
  }
  if (isImportacao(values)) {
    data = { ...data, Importacao };
  }
  if (isServico(values)) {
    data = { ...data, Servico };
  }
  return data;
};

export {
  isSaida,
  isEntrada,
  isServico,
  isCompras,
  isContrato,
  isExportacao,
  isImportacao,
  isDocumento,
  isReqCompra,
  isCotCompra,
  isPedCompra,
  isFiscal,
  isMoeda,
  capaForm,
};
