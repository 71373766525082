import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Grid,
  Button,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import api from 'util/api';
import { useQuery } from 'util/index';

const styles = (theme) => ({
  noBefore: {
    margin: '2rem 0 0 0',
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  add: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  border: {
    borderTop: '1px solid',
    borderColor: '#c5c5c5',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  btn: {
    marginTop: '1rem',
    padding: '0 0.5rem',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
});

const Descrição = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const id = query.get('id');
  const tabela = query.get('from');
  const nomeItem = query.get('item');
  const { descricao, descLoading, putDescSuccess } = useSelector(
    ({ produto }) => produto
  );
  const [items, setItems] = useState([]);
  const [descValue, setDescValue] = useState('');
  const [descType, setDescType] = useState('');

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    loadItems([695]);
    dispatch(
      ProdutoActions.getDescricao({ tabela, filtro: `produto_id=${id}` })
    );
  }, []);

  useEffect(() => {
    if (putDescSuccess) {
      dispatch(ProdutoActions.putDescricaoReset());
      dispatch(
        ProdutoActions.getDescricao({ tabela, filtro: `produto_id=${id}` })
      );
    }
  }, [putDescSuccess]);

  function onDelete(item, index) {
    var localDescs = descricao;
    localDescs[index].descricao = '';

    dispatch(
      ProdutoActions.putDescricao({
        id,
        registros: localDescs,
      })
    );
  }

  return (
    <div className="app-wrapper">
      <HeaderPage title={`Descrição - ${nomeItem}`} />
      <Card className="shadow">
        <CardContent>
          {descLoading ? (
            <Loading />
          ) : (
            <>
              {descricao.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell align="right">Ação</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {descricao.map((d, index) => {
                        if (d.descricao !== '') {
                          return (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {d.descricao}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {d.tipo}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                <DeleteIcon
                                  color="action"
                                  onClick={() => onDelete(d, index)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Accordion className={classes.noBefore}>
                <AccordionSummary
                  expandIcon={<AddRoundedIcon color="primary" />}
                >
                  <span className={classes.add}>Nova Descrição</span>
                </AccordionSummary>
                <AccordionDetails className={classes.border}>
                  <Grid container>
                    <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                      <TextField
                        name="descValue"
                        label="Descrição"
                        value={descValue}
                        onChange={(e) => setDescValue(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Tipo"
                        items={items[0] || []}
                        value={descType}
                        onChange={setDescType}
                      />
                    </Grid>
                    <div className={classes.btn}>
                      <Button
                        color="primary"
                        onClick={() =>
                          dispatch(
                            ProdutoActions.putDescricao({
                              id,
                              registros: [
                                ...descricao,
                                {
                                  produto_id: id,
                                  tipo: descType?.value,
                                  descricao: descValue,
                                },
                              ],
                            })
                          )
                        }
                        variant="contained"
                      >
                        Adicionar
                      </Button>
                    </div>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Descrição);
