import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Checkbox,
  Grid,
  TextField,
  IconButton,
  InputLabel,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import api from 'util/api';
import Loading from 'components/Loading';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  grPlus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const ClienteModal = ({ open, setOpen, onSubmit, classes }) => {
  const [search, setSearch] = useState('');
  const [boxes, setBoxes] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cpfNota, setCpfNota] = useState(false);

  const loadDrop = async () => {
    setLoading(true);
    setSelected(null);
    const { data } = await api.post(`/csql/782`, {
      Parametros: [{ nome: 'likevalue', valor: search }],
    });
    if (data) {
      const { dados } = data;
      setBoxes(dados || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setBoxes([]);
      setSearch('');
      setSelected(null);
    }
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Buscar Cliente (RSocial, Nome, Cidade, Documento ou Código: )</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="10" md="10" lg="10" className={classes.grid}>
            <TextField
              label="Digite para pesquisar"
              value={search || ''}
              onChange={(e) => setSearch(e?.target?.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid xs="2" sm="2" md="2" lg="2" className={classes.grPlus}>
            <IconButton className="icon-btn" onClick={loadDrop}>
              <SearchIcon color="primary" />
            </IconButton>
          </Grid>
          {loading ? (
            <Loading />
          ) : (
            boxes.map((i) => (
              <div className="d-flex align-items-center py-3 w-100">
                <Checkbox
                  name={i.value}
                  id={i.value}
                  color="primary"
                  checked={selected?.value === i.value}
                  value={i.value}
                  style={{ padding: '0 10px 0 0' }}
                  onChange={(e) => setSelected(i)}
                />
                <InputLabel htmlFor={i.value}>{i.label}</InputLabel>
              </div>
            ))
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Checkbox
            color="primary"
            checked={cpfNota}
            onChange={() => setCpfNota(!cpfNota)}
          />
          <DialogContentText style={{ margin: 0 }}>
            Inserir documento na nota
          </DialogContentText>
        </div>
        <Button color="primary" onClick={() => onSubmit(cpfNota, selected)}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ClienteModal);
