import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';
import api from 'util/api';

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const BaixarExcelModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [items, setItems] = useState([]);
  const [metodo, setMetodo] = useState(null);
  const { postExcelLoading } = useSelector(({ lancamento }) => lancamento);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([898]);
    }
  }, [open]);

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('ecommerce', metodo?.value);
    dispatch(LancamentoActions.postExcel(formData));
  };

  const onChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Baixar E-Commerce</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Método"
              items={items[0] || []}
              value={metodo}
              onChange={(v) => setMetodo(v)}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <input
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              className="w-100"
              onChange={(e) => onChange(e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="primary" disabled={postExcelLoading}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BaixarExcelModal);
