import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import FilterReportModal from 'components/Modals/FilterReportModal';
import ViewModal from 'components/Modals/ViewModal';
import FilterAccordion from 'components/FilterAccordion';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import ResumoModal from 'components/Modals/ResumoModal';
import IntegrarDadosModal from 'components/Modals/IntegrarDadosModal';
import GerarDadosEcdModal from 'components/Modals/GerarDadosEcdModal';
import {
  checkManutencao,
  checkAgrupamento,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ResumoActions } from 'store/ducks/Resumo';
import { Creators as ContabilActions } from 'store/ducks/Contabil';
import { Creators as FilterActions } from 'store/ducks/FilterState';
import moment from 'moment';
import api from 'util/api';

const Lançamentos = ({ match }) => {
  const { filter } = useSelector(({ filterState }) => filterState);
  const filterPath = useLocation().pathname;
  const rotina = 575;
  const csql = 434;
  const [relatorio, setRelatorio] = useState(null);
  const title = 'Lançamentos contábeis';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState(
    filter[filterPath]?.dataTable?.Parametros[0]?.valor || ''
  );
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [items, setItems] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [integrarModal, setIntegrarModal] = useState(false);
  const [gerarDadosEcdModal, setGerarDadosEcdModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('planoentidade');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const { postDadosSuccess, postContabilLancSuccess } = useSelector(
    ({ contabil }) => contabil
  );
  async function loadItems() {
    const { data } = await api.post(`/csql/781`, {
      Parametros: [
        { nome: ':descricao', valor: 'ESCRITURAÇÃO CONTABIL DIGITAL - ECD' },
      ],
    });
    if (data) {
      setItems(data.dados || []);
    }
  }

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = (filter[filterPath]?.filtros || filtros).map(
        (f) => {
          if (f.parametro === 'SIM') {
            return { ...f, conteudoi: param, conteudod: nameParam };
          }
          return f;
        }
      );
      return filterWithParam;
    }
    return filter[filterPath]?.filtros || filtros;
  }

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros: filter[filterPath]?.filtros || filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      dispatch(ResumoActions.setFilterResumo(setParamOnFilter()));
      const params = {
        Parametros: [{ nome: 'likevalue', valor: search }],
        WhereClause: getWhereClause({
          filtros: filter[filterPath]?.filtros || filtros,
          setStringExport,
        }),
        OrderClause,
        Pagina: paginacao ? Pagina : undefined,
        PorPagina: paginacao ? PorPagina : undefined,
      };
      dispatch(
        FilterActions.setFilterState({
          [filterPath]: { ...filter[filterPath], dataTable: params },
        })
      );
    }
  }

  useEffect(() => {
    if (Boolean(filter[filterPath]?.dataTable)) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: filter[filterPath]?.dataTable,
        })
      );
      getTotais();
    }
  }, [filter[filterPath]]);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    loadItems();
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    getDataTable();
  }, [Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (
      headerDataStore.length &&
      (filter[filterPath]?.filtros || filtros).length
    ) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (postDadosSuccess) {
      dispatch(ContabilActions.postDadosReset());
      dispatch(TablesActions.getHeader(rotina));
      setIntegrarModal(false);
    }
  }, [postDadosSuccess]);

  useEffect(() => {
    if (postContabilLancSuccess) {
      dispatch(ContabilActions.postContabilLancReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postContabilLancSuccess]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () => history.push(`/app/Contábil/Lançamentos/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: checkAgrupamento(headerData),
    },
    {
      name: 'Integrar dados',
      type: 'Carga',
      action: () => setIntegrarModal(true),
      visible: true,
    },
    {
      name: 'Contabiliza lançamentos',
      type: 'Concluir',
      action: () => {
        const plano = (filter[filterPath]?.filtros || filtros)?.find(
          (f) => f?.titulo?.toUpperCase() === 'ENTIDADE / PLANO DE CONTAS'
        )?.conteudoi;
        const dti = (filter[filterPath]?.filtros || filtros)?.find(
          (f) => f?.titulo?.toUpperCase() === 'DATA INICIO'
        )?.conteudoi;
        const dtf = (filter[filterPath]?.filtros || filtros)?.find(
          (f) => f?.titulo?.toUpperCase() === 'DATA FIM'
        )?.conteudoi;
        const form = {
          planoentidade_id: Number(plano) || null,
          datai: moment(dti?.replace(/([/-])/g, ''), 'DDMMYYYY').isValid()
            ? moment(dti?.replace(/([/-])/g, ''), 'DDMMYYYY').format(
                'YYYY-MM-DD'
              )
            : null,
          dataf: moment(dtf?.replace(/([/-])/g, ''), 'DDMMYYYY').isValid()
            ? moment(dtf?.replace(/([/-])/g, ''), 'DDMMYYYY').format(
                'YYYY-MM-DD'
              )
            : null,
          contabilizar: data[0]?.contabilizado === 'SIM' ? 'NAO' : 'SIM',
        };
        dispatch(ContabilActions.postContabilLanc(form));
      },
      visible: !!data?.length,
    },
    {
      name: 'Configuração de Layout ECD',
      type: 'Layout',
      action: () => {
        if (!!items.length) {
          history.push(
            `/app/Exporta?id=${items[0]?.value}&name=${items[0]?.label}`
          );
        }
      },
      visible: true,
    },
    {
      name: 'Escrituração Contábil Digital (ECD)',
      type: 'Exportar',
      action: () => setGerarDadosEcdModal(true),
      visible: true,
    },

    {
      name: 'Razão',
      type: 'Imprimir',
      action: () => {
        setRelatorio(577);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Balancete',
      type: 'Imprimir',
      action: () => {
        setRelatorio(578);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Diário',
      type: 'Imprimir',
      action: () => {
        setRelatorio(579);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Resultado',
      type: 'Preço',
      action: () => history.push(`/app/Contábil/Lançamentos/Resultado`),
      visible: true,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) =>
        history.push(`/app/Contábil/Lançamentos/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Contabiliza lançamentos',
          varName: 'Check',
          action: (item) => {
            const plano = (filter[filterPath]?.filtros || filtros)?.find(
              (f) => f?.titulo?.toUpperCase() === 'ENTIDADES / PLANO DE CONTAS'
            )?.conteudoi;
            const dti = (filter[filterPath]?.filtros || filtros)?.find(
              (f) => f?.titulo?.toUpperCase() === 'DATA INICIO'
            )?.conteudoi;
            const dtf = (filter[filterPath]?.filtros || filtros)?.find(
              (f) => f?.titulo?.toUpperCase() === 'DATA FIM'
            )?.conteudoi;
            const form = {
              planoentidade_id: Number(plano) || null,
              datai: moment(dti?.replace(/([/-])/g, ''), 'DDMMYYYY').isValid()
                ? moment(dti?.replace(/([/-])/g, ''), 'DDMMYYYY').format(
                    'YYYY-MM-DD'
                  )
                : null,
              dataf: moment(dtf?.replace(/([/-])/g, ''), 'DDMMYYYY').isValid()
                ? moment(dtf?.replace(/([/-])/g, ''), 'DDMMYYYY').format(
                    'YYYY-MM-DD'
                  )
                : null,
              id: item?.id,
              contabilizar: item?.contabilizar === 'SIM' ? 'SIM' : 'NAO',
            };
            dispatch(ContabilActions.postContabilLanc(form));
          },
          visible: true,
        },
        {
          name: 'Detalhes do Documento',
          varName: 'Documento',
          action: (item) => {
            history.push(`/app/Contábil/Lançamentos/Detalhes?id=${item?.id}`);
          },
          visible: true,
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <FilterAccordion filtros={filter[filterPath]?.filtros || filtros} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <IntegrarDadosModal
                open={integrarModal}
                setOpen={setIntegrarModal}
                filtros={filter[filterPath]?.filtros || filtros}
              />
              <GerarDadosEcdModal
                open={gerarDadosEcdModal}
                setOpen={setGerarDadosEcdModal}
                rotina={rotina}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Lançamentos;
