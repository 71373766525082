import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as EntidadesActions,
  Types as EntidadesTypes,
} from 'store/ducks/Entidades';
import api from '../../util/api';

function* postEntidade({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/entidade`, payload);
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.postEntidadeSuccess(data?.id || null));
    } else {
      yield put(EntidadesActions.postEntidadeError());
    }
  } catch (error) {
    yield put(EntidadesActions.postEntidadeError());
  }
}

function* putEntidade({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/entidade/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.putEntidadeSuccess());
    } else {
      yield put(EntidadesActions.putEntidadeError());
    }
  } catch (error) {
    yield put(EntidadesActions.putEntidadeError());
  }
}

function* getEntidade({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/entidade/${payload}`);
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.getEntidadeSuccess(data.ent));
    } else {
      yield put(EntidadesActions.getEntidadeError());
    }
  } catch (error) {
    yield put(EntidadesActions.getEntidadeError());
  }
}

function* deleteEntidade({ payload }) {
  try {
    const { status } = yield call(api.delete, `/entidade/${payload}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(EntidadesActions.deleteEntidadeSuccess());
    } else {
      yield put(EntidadesActions.deleteEntidadeError());
    }
  } catch (error) {
    yield put(EntidadesActions.deleteEntidadeError());
  }
}

function* getMidia({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/entidade-regs-anexo/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.getMidiaSuccess(data.registros));
    } else {
      yield put(EntidadesActions.getMidiaError());
    }
  } catch (error) {
    yield put(EntidadesActions.getMidiaError());
  }
}

function* putMidia({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.put, `/entidade-regs-anexo/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.putMidiaSuccess());
    } else {
      yield put(EntidadesActions.putMidiaError());
    }
  } catch (error) {
    yield put(EntidadesActions.putMidiaError());
  }
}

function* setStatusEntidade({ payload }) {
  try {
    const { status } = yield call(api.put, `/entidade-ativacao/${payload}`);
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.setStatusEntidadeSuccess());
    } else {
      yield put(EntidadesActions.setStatusEntidadeError());
    }
  } catch (error) {
    yield put(EntidadesActions.setStatusEntidadeError());
  }
}

function* getIdentificacao({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/entidade-identificacao/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.getIdentificacaoSuccess(data?.entidade));
    } else {
      yield put(EntidadesActions.getIdentificacaoError());
    }
  } catch (error) {
    yield put(EntidadesActions.getIdentificacaoError());
  }
}

function* postEntidadeWatcher() {
  yield takeLatest(EntidadesTypes.POST_ENTIDADE, postEntidade);
}

function* putEntidadeWatcher() {
  yield takeLatest(EntidadesTypes.PUT_ENTIDADE, putEntidade);
}

function* getEntidadeWatcher() {
  yield takeLatest(EntidadesTypes.GET_ENTIDADE, getEntidade);
}

function* deleteEntidadeWatcher() {
  yield takeLatest(EntidadesTypes.DELETE_ENTIDADE, deleteEntidade);
}

function* getMidiaWatcher() {
  yield takeLatest(EntidadesTypes.GET_ENTIDADE_MIDIAS, getMidia);
}

function* putMidiaWatcher() {
  yield takeLatest(EntidadesTypes.PUT_ENTIDADE_MIDIA, putMidia);
}

function* setStatusEntidadeWatcher() {
  yield takeLatest(EntidadesTypes.SET_STATUS_ENTIDADE, setStatusEntidade);
}

function* getIdentificacaoWatcher() {
  yield takeLatest(EntidadesTypes.GET_IDENTIFICACAO, getIdentificacao);
}

function* atualizarControlesWeb({ payload }){
  try {
    const { status } = yield call(api.post, `/utils/sincronizar-cadastro-gestao-web`,
                                          { entidade_id: payload});
    if (status === 200 || status === 201) {
      yield put(EntidadesActions.atualizarControlesWebSuccess());
    } else {
      yield put(EntidadesActions.atualizarControlesWebError());
    }
  } catch (error) {
    yield put(EntidadesActions.atualizarControlesWebError());
  }
}

function* atualizarControlesWebWatcher() {
  yield takeLatest(EntidadesTypes.POST_CONTROLESWEB, atualizarControlesWeb);
}


export default function* rootSaga() {
  yield all([
    fork(postEntidadeWatcher),
    fork(putEntidadeWatcher),
    fork(getEntidadeWatcher),
    fork(deleteEntidadeWatcher),
    fork(getMidiaWatcher),
    fork(putMidiaWatcher),
    fork(setStatusEntidadeWatcher),
    fork(getIdentificacaoWatcher),
    fork(atualizarControlesWebWatcher),
  ]);
}
