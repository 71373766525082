import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';
import jwt from 'jwt-simple';

const ResumoModal = ({
  open,
  setOpen,
  boxes,
  rotina,
  csql,
  history,
  where,
}) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(boxes);
  const [selected, setSelected] = useState(null);

  return (
    <Dialog open={open}>
      <DialogTitle>Agrupar por...</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecione a coluna a ser agrupada.
        </DialogContentText>
        {columns?.map((b) => {
          return (
            <FormControlLabel
              className="d-flex"
              control={
                <Checkbox
                  color="primary"
                  checked={selected === b.campo}
                  onChange={() => setSelected(b.campo)}
                />
              }
              label={b.label}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            let url = `/app/Resumo?group=${selected}&rotina=${rotina}&csql=${csql}`;
            if (!!where) {
              const filter = jwt.encode(where(), 'SecretFilterEprom');
              url += `&filter=${filter}`;
            }
            setOpen(!open);
            history.push(url);
          }}
          color="primary"
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ResumoModal;
