import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { findOnArray, setDecimal, transformData } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Checkbox,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';
import moment from 'moment';

const styles = () => ({
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const ChooseModal = ({ open, setOpen, documento, type, onSubmit, classes }) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);

  async function loadItems() {
    const { data } = await api.post(`/csql/${931}`, {
      Parametros: [
        {
          nome: ':cadastro_id',
          valor: documento?.Documento?.cadastro_id,
        },
        {
          nome: ':grupo',
          valor: documento?.Documento?.doc_grupo,
        },
        {
          nome: ':tipo',
          valor: type,
        },
      ],
    });
    if (data) {
      setItems(data?.dados || []);
    }
  }

  useEffect(() => {
    if (open) {
      setSelected(null);
      loadItems();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <DialogTitle>Opções de Transporte</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Cidade</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Vínculo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow>
                  <TableCell>
                    <Checkbox
                      style={{ padding: '0 5px' }}
                      color="default"
                      checked={selected?.index === index}
                      onChange={() => setSelected({ ...item, index })}
                    />
                  </TableCell>
                  <TableCell>{item?.nome}</TableCell>
                  <TableCell>{item?.cidade}</TableCell>
                  <TableCell>
                    {moment(item?.data).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{item?.vinculo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onSubmit(selected)}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(ChooseModal);
