import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import FilterReportModal from 'components/Modals/FilterReportModal';
import ViewModal from 'components/Modals/ViewModal';
import FilterAccordion from 'components/FilterAccordion';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import ResumoModal from 'components/Modals/ResumoModal';
import AjusteFormaPgtoModal from 'components/Modals/AjusteFormaPgtoModal';
import RemessaModal from 'components/Modals/RemessaModal';
import BaixarExcelModal from 'components/Modals/BaixarExcelModal';
import DevolucaoChequeModal from 'components/Modals/DevolucaoChequeModal';
import {
  checkManutencao,
  checkAgrupamento,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ResumoActions } from 'store/ducks/Resumo';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';
import { Creators as FilterActions } from 'store/ducks/FilterState';
import api from 'util/api';

const Pendências = ({ match }) => {
  const { filter } = useSelector(({ filterState }) => filterState);
  const filterPath = useLocation().pathname;
  const rotina = 412;
  const csql = 293;
  const [relatorio, setRelatorio] = useState(null);
  const title = 'Pendências Financeiras';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState(
    filter[filterPath]?.dataTable?.Parametros[0]?.valor || ''
  );
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [multiSelected, setMultiSelected] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [ajusteFormaModal, setAjusteFormaModal] = useState(false);
  const [remessaModal, setRemessaModal] = useState(false);
  const [baixarExcelModal, setBaixarExcelModal] = useState(false);
  const [devolucaoChequeModal, setDevolucaoChequeModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    dataLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    boleto,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const { excelRegistros, postExcelSuccess } = useSelector(
    ({ lancamento }) => lancamento
  );

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = (filter[filterPath]?.filtros || filtros).map(
        (f) => {
          if (f.parametro === 'SIM') {
            return { ...f, conteudoi: param, conteudod: nameParam };
          }
          return f;
        }
      );
      return filterWithParam;
    }
    return filter[filterPath]?.filtros || filtros;
  }

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros: filter[filterPath]?.filtros || filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      setMultiSelected([]);
      dispatch(ResumoActions.setFilterResumo(setParamOnFilter()));

      const params = {
        Parametros: [{ nome: 'likevalue', valor: search }],
        WhereClause: getWhereClause({
          filtros: filter[filterPath]?.filtros || filtros,
          setStringExport,
        }),
        OrderClause,
        Pagina: paginacao ? Pagina : undefined,
        PorPagina: paginacao ? PorPagina : undefined,
      };
      dispatch(
        FilterActions.setFilterState({
          [filterPath]: { ...filter[filterPath], dataTable: params },
        })
      );
    }
  }

  useEffect(() => {
    if (Boolean(filter[filterPath]?.dataTable)) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: filter[filterPath]?.dataTable,
        })
      );
      getTotais();
    }
  }, [filter[filterPath]]);

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    if ((relatorio === 513 || relatorio === 672) && reportFiltros.length) {
      dispatch(
        TablesActions.postReport({
          rotina_id: relatorio,
          WhereClause: getWhereClause({
            filtros: [
              {
                ...reportFiltros[0],
                conteudoi:
                  relatorio === 513
                    ? selected?.financeiro_movimento_id
                    : selected?.documento_financeiro_id,
              },
            ],
          }),
        })
      );
      setRelatorio(null);
    }
  }, [reportFiltros]);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    getDataTable();
  }, [Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (boleto) {
      const file = new Blob([boleto], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatch(TablesActions.postBoletoReset());
    }
  }, [boleto]);

  useEffect(() => {
    if (postExcelSuccess) {
      getDataTable();
      setBaixarExcelModal(false);
    }
  }, [postExcelSuccess]);

  useEffect(() => {
    if (
      !dataLoading &&
      !headerLoading &&
      postExcelSuccess &&
      excelRegistros?.length
    ) {
      history.push(`/app/Finalizar_Venda`, {
        state: {
          financeiro_id: excelRegistros?.map((r) => r?.financeiro_id),
          operacao: 'RECEBIMENTOS',
        },
      });
      dispatch(LancamentoActions.postExcelReset());
    }
  }, [dataLoading, headerLoading, postExcelSuccess]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () =>
        param
          ? history.push(`/app/Manutenção?cod=${param}`)
          : history.push(`/app/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: checkAgrupamento(headerData),
    },
    {
      name: 'Alterar dados',
      type: 'Manutenção',
      action: () =>
        multiSelected.length ? setAjusteFormaModal(!ajusteFormaModal) : {},
      visible: true,
    },
    {
      name: 'Baixar Pendências',
      type: 'Recebimento',
      action: () => {
        const financeiro_id = multiSelected?.map((m) => m?.id);
        history.push(`/app/Finalizar_Venda`, {
          state: {
            financeiro_id,
            operacao: (filter[filterPath]?.filtros || filtros)
              ?.find((f) => f?.titulo?.toUpperCase() === 'FINANCEIRO')
              ?.conteudoi?.replace(/(['])/g, ''),
          },
        });
      },
      visible: (filter[filterPath]?.filtros || filtros).some(
        (f) => f?.titulo === 'Situação' && f?.conteudoi?.includes('PENDENTE')
      ),
    },
    {
      name: 'Baixar E-Commerce',
      type: 'Recebimento',
      action: () => setBaixarExcelModal(true),
      visible: true,
    },
    {
      name: 'Devolução de Cheque',
      type: 'Recebimento',
      action: () => {
        if (multiSelected.length) {
          setDevolucaoChequeModal(true);
        }
      },
      visible:
        (filter[filterPath]?.filtros || filtros).some(
          (f) => f?.titulo === 'Financeiro' && f?.conteudod?.includes('CHEQUES')
        ) &&
        (filter[filterPath]?.filtros || filtros).some(
          (f) => f?.titulo === 'Situação' && f?.conteudod?.includes('BAIXADO')
        ),
    },
    {
      name: 'Remessa Bancária',
      type: 'Upload',
      action: () =>
        multiSelected.length ? setRemessaModal(!remessaModal) : {},
      visible: true,
    },
    {
      name: 'Retorno de Remessa Bancária',
      type: 'Upload',
      action: () => document.getElementById('upload-midia')?.click(),
      visible: true,
    },
    {
      name: 'Enviar Sispag',
      type: 'Upload',
      action: async () => {
        if (multiSelected.length) {
          const arq = await api.post(`/sispag/gerar-gnre`, {
            financeiros: multiSelected?.map((m) => m?.id),
          });
          const element = document.createElement('a');
          const file = new Blob([arq.data], { type: 'text/plain' });
          element.href = URL.createObjectURL(file);
          element.download = 'SISPAG_REMESSA.txt';
          document.body.appendChild(element);
          element.click();
        }
      },
      visible: (filter[filterPath]?.filtros || filtros).some(
        (f) =>
          f?.titulo === 'Financeiro' && f?.conteudod?.includes('PAGAMENTOS')
      ),
    },
    {
      name: 'Retorno Sispag',
      type: 'Upload',
      action: () => document.getElementById('upload-sispag')?.click(),
      visible: true
    },
    {
      name: 'Boleto',
      type: 'Imprimir',
      action: () => {
        if (multiSelected.length) {
          dispatch(
            TablesActions.postBoleto({
              rotina_id: 736,
              financeiro: multiSelected.map((i) => ({
                id: i?.id,
              })),
            })
          );
        }
      },
      visible: true,
    },
    {
      name: 'Pendências Por Vencimento',
      type: 'Imprimir',
      action: () => {
        setRelatorio(604);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Pendências Por Entidade',
      type: 'Imprimir',
      action: () => {
        setRelatorio(732);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Baixas por data',
      type: 'Imprimir',
      action: () => {
        setRelatorio(605);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Baixas por Entidade',
      type: 'Imprimir',
      action: () => {
        setRelatorio(708);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Posição por Entidade',
      type: 'Imprimir',
      action: () => {
        setRelatorio(806);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Extrato de Débito',
      type: 'Imprimir',
      action: () => {
        setRelatorio(701);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Movimento Financeiro',
      type: 'Imprimir',
      action: () => {
        setRelatorio(781);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Alínea de Cheques',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Alínea%20de%20devolução%20de%20cheques&rot=719&csql=755`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Condição de Pagamento',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Condição%20de%20pagamento&rot=492&csql=374`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Forma de Pagamento',
      type: 'Nova',
      action: () => window.open(`/app/Financeiro/Formadepagamento`, '_blank'),
      visible: true,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Documento',
          action: (item) =>
            history.push(`/app/Cadastros/Documento?id=${item?.docto}`),
          visible: true,
        },
        {
          name: 'Contatos',
          varName: 'Contato',
          action: (item) =>
            history.push(
              `/app/Financeiro/Pendências/Contatos?id=${
                item?.id
              }&name=${'Documento: ' +
                item.docto +
                ' - ' +
                item.especie +
                ' - ' +
                item.natureza +
                ' - Entidade: ' +
                item.entidade +
                ' - Valor: ' +
                item.vlrbaixarc}`
            ),
          visible: true,
        },
        {
          name: 'Outros valores',
          varName: 'Preço',
          action: (item) =>
            history.push(
              `/app/Financeiro/Pendências/OutrosValores?id=${
                item?.id
              }&name=${'Documento: ' +
                item.docto +
                ' - ' +
                item.especie +
                ' - ' +
                item.natureza +
                ' - Entidade: ' +
                item.entidade +
                ' - Valor: ' +
                item.vlrbaixarc}`
            ),
          visible: true,
        },
        {
          name: 'Movimento',
          varName: 'Movimentar',
          action: (item) =>
            history.push(
              `/app/Financeiro/MovimentoFinanceiro?id=${
                item?.financeiro_movimento_id
              }&name=${'Documento: ' +
                item.docto +
                ' - ' +
                item.especie +
                ' - ' +
                item.natureza +
                ' - Entidade: ' +
                item.entidade +
                ' - Valor: ' +
                item.vpago}`
            ),
          funcVis: (item) => item?.financeiro_movimento_id > 0,
        },
        {
          name: 'Recibo',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(513);
          },
          visible: true,
        },
        {
          name: 'Duplicata',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(672);
          },
          visible: true,
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <FilterAccordion filtros={filter[filterPath]?.filtros || filtros} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                selected={multiSelected}
                setSelected={setMultiSelected}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
                fill="PENDENCIAS"
              />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <AjusteFormaPgtoModal
                open={ajusteFormaModal}
                setOpen={setAjusteFormaModal}
                selected={multiSelected}
                filtros={filter[filterPath]?.filtros || filtros}
                rotina={rotina}
              />
              <RemessaModal
                open={remessaModal}
                setOpen={setRemessaModal}
                selected={multiSelected}
                filtros={filter[filterPath]?.filtros || filtros}
                rotina={rotina}
              />
              <BaixarExcelModal
                open={baixarExcelModal}
                setOpen={setBaixarExcelModal}
              />
              <DevolucaoChequeModal
                open={devolucaoChequeModal}
                setOpen={setDevolucaoChequeModal}
                selected={multiSelected}
              />
              <input
                id="upload-midia"
                hidden
                type="file"
                onChange={async ({ target }) => {
                  if (target?.files[0]) {
                    const formData = new FormData();
                    formData.append('file', target.files[0]);
                    const { data } = await api.post(
                      `/financeiro-importa-remessa`,
                      formData,
                      {
                        headers: { 'Content-Type': 'multipart/form-data' },
                      }
                    );
                    history.push(`/app/Finalizar_Venda`, {
                      state: {
                        financeiro_id:
                          data?.Financeiro?.map((f) => f?.financeiro_id) || [],
                        operacao: data?.financeiro,
                      },
                    });
                  }
                  target.value = '';
                }}
              />
              <input
                id="upload-sispag"
                hidden
                type="file"
                onChange={async ({ target }) => {
                  if (target?.files[0]) {
                    const formData = new FormData();
                    formData.append('arquivo', target.files[0]);
                    const { data } = await api.post(
                      `/sispag/retorno-gnre`,
                      formData,
                      {
                        headers: { 'Content-Type': 'multipart/form-data' },
                      }
                    );
                    history.push(`/app/Finalizar_Venda`, {
                      state: {
                        financeiro_id:
                          data?.Financeiro?.map((f) => f?.financeiro_id) || [],
                        operacao: data?.financeiro,
                      },
                    });
                  }
                  target.value = '';
                }}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Pendências;
