import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import moment from 'moment';

const OfxConc = ({ open, setOpen, selected, loading, conta }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [lancamento, setLancamento] = useState(null);

  async function loadItems() {
    const { data } = await api.post(`/csql/967`, {
      Parametros: [
        {
          nome: ':cxconta_id',
          valor: conta,
        },
      ],
    });
    setItems(data?.dados || []);
  }

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);
console.log(selected)
  const onSubmit = () => {
    const data = {
      conciliado: 'SIM',
      dtlanc: moment(selected?.data).format('YYYY-MM-DD'),
      Lancamento: [
        {
          cxlancto_id: lancamento?.value,
          documentocx: selected?.documento,
        },
      ],
    };
    dispatch(LancamentoActions.putFinConci(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      PaperProps={{ style: { minWidth: '90%' } }}
    >
      <DialogTitle>Conciliar Lançamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectAuto
              label="Lancamento"
              items={items || []}
              value={lancamento}
              onChange={(v) => setLancamento(v)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="primary" disabled={loading}>
          Confirmar
        </Button>
        <Button
          onClick={() => setOpen(null)}
          color="secondary"
          disabled={loading}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OfxConc;
