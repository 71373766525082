import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  InputLabel,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import { Creators as EntidadesActions } from 'store/ducks/Entidades';
import { Creators as MidiaActions } from 'store/ducks/Midia';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useQuery, getTypeMidia } from 'util/index';

const styles = (theme) => ({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  addItem: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #c5c5c5',
  },
  addIcon: {
    width: '50px',
    height: '50px',
  },
  addText: {
    fontWeight: 'bold',
    color: '#888',
  },
  root: {
    flex: 1,
  },
  label: {
    display: 'flex',
    flex: 1,
  },
  tileContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Mídias = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const id = query.get('id');
  const nomeItem = query.get('name');
  const { midiaLoading, midia, putMidiaLoading, putMidiaSuccess } = useSelector(
    ({ entidades }) => entidades
  );
  const { postLoading, postMidiaId } = useSelector(({ midia }) => midia);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    dispatch(EntidadesActions.getMidia(id));
  }, []);

  useEffect(() => {
    if (putMidiaSuccess) {
      dispatch(EntidadesActions.putMidiaReset());
      dispatch(EntidadesActions.getMidia(id));
    }
  }, [putMidiaSuccess]);

  useEffect(() => {
    if (postMidiaId) {
      var novaMidia = [
        ...midia,
        {
          entidade_regs_id: Number(id),
          tipo: midia.length ? 'SECUNDARIA' : 'PRINCIPAL',
          midia_id: postMidiaId,
          descricao: description,
        },
      ];
      dispatch(
        EntidadesActions.putMidia({ data: { registros: novaMidia }, id })
      );
      dispatch(MidiaActions.postMidiaReset());
    }
  }, [postMidiaId]);

  function onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('tipo', getTypeMidia(event.target.files[0]));
      formData.append('tipo_armazenamento', 'INTERNET');
      formData.append('midia', event.target.files[0]);
      setDescription(event.target.files[0].name.split('.')[0]);
      dispatch(MidiaActions.postMidia(formData));
    }
  }

  function onDelete(index) {
    midia[index].midia_id = 0;
    dispatch(EntidadesActions.putMidia({ data: { registros: midia }, id }));
  }

  return (
    <div className="app-wrapper">
      <HeaderPage title={`Mídias - ${nomeItem}`} history={history} />
      <Card className="shadow">
        <CardContent>
          {midiaLoading || putMidiaLoading || postLoading ? (
            <Loading />
          ) : (
            <div className={classes.root}>
              <GridList cellHeight={180} className={classes.gridList}>
                {midia.map((tile, index) => {
                  if (
                    tile.tipo_midia === 'IMAGEM' ||
                    tile.tipo_midia === 'PDF' ||
                    tile.tipo_midia === 'VIDEO'
                  ) {
                    return (
                      <GridListTile key={tile.id}>
                        {tile.tipo_midia === 'IMAGEM' && (
                          <img
                            src={tile.url}
                            alt={tile.descricao}
                            className={classes.img}
                          />
                        )}
                        {tile.tipo_midia === 'VIDEO' && (
                          <div className={classes.tileContainer}>
                            <iframe src={tile.url} allowFullScreen />
                          </div>
                        )}
                        {tile.tipo_midia === 'PDF' && (
                          <div className={classes.tileContainer}>
                            <IconButton onClick={() => window.open(tile.url)}>
                              <PictureAsPdf fontSize="large" color="action" />
                            </IconButton>
                          </div>
                        )}
                        <GridListTileBar
                          title={tile.descricao}
                          actionIcon={
                            <IconButton
                              className={classes.icon}
                              onClick={() => onDelete(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        />
                      </GridListTile>
                    );
                  }
                })}

                <InputLabel className={classes.label} htmlFor="addImageButton">
                  <GridListTile
                    key={'add'}
                    classes={{ tile: classes.addItem, root: classes.root }}
                  >
                    <AddRoundedIcon
                      color="primary"
                      className={classes.addIcon}
                    />
                    <span className={classes.addText}>ADICIONAR</span>
                  </GridListTile>
                </InputLabel>
              </GridList>
            </div>
          )}
        </CardContent>
      </Card>
      <input
        style={{ display: 'none' }}
        id="addImageButton"
        type="file"
        onChange={(e) => onImageChange(e)}
      />
    </div>
  );
};

export default withStyles(styles)(Mídias);
