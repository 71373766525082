import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  text: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
});

const FilterDescription = ({ text, classes }) => {
  const parseText = text ? text.replace(/'/g, '') : text;
  return <span className={classes.text}>{parseText}</span>;
};

export default withStyles(styles)(FilterDescription);
