import React from 'react';

import NotificationItem from './NotificationItem';
import CustomScrollbars from 'util/CustomScrollbars';

const AppNotification = ({ notificacoes = [] }) => {
  return (
    <CustomScrollbars
      className="messages-list scrollbar"
      style={{ height: 280 }}
    >
      <ul className="list-unstyled">
        {notificacoes.map((notification, index) => (
          <NotificationItem key={index} notification={notification} />
        ))}
      </ul>
    </CustomScrollbars>
  );
};

export default AppNotification;
