import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as MidiaActions,
  Types as MidiaTypes,
} from 'store/ducks/Midia';
import api from '../../util/api';

function* postMidia({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/midia`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (status === 200 || status === 201) {
      yield put(MidiaActions.postMidiaSuccess(data.id));
    } else {
      yield put(MidiaActions.postMidiaFail());
    }
  } catch (error) {
    yield put(MidiaActions.postMidiaFail());
  }
}

function* getMidia({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/midia/${payload}`);
    if (status === 200 || status === 201) {
      yield put(MidiaActions.getMidiaSuccess(data?.midia));
    } else {
      yield put(MidiaActions.getMidiaError());
    }
  } catch (error) {
    yield put(MidiaActions.getMidiaError());
  }
}

function* deleteMidia({ payload }) {
  try {
    const { status } = yield call(api.delete, `/midia/${payload}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(MidiaActions.deleteMidiaSuccess());
    } else {
      yield put(MidiaActions.deleteMidiaError());
    }
  } catch (error) {
    yield put(MidiaActions.deleteMidiaError());
  }
}

function* postMidiaWatcher() {
  yield takeLatest(MidiaTypes.POST_MIDIA, postMidia);
}

function* getMidiaWatcher() {
  yield takeLatest(MidiaTypes.GET_MIDIA, getMidia);
}

function* deleteMidiaWatcher() {
  yield takeLatest(MidiaTypes.DELETE_MIDIA, deleteMidia);
}

export default function* rootSaga() {
  yield all([
    fork(postMidiaWatcher),
    fork(getMidiaWatcher),
    fork(deleteMidiaWatcher),
  ]);
}
