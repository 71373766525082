import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import SelectAuto from 'components/SelectAuto';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import PaymentModal from 'components/Modals/PaymentModal';
import ParcelaModal from 'components/Modals/ParcelaModal';
import api from 'util/api';
import { findOnArray, setDecimal, useQuery, getWhereClause } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as MidiaActions } from 'store/ducks/Midia';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  textGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '0 0.5rem',
  },
  subtitle: {
    padding: '0 0.5rem',
  },
  modal: {
    maxWidth: '80%',
  },
  acc: {
    cursor: 'default',
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const Pagamento = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [items, setItems] = useState([]);
  const [formValue, setFormValue] = useState({ condPgto: '', formaPgto: '' });
  const [localLoading, setLocalLoading] = useState(true);
  const [relatorio, setRelatorio] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openParcelaModal, setOpenParcelaModal] = useState(null);
  const [selected, setSelected] = useState(null);
  const param = query.get('id');
  const steps = ['Documento', 'Itens', 'Pagamento', 'Entrega'];
  const {
    documento,
    loading,
    postFinancSuccess,
    postParcelaSuccess,
    putParcelaSuccess,
    deleteParcelaSuccess,
  } = useSelector(({ documento }) => documento);
  const { reportFiltros, reportUrl, reportId } = useSelector(
    ({ tables }) => tables
  );

  const financeiros = documento?.Financeiro || [];
  let totalFin = 0;
  financeiros.map((f) => {
    totalFin = totalFin + f.valor;
  });
  const itemFormaPgto =
    documento?.Documento?.doc_grupo === 'SAIDA' ? items[5] : items[6];

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([642, 621, 633, 616, 640, 646, 647, 632]);
    if (param) {
      dispatch(DocumentoActions.getDocumento(param));
    }
  }, []);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    if (documento && items[4]) {
      setFormValue({
        ...formValue,
        condPgto: findOnArray(
          documento?.Saida?.condicao_pagamento_id,
          items[4]
        ),
      });
    }
  }, [documento, items]);

  useEffect(() => {
    if (reportFiltros.length && relatorio) {
      dispatch(
        TablesActions.postReport({
          rotina_id: relatorio,
          WhereClause: getWhereClause({
            filtros: [{ ...reportFiltros[0], conteudoi: selected?.id }],
          }),
        })
      );
      setRelatorio(null);
      dispatch(TablesActions.getReportReset());
    }
  }, [reportFiltros]);

  useEffect(() => {
    if (reportUrl) {
      window.open(reportUrl);
      if (reportId) {
        setTimeout(() => {
          dispatch(MidiaActions.deleteMidia(reportId));
          dispatch(TablesActions.postReportReset());
        }, 3000);
      } else {
        dispatch(TablesActions.postReportReset());
      }
    }
  }, [reportUrl]);

  useEffect(() => {
    if (deleteParcelaSuccess) {
      dispatch(DocumentoActions.deleteParcelaReset());
      dispatch(DocumentoActions.getDocumento(param));
    }
    if (postParcelaSuccess) {
      dispatch(DocumentoActions.postParcelaReset());
      dispatch(DocumentoActions.getDocumento(param));
      setOpenParcelaModal(false);
    }
    if (putParcelaSuccess) {
      dispatch(DocumentoActions.putParcelaReset());
      dispatch(DocumentoActions.getDocumento(param));
      setOpenParcelaModal(false);
    }
    if (postFinancSuccess) {
      dispatch(DocumentoActions.postFinanceiroReset());
      dispatch(DocumentoActions.getDocumento(param));
      setOpenModal(false);
    }
  }, [
    deleteParcelaSuccess,
    postFinancSuccess,
    postParcelaSuccess,
    putParcelaSuccess,
  ]);

  if (localLoading || loading || !documento) {
    return <Loading />;
  }

  return (
    <div className="app-wrapper">
      <HeaderPage title="Pagamento do Documento" history={param && history} />
      <Card className="shadow">
        <CardContent>
          <Grid container>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Espécie:</span>
              <span> {documento?.Documento?.doc_especie}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Natureza da Operação:</span>
              <span>{documento?.Documento?.doc_naturezaop}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Documento:</span>
              <span> {documento?.Documento?.id}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Entidade:</span>
              <span> {documento?.Documento?.doc_nome}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Status:</span>
              <span> {documento?.Documento?.doc_status}</span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className="shadow" style={{ marginTop: '1rem' }}>
        <CardContent>
          <Stepper activeStep={2} alternativeLabel className="p-0">
            {steps.map((label) => {
              return (
                <Step
                  key={label}
                  onClick={() => {
                    if (param) {
                      history.push(
                        `/app/Cadastros/Documento${
                          label !== 'Documento' ? `/${label}` : ''
                        }?id=${param}`
                      );
                    }
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </CardContent>
      </Card>
      <Card className="shadow" style={{ marginTop: '1rem' }}>
        <CardContent>
          <div className="my-4">
            {documento?.Documento?.sleitura !== 'SIM' && (
              <>
                <Grid container className="my-4">
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Condição de Pagamento"
                      items={items[4]}
                      value={formValue.condPgto}
                      onChange={(v) =>
                        setFormValue({ ...formValue, condPgto: v })
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Forma de Pagamento"
                      items={itemFormaPgto}
                      value={formValue.formaPgto}
                      onChange={(v) =>
                        setFormValue({ ...formValue, formaPgto: v })
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="4"
                    md="4"
                    lg="4"
                    className={classes.textGrid}
                  >
                    <h4>
                      Total: R$ {setDecimal(documento?.Totais?.documento, 2)}
                    </h4>
                    <h4>
                      Total em Haver: R${' '}
                      {setDecimal(documento?.Documento?.haverdisp || '0', 2)}
                    </h4>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  className="jr-btn mb-4"
                  onClick={() =>
                    formValue.formaPgto &&
                    formValue.condPgto &&
                    setOpenModal(!openModal)
                  }
                >
                  PARCELAMENTO
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="jr-btn mb-4"
                  onClick={() =>
                    dispatch(
                      DocumentoActions.postFinanceiro({
                        id: documento?.Documento?.id,
                        data: {
                          condicao_pagamento_id: formValue?.condPgto?.value,
                        },
                      })
                    )
                  }
                >
                  HISTÓRICO
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="jr-btn mb-4"
                  onClick={() => setOpenParcelaModal(!openParcelaModal)}
                >
                  ADICIONAR PARCELA
                </Button>
              </>
            )}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ações</TableCell>
                    <TableCell>Parcela</TableCell>
                    <TableCell>Vencimento</TableCell>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Observação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {financeiros.map((item) => (
                    <TableRow>
                      <TableCell>
                        {documento?.Documento?.sleitura !== 'SIM' && (
                          <>
                            <IconButton
                              onClick={() =>
                                dispatch(
                                  DocumentoActions.deleteParcela(item?.id)
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpenParcelaModal(!openParcelaModal);
                                setSelected(item);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                        <IconButton
                          onClick={() => {
                            setSelected(item);
                            setRelatorio(672);
                          }}
                        >
                          <PrintIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.parcela}</TableCell>
                      <TableCell>
                        {item.dtvenc &&
                          moment(item.dtvenc, 'YYYY-MM-DD').isValid() &&
                          moment(item.dtvenc, 'YYYY-MM-DD').format(
                            'DD/MM/YYYY'
                          )}
                      </TableCell>
                      <TableCell>
                        {
                          itemFormaPgto.find(
                            (i) => i.value === item.forma_pagto_id
                          )?.label
                        }
                      </TableCell>
                      <TableCell>{setDecimal(item.valor, 2)}</TableCell>
                      <TableCell>{item.observacao}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>TOTAL</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>{setDecimal(totalFin, 2)}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <PaymentModal
              open={openModal}
              setOpen={setOpenModal}
              condPag={formValue.condPgto}
              formaPag={formValue.formaPgto}
              documento={documento}
            />
            <ParcelaModal
              open={openParcelaModal}
              setOpen={setOpenParcelaModal}
              dropPgto={itemFormaPgto}
              documento={documento}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Pagamento);
