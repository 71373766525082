import React from 'react';
import { useSelector } from 'react-redux';
import DashChart from 'components/DashChart';

const Dashboard = ({ match }) => {
  const { midia } = useSelector(({ midia }) => midia);
  const { dashboard } = useSelector(({ auth }) => auth);

  return (
    <div className="app-wrapper text-center">
      {!!dashboard.length ? (
        <DashChart />
      ) : (
        <>
          {midia && (
            <img
              src={midia?.url || `data:image/png;base64, ${midia?.data}`}
              style={{ maxWidth: '100%' }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
