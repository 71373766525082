import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as DocumentoActions,
  Types as DocumentoTypes,
} from 'store/ducks/Documento';
import api from '../../util/api';

function* getDocumento({ payload }) {
  try {
    const { data, status } = yield call(api.get, `/documento/${payload}`);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getDocumentoSuccess(data.doc));
    } else {
      yield put(DocumentoActions.getDocumentoError());
    }
  } catch (error) {
    yield put(DocumentoActions.getDocumentoError());
  }
}

function* deleteDocumento({ payload }) {
  try {
    const { status } = yield call(api.delete, `/documento/${payload}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(DocumentoActions.deleteDocumentoSuccess());
    } else {
      yield put(DocumentoActions.deleteDocumentoError());
    }
  } catch (error) {
    yield put(DocumentoActions.deleteDocumentoError());
  }
}

function* getItens({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/csql/2`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getItensSuccess(data.dados || []));
    } else {
      yield put(DocumentoActions.getItensError());
    }
  } catch (error) {
    yield put(DocumentoActions.getItensError());
  }
}

function* postCapa({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/documento`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postCapaSuccess(data?.id));
    } else {
      yield put(DocumentoActions.postCapaError());
    }
  } catch (error) {
    yield put(DocumentoActions.postCapaError());
  }
}

function* postReposicao({ payload }) {
  try {
    const { status } = yield call(api.post, `/suprimentos-reposicao`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postReposicaoSuccess());
    } else {
      yield put(DocumentoActions.postReposicaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postReposicaoError());
  }
}

function* postAprovacao({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.post, `/documento-aprovacao/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postAprovacaoSuccess());
    } else {
      yield put(DocumentoActions.postAprovacaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postAprovacaoError());
  }
}

function* postCustoDocumento({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.post, `/documento-custo/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postCustoSuccess());
    } else {
      yield put(DocumentoActions.postCustoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postCustoError());
  }
}

function* postRegistroDocumento({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.post, `/documento-registro/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postRegistroSuccess());
    } else {
      yield put(DocumentoActions.postRegistroError());
    }
  } catch (error) {
    yield put(DocumentoActions.postRegistroError());
  }
}

function* postStatus({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.post, `/documento-status/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postStatusSuccess());
    } else {
      yield put(DocumentoActions.postStatusError());
    }
  } catch (error) {
    yield put(DocumentoActions.postStatusError());
  }
}

function* getProdDoc({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/csql/609`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getProdDocSuccess(data.dados));
    } else {
      yield put(DocumentoActions.getProdDocError());
    }
  } catch (error) {
    yield put(DocumentoActions.getProdDocError());
  }
}

function* getFields({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/csql/711`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getFieldsSuccess(data.dados));
    } else {
      yield put(DocumentoActions.getFieldsError());
    }
  } catch (error) {
    yield put(DocumentoActions.getFieldsError());
  }
}

function* getCusto({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/csql/708`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getCustoSuccess(data.dados));
    } else {
      yield put(DocumentoActions.getCustoError());
    }
  } catch (error) {
    yield put(DocumentoActions.getCustoError());
  }
}

function* getPlanoConta({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/csql/606`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getPlanoContaSuccess(data.dados || []));
    } else {
      yield put(DocumentoActions.getPlanoContaError());
    }
  } catch (error) {
    yield put(DocumentoActions.getPlanoContaError());
  }
}

function* putDocGerar({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/documentoitem-gerar`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.putDocGerarSuccess(data.doc));
    } else {
      yield put(DocumentoActions.putDocGerarError());
    }
  } catch (error) {
    yield put(DocumentoActions.putDocGerarError());
  }
}

function* postDocMovimentar({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/documento-movimentacao`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postDocMovimentarSuccess(data.doc));
    } else {
      yield put(DocumentoActions.postDocMovimentarError());
    }
  } catch (error) {
    yield put(DocumentoActions.postDocMovimentarError());
  }
}

function* getDocItem({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/documentoitem/${payload}`);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getDocItemSuccess(data?.Item));
    } else {
      yield put(DocumentoActions.getDocItemError());
    }
  } catch (error) {
    yield put(DocumentoActions.getDocItemError());
  }
}

function* postDocItem({ payload }) {
  try {
    const { status } = yield call(api.post, `/documentoitem`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postDocItemSuccess());
    } else {
      yield put(DocumentoActions.postDocItemError());
    }
  } catch (error) {
    yield put(DocumentoActions.postDocItemError());
  }
}

function* deleteDocItem({ payload }) {
  try {
    const { status } = yield call(api.delete, `/documentoitem/${payload}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(DocumentoActions.deleteDocItemSuccess());
    } else {
      yield put(DocumentoActions.deleteDocItemError());
    }
  } catch (error) {
    yield put(DocumentoActions.deleteDocItemError());
  }
}

function* putDocItem({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/documentoitem/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.putDocItemSuccess());
    } else {
      yield put(DocumentoActions.putDocItemError());
    }
  } catch (error) {
    yield put(DocumentoActions.putDocItemError());
  }
}

function* attPrecoItem({ payload }) {
  try {
    const { status } = yield call(api.put, `/documento-preco/${payload}`);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.attPrecoItemSuccess());
    } else {
      yield put(DocumentoActions.attPrecoItemError());
    }
  } catch (error) {
    yield put(DocumentoActions.attPrecoItemError());
  }
}

function* getMovimentacao({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/csql/240`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getMovimentacaoSuccess(data.dados || []));
    } else {
      yield put(DocumentoActions.getMovimentacaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.getMovimentacaoError());
  }
}

function* postOutros({ payload }) {
  try {
    const { status } = yield call(api.put, `/documento-outros`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postItemOutrosSuccess());
    } else {
      yield put(DocumentoActions.postItemOutrosError());
    }
  } catch (error) {
    yield put(DocumentoActions.postItemOutrosError());
  }
}

function* postCfo({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.put, `/documento-cfo/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postCfoSuccess());
    } else {
      yield put(DocumentoActions.postCfoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postCfoError());
  }
}

function* postTributo({ payload }) {
  try {
    const { status } = yield call(api.post, `/documento-tributo`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postTributoSuccess());
    } else {
      yield put(DocumentoActions.postTributoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postTributoError());
  }
}

function* postXML({ payload }) {
  try {
    const { status } = yield call(api.post, `/documento-importxml`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postXMLSuccess());
    } else {
      yield put(DocumentoActions.postXMLError());
    }
  } catch (error) {
    yield put(DocumentoActions.postXMLError());
  }
}

function* exportXML({ payload }) {
  try {
    const { status } = yield call(api.post, `/documento-exportarxml`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.exportXmlSuccess());
    } else {
      yield put(DocumentoActions.exportXmlReset());
    }
  } catch (error) {
    yield put(DocumentoActions.exportXmlReset());
  }
}

function* postFinanceiro({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/documento-financeiro/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postFinanceiroSuccess());
    } else {
      yield put(DocumentoActions.postFinanceiroError());
    }
  } catch (error) {
    yield put(DocumentoActions.postFinanceiroError());
  }
}

function* postParcela({ payload }) {
  try {
    const { status } = yield call(api.post, `/documentofin`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postParcelaSuccess());
    } else {
      yield put(DocumentoActions.postParcelaError());
    }
  } catch (error) {
    yield put(DocumentoActions.postParcelaError());
  }
}

function* putParcela({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/documentofin/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.putParcelaSuccess());
    } else {
      yield put(DocumentoActions.putParcelaError());
    }
  } catch (error) {
    yield put(DocumentoActions.putParcelaError());
  }
}

function* deleteParcela({ payload }) {
  try {
    const { status } = yield call(api.delete, `/documentofin/${payload}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(DocumentoActions.deleteParcelaSuccess());
    } else {
      yield put(DocumentoActions.deleteParcelaError());
    }
  } catch (error) {
    yield put(DocumentoActions.deleteParcelaError());
  }
}

function* postEntrega({ payload }) {
  try {
    const { status } = yield call(api.post, `/documentotrp`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postEntregaSuccess());
    } else {
      yield put(DocumentoActions.postEntregaError());
    }
  } catch (error) {
    yield put(DocumentoActions.postEntregaError());
  }
}

function* putEntrega({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/documentotrp/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.putEntregaSuccess());
    } else {
      yield put(DocumentoActions.putEntregaError());
    }
  } catch (error) {
    yield put(DocumentoActions.putEntregaError());
  }
}

function* deleteEntrega({ payload }) {
  try {
    const { status } = yield call(api.delete, `/documentotrp/${payload}`);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.deleteEntregaSuccess());
    } else {
      yield put(DocumentoActions.deleteEntregaError());
    }
  } catch (error) {
    yield put(DocumentoActions.deleteEntregaError());
  }
}

function* imprimirDocumento({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/relatorio-documentos`,
      payload,
      {
        responseType: 'blob',
      }
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.imprimirDocumentoSuccess(data));
    } else {
      yield put(DocumentoActions.imprimirDocumentoError());
    }
  } catch (error) {
    yield put(DocumentoActions.imprimirDocumentoError());
  }
}

function* userPerfil({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/user-perfil-comercial`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.userPerfilSuccess(data?.perfil));
    } else {
      yield put(DocumentoActions.userPerfilError());
    }
  } catch (error) {
    yield put(DocumentoActions.userPerfilError());
  }
}

function* postLancamento({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro-cxlanctos`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postLancamentoSuccess());
    } else {
      yield put(DocumentoActions.postLancamentoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postLancamentoError());
  }
}

function* getQtdMov({ payload }) {
  const { id, loc } = payload;
  try {
    const { data } = yield call(api.post, `/csql/800`, {
      Parametros: [
        { nome: ':documento_item_id', valor: id },
        { nome: ':loc', valor: loc },
      ],
    });
    yield put(DocumentoActions.getQtdMovSuccess(data?.dados || []));
  } catch (error) {
    yield put(DocumentoActions.getQtdMovError());
  }
}

function* postEmissao({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/documento-saida`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postEmissaoSuccess(data));
    } else {
      yield put(DocumentoActions.postEmissaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postEmissaoError());
  }
}

function* postManifestacao({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/documento-dfe/manifestacaoDFe/nfe/buscarDocumentos`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postManifestacaoSuccess());
    } else {
      yield put(DocumentoActions.postManifestacaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postManifestacaoError());
  }
}

function* getQualidade({ payload }) {
  try {
    const { data } = yield call(api.get, `/documentoitem_qualidade/${payload}`);
    yield put(DocumentoActions.getQualidadeSuccess(data?.qia || []));
  } catch (error) {
    yield put(DocumentoActions.getQualidadeError());
  }
}

function* postResposta({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/documentoitem_qualidade_resp`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postRespostaSuccess());
    } else {
      yield put(DocumentoActions.postRespostaError());
    }
  } catch (error) {
    yield put(DocumentoActions.postRespostaError());
  }
}

function* postConsultasNfe({ payload }) {
  const { body, path } = payload;
  try {
    const { status, data } = yield call(
      api.post,
      `/documento-dfe/${path}`,
      body
    );
    if (status === 200 || status === 201) {
      yield put(
        DocumentoActions.postConsultasNfeSuccess(data?.retorno || null)
      );
    } else {
      yield put(DocumentoActions.postConsultasNfeError());
    }
  } catch (error) {
    yield put(DocumentoActions.postConsultasNfeError());
  }
}

function* postCorrecao({ payload }) {
  const endpoint = payload?.documentoid
    ? '/relatorio-cce'
    : '/documento-dfe/nfe/enviar-cce';
  try {
    const { data, status } = yield call(api.post, endpoint, payload, {
      responseType: 'blob',
    });
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postCorrecaoSuccess(data));
    } else {
      yield put(DocumentoActions.postCorrecaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postCorrecaoError());
  }
}

function* postInutilizacao({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/documento-dfe/nfe/inutilizar-dfe`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postInutilizacaoSuccess());
    } else {
      yield put(DocumentoActions.postInutilizacaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postInutilizacaoError());
  }
}

function* sendMail({ payload }) {
  try {
    const { status } = yield call(api.post, `/enviarmail`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.sendMailSuccess());
    } else {
      yield put(DocumentoActions.sendMailError());
    }
  } catch (error) {
    yield put(DocumentoActions.sendMailError());
  }
}

function* getInfoTributo({ payload }) {
  const { documento_item_id, documento_id } = payload;
  try {
    const { status, data } = yield call(api.post, `/csql/590`, {
      WhereClause: `di.id = ${documento_item_id}`,
      Parametros: [{ nome: ':documento_id', valor: documento_id }],
    });
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getInfoTributoSuccess(data.dados || []));
    } else {
      yield put(DocumentoActions.getInfoTributoError());
    }
  } catch (error) {
    yield put(DocumentoActions.getInfoTributoError());
  }
}

function* postFaturacao({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/documento-faturarcontrato`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postFaturacaoSuccess());
    } else {
      yield put(DocumentoActions.postFaturacaoError());
    }
  } catch (error) {
    yield put(DocumentoActions.postFaturacaoError());
  }
}

function* getDadosAdc({ payload }) {
  try {
    const { status, data } = yield call(
      api.put,
      `/documento-dadosadc/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getDadosAdcSuccess(data?.texto));
    } else {
      yield put(DocumentoActions.getDadosAdcReset());
    }
  } catch (error) {
    yield put(DocumentoActions.getDadosAdcReset());
  }
}

function* postNfe({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/documento-dfe/previsualizarnfe/${payload}`,
      {
        responseType: 'blob',
      }
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postNfeSuccess(data));
    } else {
      yield put(DocumentoActions.postNfeReset());
    }
  } catch (error) {
    yield put(DocumentoActions.postNfeReset());
  }
}

function* postItemOrigem({ payload }) {
  try {
    const { status } = yield call(api.post, `/documentoitemorigem`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postItemOrigemSuccess());
    } else {
      yield put(DocumentoActions.postItemOrigemReset());
    }
  } catch (error) {
    yield put(DocumentoActions.postItemOrigemReset());
  }
}

function* postBxOrigem({ payload }) {
  try {
    const { status } = yield call(api.post, `/documento-bxorigem/${payload}`);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postBxOrigemSuccess());
    } else {
      yield put(DocumentoActions.postBxOrigemReset());
    }
  } catch (error) {
    yield put(DocumentoActions.postBxOrigemReset());
  }
}

function* getConferencia({ payload }) {
  try {
    const { status } = yield call(api.get, `/documento-conferencia/${payload}`);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getConferenciaSuccess());
    } else {
      yield put(DocumentoActions.getConferenciaReset());
    }
  } catch (error) {
    yield put(DocumentoActions.getConferenciaReset());
  }
}

function* postStatusAnt({ payload }) {
  try {
    const { status } = yield call(api.post, `/documento-statusant/${payload}`);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postStatusAntSuccess());
    } else {
      yield put(DocumentoActions.postStatusAntReset());
    }
  } catch (error) {
    yield put(DocumentoActions.postStatusAntReset());
  }
}

function* postExportaInutilizadas({ payload }) {
  try {
    const { cnpj, ano } = payload;
    const { data, status } = yield call(
      api.post,
      `/documento-dfe/exportar-nfe-inut/${cnpj}/${ano}`
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postExportaInutilizadasSuccess());
    } else {
      yield put(DocumentoActions.postExportaInutilizadasReset());
    }
  } catch (error) {
    yield put(DocumentoActions.postExportaInutilizadasReset());
  }
}

function* postCarregamento({ payload }) {
  try {
    const { status } = yield call(api.post, `/documento-carregamento`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postCarregamentoSuccess());
    } else {
      yield put(DocumentoActions.postCarregamentoReset());
    }
  } catch (error) {
    yield put(DocumentoActions.postCarregamentoReset());
  }
}

function* getMidia({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/documento-histmid/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.getMidiaSuccess(data.registros));
    } else {
      yield put(DocumentoActions.getMidiaReset());
    }
  } catch (error) {
    yield put(DocumentoActions.getMidiaReset());
  }
}

function* putMidia({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.put, `/documento-histmid/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.putMidiaSuccess());
    } else {
      yield put(DocumentoActions.putMidiaReset());
    }
  } catch (error) {
    yield put(DocumentoActions.putMidiaReset());
  }
}

function* postDocumentoGerar({ payload }) {
  try {
    const { status } = yield call(api.post, `/documento-gerar`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.postDocumentoGerarSuccess());
    } else {
      yield put(DocumentoActions.postDocumentoGerarReset());
    }
  } catch (error) {
    yield put(DocumentoActions.postDocumentoGerarReset());
  }
}

function* imprimirDanfeEtiqueta({ payload }) {
  try {
    const { status } = yield call(api.post, `/imprime-danfe-simp`, payload);
    if (status === 200 || status === 201) {
      yield put(DocumentoActions.imprimirDanfeEtiquetaSuccess());
    } else {
      yield put(DocumentoActions.imprimirDanfeEtiquetaReset());
    }
  } catch (error) {
    yield put(DocumentoActions.imprimirDanfeEtiquetaReset());
  }
}

function* getDocumentoWatcher() {
  yield takeLatest(DocumentoTypes.GET_DOCUMENTO, getDocumento);
}

function* deleteDocumentoWatcher() {
  yield takeLatest(DocumentoTypes.DELETE_DOCUMENTO, deleteDocumento);
}

function* getItensWatcher() {
  yield takeLatest(DocumentoTypes.GET_ITENS, getItens);
}

function* postCapaWatcher() {
  yield takeLatest(DocumentoTypes.POST_CAPA, postCapa);
}

function* postReposicaoWatcher() {
  yield takeLatest(DocumentoTypes.POST_REPOSICAO, postReposicao);
}

function* postAprovacaoWatcher() {
  yield takeLatest(DocumentoTypes.POST_APROVACAO, postAprovacao);
}

function* postCustoDocumentoWatcher() {
  yield takeLatest(DocumentoTypes.POST_CUSTO, postCustoDocumento);
}

function* postRegistroDocumentoWatcher() {
  yield takeLatest(DocumentoTypes.POST_REGISTRO, postRegistroDocumento);
}

function* postStatusWatcher() {
  yield takeLatest(DocumentoTypes.POST_STATUS, postStatus);
}

function* getProdDocWatcher() {
  yield takeLatest(DocumentoTypes.GET_PRODUTO_DOCUMENTO, getProdDoc);
}

function* getFieldsWatcher() {
  yield takeLatest(DocumentoTypes.GET_FIELDS_ITENS_DOCUMENTO, getFields);
}

function* getCustoWatcher() {
  yield takeLatest(DocumentoTypes.GET_CUSTO_ITENS_DOCUMENTO, getCusto);
}

function* getPlanoContaWatcher() {
  yield takeLatest(DocumentoTypes.GET_PLANOCONTA_DOCUMENTO, getPlanoConta);
}

function* putDocGerarWatcher() {
  yield takeLatest(DocumentoTypes.PUT_DOCGERAR, putDocGerar);
}

function* postDocMovimentarWatcher() {
  yield takeLatest(DocumentoTypes.POST_DOCMOVIMENTAR, postDocMovimentar);
}

function* getDocItemWatcher() {
  yield takeLatest(DocumentoTypes.GET_DOC_ITEM, getDocItem);
}

function* postDocItemWatcher() {
  yield takeLatest(DocumentoTypes.POST_DOC_ITEM, postDocItem);
}

function* deleteDocItemWatcher() {
  yield takeLatest(DocumentoTypes.DELETE_DOC_ITEM, deleteDocItem);
}

function* putDocItemWatcher() {
  yield takeLatest(DocumentoTypes.PUT_DOC_ITEM, putDocItem);
}

function* attPrecoItemWatcher() {
  yield takeLatest(DocumentoTypes.ATT_PRECO_ITEM, attPrecoItem);
}

function* getMovimentacaoWatcher() {
  yield takeLatest(DocumentoTypes.GET_MOVIMENTACAO, getMovimentacao);
}

function* postOutrosWatcher() {
  yield takeLatest(DocumentoTypes.POST_ITEM_OUTROS, postOutros);
}

function* postCfoWatcher() {
  yield takeLatest(DocumentoTypes.POST_CFO, postCfo);
}

function* postTributoWatcher() {
  yield takeLatest(DocumentoTypes.POST_TRIBUTO, postTributo);
}

function* postXMLWatcher() {
  yield takeLatest(DocumentoTypes.POST_XML, postXML);
}

function* exportXMLWatcher() {
  yield takeLatest(DocumentoTypes.EXPORT_XML, exportXML);
}

function* postFinanceiroWatcher() {
  yield takeLatest(DocumentoTypes.POST_FINANCEIRO, postFinanceiro);
}

function* postParcelaWatcher() {
  yield takeLatest(DocumentoTypes.POST_PARCELA, postParcela);
}

function* putParcelaWatcher() {
  yield takeLatest(DocumentoTypes.PUT_PARCELA, putParcela);
}

function* deleteParcelaWatcher() {
  yield takeLatest(DocumentoTypes.DELETE_PARCELA, deleteParcela);
}

function* postEntregaWatcher() {
  yield takeLatest(DocumentoTypes.POST_ENTREGA, postEntrega);
}

function* putEntregaWatcher() {
  yield takeLatest(DocumentoTypes.PUT_ENTREGA, putEntrega);
}

function* deleteEntregaWatcher() {
  yield takeLatest(DocumentoTypes.DELETE_ENTREGA, deleteEntrega);
}

function* imprimirDocumentoWatcher() {
  yield takeLatest(DocumentoTypes.IMPRIMIR_DOCUMENTO, imprimirDocumento);
}

function* userPerfilWatcher() {
  yield takeLatest(DocumentoTypes.USER_PERFIL, userPerfil);
}

function* postLancamentoWatcher() {
  yield takeLatest(DocumentoTypes.POST_LANCAMENTO, postLancamento);
}

function* getQtdMovWatcher() {
  yield takeLatest(DocumentoTypes.GET_QTD_MOV, getQtdMov);
}

function* postEmissaoWatcher() {
  yield takeLatest(DocumentoTypes.POST_EMISSAO, postEmissao);
}

function* postManifestacaoWatcher() {
  yield takeLatest(DocumentoTypes.POST_MANIFESTACAO, postManifestacao);
}

function* getQualidadeWatcher() {
  yield takeLatest(DocumentoTypes.GET_QUALIDADE, getQualidade);
}

function* postRespostaWatcher() {
  yield takeLatest(DocumentoTypes.POST_RESPOSTA, postResposta);
}

function* postConsultasNfeWatcher() {
  yield takeLatest(DocumentoTypes.POST_CONSULTAS_NFE, postConsultasNfe);
}

function* postCorrecaoWatcher() {
  yield takeLatest(DocumentoTypes.POST_CORRECAO, postCorrecao);
}

function* postInutilizacaoWatcher() {
  yield takeLatest(DocumentoTypes.POST_INUTILIZACAO, postInutilizacao);
}

function* sendMailWatcher() {
  yield takeLatest(DocumentoTypes.SEND_MAIL, sendMail);
}

function* getInfoTributoWatcher() {
  yield takeLatest(DocumentoTypes.GET_INFO_TRIBUTO, getInfoTributo);
}

function* postFaturacaoWatcher() {
  yield takeLatest(DocumentoTypes.POST_FATURACAO, postFaturacao);
}

function* getDadosAdcWatcher() {
  yield takeLatest(DocumentoTypes.GET_DADOSADC, getDadosAdc);
}

function* postNfeWatcher() {
  yield takeLatest(DocumentoTypes.POST_NFE, postNfe);
}

function* postItemOrigemWatcher() {
  yield takeLatest(DocumentoTypes.POST_ITEM_ORIGEM, postItemOrigem);
}

function* postBxOrigemWatcher() {
  yield takeLatest(DocumentoTypes.POST_BX_ORIGEM, postBxOrigem);
}

function* getConferenciaWatcher() {
  yield takeLatest(DocumentoTypes.GET_CONFERENCIA, getConferencia);
}

function* postStatusAntWatcher() {
  yield takeLatest(DocumentoTypes.POST_STATUSANT, postStatusAnt);
}

function* postExportaInutilizadasWatcher() {
  yield takeLatest(
    DocumentoTypes.POST_EXPORTA_INUTILIZADAS,
    postExportaInutilizadas
  );
}

function* postCarregamentoWatcher() {
  yield takeLatest(DocumentoTypes.POST_CARREGAMENTO, postCarregamento);
}

function* getMidiaWatcher() {
  yield takeLatest(DocumentoTypes.GET_DOCUMENTO_MIDIAS, getMidia);
}

function* putMidiaWatcher() {
  yield takeLatest(DocumentoTypes.PUT_DOCUMENTO_MIDIAS, putMidia);
}

function* postDocumentoGerarWatcher() {
  yield takeLatest(DocumentoTypes.POST_DOCUMENTO_GERAR, postDocumentoGerar);
}

function* imprimirDanfeEtiquetaWatcher() {
  yield takeLatest(
    DocumentoTypes.IMPRIMIR_DANFE_ETIQUETA,
    imprimirDanfeEtiqueta
  );
}

export default function* rootSaga() {
  yield all([
    fork(getDocumentoWatcher),
    fork(deleteDocumentoWatcher),
    fork(postCapaWatcher),
    fork(postReposicaoWatcher),
    fork(postAprovacaoWatcher),
    fork(postCustoDocumentoWatcher),
    fork(postRegistroDocumentoWatcher),
    fork(postStatusWatcher),
    fork(getProdDocWatcher),
    fork(getFieldsWatcher),
    fork(getCustoWatcher),
    fork(getPlanoContaWatcher),
    fork(putDocGerarWatcher),
    fork(postDocMovimentarWatcher),
    fork(getDocItemWatcher),
    fork(postDocItemWatcher),
    fork(deleteDocItemWatcher),
    fork(putDocItemWatcher),
    fork(attPrecoItemWatcher),
    fork(getItensWatcher),
    fork(getMovimentacaoWatcher),
    fork(postOutrosWatcher),
    fork(getMovimentacaoWatcher),
    fork(postCfoWatcher),
    fork(postTributoWatcher),
    fork(postXMLWatcher),
    fork(exportXMLWatcher),
    fork(postFinanceiroWatcher),
    fork(postParcelaWatcher),
    fork(putParcelaWatcher),
    fork(deleteParcelaWatcher),
    fork(postEntregaWatcher),
    fork(putEntregaWatcher),
    fork(deleteEntregaWatcher),
    fork(imprimirDocumentoWatcher),
    fork(userPerfilWatcher),
    fork(postLancamentoWatcher),
    fork(getQtdMovWatcher),
    fork(postEmissaoWatcher),
    fork(postManifestacaoWatcher),
    fork(getQualidadeWatcher),
    fork(postRespostaWatcher),
    fork(postConsultasNfeWatcher),
    fork(postCorrecaoWatcher),
    fork(postInutilizacaoWatcher),
    fork(sendMailWatcher),
    fork(getInfoTributoWatcher),
    fork(postFaturacaoWatcher),
    fork(getDadosAdcWatcher),
    fork(postNfeWatcher),
    fork(postItemOrigemWatcher),
    fork(postBxOrigemWatcher),
    fork(getConferenciaWatcher),
    fork(postStatusAntWatcher),
    fork(postExportaInutilizadasWatcher),
    fork(postCarregamentoWatcher),
    fork(getMidiaWatcher),
    fork(putMidiaWatcher),
    fork(postDocumentoGerarWatcher),
    fork(imprimirDanfeEtiquetaWatcher),
  ]);
}
