import React, { useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Menu = ({ history }) => {
  const { menu } = useSelector(({ auth }) => auth);
  useEffect(() => {
    const pathname = `#${history.location.pathname}`;
    const mainMenu = document.getElementsByClassName('nav-item');
    for (let i = 0; i < mainMenu.length; i++) {
      mainMenu[i].onclick = function() {
        for (let j = 0; j < mainMenu.length; j++) {
          if (mainMenu[j].classList.contains('active')) {
            mainMenu[j].classList.remove('active');
          }
        }
        this.classList.toggle('active');
      };
    }
    const subMenuLi = document.getElementsByClassName('nav-arrow');
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function() {
        for (let j = 0; j < subMenuLi.length; j++) {
          if (subMenuLi[j].classList.contains('active')) {
            subMenuLi[j].classList.remove('active');
          }
        }
        this.classList.toggle('active');
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]');
    try {
      const activeNav = closest(activeLi, 'ul');
      if (activeNav.classList.contains('sub-menu')) {
        closest(activeNav, 'li').classList.add('active');
      } else {
        closest(activeLi, 'li').classList.add('active');
      }
      const parentNav = closest(activeNav, '.nav-item');
      if (parentNav) {
        parentNav.classList.add('active');
      }
    } catch (e) {}
  }, []);

  function closest(el, selector) {
    try {
      let matchesFn;
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ].some(function(fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }
  return (
    <ul
      className="navbar-nav"
      style={{
        backgroundColor: '#252525',
        flexDirection: 'row',
        flexWrap: 'wrap',
        zIndex: 1000,
        alignItems: 'center',
      }}
    >
      {menu.map((m) => (
        <li style={{ padding: '10px 20px' }}>
          {m.link ? (
            <NavLink
              className="prepend-icon"
              to={m.link}
              //target={m?.name === 'Rotinas' ? '_blank' : ''}
            >
              <span className="nav-text">{m.name}</span>
            </NavLink>
          ) : (
            <span className="nav-link" style={{ padding: 0 }}>
              {m.name}
            </span>
          )}
          <ul className="sub-menu" style={{ zIndex: 9999 }}>
            {m?.children?.map((i) => (
              <li>
                <NavLink
                  className="prepend-icon"
                  to={i.link}
                  //target={i?.name === 'Rotinas' ? '_blank' : ''}
                >
                  <span className="nav-text">{i.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default withRouter(Menu);
