import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  InputLabel,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { findOnArray, useQuery, setDecimal, transformData } from 'util/index';
import { Creators as EntidadesActions } from 'store/ducks/Entidades';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  insideGrid: {
    border: '1px solid ',
    borderRadius: '5px',
    borderColor: '#00000020',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    padding: '2rem',
  },
  image: {
    maxWidth: '100%',
  },
  textView: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    position: 'absolute',
    top: 5,
  },
  textImage: {
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    marginTop: '1rem',
    padding: '0 16px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const integraDrop = [
  { value: 'MERCOS', label: 'MERCOS' },
  { value: 'TRAY', label: 'TRAY' },
];

const Filiais = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);

  const id = query.get('id');
  const nameParam = query.get('name');
  const { getLoading, entidade, putLoading, putSuccess } = useSelector(
    ({ entidades }) => entidades
  );
  const [localLoading, setLocalLoading] = useState(true);
  const [fill, setFill] = useState(false);
  const [items, setItems] = useState([]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([701, 702, 883]);
    if (id) {
      dispatch(EntidadesActions.getEntidade(id));
    }
  }, []);

  useEffect(() => {
    if (putSuccess) {
      dispatch(EntidadesActions.putEntidadeReset());
      setFill(false);
      dispatch(EntidadesActions.getEntidade(id));
    }
  }, [putSuccess]);

  function onImageChange(event, values, campo) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsBinaryString(event.target.files[0]);
      reader.onload = function(event) {
        onSubmit(values, { campo, valor: event.target.result });
      };
    }
  }

  function getAddress(param) {
    const end = entidade?.Enderecos
      ? entidade?.Enderecos.find((e) => e.principal === 'SIM')
      : null;
    if (end) {
      return ' - ' + end.cidade + ' / ' + end.uf;
    }
    return '';
  }

  const SetFields = () => {
    const { setValues } = useFormikContext();

    useEffect(() => {
      if (entidade && !fill) {
        const filial = entidade?.Filial;
        setFill(true);
        setValues({
          regime: findOnArray(filial?.regtrib, items[0]),
          regesp: findOnArray(filial?.regesp, items[2]),
          simples: setDecimal(filial?.pcsimples, 2),
          classificacao: findOnArray(filial?.classificacao, items[1]),
          spcServer: filial?.spc_servidor,
          spcPorta: filial?.spc_porta,
          spcCodigo: filial?.spc_codigo,
          spcPw: filial?.spc_senha,
          spcConsulta: filial?.spc_consulta,
          nfePw: filial?.nfe_senha,
          nfeCrc: filial?.nfe_crc,
          nfeCrcUf: filial?.nfe_crc_uf,
          nfeCrt: filial?.nfe_crt,
          nfsUsuario: filial?.nfs_usuario,
          nfsContribuinte: filial?.nfs_contribuinte,

          mail_conta: filial?.mail_conta,
          mail_email: filial?.mail_email,
          mail_senha: filial?.mail_senha,
          mail_servidorsmtp: filial?.mail_servidorsmtp,
          mail_portasmtp: filial?.mail_portasmtp,
          mail_usatls: findOnArray(filial?.mail_usatls, yn),
          integra: findOnArray(filial?.integra, integraDrop),
        });
      }
    }, [entidade]);
    return null;
  };

  function onSubmit(values, imagem) {
    const filial = entidade?.Filial || {};
    let form = {
      ...filial,
      regtrib: values?.regime?.value,
      regesp: values?.regesp?.value,
      pcsimples: transformData(values?.simples, 'number') || null,
      classificacao: values.classificacao?.value,
      spc_servidor: values?.spcServer,
      spc_porta: Number(values?.spcPorta) || null,
      spc_codigo: values?.spcCodigo,
      spc_senha: values?.spcPw,
      spc_consulta: values?.spcConsulta,
      nfe_senha: values?.nfePw,
      nfe_crc: Number(values?.nfeCrc),
      nfe_crc_uf: Number(values?.nfeCrcUf),
      nfe_crt: Number(values?.nfeCrt),
      nfs_usuario: values?.nfsUsuario,
      nfs_contribuinte: values?.nfsContribuinte,
      mail_conta: values?.mail_conta,
      mail_email: values?.mail_email,
      mail_senha: values?.mail_senha,
      mail_servidorsmtp: values?.mail_servidorsmtp,
      mail_portasmtp: Number(values?.mail_portasmtp),
      mail_usatls: values?.mail_usatls?.value,
      integra: values?.integra?.value,
      logo: undefined,
      assinatura: undefined,
    };
    if (imagem) {
      form = { ...form, [imagem.campo]: imagem.valor };
    }
    dispatch(EntidadesActions.putEntidade({ data: { Filial: form }, id }));
  }

  if (getLoading || localLoading) {
    return <Loading />;
  }
  return (
    <div className="app-wrapper">
      <HeaderPage
        title={`Filial: ${nameParam} ${getAddress()}`}
        history={history}
      />
      <Formik
        initialValues={{
          regime: null,
          regesp: null,
          simples: '',
          classificacao: null,
          spcServer: '',
          spcPorta: '',
          spcCodigo: '',
          spcPw: '',
          spcConsulta: '',
          nfePw: '',
          nfeCrc: '',
          nfeCrcUf: null,
          nfeCrt: '',
          nfsUsuario: '',
          nfsContribuinte: '',
          mail_conta: '',
          mail_email: '',
          mail_senha: '',
          mail_servidorsmtp: '',
          mail_portasmtp: '',
          mail_usatls: null,
          integra: '',
        }}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <>
            <SetFields />
            <Card className="shadow">
              <CardContent>
                <Grid container>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <div className={classes.insideGrid}>
                      <div className={classes.textView}>
                        <span className={classes.textImage}>LOGOTIPO</span>
                        {entidade?.Filial?.logo ? (
                          <DeleteIcon
                            color="primary"
                            fontSize="small"
                            onClick={() =>
                              onSubmit(values, { campo: 'logo', valor: null })
                            }
                          />
                        ) : (
                          <InputLabel htmlFor="addLogo">
                            <AddIcon color="primary" fontSize="small" />
                          </InputLabel>
                        )}
                      </div>
                      {entidade?.Filial?.logo && (
                        <img
                          className={classes.image}
                          src={`data:image/png;base64,${entidade?.Filial?.logo}`}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <div className={classes.insideGrid}>
                      <div className={classes.textView}>
                        <span className={classes.textImage}>ASSINATURA</span>
                        {entidade?.Filial?.assinatura ? (
                          <DeleteIcon
                            color="primary"
                            fontSize="small"
                            onClick={() =>
                              onSubmit(values, {
                                campo: 'assinatura',
                                valor: null,
                              })
                            }
                          />
                        ) : (
                          <InputLabel htmlFor="addAssinatura">
                            <AddIcon color="primary" fontSize="small" />
                          </InputLabel>
                        )}
                      </div>
                      {entidade?.Filial?.assinatura && (
                        <img
                          className={classes.image}
                          src={`data:image/png;base64,${entidade?.Filial?.assinatura}`}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Regime Tributário"
                      items={items[0]}
                      value={values.regime}
                      onChange={(v) => setFieldValue('regime', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Regime Especial"
                      items={items[2]}
                      value={values.regesp}
                      onChange={(v) => setFieldValue('regesp', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="simples"
                      label="% Simples"
                      value={values.simples}
                      onChange={handleChange}
                      onBlur={(v) =>
                        setFieldValue(
                          'simples',
                          setDecimal(v?.target?.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Classificação do Estabelecimento"
                      items={items[1]}
                      value={values.classificacao}
                      onChange={(v) => setFieldValue('classificacao', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Integração"
                      items={integraDrop}
                      value={values.integra}
                      onChange={(v) => setFieldValue('integra', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                    <TextField
                      name="spcServer"
                      label="Spc Servidor"
                      value={values.spcServer}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="spcPorta"
                      label="Spc Porta"
                      value={values.spcPorta}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="spcCodigo"
                      label="Spc Código"
                      value={values.spcCodigo}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="spcPw"
                      label="Spc Senha"
                      value={values.spcPw}
                      onChange={handleChange}
                      fullWidth
                      type="password"
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="spcConsulta"
                      label="Spc Consulta"
                      value={values.spcConsulta}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="nfePw"
                      label="Nfe Senha"
                      value={values.nfePw}
                      onChange={handleChange}
                      fullWidth
                      type="password"
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="nfeCrc"
                      label="Nfe CRC"
                      value={values.nfeCrc}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="nfeCrcUf"
                      label="Nfe CRC Uf"
                      value={values.nfeCrcUf}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="nfeCrt"
                      label="Nfe CRT"
                      value={values.nfeCrt}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="nfsUsuario"
                      label="Usuário - Nota fiscal de Serviço (nfs)"
                      value={values.nfsUsuario}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="nfsContribuinte"
                      label="Contribuinte - Nota fiscal de Serviço (nfs)"
                      value={values.nfsContribuinte}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <h3 className="my-4">Dados para envio de email automático</h3>
                <Grid container>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="mail_conta"
                      label="Conta do Email"
                      value={values.mail_conta}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="mail_email"
                      label="Email"
                      value={values.mail_email}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="mail_senha"
                      label="Senha do Email"
                      value={values.mail_senha}
                      onChange={handleChange}
                      fullWidth
                      type="password"
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="mail_servidorsmtp"
                      label="Servidor"
                      value={values.mail_servidorsmtp}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="mail_portasmtp"
                      label="Porta"
                      value={values.mail_portasmtp}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Utilizar TLS"
                      items={yn}
                      value={values.mail_usatls}
                      onChange={(v) => setFieldValue('mail_usatls', v)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disable={putLoading}
              >
                Finalizar
              </Button>
            </div>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="addLogo"
              type="file"
              onChange={(e) => onImageChange(e, values, 'logo')}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="addAssinatura"
              type="file"
              onChange={(e) => onImageChange(e, values, 'assinatura')}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(Filiais);
