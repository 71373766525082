import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as IntegraActions,
  Types as IntegraTypes,
} from 'store/ducks/Integra';
import api from '../../util/api';

function* postIntegraPedidos({ payload }) {
  try {
    const { status } =
      payload === 'MERCOS'
        ? yield call(api.post, `/mercos/AtPedidoMercosEprom`)
        : payload === 'TRAY'
        ? yield call(api.post, `/tray/AtPedidoTrayEprom`)
        : null;
    if (status === 200 || status === 201) {
      yield put(IntegraActions.postIntegraPedidosSuccess());
    } else {
      yield put(IntegraActions.postIntegraPedidosReset());
    }
  } catch (error) {
    yield put(IntegraActions.postIntegraPedidosReset());
  }
}

function* postNotificacao({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(
      api.post,
      `/tray/AtPedidoTrayEpromNotificacao/${id}`,
      data
    );

    if (status === 200 || status === 201) {
      yield put(IntegraActions.postNotificacaoSuccess());
    } else {
      yield put(IntegraActions.postNotificacaoReset());
    }
  } catch (error) {
    yield put(IntegraActions.postNotificacaoReset());
  }
}

function* postIntegra2rs({ payload }) {
  try {
    const { status } = Boolean(payload)
      ? yield call(api.post, `/2rs/AtPedido2rsEprom/${payload}`)
      : yield call(api.get, `/2rs/pedidos`);

    if (status === 200 || status === 201) {
      yield put(IntegraActions.postIntegra2rsSuccess());
    } else {
      yield put(IntegraActions.postIntegra2rsReset());
    }
  } catch (error) {
    yield put(IntegraActions.postIntegra2rsReset());
  }
}

function* postIntegraPedidosWatcher() {
  yield takeLatest(IntegraTypes.POST_INTEGRA_PEDIDOS, postIntegraPedidos);
}

function* postNotificacaoWatcher() {
  yield takeLatest(IntegraTypes.POST_NOTIFICACAO, postNotificacao);
}

function* postIntegra2rsWatcher() {
  yield takeLatest(IntegraTypes.POST_INTEGRA_2RS, postIntegra2rs);
}

export default function* rootSaga() {
  yield all([
    fork(postIntegraPedidosWatcher),
    fork(postNotificacaoWatcher),
    fork(postIntegra2rsWatcher),
  ]);
}
