import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Paper,
  Dialog,
  TextField,
  IconButton,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { setDecimal, transformData } from 'util/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = (theme) => ({
  gridLeft: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  marginGridLeft: {
    marginRight: '10px',
  },
  gridRight: {
    marginLeft: '10px',
  },
  img: {
    width: '100%',
    objectFit: 'contain',
  },
  paper: {
    minWidth: '60%',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  icon: {
    color: 'black',
  },
  itemTitleFlex: {
    color: 'black',
    fontSize: '14px',
    flex: 1,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fonSize: '20px',
  },
  itemTitle: {
    color: 'black',
    fonSize: '14px',
  },
});

const CatalogoDetailModal = ({
  open,
  setOpen,
  shopCart,
  setShopCart,
  classes,
}) => {
  const [count, setCount] = useState(0);
  const [quantidade, setQuantidade] = useState('1');

  const match = useMediaQuery('(min-width:600px)');

  const addItem = () => {
    if (shopCart.find((i) => i?.id === open?.id)) {
      let newShop = shopCart.filter((f) => f?.id !== open?.id);
      setShopCart([
        ...newShop,
        { ...open, quantidade: transformData(quantidade, 'number') },
      ]);
    } else {
      setShopCart([
        ...shopCart,
        { ...open, quantidade: transformData(quantidade, 'number') },
      ]);
    }
    setOpen(null);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      classes={{ paper: classes.paper }}
      onExited={() => setQuantidade('1')}
    >
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="6" md="6" lg="6">
            <Paper
              elevation={5}
              classes={{ root: classes.gridLeft }}
              className={match && classes.marginGridLeft}
            >
              <img
                src={open?.Imagens[count]?.imagem}
                alt={open?.descricaoc}
                className={classes.img}
              />
              <div className={classes.buttonContainer}>
                <IconButton
                  size="small"
                  className={classes.icon}
                  onClick={() => {
                    if (count > 0) {
                      setCount(count - 1);
                    }
                  }}
                >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  className={classes.icon}
                  onClick={() => {
                    if (count < open?.Imagens.length - 1) {
                      setCount(count + 1);
                    }
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              </div>
            </Paper>
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6">
            <div className={match && classes.gridRight}>
              <DialogContentText
                classes={{ root: classes.title }}
                className={!match && 'mt-4'}
              >
                {open?.descricaoc}
              </DialogContentText>
              <DialogContentText classes={{ root: classes.itemTitle }}>
                Referência: {open?.referencia}
              </DialogContentText>
              {!!(open?.lotemin || open?.lotemult) && (
                <div className="d-flex">
                  {open?.lotemin && (
                    <DialogContentText className={classes.itemTitleFlex}>
                      Lote Mínimo: {open?.lotemin} {open?.unidade}
                    </DialogContentText>
                  )}
                  {open?.mult && (
                    <DialogContentText className={classes.itemTitleFlex}>
                      Lote Múltiplo: {open?.lotemult} {open?.unidade}
                    </DialogContentText>
                  )}
                </div>
              )}
              <DialogContentText classes={{ root: classes.itemTitle }}>
                Preço Unitário: R${setDecimal(open?.preco || 0, 2)}
              </DialogContentText>
              <DialogContentText classes={{ root: classes.itemTitle }}>
                Total: R$
                {setDecimal(
                  (open?.preco || 0) * transformData(quantidade, 'number'),
                  2
                )}
              </DialogContentText>
              <DialogContentText classes={{ root: classes.itemTitle }}>
                Disponível: {(setDecimal(open?.saldo,2))}
              </DialogContentText>
              <TextField
                name="quantidade"
                label="Quantidade"
                value={quantidade}
                className="my-4"
                onChange={(e) => setQuantidade(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                className="jr-btn"
                onClick={() => addItem()}
              >
                ADICIONAR AO CARRINHO
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(CatalogoDetailModal);
