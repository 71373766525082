import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { withStyles } from '@material-ui/core/styles';
import api from 'util/api';

const styles = () => ({
  paper: {
    minWidth: '90%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const ImportaCustoModal = ({ open, setOpen, classes }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    entidade_id: null,
    sheet: '',
    produto: '',
    cdbarra: '',
    referencia: '',
    descricao: '',
    ncm: '',
    peso: '',
    preco: '',
    icms_base: '',
    icms_percentual: '',
    icms_valor: '',
    st_base: '',
    st_percentual: '',
    st_valor: '',
    ipi_base: '',
    ipi_percentual: '',
    ipi_valor: '',
    pis_base: '',
    pis_percentual: '',
    pis_valor: '',
    cofins_base: '',
    cofins_percentual: '',
    cofins_valor: '',
  });

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([633]);
    }
  }, [open]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      if (Boolean(values?.entidade_id?.value)) {
        formData.append('entidade_id', values?.entidade_id?.value);
      }
      if (Boolean(values?.sheet)) {
        formData.append('sheet', values?.sheet);
      }
      if (Boolean(values?.produto)) {
        formData.append('produto', values?.produto);
      }
      if (Boolean(values?.cdbarra)) {
        formData.append('cdbarra', values?.cdbarra);
      }
      if (Boolean(values?.referencia)) {
        formData.append('referencia', values?.referencia);
      }
      if (Boolean(values?.descricao)) {
        formData.append('descricao', values?.descricao);
      }
      if (Boolean(values?.ncm)) {
        formData.append('ncm', values?.ncm);
      }
      if (Boolean(values?.peso)) {
        formData.append('peso', values?.peso);
      }
      if (Boolean(values?.preco)) {
        formData.append('preco', values?.preco);
      }
      if (Boolean(values?.icms_base)) {
        formData.append('icms_base', values?.icms_base);
      }
      if (Boolean(values?.icms_percentual)) {
        formData.append('icms_percentual', values?.icms_percentual);
      }
      if (Boolean(values?.icms_valor)) {
        formData.append('icms_valor', values?.icms_valor);
      }
      if (Boolean(values?.st_base)) {
        formData.append('st_base', values?.st_base);
      }
      if (Boolean(values?.st_percentual)) {
        formData.append('st_percentual', values?.st_percentual);
      }
      if (Boolean(values?.st_valor)) {
        formData.append('st_valor', values?.st_valor);
      }
      if (Boolean(values?.ipi_base)) {
        formData.append('ipi_base', values?.ipi_base);
      }
      if (Boolean(values?.ipi_percentual)) {
        formData.append('ipi_percentual', values?.ipi_percentual);
      }
      if (Boolean(values?.ipi_valor)) {
        formData.append('ipi_valor', values?.ipi_valor);
      }
      if (Boolean(values?.pis_base)) {
        formData.append('pis_base', values?.pis_base);
      }
      if (Boolean(values?.pis_percentual)) {
        formData.append('pis_percentual', values?.pis_percentual);
      }
      if (Boolean(values?.pis_valor)) {
        formData.append('pis_valor', values?.pis_valor);
      }
      if (Boolean(values?.cofins_base)) {
        formData.append('cofins_base', values?.cofins_base);
      }
      if (Boolean(values?.cofins_percentual)) {
        formData.append('cofins_percentual', values?.cofins_percentual);
      }
      if (Boolean(values?.cofins_valor)) {
        formData.append('cofins_valor', values?.cofins_valor);
      }
      await api.post(`/suprimentos-importar-custos`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Importar Custo de Fornecedores</DialogTitle>
      <DialogContent>
        <Typography
          variant="subtitle1"
          component="div"
          style={{ fontWeight: 'bold' }}
        >
          Informe o numero da coluna correspondente a cada campo começando do
          numero 1
        </Typography>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <input
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              className="w-100"
              onChange={(e) => onChange(e)}
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              name="sheet"
              label="Índice do sheet"
              value={values.sheet}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, sheet: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Entidade"
              items={items[0] || []}
              value={values?.entidade_id}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, entidade_id: v }))
              }
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="produto"
              label="coluna do produto"
              value={values.produto}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, produto: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="cdbarra"
              label="Coluna do Código de barras"
              value={values.cdbarra}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, cdbarra: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="referencia"
              label="Coluna da referência"
              value={values.referencia}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, referencia: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              name="descricao"
              label="Coluna da descricao"
              value={values.descricao}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, descricao: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              name="ncm"
              label="Coluna do NCM"
              value={values.ncm}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, ncm: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              name="peso"
              label="Coluna do peso"
              value={values.peso}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, peso: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              name="preco"
              label="Coluna do Preço"
              value={values.preco}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, preco: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="icms_base"
              label="Coluna da base do ICMS"
              value={values.icms_base}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, icms_base: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="icms_percentual"
              label="Coluna do % do ICMS"
              value={values.icms_percentual}
              onChange={(v) =>
                setValues((prev) => ({
                  ...prev,
                  icms_percentual: v?.target?.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="icms_valor"
              label="Coluna do valor do ICMS"
              value={values.icms_valor}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, icms_valor: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>

          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="st_base"
              label="Coluna da base da Sub.Tributária"
              value={values.st_base}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, st_base: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="st_percentual"
              label="Coluna do % da Sub.Tributária"
              value={values.st_percentual}
              onChange={(v) =>
                setValues((prev) => ({
                  ...prev,
                  st_percentual: v?.target?.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="st_valor"
              label="Coluna do valor da Sub.Tributária"
              value={values.st_valor}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, st_valor: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>

          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="ipi_base"
              label="Coluna da base do IPI"
              value={values.ipi_base}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, ipi_base: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="ipi_percentual"
              label="Coluna do % do IPI"
              value={values.ipi_percentual}
              onChange={(v) =>
                setValues((prev) => ({
                  ...prev,
                  ipi_percentual: v?.target?.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="ipi_valor"
              label="Coluna do valor do IPI"
              value={values.ipi_valor}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, ipi_valor: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>

          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="pis_base"
              label="Coluna da base do PIS"
              value={values.pis_base}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, pis_base: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="pis_percentual"
              label="Coluna do % do PIS"
              value={values.pis_percentual}
              onChange={(v) =>
                setValues((prev) => ({
                  ...prev,
                  pis_percentual: v?.target?.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="pis_valor"
              label="Coluna do valor do PIS"
              value={values.pis_valor}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, pis_valor: v?.target?.value }))
              }
              fullWidth
            />
          </Grid>

          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="cofins_base"
              label="Coluna da base do COFINS"
              value={values.cofins_base}
              onChange={(v) =>
                setValues((prev) => ({
                  ...prev,
                  cofins_base: v?.target?.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="cofins_percentual"
              label="Coluna do % do COFINS"
              value={values.cofins_percentual}
              onChange={(v) =>
                setValues((prev) => ({
                  ...prev,
                  cofins_percentual: v?.target?.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              name="cofins_valor"
              label="Coluna do valor do COFINS"
              value={values.cofins_valor}
              onChange={(v) =>
                setValues((prev) => ({
                  ...prev,
                  cofins_valor: v?.target?.value,
                }))
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={20} />}
        <Button onClick={onSubmit} color="primary" disabled={loading}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ImportaCustoModal);
