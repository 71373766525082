import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AuthActions } from '../../store/ducks/Auth';

const UserInfoPopup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <div>
      <div className="user-profile align-items-center">
        <h5 className="user-name mb-0">{authUser?.storedEmpresa}</h5>
      </div>
      <span
        className="jr-link dropdown-item text-muted"
        onClick={() => history.push('/app/Perfil')}
      >
        <i className="zmdi zmdi-face zmdi-hc-fw mr-1" />
        Perfil
      </span>
      {/* <span className="jr-link dropdown-item text-muted">
        <i className="zmdi zmdi-settings zmdi-hc-fw mr-1" />
        Configurações
      </span> */}
      <span
        className="jr-link dropdown-item text-muted"
        onClick={() => dispatch(AuthActions.cleanAuthUser())}
      >
        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
        Sair
      </span>
    </div>
  );
};

export default UserInfoPopup;
