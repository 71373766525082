import React, {
  forwardRef,
  useRef,
  useEffect,
  createContext,
  useContext,
} from 'react';
import { Typography, TextField } from '@material-ui/core/';
import { Autocomplete } from '@material-ui/lab/';

import { VariableSizeList } from 'react-window';

const renderRow = ({ data, index, style }) => {
  const dataSet = data[index];
  return (
    <Typography
      noWrap
      component="li"
      {...dataSet?.props}
      key={dataSet?.key}
      style={{ ...style, top: style.top + 8 }}
    >
      {dataSet?.props?.children[0]?.label}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = forwardRef(({ children, ...other }, ref) => {
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const itemCount = itemData.length;
  const itemSize = 36;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          ref={gridRef}
          outerElementType={OuterElementType}
          itemSize={() => 36}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const SelectAuto = ({
  label,
  value,
  onChange,
  items = [],
  className,
  style,
  multiple,
  disabled,
}) => {
  return (
    <div className={`${className}`} style={style}>
      <Autocomplete
        clearOnEscape
        disableListWrap
        value={value}
        options={items}
        disabled={disabled}
        multiple={multiple}
        ListboxComponent={ListboxComponent}
        renderOption={(props, option) => {
          return [props, option];
        }}
        getOptionLabel={(option) => (option.label === null ? '' : option.label)}
        onChange={(_, newValue) => onChange(newValue)}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </div>
  );
};

export default SelectAuto;
