import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import FilterDescription from 'components/FilterDescription';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import ResumoModal from 'components/Modals/ResumoModal';
import {
  checkManutencao,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ResumoActions } from 'store/ducks/Resumo';
import jwt from 'jwt-simple';

const Resumo = ({ match }) => {
  const title = 'Resumo';
  const query = useQuery(useLocation);
  const coluna = query.get('group');
  const rotina = query.get('rotina');
  const csql = query.get('csql');
  const encode = query.get('filter');
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [parseHeader, setParseHeader] = useState([]);
  const {
    headerLoading,
    headerData: headerDataStore,
    dataLoading,
    data,
    filtros,
    filtrosChanged,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const { filter } = useSelector(({ resumo }) => resumo);

  function getDataTable(props) {
    if (rotina === renderRotina) {
      const newParams = getWhereClause({
        filtros: filter,
        param: true,
      });
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: {
            Parametros: [{ nome: 'likevalue', valor: search }, ...newParams],

            WhereClause: encode
              ? jwt.decode(encode, 'SecretFilterEprom')
              : getWhereClause({
                  filtros: filter,
                  setStringExport,
                }),
            OrderClause: props?.OrderClause,
            Agrupamento: {
              coluna,
              rotina,
            },
          },
        })
      );
    }
  }

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (filtrosChanged) {
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length) {
      let parseHeader = [
        {
          tabela: ' ',
          grupo: '',
          itens: [
            {
              campo: 'registros',
              coluna: 'registros',
              tipo: 'NUMERO',
              titulo: 'Registros',
            },
          ],
        },
      ];
      headerData.map((h) => {
        if (
          h.itens.find((f) => f.coluna === coluna) ||
          h.itens.find((f) => f.agrupamento !== 'NENHUM')
        ) {
          parseHeader.push(h);
        }
      });
      parseHeader.sort((a, b) => {
        if (a.itens.find((f) => f.agrupamento === 'NENHUM')) return -1;
      });
      setParseHeader(parseHeader);
      getDataTable();
    }
  }, [headerData]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
    }
  }, [headerDataStore]);

  const buttonActions = [
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => {
        if (filter) {
          dispatch(ResumoActions.setFilterResumo(null));
        }
        setFilterModal(!filterModal);
      },
      visible: false,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: false,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: false,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => getDataTable()}
                />
              </div>
              <FilterDescription text={stringExport} />
              <Table
                headerData={parseHeader}
                data={data}
                actions={[]}
                onOrder={(OrderClause) => getDataTable({ OrderClause })}
              />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filtros}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(parseHeader)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(parseHeader)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Resumo;
