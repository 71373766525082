export const Types = {
  REFRESH: 'REFRESH',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_RESET: 'LOGIN_RESET',

  SET_MENU: 'SET_MENU',
  SET_DASHBOARD: 'SET_DASHBOARD',

  UPDATE_CHART: 'UPDATE_CHART',
  UPDATE_CHART_SUCCESS: 'UPDATE_CHART_SUCCESS',
  UPDATE_CHART_ERROR: 'UPDATE_CHART_ERROR',
  UPDATE_CHART_RESET: 'UPDATE_CHART_RESET',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_RESET: 'UPDATE_USER_RESET',

  CLEAN_AUTH_USER: 'CLEAN_AUTH_USER',
  SET_AUTH_USER: 'SET_AUTH_USER',
};

const INIT_STATE = {
  authUser: null,
  authUserLoading: false,

  menu: [],
  tabelas: [],

  updateLoading: false,
  dashboard: [],
  updateError: false,

  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserError: false,
};

export default function Auth(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.REFRESH: {
      return {
        ...state,
        authUser: null,
        authUserLoading: true,
      };
    }
    case Types.LOGIN: {
      return {
        ...state,
        authUser: null,
        authUserLoading: true,
      };
    }
    case Types.LOGIN_SUCCESS: {
      return {
        ...state,
        authUser: action.payload,
        authUserLoading: false,
      };
    }
    case Types.LOGIN_RESET: {
      return {
        ...state,
        authUser: null,
        authUserLoading: false,
      };
    }
    case Types.CLEAN_AUTH_USER: {
      localStorage.clear();
      window.location.reload();
      return {
        ...state,
        authUser: null,
      };
    }
    case Types.SET_AUTH_USER: {
      return {
        ...state,
        authUser: { ...state.authUser, ...action.payload },
      };
    }
    case Types.SET_MENU: {
      return {
        ...state,
        menu: action.payload.itens,
        tabelas: action.payload.tabelas,
      };
    }
    case Types.SET_DASHBOARD: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }
    case Types.UPDATE_CHART: {
      return {
        ...state,
        updateLoading: true,
        updateError: false,
      };
    }
    case Types.UPDATE_CHART_SUCCESS: {
      return {
        ...state,
        updateLoading: false,
        dashboard: action.payload,
        updateError: false,
      };
    }
    case Types.UPDATE_CHART_ERROR: {
      return {
        ...state,
        updateLoading: false,
        updateError: true,
      };
    }
    case Types.UPDATE_CHART_RESET: {
      return {
        ...state,
        updateLoading: false,
        updateError: false,
      };
    }

    case Types.UPDATE_USER: {
      return {
        ...state,
        updateUserLoading: true,
        updateUserSuccess: false,
        updateUserError: false,
      };
    }
    case Types.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: true,
        updateUserError: false,
      };
    }
    case Types.UPDATE_USER_ERROR: {
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: true,
      };
    }
    case Types.UPDATE_USER_RESET: {
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: false,
      };
    }
    default:
      return state;
  }
}

export const Creators = {
  refresh: (payload) => ({
    type: Types.REFRESH,
    payload,
  }),
  login: (payload) => ({
    type: Types.LOGIN,
    payload,
  }),
  loginSuccess: (payload) => ({
    type: Types.LOGIN_SUCCESS,
    payload,
  }),
  loginReset: () => ({
    type: Types.LOGIN_RESET,
  }),
  cleanAuthUser: () => ({
    type: Types.CLEAN_AUTH_USER,
  }),
  setAuthUser: (payload) => ({
    type: Types.SET_AUTH_USER,
    payload,
  }),
  setMenu: (payload) => ({
    type: Types.SET_MENU,
    payload,
  }),
  setDashboard: (payload) => ({
    type: Types.SET_DASHBOARD,
    payload,
  }),
  updateChart: (payload) => ({
    type: Types.UPDATE_CHART,
    payload,
  }),
  updateChartSuccess: (payload) => ({
    type: Types.UPDATE_CHART_SUCCESS,
    payload,
  }),
  updateChartError: () => ({
    type: Types.UPDATE_CHART_ERROR,
  }),
  updateChartReset: () => ({
    type: Types.UPDATE_CHART_RESET,
  }),

  updateUser: (payload) => ({
    type: Types.UPDATE_USER,
    payload,
  }),
  updateUserSuccess: () => ({
    type: Types.UPDATE_USER_SUCCESS,
  }),
  updateUserError: () => ({
    type: Types.UPDATE_USER_ERROR,
  }),
  updateUserReset: () => ({
    type: Types.UPDATE_USER_RESET,
  }),
};
