import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { findOnArray } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import { Creators as CalculoNSActions } from 'store/ducks/CalculoNS';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
  button: {
    margin: '0 5px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NÃO', label: 'NÃO' },
];

const org = [
  { value: 'COMPRADO', label: 'COMPRADO' },
  { value: 'FABRICADO', label: 'FABRICADO' },
];

const calc = [
  {
    value: 'PEDIDOS DE VENDAS EM ABERTO',
    label: 'PEDIDOS DE VENDAS EM ABERTO',
  },
  { value: 'MÉDIA DIÁRIA DE VENDAS', label: 'MÉDIA DIÁRIA DE VENDAS' },
];

const CalculoNDmodal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { calculo, postLoading } = useSelector(({ calculoNS }) => calculoNS);
  const { prodDoc } = useSelector(({ documento }) => documento);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    loadItems([633, 916, 962]);
    dispatch(CalculoNSActions.getCalc());
    dispatch(DocumentoActions.getProdDoc());
  }, []);

  const SetValues = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (calculo) {
        setValues({
          calculo: findOnArray(
            calculo?.formacalculo || 'PEDIDOS DE VENDAS EM ABERTO',
            calc
          ),
          estoqueseg: findOnArray(calculo?.estseg, yn),
          minmax: findOnArray(calculo?.estminmax, yn),
          saldo: findOnArray(calculo?.saldo, yn),
          movimentacao: findOnArray(calculo?.movimentacao, yn),
          ordfab: findOnArray(calculo?.ordfab, yn),
          compras: findOnArray(calculo?.compras, yn),
          produto_id: findOnArray(calculo?.produto_id, prodDoc),
          cemarca_id: findOnArray(calculo?.cemarca_id, items[2] || []),
          entidade_id: findOnArray(calculo?.entidade_id, items[0] || []),
          documento_id: findOnArray(calculo?.documento_id, items[1] || []),
          prazoi: calculo?.przenti
            ? moment(calculo?.przenti).format('DD/MM/YYYY')
            : null,
          prazof: calculo?.przentf
            ? moment(calculo?.przentf).format('DD/MM/YYYY')
            : null,
          vendai: calculo?.vendai
            ? moment(calculo?.vendai).format('DD/MM/YYYY')
            : moment()
                .add(-1, 'year')
                .format('DD/MM/YYYY'),
          vendaf: calculo?.vendaf
            ? moment(calculo?.vendaf).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY'),
          diasestoque: calculo?.diasestoque,
        });
      }
    }, [calculo]);
    return null;
  };

  function sendCalc(values) {
    const data = {
      ...values,
      calculo: values?.calculo?.value,
      produto_id: values?.produto_id?.value,
      cemarca_id: values?.cemarca_id?.value,
      entidade_id: values?.entidade_id?.value,
      documento_id: values?.documento_id?.value,
      origem: values?.origem?.value,
      prazoi: moment(values?.prazoi, 'DD/MM/YYYY').isValid()
        ? moment(values?.prazoi, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      prazof: moment(values?.prazof, 'DD/MM/YYYY').isValid()
        ? moment(values?.prazof, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      estoqueseg: values?.estoqueseg?.value,
      compras: values?.compras?.value,
      minmax: values?.minmax?.value,
      saldo: values?.saldo?.value,
      movimentacao: values?.movimentacao?.value,
      ordfab: values?.ordfab?.value,
      vendai: moment(values?.vendai, 'DD/MM/YYYY').isValid()
        ? moment(values?.vendai, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      vendaf: moment(values?.vendaf, 'DD/MM/YYYY').isValid()
        ? moment(values?.vendaf, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      diasestoque: values?.diasestoque ? Number(values?.diasestoque) : null,
    };
    dispatch(CalculoNSActions.postCalc(data));
    setOpen(!open);
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          calculo: null,
          produto_id: null,
          cemarca_id: null,
          entidade_id: null,
          documento_id: null,
          origem: null,
          prazoi: null,
          prazof: null,
          estoqueseg: null,
          minmax: null,
          compras: null,
          saldo: null,
          movimentacao: null,
          ordfab: null,
          vendai: moment()
            .add(-1, 'year')
            .format('DD/MM/YYYY'),
          vendaf: moment().format('DD/MM/YYYY'),
          diasestoque: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetValues />
            <DialogTitle>Cálculo da Necessidade de Suprimentos</DialogTitle>
            <DialogContent>
              {calculo?.datahora && (
                <DialogContentText color="primary">
                  Último cálculo em:{' '}
                  {moment(calculo?.datahora).format('DD/MM/YYYY HH:mm:ss')}
                </DialogContentText>
              )}
              <Grid container>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Forma de Cálculo"
                    items={calc}
                    value={values.calculo}
                    onChange={(v) => setFieldValue('calculo', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Origem dos Produtos"
                    items={org}
                    value={values.origem}
                    onChange={(v) => setFieldValue('origem', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Marca"
                    items={items[2] || []}
                    value={values.cemarca_id}
                    onChange={(v) => setFieldValue('cemarca_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Estoque de Segurança (Demanda)"
                    items={yn}
                    value={values.estoqueseg}
                    onChange={(v) => setFieldValue('estoqueseg', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Estoque Mínimo / Máximo"
                    items={yn}
                    value={values.minmax}
                    onChange={(v) => setFieldValue('minmax', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Movimentações pendentes"
                    items={yn}
                    value={values.movimentacao}
                    onChange={(v) => setFieldValue('movimentacao', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Saldo"
                    items={yn}
                    value={values.saldo}
                    onChange={(v) => setFieldValue('saldo', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Ordens de Fabricação Pendentes"
                    items={yn}
                    value={values.ordfab}
                    onChange={(v) => setFieldValue('ordfab', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Compras Pendentes"
                    items={yn}
                    value={values.compras}
                    onChange={(v) => setFieldValue('compras', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Produto"
                    items={prodDoc}
                    value={values.produto_id}
                    onChange={(v) => setFieldValue('produto_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade"
                    items={items[0] || []}
                    value={values.entidade_id}
                    onChange={(v) => setFieldValue('entidade_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Documento"
                    items={items[1] || []}
                    value={values.documento_id}
                    onChange={(v) => setFieldValue('documento_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <InputMask
                    name="prazoi"
                    label="Prazo de entrega inicio"
                    mask="99/99/9999"
                    value={values.prazoi}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <InputMask
                    name="prazof"
                    label="Prazo de entrega fim"
                    mask="99/99/9999"
                    value={values.prazof}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="vendai"
                    label="Período de vendas (início)"
                    mask="99/99/9999"
                    value={values.vendai}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="vendaf"
                    label="Período de vendas (fim)"
                    mask="99/99/9999"
                    value={values.vendaf}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="diasestoque"
                    label="Qt. Dias Estoque"
                    value={values.diasestoque}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row align-items-end">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => sendCalc(values)} 
                  disabled={postLoading}
                >
                  CALCULAR
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setOpen(!open)}
                >
                  CANCELAR
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(CalculoNDmodal);
