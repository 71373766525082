import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as DocumentoActions } from '../../../store/ducks/Documento';
import { setDecimal, transformData } from 'util/index';

const styles = (theme) => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const DevolucaoModal = ({
  open,
  setOpen,
  selected = [],
  documento,
  classes,
}) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const { capaLoading } = useSelector(({ documento }) => documento);

  const onSubmit = (values) => {
    const Itens = selected.map((i) => ({
      documento_id: Number(documento),
      produto_id: i?.produto_id,
      quantidade: Number(i?.saldo),
      preco: Number(i?.preco),
      Vinculo: [{ documento_item_id_vinculo: i?.id }],
    }));
    Itens.map((i, index) => {
      if (values[`n${index}`] && values[`n${index}`] !== '') {
        i.quantidade = transformData(values[`n${index}`], 'number');
      }
      if (values[`p${index}`] && values[`p${index}`] !== '') {
        i.preco = transformData(values[`p${index}`], 'number');
      }
    });
    dispatch(DocumentoActions.postCapa({ Itens }));
  };

  useEffect(() => {
    let newValues = {};
    selected.map((s, index) => {
      newValues[`n${index}`] = setDecimal(s?.saldo, 2);
      newValues[`p${index}`] = setDecimal(s?.preco, 2);
    });
    setInitialValues(newValues);
  }, [selected]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik initialValues={initialValues}>
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogTitle>Confirmar Quantidades a vincular</DialogTitle>
            <DialogContent>
              <Grid container>
                {selected?.map((s, index) => (
                  <>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name={`n${index}`}
                        label={s?.descricao || ''}
                        value={values[`n${index}`]}
                        onChange={handleChange}
                        onBlur={(e) => {
                          if (
                            transformData(e.target.value, 'number') >
                            transformData(setDecimal(s?.saldo, 2), 'number')
                          ) {
                            setFieldValue(`n${index}`, setDecimal(s?.saldo, 2));
                          } else {
                            setFieldValue(
                              `n${index}`,
                              setDecimal(e.target.value, 2)
                            );
                          }
                        }}
                        className="mb-4"
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name={`p${index}`}
                        label="Valor"
                        value={values[`p${index}`]}
                        onChange={handleChange}
                        onBlur={(e) => {
                          if (e.target.value) {
                            setFieldValue(
                              `p${index}`,
                              setDecimal(e.target.value, 2)
                            );
                          }
                        }}
                        className="mb-4"
                        fullWidth
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </DialogContent>

            <DialogActions>
              {capaLoading && (
                <CircularProgress size={20} />
              )}
              <Button onClick={() => onSubmit(values)} color="primary" disabled={capaLoading}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DevolucaoModal);
