import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Paper,
} from '@material-ui/core';

const MessageModal = ({ open, onClose, consultas }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {Array.isArray(consultas) ? (
          <>
            <DialogTitle>Certificados</DialogTitle>
            {consultas.map((c) => (
              <Paper className="my-3 p-3">
                <DialogContentText style={{ color: 'black' }}>
                  CNPJ: {c?.CNPJ}
                </DialogContentText>
                <DialogContentText style={{ color: 'black' }}>
                  Friendly Name: {c?.FriendlyName}
                </DialogContentText>
                <DialogContentText style={{ color: 'black' }}>
                  Provider Name: {c?.Provider?.$?.name}
                </DialogContentText>
                <DialogContentText style={{ color: 'black' }}>
                  Provider Type: {c?.Provider?.$?.type}
                </DialogContentText>
                <DialogContentText style={{ color: 'black' }}>
                  Subject: {c?.Subject}
                </DialogContentText>
                <DialogContentText style={{ color: 'black' }}>
                  Thumbprint: {c?.Thumbprint}
                </DialogContentText>
                <DialogContentText style={{ color: 'black' }}>
                  Valid From: {c?.ValidFrom}
                </DialogContentText>
                <DialogContentText style={{ color: 'black' }}>
                  Valid Until: {c?.ValidUntil}
                </DialogContentText>
              </Paper>
            ))}
          </>
        ) : (
          <>
            {consultas?.status && (
              <DialogTitle>Status: {consultas?.status}</DialogTitle>
            )}
            {consultas?.motivo && (
              <DialogTitle>Motivo: {consultas?.motivo}</DialogTitle>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default MessageModal;
