import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/images/loading.json';

export default function Loading({ width, height }) {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center w-100">
      <div
        style={{ width: width || 150, height: height || 200 }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <Lottie
          options={{ animationData, autoplay: true, loop: true }}
          isClickToPauseDisabled
        />
      </div>
    </div>
  );
}
