import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import Loading from 'components/Loading';

const ResourcesModal = ({ open }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent>
        <h1 style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
          Aguarde...
          <br />
          Inicializando o sistema
        </h1>
        <Loading />
      </DialogContent>
    </Dialog>
  );
};
export default ResourcesModal;
