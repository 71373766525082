import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { transformData, setDecimal, findOnArray } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '50%',
  },
  button: {
    margin: '0 5px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const DocRegistromodal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();

  const SetValues = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (selected) {
        setValues({
          conferido:
            selected.conferido === 'SIM'
              ? { value: 'NAO', label: 'NÃO' }
              : { value: 'SIM', label: 'SIM' },
        });
      }
    }, [selected]);
    return null;
  };

  function sendRegistro(values) {
    const data = {
      conferido: values?.conferido?.value,
    };
    dispatch(DocumentoActions.postRegistro({ data, id: selected?.id }));
    setOpen(!open);
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          conferido: null,
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <SetValues />
            <DialogTitle>Registro do documento</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Registrar o documento"
                    items={yn}
                    value={values.conferido}
                    onChange={(v) => setFieldValue('conferido', v)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row align-items-end">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => sendRegistro(values)}
                >
                  REGISTRAR
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setOpen(!open)}
                >
                  CANCELAR
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocRegistromodal);
