import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
  button: {
    margin: '0 5px',
  },
});

const GarantiaModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    especie_id: null,
    natureza_operacao_id: null,
    filial_id: null,
    cadastro_id: null,
  });
  const { idRegistred } = useSelector(({ documento }) => documento);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) loadItems([940, 941, 621, 633]);
  }, [open]);

  useEffect(() => {
    if (!!items?.length) {
      setValues({
        ...values,
        especie_id: !!items[0]?.length ? items[0][0] : null,
        natureza_operacao_id: !!items[1]?.length ? items[1][0] : null,
        filial_id: !!items[2]?.length ? items[2][0] : null,
      });
    }
  }, [items]);

  useEffect(() => {
    if (idRegistred) {
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
      if (open) {
        history.push(
          `/app/Devolução?id=${idRegistred}&filial=${
            values?.filial_id?.value
          }&entidade=${values?.cadastro_id?.value}&garantia=${true}`
        );
      }
      setOpen(false);
    }
  }, [idRegistred]);

  const onSubmit = () => {
    const data = {
      Documento: {
        cadastro_id: values?.cadastro_id?.value,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        especie_id: values?.especie_id?.value,
        filial_id: values?.filial_id?.value,
        natureza_operacao_id: values?.natureza_operacao_id?.value,
      },
      Saida: {
        condicao_pagamento_id: 1,
      },
    };
    dispatch(DocumentoActions.postCapa(data));
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Nova Garantia</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Espécie"
              items={items[0]}
              value={values.especie_id}
              onChange={(v) => setValues({ ...values, especie_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Natureza de Operação"
              items={items[1]}
              value={values.natureza_operacao_id}
              onChange={(v) =>
                setValues({ ...values, natureza_operacao_id: v })
              }
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Filial"
              items={items[2]}
              value={values.filial_id}
              onChange={(v) => setValues({ ...values, filial_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Entidade"
              items={items[3]}
              value={values.cadastro_id}
              onChange={(v) => setValues({ ...values, cadastro_id: v })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(GarantiaModal);
