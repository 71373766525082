import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
/* TELAS PADRÕES */
import Dashboard from '../screens/.Dashboard';
import Profile from '../screens/.Perfil';
import Guia from '../screens/.Guia';
import Manutenção from '../screens/.Manutenção';
import Movimentação from '../screens/.Movimentação';
import Relatorios from '../screens/Relatorios';
/* OUTRAS TELAS */
import Aprovação from '../screens/.Outras/Aprovação';
import Devolução from '../screens/.Outras/Devolução';
import Resumo from '../screens/.Outras/Resumo';
import Disp from '../screens/.Outras/Disponibilidade';
import Reserva from '../screens/.Outras/Reservas';
import Histórico from '../screens/.Outras/Histórico';
import VinculoItens from '../screens/.Outras/VinculoItens';
import Qualidade from '../screens/.Outras/Qualidade';
import FormCusto from '../screens/.Outras/FormacaoCusto';
import CompItens from '../screens/.Outras/ComplementoItens';
import NovoLanc from '../screens/.Outras/NovoLancamento';
import EditLanc from '../screens/.Outras/EditarLancamento';
import FinalVenda from '../screens/.Outras/FinalizarVenda';
import Catalogo from '../screens/.Outras/Catalogo';
import Apontamentos from '../screens/.Outras/Apontamentos';
import Exporta from '../screens/.Outras/Exportar/Exporta';
import ExpLayout from '../screens/.Outras/Exportar/ExportaLayout';
import CnsMov from '../screens/.Outras/CnsMovimentação';
import Terceiros from '../screens/.Outras/Terceiros';
import Custo from '../screens/.Outras/Custo';
import Preço from '../screens/.Outras/Preço';
import Operação from '../screens/.Outras/Operação';
import DocItem from '../screens/.Outras/DocItem';
import DIQIndicadores from '../screens/.Outras/DocItem/Qualidade/Indicadores';
import DIQRespostas from '../screens/.Outras/DocItem/Qualidade/Respostas';
import DIT from '../screens/.Outras/DocItem/Tributação';
import DITTributos from '../screens/.Outras/DocItem/Tributação/Tributos';
import DITManutencao from '../screens/.Outras/DocItem/Tributação/Manutenção';
import SitTribManutencao from '../screens/.Outras/SitTribManutencao';
import FormacaoPreco from '../screens/.Outras/FormacaoPreco';
/* MENU - SUPRIMENTOS */
import NecSupHome from '../screens/Suprimentos/NecessidadesdeSuprimentos/Home';
import NecSupCalculo from '../screens/Suprimentos/NecessidadesdeSuprimentos/Calculo';
import NecSupBruta from '../screens/Suprimentos/NecessidadesdeSuprimentos/NecBruta';
import NecSupEstSeg from '../screens/Suprimentos/NecessidadesdeSuprimentos/EstSeg';
import NecSupMov from '../screens/Suprimentos/NecessidadesdeSuprimentos/Movimentar';
import NecSupCompras from '../screens/Suprimentos/NecessidadesdeSuprimentos/Compras';
import NecSupProd from '../screens/Suprimentos/NecessidadesdeSuprimentos/Produção';
import NecSupLiq from '../screens/Suprimentos/NecessidadesdeSuprimentos/Liquida';
import GestãodeCompras from '../screens/Suprimentos/GestãodeCompras';
import GCNfsVinculadas from '../screens/Suprimentos/GestãodeCompras/NfsVinculadas';
import GcRegistros from '../screens/Suprimentos/GestãodeCompras/Registros/Home';
import GcRegMidia from '../screens/Suprimentos/GestãodeCompras/Registros/Mídias';
import CentrosdeCustos from '../screens/Suprimentos/CentrosdeCustos/Home';
import CcValores from '../screens/Suprimentos/CentrosdeCustos/CCValores';
import GestãodeEstoque from '../screens/Suprimentos/GestãodeEstoque';
import SaldoeCusto from '../screens/Suprimentos/SaldoeCusto';
import NotasRecebidas from '../screens/Suprimentos/NotasRecebidas';
import MovEstoque from '../screens/Suprimentos/MovimentaçãodeEstoque';
import RegCustos from '../screens/Suprimentos/RegistrodeCustos/Home';
import RegCustosDetalhe from '../screens/Suprimentos/RegistrodeCustos/DetalhesCustoItens';
import Inventario from '../screens/Suprimentos/Inventário/Home';
import InventarioItens from '../screens/Suprimentos/Inventário/ItensInventario';
import TerceirosMenu from '../screens/.Outras/Terceiros';
/* MENU - COMERCIAL */
import Gestãodecontratos from '../screens/Comercial/Gestãodecontratos';
import Gestãodeserviços from '../screens/Comercial/Gestãodeserviços';
import AgendamentosDoc from '../screens/Comercial/Gestãodeserviços/AgendamentosDoc';
import ConsumosDoc from '../screens/Comercial/Gestãodeserviços/ConsumosDoc';
import Maletas from '../screens/Comercial/Gestãodeserviços/Maletas';
import OrçamentosVenda from '../screens/Comercial/OrçamentosdeVenda';
import PedidosVenda from '../screens/Comercial/PedidosdeVenda';
import Faturamento from '../screens/Comercial/Faturamentos';
import Comercialização from '../screens/Comercial/Comercialização';
import FaturasDoc from '../screens/Comercial/Comercialização/FaturasDoc';
import Representantes from '../screens/Comercial/Representantes';
import NotasEmitidas from '../screens/Comercial/NotasEmitidas/Home';
import NEInutilizados from '../screens/Comercial/NotasEmitidas/DocInutilizados';
import NECarregamento from '../screens/Comercial/NotasEmitidas/Carregamento';
import Expedição from '../screens/Comercial/Expedição';
import Entregas from '../screens/Comercial/Entregas';
import Preços from '../screens/Comercial/Preços';
import Retornaveis from '../screens/Comercial/Retornaveis';
import Garantia from '../screens/Comercial/Garantia';
import Prospecção from '../screens/Comercial/Prospecção';
import Orçamentos from '../screens/Comercial/Prospecção/Orçamentos';
import Agendamentos from '../screens/Comercial/Prospecção/Agendamentos';
import Comissões from '../screens/Comercial/Comissões/Home';
import CFaturamento from '../screens/Comercial/Comissões/Faturamento';
import CRecebimento from '../screens/Comercial/Comissões/Recebimento';
import CManutenção from '../screens/Comercial/Comissões/ManutComissão';
import Atendimento from '../screens/Comercial/Atendimento';
import AtendimentoVendas from '../screens/Comercial/Atendimento/Vendas';
import AtendimentoHistorico from '../screens/Comercial/Atendimento/Historico';
import AtendimentoCaixa from '../screens/Comercial/AtendimentoCaixa';
import GestãoDeAgendas from '../screens/Comercial/GestãoDeAgendas';
/* MENU - FINANCEIRO */
import CaixaeBancos from '../screens/Financeiro/CaixaeBancos/Home';
import CBRestrições from '../screens/Financeiro/CaixaeBancos/Restrições';
import CBLançamentos from '../screens/Financeiro/CaixaeBancos/Lançamentos';
import CBDetalhamento from '../screens/Financeiro/CaixaeBancos/Detalhamento';
import CBConciliação from '../screens/Financeiro/CaixaeBancos/Conciliação';
import CBOfx from '../screens/Financeiro/CaixaeBancos/Ofx';
import FluxodeCaixa from '../screens/Financeiro/FluxodeCaixa';
import Previsões from '../screens/Financeiro/Previsões';
import Haver from '../screens/Financeiro/Haver';
import CondiçãodePagamento from '../screens/Financeiro/CondiçãodePagamento';
import AuditoriaFinanceira from '../screens/Financeiro/AuditoriaFinanceira';
import Pendências from '../screens/Financeiro/Pendências';
import POutrosValores from '../screens/Financeiro/Pendências/OutrosValores';
import PContatos from '../screens/Financeiro/Pendências/Contatos';
import PMovFin from '../screens/Financeiro/Pendências/MovimentoFinanceiro';
import LançamentosCaixa from '../screens/Financeiro/LançamentosCaixa';
import FormaPagto from '../screens/Financeiro/Formadepagamento/Home';
import FPConfigBoleto from '../screens/Financeiro/Formadepagamento/ConfigBoleto';
import FPConfigRemessa from '../screens/Financeiro/Formadepagamento/ConfigRemessa';
import Moedas from '../screens/Financeiro/Moedas';
/* MENU - PRODUÇÃO */
import Estrutura from '../screens/Produção/EstruturaDeProduto/Estrutura';
import EstruturaPadrao from '../screens/Produção/EstruturaDeProduto/EstruturaPadrao';
import Processo from '../screens/Produção/ProcessosProdução/Processo';
import ProcessoPadrao from '../screens/Produção/ProcessosProdução/ProcessoPadrao';
import GestãodeProdução from '../screens/Produção/GestãodeProdução';
import Custos from '../screens/Produção/GestãodeProdução/Custos';
import FormacaoCustoPA from '../screens/Produção/GestãodeProdução/FormacaoCusto';
import CustoProducao from '../screens/Produção/GestãodeProdução/CustoProducao';

/* MENU - FISCAL */
import RegrasEntidade from '../screens/Fiscal/RegrasEntidade';
import RETributo from '../screens/Fiscal/RegrasEntidade/Tributo';
import RegrasCfo from '../screens/Fiscal/RegrasCfo';
import Tributação from '../screens/Fiscal/Tributação';
import RegrasPagtodeTributos from '../screens/Fiscal/RegrasPagtodeTributos';
import Indicadorescréditotributos from '../screens/Fiscal/Indicadorescréditotributos';
import EFDContribuições from '../screens/Fiscal/EFDContribuições';
import AcompanhamentoTributos from '../screens/Fiscal/AcompanhamentoTributos';
import Gia from '../screens/Fiscal/Gia';
import Dapi from '../screens/Fiscal/DAPI';
import GNRE from '../screens/Fiscal/GNRE';
import DetalhamentodeTributos from '../screens/Fiscal/DetalhamentodeTributos';
import ApuraçãodeTributos from '../screens/Fiscal/ApuraçãodeTributos';
import EFDICMSIPI from '../screens/Fiscal/EFDICMSIPI';
import Exportarprodutos from '../screens/Fiscal/Exportarprodutos';
import Exportarfolhamatic from '../screens/Fiscal/Exportarfolhamatic';
import Manifestaçãodedestinatário from '../screens/Fiscal/Manifestaçãodedestinatário';
import SituaçãoTributária from '../screens/Fiscal/SituaçãoTributária';
import ModalidadeBC from '../screens/Fiscal/ModalidadeBaseCálculo';
import TributosDocumento from '../screens/Fiscal/TributosporDocumento';
/* MENU - Contabil */
import PlanodeContas from '../screens/Contábil/PlanodeContas/Home';
import PlanoEntidade from '../screens/Contábil/PlanodeContas/PlanoXEntidade';
import PlanoContas from '../screens/Contábil/PlanodeContas/ContasPlano';
import LançamentosCTB from '../screens/Contábil/Lançamentos/Home';
import Resultado from '../screens/Contábil/Lançamentos/Resultado';
import LancDetalhes from '../screens/Contábil/Lançamentos/Detalhes';
import LançManutenção from '../screens/Contábil/Lançamentos/Manutenção';
import RegrasIntegração from '../screens/Contábil/RegrasIntegração';
/* MENU - SUPORTE A DECISÃO */
import IndicadoresporDocumento from '../screens/Suporteadecisão/IndicadoresporDocumento';
import IndicadoresComerciais from '../screens/Suporteadecisão/IndicadoresComerciais';
import IndicadoresporEntidade from '../screens/Suporteadecisão/IndicadoresporEntidade/Home';
import ComprasEntidade from '../screens/Suporteadecisão/IndicadoresporEntidade/ComprasporEntidade';
import ProdutosEntidade from '../screens/Suporteadecisão/IndicadoresporEntidade/ProdutosporEntidade';
import FinanceiroEntidade from '../screens/Suporteadecisão/IndicadoresporEntidade/FinanceiroporEntidade';
import AbcEntidade from '../screens/Suporteadecisão/IndicadoresporEntidade/AbcEntidade';
import IndicadoresporProduto from '../screens/Suporteadecisão/IndicadoresporProduto';
import RankingProduto from '../screens/Suporteadecisão/IndicadoresporProduto/RankingProduto';
import LucratividadeProduto from '../screens/Suporteadecisão/IndicadoresporProduto/LucratividadeProduto';
import IndicadoresEconômicos from '../screens/Suporteadecisão/IndicadoresEconômicos';
import IEDetalhes from '../screens/Suporteadecisão/IndicadoresEconômicos/IEDetalhes';
import IELancamentos from '../screens/Suporteadecisão/IndicadoresEconômicos/IELancamentos';
import MargemProdutos from '../screens/Suporteadecisão/MargemdeProdutos';
import ConfigResFin from '../screens/Suporteadecisão/ConfigurarResultadofinanceiro';
/* MENU - CADASTROS */
import Produto from '../screens/Cadastros/Produto/Home';
import ProdComissões from '../screens/Cadastros/Produto/Comissões';
import ProdManutenção from '../screens/Cadastros/Produto/Manutenção';
import ProdDescrição from '../screens/Cadastros/Produto/Descrição';
import ProdVariação from '../screens/Cadastros/Produto/Variação';
import ProdMídias from '../screens/Cadastros/Produto/Mídias';
import ProdRegs from '../screens/Cadastros/Produto/Registros';
import ProdCatalogo from '../screens/Cadastros/Produto/Catalogo';
import Refintegra from '../screens/Cadastros/Produto/RefIntegra/Home';
import RefintegraManutenção from '../screens/Cadastros/Produto/RefIntegra/Manutenção';
import Entidade from '../screens/Cadastros/Entidades/Home';
import EntManutenção from '../screens/Cadastros/Entidades/Manutenção';
import EntDocumentos from '../screens/Cadastros/Entidades/Documentos';
import EntEnderecos from '../screens/Cadastros/Entidades/Enderecos';
import EntContatos from '../screens/Cadastros/Entidades/Contatos';
import EntVinculos from '../screens/Cadastros/Entidades/Vinculos';
import EntRegistros from '../screens/Cadastros/Entidades/Registros/Home';
import EntRegMidias from '../screens/Cadastros/Entidades/Registros/Mídias';
import EntComissão from '../screens/Cadastros/Entidades/Comissão';
import EntAprovação from '../screens/Cadastros/Entidades/Aprovação';
import EntFiliais from '../screens/Cadastros/Entidades/Filiais';
import EntPerfil from '../screens/Cadastros/Entidades/Perfil/Home';
import EntPerfilComercial from '../screens/Cadastros/Entidades/Perfil/Comercial';
import EntOperadora from '../screens/Cadastros/Entidades/Operadora';
import DocumentoCapa from '../screens/Cadastros/Documento/Capa';
import DocumentoItens from '../screens/Cadastros/Documento/Itens';
import DocumentoPagamento from '../screens/Cadastros/Documento/Pagamento';
import DocumentoEntrega from '../screens/Cadastros/Documento/Entrega';
/* MENU - TABELAS */
import Tabelas from '../screens/Tabelas';
import Tabela from '../screens/Tabelas/Tabela';
/* MENU - DEFINIÇÕES */
import Usuarios from '../screens/Definições/Usuarios';
import Menu from '../screens/Definições/Menu';
import Rotinas from '../screens/Definições/Rotinas/Home';
import RotinaGrade from '../screens/Definições/Rotinas/RotinaGrade';
import RotinaFiltro from '../screens/Definições/Rotinas/RotinaFiltro';
import Sqls from '../screens/Definições/Sqls';
import ManutençãoSql from '../screens/Definições/Sqls/ManutençãoSql';
import RegrasDocumento from '../screens/Definições/RegrasDocumento';
import Perfil from '../screens/Definições/Perfil/Home';
import PerfilAgenda from '../screens/Definições/Perfil/PerfilAgenda';
import PerfilComercial from '../screens/Definições/Perfil/PerfilComercial';
import PerfilFinanceiro from '../screens/Definições/Perfil/PerfilFinanceiro';
import PerfilProdução from '../screens/Definições/Perfil/PerfilProdução';
import PerfilIndicador from '../screens/Definições/Perfil/PerfilIndicador';
import PerfilMenu from '../screens/Definições/Perfil/PerfilMenu';

const Routes = ({ match }) => (
  <>
    {/* TELAS PADRÕES */}
    <Route exact path={`${match.url}`} component={Dashboard} />
    <Route exact path={`${match.url}/Perfil`} component={Profile} />
    <Route exact path={`${match.url}/Guia`} component={Guia} />
    <Route exact path={`${match.url}/Manutenção`} component={Manutenção} />
    <Route exact path={`${match.url}/Movimentação`} component={Movimentação} />
    <Route exact path={`${match.url}/Relatorios`} component={Relatorios} />
    {/* OUTRAS TELAS */}
    <Route exact path={`${match.url}/Aprovação`} component={Aprovação} />
    <Route exact path={`${match.url}/Devolução`} component={Devolução} />
    <Route exact path={`${match.url}/Resumo`} component={Resumo} />
    <Route exact path={`${match.url}/Disponibilidade`} component={Disp} />
    <Route exact path={`${match.url}/Reservas`} component={Reserva} />    
    <Route exact path={`${match.url}/Histórico`} component={Histórico} />
    <Route exact path={`${match.url}/VinculoItens`} component={VinculoItens} />
    <Route exact path={`${match.url}/Qualidade`} component={Qualidade} />
    <Route exact path={`${match.url}/FormaçãoDeCusto`} component={FormCusto} />
    <Route exact path={`${match.url}/ComplementoItens`} component={CompItens} />
    <Route exact path={`${match.url}/Novo_Lancamento`} component={NovoLanc} />
    <Route exact path={`${match.url}/Editar_Lancamento`} component={EditLanc} />
    <Route exact path={`${match.url}/Finalizar_Venda`} component={FinalVenda} />
    <Route exact path={`${match.url}/Catalogo`} component={Catalogo} />
    <Route exact path={`${match.url}/Apontamentos`} component={Apontamentos} />
    <Route exact path={`${match.url}/Exporta`} component={Exporta} />
    <Route exact path={`${match.url}/ExportaLayout`} component={ExpLayout} />
    <Route exact path={`${match.url}/CnsMovimentação`} component={CnsMov} />
    <Route exact path={`${match.url}/Terceiros`} component={Terceiros} />
    <Route exact path={`${match.url}/Custo`} component={Custo} />
    <Route exact path={`${match.url}/Preço`} component={Preço} />
    <Route exact path={`${match.url}/Operação`} component={Operação} />
    <Route exact path={`${match.url}/DocItem`} component={DocItem} />
    <Route
      exact
      path={`${match.url}/DocItem/Qualidade/Indicadores`}
      component={DIQIndicadores}
    />
    <Route
      exact
      path={`${match.url}/DocItem/Qualidade/Respostas`}
      component={DIQRespostas}
    />
    <Route
      exact
      path={`${match.url}/DocItem/Tributação/TributacaoDocumento`}
      component={DIT}
    />
    <Route
      exact
      path={`${match.url}/DocItem/Tributação/Tributos`}
      component={DITTributos}
    />
    <Route
      exact
      path={`${match.url}/DocItem/Tributação/Manutenção`}
      component={DITManutencao}
    />
    <Route
      exact
      path={`${match.url}/SitTribManutencao`}
      component={SitTribManutencao}
    />
    <Route
      exact
      path={`${match.url}/FormacaoPreco`}
      component={FormacaoPreco}
    />
    {/* MENU - SUPRIMENTOS */}
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos`}
      component={NecSupHome}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos/Calculo`}
      component={NecSupCalculo}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos/Bruta`}
      component={NecSupBruta}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos/EstSeg`}
      component={NecSupEstSeg}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos/Movimentar`}
      component={NecSupMov}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos/Compras`}
      component={NecSupCompras}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos/Produção`}
      component={NecSupProd}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NecessidadesdeSuprimentos/Liquida`}
      component={NecSupLiq}
    />

    <Route
      exact
      path={`${match.url}/Suprimentos/GestãodeCompras`}
      component={GestãodeCompras}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/GestãodeCompras/NfsVinculadas`}
      component={GCNfsVinculadas}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/GestãodeCompras/Registros`}
      component={GcRegistros}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/GestãodeCompras/Registros/Mídias`}
      component={GcRegMidia}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/CentrosdeCustos`}
      component={CentrosdeCustos}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/CentrosdeCustos/Valores`}
      component={CcValores}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/GestãodeEstoque`}
      component={GestãodeEstoque}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/SaldoeCusto`}
      component={SaldoeCusto}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/NotasRecebidas`}
      component={NotasRecebidas}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/MovimentaçãodeEstoque`}
      component={MovEstoque}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/RegistrodeCustos`}
      component={RegCustos}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/RegistrodeCustos/Detalhes`}
      component={RegCustosDetalhe}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/Inventário`}
      component={Inventario}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/Inventário/Itens`}
      component={InventarioItens}
    />
    <Route
      exact
      path={`${match.url}/Suprimentos/Terceiros`}
      component={TerceirosMenu}
    />
    {/* MENU - COMERCIAL */}
    <Route
      exact
      path={`${match.url}/Comercial/Gestãodecontratos`}
      component={Gestãodecontratos}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Gestãodeserviços`}
      component={Gestãodeserviços}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Gestãodeserviços/AgendamentosDoc`}
      component={AgendamentosDoc}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Gestãodeserviços/ConsumosDoc`}
      component={ConsumosDoc}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Gestãodeserviços/Maletas`}
      component={Maletas}
    />
    <Route
      exact
      path={`${match.url}/Comercial/OrçamentosdeVenda`}
      component={OrçamentosVenda}
    />
    <Route
      exact
      path={`${match.url}/Comercial/PedidosdeVenda`}
      component={PedidosVenda}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Faturamentos`}
      component={Faturamento}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Comercialização`}
      component={Comercialização}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Comercialização/FaturasDoc`}
      component={FaturasDoc}
    />
    <Route
      exact
      path={`${match.url}/Comercial/CarteiradoRepresentante`}
      component={Representantes}
    />
    <Route
      exact
      path={`${match.url}/Comercial/NotasEmitidas`}
      component={NotasEmitidas}
    />
    <Route
      exact
      path={`${match.url}/Comercial/NotasEmitidas/Inutilizados`}
      component={NEInutilizados}
    />
    <Route
      exact
      path={`${match.url}/Comercial/NotasEmitidas/Carregamento`}
      component={NECarregamento}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Expedição`}
      component={Expedição}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Entregas`}
      component={Entregas}
    />
    <Route exact path={`${match.url}/Comercial/Preços`} component={Preços} />
    <Route
      exact
      path={`${match.url}/Comercial/Retornáveis`}
      component={Retornaveis}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Garantia`}
      component={Garantia}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Prospecçãodeclientes`}
      component={Prospecção}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Prospecção/Orçamentos`}
      component={Orçamentos}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Prospecção/Agendamentos`}
      component={Agendamentos}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Comissões`}
      component={Comissões}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Comissões/Faturamento`}
      component={CFaturamento}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Comissões/Recebimento`}
      component={CRecebimento}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Comissões/Manutenção`}
      component={CManutenção}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Atendimento`}
      component={Atendimento}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Atendimento/Vendas`}
      component={AtendimentoVendas}
    />
    <Route
      exact
      path={`${match.url}/Comercial/Atendimento/Historico`}
      component={AtendimentoHistorico}
    />

    <Route
      exact
      path={`${match.url}/Comercial/Atendimento-Caixa`}
      component={AtendimentoCaixa}
    />
    <Route
      exact
      path={`${match.url}/Comercial/GestãodeAgendas`}
      component={GestãoDeAgendas}
    />
    {/* MENU - FINANCEIRO */}
    <Route
      exact
      path={`${match.url}/Financeiro/CaixaeBancos`}
      component={CaixaeBancos}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/CaixaeBancos/Restrições`}
      component={CBRestrições}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/CaixaeBancos/Lançamentos`}
      component={CBLançamentos}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/CaixaeBancos/Detalhamento`}
      component={CBDetalhamento}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/CaixaeBancos/Conciliação`}
      component={CBConciliação}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/CaixaeBancos/Ofx`}
      component={CBOfx}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/FluxodeCaixa`}
      component={FluxodeCaixa}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/Previsões`}
      component={Previsões}
    />
    <Route exact path={`${match.url}/Financeiro/Haver`} component={Haver} />
    <Route
      exact
      path={`${match.url}/Financeiro/CondiçãodePagamento`}
      component={CondiçãodePagamento}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/AuditoriaFinanceira`}
      component={AuditoriaFinanceira}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/Pendências`}
      component={Pendências}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/Pendências/OutrosValores`}
      component={POutrosValores}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/Pendências/Contatos`}
      component={PContatos}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/MovimentoFinanceiro`}
      component={PMovFin}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/LançamentosCaixa`}
      component={LançamentosCaixa}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/Formadepagamento`}
      component={FormaPagto}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/Formadepagamento/Boleto`}
      component={FPConfigBoleto}
    />
    <Route
      exact
      path={`${match.url}/Financeiro/Formadepagamento/Remessa`}
      component={FPConfigRemessa}
    />
    <Route exact path={`${match.url}/Financeiro/Moedas`} component={Moedas} />

    {/* MENU - PRODUÇÃO */}
    <Route
      exact
      path={`${match.url}/Produção/Estrutura`}
      component={Estrutura}
    />
    <Route
      exact
      path={`${match.url}/Produção/EstruturaPadrao`}
      component={EstruturaPadrao}
    />
    <Route exact path={`${match.url}/Produção/Processo`} component={Processo} />
    <Route
      exact
      path={`${match.url}/Produção/ProcessoPadrao`}
      component={ProcessoPadrao}
    />
    <Route
      exact
      path={`${match.url}/Produção/GestãodeProdução`}
      component={GestãodeProdução}
    />
    <Route
      exact
      path={`${match.url}/Produção/CustodosFabricados`}
      component={Custos}
    />
    <Route
      exact
      path={`${match.url}/Produção/GestãodeProdução/FormacaoCusto`}
      component={FormacaoCustoPA}
    />
    <Route
      exact
      path={`${match.url}/Produção/CustodaProdução`}
      component={CustoProducao}
    />
    {/* MENU - FISCAL */}
    <Route
      exact
      path={`${match.url}/Fiscal/RegrasEntidade`}
      component={RegrasEntidade}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/RegrasEntidade/Tributo`}
      component={RETributo}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/RegrasCfos`}
      component={RegrasCfo}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/Tributação`}
      component={Tributação}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/RegrasPagtodeTributos`}
      component={RegrasPagtodeTributos}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/Indicadorescréditotributos`}
      component={Indicadorescréditotributos}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/EFDContribuições`}
      component={EFDContribuições}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/AcompanhamentoTributos`}
      component={AcompanhamentoTributos}
    />
    <Route exact path={`${match.url}/Fiscal/Gia`} component={Gia} />
    <Route exact path={`${match.url}/Fiscal/DAPI`} component={Dapi} />
    <Route exact path={`${match.url}/Fiscal/GNRE`} component={GNRE} />
    <Route
      exact
      path={`${match.url}/Fiscal/DetalhamentodeTributos`}
      component={DetalhamentodeTributos}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/ApuraçãodeTributos`}
      component={ApuraçãodeTributos}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/EFDICMSIPI`}
      component={EFDICMSIPI}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/Exportarprodutos`}
      component={Exportarprodutos}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/Exportarfolhamatic`}
      component={Exportarfolhamatic}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/Manifestaçãodedestinatário`}
      component={Manifestaçãodedestinatário}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/SituaçãoTributária`}
      component={SituaçãoTributária}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/ModalidadeBaseCálculo`}
      component={ModalidadeBC}
    />
    <Route
      exact
      path={`${match.url}/Fiscal/TributosporDocumento`}
      component={TributosDocumento}
    />

    {/* MENU - Contabil */}
    <Route
      exact
      path={`${match.url}/Contábil/PlanodeContas`}
      component={PlanodeContas}
    />
    <Route
      exact
      path={`${match.url}/Contábil/PlanodeContas/Entidade`}
      component={PlanoEntidade}
    />
    <Route
      exact
      path={`${match.url}/Contábil/PlanodeContas/Contas`}
      component={PlanoContas}
    />
    <Route
      exact
      path={`${match.url}/Contábil/RegrasIntegração`}
      component={RegrasIntegração}
    />
    <Route
      exact
      path={`${match.url}/Contábil/Lançamentos`}
      component={LançamentosCTB}
    />
    <Route
      exact
      path={`${match.url}/Contábil/Lançamentos/Resultado`}
      component={Resultado}
    />
    <Route
      exact
      path={`${match.url}/Contábil/Lançamentos/Detalhes`}
      component={LancDetalhes}
    />
    <Route
      exact
      path={`${match.url}/Contábil/Lançamentos/Manutenção`}
      component={LançManutenção}
    />

    {/* MENU - SUPORTE A DECISÃO */}
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporDocumento`}
      component={IndicadoresporDocumento}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresComerciais`}
      component={IndicadoresComerciais}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporEntidade`}
      component={IndicadoresporEntidade}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporEntidade/ComprasporEntidade`}
      component={ComprasEntidade}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporEntidade/ProdutosporEntidade`}
      component={ProdutosEntidade}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporEntidade/FinanceiroporEntidade`}
      component={FinanceiroEntidade}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporEntidade/AbcEntidade`}
      component={AbcEntidade}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporProduto`}
      component={IndicadoresporProduto}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporProduto/RankingProduto`}
      component={RankingProduto}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/IndicadoresporProduto/LucratividadeProduto`}
      component={LucratividadeProduto}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/Indicadoreseconômicos`}
      component={IndicadoresEconômicos}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/Indicadoreseconômicos/Detalhes`}
      component={IEDetalhes}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/Indicadoreseconômicos/Lancamentos`}
      component={IELancamentos}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/MargemdeProdutos`}
      component={MargemProdutos}
    />
    <Route
      exact
      path={`${match.url}/Suporteadecisão/ConfigurarResultadofinanceiro`}
      component={ConfigResFin}
    />
    {/* MENU - CADASTROS */}
    <Route exact path={`${match.url}/Cadastros/Produto`} component={Produto} />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Comissões`}
      component={ProdComissões}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Manutenção`}
      component={ProdManutenção}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Descrição`}
      component={ProdDescrição}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Variação`}
      component={ProdVariação}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Mídias`}
      component={ProdMídias}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Registros`}
      component={ProdRegs}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Catalogo`}
      component={ProdCatalogo}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Refintegra`}
      component={Refintegra}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Produto/Refintegra/Manutenção`}
      component={RefintegraManutenção}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades`}
      component={Entidade}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Manutenção`}
      component={EntManutenção}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Documentos`}
      component={EntDocumentos}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Enderecos`}
      component={EntEnderecos}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Contatos`}
      component={EntContatos}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Vinculos`}
      component={EntVinculos}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Registros`}
      component={EntRegistros}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Registros/Mídias`}
      component={EntRegMidias}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Comissão`}
      component={EntComissão}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Aprovação`}
      component={EntAprovação}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Filiais`}
      component={EntFiliais}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Perfil`}
      component={EntPerfil}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Perfil/Comercial`}
      component={EntPerfilComercial}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Entidades/Operadora`}
      component={EntOperadora}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Documento`}
      component={DocumentoCapa}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Documento/Itens`}
      component={DocumentoItens}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Documento/Pagamento`}
      component={DocumentoPagamento}
    />
    <Route
      exact
      path={`${match.url}/Cadastros/Documento/Entrega`}
      component={DocumentoEntrega}
    />
    {/* MENU - TABELAS */}
    <Route exact path={`${match.url}/Tabelas`} component={Tabelas} />
    <Route exact path={`${match.url}/Tabela`} component={Tabela} />
    {/* MENU - DEFINIÇÕES */}
    <Route
      exact
      path={`${match.url}/Definições/Usuários`}
      component={Usuarios}
    />
    <Route exact path={`${match.url}/Definições/Rotinas`} component={Rotinas} />
    <Route
      exact
      path={`${match.url}/Definições/Rotinas/RotinaGrade`}
      component={RotinaGrade}
    />
    <Route
      exact
      path={`${match.url}/Definições/Rotinas/RotinaFiltro`}
      component={RotinaFiltro}
    />
    <Route exact path={`${match.url}/Definições/Sqls`} component={Sqls} />
    <Route
      exact
      path={`${match.url}/Definições/Sqls/Manutenção`}
      component={ManutençãoSql}
    />
    <Route exact path={`${match.url}/Definições/Menu`} component={Menu} />
    <Route
      exact
      path={`${match.url}/Definições/Regrasnovosdocumentos`}
      component={RegrasDocumento}
    />
    <Route exact path={`${match.url}/Definições/Perfil`} component={Perfil} />
    <Route
      exact
      path={`${match.url}/Definições/Perfil/Agenda`}
      component={PerfilAgenda}
    />
    <Route
      exact
      path={`${match.url}/Definições/Perfil/Comercial`}
      component={PerfilComercial}
    />
    <Route
      exact
      path={`${match.url}/Definições/Perfil/Financeiro`}
      component={PerfilFinanceiro}
    />
    <Route
      exact
      path={`${match.url}/Definições/Perfil/Produção`}
      component={PerfilProdução}
    />
    <Route
      exact
      path={`${match.url}/Definições/Perfil/Indicador`}
      component={PerfilIndicador}
    />
    <Route
      exact
      path={`${match.url}/Definições/Perfil/Menu`}
      component={PerfilMenu}
    />
  </>
);

export default withRouter(Routes);
