export const Types = {
  SET_FILTER_STATE: 'SET_FILTER_STATE',
  RESET_FILTER_STATE: 'RESET_FILTER_STATE',
};

const INIT_STATE = {
  filter: {},
};

export default function FilterState(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.SET_FILTER_STATE: {
      return {
        filter: { ...state.filter, ...action.payload },
      };
    }
    case Types.RESET_FILTER_STATE: {
      return {
        filter: {},
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  setFilterState: (payload) => ({
    type: Types.SET_FILTER_STATE,
    payload,
  }),

  resetFilterState: () => ({
    type: Types.RESET_FILTER_STATE,
  }),
};
