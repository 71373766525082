import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputMask from 'components/InputMask';
import Loading from 'components/Loading';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';

const ConciliarModal = ({ open, setOpen, rotina, selected }) => {
  const dispatch = useDispatch();
  const [dtlanc, setDtlanc] = useState(moment().format('DD/MM/YYYY'));
  const { putFinConciLoading, putFinConciSuccess } = useSelector(
    ({ lancamento }) => lancamento
  );

  useEffect(() => {
    if (putFinConciSuccess) {
      dispatch(LancamentoActions.putFinConciReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [putFinConciSuccess]);

  const onSubmit = () => {
    const Lancamento = selected.map((s) => ({ cxlancto_id: s?.id }));
    const data = {
      dtlanc: moment(dtlanc, 'DD/MM/YYYY').isValid()
        ? moment(dtlanc, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      conciliado: 'SIM',
      Lancamento,
    };
    dispatch(LancamentoActions.putFinConci(data));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Conciliar</DialogTitle>
      {putFinConciLoading ? (
        <Loading />
      ) : (
        <DialogContent>
          <InputMask
            name="dtlanc"
            label="Data de Lançamento"
            mask="99/99/9999"
            value={dtlanc}
            onChange={(e) => setDtlanc(e.target.value)}
            fullWidth
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onSubmit()}
          color="primary"
          disabled={putFinConciLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConciliarModal;
