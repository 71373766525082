import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core';
import api from 'util/api';
import moment from 'moment';
import { setDecimal } from 'util/index';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const AddVinculoModal = ({
  open,
  setOpen,
  filial,
  cadastro,
  produto,
  prdItem,
  onSubmit,
  classes,
}) => {
  const [vinculo, setVinculo] = useState(null);
  const [items, setItems] = useState([]);

  async function loadItems() {
    const { data } = await api.post(`/csql/929`, {
      WhereClause: `d.filial_id = ${filial} and d.cadastro_id = ${cadastro} and di.produto_id = ${produto}`,
    });
    if (data) {
      setItems(data.dados || []);
    }
  }

  useEffect(() => {
    if (open) loadItems();
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Adicionar Vínculo</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Data</TableCell>
                <TableCell>Filial</TableCell>
                <TableCell>Documento</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Produto</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Entregue</TableCell>
                <TableCell>Entregar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((i) => (
                <TableRow>
                  <TableCell>
                    <Checkbox
                      style={{ padding: '0 5px' }}
                      color="default"
                      checked={vinculo?.id === i?.id}
                      onChange={() => setVinculo(i)}
                    />
                  </TableCell>
                  <TableCell>
                    {moment(i?.dtpedido).format('DD/MM/YYYY') || null}
                  </TableCell>
                  <TableCell>{i?.filial}</TableCell>
                  <TableCell>{i?.documento}</TableCell>
                  <TableCell>{i?.nome}</TableCell>
                  <TableCell>{i?.produto}</TableCell>
                  <TableCell>{setDecimal(i?.quantidade, 2)}</TableCell>
                  <TableCell>{setDecimal(i?.entregue, 2)}</TableCell>
                  <TableCell>{setDecimal(i?.entregar, 2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() =>
            onSubmit({
              documento_item_id: vinculo?.id,
              documento_item_org_id: prdItem,
            })
          }
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(AddVinculoModal);
