import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import { Creators as SettingActions } from '../../store/ducks/Setting';
import SideBarContent from './SideBarContent';

const SideBar = () => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const { navCollapsed, drawerType, navigationStyle } = useSelector(
    ({ settings }) => settings
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  const onToggleCollapsedNav = (e) => {
    dispatch(SettingActions.toggleCollapsedNav());
  };

  let drawerStyle = drawerType?.includes('fixed_drawer')
    ? 'd-xl-flex'
    : drawerType?.includes('collapsible')
    ? ''
    : 'd-flex';
  let type = 'permanent';
  if (
    drawerType?.includes('collapsible') ||
    (drawerType?.includes('fixed_drawer') && width < 1200)
  ) {
    type = 'temporary';
  }

  if (navigationStyle === 'horizontal_navigation') {
    drawerStyle = '';
    type = 'temporary';
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type?.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
      >
        <UserInfo />
        <SideBarContent />
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
