import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, Button, IconButton } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import QualidadeModal from 'components/Modals/QualidadeModal';
import AvaliacaoModal from 'components/Modals/AvaliacaoModal';
import { useQuery } from 'util/index';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as TablesActions } from 'store/ducks/Tables';
import Loading from 'components/Loading';

const Qualidade = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery(useLocation);
  const id = query.get('id');
  const [openIndicadores, setOpenIndicadores] = useState(false);
  const [openAvaliacao, setOpenAvaliacao] = useState(false);
  const [selected, setSelected] = useState(null);
  const { saveSuccess, deleteSuccess } = useSelector(({ tables }) => tables);
  const { qualidade, getQualidadeLoading, postRespostaSuccess } = useSelector(
    ({ documento }) => documento
  );

  useEffect(() => {
    dispatch(DocumentoActions.getQualidade(Number(id)));
  }, []);

  useEffect(() => {
    if (saveSuccess || postRespostaSuccess) {
      dispatch(DocumentoActions.getQualidade(Number(id)));
    }
    if (deleteSuccess) {
      dispatch(TablesActions.deleteDataReset());
      dispatch(DocumentoActions.getQualidade(Number(id)));
    }
  }, [saveSuccess, postRespostaSuccess, deleteSuccess]);

  return (
    <div className="app-wrapper">
      <HeaderPage title="Qualidade" history={history} />
      {getQualidadeLoading ? (
        <Loading />
      ) : (
        <Card className="shadow">
          <CardContent>
            <div className="d-flex justify-content-end mb-3">
              <Button
                variant="contained"
                color="primary"
                className="jr-btn"
                color="primary"
                onClick={() => setOpenIndicadores(!openIndicadores)}
              >
                ADICIONAR INDICADOR
              </Button>
            </div>
            <h2 className="my-4">Índices de Qualidade</h2>
            {qualidade.map((q, i) => (
              <Card className="shadow mb-2">
                <CardContent className="p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{q?.qualidade_indicador}</span>
                    <div>
                      <IconButton
                        className="icon-btn"
                        onClick={() => {
                          setSelected(i);
                          setOpenAvaliacao(true);
                        }}
                      >
                        <AddIcon color="primary" />
                      </IconButton>
                      <IconButton
                        className="icon-btn"
                        onClick={() => {
                          dispatch(
                            TablesActions.deleteData({
                              table: 'documento_item_qualidade',
                              id: q?.documento_item_qualidade_id,
                            })
                          );
                        }}
                      >
                        <DeleteIcon color="primary" />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Card>
      )}
      <QualidadeModal
        open={openIndicadores}
        setOpen={setOpenIndicadores}
        idItem={id}
      />
      <AvaliacaoModal
        open={openAvaliacao}
        setOpen={setOpenAvaliacao}
        selected={qualidade[selected] || null}
      />
    </div>
  );
};

export default Qualidade;
