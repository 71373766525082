import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  IconButton,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import moment from 'moment';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { setDecimal, findOnArray, transformData } from 'util/index';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ProdutoModal from 'components/Modals/ProdutoModal';

const initialValues = {
  produto: null,
  disponivel: null,
  quantidade: null,
  itemDesc: null,
  infadc: null,
  unMed: null,
  przent: null,
  dhsaldo: null,
  preco: null,
  aplicacao: null,
  planoconta: null,
  centroCusto: null,
  frota: null,
  ajuste: null,
  defeito_id: null,
  garantia: null,
  contrato_item_id: null,
  tributo_id: null,
  percentual: null,
  situacao_tributaria_id: null,
};

const AddItemModal = ({
  open,
  setOpen,
  classes,
  items,
  onChooseProd,
  onClose,
  selected,
}) => {
  const dispatch = useDispatch();
  const [fill, setFill] = useState(false);
  const [tributos, setTributos] = useState([]);
  const [openProdModal, setOpenProdModal] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);  
  const {
    itensFields,
    documento,
    prodDoc,
    planoConta,
    custoItens,
    postDocItemSuccess,
    putDocItemSuccess,
    putDocItemLoading,
    postDocItemLoading,
  } = useSelector(({ documento }) => documento);

  function submitItem(values) {
    let data = {
      ...documento?.Documento,
      id: undefined,
      documento_id: documento?.Documento?.id,
      produto_id: values?.produto?.value,
      quantidade: transformData(values?.quantidade, 'number'),
      descricao: values?.itemDesc,
    };
    if (itensFields[0]?.iiinfadc === 'SIM') {
      data = { ...data, infadc: values?.infadc };
    }
    if (itensFields[0]?.iium === 'SIM') {
      data = { ...data, unidade_medida_id: values?.unMed?.value };
    }
    if (itensFields[0]?.iiprzent === 'SIM') {
      data = {
        ...data,
        przent: moment(values?.przent, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      };
    }
    if (itensFields[0]?.iidhcontagem === 'SIM') {
      data = {
        ...data,
        dhsaldo: moment(values?.dhsaldo, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        ),
      };
    }
    if (itensFields[0]?.iipreco === 'SIM') {
      data = { ...data, preco: transformData(values?.preco, 'number') };
    }
    if (itensFields[0]?.iiaplicacao === 'SIM') {
      data = { ...data, aplicacao_id: values?.aplicacao?.value };
    }
    if (itensFields[0]?.iiplanoconta === 'SIM') {
      data = { ...data, planoconta_id: values?.planoconta?.value };
    }
    if (itensFields[0]?.iiccusto === 'SIM') {
      data = { ...data, ccusto_id: values?.centroCusto?.value };
    }
    if (itensFields[0]?.iifrota === 'SIM') {
      data = { ...data, frota_id: values?.frota?.value };
    }
    if (itensFields[0]?.servico === 'SIM') {
      data = {
        ...data,
        defeito_id: values?.defeito_id?.value,
        garantia: values?.garantia,
        contrato_item_id: values?.contrato_item_id?.value,
      };
    }

    if (!!tributos?.length && !selected) {
      data = {
        ...data,
        Tributos: tributos.map((t) => ({
          tributo_id: t?.tributo?.value,
          percentual: transformData(t?.percentual, 'number'),
          situacao_tributaria_id: t?.situacao?.value,
        })),
      };
    }
    if (!!custoItens?.length) {
      data = {
        ...data,
        comissao_id: custoItens[0]?.comissao_id,
        comissao_pc: custoItens[0]?.comissao_pc
          ? transformData(setDecimal(custoItens[0]?.comissao_pc, 2), 'number')
          : null,
      };
    }

    if (selected) {
      dispatch(DocumentoActions.putDocItem({ id: selected?.id, data }));
    } else {
      dispatch(DocumentoActions.postDocItem(data));
    }
  }

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (!!custoItens?.length) {
        setValues({
          ...values,
          disponivel: setDecimal(custoItens[0]?.saldo, 3),
        });
        if (!documento?.Documento?.doc_nfechaveacesso) {
          setValues({
            ...values,
            preco: setDecimal(
              documento?.Documento?.doc_operacao === 'ENTRADA'
                ? custoItens[0]?.custo
                : custoItens[0]?.preco,
              documento?.Documento?.doc_operacao === 'ENTRADA' ? 6 : (authUser?.storedcnpj === "01717900000117") ? 4 : 2
            ),
          });
        }
      }
    }, [custoItens]);

    useEffect(() => {
      if (postDocItemSuccess) {
        dispatch(DocumentoActions.postDocItemReset());
        dispatch(DocumentoActions.getCustoReset());
        setValues(initialValues);
      }
      if (putDocItemSuccess) {
        dispatch(DocumentoActions.putDocItemReset());
        dispatch(DocumentoActions.getCustoReset());
        setValues(initialValues);
        setOpen(false);
      }
    }, [postDocItemSuccess, putDocItemSuccess]);

    useEffect(() => {
      if (selected && !fill) {
        setFill(true);
        if (selected?.produto_id) {
          //onChooseProd(selected?.produto_id);
        }
        setValues({
          ...values,
          produto: selected?.produto_id
            ? findOnArray(selected?.produto_id, prodDoc)
            : null,
          itemDesc: selected?.descricao,
          quantidade: setDecimal(selected?.quantidade || 0, 3),
          unMed: selected?.unidade_medida_id
            ? findOnArray(selected?.unidade_medida_id, items[4])
            : null,
          preco: selected?.preco
            ? setDecimal(
                selected?.preco,
                documento?.Documento?.doc_operacao === 'ENTRADA' ? 6 : (authUser?.storedcnpj === "01717900000117") ? 4 : 2
              )
            : null,
          infadc: selected?.infadc,
          przent: moment(selected?.przent, 'YYYY-MM-DD').isValid()
            ? moment(selected?.przent, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          planoconta: selected?.planoconta_id
            ? findOnArray(selected?.planoconta_id, planoConta)
            : null,
          centroCusto: selected?.ccusto_id
            ? findOnArray(selected?.ccusto_id, items[5])
            : null,
          aplicacao: selected?.aplicacao_id
            ? findOnArray(selected?.aplicacao_id, items[6])
            : null,
          frota: selected?.frota_id
            ? findOnArray(selected?.frota_id, items[7])
            : null,
          dhsaldo: moment(selected?.dhsaldo, 'YYYY-MM-DD HH:mm:ss').isValid()
            ? moment(selected?.dhsaldo, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )
            : null,
          ajuste: setDecimal(selected?.ajuste, 3),
        });
      }
    }, [selected]);
    return null;
  };

  return (
    <Dialog
      open={open}
      onExited={() => {
        onClose();
        setFill(false);
        setTributos([]);
        dispatch(DocumentoActions.getCustoReset());
      }}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.modal }}
    >
      <DialogTitle>{selected ? 'Editar Item' : 'Adicionar Item'}</DialogTitle>
      <Formik initialValues={initialValues}>
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <DialogContent>
              <Grid container className="my-4">
                <Grid xs="12" sm="9" md="9" lg="9" className={classes.grid}>
                  <SelectAuto
                    label="Produto"
                    items={prodDoc}
                    value={values.produto}
                    disabled
                  />
                </Grid>
                <Grid xs="2" sm="1" md="1" lg="1" className={classes.grPlus}>
                  <IconButton
                    className="icon-btn"
                    onClick={() => setOpenProdModal(true)}
                  >
                    <AddIcon color="primary" />
                  </IconButton>
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="disponivel"
                    label="Disponível"
                    value={values?.disponivel || ''}
                    onChange={handleChange}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="itemDesc"
                    label="Descrição do item"
                    value={values.itemDesc || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <TextField
                    name="quantidade"
                    label="Quantidade"
                    value={values.quantidade || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('quantidade', setDecimal(e.target.value, 3))
                    }
                    fullWidth
                  />
                </Grid>
                {itensFields[0]?.iium === 'SIM' && (
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Unidade de Medida"
                      items={items[4]}
                      value={values.unMed || ''}
                      onChange={(v) => setFieldValue('unMed', v)}
                    />
                  </Grid>
                )}
                {itensFields[0]?.iipreco === 'SIM' && (
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="preco"
                      label="Preço"
                      value={values.preco || ''}
                      onChange={handleChange}
                      onBlur={(e) => {
                        if (documento?.Documento?.doc_operacao === 'ENTRADA') {
                          setFieldValue('custo', setDecimal(e.target.value, 6));
                        } else {
                          if (authUser?.storedcnpj === "01717900000117")
                            setFieldValue('preco', setDecimal(e.target.value, 4));
                          else   
                            setFieldValue('preco', setDecimal(e.target.value, 2));                          
                        }
                      }}
                      fullWidth
                    />
                  </Grid>
                )}
                {itensFields[0]?.iiinfadc === 'SIM' && (
                  <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                    <TextField
                      name="infadc"
                      label="Informações Adicionais"
                      value={values.infadc || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                )}
                {itensFields[0]?.iiprzent === 'SIM' && (
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <InputMask
                      name="przent"
                      label="Prazo de Entrega"
                      mask="99/99/9999"
                      value={values.przent || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                )}
                {itensFields[0]?.iiplanoconta === 'SIM' && (
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Plano Conta"
                      items={planoConta}
                      value={values.planoconta || ''}
                      onChange={(v) => setFieldValue('planoconta', v)}
                    />
                  </Grid>
                )}
                {itensFields[0]?.iiccusto === 'SIM' && (
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Centro de Custo"
                      items={items[5]}
                      value={values.centroCusto || ''}
                      onChange={(v) => setFieldValue('centroCusto', v)}
                    />
                  </Grid>
                )}
                {itensFields[0]?.iiaplicacao === 'SIM' && (
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Aplicação"
                      items={items[6]}
                      value={values.aplicacao || ''}
                      onChange={(v) => setFieldValue('aplicacao', v)}
                    />
                  </Grid>
                )}
                {itensFields[0]?.iifrota === 'SIM' && (
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Frota"
                      items={items[7]}
                      value={values.frota || ''}
                      onChange={(v) => setFieldValue('frota', v)}
                    />
                  </Grid>
                )}
                {itensFields[0]?.iidhcontagem === 'SIM' && (
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <InputMask
                      name="dhsaldo"
                      label="Data/Hora contagem"
                      mask="99/99/9999 99:99:99"
                      value={values.dhsaldo || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                )}
                {itensFields[0]?.iiajcontagem === 'SIM' && (
                  <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="ajuste"
                      label="Ajuste"
                      value={values?.ajuste || ''}
                      onChange={handleChange}
                      disabled
                      fullWidth
                    />
                  </Grid>
                )}
                {itensFields[0]?.servico === 'SIM' && (
                  <>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Contrato"
                        items={items[9]}
                        value={values.contrato_item_id || ''}
                        onChange={(v) => setFieldValue('contrato_item_id', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Defeito"
                        items={items[10]}
                        value={values.garantia || ''}
                        onChange={(v) => setFieldValue('garantia', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="garantia"
                        label="Garantia"
                        value={values?.garantia || ''}
                        onChange={handleChange}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                {custoItens[0]?.tributacao === 'SIM' && !selected && (
                  <>
                    <Grid xs="12" sm="12" md="12" lg="12">
                      <DialogTitle className="p-0">Tributos</DialogTitle>
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Tributo"
                        items={items[11]}
                        value={values.tributo_id || ''}
                        onChange={(v) => setFieldValue('tributo_id', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                      <TextField
                        name="percentual"
                        label="Percentual"
                        value={values?.percentual || ''}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'percentual',
                            setDecimal(e.target.value, 2)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Situação Tributária"
                        items={items[12].filter(
                          (f) => f?.tributo_id === values?.tributo_id?.value
                        )}
                        value={values.situacao_tributaria_id || ''}
                        onChange={(v) =>
                          setFieldValue('situacao_tributaria_id', v)
                        }
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="2"
                      md="2"
                      lg="2"
                      className={classes.grid}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <IconButton
                        className="icon-btn"
                        onClick={() => {
                          setTributos([
                            ...tributos,
                            {
                              tributo: values?.tributo_id,
                              percentual: values?.percentual,
                              situacao: values?.situacao_tributaria_id,
                            },
                          ]);
                          setFieldValue('tributo_id', null);
                          setFieldValue('percentual', '');
                          setFieldValue('situacao_tributaria_id', null);
                        }}
                      >
                        <AddIcon color="primary" />
                      </IconButton>
                    </Grid>
                    {!!tributos.length && (
                      <TableContainer component={Paper} className="my-3">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Tributo</TableCell>
                              <TableCell>Percentual</TableCell>
                              <TableCell>Situação</TableCell>
                              <TableCell align="right">Ação</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tributos.map((m, i) => (
                              <TableRow>
                                <TableCell>{m?.tributo?.label}</TableCell>
                                <TableCell>
                                  {setDecimal(m?.percentual, 2)}
                                </TableCell>
                                <TableCell>{m?.situacao?.label}</TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                >
                                  <DeleteIcon
                                    color="action"
                                    onClick={() =>
                                      setTributos(
                                        tributos.filter((_, id) => id !== i)
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </>
                )}
              </Grid>
              <ProdutoModal
                open={openProdModal}
                setOpen={setOpenProdModal}
                documento={documento}
                onSubmit={(v) => {
                  if (v) {
                    if (!values?.quantidade) {
                      setFieldValue('quantidade', '1,000');
                    }
                    setFieldValue('produto', v);
                    setFieldValue('itemDesc', v?.descricao);
                    if (!values?.przent) {
                      setFieldValue(
                        'przent',
                        moment(v?.przent, 'YYYY-MM-DD').format('DD/MM/YYYY')
                      );
                    }
                    setFieldValue(
                      'unMed',
                      findOnArray(v?.unidade_medida_id, items[4])
                    );
                    if (v) {
                      onChooseProd(v);
                    }
                    if (v?.lotemin && v?.lotemin !== '0.000') {
                      setFieldValue(
                        'quantidade',
                        setDecimal(v?.lotemin || '', 3)
                      );
                    }
                    if (
                      documento?.Documento?.doc_operacao === 'SAIDA' &&
                      documento?.Documento?.doc_indvndcmp === 'SIM' &&
                      v?.lotemult &&
                      v?.lotemult !== '0.000'
                    ) {
                      setFieldValue(
                        'quantidade',
                        setDecimal(v?.lotemult || '', 3)
                      );
                    }
                    if (
                      documento?.Documento?.doc_operacao === 'ENTRADA' &&
                      documento?.Documento?.doc_indvndcmp === 'SIM' &&
                      v?.loterepos &&
                      v?.loterepos !== '0.000'
                    ) {
                      setFieldValue(
                        'quantidade',
                        setDecimal(v?.loterepos || '', 3)
                      );
                    }
                  }
                  setOpenProdModal(false);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => submitItem(values)}
                color="primary"
                disabled={postDocItemLoading || putDocItemLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default AddItemModal;
