import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import Notes from '@material-ui/icons/Notes';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import PostAdd from '@material-ui/icons/PostAdd';
import PrintIcon from '@material-ui/icons/Print';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ClearIcon from '@material-ui/icons/Clear';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CheckIcon from '@material-ui/icons/Check';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BuildIcon from '@material-ui/icons/Build';
import NewReleases from '@material-ui/icons/NewReleases';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import FormatTextdirectionLToRIcon from '@material-ui/icons/FormatTextdirectionLToR';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import EmailIcon from '@material-ui/icons/Email';

const actionIcon = {
  Adicionar: <AddRoundedIcon fontSize="small" className="mr-4" />,
  Filtrar: <FilterListRoundedIcon fontSize="small" className="mr-4" />,
  Visualizar: <ViewComfyIcon fontSize="small" className="mr-4" />,
  PDF: <PictureAsPdfIcon fontSize="small" className="mr-4" />,
  XLS: <DescriptionIcon fontSize="small" className="mr-4" />,
  Calculo: <Notes fontSize="small" className="mr-4" />,
  Reposicao: <ShoppingCart fontSize="small" className="mr-4" />,
  Gerar: <PostAdd fontSize="small" className="mr-4" />,
  Imprimir: <PrintIcon fontSize="small" className="mr-4" />,
  Preço: <MonetizationOnIcon fontSize="small" className="mr-4" />,
  Limpar: <ClearIcon fontSize="small" className="mr-4" />,
  Carga: <LocalShippingIcon fontSize="small" className="mr-4" />,
  Recebimento: <ReceiptIcon fontSize="small" className="mr-4" />,
  Concluir: <CheckIcon fontSize="small" className="mr-4" />,
  Copiar: <FileCopyIcon fontSize="small" className="mr-4" />,
  Cheque: <LocalAtmIcon fontSize="small" className="mr-4" />,
  Cartão: <CreditCardIcon fontSize="small" className="mr-4" />,
  Compensação: <CompareArrowsIcon fontSize="small" className="mr-4" />,
  Upload: <CloudUploadIcon fontSize="small" className="mr-4" />,
  Manutenção: <BuildIcon fontSize="small" className="mr-4" />,
  Aprovação: <NewReleases fontSize="small" className="mr-4" />,
  Atualizar: <SystemUpdateAltIcon fontSize="small" className="mr-4" />,
  Exportar: <OpenInBrowserIcon fontSize="small" className="mr-4" />,
  Layout: <FormatTextdirectionLToRIcon fontSize="small" className="mr-4" />,
  Calendario: <CalendarToday fontSize="small" className="mr-4" />,
  Nova: <ScreenShareIcon fontSize="small" className="mr-4" />,
  Email: <EmailIcon fontSize="small" className="mr-4" />,
};

const TableOptions = ({ actions }) => {
  const [menuOpcoes, setMenuOpcoes] = useState({ open: false });
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className="jr-btn"
        onClick={({ currentTarget }) =>
          setMenuOpcoes({ open: true, currentTarget })
        }
      >
        <MenuRoundedIcon fontSize="small" className="mr-1" />
        <span>Opções</span>
      </Button>
      <Menu
        id="long-menu"
        anchorEl={menuOpcoes?.currentTarget}
        open={menuOpcoes?.open}
        onClose={() => setMenuOpcoes({ open: false })}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        {actions?.map(({ name, action, visible, type }) => {
          if (visible)
            return (
              <>
                <MenuItem
                  onClick={() => {
                    action();
                    setMenuOpcoes({ open: false });
                  }}
                >
                  {actionIcon[type]}
                  {name}
                </MenuItem>
                <Divider />
              </>
            );
        })}
      </Menu>
    </>
  );
};
export default TableOptions;
