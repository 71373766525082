import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { useDispatch } from 'react-redux';
import api from 'util/api';
import moment from 'moment';
import { Creators as ContabilActions } from 'store/ducks/Contabil';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const FechamentoModal = ({ open, setOpen, rotina, loading, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [drop, setDrop] = useState([]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  async function loadDrop(valor) {
    const { data } = await api.post(`/csql/606`, {
      Parametros: [{ nome: ':filial_id', valor }],
    });
    if (data) {
      setDrop(data.dados || []);
    }
  }

  useEffect(() => {
    if (open) {
      loadItems([885]);
    }
  }, [open]);

  const onSubmit = (values) => {
    const data = {
      planoentidade_id: values?.planoentidade_id?.value,
      // ano: values?.ano,
      datai: moment(values?.datai, 'DD/MM/YYYY').isValid()
        ? moment(values?.datai, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      dataf: moment(values?.dataf, 'DD/MM/YYYY').isValid()
        ? moment(values?.dataf, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      ctaresultado_id: values?.ctaresultado_id?.value,
      ctalucro_id: values?.ctalucro_id?.value,
      ctaprejuizo_id: values?.ctaprejuizo_id?.value,
      estorno: values?.estorno?.value,
    };
    dispatch(ContabilActions.postFechamento(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Fechamento</DialogTitle>
      <Formik
        initialValues={{
          planoentidade_id: null,
//        ano: moment().format('YYYY'),
          datai: `01/01/${moment().format('YYYY')}`,
          dataf: `31/12/${moment().format('YYYY')}`,
          ctaresultado_id: null,
          ctalucro_id: null,
          ctaprejuizo_id: null,
          estorno: null,
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => (
          <>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade / Plano de Contas"
                    items={items[0] || []}
                    value={values.planoentidade_id}
                    onChange={(v) => {
                      setFieldValue('planoentidade_id', v);
                      if (v) {
                        loadDrop(v?.entidade_id);
                      }
                    }}
                  />
                </Grid>
                {/* <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="ano"
                    label="Ano"
                    value={values?.ano}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid> */}
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                    name="datai"
                    label="Data inicio "
                    mask="99/99/9999"
                    value={values.datai}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                    name="dataf"
                    label="Data fim"
                    mask="99/99/9999"
                    value={values.dataf}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Resultado"
                    items={drop}
                    value={values.ctaresultado_id}
                    onChange={(v) => setFieldValue('ctaresultado_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Lucro"
                    items={drop}
                    value={values.ctalucro_id}
                    onChange={(v) => setFieldValue('ctalucro_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Prejuízo"
                    items={drop}
                    value={values.ctaprejuizo_id}
                    onChange={(v) => setFieldValue('ctaprejuizo_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Estornar Movimento"
                    items={yn}
                    value={values.estorno}
                    onChange={(v) => setFieldValue('estorno', v)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
            {loading && <CircularProgress size={20} />}
              <Button color="primary" onClick={handleSubmit} disabled={loading}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(FechamentoModal);
