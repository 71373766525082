export const Types = {
  POST_GNRERETORNO: 'POST_GNRERETORNO',
  POST_GNRERETORNO_SUCCESS: 'POST_GNRERETORNO_SUCCESS',
  POST_GNRERETORNO_RESET: 'POST_GNRERETORNO_RESET',
};

const INITIAL_STATE = {
  getLoading: false,
  gnre: [],
};

export default function gnreretorno(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_GNRERETORNO:
      return {
        ...state,
        getLoading: true,
        gnre: [],
      };
    case Types.POST_GNRERETORNO_SUCCESS:
      return {
        ...state,
        getLoading: false,
        gnre: action.payload.gnre,
      };
    case Types.POST_GNRERETORNO_RESET:
      return {
        ...state,
        getLoading: false,
        gnre: [],
      };
    default:
      return state;
  }
}

export const Creators = {
  postGNRERetorno: (payload) => ({
    type: Types.POST_GNRERETORNO,
    payload,
  }),
  postGNRERetornoSuccess: (payload) => ({
    type: Types.POST_GNRERETORNO_SUCCESS,
    payload,
  }),
  postGNRERetornoReset: () => ({
    type: Types.POST_GNRERETORNO_RESET,
  }),
};
