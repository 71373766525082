import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { findOnArray, setDecimal, useQuery } from 'util/index';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import EntidadeModal from 'components/Modals/EntidadeModal';
import api from 'util/api';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as IbgeActions } from 'store/ducks/Ibge';
import {
  isDocumento,
  capaForm,
  isSaida,
  isEntrada,
  isServico,
  isCompras,
  isContrato,
  isExportacao,
  isImportacao,
  isReqCompra,
  isCotCompra,
  isPedCompra,
  isFiscal,
} from './mock';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  subtitle: {
    padding: '0 0.5rem',
  },
  modal: {
    maxWidth: '80%',
  },
  acc: {
    cursor: 'default',
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const Capa = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [entidadeLoading, setEntidadeLoading] = useState(true);
  const [fill, setFill] = useState(false);
  const [registrada, setRegistrada] = useState(null);
  const [localFornecimento, setLocalFornecimento] = useState([]);
  const [dropEntidade, setDropEntidade] = useState([]);
  const [openEntidadeModal, setOpenEntidadeModal] = useState(false);
  const param = query.get('id');
  const natureza = query.get('natureza');
  const especie = query.get('especie');
  const steps = ['Documento', 'Itens', 'Pagamento', 'Entrega'];
  const { data: entComplemento } = useSelector(({ tables }) => tables);
  const {
    documento,
    loading,
    capaLoading,
    capaSuccess,
    idRegistred,
    userPerfil,
    deleteDocLoading,
    deleteDocSuccess,
    dadosAdc,
  } = useSelector(({ documento }) => documento);
  const { estados } = useSelector(({ ibge }) => ibge);
  const { authUser } = useSelector(({ auth }) => auth);
  const { entRegistrada } = useSelector(({ entidades }) => entidades);

  function onSubmit(values) {
    dispatch(
      DocumentoActions.postCapa(capaForm(values, authUser?.storedId, documento))
    );
  }

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  async function getLocalForn(v) {
    if (v) {
      const { data } = await api.post(`/csql/696`, {
        Parametros: [
          {
            nome: ':id',
            valor: v?.value || v,
          },
        ],
      });
      setLocalFornecimento(data?.dados || []);
    }
  }

  async function getEntidade() {
    const { data } = await api.post(`/csql/633`);
    setDropEntidade(data?.dados || []);
    setEntidadeLoading(false);
  }

  useEffect(() => {
    setFill(false);
    dispatch(TablesActions.getDataTableReset());
    dispatch(IbgeActions.getEstados());
    getEntidade();
    loadItems([
      642,
      621,
      632,
      644,
      616,
      623,
      640,
      635,
      697,
      698,
      706,
      707,
      624,
      816,
      817,
      625,
      990,
    ]);
    dispatch(DocumentoActions.getDocumentoReset());
    dispatch(DocumentoActions.postCapaResetId());
    if (param) {
      setFill(false);
      dispatch(DocumentoActions.getDocumento(param));
    } else {
      dispatch(DocumentoActions.userPerfil({ modulo: 'COMERCIAL' }));
    }
  }, []);

  useEffect(() => {
    if (idRegistred && !param) {
      dispatch(DocumentoActions.getDocumento(idRegistred));
      dispatch(DocumentoActions.postCapaReset());
      history.push(`/app/Cadastros/Documento/Itens?id=${idRegistred}`);
    }
  }, [idRegistred]);

  useEffect(() => {
    if (capaSuccess) {
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
    }
  }, [capaSuccess]);

  useEffect(() => {
    if (deleteDocSuccess) {
      dispatch(DocumentoActions.deleteDocumentoReset());
      history.goBack();
    }
  }, [deleteDocSuccess]);

  const SetFields = () => {
    const { setFieldValue, setValues, values } = useFormikContext();

    useEffect(() => {
      if (entRegistrada) {
        getEntidade();
        setRegistrada(entRegistrada);
      }
      if (!!findOnArray(registrada, dropEntidade)) {
        setValues({
          ...values,
          entidade: findOnArray(registrada, dropEntidade),
        });
        setRegistrada(null);
      }
    }, [entRegistrada, registrada]);

    useEffect(() => {
      if (!!localFornecimento.length) {
        setValues({
          ...values,
          localForn: findOnArray(
            documento?.Saida?.entidade_endereco_id,
            localFornecimento
          ),
        });
      }
    }, [localFornecimento]);

    useEffect(() => {
      if (!!dadosAdc) {
        setValues({
          ...values,
          adicionais: dadosAdc,
        });
        dispatch(DocumentoActions.getDadosAdcReset());
      }
    }, [dadosAdc]);

    useEffect(() => {
      if (entComplemento?.length) {
        const {
          contribuinte_id,
          usofinal,
          vendedor_id,
          condicao_pagamento_id,
        } = entComplemento[0];
        setFieldValue('contribuinte', findOnArray(contribuinte_id, items[4]));
        setFieldValue('usoFinal', findOnArray(usofinal, yn));
        if (vendedor_id) {
          setFieldValue('vendedor', findOnArray(vendedor_id, items[5]));
        }
        if (condicao_pagamento_id) {
          setFieldValue(
            'condPag',
            findOnArray(condicao_pagamento_id, items[6])
          );
        }
      }
      if ((natureza || especie) && userPerfil && !fill) {
        setFill(true);
        setValues({
          ...values,
          especie:
            especie !== 'undefined'
              ? findOnArray(Number(especie), items[0])
              : findOnArray(userPerfil?.especie_id, items[0]),
          naturezaOperacao:
            natureza !== 'undefined'
              ? findOnArray(Number(natureza), items[3])
              : findOnArray(userPerfil?.natureza_operacao_id, items[3]),
          contribuinte: userPerfil?.contribuinte_id
            ? findOnArray(userPerfil?.contribuinte_id, items[4])
            : null,
          usoFinal: userPerfil?.usofinal
            ? findOnArray(userPerfil?.usofinal, yn)
            : null,
          vendedor: findOnArray(userPerfil?.vendedor_id, items[5])
            ? findOnArray(userPerfil?.vendedor_id, items[5])
            : null,
          filial: userPerfil?.filial_id
            ? findOnArray(userPerfil?.filial_id, items[1])
            : null,
        });
      }
      if (documento && !entidadeLoading && !fill) {
        setFill(true);
        if (documento?.Documento?.cadastro_id) {
          getLocalForn(documento?.Documento?.cadastro_id);
        }
        setValues({
          ...values,
          naturezaOperacao: findOnArray(
            documento?.Documento?.natureza_operacao_id,
            items[3]
          ),
          especie: findOnArray(documento?.Documento?.especie_id, items[0]),
          filial: findOnArray(documento?.Documento?.filial_id, items[1]),
          documento: documento?.Documento?.documento,
          dtemissao: moment(documento?.Documento?.dtemissao).isValid()
            ? moment(documento?.Documento?.dtemissao).format('DD/MM/YYYY HH:mm:ss')
            : null,
          moeda: findOnArray(documento?.Moeda?.moeda_id, items[2]),
          valor: documento?.Moeda?.cotacao
            ? setDecimal(documento?.Moeda?.cotacao, 2)
            : null,
          entidade: findOnArray(
            documento?.Documento?.cadastro_id,
            dropEntidade
          ),
          numDocumento: documento?.Saida?.cpf,
          contribuinte: findOnArray(
            documento?.Documento?.contribuinte_id,
            items[4]
          ),
          usoFinal: findOnArray(documento?.Documento?.usofinal, yn),
          indpres: findOnArray(
            documento?.Documento?.indpres,
            items[16]
          ),
          celocal_id: documento?.Documento?.celocal_id
            ? findOnArray(documento?.Documento?.celocal_id, items[12])
            : null,
          //SAIDAS
          numPedido: documento?.Saida?.ped_cliente,
          dtAtendimento: moment(documento?.Saida?.dtatend).isValid()
            ? moment(documento?.Saida?.dtatend).format('DD/MM/YYYY')
            : null,
          vendedor: findOnArray(documento?.Saida?.vendedor_id, items[5]),
          condPag: findOnArray(
            documento?.Saida?.condicao_pagamento_id,
            items[6]
          ),
          //ENTRADAS
          serie: documento?.Entrada?.serie,
          dtEntrada:
            documento?.Entrada?.dtentrada &&
            moment(documento?.Entrada?.dtentrada).isValid()
              ? moment(documento?.Entrada?.dtentrada).format('DD/MM/YYYY')
              : null,
          //COMPRAS
          requisitante: findOnArray(documento?.ReqCompra?.users_id, items[7]),
          licitacao: documento?.CotCompra?.licitacao,
          entrega:
            documento?.PedCompra?.entrega || documento?.CotCompra?.entrega,
          inspecionar: findOnArray(documento?.PedCompra?.inspecao, yn),
          contato: documento?.PedCompra?.contato,
          //CONTRATO
          tipo: findOnArray(documento?.Contrato?.tipocont, items[8]),
          reajuste: findOnArray(documento?.Contrato?.reajuste, items[9]),
          dtInicio: moment(documento?.Contrato?.Contrato).isValid()
            ? moment(documento?.Contrato?.Contrato).format('DD/MM/YYYY')
            : null,
          //EXPORTACAO
          ufEmbarque: findOnArray(
            documento?.Exportacao?.ufembarque_id,
            items[15]
          ),
          localEmbarque: documento?.Exportacao?.locembarque,
          //IMPORTACAO
          numeroDi: documento?.Importacao?.numero,
          dataDi: moment(documento?.Importacao?.data).isValid()
            ? moment(documento?.Importacao?.data).format('DD/MM/YYYY')
            : null,
          viaTransporte: findOnArray(
            documento?.Importacao?.tpviatransp,
            items[10]
          ),
          localDesembaraco: documento?.Importacao?.desembaraco_loc,
          ufDesembaraco: findOnArray(
            documento?.Importacao?.desembaraco_uf,
            estados
          ),
          dataDesembaraco: moment(
            documento?.Importacao?.desembaraco_dat
          ).isValid()
            ? moment(documento?.Importacao?.desembaraco_dat).format(
                'DD/MM/YYYY'
              )
            : null,
          intermedio: documento?.Importacao?.tpintermedio
            ? findOnArray(documento?.Importacao?.tpintermedio, items[11])
            : null,
          afrmm: documento?.Importacao?.vafrmm,
          // GERAL
          observacoes: documento?.Documento?.observacao,
          adicionais: documento?.Documento?.dadosadc,
          // SERVICO
          solicitacao: documento?.Servico?.solicitacao,
          defeito: documento?.Servico?.defeito,
          mail_contato: documento?.Servico?.mail_contato,
          garantia: documento?.Servico?.garantia,
          contrato: findOnArray(
            documento?.Servico?.contrato_item_id,
            items[13]
          ),
          defeitos: documento?.Servico?.defeitos
            ? documento?.Servico?.defeitos?.map((d) =>
                findOnArray(d, items[14])
              )
            : [],
        });
      }
    }, [entComplemento]);
    return null;
  };

  function getComplementoEntidade(v, values) {
    getLocalForn(v);
    if (v) {
      dispatch(
        TablesActions.getDataTable({
          csql: 10,
          params: {
            Parametros: [
              {
                nome: ':id',
                valor: v?.value,
              },
              {
                nome: ':tipo',
                valor:
                  values?.naturezaOperacao?.operacao === 'ENTRADA'
                    ? 'FORNECEDOR'
                    : 'CLIENTE',
              },
            ],
          },
        })
      );
    }
  }

  if (localLoading || loading || entidadeLoading) {
    return <Loading />;
  }

  return (
    <div className="app-wrapper">
      <HeaderPage
        title="Documento"
        history={(param || natureza || especie) && history}
      />
      <Formik
        initialValues={{
          naturezaOperacao: null,
          especie: null,
          filial: null,
          documento: null,
          dtemissao: moment().format('DD/MM/YYYY HH:mm:ss'),
          moeda: null,
          valor: null,
          entidade: null,
          numDocumento: null,
          contribuinte: null,
          usoFinal: null,
          indpres: null,          
          celocal_id: null,
          //SAIDAS
          numPedido: null,
          dtAtendimento: moment()
//          .add(7, 'days')
            .format('DD/MM/YYYY'),
          vendedor: null,
          condPag: null,
          localForn: null,
          //ENTRADAS
          serie: null,
          dtEntrada: null,
          //COMPRAS
          requisitante: null,
          licitacao: null,
          entrega: null,
          inspecionar: null,
          contato: null,
          //CONTRATO
          tipo: null,
          reajuste: null,
          dtInicio: null,
          dtTermino: null,
          //EXPORTACAO
          ufEmbarque: null,
          localEmbarque: null,
          //IMPORTACAO
          numeroDi: null,
          dataDi: null,
          viaTransporte: null,
          localDesembaraco: null,
          ufDesembaraco: null,
          dataDesembaraco: null,
          intermedio: null,
          afrmm: null,
          //GERAL
          observacoes: null,
          adicionais: null,
          //SERVICOS
          solicitacao: null,
          defeito: null,
          mail_contato: null,
          garantia: null,
          contrato: null,
          defeitos: [],
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            {param && (
              <Card className="shadow">
                <CardContent>
                  <Grid container>
                    <Grid xs="12" sm="6" md="6" lg="6">
                      <span className="font-weight-bold">Documento:</span>
                      <span> {documento?.Documento?.id}</span>
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6">
                      <span className="font-weight-bold">Status:</span>
                      <span> {documento?.Documento?.doc_status}</span>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            <Card className="shadow" style={param && { marginTop: '1rem' }}>
              <CardContent>
                <Stepper activeStep={0} alternativeLabel className="p-0">
                  {steps.map((label) => {
                    return (
                      <Step
                        key={label}
                        onClick={() => {
                          if (param) {
                            history.push(
                              `/app/Cadastros/Documento${
                                label !== 'Documento' ? `/${label}` : ''
                              }?id=${param}`
                            );
                          }
                        }}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </CardContent>
            </Card>
            <Card className="shadow" style={{ marginTop: '1rem' }}>
              <CardContent>
                <Grid container>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Natureza da Operação"
                      items={items[3]}
                      value={values.naturezaOperacao}
                      onChange={(v) => setFieldValue('naturezaOperacao', v)}
                      disabled={documento?.Documento?.sleitura === 'SIM'}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Espécie de Documento"
                      items={
                        values?.naturezaOperacao?.vinculo === 'SIM' ||
                        values?.naturezaOperacao?.natureza === 'IMPORTACAO'
                          ? items[0]
                          : items[0].filter(
                              (e) =>
                                e.grupo === values?.naturezaOperacao?.operacao
                            )
                      }
                      value={values.especie}
                      onChange={(v) => {
                        setFieldValue('especie', v);
                        if (v?.entidade === 'NAO') {
                          setFieldValue(
                            'contribuinte',
                            findOnArray(3, items[4])
                          );
                          setFieldValue('usoFinal', findOnArray('NAO', yn));
                        }
                      }}
                      disabled={documento?.Documento?.sleitura === 'SIM'}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                    <SelectAuto
                      label="Filial"
                      items={items[1]}
                      value={values.filial}
                      onChange={(v) => setFieldValue('filial', v)}
                      disabled={documento?.Documento?.sleitura === 'SIM'}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="documento"
                      label="Documento"
                      value={values.documento}
                      onChange={handleChange}
                      fullWidth
                      disabled={
                        documento?.Documento?.sleitura === 'SIM' ||
                        documento?.Documento?.doc_natureza === 'FATURA'
                      }
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="3" lg="3" className={classes.grid}>
                    <InputMask
                      name="dtemissao"
                      label="Data Emissão"
                      mask="99/99/9999 99:99:99"
                      value={values.dtemissao}
                      onChange={handleChange}
                      fullWidth
                      disabled={documento?.Documento?.sleitura === 'SIM'}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="2" lg="2" className={classes.grid}>
                    <SelectAuto
                      label="Moeda"
                      items={items[2]}
                      value={values.moeda}
                      onChange={(v) => setFieldValue('moeda', v)}
                      disabled={documento?.Documento?.sleitura === 'SIM'}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="valor"
                      label="Valor Moeda R$"
                      value={values.valor}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('valor', setDecimal(e.target.value, 2))
                      }
                      fullWidth
                      disabled={documento?.Documento?.sleitura === 'SIM'}
                    />
                  </Grid>
                  {(values?.especie?.entidade !== 'NAO' ||
                    values?.especie?.especie === 'CF-e') && (
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <div className="d-flex">
                        <SelectAuto
                          label="Entidade"
                          items={dropEntidade}
                          value={values.entidade}
                          onChange={(v) => {
                            setFieldValue('entidade', v);
                            getComplementoEntidade(v, values);
                          }}
                          disabled={documento?.Documento?.sleitura === 'SIM'}
                          className="w-100"
                        />
                        {!param && (
                          <IconButton
                            className="icon-btn"
                            onClick={() => setOpenEntidadeModal(true)}
                          >
                            <AddIcon color="primary" />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  )}
                  {isDocumento(values) && (
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="numDocumento"
                        label="CPF / CNPJ"
                        value={values.numDocumento}
                        onChange={handleChange}
                        fullWidth
                        disabled={documento?.Documento?.sleitura === 'SIM'}
                        onBlur={(e) => {
                          let parseDoc = e.target.value.replace(
                            /([\/\.\-])/g,
                            ''
                          );
                          if (parseDoc.length === 14) {
                            parseDoc = `${parseDoc.substr(
                              0,
                              2
                            )}.${parseDoc.substr(2, 3)}.${parseDoc.substr(
                              5,
                              3
                            )}/${parseDoc.substr(8, 4)}-${parseDoc.substr(
                              12,
                              2
                            )}`;
                          }
                          if (parseDoc.length === 11) {
                            parseDoc = `${parseDoc.substr(
                              0,
                              3
                            )}.${parseDoc.substr(3, 3)}.${parseDoc.substr(
                              6,
                              3
                            )}-${parseDoc.substr(9, 2)}`;
                          }
                          setFieldValue('numDocumento', parseDoc);
                        }}
                      />
                    </Grid>
                  )}
                  {values?.especie?.natureza !== 'SERVICO' &&
                    values?.especie?.fiscal == 'SIM' && (
                      <Grid
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Contribuinte"
                          items={items[4]}
                          value={values.contribuinte}
                          onChange={(v) => setFieldValue('contribuinte', v)}
                          disabled={documento?.Documento?.sleitura === 'SIM'}
                        />
                      </Grid>
                    )}
                  {values?.especie?.fiscal == 'SIM' && (
                    <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                      <SelectAuto
                        label="Uso Final"
                        items={yn}
                        value={values.usoFinal}
                        onChange={(v) => setFieldValue('usoFinal', v)}
                        disabled={documento?.Documento?.sleitura === 'SIM'}
                      />
                    </Grid>
                  )}
                  {values?.especie?.fiscal == 'SIM' && (
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Indicador Presencial"
                        items={items[16]}
                        value={values.indpres}
                        onChange={(v) => setFieldValue('indpres', v)}
                        disabled={documento?.Documento?.sleitura === 'SIM'}
                      />
                    </Grid>
                  )}
                  { /* (values?.naturezaOperacao?.natureza === 'COMPRA' || 
                    values?.especie?.especie === 'INVENTARIO' ||
                    values?.especie?.especie === 'MOVIMENTAÇÃO DE ESTOQUE' ||
                    values?.naturezaOperacao?.natureza === 'SERVICOS' || 
                    values?.naturezaOperacao?.devolucao === 'SIM') &&*/ (
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Local de Estoque"
                        items={items[12]}
                        value={values.celocal_id}
                        onChange={(v) => setFieldValue('celocal_id', v)}
                        disabled={documento?.Documento?.sleitura === 'SIM'}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>

            <Card className="shadow" style={{ marginTop: '1rem' }}>
              <CardContent>
                <>
                  {isSaida(values) && (
                    <div className="my-4">
                      <h3 className={classes.subtitle}>Saídas</h3>
                      <Grid container>
                        <Grid
                          xs="12"
                          sm="6"
                          md="2"
                          lg="2"
                          className={classes.grid}
                        >
                          <TextField
                            name="numPedido"
                            label="Nº Pedido"
                            value={values.numPedido}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="3"
                          lg="3"
                          className={classes.grid}
                        >
                          <InputMask
                            name="dtAtendimento"
                            label="Data de Atendimento"
                            mask="99/99/9999"
                            value={values.dtAtendimento}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="4"
                          lg="4"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Vendedor"
                            items={items[5]}
                            value={values.vendedor}
                            onChange={(v) => setFieldValue('vendedor', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="3"
                          lg="3"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Condição de Pagamento"
                            items={items[6]}
                            value={values.condPag}
                            onChange={(v) => setFieldValue('condPag', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Local de Fornecimento"
                            items={localFornecimento}
                            value={values.localForn}
                            onChange={(v) => setFieldValue('localForn', v)}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {isEntrada(values) && (
                    <div className="my-4">
                      <h3 className={classes.subtitle}>Entradas</h3>
                      <Grid container>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <TextField
                            name="serie"
                            label="Série"
                            value={values.serie}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <InputMask
                            name="dtEntrada"
                            label="Data de Entrada"
                            mask="99/99/9999"
                            value={values.dtEntrada}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {isServico(values) && (
                    <div className="my-4">
                      <h3 className={classes.subtitle}>Serviços</h3>
                      <Grid container>
                        <Grid
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          className={classes.grid}
                        >
                          <TextField
                            name="solicitacao"
                            label="Solicitação"
                            value={values.solicitacao}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          className={classes.grid}
                        >
                          <TextField
                            name="defeito"
                            label="Defeito"
                            value={values.defeito}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="4"
                          md="4"
                          lg="4"
                          className={classes.grid}
                        >
                          <TextField
                            name="mail_contato"
                            label="Email de Contato"
                            value={values.mail_contato}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="4"
                          md="4"
                          lg="4"
                          className={classes.grid}
                        >
                          <TextField
                            name="garantia"
                            label="Garantia (Dias)"
                            value={values.garantia}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="4"
                          md="4"
                          lg="4"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Contrato"
                            items={items[13] || []}
                            value={values.contrato}
                            onChange={(v) => setFieldValue('contrato', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          className={classes.grid}
                        >
                          <SelectAuto
                            multiple
                            label="Defeitos"
                            items={items[14] || []}
                            value={values.defeitos}
                            onChange={(v) => setFieldValue('defeitos', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {isCompras(values) && (
                    <div className="my-4">
                      <h3 className={classes.subtitle}>Compras</h3>
                      <Grid container>
                        {isReqCompra(values) && (
                          <Grid
                            xs="12"
                            sm="6"
                            md="6"
                            lg="6"
                            className={classes.grid}
                          >
                            <SelectAuto
                              label="Requisitante"
                              items={items[7]}
                              value={values.requisitante}
                              onChange={(v) => setFieldValue('requisitante', v)}
                              disabled={
                                documento?.Documento?.sleitura === 'SIM'
                              }
                            />
                          </Grid>
                        )}
                        {isCotCompra(values) && (
                          <Grid
                            xs="12"
                            sm="6"
                            md="6"
                            lg="6"
                            className={classes.grid}
                          >
                            <TextField
                              name="licitacao"
                              label="Licitação"
                              value={values.licitacao}
                              onChange={handleChange}
                              fullWidth
                              disabled={
                                documento?.Documento?.sleitura === 'SIM'
                              }
                            />
                          </Grid>
                        )}
                        {(isCotCompra(values) || isPedCompra(values)) && (
                          <Grid
                            xs="12"
                            sm="6"
                            md="6"
                            lg="6"
                            className={classes.grid}
                          >
                            <TextField
                              name="entrega"
                              label="Entrega"
                              value={values.entrega}
                              onChange={handleChange}
                              fullWidth
                              disabled={
                                documento?.Documento?.sleitura === 'SIM'
                              }
                            />
                          </Grid>
                        )}
                        {isPedCompra(values) && (
                          <Grid
                            xs="12"
                            sm="6"
                            md="6"
                            lg="6"
                            className={classes.grid}
                          >
                            <SelectAuto
                              label="Inspecionar"
                              items={yn}
                              value={values.inspecionar}
                              onChange={(v) => setFieldValue('inspecionar', v)}
                              disabled={
                                documento?.Documento?.sleitura === 'SIM'
                              }
                            />
                          </Grid>
                        )}
                        {isPedCompra(values) && (
                          <Grid
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            className={classes.grid}
                          >
                            <TextField
                              name="contato"
                              label="Contato"
                              value={values.contato}
                              onChange={handleChange}
                              fullWidth
                              disabled={
                                documento?.Documento?.sleitura === 'SIM'
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )}
                  {isContrato(values) && (
                    <div className="my-4">
                      <h3 className={classes.subtitle}>Dados do Contrato</h3>
                      <Grid container>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Tipo"
                            items={items[8]}
                            value={values.tipo}
                            onChange={(v) => setFieldValue('tipo', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Reajuste"
                            items={items[9]}
                            value={values.reajuste}
                            onChange={(v) => setFieldValue('reajuste', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm={param ? '12' : '6'}
                          md={param ? '12' : '6'}
                          lg={param ? '12' : '6'}
                          className={classes.grid}
                        >
                          <InputMask
                            name="dtInicio"
                            label="Data de Início"
                            mask="99/99/9999"
                            value={values.dtInicio}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        {!param && (
                          <Grid
                            xs="12"
                            sm="6"
                            md="6"
                            lg="6"
                            className={classes.grid}
                          >
                            <InputMask
                              name="dtTermino"
                              label="Data de Término"
                              mask="99/99/9999"
                              value={values.dtTermino}
                              onChange={handleChange}
                              fullWidth
                              disabled={
                                documento?.Documento?.sleitura === 'SIM'
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )}
                  {isExportacao(values) && (
                    <div className="my-4">
                      <h3 className={classes.subtitle}>Exportação</h3>
                      <Grid container>
                        <Grid
                          xs="12"
                          sm="4"
                          md="4"
                          lg="4"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="UF Embarque"
                            items={items[15]}
                            value={values.ufEmbarque}
                            onChange={(v) => setFieldValue('ufEmbarque', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="8"
                          md="8"
                          lg="8"
                          className={classes.grid}
                        >
                          <TextField
                            name="localEmbarque"
                            label="Local de Embarque"
                            value={values.localEmbarque}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {isImportacao(values) && (
                    <div className="my-4">
                      <h3 className={classes.subtitle}>Importação</h3>
                      <Grid container>
                        <Grid
                          xs="12"
                          sm="3"
                          md="3"
                          lg="3"
                          className={classes.grid}
                        >
                          <TextField
                            name="numeroDi"
                            label="Número (D.I)"
                            value={values.numeroDi}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="3"
                          md="3"
                          lg="3"
                          className={classes.grid}
                        >
                          <InputMask
                            name="dataDi"
                            label="Data (D.I)"
                            mask="99/99/9999"
                            value={values.dataDi}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Via de transporte informada na D.I"
                            items={items[10]}
                            value={values.viaTransporte}
                            onChange={(v) => setFieldValue('viaTransporte', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <TextField
                            name="localDesembaraco"
                            label="Local desembaraço"
                            value={values.localDesembaraco}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="3"
                          md="3"
                          lg="3"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="UF desembaraço"
                            items={estados}
                            value={values.ufDesembaraco}
                            onChange={(v) => setFieldValue('ufDesembaraco', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="3"
                          md="3"
                          lg="3"
                          className={classes.grid}
                        >
                          <InputMask
                            name="dataDesembaraco"
                            label="Data desembaraço"
                            mask="99/99/9999"
                            value={values.dataDesembaraco}
                            onChange={handleChange}
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Intermédio da importação"
                            items={items[11]}
                            value={values.intermedio}
                            onChange={(v) => setFieldValue('intermedio', v)}
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <TextField
                            name="afrmm"
                            label="Valor da AFRMM"
                            value={values.afrmm}
                            onChange={handleChange}
                            onBlur={(e) =>
                              setFieldValue(
                                'afrmm',
                                setDecimal(e.target.value, 2)
                              )
                            }
                            fullWidth
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  <div className="my-4">
                    <Grid container>
                      <Grid
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        className={classes.grid}
                      >
                        <TextField
                          name="observacoes"
                          label="Observações"
                          value={values.observacoes}
                          onChange={handleChange}
                          fullWidth
                          multiline
                          rows={5}
                          variant="outlined"
                          disabled={documento?.Documento?.sleitura === 'SIM'}
                        />
                      </Grid>
                      {isFiscal(values) && (
                        <Grid
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          className={classes.grid}
                        >
                          <TextField
                            name="adicionais"
                            label="Dados Adicionais"
                            value={values.adicionais}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            rows={5}
                            variant="outlined"
                            disabled={documento?.Documento?.sleitura === 'SIM'}
                          />
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="jr-btn"
                              onClick={() =>
                                dispatch(DocumentoActions.getDadosAdc(param))
                              }
                              disabled={
                                documento?.Documento?.sleitura === 'SIM'
                              }
                            >
                              CARREGAR DADOS ADICIONAIS
                            </Button>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </>
                <div className="d-flex justify-content-end">
                  {param && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      className="jr-btn"
                      onClick={() =>
                        dispatch(DocumentoActions.deleteDocumento(param))
                      }
                      disabled={
                        deleteDocLoading ||
                        documento?.Documento?.sleitura === 'SIM' ||
                        documento?.Documento?.excluidoc === 'NAO'
                      }
                    >
                      EXCLUIR DOCUMENTO
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn"
                    onClick={() => onSubmit(values)}
                    disabled={
                      capaLoading || documento?.Documento?.sleitura === 'SIM'
                    }
                  >
                    ENVIAR E AVANÇAR
                  </Button>
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </Formik>
      <EntidadeModal open={openEntidadeModal} setOpen={setOpenEntidadeModal} />
    </div>
  );
};

export default withStyles(styles)(Capa);
