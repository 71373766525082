import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { setDecimal, transformData } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = (theme) => ({
  gridLeft: {
    borderRadius: '10px',
    overflow: 'hidden',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
  marginGridLeft: {
    marginRight: '10px',
  },
  gridRight: {
    marginLeft: '10px',
  },
  img: {
    flex: 1,
    objectFit: 'contain',
  },
  paper: {
    minWidth: '60%',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  icon: {
    color: 'black',
  },
  itemTitleFlex: {
    color: 'black',
    fontSize: '14px',
    flex: 1,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fonSize: '20px',
  },
  itemTitle: {
    color: 'black',
    fonSize: '14px',
  },
});

const ShopModal = ({
  open,
  setOpen,
  shopCart,
  documentoId,
  setShopCart,
  classes,
}) => {
  const dispatch = useDispatch();
  const { capaLoading } = useSelector(({ documento }) => documento);

  const getTotal = () => {
    let valorTotal = 0;
    shopCart.map((i) => {
      valorTotal = valorTotal + i?.preco * i?.quantidade;
    });
    return valorTotal;
  };

  const onSubmit = () => {
    let Itens = [];

    shopCart.map((i) => {
      Itens.push({
        documento_id: Number(documentoId),
        preco: i?.preco,
        produto_id: i?.id,
        quantidade: i?.quantidade,
      });
    });

    dispatch(DocumentoActions.postCapa({ Itens }));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Finalizar Pedido</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} className="my-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Produto</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Preço Unitário</TableCell>
                <TableCell>Preço Total</TableCell>
                <TableCell align="right">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shopCart.map((m) => (
                <TableRow>
                  <TableCell>{m?.descricaor}</TableCell>
                  <TableCell>{m?.quantidade}</TableCell>
                  <TableCell>{setDecimal(m?.preco, 2)}</TableCell>
                  <TableCell>
                    {setDecimal(m?.preco * m?.quantidade, 2)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <DeleteIcon
                      color="action"
                      onClick={() =>
                        setShopCart(shopCart.filter((f) => f?.id !== m?.id))
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>TOTAL</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>{setDecimal(getTotal(), 2)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className="jr-btn"
          color="primary"
          onClick={() => onSubmit()}
          disabled={capaLoading}
        >
          FINALIZAR
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className="jr-btn"
          color="primary"
          onClick={() => setOpen(!open)}
        >
          VOLTAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ShopModal);
