import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as TablesActions } from 'store/ducks/Tables';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const AcessoModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const [items, setItems] = useState([]);
  const [grid, setGrid] = useState([]);
  const { saveLoading, saveSuccess, deleteSuccess } = useSelector(
    ({ tables }) => tables
  );

  async function loadAcessos() {
    const { data } = await api.post(`/csql/972`, {
      WhereClause: `s.perfil_agenda_id = ${selected?.id}`,
    });
    if (data) {
      setGrid(data.dados || []);
    }
  }

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (saveSuccess) {
      dispatch(TablesActions.saveDataReset());
      loadAcessos();
    }
    if (deleteSuccess) {
      dispatch(TablesActions.deleteDataReset());
      loadAcessos();
    }
  }, [saveSuccess, deleteSuccess]);

  useEffect(() => {
    if (open) {
      loadAcessos();
      if (selected?.tpagenda === 'USUARIO') {
        loadItems([635]);
      } else if (selected?.tpagenda === 'REPRESENTANTE') {
        loadItems([971]);
      } else if (selected?.tpagenda === 'FROTA') {
        loadItems([710]);
      }
    }
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Acessos</DialogTitle>
      <DialogContent>
        <div className="d-flex align-items-center">
          <div className="w-100">
            <SelectAuto
              label="Seleção"
              items={items[0] || []}
              value={values}
              onChange={(v) => setValues(v)}
            />
          </div>
          <Button
            color="primary"
            disabled={saveLoading}
            onClick={() => {
              if (!!values) {
                dispatch(
                  TablesActions.saveData({
                    table: 'perfil_agenda_selecao',
                    campos: {
                      perfil_agenda_id: selected?.id,
                      selecao_id: values?.value,
                    },
                  })
                );
              }
            }}
          >
            Adicionar
          </Button>
        </div>
        {!!grid.length && (
          <TableContainer component={Paper} className="my-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Selecionados</TableCell>
                  <TableCell align="right">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {grid.map((g, i) => (
                  <TableRow>
                    <TableCell>{g?.label || g?.descricao}</TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <DeleteIcon
                        color="action"
                        onClick={() =>
                          dispatch(
                            TablesActions.deleteData({
                              table: 'perfil_agenda_selecao',
                              id: g?.id,
                            })
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(AcessoModal);
