export const Types = {
  POST_TRIBUTACAO: 'POST_TRIBUTACAO',
  POST_TRIBUTACAO_SUCCESS: 'POST_TRIBUTACAO_SUCCESS',
  POST_TRIBUTACAO_ERROR: 'POST_TRIBUTACAO_ERROR',
  POST_TRIBUTACAO_RESET: 'POST_TRIBUTACAO_RESET',

  POST_COPIA_REGRA: 'POST_COPIA_REGRA',
  POST_COPIA_REGRA_SUCCESS: 'POST_COPIA_REGRA_SUCCESS',
  POST_COPIA_REGRA_ERROR: 'POST_COPIA_REGRA_ERROR',
  POST_COPIA_REGRA_RESET: 'POST_COPIA_REGRA_RESET',

  PUT_TRIBUTACAO: 'PUT_TRIBUTACAO',
  PUT_TRIBUTACAO_SUCCESS: 'PUT_TRIBUTACAO_SUCCESS',
  PUT_TRIBUTACAO_ERROR: 'PUT_TRIBUTACAO_ERROR',
  PUT_TRIBUTACAO_RESET: 'PUT_TRIBUTACAO_RESET',

  IMPORT_IBPT: 'IMPORT_IBPT',
  IMPORT_IBPT_SUCCESS: 'IMPORT_IBPT_SUCCESS',
  IMPORT_IBPT_ERROR: 'IMPORT_IBPT_ERROR',
  IMPORT_IBPT_RESET: 'IMPORT_IBPT_RESET',

  GET_APURACAO: 'GET_APURACAO',
  GET_APURACAO_SUCCESS: 'GET_APURACAO_SUCCESS',
  GET_APURACAO_ERROR: 'GET_APURACAO_ERROR',

  POST_APURACAO: 'POST_APURACAO',
  POST_APURACAO_SUCCESS: 'POST_APURACAO_SUCCESS',
  POST_APURACAO_ERROR: 'POST_APURACAO_ERROR',
  POST_APURACAO_RESET: 'POST_APURACAO_RESET',

  POST_APR: 'POST_APR',
  POST_APR_SUCCESS: 'POST_APR_SUCCESS',
  POST_APR_ERROR: 'POST_APR_ERROR',
  POST_APR_RESET: 'POST_APR_RESET',

  POST_GNRE: 'POST_GNRE',
  POST_GNRE_SUCCESS: 'POST_GNRE_SUCCESS',
  POST_GNRE_RESET: 'POST_GNRE_RESET',
};

const INIT_STATE = {
  postTribLoading: false,
  postTribSuccess: false,
  postTribError: false,

  postCopiaRegraLoading: false,
  postCopiaRegraSuccess: false,
  postCopiaRegraError: false,

  putTribLoading: false,
  putTribSuccess: false,
  putTribError: false,

  importLoading: false,
  importSuccess: false,
  importError: false,

  apuracaoLoading: false,
  apuracao: [],
  apuracaoError: false,

  postApurLoading: false,
  postApurSuccess: false,
  postApurError: false,

  postAprLoading: false,
  postAprSuccess: false,
  postAprError: false,

  postGnreLoading: false,
  postGnreSuccess: false,
  gnre: null,
};

export default function Fiscal(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.POST_TRIBUTACAO: {
      return {
        ...state,
        postTribLoading: true,
        postTribSuccess: false,
        postTribError: false,
      };
    }
    case Types.POST_TRIBUTACAO_SUCCESS: {
      return {
        ...state,
        postTribLoading: false,
        postTribSuccess: true,
        postTribError: false,
      };
    }
    case Types.POST_TRIBUTACAO_ERROR: {
      return {
        ...state,
        postTribLoading: false,
        postTribSuccess: false,
        postTribError: true,
      };
    }
    case Types.POST_TRIBUTACAO_RESET: {
      return {
        ...state,
        postTribLoading: false,
        postTribSuccess: false,
        postTribError: false,
      };
    }

    case Types.POST_COPIA_REGRA: {
      return {
        ...state,
        postCopiaRegraLoading: true,
        postCopiaRegraSuccess: false,
        postCopiaRegraError: false,
      };
    }
    case Types.POST_COPIA_REGRA_SUCCESS: {
      return {
        ...state,
        postCopiaRegraLoading: false,
        postCopiaRegraSuccess: true,
        postCopiaRegraError: false,
      };
    }
    case Types.POST_COPIA_REGRA_ERROR: {
      return {
        ...state,
        postCopiaRegraLoading: false,
        postCopiaRegraSuccess: false,
        postCopiaRegraError: true,
      };
    }
    case Types.POST_COPIA_REGRA_RESET: {
      return {
        ...state,
        postCopiaRegraLoading: false,
        postCopiaRegraSuccess: false,
        postCopiaRegraError: false,
      };
    }

    case Types.PUT_TRIBUTACAO: {
      return {
        ...state,
        putTribLoading: true,
        putTribSuccess: false,
        putTribError: false,
      };
    }
    case Types.PUT_TRIBUTACAO_SUCCESS: {
      return {
        ...state,
        putTribLoading: false,
        putTribSuccess: true,
        putTribError: false,
      };
    }
    case Types.PUT_TRIBUTACAO_ERROR: {
      return {
        ...state,
        putTribLoading: false,
        putTribSuccess: false,
        putTribError: true,
      };
    }
    case Types.PUT_TRIBUTACAO_RESET: {
      return {
        ...state,
        putTribLoading: false,
        putTribSuccess: false,
        putTribError: false,
      };
    }

    case Types.IMPORT_IBPT: {
      return {
        ...state,
        importLoading: true,
        importSuccess: false,
        importError: false,
      };
    }
    case Types.IMPORT_IBPT_SUCCESS: {
      return {
        ...state,
        importLoading: false,
        importSuccess: true,
        importError: false,
      };
    }
    case Types.IMPORT_IBPT_ERROR: {
      return {
        ...state,
        importLoading: false,
        importSuccess: false,
        importError: true,
      };
    }
    case Types.IMPORT_IBPT_RESET: {
      return {
        ...state,
        importLoading: false,
        importSuccess: false,
        importError: false,
      };
    }

    case Types.GET_APURACAO: {
      return {
        ...state,
        apuracaoLoading: true,
        apuracao: [],
        apuracaoError: false,
      };
    }
    case Types.GET_APURACAO_SUCCESS: {
      return {
        ...state,
        apuracaoLoading: false,
        apuracao: action.payload,
        apuracaoError: false,
      };
    }
    case Types.GET_APURACAO_ERROR: {
      return {
        ...state,
        apuracaoLoading: false,
        apuracao: [],
        apuracaoError: true,
      };
    }

    case Types.POST_APURACAO: {
      return {
        ...state,
        postApurLoading: true,
        postApurSuccess: false,
        postApurError: false,
      };
    }
    case Types.POST_APURACAO_SUCCESS: {
      return {
        ...state,
        postApurLoading: false,
        postApurSuccess: true,
        postApurError: false,
      };
    }
    case Types.POST_APURACAO_ERROR: {
      return {
        ...state,
        postApurLoading: false,
        postApurSuccess: false,
        postApurError: true,
      };
    }
    case Types.POST_APURACAO_RESET: {
      return {
        ...state,
        postApurLoading: false,
        postApurSuccess: false,
        postApurError: false,
      };
    }

    case Types.POST_APR: {
      return {
        ...state,
        postAprLoading: true,
        postAprSuccess: false,
        postAprError: false,
      };
    }
    case Types.POST_APR_SUCCESS: {
      return {
        ...state,
        postAprLoading: false,
        postAprSuccess: true,
        postAprError: false,
      };
    }
    case Types.POST_APR_ERROR: {
      return {
        ...state,
        postAprLoading: false,
        postAprSuccess: false,
        postAprError: true,
      };
    }
    case Types.POST_APR_RESET: {
      return {
        ...state,
        postAprLoading: false,
        postAprSuccess: false,
        postAprError: false,
      };
    }

    case Types.POST_GNRE: {
      return {
        ...state,
        postGnreLoading: true,
        postGnreSuccess: false,
        gnre: null,
      };
    }
    case Types.POST_GNRE_SUCCESS: {
      return {
        ...state,
        postGnreLoading: false,
        postGnreSuccess: true,
        gnre: action.payload,
      };
    }
    case Types.POST_GNRE_RESET: {
      return {
        ...state,
        postGnreLoading: false,
        postGnreSuccess: false,
        gnre: null,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  postTributacao: (payload) => ({
    type: Types.POST_TRIBUTACAO,
    payload,
  }),
  postTributacaoSuccess: () => ({
    type: Types.POST_TRIBUTACAO_SUCCESS,
  }),
  postTributacaoError: () => ({
    type: Types.POST_TRIBUTACAO_ERROR,
  }),
  postTributacaoReset: () => ({
    type: Types.POST_TRIBUTACAO_RESET,
  }),

  postCopiaRegra: (payload) => ({
    type: Types.POST_COPIA_REGRA,
    payload,
  }),
  postCopiaRegraSuccess: () => ({
    type: Types.POST_COPIA_REGRA_SUCCESS,
  }),
  postCopiaRegraError: () => ({
    type: Types.POST_COPIA_REGRA_ERROR,
  }),
  postCopiaRegraReset: () => ({
    type: Types.POST_COPIA_REGRA_RESET,
  }),

  putTributacao: (payload) => ({
    type: Types.PUT_TRIBUTACAO,
    payload,
  }),
  putTributacaoSuccess: () => ({
    type: Types.PUT_TRIBUTACAO_SUCCESS,
  }),
  putTributacaoError: () => ({
    type: Types.PUT_TRIBUTACAO_ERROR,
  }),
  putTributacaoReset: () => ({
    type: Types.PUT_TRIBUTACAO_RESET,
  }),

  importIbpt: (payload) => ({
    type: Types.IMPORT_IBPT,
    payload,
  }),
  importIbptSuccess: () => ({
    type: Types.IMPORT_IBPT_SUCCESS,
  }),
  importIbptError: () => ({
    type: Types.IMPORT_IBPT_ERROR,
  }),
  importIbptReset: () => ({
    type: Types.IMPORT_IBPT_RESET,
  }),

  getApuracao: (payload) => ({
    type: Types.GET_APURACAO,
    payload,
  }),
  getApuracaoSuccess: (payload) => ({
    type: Types.GET_APURACAO_SUCCESS,
    payload,
  }),
  getApuracaoError: () => ({
    type: Types.GET_APURACAO_ERROR,
  }),

  postApuracao: (payload) => ({
    type: Types.POST_APURACAO,
    payload,
  }),
  postApuracaoSuccess: () => ({
    type: Types.POST_APURACAO_SUCCESS,
  }),
  postApuracaoError: () => ({
    type: Types.POST_APURACAO_ERROR,
  }),
  postApuracaoReset: () => ({
    type: Types.POST_APURACAO_RESET,
  }),

  postApr: (payload) => ({
    type: Types.POST_APR,
    payload,
  }),
  postAprSuccess: () => ({
    type: Types.POST_APR_SUCCESS,
  }),
  postAprError: () => ({
    type: Types.POST_APR_ERROR,
  }),
  postAprReset: () => ({
    type: Types.POST_APR_RESET,
  }),

  postGnre: (payload) => ({
    type: Types.POST_GNRE,
    payload,
  }),
  postGnreSuccess: (payload) => ({
    type: Types.POST_GNRE_SUCCESS,
    payload,
  }),
  postGnreReset: () => ({
    type: Types.POST_GNRE_RESET,
  }),
};
