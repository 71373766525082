import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import api from 'util/api';
import { Creators as AgendaActions } from 'store/ducks/Agenda';
import { findOnArray } from 'util/index';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  calendar: {
    borderRadius: '5px',
  },
  gridCalendar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  activeDay: {
    backgroundColor: '#006edc !important',
    color: 'white !important',
  },
  inactiveDay: {
    backgroundColor: 'white !important',
    color: 'black !important',
  },
});

const dateAlreadyClicked = (dates, date) =>
  dates.some((d) => moment(date).isSame(moment(d), 'day'));
const datesExcept = (dates, date) =>
  dates.filter((d) => !moment(date).isSame(moment(d), 'day'));

const statusDrop = [
  {
    value: 'NAO INICIADA',
    label: 'NÃO INICIADA',
  },
  {
    value: 'EM ANDAMENTO',
    label: 'EM ANDAMENTO',
  },
  {
    value: 'CONCLUIDA',
    label: 'CONCLUIDA',
  },
  {
    value: 'AGUARDANDO OUTRA PESSOA',
    label: 'AGUARDANDO OUTRA PESSOA',
  },
  {
    value: 'ADIADA',
    label: 'ADIADA',
  },
];

const prioridadeDrop = [
  {
    value: 'BAIXA',
    label: 'BAIXA',
  },
  {
    value: 'NORMAL',
    label: 'NORMAL',
  },
  {
    value: 'ALTA',
    label: 'ALTA',
  },
];

const AddAgendaModal = ({
  open,
  setOpen,
  selected,
  setSelected,
  state,
  classes,
}) => {
  const dispatch = useDispatch();
  const [fill, setFill] = useState(false);
  const [dates, setDates] = useState([]);
  const [items, setItems] = useState([]);
  const { postTarefaLoading } = useSelector(({ agenda }) => agenda);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([608, 635, 633, 710]);
    }
  }, [open]);

  const onClickDay = (date) => {
    if (dateAlreadyClicked(dates, date)) setDates(datesExcept(dates, date));
    else setDates([...dates, date]);
  };

  const tileClassName = ({ date }) => {
    if (dateAlreadyClicked(dates, date)) return classes.activeDay;
    return classes.inactiveDay;
  };

  const onSend = (values) => {
    const recorrencias = dates.map(
      (d) =>
        `${moment(d).format('YYYY-MM-DD')} ${moment(values?.inicio, 'DD/MM/YYYY HH:mm:ss').isValid()
          ? moment(values?.inicio, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss')
          : ''
        }`
    );
    let data = {
      assunto: values?.assunto,
      tarefa: values?.tarefa,
      datahora: moment(values?.inicio, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.inicio, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        : null,
      status: values?.status?.value,
      conclusao: moment(values?.fim, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.fim, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        : null,
      lembrete: moment(values?.lembrete, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.lembrete, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        : null,
      prioridade: values?.prioridade?.value,
      userdest_id: values?.user?.value,
      documento_id: values?.documento?.value,
      entidade_id: values?.entidade?.value,
      frota_id: values?.frota?.value,
    };
    if (selected) {
      dispatch(AgendaActions.putTarefa({ data, id: selected?.id }));
    } else {
      dispatch(AgendaActions.postTarefa({ ...data, recorrencias }));
    }
  };

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (items.length && open && selected && !fill) {
        setFill(true);
        setValues({
          ...values,
          assunto: selected?.assunto,
          inicio: selected?.datahora
            ? moment(selected?.datahora, 'YYYY-MM-DD HH:mm:ss').format(
              'DD/MM/YYYY HH:mm:ss'
            )
            : null,
          fim: selected?.conclusao
            ? moment(selected?.conclusao, 'YYYY-MM-DD HH:mm:ss').format(
              'DD/MM/YYYY HH:mm:ss'
            )
            : null,
          lembrete: selected?.lembrete
            ? moment(selected?.lembrete, 'YYYY-MM-DD HH:mm:ss').format(
              'DD/MM/YYYY HH:mm:ss'
            )
            : null,
          status: findOnArray(selected?.status, statusDrop),
          prioridade: findOnArray(selected?.prioridade, prioridadeDrop),
          documento: findOnArray(selected?.documento_id, items[0]),
          user: findOnArray(selected?.userdest_id, items[1]),
          entidade: findOnArray(selected?.entidade_id, items[2]),
          frota: findOnArray(selected?.frota_id, items[3]),
          tarefa: selected?.tarefa,
        });
      }
      if (items.length && open && !selected && !fill && state) {
        setFill(true);
        setValues({
          ...values,
          documento: findOnArray(state?.documento_id, items[0]),
          entidade: findOnArray(state?.entidade_id, items[2]),
          frota: findOnArray(state?.frota_id, items[3]),
        });
      }
    }, [items, open]);
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExit={() => {
        setDates([]);
        if (setSelected) setSelected(null);
        setFill(false);
      }}
    >
      <DialogTitle>{selected ? 'Editar' : 'Adicionar'} Tarefa</DialogTitle>
      <Formik
        initialValues={{
          assunto: null,
          inicio: null,
          fim: null,
          lembrete: null,
          status: null,
          prioridade: null,
          documento: null,
          user: null,
          entidade: null,
          frota: null,
          tarefa: null,
          recorrencia: null,
          recQtd: null,
          recFim: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="assunto"
                    label="Assunto"
                    value={values.assunto || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="inicio"
                    label="Data/Hora Início"
                    mask="99/99/9999 99:99:99"
                    value={values.inicio || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="fim"
                    label="Data/Hora Conclusão"
                    mask="99/99/9999 99:99:99"
                    value={values.fim || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="lembrete"
                    label="Data/Hora Lembrete"
                    mask="99/99/9999 99:99:99"
                    value={values.lembrete || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Status"
                    items={statusDrop}
                    value={values.status}
                    onChange={(v) => setFieldValue('status', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Prioridade"
                    items={prioridadeDrop}
                    value={values.prioridade}
                    onChange={(v) => setFieldValue('prioridade', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Documento"
                    items={items[0]}
                    value={values.documento}
                    onChange={(v) => setFieldValue('documento', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Usuário"
                    items={items[1]}
                    value={values.user}
                    onChange={(v) => setFieldValue('user', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Entidade"
                    items={items[2]}
                    value={values.entidade}
                    onChange={(v) => setFieldValue('entidade', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Frota"
                    items={items[3]}
                    value={values.frota}
                    onChange={(v) => setFieldValue('frota', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="tarefa"
                    label="Tarefa"
                    value={values.tarefa || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                {!selected && (
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.gridCalendar}
                  >
                    <h2 className="my-2">Selecionar Recorrências</h2>
                    <Calendar
                      tileClassName={tileClassName}
                      onClickDay={onClickDay}
                      calendarType="US"
                      className={classes.calendar}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onSend(values)}
                color="primary"
                disabled={postTarefaLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(AddAgendaModal);
