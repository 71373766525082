import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Checkbox,
  InputLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { Creators as FiscalActions } from 'store/ducks/Fiscal';
import { toast } from 'react-toastify'

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const GNREModal = ({ open, setOpen, selected = [], classes }) => {
  const dispatch = useDispatch();
  const [codigo, setCodigo] = useState(null);
  const [items, setItems] = useState([]);
  const [envioWS, setEnvioWS] = useState(true);
  const [homologacaoWS, setHomologacaoWS] = useState(false);

  const { postGnreSuccess, postGnreLoading, gnre } = useSelector(
    ({ fiscal }) => fiscal
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([897]);
    }
  }, [open]);

  useEffect(() => {
    if (postGnreSuccess && !envioWS) {
      const element = document.createElement('a');
      const file = new Blob([gnre], { type: 'application/zip' });
      element.href = URL.createObjectURL(file);
      element.download = 'gnre_guias.zip';
      document.body.appendChild(element);
      element.click();
      dispatch(FiscalActions.postGnreReset());
      setOpen(false);
    } else if (postGnreSuccess && envioWS) {
      dispatch(FiscalActions.postGnreSuccess());
      toast.success("Processamento em andamento. O resultado será enviado através de uma notificação!");
      setOpen(false);
    }
  }, [postGnreSuccess]);

  const onSubmit = () => {
    const data = {
      codigoReceitaId: codigo?.value,
      documentosIds: selected?.map((s) => s?.id),
      envioWS: envioWS,
      homologacaoWS: homologacaoWS
    };
    dispatch(FiscalActions.postGnre(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <DialogTitle>Gerar GNRE</DialogTitle>
      <DialogContent>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <SelectAuto
            label="Código da Receita"
            items={items[0] || []}
            value={codigo}
            onChange={(v) => setCodigo(v)}
          />
        </Grid>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <div className="d-flex align-items-center ">
            <InputLabel>Enviar ao Webservice da GNRE?</InputLabel>
            <Checkbox
            checked={envioWS}
            style={{ padding: '0 5px' }}
            color="default"
            onChange={() => { setEnvioWS(!envioWS)}} 
            />
            <InputLabel>Webservice em Homologação?</InputLabel>
            <Checkbox
            checked={homologacaoWS && envioWS}
            style={{ padding: '0 5px' }}
            color="default"
            onChange={() => { setHomologacaoWS(!homologacaoWS)}} 
            />
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit} disabled={postGnreLoading}>
          Finalizar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(GNREModal);
