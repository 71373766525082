import React from 'react';
import { useDispatch } from 'react-redux';
import { List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Creators as SettingActions } from '../../store/ducks/Setting';
import { Creators as FiltrosStoreActions } from '../../store/ducks/FiltrosStore';

const NavMenuItem = (props) => {
  const dispatch = useDispatch();
  const { name, icon, link } = props;

  return (
    <List component="div" className="nav-menu-item">
      <NavLink
        className="prepend-icon nav-menu-link"
        to={link}
        //target={name === 'Rotinas' ? '_blank' : ''}
        onClick={() => {
          dispatch(FiltrosStoreActions.resetFiltrosStore());
          dispatch(SettingActions.toggleCollapsedNav());
        }}
      >
        {/* Display an icon if any */}
        {!!icon && <i className={'zmdi zmdi-hc-fw  zmdi-' + icon} />}
        <span className="nav-text">{name}</span>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
