import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import EntidadeModal from 'components/Modals/EntidadeModal';
import EnderecoModal from 'components/Modals/EnderecoModal';
import ListaDocumentoModal from 'components/Modals/ListaDocumentoModal';
import ClienteModal from 'components/Modals/ClienteModal';
import DocGerarModal from 'components/Modals/DocGerarModal';
import AddIcon from '@material-ui/icons/Add';
import api from 'util/api';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as EntidadesActions } from 'store/ducks/Entidades';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';
import { findOnArray, setDecimal, validaCpfCnpj } from 'util/index';
import moment from 'moment';
import { toast } from 'react-toastify';

const gridContainer = {
  padding: '0 0.5rem',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-end',
  display: 'flex',
};

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  grPlus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const Atendimento = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const [items, setItems] = useState([]);
  const [adressDrop, setAdressDrop] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [entidadeModal, setEntidadeModal] = useState(false);
  const [enderecoModal, setEnderecoModal] = useState(false);
  const [documentoModal, setDocumentoModal] = useState(false);
  const [clienteModal, setClienteModal] = useState(false);
  const [fill, setFill] = useState(false);
  const [docGerarModal, putDocGerarModal] = useState(false);
  const {
    userPerfilLoading,
    userPerfil,
    capaLoading,
    idRegistred,
    docgerar,
  } = useSelector(({ documento }) => documento);
  const { identificacao } = useSelector(({ entidades }) => entidades);
  const { authUser } = useSelector(({ auth }) => auth);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  async function loadAdress(id) {
    const { data } = await api.post(`/csql/696`, {
      Parametros: [{ nome: ':id', valor: id }],
    });
    if (data) {
      const { dados } = data;
      setAdressDrop(dados || []);
    }
  }

  useEffect(() => {
    dispatch(EntidadesActions.getIdentificacaoReset());
    loadItems([644, 616, 623, 958]);
    dispatch(DocumentoActions.userPerfil({ modulo: 'ATENDIMENTO' }));
  }, []);

  useEffect(() => {
    if (identificacao) {
      loadAdress(identificacao?.Entidade?.id);
    } else {
      setAdressDrop([]);
    }
  }, [identificacao]);

  useEffect(() => {
    if (docgerar) {
      putDocGerarModal(!docGerarModal);
    }
  }, [docgerar]);

  const SetFields = () => {
    const { setFieldValue, setValues, values } = useFormikContext();

    useEffect(() => {
      if (idRegistred) {
        dispatch(DocumentoActions.postCapaReset());
        dispatch(DocumentoActions.postCapaResetId());
        setFill(false);
        if (values?.natureza?.vinculo === 'SIM' && identificacao) {
          if (values?.natureza?.label?.includes('FORA DO ESTABELECIMENTO')) {
            history.push(
              `/app/Devolução?id=${idRegistred}&filial=${
                userPerfil?.filial_id
              }&entidade=${values?.vendedor?.value}&vendas=${true}&externa=${
                items[0][0]?.natureza_remessave
              }`
            );
          } else {
            history.push(
              `/app/Devolução?id=${idRegistred}&filial=${
                userPerfil?.filial_id
              }&entidade=${identificacao?.Entidade?.id}&vendas=${true}`
            );
          }
        } else {
          history.push(`/app/Comercial/Atendimento/Vendas?id=${idRegistred}`, {
            state: {
              backTo: state?.state?.backTo,
            },
          });
        }
      }
    }, [idRegistred]);

    useEffect(() => {
      if (!fill && userPerfil) {
        setFill(true);
        setValues({
          ...values,
          especie: findOnArray(
            state?.state?.especie || userPerfil?.especie_id,
            userPerfil?.Especies
          ),
          natureza: findOnArray(
            state?.state?.natureza || userPerfil?.natureza_operacao_id,
            items[0]
          ),
          contribuinte: findOnArray(userPerfil?.contribuinte_id, items[1]),
          final: findOnArray(userPerfil?.usofinal, yn),
          vendedor: findOnArray(userPerfil?.vendedor_id, items[2]),
          vinculo: findOnArray(Number(state?.state?.vinculo), items[3]),
        });
      }
    }, [userPerfil]);

    useEffect(() => {
      if (identificacao) {
        const cliente = identificacao?.Perfis?.find(
          (p) => p.tipo === 'CLIENTE'
        );
        setFieldValue('cliente', identificacao?.Entidade?.nome);
        setFieldValue('cadastro_id', identificacao?.Entidade?.id);
        setFieldValue(
          'condicao_pagamento_id',
          identificacao?.Entidade?.condicao_pagamento_id
        );
        if (cliente?.contribuinte_id) {
          setFieldValue(
            'contribuinte',
            findOnArray(cliente?.contribuinte_id, items[1])
          );
        }
        if (cliente?.usofinal) {
          setFieldValue('final', findOnArray(cliente?.usofinal, yn));
        }
      }
    }, [values, identificacao]);

    return null;
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        cadastro_id: values?.cadastro_id,
        contribuinte_id: values?.contribuinte?.value,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        especie_id: values?.especie?.value,
        filial_id: userPerfil?.filial_id,
        natureza_operacao_id: values?.natureza?.value,
        observacao: values?.observacao,
        usofinal: values?.final?.value,
        vinculo_id: values?.vinculo?.value,
      },
      Saida: {
        cpf: values?.numDoc,
        documento: values?.numDoc,
        entidade_endereco_id: values?.endereco?.value,
        vendedor_id: values?.vendedor?.value,
        condicao_pagamento_id: values?.condicao_pagamento_id,
        ped_cliente: values?.ped_cliente,
      },
    };
    if (values?.numDoc && values?.numDoc !== '') {
      if (validaCpfCnpj(values?.numDoc?.replace(/([\/\.\-])/g, ''))) {
        dispatch(DocumentoActions.postCapa(data));
      } else {
//      toast.warning('Digite um documento válido'); esta dando problema em alguns documentos válidos
      }
    } else {
      dispatch(DocumentoActions.postCapa(data));
    }
  };

  return (
    <div className="app-wrapper">
      <HeaderPage title="Atendimento" />
      <Formik
        initialValues={{
          especie: null,
          natureza: null,
          contribuinte: null,
          final: null,
          documento: null,
          vendedor: null,
          vinculo: null,
          numDoc: null,
          cliente: null,
          observacao: null,
          endereco: null,
          cadastro_id: null,
          condicao_pagamento_id: null,
          ped_cliente: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Card className="shadow">
            <CardContent>
              {localLoading || userPerfilLoading ? (
                <Loading />
              ) : (
                <>
                  <SetFields />
                  <Grid container>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Espécie"
                        items={userPerfil?.Especies || []}
                        value={values.especie}
                        onChange={(v) => setFieldValue('especie', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Natureza de Operação"
                        items={items[0]}
                        value={values.natureza}
                        onChange={(v) => setFieldValue('natureza', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Contribuinte"
                        items={items[1]}
                        value={values.contribuinte}
                        onChange={(v) => setFieldValue('contribuinte', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="1" md="1" lg="1" className={classes.grid}>
                      <SelectAuto
                        label="Uso Final"
                        items={yn}
                        value={values.final}
                        onChange={(v) => setFieldValue('final', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="ped_cliente"
                        label="Pedido do cliente"
                        value={values.ped_cliente}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Vendedor"
                        items={items[2]}
                        value={values.vendedor}
                        onChange={(v) => setFieldValue('vendedor', v)}
                      />
                    </Grid>
                    {values?.natureza?.label?.includes('CONSUMO') && (
                      <Grid
                        xs="12"
                        sm="3"
                        md="3"
                        lg="3"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Documento em que foi utilizado"
                          items={items[3]}
                          value={values.vinculo}
                          onChange={(v) => setFieldValue('vinculo', v)}
                        />
                      </Grid>
                    )}
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="numDoc"
                        label="Nº Documento do Cliente"
                        value={values.numDoc || ''}
                        onChange={handleChange}
                        onBlur={(v) => {
                          if (!!v?.target?.value && v?.target?.value !== '') {
                            dispatch(
                              EntidadesActions.getIdentificacao(
                                v?.target?.value?.replace(/([\/\.\-])/g, '')
                              )
                            );
                          } else {
                            dispatch(EntidadesActions.getIdentificacaoReset());
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="10" sm="7" md="7" lg="7" className={classes.grid}>
                      <TextField
                        name="cliente"
                        label="Cliente"
                        value={values.cliente || ''}
                        autoComplete="off"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="2"
                      sm="1"
                      md="1"
                      lg="1"
                      className={classes.grPlus}
                    >
                      <IconButton
                        className="icon-btn"
                        onClick={() => setClienteModal(true)}
                      >
                        <AddIcon color="primary" />
                      </IconButton>
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <TextField
                        name="observacao"
                        label="Observação"
                        value={values.observacao}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    {identificacao && (
                      <>
                        <Grid
                          xs="10"
                          sm="10"
                          md="10"
                          lg="10"
                          className={classes.grid}
                        >
                          <SelectAuto
                            label="Endereço"
                            items={adressDrop}
                            value={values.endereco}
                            onChange={(v) => setFieldValue('endereco', v)}
                          />
                        </Grid>
                        <Grid
                          xs="2"
                          sm="2"
                          md="2"
                          lg="2"
                          className={classes.grPlus}
                        >
                          <IconButton
                            className="icon-btn"
                            onClick={() => setEnderecoModal(!enderecoModal)}
                          >
                            <AddIcon color="primary" />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container className="mt-4">
                    <Grid xs="12" sm="12" md="12" lg="12" style={gridContainer}>
                      {!!authUser?.storedCxContaId && (
                        <Button
                          variant="outlined"
                          color="primary"
                          className="mr-2"
                          onClick={() =>
                            history.push(`/app/Comercial/Atendimento-Caixa`)
                          }
                        >
                          CAIXA
                        </Button>
                      )}
                      {!!values?.especie?.impressoraweb_id && (
                        <Button
                          variant="outlined"
                          color="primary"
                          className="mr-2"
                          onClick={() =>
                            dispatch(
                              LancamentoActions.postCancelaVenda(
                                values?.especie?.impressoraweb_id
                              )
                            )
                          }
                        >
                          CANCELAR ULTIMA VENDA
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        color="primary"
                        className="mr-2"
                        onClick={() => setDocumentoModal(true)}
                      >
                        SELECIONAR DOCUMENTO
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="mr-2"
                        disabled={capaLoading}
                        onClick={() => setEntidadeModal(!entidadeModal)}
                      >
                        {identificacao ? 'EDITAR ENTIDADE' : 'NOVA ENTIDADE'}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onSubmit(values)}
                        disabled={capaLoading}
                      >
                        INICIAR TRANSAÇÃO
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              <EntidadeModal
                open={entidadeModal}
                setOpen={setEntidadeModal}
                identificacao={identificacao}
                doc={values?.numDoc}
                refresh={() => loadItems([644, 616, 623])}
              />
              <EnderecoModal
                open={enderecoModal}
                setOpen={setEnderecoModal}
                identificacao={identificacao}
                doc={values?.numDoc}
                refresh={() => loadItems([644, 616, 623])}
              />
              <ListaDocumentoModal
                open={documentoModal}
                setOpen={setDocumentoModal}
                values={values}
                onSubmit={(id, fat) => {
                  if (id && !fat) {
                    history.push(`/app/Comercial/Atendimento/Vendas?id=${id}`, {
                      state: {
                        backTo: state?.backTo,
                      },
                    });
                  }
                  if (id && fat) {
                    const Documentos = [{ id }];
                    dispatch(
                      DocumentoActions.putDocGerar({ Documentos, Rotina: '' })
                    );
                  }
                }}
              />
              <ClienteModal
                open={clienteModal}
                setOpen={setClienteModal}
                onSubmit={(cpf, cli) => {
                  if (cpf) {
                    setFieldValue('numDoc', cli?.value);
                  } else {
                    setFieldValue('numDoc', null);
                  }
                  if (cli?.value) {
                    dispatch(
                      EntidadesActions.getIdentificacao(
                        cli?.value?.replace(/([\/\.\-])/g, '')
                      )
                    );
                  }
                  setClienteModal(false);
                }}
              />
              <DocGerarModal
                open={docGerarModal}
                setOpen={putDocGerarModal}
                selected={[]}
                despecie={10}
                dnatureza={6}
              />
            </CardContent>
          </Card>
        )}
      </Formik>
      {identificacao && (
        <Card className="shadow mt-4">
          <CardContent>
            <Grid container spacing={2}>
              {identificacao?.bloqueado == 'SIM' && (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    color="secondary"
                  >
                    *** CLIENTE BLOQUEADO ***
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="subtitle2" component="div">
                  Total Pendente: R${' '}
                  {setDecimal(identificacao?.Entidade?.vpendente || '0', 2)}
                  {!!identificacao?.Entidade?.acvalorpend &&
                    `   -    Limite de crédito: R$ ${setDecimal(
                      identificacao?.Entidade?.acvalorpend || '0',
                      2
                    )}`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" component="div">
                  {!!identificacao?.Entidade?.vlratraso &&
                    `Atraso: R$ 
                  ${setDecimal(
                    identificacao?.Entidade?.vlratraso || '0',
                    2
                  )} - (${identificacao?.Entidade?.diasatraso} Dias)`}
                  {!!identificacao?.Entidade?.vlratraso &&
                    !!identificacao?.Entidade?.acvlratraso &&
                    ` - Limites: R$: ${setDecimal(
                      identificacao?.Entidade?.acvlratraso || '0',
                      2
                    )} - (${identificacao?.Entidade?.acdiasatraso} Dias)`}

                  {!!identificacao?.Entidade?.vlratraso &&
                    !!identificacao?.Entidade?.acqtdatraso &&
                    ` - Quantidade de atrasos: ${identificacao?.Entidade?.acqtdatraso}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" component="div">
                  Última Compra:{' '}
                  {identificacao?.Entidade?.dataultcomp &&
                    moment(identificacao?.Entidade?.dataultcomp).format(
                      'DD/MM/YYYY'
                    )}
                  {' - '}({identificacao?.Entidade?.diasultcomp || '0'} Dias)
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {!!identificacao?.Registros?.filter(
                  (r) => r?.infcomercial === 'SIM'
                )?.length ? (
                  <>
                    <Typography variant="subtitle2" component="div">
                      Registros:
                    </Typography>
                    {identificacao?.Registros?.filter(
                      (r) => r?.infcomercial === 'SIM'
                    )?.map((r) => (
                      <Typography variant="subtitle2" component="div">
                        • {r?.registro}
                      </Typography>
                    ))}
                  </>
                ) : (
                  <Typography variant="subtitle2" component="div">
                    Nenhum registro comercial informado
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default withStyles(styles)(Atendimento);
