import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from '../../components/Navigation';
import { useSelector } from 'react-redux';

const SideBarContent = () => {
  const { menu } = useSelector(({ auth }) => auth);

  const navigationMenus = [
    {
      name: ' ',
      type: 'section',
      children: menu,
    },
  ];

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
