import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from 'store/ducks/Tables';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const ConfigRotinaModal = ({ open, setOpen, selected, type, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [perfil, setPerfil] = useState(null);
  const { postCustomRotinaLoading } = useSelector(({ tables }) => tables);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls, database: 'eprom' });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([892, 641]);
    } else {
      setCliente(null);
      setPerfil(null);
    }
  }, [open]);

  const onSubmit = () => {
    dispatch(
      TablesActions.postCustomRotina({
        data: {
          producao: selected?.producao,
          perfil_id: perfil?.value,
          eprom_id: cliente?.value,
        },
        type,
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      {(!!selected?.csqldescricao || !!selected?.descricao) && (
        <DialogTitle>
          {selected?.csqldescricao || selected?.descricao}
        </DialogTitle>
      )}
      <DialogContent>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <SelectAuto
            label="Cliente"
            items={items[0] || []}
            value={cliente}
            onChange={(v) => setCliente(v)}
          />
        </Grid>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <SelectAuto
            label="Perfil"
            items={items[1] || []}
            value={perfil}
            onChange={(v) => setPerfil(v)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={postCustomRotinaLoading}
        >
          Salvar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(ConfigRotinaModal);
