import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Grid,
} from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import DocMovimentacaoModal from 'components/Modals/DocMovimentacaoModal';
import SelectAuto from 'components/SelectAuto';
import { findOnArray, useQuery } from 'util/index';
import api from 'util/api';

const styles = (theme) => ({
  grid: {
    padding: '0 0.5rem',
    margin: '1rem 0',
  },
});

const Movimentacao = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const id = query.get('id');
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [local_id, setLocal_id] = useState(null);
  const {
    docmovimentarSuccess,
    docmovimentarLoading,
    itensLoading,
    itens,
  } = useSelector(({ documento }) => documento);
  const [docMovimentacaoModal, setDocMovimentacaoModal] = useState(false);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    loadItems([624]);
  }, []);

  useEffect(() => {
    if (!!items?.length && !!itens?.length) {
      if (itens[0]?.celocal_id) {
        setLocal_id(findOnArray(itens[0]?.celocal_id, items[0]));
      } else {
        setLocal_id(items[0][0]);
      }
    }
  }, [items, itens]);

  useEffect(() => {
    dispatch(
      DocumentoActions.getItens({ Parametros: [{ nome: ':id', valor: id }] })
    );
    if (docmovimentarSuccess) {
      dispatch(DocumentoActions.postDocMovimentarResset());
    }
  }, [docmovimentarSuccess]);

  function sendMovTodos() {
    let data = {
      operacao: 'INCLUIR',
      documento_id: itens[0].id,
      celocal_id: local_id?.value || null,
    };
    dispatch(DocumentoActions.postDocMovimentar(data));
  }

  function sendExcTodos() {
    let data = {
      operacao: 'EXCLUIR',
      documento_id: itens[0].id,
    };
    dispatch(DocumentoActions.postDocMovimentar(data));
  }

  const getTitulo = () => {
    let mtitulo = itens[0].nonatureza;
    if (mtitulo === 'ESTOQUE') mtitulo = itens[0].descespecie;
    mtitulo = mtitulo + '   -   Documento: ' + itens[0].id;
    if (itens[0].fornecedor != null && itens[0].fornecedor != '')
      mtitulo = mtitulo + '   -   Entidade: ' + itens[0].fornecedor;
    return mtitulo;
  };

  return (
    <div className="app-wrapper">
      {!!itens?.length && !itensLoading && (
        <HeaderPage title={getTitulo()} history={history} />
      )}
      <Card className="shadow">
        <Grid container>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Localização"
              items={items[0] || []}
              value={local_id}
              onChange={(v) => setLocal_id(v)}
              disabled={!!itens[0]?.celocal_id}
            />
          </Grid>
        </Grid>
      </Card>
      <Card className="shadow mt-4">
        {itensLoading ? (
          <Loading />
        ) : (
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Id</TableCell>
                    <TableCell align="right">Seq</TableCell>
                    <TableCell align="right">Produto</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell>Unid</TableCell>
                    <TableCell align="right">Qt.Expedida</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!itens.length &&
                    itens.map((i) => (
                      <TableRow>
                        <TableCell component="th" scope="row" align="right">
                          {i.iditem}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {i.sequencia}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {i.produto_id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i.descricao}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {parseFloat(Number(i.quantidade).toFixed(3))}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i.um}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {parseFloat(Number(i.qtconferida).toFixed(3))}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          <PlaylistAddCheckIcon
                            color="action"
                            onClick={() => {
                              setSelected(i);
                              setDocMovimentacaoModal(!docMovimentacaoModal);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <DocMovimentacaoModal
              open={docMovimentacaoModal}
              setOpen={setDocMovimentacaoModal}
              selected={selected}
              items={items}
            />
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="contained"
                color="primary"
                className="mr-2"
                onClick={() => sendMovTodos()}
                disabled={docmovimentarLoading}
              >
                MOVIMENTAR ITENS
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="ml-2"
                onClick={() => sendExcTodos()}
                disabled={docmovimentarLoading}
              >
                EXCLUIR MOVIMENTAÇÃO
              </Button>
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default withStyles(styles)(Movimentacao);
