import React from 'react';

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">
        Copyright Eprom Informática &copy; 2020 - Todos os direitos reservados
      </span>
    </footer>
  );
};
export default Footer;
