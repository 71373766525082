import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import InputMask from 'components/InputMask';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import moment from 'moment';

const OfxLanc = ({ open, setOpen, selected, loading, conta }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [lancamento, setLancamento] = useState({
    dtlanc: moment().format('DD/MM/YYYY'),
    planoconta_id: null,
    contacp_id: null,
  });

  useEffect(() => {
    if (selected) {
      setLancamento((prev) => ({
        ...prev,
        dtlanc: moment(selected?.data).format('DD/MM/YYYY'),
      }));
    }
  }, [selected]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([737, 607]);
    }
  }, [open]);

  useEffect(() => {
    if (selected) {
      setLancamento((prev) => ({
        ...prev,
        dtlanc: moment(selected?.data).format('DD/MM/YYYY'),
      }));
    }
  }, [selected]);

  const onSubmit = () => {
    const data = {
      conta_id: conta,
      conciliado: 'SIM',
      dtemis: moment(lancamento?.dtlanc, 'DD/MM/YYYY').isValid()
        ? moment(lancamento?.dtlanc, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      dtlanc: moment(lancamento?.dtlanc, 'DD/MM/YYYY').isValid()
        ? moment(lancamento?.dtlanc, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      documentocx: selected?.documento,
      origemlct: 'OFX',
      Detalhes: [
        {
          historico: selected?.memo,
          valor: selected?.valor,
          planoconta_id: lancamento?.planoconta_id?.value,
          contacp_id: lancamento?.contacp_id?.value,
        },
      ],
    };
    dispatch(DocumentoActions.postLancamento(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      PaperProps={{ style: { minWidth: '90%' } }}
    >
      <DialogTitle>Lançamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputMask
              label="Data do Lançamento"
              mask="99/99/9999"
              value={lancamento?.dtlanc}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setLancamento((prev) => ({ ...prev, dtlanc: value }));
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectAuto
              label="Plano de Conta"
              items={items[0] || []}
              value={lancamento?.planoconta_id}
              onChange={(v) =>
                setLancamento((prev) => ({ ...prev, planoconta_id: v }))
              }
            />
          </Grid>
          {Boolean(lancamento?.planoconta_id?.contacp_id) && (
            <Grid item xs={12}>
              <SelectAuto
                label="Conta para Transferência"
                items={items[1] || []}
                value={lancamento?.contacp_id}
                onChange={(v) =>
                  setLancamento((prev) => ({ ...prev, contacp_id: v }))
                }
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="primary" disabled={loading}>
          Confirmar
        </Button>
        <Button
          onClick={() => setOpen(null)}
          color="secondary"
          disabled={loading}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OfxLanc;
