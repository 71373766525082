import React from 'react';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import DescriptionIcon from '@material-ui/icons/Description';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import StraightenIcon from '@material-ui/icons/Straighten';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocationSearching from '@material-ui/icons/LocationSearching';
import Contacts from '@material-ui/icons/Contacts';
import InsertLink from '@material-ui/icons/InsertLink';
import HowToReg from '@material-ui/icons/HowToReg';
import Schedule from '@material-ui/icons/Schedule';
import AttachMoney from '@material-ui/icons/AttachMoney';
import GroupIcon from '@material-ui/icons/Group';
import Notes from '@material-ui/icons/Notes';
import FindReplace from '@material-ui/icons/FindReplace';
import Store from '@material-ui/icons/Store';
import Exposure from '@material-ui/icons/Exposure';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import Build from '@material-ui/icons/Build';
import FilterList from '@material-ui/icons/FilterList';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Receipt from '@material-ui/icons/Receipt';
import NewReleases from '@material-ui/icons/NewReleases';
import Cancel from '@material-ui/icons/Cancel';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PostAdd from '@material-ui/icons/PostAdd';
import HighQualityIcon from '@material-ui/icons/HighQuality';
import DetailsIcon from '@material-ui/icons/Details';
import CheckIcon from '@material-ui/icons/Check';
import StopIcon from '@material-ui/icons/Stop';
import PrintIcon from '@material-ui/icons/Print';
import BuildIcon from '@material-ui/icons/Build';
import ContactsIcon from '@material-ui/icons/Contacts';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import FormatTextdirectionLToRIcon from '@material-ui/icons/FormatTextdirectionLToR';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DoneIcon from '@material-ui/icons/Done';
import EmailIcon from '@material-ui/icons/Email';

const actionIcon = {
  Boleto: <PaymentIcon color="action" fontSize="small" className="mr-4" />,
  Remessa: <ReceiptIcon color="action" fontSize="small" className="mr-4" />,
  Duplicar: <FileCopyIcon color="action" fontSize="small" className="mr-4" />,
  Descrição: (
    <DescriptionIcon color="action" fontSize="small" className="mr-4" />
  ),
  Variação: <DoneAllIcon color="action" fontSize="small" className="mr-4" />,
  EstruturaDoProduto: (
    <StraightenIcon color="action" fontSize="small" className="mr-4" />
  ),
  Mídias: <PermMediaIcon color="action" fontSize="small" className="mr-4" />,
  ProcessosDeProdução: (
    <LocationCityIcon color="action" fontSize="small" className="mr-4" />
  ),
  Comissionamento: (
    <LocalAtmIcon color="action" fontSize="small" className="mr-4" />
  ),
  DocCusto: <LocalAtmIcon color="action" fontSize="small" className="mr-4" />,
  DocRegistro: <ExitToApp color="action" fontSize="small" className="mr-4" />,
  Aprovação: <NewReleases color="action" fontSize="small" className="mr-4" />,
  DocRecebimento: <Receipt color="action" fontSize="small" className="mr-4" />,
  DocStatus: <Cancel color="action" fontSize="small" className="mr-4" />,
  Documentos: (
    <DescriptionIcon color="action" fontSize="small" className="mr-4" />
  ),
  Documento: (
    <DescriptionIcon color="action" fontSize="small" className="mr-4" />
  ),
  Endereços: (
    <LocationSearching color="action" fontSize="small" className="mr-4" />
  ),
  Contatos: <Contacts color="action" fontSize="small" className="mr-4" />,
  Vinculos: <InsertLink color="action" fontSize="small" className="mr-4" />,
  Registros: <HowToReg color="action" fontSize="small" className="mr-4" />,
  Agenda: <Schedule color="action" fontSize="small" className="mr-4" />,
  Comissão: <AttachMoney color="action" fontSize="small" className="mr-4" />,
  Filiais: <GroupIcon color="action" fontSize="small" className="mr-4" />,
  Calculo: <Notes color="action" fontSize="small" className="mr-4" />,
  NecBruta: <FindReplace color="action" fontSize="small" className="mr-4" />,
  EstSeg: <Store color="action" fontSize="small" className="mr-4" />,
  Movimentar: <Exposure color="action" fontSize="small" className="mr-4" />,
  Compras: <ShoppingBasket color="action" fontSize="small" className="mr-4" />,
  Produção: <Build color="action" fontSize="small" className="mr-4" />,
  Liquida: <FilterList color="action" fontSize="small" className="mr-4" />,
  Terceiros: (
    <PeopleOutlineIcon color="action" fontSize="small" className="mr-4" />
  ),
  Preço: (
    <MonetizationOnIcon color="action" fontSize="small" className="mr-4" />
  ),
  Qualidade: (
    <HighQualityIcon color="action" fontSize="small" className="mr-4" />
  ),
  Gerar: <PostAdd fontSize="small" className="mr-4" />,
  Detalhes: <DetailsIcon fontSize="small" className="mr-4" />,
  Concluir: <CheckIcon color="action" fontSize="small" className="mr-4" />,
  Parar: <StopIcon color="action" fontSize="small" className="mr-4" />,
  Imprimir: <PrintIcon fontSize="small" className="mr-4" />,
  Manutenção: <BuildIcon fontSize="small" className="mr-4" />,
  Contato: <ContactsIcon color="action" fontSize="small" className="mr-4" />,
  Perfil: <PersonIcon color="action" fontSize="small" className="mr-4" />,
  Check: <DoneIcon color="action" fontSize="small" className="mr-4" />,
  Email: <EmailIcon color="action" fontSize="small" className="mr-4" />,
  Reverter: (
    <SettingsBackupRestoreIcon
      color="action"
      fontSize="small"
      className="mr-4"
    />
  ),
  Calendario: (
    <CalendarTodayIcon color="action" fontSize="small" className="mr-4" />
  ),
  Layout: (
    <FormatTextdirectionLToRIcon
      color="action"
      fontSize="small"
      className="mr-4"
    />
  ),
};

const TableItemOptions = ({
  menuOpcoes,
  setMenuOpcoes,
  data,
  index,
  items,
}) => {
  let visibles = items.some((i) => i?.visible);
  if (!visibles) return null;
  return (
    <>
      <MoreVertIcon
        color="action"
        style={{ paddingRight: '5px' }}
        onClick={({ currentTarget }) => {
          setMenuOpcoes({ open: index, currentTarget });
        }}
      />
      <Menu
        id="long-menu"
        anchorEl={menuOpcoes?.currentTarget}
        open={menuOpcoes?.open === index}
        onClose={() => setMenuOpcoes({ open: null })}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        {items.map(({ visible, action, name, varName, funcVis }) => {
          if (visible || (funcVis && funcVis(data))) {
            return (
              <>
                <MenuItem
                  onClick={() => {
                    action(data);
                    setMenuOpcoes({ open: null });
                  }}
                >
                  {actionIcon[varName || name]}
                  {name}
                </MenuItem>
                <Divider />
              </>
            );
          }
        })}
      </Menu>
    </>
  );
};
export default TableItemOptions;
