import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { transformData, setDecimal, findOnArray } from 'util/index';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import moment from 'moment';
import {
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  TextField,
  DialogContentText,
  Paper,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
  infoContainer: {
    margin: '0 0 20px 0',
    padding: '1rem',
    backgroundColor: '#c5c5c5',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0 5px',
  },
});

const DocMovimentacaoModal = ({ open, setOpen, selected, items, classes }) => {
  const dispatch = useDispatch();
  const [loc, setLoc] = useState(null);
  const [fill, setFill] = useState(null);
  const [listMov, setListMov] = useState(false);
  const {
    docmovimentarSuccess,
    docmovimentarLoading,
    movimentacao,
    movimentacaoLoading,
    qtdMov,
  } = useSelector(({ documento }) => documento);
  const { variacao } = useSelector(({ produto }) => produto);

  useEffect(() => {
    if (open) {
      setFill(false);
    }
  }, [open]);

  useEffect(() => {
    if (loc) {
      dispatch(DocumentoActions.getQtdMov({ id: selected?.iditem, loc }));
    }
  }, [loc]);

  useEffect(() => {
    if (selected) {
      dispatch(
        DocumentoActions.getMovimentacao({
          WhereClause: `and m.documento_item_id = ${selected?.iditem}`,
        })
      );
      dispatch(
        ProdutoActions.getVariacao({
          tabela: 'produto_variacao',
          filtro: `produto_id=${selected?.produto_id}`,
        })
      );
    }
  }, [selected]);

  useEffect(() => {
    if (selected && docmovimentarSuccess) {
      dispatch(DocumentoActions.postDocMovimentarResset());
      dispatch(
        DocumentoActions.getMovimentacao({
          WhereClause: `and m.documento_item_id = ${selected?.iditem}`,
        })
      );
    }
  }, [docmovimentarSuccess]);

  const SetValues = () => {
    const { values, setValues, setFieldValue } = useFormikContext();

    useEffect(() => {
      if (!!items.length && open && !fill) {
        setFill(true);
        if (!!items.length && !!selected?.celocal_id) {
          setValues({
            ...values,
            local_id: findOnArray(selected?.celocal_id, items[0]),
          });
        } else {
          setValues({
            ...values,
            local_id: items[0][0] || null,
          });
        }
      }

      if (!!qtdMov.length && open) {
        setValues({
          ...values,
          quantidade: setDecimal(qtdMov[0]?.qtmovimentar, 3),
          fator: qtdMov[0]?.fator,
        });
      }
    }, [items, open, qtdMov]);

    useEffect(() => {
      if (values?.local_id !== '') {
        setLoc(values?.local_id?.value);
      }
    }, [values]);
    return null;
  };

  function sendRegistro(values) {
    let data = {
      operacao: 'INCLUIR',
      documento_item_id: selected?.iditem,
      local_id: values?.local_id?.value,
      quantidade: transformData(values.quantidade, 'number') || 0,
      fator: transformData(values.fator, 'number') || 0,
      variacao_id: values?.variacao_id?.value,
      rastreabilidade: values?.rastreabilidade,
    };
    dispatch(DocumentoActions.postDocMovimentar(data));
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExit={() => {
        dispatch(
          DocumentoActions.getItens({
            Parametros: [{ nome: ':id', valor: selected?.id }],
          })
        );
      }}
    >
      <Formik
        initialValues={{
          local_id: null,
          origem_id: null,
          variacao_id: null,
          rastreabilidade: null,
          quantidade: null,
          fator: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetValues />
            <DialogTitle>Movimentação de estoque</DialogTitle>
            {listMov ? (
              <>
                <DialogContent>
                  <div className="app-wrapper">
                    <HeaderPage title={'Movimentações do item'} />
                    <Card className="shadow">
                      {movimentacaoLoading ? (
                        <Loading />
                      ) : (
                        <CardContent>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Usuário</TableCell>
                                  <TableCell>Data</TableCell>
                                  <TableCell>Local</TableCell>
                                  <TableCell>Rastreabilidade</TableCell>
                                  <TableCell>Variação</TableCell>
                                  <TableCell align="right">
                                    Quantidade
                                  </TableCell>
                                  <TableCell>Unid</TableCell>
                                  <TableCell align="right">Ação</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {!!movimentacao.length &&
                                  movimentacao.map((m) => (
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {m.name}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {moment(m.dtmovimento).format(
                                          'DD/MM/YYYY HH:mm:ss'
                                        )}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {m.local}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {m.rastreabilidade}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {m.variacao}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                      >
                                        {parseFloat(
                                          Number(m.quantidade).toFixed(3)
                                        )}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {m.sigla}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                      >
                                        <DeleteIcon
                                          color="action"
                                          onClick={() =>
                                            dispatch(
                                              DocumentoActions.postDocMovimentar(
                                                {
                                                  operacao: 'EXCLUIR',
                                                  id: m.id,
                                                }
                                              )
                                            )
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      )}
                    </Card>
                  </div>
                </DialogContent>
                <DialogActions>
                  <div className="d-flex flex-row align-items-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={() => setListMov(false)}
                    >
                      VOLTAR
                    </Button>
                  </div>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent>
                  <Paper className={classes.infoContainer}>
                    {selected?.descricao && (
                      <div className={classes.textContainer}>
                        <DialogContentText color="primary">
                          Produto: {selected?.produto_id} {' - '}{' '}
                          {selected?.descricao}
                        </DialogContentText>
                        <DialogContentText color="primary">
                          Quantidade:{' '}
                          {parseFloat(Number(selected?.quantidade).toFixed(3))}{' '}
                          {'  '} {selected?.um}
                        </DialogContentText>
                      </div>
                    )}
                  </Paper>

                  <Grid container className="my-4">
                    <Grid
                      xs="12"
                      sm={
                        selected?.variacao !== 'SIM' &&
                        selected?.rastreavel !== 'SIM'
                          ? '12'
                          : selected?.variacao === 'SIM' &&
                            selected?.rastreavel === 'SIM'
                          ? '4'
                          : '8'
                      }
                      md={
                        selected?.variacao !== 'SIM' &&
                        selected?.rastreavel !== 'SIM'
                          ? '12'
                          : selected?.variacao === 'SIM' &&
                            selected?.rastreavel === 'SIM'
                          ? '4'
                          : '8'
                      }
                      lg={
                        selected?.variacao !== 'SIM' &&
                        selected?.rastreavel !== 'SIM'
                          ? '12'
                          : selected?.variacao === 'SIM' &&
                            selected?.rastreavel === 'SIM'
                          ? '4'
                          : '8'
                      }
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Localização"
                        items={items[0]}
                        value={values.local_id}
                        onChange={(v) => {
                          setFieldValue('local_id', v);
                          dispatch(
                            ProdutoActions.getConversaoum({
                              Parametros: [
                                { nome: ':loc', valor: '0' },
                                { nome: ':prod', valor: '0' },
                              ],
                            })
                          );
                        }}
                      />
                    </Grid>
                    {selected?.variacao === 'SIM' && (
                      <Grid
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Variação do produto"
                          items={variacao.map((v) => ({
                            value: v.id,
                            label: v.variacao,
                          }))}
                          value={values.variacao}
                          onChange={(v) => setFieldValue('variacao_id', v)}
                        />
                      </Grid>
                    )}
                    {selected?.rastreavel === 'SIM' && (
                      <Grid
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        className={classes.grid}
                      >
                        <TextField
                          name="rastreabilidade"
                          label="Rastreabilidade"
                          value={values.rastreabilidade || ''}
                          onChange={handleChange}
                          onBlur={(e) => setFieldValue('rastreabilidade', e)}
                          fullWidth
                        />
                      </Grid>
                    )}

                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="quantidade"
                        label="Quantidade"
                        value={values.quantidade || ''}
                        onChange={handleChange}
                        InputProps={{ endAdornment: `${selected?.um}` }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="fator"
                        label="Fator entre unidades"
                        value={values.fator || ''}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="qtemov"
                        label="Quantidade à movimentar"
                        value={setDecimal(
                          (transformData(values.quantidade, 'number') || 0) *
                            (transformData(values.fator, 'number') || 0),
                          3
                        )}
                        onChange={handleChange}
                        disabled
                        InputProps={{ endAdornment: `${selected?.siglaumfab}` }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <div className="d-flex flex-row align-items-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => sendRegistro(values)}
                      disabled={docmovimentarLoading}
                    >
                      CONFIRMAR
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={() => setOpen(!open)}
                    >
                      CANCELAR
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={() => setListMov(true)}
                    >
                      MOVIMENTAÇÃO
                    </Button>
                  </div>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocMovimentacaoModal);
