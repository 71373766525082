import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { findOnArray } from 'util/index';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  infoContainer: {
    margin: '0 0 20px 0',
    padding: '1rem',
    backgroundColor: '#c5c5c5',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0 5px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const operacao = [
  { value: 'APROVAÇÃO', label: 'APROVAÇÃO' },
  { value: 'ANALISE', label: 'ANALISE' },
];

const DocAprovacaomodal = ({
  open,
  setOpen,
  selected,
  docId,
  data,
  classes,
}) => {
  const dispatch = useDispatch();

  const SetValues = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (selected) {
        setValues({
          operacao: findOnArray(selected?.operacaoaprov, operacao),
          acao: selected?.acao,
          justificativa: selected?.justificativa,
          aprovado: findOnArray(selected?.aprovado || 'SIM', yn),
        });
      } else {
        let initialAprovado = data.some((d) => d.aprovado !== 'SIM');
        setValues({
          operacao: findOnArray('APROVAÇÃO', operacao),
          aprovado: findOnArray(initialAprovado ? 'SIM' : 'NAO', yn),
        });
      }
    }, [selected]);
    return null;
  };

  function sendRegistro(values) {
    let data = {
      operacao: values?.operacao?.value,
      opcao: selected ? 'SOMENTE O ITEM' : 'TODO DOCUMENTO',
      acao: values?.acao,
      justificativa: values?.justificativa,
      aprovado: values?.aprovado?.value,
    };
    if (selected) {
      data = { ...data, documento_item_id: selected?.id };
    }

    dispatch(DocumentoActions.postAprovacao({ data, id: docId }));
    setOpen(!open);
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          operacao: null,
          acao: null,
          justificativa: null,
          aprovado: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetValues />
            <DialogTitle>Aprovação / Análise do Registro</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Operação"
                    items={operacao}
                    value={values.operacao}
                    onChange={(v) => setFieldValue('operacao', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="acao"
                    label="Ação a tomar"
                    value={values.acao}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="justificativa"
                    label="Justificativa"
                    value={values.justificativa}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Aprovar"
                    items={yn}
                    value={values.aprovado}
                    onChange={(v) => setFieldValue('aprovado', v)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row align-items-end">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => sendRegistro(values)}
                >
                  CONFIRMAR
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setOpen(!open)}
                >
                  CANCELAR
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocAprovacaomodal);
