import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as RelatorioActions,
  Types as RelatorioTypes,
} from 'store/ducks/Relatorio';
import api from '../../util/api';

function* postRelatorio({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/relatorio-relbaixas`, {
      filial_id: 125,
      titulo: 'Financeiro - Relação de Baixas',
      dados: {
        grupofin: 'TODOS',
        chequei: '',
        chequef: 'zzzzzzzzzz',
        dtemi: '1899-12-30',
        dtemf: '3000-12-31',
        entidadei: 0,
        entidadef: 999999,
        dtlanci: '1899-12-30',
        dtlancf: '3000-12-31',
        doci: 0,
        docf: 999999999,
        filiali: 0,
        filialf: 999999,
        dtvenci: '1899-12-30',
        dtvencf: '3000-12-31',
        formapgi: 0,
        formapgf: 999999,
      },
    });
    if (status === 200 || status === 201) {
      yield put(RelatorioActions.postRelatorioSuccess(data));
    } else {
      yield put(RelatorioActions.postRelatorioError());
    }
  } catch (error) {
    yield put(RelatorioActions.postRelatorioError());
  }
}

function* postRelatorioWatcher() {
  yield takeLatest(RelatorioTypes.POST_RELATORIO, postRelatorio);
}

export default function* rootSaga() {
  yield all([fork(postRelatorioWatcher)]);
}
