import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { transformData, setDecimal, findOnArray } from 'util/index';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  DialogContentText,
  Paper,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  infoContainer: {
    margin: '0 0 20px 0',
    padding: '1rem',
    backgroundColor: '#c5c5c5',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0 5px',
  },
});

const DocStatusmodal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const { documento } = useSelector(({ documento }) => documento);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([713]);
    if (selected && open)
      dispatch(DocumentoActions.getDocumento(selected?.documento_id));
  }, [selected, open]);

  const SetValues = () => {
    const { setFieldValue, setValues } = useFormikContext();
    useEffect(() => {
      if (selected) {
        setValues({
          status_id: findOnArray(documento?.statusdc_id, items[0]),
          motivo: documento?.Documento?.especie_id == 2 ? 'PEÇA INDISPONÍVEL' : '',
        });
      }
    }, [selected]);
    return null;
  };

  function sendRegistro(values) {
    let data = {
      status_id: values?.status_id?.value,
      motivo: values?.motivo,
    };
    dispatch(DocumentoActions.postStatus({ data, id: selected?.documento_id }));
    setOpen(!open);
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          status_id: null,
          motivo: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetValues />
            <DialogTitle>Registro de status para o documento</DialogTitle>
            {documento && !localLoading ? (
              <>
                <DialogContent>
                  <Paper className={classes.infoContainer}>
                    {documento?.Documento?.doc_especie && (
                      <DialogContentText color="primary">
                        {documento?.Documento?.doc_especie}
                        {'   ('}
                        {documento?.Documento?.id}
                        {')'}
                      </DialogContentText>
                    )}
                    <div className={classes.textContainer}>
                      {documento?.Documento?.dtemissao && (
                        <DialogContentText color="primary">
                          Emissao:{' '}
                          {moment(documento?.Documento?.dtemissao).format(
                            'DD/MM/YYYY'
                          )}
                        </DialogContentText>
                      )}
                      {documento?.Documento?.documento ? (
                        <DialogContentText color="primary">
                          Nro: {documento.Documento.documento}
                        </DialogContentText>
                      ) : null}
                    </div>
                    {documento?.Documento?.doc_nome && (
                      <DialogContentText color="primary">
                        Entidade: {documento?.Documento?.doc_nome}
                      </DialogContentText>
                    )}
                    {documento?.Documento?.doc_cidade && (
                      <DialogContentText color="primary">
                        Cidade: {documento?.Documento?.doc_cidade}
                        {' / '}
                        {documento?.Documento?.doc_uf}
                      </DialogContentText>
                    )}
                    {documento?.Documento?.doc_naturezaop && (
                      <DialogContentText color="primary">
                        Natureza: {documento?.Documento?.doc_naturezaop}
                      </DialogContentText>
                    )}
                    {documento?.Documento?.doc_status && (
                      <DialogContentText color="primary">
                        Status Atual: {documento?.Documento?.doc_status}
                      </DialogContentText>
                    )}
                  </Paper>
                  <Grid container>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Novo Status"
                        items={items[0]}
                        value={values.status_id}
                        onChange={(v) => setFieldValue('status_id', v)}
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <TextField
                        name="motivo"
                        label="Motivo da mudança"
                        value={values.motivo}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <div className="d-flex flex-row align-items-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => sendRegistro(values)}
                    >
                      CONFIRMAR
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={() => setOpen(!open)}
                    >
                      CANCELAR
                    </Button>
                  </div>
                </DialogActions>
              </>
            ) : (
              <Loading />
            )}
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocStatusmodal);
