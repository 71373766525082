import moment from 'moment';
import { toast } from 'react-toastify';

const getToken = () => localStorage.getItem('EpromApp:token');

const getUrl = () => localStorage.getItem('EpromApp:cliURL');

const removeToken = () => {
  localStorage.removeItem('EpromApp:token');
};

const isAuth = !!getToken();

const setMenuItems = (menu) => {
  const itens = [
    {
      id: 0,
      name: 'Dashboard',
      type: 'item',
      link: `/app`,
    },
  ];
  menu.map((r) => {
    if (!itens.some((i) => i['id'] === r.menu_id)) {
      if (r.menu_id !== null && r.menu_id !== 2) {
        itens.push({
          id: r.menu_id,
          name: r.descmenu,
          type: 'collapse',
          children: [],
        });
      }
    }
  });
  menu.map((m) => {
    itens.map((i) => {
      if (i.id === m?.menu_id && m.descmenu && m.descrotina) {
        i.children.push({
          name: m.descrotina,
          type: 'item',
          link: `/app/${m.descmenu.replace(/\s/g, '')}/${m.descrotina.replace(
            /\s/g,
            ''
          )}`,
        });
      }
    });
  });

  if (menu.some((s) => s?.menu_id === 2)) {
    itens.push({
      id: 999,
      name: 'Tabelas',
      type: 'item',
      link: `/app/Tabelas`,
    });
  }

  itens.map((i) => {
    if (i.children) {
      i.children.push({
        link: `/app/Relatorios?id=${i?.id}`,
        name: 'Relatórios',
        type: 'item',
      });
    }
  });

  return { itens, tabelas: menu.filter((f) => f.menu_id === 2) };
};

const getVisibleFields = (headerData) => {
  const fields = [];
  headerData.map((h) => {
    h.itens.map((i) => {
      if (i.restricao !== 'INVISIVEL') {
        fields.push(i.coluna);
      }
    });
  });
  return fields;
};

const getWhereClause = ({ filtros, setStringExport, param, relatorio }) => {
  let whereString = '';
  let exportString = '';
  let Parametros = [];

  if (filtros?.length > 0) {
    filtros.map((s) => {
      let mconteudoi = replaceConteudoi(s.conteudoi);
      if (
        mconteudoi !== null &&
        mconteudoi !== '' &&
        s.restricao === 'VISIVEL' &&
        s?.parmsqlcond !== 'NAO'
      ) {
        if (s.condicao === 'like') {
          const like =
            typeof s.conteudoi === 'string'
              ? mconteudoi.replace(/'/g, '')
              : mconteudoi;
          whereString =
            whereString + `and ${s.expressao} ${s.condicao} ${`'%${like}%'`} `;
        } else if (s.tipo === 'DATA') {
          const valiDate = moment(mconteudoi, 'DD/MM/YYYY').isValid();
          const parseDate = moment(mconteudoi, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          );
          whereString =
            whereString +
            `and ${s.expressao} ${s.condicao} ${
              valiDate ? `'${parseDate}'` : ''
            } `;
        } else {
          whereString =
            whereString + `and ${s.expressao} ${s.condicao} ${mconteudoi} `;
        }
      }
      if (s.parmsql) {
        let valor =
          s.tipo === 'DATA'
            ? moment(replaceConteudoi(s.conteudoi), 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )
            : replaceConteudoi(s.conteudoi);

        if (valor !== 'undefined') {
          if (!valor) {
            if (s?.tipo === 'NUMERO') {
              if (
                (s?.parmsql && s?.parmsql?.slice(-1) === 'F') ||
                s?.parmsql?.slice(-1) === 'f'
              ) {
                Parametros.push({
                  nome: s.parmsql,
                  valor: 999999,
                });
              } else {
                Parametros.push({
                  nome: s.parmsql,
                  valor: 0,
                });
              }
            } else {
              if (
                (s?.parmsql && s?.parmsql?.slice(-1) === 'F') ||
                s?.parmsql?.slice(-1) === 'f'
              ) {
                Parametros.push({
                  nome: s.parmsql,
                  valor: 'zzzzzz',
                });
              } else {
                Parametros.push({
                  nome: s.parmsql,
                  valor: '',
                });
              }
            }
          } else {
            if (typeof valor === 'string') {
              Parametros.push({
                nome: s.parmsql,
                valor: valor?.replace(/'|'|/g, ''),
              });
            } else {
              Parametros.push({
                nome: s.parmsql,
                valor,
              });
            }
          }
        }
      }
      if (
        mconteudoi !== null &&
        mconteudoi !== '' &&
        s.restricao === 'VISIVEL'
      ) {
        exportString =
          exportString + `, ${s.titulo}: ${s.conteudod || mconteudoi}`;
      }
    });
  }
  if (setStringExport) {
    setStringExport(exportString.slice(2));
  }
  if (param) {
    return Parametros;
  }
  if (relatorio) {
    return { whereString, filterString: exportString.slice(2) };
  }
  if (whereString === '') {
    return undefined;
  }
  return whereString;
};

const checkManutencao = (data) => {
  let status = false;
  data.map((d) => {
    d.itens.map((i) => {
      if (i.manutencao === 'SIM') status = true;
    });
  });
  return status;
};

const checkAgrupamento = (data) => {
  let status = false;
  data.map((d) => {
    d.itens.map((i) => {
      if (i.agrupamento !== 'NENHUM') status = true;
    });
  });
  return status;
};

const checkVariacao = (data) => {
  let status = false;
  data.map((d) => {
    d.itens.map((i) => {
      if (i.variacao === 'SIM') status = true;
    });
  });
  return status;
};

const checkRastreavel = (data) => {
  let status = false;
  data.map((d) => {
    d.itens.map((i) => {
      if (i.rastreavel === 'SIM') status = true;
    });
  });
  return status;
};

const viewBoxes = (data) => {
  let newData = [];
  data.map((e) => {
    if (e.grupo !== '') {
      e.itens.map((i) => {
        newData.push({
          id: `${i?.id}`,
          label: `${e?.grupo} - ${i?.titulo}`,
          campo: `${i?.coluna}`,
          check: i?.restricao === 'VISIVEL',
          tipo: i?.tipo,
          formato: i?.formato,
        });
      });
    } else {
      newData.push({
        id: `${e?.itens[0]?.id}`,
        label: e?.itens[0]?.titulo,
        campo: `${e?.itens[0]?.coluna}`,
        check: e?.itens[0]?.restricao === 'VISIVEL',
        tipo: e?.itens[0]?.tipo,
        formato: e?.itens[0]?.formato,
      });
    }
  });
  return newData;
};

const monetize = (value, prec) => {
  if (value !== '' && value !== null) {
    const precision = prec || 2;
    var digits = value.match(/\d/g) || ['0'];
    while (digits.length <= precision) {
      digits.unshift('0');
    }
    digits.splice(digits.length - precision, 0, '.');
    digits = Number(digits.join(''))
      .toFixed(precision)
      .split('');
    var decimalpos = digits.length - precision - 1;
    digits[decimalpos] = ',';
    for (var x = decimalpos - 3; x > 0; x = x - 3) {
      digits.splice(x, 0, '.');
    }

    const currency = digits.join('');
    return currency;
  }
  return value;
};

const setDecimal = (value, decs) => {
  if (value && value !== '' && value !== null) {
    const normalize = value.toString().replace(',', '.');
    var number = Number(normalize).toLocaleString('pt-BR', {
      minimumFractionDigits: decs,
      maximumFractionDigits: decs,
    });
    if (number === 'NaN') {
      return value;
    }
    return number;
  }
  return value;
};

const transformData = (data, type) => {
  if (typeof data === 'object') {
    return data?.value;
  }
  if (data === '') {
    return null;
  }
  if (type === 'number' && data !== null) {
    let parseData = data
      ?.toString()
      ?.replace(/\./g, '')
      ?.replace(',', '.');
    return Number(parseData);
  }
  return data;
};

const findOnArray = (item, arr) => {
  const rec = arr?.find((i) => i.value === item);
  return rec;
};

function useQuery(useLocation) {
  return new URLSearchParams(useLocation().search);
}

function replaceConteudoi(conteudoi, arr) {
  let mconteudoi = conteudoi;
  if (mconteudoi === '#DTHOJE') mconteudoi = moment().format('DD/MM/YYYY');
  if (mconteudoi === '#DTHOJE - 180') {
    var data = new Date();
    mconteudoi = moment(data.setDate(data.getDate() - 180)).format(
      'DD/MM/YYYY'
    );
  }
  if (mconteudoi === '#DTHOJE - 60') {
    var data = new Date();
    mconteudoi = moment(data.setDate(data.getDate() - 60)).format('DD/MM/YYYY');
  }
  if (mconteudoi === '#DTHOJE + 30') {
    var data = new Date();
    mconteudoi = moment(data.setDate(data.getDate() + 30)).format('DD/MM/YYYY');
  }
  if (mconteudoi === '#DTHOJE - 30') {
    var data = new Date();
    mconteudoi = moment(data.setDate(data.getDate() - 30)).format('DD/MM/YYYY');
  }
  if (mconteudoi === '#DTINIMES+1h' || mconteudoi === '#DTINIMES')
    mconteudoi = moment().format('01/MM/YYYY');
  if (mconteudoi === '#DTFIMMES+23h' || mconteudoi === '#DTFIMMES') {
    let end = moment().format('YYYY-MM-') + moment().daysInMonth();
    mconteudoi = moment(end, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }
  if (mconteudoi === '#DTINIANO') mconteudoi = moment().format('01/01/YYYY');
  if (mconteudoi === '#DTFIMANO') mconteudoi = moment().format('31/12/YYYY');

  if (arr) {
    const parseConteudo =
      typeof conteudoi === 'string'
        ? mconteudoi?.replace(/'|'|/g, '')
        : mconteudoi;
    return (
      arr.find((i) => i.value === parseConteudo) ||
      arr.find((i) => i.value === Number(parseConteudo))
    );
  }
  if (typeof conteudoi === 'string') {
    if (mconteudoi?.includes('[')) {
      return null;
    }
  }
  return mconteudoi;
}

const parseSuccess = (res) => {
  const message = res?.data?.message;
  if (res?.status === 204) {
    return toast.warning(
      'Nenhum dado encontrado, verifique o filtro do relatório'
    );
  }
  return toast.success(message);
};

const parseError = async (error) => {
  const message =
    error?.response?.data?.message ||
    JSON.parse(await error?.response?.data?.text())?.message ||
    '';
  const validationErrorMessage =
    error?.response?.data?.erros?.itens?.ErrosValidacao[0]?.message;

  if (validationErrorMessage) {
    if (validationErrorMessage?.includes('TokenExpiredError')) {
      window.location.reload();
    }
    return toast.error(validationErrorMessage);
  }
  if (message?.includes('TokenExpiredError')) {
    window.location.reload();
  }
  return toast.error(message);
};

const validaCpfCnpj = (val) => {
  if (val?.length == 11) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val?.length == 14) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const getTypeMidia = (file) => {
  if (file?.name?.includes('.pdf')) {
    return 'PDF';
  }
  if (
    file?.name?.includes('.avi') ||
    file?.name?.includes('.mp4') ||
    file?.name?.includes('.mpeg') ||
    file?.name?.includes('.ogv') ||
    file?.name?.includes('.ts') ||
    file?.name?.includes('.webm') ||
    file?.name?.includes('.3gp') ||
    file?.name?.includes('.3g2') ||
    file?.name?.includes('.flv') ||
    file?.name?.includes('.m3u8') ||
    file?.name?.includes('.mov') ||
    file?.name?.includes('.wmv')
  ) {
    return 'VIDEO';
  }
  return 'IMAGEM';
};

export {
  getToken,
  getUrl,
  removeToken,
  isAuth,
  setMenuItems,
  getVisibleFields,
  getWhereClause,
  checkManutencao,
  viewBoxes,
  monetize,
  setDecimal,
  transformData,
  findOnArray,
  useQuery,
  replaceConteudoi,
  checkAgrupamento,
  checkVariacao,
  checkRastreavel,
  parseSuccess,
  parseError,
  validaCpfCnpj,
  getTypeMidia,
};
