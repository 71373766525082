import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import tablesSagas from './Tables';
import ibgeSagas from './Ibge';
import entidadesSagas from './Entidades';
import produtoSagas from './Produto';
import midiaSagas from './Midia';
import documentoSagas from './Documento';
import calculoNSSagas from './CalculoNS';
import lancamentoSagas from './Lancamento';
import catalogoSagas from './Catalogo';
import agendaSagas from './Agenda';
import fiscalSagas from './Fiscal';
import relatorioSagas from './Relatorio';
import usuariosSagas from './Usuarios';
import integraSagas from './Integra';
import notificacoesSagas from './Notificacoes';
import contabilSagas from './Contabil';
import dropsSagas from './Drops';
import gnreSagas from './Gnre';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    tablesSagas(),
    ibgeSagas(),
    entidadesSagas(),
    produtoSagas(),
    midiaSagas(),
    documentoSagas(),
    calculoNSSagas(),
    lancamentoSagas(),
    catalogoSagas(),
    agendaSagas(),
    fiscalSagas(),
    relatorioSagas(),
    usuariosSagas(),
    integraSagas(),
    notificacoesSagas(),
    contabilSagas(),
    dropsSagas(),
    gnreSagas(),
  ]);
}
