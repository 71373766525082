export const Types = {
  SET_FILTROS_STORE: 'SET_FILTROS_STORE',
  RESET_FILTROS_STORE: 'RESET_FILTROS_STORE',
};

const INIT_STATE = {
  lastCalled: null,
  stored: {},
};

export default function FiltrosStore(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.SET_FILTROS_STORE: {
      return {
        ...state,
        lastCalled: action.payload.lastCalled,
        stored: { ...state.stored, ...action.payload.filtro },
      };
    }
    case Types.RESET_FILTROS_STORE: {
      return {
        ...state,
        lastCalled: null,
        stored: {},
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  setFiltrosStore: (payload) => ({
    type: Types.SET_FILTROS_STORE,
    payload,
  }),

  resetFiltrosStore: () => ({
    type: Types.RESET_FILTROS_STORE,
  }),
};
