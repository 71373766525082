export const Types = {
    GET_CALC: 'GET_CALC',
    GET_CALC_SUCCESS: 'GET_CALC_SUCCESS',
    GET_CALC_ERROR: 'GET_CALC_ERROR',

    POST_CALC: 'POST_CALC',
    POST_CALC_SUCCESS: 'POST_CALC_SUCCESS',
    POST_CALC_ERROR: 'POST_CALC_ERROR',
    POST_CALC_RESET: 'POST_CALC_RESET',
};

const INIT_STATE = {
    loading: false,
    calculo: null,
    error: false,

    postLoading: false,
    postSuccess: false,
    postError: false
};

export default function Ibge(state = INIT_STATE, action) {
    switch (action.type) {
        case Types.GET_CALC: {
            return {
                ...state,
                loading: true,
                calculo: null,
                error: false,
            };
        }
        case Types.GET_CALC_SUCCESS: {
            return {
                ...state,
                loading: false,
                calculo: action.payload,
                error: false,
            };
        }
        case Types.GET_CALC_ERROR: {
            return {
                ...state,
                loading: false,
                calculo: null,
                error: true,
            };
        }

        case Types.POST_CALC: {
            return {
                ...state,
                postLoading: true,
                postSuccess: false,
                postError: false
            };
        }
        case Types.POST_CALC_SUCCESS: {
            return {
                ...state,
                postLoading: false,
                postSuccess: true,
                postError: false
            };
        }
        case Types.POST_CALC_ERROR: {
            return {
                ...state,
                postLoading: false,
                postSuccess: false,
                postError: true
            };
        }
        case Types.POST_CALC_RESET: {
            return {
                ...state,
                postLoading: false,
                postSuccess: false,
                postError: false
            };
        }
        default:
            return state;
    }
}

export const Creators = {
    getCalc: () => ({
        type: Types.GET_CALC,
    }),
    getCalcSuccess: (payload) => ({
        type: Types.GET_CALC_SUCCESS,
        payload
    }),
    getCalcError: () => ({
        type: Types.GET_CALC_ERROR,
    }),

    postCalc: (payload) => ({
        type: Types.POST_CALC,
        payload
    }),
    postCalcSuccess: () => ({
        type: Types.POST_CALC_SUCCESS,
    }),
    postCalcError: () => ({
        type: Types.POST_CALC_ERROR,
    }),
    postCalcReset: () => ({
        type: Types.POST_CALC_RESET
    }),
};
