import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import { Creators as MidiaActions } from 'store/ducks/Midia';
import api from 'util/api';

const LinkMidiaModal = ({ open, setOpen, id }) => {
  const [util, setUtil] = useState(null);
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const { midia, putMidiaLoading } = useSelector(({ produto }) => produto);
  const { postMidiaId } = useSelector(({ midia }) => midia);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([779]);
    }
  }, [open]);

  const onSend = () => {
    var novaMidia = [
      ...midia,
      {
        produto_id: Number(id),
        tipo: util?.value || null,
        midia_id: postMidiaId,
      },
    ];
    dispatch(ProdutoActions.putMidia({ data: { registros: novaMidia }, id }));
    dispatch(MidiaActions.postMidiaReset());
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Utilização da Mídia</DialogTitle>
      {false ? (
        <Loading />
      ) : (
        <DialogContent>
          <SelectAuto
            label="Utilização"
            items={items[0] || []}
            value={util}
            onChange={(v) => setUtil(v)}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onSend()}
          color="primary"
          disabled={putMidiaLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default LinkMidiaModal;
