import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import api from 'util/api';
import { setDecimal, transformData, findOnArray } from 'util/index';
import { toast } from 'react-toastify';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const ChequeModal = ({ open, setOpen, onSubmit, pagamentos, classes }) => {
  const [items, setItems] = useState([]);
  const [cheques, setCheques] = useState([]);
  const [values, setValues] = useState({
    banda: '',
    banco_id: null,
    agencia: '',
    conta: '',
    cheque: '',
    valor: '',
    cidade: '',
    dtemissao: '',
    dtvenc: '',
    nome: '',
    tipdoc: null,
    numdoc: '',
    observacao: '',
  });

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  const resetValues = () => {
    setValues({
      ...values,
      banda: '',
      //    banco_id: null,
      //    agencia: '',
      //    conta: '',
      //    cheque: '',
      valor: '',
      //    cidade: '',
      //    dtemissao: '',
      //    dtvenc: '',
      //    nome: '',
      //    tipdoc: null,
      //    numdoc: '',
      observacao: '',
    });
  };

  useEffect(() => {
    if (open) {
      setValues((prev) => ({ ...prev, valor: pagamentos?.valor }));
      loadItems([617, 626]);
    } else {
      resetValues();
      setCheques([]);
    }
  }, [open]);

  const parseSubmit = () => {
    const valor = setDecimal(
      cheques?.length
        ? cheques
            ?.map((i) => transformData(i?.valor, 'number'))
            ?.reduce((prev, next) => prev + next)
        : '0',
      2
    );
    const data = {
      valor,
      cheques: cheques?.map((c) => ({
        ...c,
        banco_id: c?.banco_id?.value,
        tipo_documento_id: c?.tipdoc?.value,
        dtemissao: moment(c?.dtemissao, 'DD/MM/YYYY').isValid()
          ? moment(c?.dtemissao, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        dtvenc: moment(c?.dtvenc, 'DD/MM/YYYY').isValid()
          ? moment(c?.dtvenc, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
      })),
    };
    onSubmit(data);
    setOpen(null);
  };

  const onConfirm = () => {
    setCheques((prev) => [...prev, values]);
    resetValues();
  };

  const dadosCheque = async () => {
    if (
      Boolean(values?.banco_id) &&
      Boolean(values?.agencia) &&
      Boolean(values?.conta)
    ) {
      const { data: resVerify } = await api.post(`/csql/26`, {
        Parametros: [
          { nome: ':banco', valor: values?.banco_id?.value },
          { nome: ':agencia', valor: values?.agencia },
          { nome: ':conta', valor: values?.conta },
        ],
      });
      const { data: resData } = await api.post(`/csql/27`, {
        Parametros: [
          { nome: ':banco', valor: values?.banco_id?.value },
          { nome: ':agencia', valor: values?.agencia },
          { nome: ':conta', valor: values?.conta },
        ],
      });
      if (resVerify?.dados[0]?.valor > 0) {
        toast.warn(
          `Atenção! Esta conta possui uma dívida de R$${setDecimal(
            resVerify?.dados[0]?.valor,
            2
          )}`
        );
      }

      if (!!resData?.dados?.length) {
        setValues({
          ...values,
          cidade: resData?.dados[0]?.cidade,
          nome: resData?.dados[0]?.nome,
          tipdoc: findOnArray(resData?.dados[0]?.tipo_documento_id, items[1]),
          numdoc: resData?.dados[0]?.numdoc,
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Dados do Cheque</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Banda"
              value={values.banda}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, banda: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Banco"
              items={items[0]}
              value={values.banco_id}
              onChange={(v) => setValues((prev) => ({ ...prev, banco_id: v }))}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              label="Agência"
              value={values.agencia}
              fullWidth
              onBlur={dadosCheque}
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, agencia: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              label="Conta"
              value={values.conta}
              fullWidth
              onBlur={dadosCheque}
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, conta: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              label="Cheque"
              value={values.cheque}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, cheque: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              label="Valor"
              value={values.valor}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, valor: value }));
              }}
              onBlur={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({
                  ...prev,
                  valor: setDecimal(value, 2),
                }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Cidade"
              value={values.cidade}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, cidade: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <InputMask
              label="Data de Emissão"
              mask="99/99/9999"
              value={values.dtemissao}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, dtemissao: value }));
              }}
              onBlur={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, dtvenc: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <InputMask
              label="Data de Vencimento"
              mask="99/99/9999"
              value={values.dtvenc}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, dtvenc: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Nome"
              value={values.nome}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, nome: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <SelectAuto
              label="Tipo Documento"
              items={items[1] || []}
              value={values.tipdoc}
              onChange={(v) => setValues((prev) => ({ ...prev, tipdoc: v }))}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              label="Nº Documento"
              value={values.numdoc}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, numdoc: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <TextField
              label="Observação"
              value={values.observacao}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, observacao: value }));
              }}
            />
          </Grid>
        </Grid>
        <div className="d-flex flex-row align-items-end justify-content-end">
          <Button variant="outlined" color="primary" onClick={onConfirm}>
            CONFIRMAR
          </Button>
        </div>
        <TableContainer component={Paper} className="my-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Nº Cheque</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell align="right">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cheques.map((m, i) => (
                <TableRow>
                  <TableCell>{m?.nome}</TableCell>
                  <TableCell>{m?.cheque}</TableCell>
                  <TableCell>{m?.valor}</TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <DeleteIcon
                      color="action"
                      onClick={() =>
                        setCheques((prev) =>
                          prev?.filter((_, idx) => idx !== i)
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row align-items-end">
          <Button variant="contained" color="primary" onClick={parseSubmit}>
            SALVAR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="ml-2"
            onClick={() => setOpen(null)}
          >
            FECHAR
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ChequeModal);
