import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as FiscalActions } from '../../../store/ducks/Fiscal';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import api from 'util/api';
import { findOnArray, setDecimal, transformData } from 'util/index';
import moment from 'moment';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const atividadeDrop = [
  { value: 0, label: 'Industrial ou equiparado a industrial' },
  { value: 1, label: 'Prestador de serviços' },
  { value: 2, label: 'Atividade de coméricio' },
  {
    value: 3,
    label:
      'Pessoas jurídicas referidas nos §§ 6º, 8º e 9º do art. 3º da Lei nº 9.718, de 1998',
  },
  { value: 4, label: 'Atividade imobiliária' },
  { value: 9, label: 'Outros' },
];

const apuracaoDrop = [
  { value: 0, label: 'Mensal' },
  { value: 1, label: 'Decendial' },
];

const tributoDrop = [
  {
    value: 1,
    label:
      'Escrituração de operações com incidência exclusivamente no regime não-cumulativo',
  },
  {
    value: 2,
    label:
      'Escrituração de operações com incidência exclusivamente no regime cumulativo',
  },
  {
    value: 3,
    label:
      'Escrituração de operações com incidência exclusivamente no regime não-cumulativo e cumulativo',
  },
];

const creditoDrop = [
  { value: 1, label: 'Método de Apropriação Direta' },
  { value: 2, label: 'Método de Rateio Proporcional (Receita Bruta)' },
];

const contribDrop = [
  {
    value: 1,
    label: 'Apuração da Contribuição Exclusivamente a Alíquita Básica',
  },
  {
    value: 2,
    label:
      'Apuração da Contribuição a Alíquitas Específicas (Diferenciadas e/ou por Unidade de Medida de Produto',
  },
];

const regimeDrop = [
  { value: 0, label: 'Se Cod_Inc_Trbd = 1' },
  { value: 1, label: 'Regime de Caixa - Escrituração consolidada' },
  {
    value: 2,
    label: 'Regime de Competência - Escrituração consolidada - Usar 9',
  },
  {
    value: 9,
    label:
      "Regime de Competência - Escrituração detalhada, com base nos registros dos Blocos 'A', 'C', 'D' e 'F' ",
  },
];

const ConfigFilialModal = ({ open, setOpen, rotina, filtros, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const { apuracao, postApurLoading, postApurSuccess } = useSelector(
    ({ fiscal }) => fiscal
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    if (postApurSuccess) {
      setOpen(!open);
      dispatch(FiscalActions.getApuracaoError());
      dispatch(FiscalActions.postApuracaoReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postApurSuccess]);

  useEffect(() => {
    loadItems([621, 633]);
  }, [open]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();

    const getValue = (value) => {
      return apuracao.find((a) => a.identificacao === value)?.conteudo;
    };

    useEffect(() => {
      if (!open) {
        setValues({
          filial: null,
          versao: null,
          atividade: null,
          contabilista: null,
          apuracao: null,
          atEconomica: null,
          semReceita: null,
          receitaBruta: null,
          contaContabil: null,
          tributo: null,
          credito: null,
          contribuicao: null,
          regime: null,
        });
      }
      if (!!apuracao.length && open) {
        setValues({
          ...values,
          versao: getValue('EFD_VERSAO')
            ? setDecimal(getValue('EFD_VERSAO'), 2)
            : null,
          atividade: getValue('EFD_ATIVIDADE')
            ? findOnArray(Number(getValue('EFD_ATIVIDADE')), atividadeDrop)
            : null,
          contabilista: getValue('EFD_CONTABILISTA')
            ? findOnArray(Number(getValue('EFD_CONTABILISTA')), items[1])
            : null,
          apuracao: getValue('EFD_APURACAO')
            ? findOnArray(Number(getValue('EFD_APURACAO')), apuracaoDrop)
            : null,
          atEconomica: getValue('EFD_ATIV_ECON_P')
            ? setDecimal(getValue('EFD_ATIV_ECON_P'), 2)
            : null,
          semReceita: getValue('EFD_PORC_SEM_RECEITA_P')
            ? setDecimal(getValue('EFD_PORC_SEM_RECEITA_P'), 2)
            : null,
          receitaBruta: getValue('EFD_VL_REC_BRUTA_P')
            ? setDecimal(getValue('EFD_VL_REC_BRUTA_P'), 2)
            : null,
          contaContabil: getValue('EFD_CONTA_CONTABIL_P')
            ? setDecimal(getValue('EFD_CONTA_CONTABIL_P'), 2)
            : null,
          tributo: getValue('EFD_INC_TRIB')
            ? findOnArray(Number(getValue('EFD_INC_TRIB')), tributoDrop)
            : null,
          credito: getValue('EFD_APROP_CREDITO')
            ? findOnArray(Number(getValue('EFD_APROP_CREDITO')), creditoDrop)
            : null,
          contribuicao: getValue('EFD_TIPO_CONTRIB')
            ? findOnArray(Number(getValue('EFD_TIPO_CONTRIB')), contribDrop)
            : null,
          regime: getValue('EFD_TIPO_REGIME')
            ? findOnArray(Number(getValue('EFD_TIPO_REGIME')), regimeDrop)
            : null,
        });
      } else {
        setValues({
          ...values,
          versao: null,
          atividade: null,
          contabilista: null,
          apuracao: null,
          atEconomica: null,
          semReceita: null,
          receitaBruta: null,
          contaContabil: null,
          tributo: null,
          credito: null,
          contribuicao: null,
          regime: null,
        });
      }
    }, [apuracao, open]);
    return null;
  };

  const getIdentificacao = (key) => {
    if (key === 'versao') {
      return 'EFD_VERSAO';
    }
    if (key === 'atividade') {
      return 'EFD_ATIVIDADE';
    }
    if (key === 'contabilista') {
      return 'EFD_CONTABILISTA';
    }
    if (key === 'apuracao') {
      return 'EFD_APURACAO';
    }
    if (key === 'atEconomica') {
      return 'EFD_ATIV_ECON_P';
    }
    if (key === 'semReceita') {
      return 'EFD_PORC_SEM_RECEITA_P';
    }
    if (key === 'receitaBruta') {
      return 'EFD_VL_REC_BRUTA_P';
    }
    if (key === 'contaContabil') {
      return 'EFD_CONTA_CONTABIL_P';
    }
    if (key === 'tributo') {
      return 'EFD_INC_TRIB';
    }
    if (key === 'credito') {
      return 'EFD_APROP_CREDITO';
    }
    if (key === 'contribuicao') {
      return 'EFD_TIPO_CONTRIB';
    }
    if (key === 'regime') {
      return 'EFD_TIPO_REGIME';
    }
    return '';
  };

  const onSendPost = (values) => {
    let dtapuracao = filtros.find((f) => f.titulo.toUpperCase() === 'DATA FIM')
      ?.conteudoi;
    if (dtapuracao === '#DTFIMMES') {
      dtapuracao = moment().format('YYYY-MM-') + moment().daysInMonth();
    } else if (moment(dtapuracao, 'DD/MM/YYYY').isValid()) {
      dtapuracao = moment(dtapuracao, 'DD/MM/YYYY').format('YYYY-MM-DD');
    } else if (moment(dtapuracao, 'DD-MM-YYYY').isValid()) {
      dtapuracao = moment(dtapuracao, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    let apuracao = [];
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'filial') {
        if (typeof value === 'object') {
          apuracao.push({
            identificacao: getIdentificacao(key),
            conteudo: value?.value || null,
          });
        } else {
          apuracao.push({
            identificacao: getIdentificacao(key),
            conteudo: value ? transformData(value, 'number') : null,
          });
        }
      }
    });
    const data = {
      filial_id: values?.filial?.value,
      tributo_id: 4,
      dtapuracao,
      descricao: 'EFD-CONTRIBUICAO',
      apuracao,
    };
    dispatch(FiscalActions.postApuracao(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExited={() => dispatch(FiscalActions.getApuracaoError())}
    >
      <Formik
        initialValues={{
          filial: null,
          versao: null,
          atividade: null,
          contabilista: null,
          apuracao: null,
          atEconomica: null,
          semReceita: null,
          receitaBruta: null,
          contaContabil: null,
          tributo: null,
          credito: null,
          contribuicao: null,
          regime: null,
        }}
      >
        {({ values, handleChange, setFieldValue, setValues }) => (
          <>
            <SetFields />
            <DialogTitle>Configurar Filial</DialogTitle>
            {localLoading ? (
              <Loading />
            ) : (
              <DialogContent>
                <Grid container>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Filial"
                      items={items[0]}
                      value={values.filial}
                      onChange={(v) => {
                        setFieldValue('filial', v);
                        if (v) {
                          dispatch(
                            FiscalActions.getApuracao({
                              filial_id: v?.value,
                              tributo_id: 4,
                              descricao: 'EFD-PISCOFINS',
                            })
                          );
                        } else {
                          setValues({
                            versao: null,
                            atividade: null,
                            contabilista: null,
                            apuracao: null,
                            atEconomica: null,
                            semReceita: null,
                            receitaBruta: null,
                            contaContabil: null,
                            tributo: null,
                            credito: null,
                            contribuicao: null,
                            regime: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="versao"
                      label="Versão"
                      value={values?.versao || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                    <SelectAuto
                      label="Atividade"
                      items={atividadeDrop}
                      value={values.atividade}
                      onChange={(v) => setFieldValue('atividade', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Contabilista"
                      items={items[1]}
                      value={values.contabilista}
                      onChange={(v) => setFieldValue('contabilista', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Apuração"
                      items={apuracaoDrop}
                      value={values.apuracao}
                      onChange={(v) => setFieldValue('apuracao', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="atEconomica"
                      label="Atividade Econômica"
                      value={values?.atEconomica || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="semReceita"
                      label="% Sem Receita"
                      value={values?.semReceita || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="receitaBruta"
                      label="Receita Bruta"
                      value={values?.receitaBruta || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="contaContabil"
                      label="Conta Contábil"
                      value={values?.contaContabil || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <SelectAuto
                      label="Tributo"
                      items={tributoDrop}
                      value={values.tributo}
                      onChange={(v) => setFieldValue('tributo', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <SelectAuto
                      label="Crédito"
                      items={creditoDrop}
                      value={values.credito}
                      onChange={(v) => setFieldValue('credito', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <SelectAuto
                      label="Contribuição"
                      items={contribDrop}
                      value={values.contribuicao}
                      onChange={(v) => setFieldValue('contribuicao', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <SelectAuto
                      label="Regime"
                      items={regimeDrop}
                      value={values.regime}
                      onChange={(v) => setFieldValue('regime', v)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={() => onSendPost(values)}
                color="primary"
                disabled={postApurLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(ConfigFilialModal);
