import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WarningIcon from '@material-ui/icons/Warning';

const Legendas = ({ screen }) => {
  if (screen === 'Produto') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'green' }} />
        <span className="mr-4">Ativo</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'red' }} />
        <span className="mr-4">Inativo</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'orange' }} />
        <span className="mr-4">Pendente</span>
      </div>
    );
  }
  if (screen === 'Aprovacao') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'orange' }} />
        <span className="mr-4">Documentos a serem aprovados</span>
      </div>
    );
  }
  if (screen === 'Producao') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'blue' }} />
        <span className="mr-4">Aguardando Aprovação</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'black' }} />
        <span className="mr-4">Aprovadas</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'green' }} />
        <span className="mr-4">Em Produção</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'red' }} />
        <span className="mr-4">Finalizadas</span>
      </div>
    );
  }
  if (screen === 'Vencimento') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'orange' }} />
        <span className="mr-4">À vencer em até 3 dias</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'red' }} />
        <span className="mr-4">Vencidos</span>
      </div>
    );
  }
  if (screen === 'Documento') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'blue' }} />
        <span className="mr-4">Ciência da Operação</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'green' }} />
        <span className="mr-4">Confirmação da Operação</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'orange' }} />
        <span className="mr-4">Operação não realizada</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'yellow' }} />
        <span className="mr-4">Desconhecimento da operação</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'red' }} />
        <span className="mr-4">Desacordo</span>
      </div>
    );
  }
  if (screen === 'Notas') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'red' }} />
        <span className="mr-4">Cancelado</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'orange' }} />
        <span className="mr-4">Falta Aprovação</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'blue' }} />
        <span className="mr-4">Carta de Correção</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'pink' }} />
        <span className="mr-4">Aguardando Autorização</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'green' }} />
        <span className="mr-4">Concluído</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'brown' }} />
        <span className="mr-4">Contingência</span>
      </div>
    );
  }
  if (screen === 'GNRE') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'red' }} />
        <span className="mr-4">Invalidada pelo portal ou UF</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'orange' }} />
        <span className="mr-4">Em processamento</span>
        <FiberManualRecordIcon fontSize="small" style={{ color: 'green' }} />
        <span className="mr-4">Concluído</span>
        <WarningIcon fontSize="small" style={{ color: 'red' }} />
        <span className="mr-4">Homologação</span>
      </div>
    );
  }
  if (screen === 'Plano de Contas') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'blue' }} />
        <span className="mr-4">Conta Operacional</span>
      </div>
    );
  }
  if (screen === 'Gestão de Serviço') {
    return (
      <div className="d-flex align-items-center mt-3">
        <FiberManualRecordIcon fontSize="small" style={{ color: 'green' }} />
        <span className="mr-4">Em Execução</span>
      </div>
    );
  }
  return <></>;
};

export default Legendas;
