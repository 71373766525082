import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const CarregamentoModal = ({
  open,
  setOpen,
  selected = [],
  history,
  classes,
}) => {
  const dispatch = useDispatch();
  const { postCarregamentoLoading, postCarregamentoSuccess } = useSelector(
    ({ documento }) => documento
  );
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    filial_id: null,
    entidade_id: null,
    tpemit: null,
    tptransp: null,
    frota_id: null,
    reboque1_id: null,
    reboque2_id: null,
    transportador_id: null,
    motorista_id: null,
  });

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([621, 728, 842, 843, 710, 729]);
    }
  }, [open]);

  useEffect(() => {
    if (postCarregamentoSuccess) {
      dispatch(DocumentoActions.postCarregamentoReset());
      setOpen(false);
      history.push(`/app/Comercial/NotasEmitidas/Carregamento`);
    }
  }, [postCarregamentoSuccess]);

  const onSubmit = () => {
    const data = {
      filial_id: values?.filial_id?.value,
      entidade_id: values?.entidade_id?.value,
      tpemit: values?.tpemit?.value,
      tptransp: values?.tptransp?.value,
      frota_id: values?.frota_id?.value,
      reboque1_id: values?.reboque1_id?.value,
      reboque2_id: values?.reboque2_id?.value,
      transportador_id: values?.transportador_id?.value,
      motorista_id: values?.motorista_id?.value,
      Documentos: selected?.map((s) => s?.id),
    };
    dispatch(DocumentoActions.postCarregamento(data));
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Gerar Carregamento</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Filial"
              items={items[0] || []}
              value={values?.filial_id}
              onChange={(v) =>
                setValues((prevState) => ({ ...prevState, filial_id: v }))
              }
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Emitente"
              items={items[1] || []}
              value={values?.entidade_id}
              onChange={(v) =>
                setValues((prevState) => ({ ...prevState, entidade_id: v }))
              }
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Tipo Emitente"
              items={items[2] || []}
              value={values?.tpemit}
              onChange={(v) =>
                setValues((prevState) => ({ ...prevState, tpemit: v }))
              }
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Tipo Transportador"
              items={items[3] || []}
              value={values?.tptransp}
              onChange={(v) =>
                setValues((prevState) => ({ ...prevState, tptransp: v }))
              }
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Transportador"
              items={items[1] || []}
              value={values?.transportador_id}
              onChange={(v) =>
                setValues((prevState) => ({
                  ...prevState,
                  transportador_id: v,
                }))
              }
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Motorista"
              items={items[5] || []}
              value={values?.motorista_id}
              onChange={(v) =>
                setValues((prevState) => ({
                  ...prevState,
                  motorista_id: v,
                }))
              }
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Veículo"
              items={items[4] || []}
              value={values?.frota_id}
              onChange={(v) =>
                setValues((prevState) => ({ ...prevState, frota_id: v }))
              }
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Reboque 1"
              items={items[4] || []}
              value={values?.reboque1_id}
              onChange={(v) =>
                setValues((prevState) => ({ ...prevState, reboque1_id: v }))
              }
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Reboque 2"
              items={items[4] || []}
              value={values?.reboque2_id}
              onChange={(v) =>
                setValues((prevState) => ({ ...prevState, reboque2_id: v }))
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={postCarregamentoLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(CarregamentoModal);
