import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { findOnArray } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridRadio: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const itensDataBase = (doc) => {
  const item = [];
  if (doc.dtemissao) {
    item.push({
      value: 'EMISSAO',
      label: 'Emissão',
    });
  }
  if (doc.doc_dtorg) {
    item.push({
      value: 'ORIGEM',
      label: 'Docto Origem',
    });
  }
  if (doc.doc_przent) {
    item.push({
      value: 'ENTREGA',
      label: 'Prazo Entrega',
    });
  }
  return item;
};

const itensFora = [
  {
    value: 'SEMANA',
    label: 'SEMANA',
  },
  {
    value: 'QUINZENA',
    label: 'QUINZENA',
  },
  {
    value: 'MES',
    label: 'MES',
  },
  {
    value: 'ANO',
    label: 'ANO',
  },
];

const itensSubTrib = [
  {
    value: 'TODAS',
    label: 'Todas Parcelas',
  },
  {
    value: 'PRIMEIRA',
    label: 'Primeira Parcela',
  },
  {
    value: 'ULTIMA',
    label: 'Última Parcela',
  },
];

const itensSemana = [
  {
    value: 1,
    label: 'SEGUNDA-FEIRA',
  },
  {
    value: 2,
    label: 'TERÇA-FEIRA',
  },
  {
    value: 3,
    label: 'QUARTA-FEIRA',
  },
  {
    value: 4,
    label: 'QUINTA-FEIRA',
  },
  {
    value: 5,
    label: 'SEXTA-FEIRA',
  },
  {
    value: 6,
    label: 'SÁBADO',
  },
  {
    value: 7,
    label: 'DOMINGO',
  },
];

const PaymentModal = ({
  open,
  setOpen,
  condPag,
  formaPag,
  documento,
  classes,
}) => {
  const dispatch = useDispatch();

  const getFora = (condPag) => {
    if (condPag.forasemana === 'SIM') {
      return 'SEMANA';
    }
    if (condPag.foraquinzena === 'SIM') {
      return 'QUINZENA';
    }
    if (condPag.forames === 'SIM') {
      return 'MES';
    }
    if (condPag.foraano === 'SIM') {
      return 'ANO';
    }
    return null;
  };

  const SetFields = () => {
    const { setFieldValue, setValues, values } = useFormikContext();
    useEffect(() => {
      if (condPag) {
        setValues({
          ...values,
          parcelas: condPag?.nvezes,
          entrada: condPag?.entrada === 'SIM',
          intervaloPrimeira: condPag?.intervalop ? condPag?.intervalop : '0',
          intervalo: condPag?.intervalo,
          tododia: condPag?.dia,
          cstfin: condPag?.custofin,
          fora: findOnArray(getFora(condPag), itensFora),
          obs: null
        });
      }
    }, []);
    return null;
  };

  const onSend = (values) => {
    const data = {
      condicao_pagamento_id: condPag?.value,
      forma_pagto_id: formaPag?.value,
      cstfin: values?.cstfin,
      fora: values?.fora?.value,
      imposto: values?.subTrib?.value,
      intervalo: Number(values?.intervalo),
      intervalop: Number(values?.intervaloPrimeira),
      qtparcelas: values?.parcelas,
      diafixo: Number(values?.tododia),
      diasemana: values?.diasemana?.value,
      posterga: values?.posterga ? 'SIM' : 'NAO',
      entrada: values?.entrada ? 'SIM' : 'NAO',
      dtemissao: moment(values?.database, 'DD/MM/YYYY').isValid()
        ? moment(values?.database, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      observacao: values?.obs  
    };
    dispatch(
      DocumentoActions.postFinanceiro({ id: documento?.Documento?.id, data })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <Formik
        initialValues={{
          parcelas: null,
          entrada: false,
          intervaloPrimeira: null,
          intervalo: null,
          posterga: false,
          database: null,
          radioDatabase: null,
          fora: null,
          subTrib: null,
          cstfin: null,
          tododia: null,
          diasemana: null,
          obs: null
        }}
        onSubmit={(values) => onSend(values)}
      >
        {({ values, handleChange, submitForm, setFieldValue }) => (
          <>
            <SetFields />
            <DialogTitle>Detalhes do Pagamento</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="parcelas"
                    label="Parcelas"
                    value={values?.parcelas || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <FormControlLabel
                    className="d-flex"
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.entrada}
                        onChange={() =>
                          setFieldValue('entrada', !values.entrada)
                        }
                      />
                    }
                    label="Haverá entrada"
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <TextField
                    name="intervaloPrimeira"
                    label="Intervalo 1ª Pc"
                    value={values?.intervaloPrimeira || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <TextField
                    name="intervalo"
                    label="Intervalo"
                    value={values?.intervalo || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    name="radioDatabase"
                    label="Data Base para Parcelamento"
                    items={itensDataBase(documento?.Documento)}
                    value={values.radioDatabase}
                    onChange={(v) => {
                      setFieldValue('radioDatabase', v);
                      if (v?.value === 'ORIGEM') {
                        setFieldValue(
                          'database',
                          moment(documento?.Documento?.doc_dtorg).format(
                            'DD/MM/YYYY'
                          )
                        );
                      }
                      if (v?.value === 'EMISSAO') {
                        setFieldValue(
                          'database',
                          moment(documento?.Documento?.dtemissao).format(
                            'DD/MM/YYYY'
                          )
                        );
                      }
                      if (v?.value === 'ENTREGA') {
                        setFieldValue(
                          'database',
                          moment(documento?.Documento?.doc_przent).format(
                            'DD/MM/YYYY'
                          )
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="database"
                    label="Data Base"
                    mask="99/99/9999"
                    value={values.database || ''}
                    onChange={handleChange}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <FormControlLabel
                    className="d-flex"
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.posterga}
                        onChange={() =>
                          setFieldValue('posterga', !values.posterga)
                        }
                      />
                    }
                    label="Postergar Prx. Dia Útil"
                  />
                </Grid>

                {Number(values?.intervalo) - Number(values?.intervaloPrimeira) >
                  30 && (
                  <>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <InputMask
                        name="tododia"
                        label="Todo Dia"
                        mask="99"
                        value={values.tododia}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        name="diasemana"
                        label="Dia da Semana"
                        items={itensSemana}
                        value={values.diasemana}
                        onChange={(v) => setFieldValue('diasemana', v)}
                      />
                    </Grid>
                  </>
                )}
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    name="fora"
                    label="Fora"
                    items={itensFora}
                    value={values.fora}
                    onChange={(v) => setFieldValue('fora', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    name="subTrib"
                    label="Parcelamento do Valor da Sub. Tributária"
                    items={itensSubTrib}
                    value={values.subTrib}
                    onChange={(v) => setFieldValue('subTrib', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="cstfin"
                    label="Custo Financeiro"
                    value={values?.cstfin || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="obs"
                    label="Observação"
                    value={values?.obs || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={submitForm}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(PaymentModal);
