import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import { useQuery } from 'util/index';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import api from 'util/api';

const Relatorios = ({ match }) => {
  const query = useQuery(useLocation);
  const id = query.get('id');
  const [loading, setLoading] = useState(false);
  const [relatorios, setRelatorios] = useState([]);
  const { authUser } = useSelector(({ auth }) => auth);

  const getRelatorios = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/relatorios/${id}`);
      setRelatorios(data?.relatorios || []);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onPrintRelatorio = (id) => {
    window.open(
      `https://sistemas.eprom2.com.br/eprom_web_gr/relatorio_chamada/index.php?&p_id1=${btoa(
        authUser?.storedcnpj
      )}&p_id2=${btoa(id)}`
    );
  };

  useEffect(() => {
    getRelatorios();
  }, [id]);

  const RenderItems = () =>
    relatorios?.map((r, i) => (
      <Grid item xs={12} key={i?.id?.toString()}>
        {Boolean(i) && <Divider />}
        <div style={{ margin: '1em', display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1, marginRight: '1em' }}>
            <Typography variant="h6">{r?.descricao}</Typography>
            <Typography variant="body1">{r?.informacoes}</Typography>
          </div>
          <IconButton
            size="small"
            color="primary"
            onClick={() => onPrintRelatorio(r?.id)}
          >
            <Print />
          </IconButton>
        </div>
      </Grid>
    ));

  if (loading) return <Loading />;

  return (
    <div className="app-wrapper">
      <HeaderPage title="Relatórios" />
      <Card className="shadow">
        <Grid container>
          <RenderItems />
        </Grid>
      </Card>
    </div>
  );
};

export default Relatorios;
