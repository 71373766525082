export const Types = {
  GET_DOCUMENTO: 'GET_DOCUMENTO',
  GET_DOCUMENTO_SUCCESS: 'GET_DOCUMENTO_SUCCESS',
  GET_DOCUMENTO_ERROR: 'GET_DOCUMENTO_ERROR',
  GET_DOCUMENTO_RESET: 'GET_DOCUMENTO_RESET',

  DELETE_DOCUMENTO: 'DELETE_DOCUMENTO',
  DELETE_DOCUMENTO_SUCCESS: 'DELETE_DOCUMENTO_SUCCESS',
  DELETE_DOCUMENTO_ERROR: 'DELETE_DOCUMENTO_ERROR',
  DELETE_DOCUMENTO_RESET: 'DELETE_DOCUMENTO_RESET',

  GET_ITENS: 'GET_ITENS',
  GET_ITENS_SUCCESS: 'GET_ITENS_SUCCESS',
  GET_ITENS_ERROR: 'GET_ITENS_ERROR',
  GET_ITENS_RESET: 'GET_ITENS_RESET',

  PUT_DOCGERAR: 'PUT_DOCGERAR',
  PUT_DOCGERAR_SUCCESS: 'PUT_DOCGERAR_SUCCESS',
  PUT_DOCGERAR_ERROR: 'PUT_DOCGERAR_ERROR',
  PUT_DOCGERAR_RESET: 'PUT_DOCGERAR_RESET',

  POST_DOCMOVIMENTAR: 'POST_DOCMOVIMENTAR',
  POST_DOCMOVIMENTAR_SUCCESS: 'POST_DOCMOVIMENTAR_SUCCESS',
  POST_DOCMOVIMENTAR_ERROR: 'POST_DOCMOVIMENTAR_ERROR',
  POST_DOCMOVIMENTAR_RESET: 'POST_DOCMOVIMENTAR_RESET',

  POST_CAPA: 'POST_CAPA',
  POST_CAPA_SUCCESS: 'POST_CAPA_SUCCESS',
  POST_CAPA_ERROR: 'POST_CAPA_ERROR',
  POST_CAPA_RESET: 'POST_CAPA_RESET',
  POST_CAPA_RESET_ID: 'POST_CAPA_RESET_ID',

  POST_REPOSICAO: 'POST_REPOSICAO',
  POST_REPOSICAO_SUCCESS: 'POST_REPOSICAO_SUCCESS',
  POST_REPOSICAO_ERROR: 'POST_REPOSICAO_ERROR',
  POST_REPOSICAO_RESET: 'POST_REPOSICAO_RESET',

  POST_CUSTO: 'POST_CUSTO',
  POST_CUSTO_SUCCESS: 'POST_CUSTO_SUCCESS',
  POST_CUSTO_ERROR: 'POST_CUSTO_ERROR',
  POST_CUSTO_RESET: 'POST_CUSTO_RESET',

  POST_REGISTRO: 'POST_REGISTRO',
  POST_REGISTRO_SUCCESS: 'POST_REGISTRO_SUCCESS',
  POST_REGISTRO_ERROR: 'POST_REGISTRO_ERROR',
  POST_REGISTRO_RESET: 'POST_REGISTRO_RESET',

  POST_APROVACAO: 'POST_APROVACAO',
  POST_APROVACAO_SUCCESS: 'POST_APROVACAO_SUCCESS',
  POST_APROVACAO_ERROR: 'POST_APROVACAO_ERROR',
  POST_APROVACAO_RESET: 'POST_APROVACAO_RESET',

  POST_STATUS: 'POST_STATUS',
  POST_STATUS_SUCCESS: 'POST_STATUS_SUCCESS',
  POST_STATUS_ERROR: 'POST_STATUS_ERROR',
  POST_STATUS_RESET: 'POST_STATUS_RESET',

  GET_PRODUTO_DOCUMENTO: 'GET_PRODUTO_DOCUMENTO',
  GET_PRODUTO_DOCUMENTO_SUCCESS: 'GET_PRODUTO_DOCUMENTO_SUCCESS',
  GET_PRODUTO_DOCUMENTO_ERROR: 'GET_PRODUTO_DOCUMENTO_ERROR',
  GET_PRODUTO_DOCUMENTO_RESET: 'GET_PRODUTO_DOCUMENTO_RESET',

  GET_FIELDS_ITENS_DOCUMENTO: 'GET_FIELDS_ITENS_DOCUMENTO',
  GET_FIELDS_ITENS_DOCUMENTO_SUCCESS: 'GET_FIELDS_ITENS_DOCUMENTO_SUCCESS',
  GET_FIELDS_ITENS_DOCUMENTO_ERROR: 'GET_FIELDS_ITENS_DOCUMENTO_ERROR',

  GET_CUSTO_ITENS_DOCUMENTO: 'GET_CUSTO_ITENS_DOCUMENTO',
  GET_CUSTO_ITENS_DOCUMENTO_SUCCESS: 'GET_CUSTO_ITENS_DOCUMENTO_SUCCESS',
  GET_CUSTO_ITENS_DOCUMENTO_ERROR: 'GET_CUSTO_ITENS_DOCUMENTO_ERROR',
  GET_CUSTO_ITENS_DOCUMENTO_RESET: 'GET_CUSTO_ITENS_DOCUMENTO_RESET',

  GET_PLANOCONTA_DOCUMENTO: 'GET_PLANOCONTA_DOCUMENTO',
  GET_PLANOCONTA_DOCUMENTO_SUCCESS: 'GET_PLANOCONTA_DOCUMENTO_SUCCESS',
  GET_PLANOCONTA_DOCUMENTO_ERROR: 'GET_PLANOCONTA_DOCUMENTO_ERROR',

  GET_DOC_ITEM: 'GET_DOC_ITEM',
  GET_DOC_ITEM_SUCCESS: 'GET_DOC_ITEM_SUCCESS',
  GET_DOC_ITEM_ERROR: 'GET_DOC_ITEM_ERROR',

  POST_DOC_ITEM: 'POST_DOC_ITEM',
  POST_DOC_ITEM_SUCCESS: 'POST_DOC_ITEM_SUCCESS',
  POST_DOC_ITEM_ERROR: 'POST_DOC_ITEM_ERROR',
  POST_DOC_ITEM_RESET: 'POST_DOC_ITEM_RESET',

  DELETE_DOC_ITEM: 'DELETE_DOC_ITEM',
  DELETE_DOC_ITEM_SUCCESS: 'DELETE_DOC_ITEM_SUCCESS',
  DELETE_DOC_ITEM_ERROR: 'DELETE_DOC_ITEM_ERROR',
  DELETE_DOC_ITEM_RESET: 'DELETE_DOC_ITEM_RESET',

  PUT_DOC_ITEM: 'PUT_DOC_ITEM',
  PUT_DOC_ITEM_SUCCESS: 'PUT_DOC_ITEM_SUCCESS',
  PUT_DOC_ITEM_ERROR: 'PUT_DOC_ITEM_ERROR',
  PUT_DOC_ITEM_RESET: 'PUT_DOC_ITEM_RESET',

  ATT_PRECO_ITEM: 'ATT_PRECO_ITEM',
  ATT_PRECO_ITEM_SUCCESS: 'ATT_PRECO_ITEM_SUCCESS',
  ATT_PRECO_ITEM_ERROR: 'ATT_PRECO_ITEM_ERROR',
  ATT_PRECO_ITEM_RESET: 'ATT_PRECO_ITEM_RESET',

  GET_MOVIMENTACAO: 'GET_MOVIMENTACAO',
  GET_MOVIMENTACAO_SUCCESS: 'GET_MOVIMENTACAO_SUCCESS',
  GET_MOVIMENTACAO_ERROR: 'GET_MOVIMENTACAO_ERROR',

  POST_ITEM_OUTROS: 'POST_ITEM_OUTROS',
  POST_ITEM_OUTROS_SUCCESS: 'POST_ITEM_OUTROS_SUCCESS',
  POST_ITEM_OUTROS_ERROR: 'POST_ITEM_OUTROS_ERROR',
  POST_ITEM_OUTROS_RESET: 'POST_ITEM_OUTROS_RESET',

  POST_CFO: 'POST_CFO',
  POST_CFO_SUCCESS: 'POST_CFO_SUCCESS',
  POST_CFO_ERROR: 'POST_CFO_ERROR',
  POST_CFO_RESET: 'POST_CFO_RESET',

  POST_TRIBUTO: 'POST_TRIBUTO',
  POST_TRIBUTO_SUCCESS: 'POST_TRIBUTO_SUCCESS',
  POST_TRIBUTO_ERROR: 'POST_TRIBUTO_ERROR',
  POST_TRIBUTO_RESET: 'POST_TRIBUTO_RESET',

  POST_XML: 'POST_XML',
  POST_XML_SUCCESS: 'POST_XML_SUCCESS',
  POST_XML_ERROR: 'POST_XML_ERROR',
  POST_XML_RESET: 'POST_XML_RESET',

  EXPORT_XML: 'EXPORT_XML',
  EXPORT_XML_SUCCESS: 'EXPORT_XML_SUCCESS',
  EXPORT_XML_RESET: 'EXPORT_XML_RESET',

  POST_FINANCEIRO: 'POST_FINANCEIRO',
  POST_FINANCEIRO_SUCCESS: 'POST_FINANCEIRO_SUCCESS',
  POST_FINANCEIRO_ERROR: 'POST_FINANCEIRO_ERROR',
  POST_FINANCEIRO_RESET: 'POST_FINANCEIRO_RESET',

  POST_PARCELA: 'POST_PARCELA',
  POST_PARCELA_SUCCESS: 'POST_PARCELA_SUCCESS',
  POST_PARCELA_ERROR: 'POST_PARCELA_ERROR',
  POST_PARCELA_RESET: 'POST_PARCELA_RESET',

  PUT_PARCELA: 'PUT_PARCELA',
  PUT_PARCELA_SUCCESS: 'PUT_PARCELA_SUCCESS',
  PUT_PARCELA_ERROR: 'PUT_PARCELA_ERROR',
  PUT_PARCELA_RESET: 'PUT_PARCELA_RESET',

  DELETE_PARCELA: 'DELETE_PARCELA',
  DELETE_PARCELA_SUCCESS: 'DELETE_PARCELA_SUCCESS',
  DELETE_PARCELA_ERROR: 'DELETE_PARCELA_ERROR',
  DELETE_PARCELA_RESET: 'DELETE_PARCELA_RESET',

  POST_ENTREGA: 'POST_ENTREGA',
  POST_ENTREGA_SUCCESS: 'POST_ENTREGA_SUCCESS',
  POST_ENTREGA_ERROR: 'POST_ENTREGA_ERROR',
  POST_ENTREGA_RESET: 'POST_ENTREGA_RESET',

  PUT_ENTREGA: 'PUT_ENTREGA',
  PUT_ENTREGA_SUCCESS: 'PUT_ENTREGA_SUCCESS',
  PUT_ENTREGA_ERROR: 'PUT_ENTREGA_ERROR',
  PUT_ENTREGA_RESET: 'PUT_ENTREGA_RESET',

  DELETE_ENTREGA: 'DELETE_ENTREGA',
  DELETE_ENTREGA_SUCCESS: 'DELETE_ENTREGA_SUCCESS',
  DELETE_ENTREGA_ERROR: 'DELETE_ENTREGA_ERROR',
  DELETE_ENTREGA_RESET: 'DELETE_ENTREGA_RESET',

  IMPRIMIR_DOCUMENTO: 'IMPRIMIR_DOCUMENTO',
  IMPRIMIR_DOCUMENTO_SUCCESS: 'IMPRIMIR_DOCUMENTO_SUCCESS',
  IMPRIMIR_DOCUMENTO_ERROR: 'IMPRIMIR_DOCUMENTO_ERROR',
  IMPRIMIR_DOCUMENTO_RESET: 'IMPRIMIR_DOCUMENTO_RESET',

  USER_PERFIL: 'USER_PERFIL',
  USER_PERFIL_SUCCESS: 'USER_PERFIL_SUCCESS',
  USER_PERFIL_ERROR: 'USER_PERFIL_ERROR',
  USER_PERFIL_RESET: 'USER_PERFIL_RESET',

  POST_LANCAMENTO: 'POST_LANCAMENTO',
  POST_LANCAMENTO_SUCCESS: 'POST_LANCAMENTO_SUCCESS',
  POST_LANCAMENTO_ERROR: 'POST_LANCAMENTO_ERROR',
  POST_LANCAMENTO_RESET: 'POST_LANCAMENTO_RESET',

  GET_QTD_MOV: 'GET_QTD_MOV',
  GET_QTD_MOV_SUCCESS: 'GET_QTD_MOV_SUCCESS',
  GET_QTD_MOV_ERROR: 'GET_QTD_MOV_ERROR',

  POST_EMISSAO: 'POST_EMISSAO',
  POST_EMISSAO_SUCCESS: 'POST_EMISSAO_SUCCESS',
  POST_EMISSAO_ERROR: 'POST_EMISSAO_ERROR',
  POST_EMISSAO_RESET: 'POST_EMISSAO_RESET',

  POST_MANIFESTACAO: 'POST_MANIFESTACAO',
  POST_MANIFESTACAO_SUCCESS: 'POST_MANIFESTACAO_SUCCESS',
  POST_MANIFESTACAO_ERROR: 'POST_MANIFESTACAO_ERROR',
  POST_MANIFESTACAO_RESET: 'POST_MANIFESTACAO_RESET',

  GET_QUALIDADE: 'GET_QUALIDADE',
  GET_QUALIDADE_SUCCESS: 'GET_QUALIDADE_SUCCESS',
  GET_QUALIDADE_ERROR: 'GET_QUALIDADE_ERROR',

  POST_RESPOSTA: 'POST_RESPOSTA',
  POST_RESPOSTA_SUCCESS: 'POST_RESPOSTA_SUCCESS',
  POST_RESPOSTA_ERROR: 'POST_RESPOSTA_ERROR',
  POST_RESPOSTA_RESET: 'POST_RESPOSTA_RESET',

  POST_CONSULTAS_NFE: 'POST_CONSULTAS_NFE',
  POST_CONSULTAS_NFE_SUCCESS: 'POST_CONSULTAS_NFE_SUCCESS',
  POST_CONSULTAS_NFE_ERROR: 'POST_CONSULTAS_NFE_ERROR',
  POST_CONSULTAS_NFE_RESET: 'POST_CONSULTAS_NFE_RESET',

  POST_CORRECAO: 'POST_CORRECAO',
  POST_CORRECAO_SUCCESS: 'POST_CORRECAO_SUCCESS',
  POST_CORRECAO_ERROR: 'POST_CORRECAO_ERROR',
  POST_CORRECAO_RESET: 'POST_CORRECAO_RESET',

  POST_INUTILIZACAO: 'POST_INUTILIZACAO',
  POST_INUTILIZACAO_SUCCESS: 'POST_INUTILIZACAO_SUCCESS',
  POST_INUTILIZACAO_ERROR: 'POST_INUTILIZACAO_ERROR',
  POST_INUTILIZACAO_RESET: 'POST_INUTILIZACAO_RESET',

  SEND_MAIL: 'SEND_MAIL',
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS',
  SEND_MAIL_ERROR: 'SEND_MAIL_ERROR',
  SEND_MAIL_RESET: 'SEND_MAIL_RESET',

  GET_INFO_TRIBUTO: 'GET_INFO_TRIBUTO',
  GET_INFO_TRIBUTO_SUCCESS: 'GET_INFO_TRIBUTO_SUCCESS',
  GET_INFO_TRIBUTO_ERROR: 'GET_INFO_TRIBUTO_ERROR',
  GET_INFO_TRIBUTO_RESET: 'GET_INFO_TRIBUTO_RESET',

  POST_FATURACAO: 'POST_FATURACAO',
  POST_FATURACAO_SUCCESS: 'POST_FATURACAO_SUCCESS',
  POST_FATURACAO_ERROR: 'POST_FATURACAO_ERROR',
  POST_FATURACAO_RESET: 'POST_FATURACAO_RESET',

  GET_DADOSADC: 'GET_DADOSADC',
  GET_DADOSADC_SUCCESS: 'GET_DADOSADC_SUCCESS',
  GET_DADOSADC_RESET: 'GET_DADOSADC_RESET',

  POST_NFE: 'POST_NFE',
  POST_NFE_SUCCESS: 'POST_NFE_SUCCESS',
  POST_NFE_RESET: 'POST_NFE_RESET',

  POST_ITEM_ORIGEM: 'POST_ITEM_ORIGEM',
  POST_ITEM_ORIGEM_SUCCESS: 'POST_ITEM_ORIGEM_SUCCESS',
  POST_ITEM_ORIGEM_RESET: 'POST_ITEM_ORIGEM_RESET',

  POST_BX_ORIGEM: 'POST_BX_ORIGEM',
  POST_BX_ORIGEM_SUCCESS: 'POST_BX_ORIGEM_SUCCESS',
  POST_BX_ORIGEM_RESET: 'POST_BX_ORIGEM_RESET',

  GET_CONFERENCIA: 'GET_CONFERENCIA',
  GET_CONFERENCIA_SUCCESS: 'GET_CONFERENCIA_SUCCESS',
  GET_CONFERENCIA_RESET: 'GET_CONFERENCIA_RESET',

  POST_STATUSANT: 'POST_STATUSANT',
  POST_STATUSANT_SUCCESS: 'POST_STATUSANT_SUCCESS',
  POST_STATUSANT_RESET: 'POST_STATUSANT_RESET',

  POST_EXPORTA_INUTILIZADAS: 'POST_EXPORTA_INUTILIZADAS',
  POST_EXPORTA_INUTILIZADAS_SUCCESS: 'POST_EXPORTA_INUTILIZADAS_SUCCESS',
  POST_EXPORTA_INUTILIZADAS_RESET: 'POST_EXPORTA_INUTILIZADAS_RESET',

  POST_CARREGAMENTO: 'POST_CARREGAMENTO',
  POST_CARREGAMENTO_SUCCESS: 'POST_CARREGAMENTO_SUCCESS',
  POST_CARREGAMENTO_RESET: 'POST_CARREGAMENTO_RESET',

  GET_DOCUMENTO_MIDIAS: 'GET_DOCUMENTO_MIDIAS',
  GET_DOCUMENTO_MIDIAS_SUCCESS: 'GET_DOCUMENTO_MIDIAS_SUCCESS',
  GET_DOCUMENTO_MIDIAS_RESET: 'GET_DOCUMENTO_MIDIAS_RESET',

  PUT_DOCUMENTO_MIDIAS: 'PUT_DOCUMENTO_MIDIAS',
  PUT_DOCUMENTO_MIDIAS_SUCCESS: 'PUT_DOCUMENTO_MIDIAS_SUCCESS',
  PUT_DOCUMENTO_MIDIAS_RESET: 'PUT_DOCUMENTO_MIDIAS_RESET',

  POST_DOCUMENTO_GERAR: 'POST_DOCUMENTO_GERAR',
  POST_DOCUMENTO_GERAR_SUCCESS: 'POST_DOCUMENTO_GERAR_SUCCESS',
  POST_DOCUMENTO_GERAR_RESET: 'POST_DOCUMENTO_GERAR_RESET',

  IMPRIMIR_DANFE_ETIQUETA: 'IMPRIMIR_DANFE_ETIQUETA',
  IMPRIMIR_DANFE_ETIQUETA_SUCCESS: 'IMPRIMIR_DANFE_ETIQUETA_SUCCESS',
  IMPRIMIR_DANFE_ETIQUETA_RESET: 'IMPRIMIR_DANFE_ETIQUETA_RESET',
};

const INIT_STATE = {
  loading: false,
  error: false,
  documento: null,

  deleteDocLoading: false,
  deleteDocSuccess: false,
  deleteDocError: false,

  itensLoading: false,
  itens: [],
  itensError: false,

  docgerarLoading: false,
  docgerar: null,
  docgerarError: false,

  docmovimentarLoading: false,
  docmovimentarSuccess: false,
  docmovimentarError: false,

  capaLoading: false,
  capaSuccess: false,
  capaError: false,
  idRegistred: null,

  reposLoading: false,
  reposSuccess: false,
  reposError: false,

  custoLoading: false,
  custoSuccess: false,
  custoError: false,

  registroLoading: false,
  registroSuccess: false,
  registroError: false,

  aprovLoading: false,
  aprovSuccess: false,
  aprovError: false,

  statusLoading: false,
  statusSuccess: false,
  statusError: false,

  prodDocLoading: false,
  prodDoc: [],
  prodDocError: false,

  itensFieldsLoading: false,
  itensFields: [],
  itensFieldsError: false,

  custoItensLoading: false,
  custoItens: [],
  custoItensError: false,

  planoContaLoading: false,
  planoConta: [],
  planoContaError: false,

  getDocItemLoading: false,
  docItem: null,
  getDocItemError: false,

  postDocItemLoading: false,
  postDocItemSuccess: false,
  postDocItemError: false,

  deleteDocItemLoading: false,
  deleteDocItemSuccess: false,
  deleteDocItemError: false,

  putDocItemLoading: false,
  putDocItemSuccess: false,
  putDocItemError: false,

  attPrecoItemLoading: false,
  attPrecoItemSuccess: false,
  attPrecoItemError: false,

  movimentacaoLoading: false,
  movimentacao: [],
  movimentacaoError: false,

  postOutrosLoading: false,
  postOutrosSuccess: false,
  postOutrosError: false,

  postCfoLoading: false,
  postCfoSuccess: false,
  postCfoError: false,

  postTributoLoading: false,
  postTributoSuccess: false,
  postTributoError: false,

  postXmlLoading: false,
  postXmlSuccess: false,
  postXmlError: false,

  exportXmlLoading: false,
  exportXmlSuccess: false,

  postFinancLoading: false,
  postFinancSuccess: false,
  postFinancError: false,

  postParcelaLoading: false,
  postParcelaSuccess: false,
  postParcelaError: false,

  putParcelaLoading: false,
  putParcelaSuccess: false,
  putParcelaError: false,

  deleteParcelaLoading: false,
  deleteParcelaSuccess: false,
  deleteParcelaError: false,

  postEntregaLoading: false,
  postEntregaSuccess: false,
  postEntregaError: false,

  putEntregaLoading: false,
  putEntregaSuccess: false,
  putEntregaError: false,

  deleteEntregaLoading: false,
  deleteEntregaSuccess: false,
  deleteEntregaError: false,

  imprimirDocLoading: false,
  imprimirData: null,
  imprimirDocError: false,

  userPerfilLoading: false,
  userPerfil: null,
  userPerfilError: false,

  postLancamentoLoading: false,
  postLancamentoSuccess: false,
  postLancamentoError: false,

  getQtdMovLoading: false,
  qtdMov: [],
  getQtdMovError: false,

  postEmissaoLoading: false,
  emissao: null,
  postEmissaoError: false,

  postManifestacaoLoading: false,
  postManifestacaoSuccess: false,
  postManifestacaoError: false,

  getQualidadeLoading: false,
  qualidade: [],
  getQualidadeError: false,

  postRespostaLoading: false,
  postRespostaSuccess: false,
  postRespostaError: false,

  postConsultasLoading: false,
  postConsultasSuccess: false,
  consultas: null,
  postConsultasError: false,

  postCorrecaoLoading: false,
  carta: null,
  postCorrecaoError: false,

  postInutilizacaoLoading: false,
  postInutilizacaoSuccess: false,
  postInutilizacaoError: false,

  sendMailLoading: false,
  sendMailSuccess: false,
  sendMailError: false,

  infoTributoLoading: false,
  infoTributo: null,
  infoTributoError: false,

  postFaturacaoLoading: false,
  postFaturacaoSuccess: false,
  postFaturacaoError: false,

  getDadosAdcLoading: false,
  dadosAdc: null,

  postNfeLoading: false,
  postNfePreview: null,

  postItemOrigemLoading: false,
  postItemOrigemSuccess: false,

  postBxOrigemLoading: false,
  postBxOrigemSuccess: false,

  getConferenciaLoading: false,
  getConferenciaSuccess: false,

  postStatusAntLoading: false,
  postStatusAntSuccess: false,

  postExportInutLoading: false,
  postExportInutSuccess: false,

  postCarregamentoLoading: false,
  postCarregamentoSuccess: false,

  midiaLoading: false,
  midia: [],

  putMidiaLoading: false,
  putMidiaSuccess: false,

  postDocumentoGerarLoading: false,
  postDocumentoGerarSuccess: false,

  imprimirDanfeEtiquetaLoading: false,
  imprimirDanfeEtiquetaSuccess: false,
};

export default function Documento(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_DOCUMENTO: {
      return {
        ...state,
        loading: true,
        error: false,
        documento: null,
      };
    }
    case Types.GET_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        documento: action.payload,
      };
    }
    case Types.GET_DOCUMENTO_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        documento: null,
      };
    }
    case Types.GET_DOCUMENTO_RESET: {
      return {
        ...state,
        loading: false,
        error: false,
        documento: null,
      };
    }

    case Types.DELETE_DOCUMENTO: {
      return {
        ...state,
        deleteDocLoading: true,
        deleteDocSuccess: false,
        deleteDocError: false,
      };
    }
    case Types.DELETE_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        deleteDocLoading: false,
        deleteDocSuccess: true,
        deleteDocError: false,
      };
    }
    case Types.DELETE_DOCUMENTO_ERROR: {
      return {
        ...state,
        deleteDocLoading: false,
        deleteDocSuccess: false,
        deleteDocError: true,
      };
    }
    case Types.DELETE_DOCUMENTO_RESET: {
      return {
        ...state,
        deleteDocLoading: false,
        deleteDocSuccess: false,
        deleteDocError: false,
      };
    }

    case Types.GET_ITENS: {
      return {
        ...state,
        itensLoading: true,
        itens: [],
        itensError: false,
      };
    }
    case Types.GET_ITENS_SUCCESS: {
      return {
        ...state,
        itensLoading: false,
        itens: action.payload,
        itensError: false,
      };
    }
    case Types.GET_ITENS_ERROR: {
      return {
        ...state,
        itensLoading: false,
        itens: [],
        itensError: true,
      };
    }
    case Types.GET_ITENS_RESET: {
      return {
        ...state,
        itensLoading: false,
        itens: [],
        itensError: false,
      };
    }

    case Types.POST_CAPA: {
      return {
        ...state,
        capaLoading: true,
        capaSuccess: false,
        capaError: false,
        idRegistred: null,
      };
    }
    case Types.POST_CAPA_SUCCESS: {
      return {
        ...state,
        capaLoading: false,
        capaSuccess: true,
        capaError: false,
        idRegistred: action.payload,
      };
    }
    case Types.POST_CAPA_ERROR: {
      return {
        ...state,
        capaLoading: false,
        capaSuccess: false,
        capaError: true,
        idRegistred: null,
      };
    }
    case Types.POST_CAPA_RESET: {
      return {
        ...state,
        capaLoading: false,
        capaSuccess: false,
        capaError: false,
      };
    }
    case Types.POST_CAPA_RESET_ID: {
      return {
        ...state,
        idRegistred: null,
      };
    }

    case Types.POST_REPOSICAO: {
      return {
        ...state,
        reposLoading: true,
        reposSuccess: false,
        reposError: false,
      };
    }
    case Types.POST_REPOSICAO_SUCCESS: {
      return {
        ...state,
        reposLoading: false,
        reposSuccess: true,
        reposError: false,
      };
    }
    case Types.POST_REPOSICAO_ERROR: {
      return {
        ...state,
        reposLoading: false,
        reposSuccess: false,
        reposError: true,
      };
    }
    case Types.POST_REPOSICAO_RESET: {
      return {
        ...state,
        reposLoading: false,
        reposSuccess: false,
        reposError: false,
      };
    }

    case Types.POST_APROVACAO: {
      return {
        ...state,
        aprovLoading: true,
        aprovSuccess: false,
        aprovError: false,
      };
    }
    case Types.POST_APROVACAO_SUCCESS: {
      return {
        ...state,
        aprovLoading: false,
        aprovSuccess: true,
        aprovError: false,
      };
    }
    case Types.POST_APROVACAO_ERROR: {
      return {
        ...state,
        aprovLoading: false,
        aprovSuccess: false,
        aprovError: true,
      };
    }
    case Types.POST_APROVACAO_RESET: {
      return {
        ...state,
        aprovLoading: false,
        aprovSuccess: false,
        aprovError: false,
      };
    }

    case Types.POST_CUSTO: {
      return {
        ...state,
        custoLoading: true,
        custoSuccess: false,
        custoError: false,
      };
    }
    case Types.POST_CUSTO_SUCCESS: {
      return {
        ...state,
        custoLoading: false,
        custoSuccess: true,
        custoError: false,
      };
    }
    case Types.POST_CUSTO_ERROR: {
      return {
        ...state,
        custoLoading: false,
        custoSuccess: false,
        custoError: true,
      };
    }
    case Types.POST_CUSTO_RESET: {
      return {
        ...state,
        custoLoading: false,
        custoSuccess: false,
        custoError: false,
      };
    }

    case Types.POST_REGISTRO: {
      return {
        ...state,
        registroLoading: true,
        registroSuccess: false,
        registroError: false,
      };
    }
    case Types.POST_REGISTRO_SUCCESS: {
      return {
        ...state,
        registroLoading: false,
        registroSuccess: true,
        registroError: false,
      };
    }
    case Types.POST_REGISTRO_ERROR: {
      return {
        ...state,
        registroLoading: false,
        registroSuccess: false,
        registroError: true,
      };
    }
    case Types.POST_REGISTRO_RESET: {
      return {
        ...state,
        registroLoading: false,
        registroSuccess: false,
        registroError: false,
      };
    }

    case Types.POST_STATUS: {
      return {
        ...state,
        statusLoading: true,
        statusSuccess: false,
        statusError: false,
      };
    }
    case Types.POST_STATUS_SUCCESS: {
      return {
        ...state,
        statusLoading: false,
        statusSuccess: true,
        statusError: false,
      };
    }
    case Types.POST_STATUS_ERROR: {
      return {
        ...state,
        statusLoading: false,
        statusSuccess: false,
        statusError: true,
      };
    }
    case Types.POST_STATUS_RESET: {
      return {
        ...state,
        statusLoading: false,
        statusSuccess: false,
        statusError: false,
      };
    }

    case Types.GET_PRODUTO_DOCUMENTO: {
      return {
        ...state,
        prodDocLoading: true,
        prodDoc: [],
        prodDocError: false,
      };
    }
    case Types.GET_PRODUTO_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        prodDocLoading: false,
        prodDoc: action.payload,
        prodDocError: false,
      };
    }
    case Types.GET_PRODUTO_DOCUMENTO_ERROR: {
      return {
        ...state,
        prodDocLoading: false,
        prodDoc: [],
        prodDocError: true,
      };
    }
    case Types.GET_PRODUTO_DOCUMENTO_RESET: {
      return {
        ...state,
        prodDocLoading: false,
        prodDoc: [],
        prodDocError: false,
      };
    }

    case Types.GET_FIELDS_ITENS_DOCUMENTO: {
      return {
        ...state,
        itensFieldsLoading: true,
        itensFields: [],
        itensFieldsError: false,
      };
    }
    case Types.GET_FIELDS_ITENS_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        itensFieldsLoading: false,
        itensFields: action.payload,
        itensFieldsError: false,
      };
    }
    case Types.GET_FIELDS_ITENS_DOCUMENTO_ERROR: {
      return {
        ...state,
        itensFieldsLoading: false,
        itensFields: [],
        itensFieldsError: true,
      };
    }

    case Types.GET_CUSTO_ITENS_DOCUMENTO: {
      return {
        ...state,
        custoItensLoading: true,
        custoItens: [],
        custoItensError: false,
      };
    }
    case Types.GET_CUSTO_ITENS_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        custoItensLoading: false,
        custoItens: action.payload,
        custoItensError: false,
      };
    }
    case Types.GET_CUSTO_ITENS_DOCUMENTO_ERROR: {
      return {
        ...state,
        custoItensLoading: false,
        custoItens: [],
        custoItensError: true,
      };
    }
    case Types.GET_CUSTO_ITENS_DOCUMENTO_RESET: {
      return {
        ...state,
        custoItensLoading: false,
        custoItens: [],
        custoItensError: false,
      };
    }

    case Types.GET_PLANOCONTA_DOCUMENTO: {
      return {
        ...state,
        planoContaLoading: true,
        planoConta: [],
        planoContaError: false,
      };
    }
    case Types.GET_PLANOCONTA_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        planoContaLoading: false,
        planoConta: action.payload,
        planoContaError: false,
      };
    }
    case Types.GET_PLANOCONTA_DOCUMENTO_ERROR: {
      return {
        ...state,
        planoContaLoading: false,
        planoConta: [],
        planoContaError: true,
      };
    }

    case Types.PUT_DOCGERAR: {
      return {
        ...state,
        docgerarLoading: true,
        docgerar: null,
        docgerarError: false,
      };
    }
    case Types.PUT_DOCGERAR_SUCCESS: {
      return {
        ...state,
        docgerarLoading: false,
        docgerar: action.payload,
        docgerarError: false,
      };
    }
    case Types.PUT_DOCGERAR_ERROR: {
      return {
        ...state,
        docgerarLoading: false,
        docgerar: null,
        docgerarError: true,
      };
    }
    case Types.PUT_DOCGERAR_RESET: {
      return {
        ...state,
        docgerarLoading: false,
        docgerar: null,
        docgerarError: false,
      };
    }

    case Types.POST_DOCMOVIMENTAR: {
      return {
        ...state,
        docmovimentarLoading: true,
        docmovimentarSuccess: false,
        docmovimentarError: false,
      };
    }
    case Types.POST_DOCMOVIMENTAR_SUCCESS: {
      return {
        ...state,
        docmovimentarLoading: false,
        docmovimentarSuccess: true,
        docmovimentarError: false,
      };
    }
    case Types.POST_DOCMOVIMENTAR_ERROR: {
      return {
        ...state,
        docmovimentarLoading: false,
        docmovimentarSuccess: false,
        docmovimentarError: true,
      };
    }
    case Types.POST_DOCMOVIMENTAR_RESET: {
      return {
        ...state,
        docmovimentarLoading: false,
        docmovimentarSuccess: false,
        docmovimentarError: false,
      };
    }

    case Types.GET_DOC_ITEM: {
      return {
        ...state,
        getDocItemLoading: true,
        docItem: null,
        getDocItemError: false,
      };
    }
    case Types.GET_DOC_ITEM_SUCCESS: {
      return {
        ...state,
        getDocItemLoading: false,
        docItem: action.payload,
        getDocItemError: false,
      };
    }
    case Types.GET_DOC_ITEM_ERROR: {
      return {
        ...state,
        getDocItemLoading: false,
        getDocItemError: null,
        getDocItemError: true,
      };
    }

    case Types.POST_DOC_ITEM: {
      return {
        ...state,
        postDocItemLoading: true,
        postDocItemSuccess: false,
        postDocItemError: false,
      };
    }
    case Types.POST_DOC_ITEM_SUCCESS: {
      return {
        ...state,
        postDocItemLoading: false,
        postDocItemSuccess: true,
        postDocItemError: false,
      };
    }
    case Types.POST_DOC_ITEM_ERROR: {
      return {
        ...state,
        postDocItemLoading: false,
        postDocItemSuccess: false,
        postDocItemError: true,
      };
    }
    case Types.POST_DOC_ITEM_RESET: {
      return {
        ...state,
        postDocItemLoading: false,
        postDocItemSuccess: false,
        postDocItemError: false,
      };
    }

    case Types.DELETE_DOC_ITEM: {
      return {
        ...state,
        deleteDocItemLoading: true,
        deleteDocItemSuccess: false,
        deleteDocItemError: false,
      };
    }
    case Types.DELETE_DOC_ITEM_SUCCESS: {
      return {
        ...state,
        deleteDocItemLoading: false,
        deleteDocItemSuccess: true,
        deleteDocItemError: false,
      };
    }
    case Types.DELETE_DOC_ITEM_ERROR: {
      return {
        ...state,
        deleteDocItemLoading: false,
        deleteDocItemSuccess: false,
        deleteDocItemError: true,
      };
    }
    case Types.DELETE_DOC_ITEM_RESET: {
      return {
        ...state,
        deleteDocItemLoading: false,
        deleteDocItemSuccess: false,
        deleteDocItemError: false,
      };
    }

    case Types.PUT_DOC_ITEM: {
      return {
        ...state,
        putDocItemLoading: true,
        putDocItemSuccess: false,
        putDocItemError: false,
      };
    }
    case Types.PUT_DOC_ITEM_SUCCESS: {
      return {
        ...state,
        putDocItemLoading: false,
        putDocItemSuccess: true,
        putDocItemError: false,
      };
    }
    case Types.PUT_DOC_ITEM_ERROR: {
      return {
        ...state,
        putDocItemLoading: false,
        putDocItemSuccess: false,
        putDocItemError: true,
      };
    }
    case Types.PUT_DOC_ITEM_RESET: {
      return {
        ...state,
        putDocItemLoading: false,
        putDocItemSuccess: false,
        putDocItemError: false,
      };
    }

    case Types.ATT_PRECO_ITEM: {
      return {
        ...state,
        attPrecoItemLoading: true,
        attPrecoItemSuccess: false,
        attPrecoItemError: false,
      };
    }
    case Types.ATT_PRECO_ITEM_SUCCESS: {
      return {
        ...state,
        attPrecoItemLoading: false,
        attPrecoItemSuccess: true,
        attPrecoItemError: false,
      };
    }
    case Types.ATT_PRECO_ITEM_ERROR: {
      return {
        ...state,
        attPrecoItemLoading: false,
        attPrecoItemSuccess: false,
        attPrecoItemError: true,
      };
    }
    case Types.ATT_PRECO_ITEM_RESET: {
      return {
        ...state,
        attPrecoItemLoading: false,
        attPrecoItemSuccess: false,
        attPrecoItemError: false,
      };
    }

    case Types.GET_MOVIMENTACAO: {
      return {
        ...state,
        movimentacaoLoading: true,
        movimentacao: [],
        movimentacaoError: false,
      };
    }
    case Types.GET_MOVIMENTACAO_SUCCESS: {
      return {
        ...state,
        movimentacaoLoading: false,
        movimentacao: action.payload,
        movimentacaoError: false,
      };
    }
    case Types.GET_MOVIMENTACAO_ERROR: {
      return {
        ...state,
        movimentacaoLoading: false,
        movimentacao: [],
        movimentacaoError: true,
      };
    }
    case Types.POST_ITEM_OUTROS: {
      return {
        ...state,
        postOutrosLoading: true,
        postOutrosSuccess: false,
        postOutrosError: false,
      };
    }
    case Types.POST_ITEM_OUTROS_SUCCESS: {
      return {
        ...state,
        postOutrosLoading: false,
        postOutrosSuccess: true,
        postOutrosError: false,
      };
    }
    case Types.POST_ITEM_OUTROS_ERROR: {
      return {
        ...state,
        postOutrosLoading: false,
        postOutrosSuccess: false,
        postOutrosError: true,
      };
    }
    case Types.POST_ITEM_OUTROS_RESET: {
      return {
        ...state,
        postOutrosLoading: false,
        postOutrosSuccess: false,
        postOutrosError: false,
      };
    }

    case Types.POST_CFO: {
      return {
        ...state,
        postCfoLoading: true,
        postCfoSuccess: false,
        postCfoError: false,
      };
    }
    case Types.POST_CFO_SUCCESS: {
      return {
        ...state,
        postCfoLoading: false,
        postCfoSuccess: true,
        postCfoError: false,
      };
    }
    case Types.POST_CFO_ERROR: {
      return {
        ...state,
        postCfoLoading: false,
        postCfoSuccess: false,
        postCfoError: true,
      };
    }
    case Types.POST_CFO_RESET: {
      return {
        ...state,
        postCfoLoading: false,
        postCfoSuccess: false,
        postCfoError: false,
      };
    }

    case Types.POST_TRIBUTO: {
      return {
        ...state,
        postTributoLoading: true,
        postTributoSuccess: false,
        postTriibutoError: false,
      };
    }
    case Types.POST_TRIBUTO_SUCCESS: {
      return {
        ...state,
        postTributoLoading: false,
        postTributoSuccess: true,
        postTributoError: false,
      };
    }
    case Types.POST_TRIBUTO_ERROR: {
      return {
        ...state,
        postTributoLoading: false,
        postTributoSuccess: false,
        postTributoError: true,
      };
    }
    case Types.POST_TRIBUTO_RESET: {
      return {
        ...state,
        postTributoLoading: false,
        postTributoSuccess: false,
        postTributoError: false,
      };
    }

    case Types.POST_XML: {
      return {
        ...state,
        postXmlLoading: true,
        postXmlSuccess: false,
        postXmlError: false,
      };
    }
    case Types.POST_XML_SUCCESS: {
      return {
        ...state,
        postXmlLoading: false,
        postXmlSuccess: true,
        postXmlError: false,
      };
    }
    case Types.POST_XML_ERROR: {
      return {
        ...state,
        postXmlLoading: false,
        postXmlSuccess: false,
        postXmlError: true,
      };
    }
    case Types.POST_XML_RESET: {
      return {
        ...state,
        postXmlLoading: false,
        postXmlSuccess: false,
        postXmlError: false,
      };
    }

    case Types.EXPORT_XML: {
      return {
        ...state,
        exportXmlLoading: true,
        exportXmlSuccess: false,
      };
    }
    case Types.EXPORT_XML_SUCCESS: {
      return {
        ...state,
        exportXmlLoading: false,
        exportXmlSuccess: true,
      };
    }
    case Types.EXPORT_XML_RESET: {
      return {
        ...state,
        exportXmlLoading: false,
        exportXmlSuccess: false,
      };
    }

    case Types.POST_FINANCEIRO: {
      return {
        ...state,
        postFinancLoading: true,
        postFinancSuccess: false,
        postFinancError: false,
      };
    }
    case Types.POST_FINANCEIRO_SUCCESS: {
      return {
        ...state,
        postFinancLoading: false,
        postFinancSuccess: true,
        postFinancError: false,
      };
    }
    case Types.POST_FINANCEIRO_ERROR: {
      return {
        ...state,
        postFinancLoading: false,
        postFinancSuccess: false,
        postFinancError: true,
      };
    }
    case Types.POST_FINANCEIRO_RESET: {
      return {
        ...state,
        postFinancLoading: false,
        postFinancSuccess: false,
        postFinancError: false,
      };
    }

    case Types.POST_PARCELA: {
      return {
        ...state,
        postParcelaLoading: true,
        postParcelaSuccess: false,
        postParcelaError: false,
      };
    }
    case Types.POST_PARCELA_SUCCESS: {
      return {
        ...state,
        postParcelaLoading: false,
        postParcelaSuccess: true,
        postParcelaError: false,
      };
    }
    case Types.POST_PARCELA_ERROR: {
      return {
        ...state,
        postParcelaLoading: false,
        postParcelaSuccess: false,
        postParcelaError: true,
      };
    }
    case Types.POST_PARCELA_RESET: {
      return {
        ...state,
        postParcelaLoading: false,
        postParcelaSuccess: false,
        postParcelaError: false,
      };
    }

    case Types.PUT_PARCELA: {
      return {
        ...state,
        putParcelaLoading: true,
        putParcelaSuccess: false,
        putParcelaError: false,
      };
    }
    case Types.PUT_PARCELA_SUCCESS: {
      return {
        ...state,
        putParcelaLoading: false,
        putParcelaSuccess: true,
        putParcelaError: false,
      };
    }
    case Types.PUT_PARCELA_ERROR: {
      return {
        ...state,
        putParcelaLoading: false,
        putParcelaSuccess: false,
        putParcelaError: true,
      };
    }
    case Types.PUT_PARCELA_RESET: {
      return {
        ...state,
        putParcelaLoading: false,
        putParcelaSuccess: false,
        putParcelaError: false,
      };
    }

    case Types.DELETE_PARCELA: {
      return {
        ...state,
        deleteParcelaLoading: true,
        deleteParcelaSuccess: false,
        deleteParcelaError: false,
      };
    }
    case Types.DELETE_PARCELA_SUCCESS: {
      return {
        ...state,
        deleteParcelaLoading: false,
        deleteParcelaSuccess: true,
        deleteParcelaError: false,
      };
    }
    case Types.DELETE_PARCELA_ERROR: {
      return {
        ...state,
        deleteParcelaLoading: false,
        deleteParcelaSuccess: false,
        deleteParcelaError: true,
      };
    }
    case Types.DELETE_PARCELA_RESET: {
      return {
        ...state,
        deleteParcelaLoading: false,
        deleteParcelaSuccess: false,
        deleteParcelaError: false,
      };
    }

    case Types.POST_ENTREGA: {
      return {
        ...state,
        postEntregaLoading: true,
        postEntregaSuccess: false,
        postEntregaError: false,
      };
    }
    case Types.POST_ENTREGA_SUCCESS: {
      return {
        ...state,
        postEntregaLoading: false,
        postEntregaSuccess: true,
        postEntregaError: false,
      };
    }
    case Types.POST_ENTREGA_ERROR: {
      return {
        ...state,
        postEntregaLoading: false,
        postEntregaSuccess: false,
        postEntregaError: true,
      };
    }
    case Types.POST_ENTREGA_RESET: {
      return {
        ...state,
        postEntregaLoading: false,
        postEntregaSuccess: false,
        postEntregaError: false,
      };
    }

    case Types.PUT_ENTREGA: {
      return {
        ...state,
        putEntregaLoading: true,
        putEntregaSuccess: false,
        putEntregaError: false,
      };
    }
    case Types.PUT_ENTREGA_SUCCESS: {
      return {
        ...state,
        putEntregaLoading: false,
        putEntregaSuccess: true,
        putEntregaError: false,
      };
    }
    case Types.PUT_ENTREGA_ERROR: {
      return {
        ...state,
        putEntregaLoading: false,
        putEntregaSuccess: false,
        putEntregaError: true,
      };
    }
    case Types.PUT_ENTREGA_RESET: {
      return {
        ...state,
        putEntregaLoading: false,
        putEntregaSuccess: false,
        putEntregaError: false,
      };
    }

    case Types.DELETE_ENTREGA: {
      return {
        ...state,
        deleteEntregaLoading: true,
        deleteEntregaSuccess: false,
        deleteEntregaError: false,
      };
    }
    case Types.DELETE_ENTREGA_SUCCESS: {
      return {
        ...state,
        deleteEntregaLoading: false,
        deleteEntregaSuccess: true,
        deleteEntregaError: false,
      };
    }
    case Types.DELETE_ENTREGA_ERROR: {
      return {
        ...state,
        deleteEntregaLoading: false,
        deleteEntregaSuccess: false,
        deleteEntregaError: true,
      };
    }
    case Types.DELETE_ENTREGA_RESET: {
      return {
        ...state,
        deleteEntregaLoading: false,
        deleteEntregaSuccess: false,
        deleteEntregaError: false,
      };
    }

    case Types.IMPRIMIR_DOCUMENTO: {
      return {
        ...state,
        imprimirDocLoading: true,
        imprimirData: null,
        imprimirDocError: false,
      };
    }
    case Types.IMPRIMIR_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        imprimirDocLoading: false,
        imprimirData: action.payload,
        imprimirDocError: false,
      };
    }
    case Types.IMPRIMIR_DOCUMENTO_ERROR: {
      return {
        ...state,
        imprimirDocLoading: false,
        imprimirData: null,
        imprimirDocError: true,
      };
    }
    case Types.IMPRIMIR_DOCUMENTO_RESET: {
      return {
        ...state,
        imprimirDocLoading: false,
        imprimirData: null,
        imprimirDocError: false,
      };
    }

    case Types.USER_PERFIL: {
      return {
        ...state,
        userPerfilLoading: true,
        userPerfil: null,
        userPerfilError: false,
      };
    }
    case Types.USER_PERFIL_SUCCESS: {
      return {
        ...state,
        userPerfilLoading: false,
        userPerfil: action.payload,
        userPerfilError: false,
      };
    }
    case Types.USER_PERFIL_ERROR: {
      return {
        ...state,
        userPerfilLoading: false,
        userPerfil: null,
        userPerfilError: true,
      };
    }
    case Types.USER_PERFIL_RESET: {
      return {
        ...state,
        userPerfilLoading: false,
        userPerfil: null,
        userPerfilError: false,
      };
    }

    case Types.POST_LANCAMENTO: {
      return {
        ...state,
        postLancamentoLoading: true,
        postLancamentoSuccess: false,
        postLancamentoError: false,
      };
    }
    case Types.POST_LANCAMENTO_SUCCESS: {
      return {
        ...state,
        postLancamentoLoading: false,
        postLancamentoSuccess: true,
        postLancamentoError: false,
      };
    }
    case Types.POST_LANCAMENTO_ERROR: {
      return {
        ...state,
        postLancamentoLoading: false,
        postLancamentoSuccess: false,
        postLancamentoError: true,
      };
    }
    case Types.POST_LANCAMENTO_RESET: {
      return {
        ...state,
        postLancamentoLoading: false,
        postLancamentoSuccess: false,
        postLancamentoError: false,
      };
    }

    case Types.GET_QTD_MOV:
      return {
        ...state,
        getQtdMovLoading: true,
        qtdMov: [],
        getQtdMovError: false,
      };
    case Types.GET_QTD_MOV_SUCCESS:
      return {
        ...state,
        getQtdMovLoading: false,
        qtdMov: action.payload,
        getQtdMovError: false,
      };
    case Types.GET_QTD_MOV_ERROR:
      return {
        ...state,
        getQtdMovLoading: false,
        qtdMov: [],
        getQtdMovError: true,
      };

    case Types.POST_EMISSAO: {
      return {
        ...state,
        postEmissaoLoading: true,
        emissao: null,
        postEmissaoError: false,
      };
    }
    case Types.POST_EMISSAO_SUCCESS: {
      return {
        ...state,
        postEmissaoLoading: false,
        emissao: action.payload,
        postEmissaoError: false,
      };
    }
    case Types.POST_EMISSAO_ERROR: {
      return {
        ...state,
        postEmissaoLoading: false,
        emissao: null,
        postEmissaoError: true,
      };
    }
    case Types.POST_EMISSAO_RESET: {
      return {
        ...state,
        postEmissaoLoading: false,
        emissao: null,
        postEmissaoError: false,
      };
    }

    case Types.POST_MANIFESTACAO: {
      return {
        ...state,
        postManifestacaoLoading: true,
        postManifestacaoSuccess: false,
        postManifestacaoError: false,
      };
    }
    case Types.POST_MANIFESTACAO_SUCCESS: {
      return {
        ...state,
        postManifestacaoLoading: false,
        postManifestacaoSuccess: true,
        postManifestacaoError: false,
      };
    }
    case Types.POST_MANIFESTACAO_ERROR: {
      return {
        ...state,
        postManifestacaoLoading: false,
        postManifestacaoSuccess: false,
        postManifestacaoError: true,
      };
    }
    case Types.POST_MANIFESTACAO_RESET: {
      return {
        ...state,
        postManifestacaoLoading: false,
        postManifestacaoSuccess: false,
        postManifestacaoError: false,
      };
    }

    case Types.GET_QUALIDADE:
      return {
        ...state,
        getQualidadeLoading: true,
        qualidade: [],
        getQualidadeError: false,
      };
    case Types.GET_QUALIDADE_SUCCESS:
      return {
        ...state,
        getQualidadeLoading: false,
        qualidade: action.payload,
        getQualidadeError: false,
      };
    case Types.GET_QUALIDADE_ERROR:
      return {
        ...state,
        getQualidadeLoading: false,
        qualidade: [],
        getQualidadeError: true,
      };

    case Types.POST_RESPOSTA: {
      return {
        ...state,
        postRespostaLoading: true,
        postRespostaSuccess: false,
        postRespostaError: false,
      };
    }
    case Types.POST_RESPOSTA_SUCCESS: {
      return {
        ...state,
        postRespostaLoading: false,
        postRespostaSuccess: true,
        postRespostaError: false,
      };
    }
    case Types.POST_RESPOSTA_ERROR: {
      return {
        ...state,
        postRespostaLoading: false,
        postRespostaSuccess: false,
        postRespostaError: true,
      };
    }
    case Types.POST_RESPOSTA_RESET: {
      return {
        ...state,
        postRespostaLoading: false,
        postRespostaSuccess: false,
        postRespostaError: false,
      };
    }

    case Types.POST_CONSULTAS_NFE: {
      return {
        ...state,
        postConsultasLoading: true,
        postConsultasSuccess: false,
        consultas: null,
        postConsultasError: false,
      };
    }
    case Types.POST_CONSULTAS_NFE_SUCCESS: {
      return {
        ...state,
        postConsultasLoading: false,
        postConsultasSuccess: true,
        consultas: action.payload,
        postConsultasError: false,
      };
    }
    case Types.POST_CONSULTAS_NFE_ERROR: {
      return {
        ...state,
        postConsultasLoading: false,
        postConsultasSuccess: false,
        consultas: null,
        postConsultasError: true,
      };
    }
    case Types.POST_CONSULTAS_NFE_RESET: {
      return {
        ...state,
        postConsultasLoading: false,
        postConsultasSuccess: false,
        consultas: null,
        postConsultasError: false,
      };
    }

    case Types.POST_CORRECAO: {
      return {
        ...state,
        postCorrecaoLoading: true,
        carta: null,
        postCorrecaoError: false,
      };
    }
    case Types.POST_CORRECAO_SUCCESS: {
      return {
        ...state,
        postCorrecaoLoading: false,
        carta: action.payload,
        postCorrecaoError: false,
      };
    }
    case Types.POST_CORRECAO_ERROR: {
      return {
        ...state,
        postCorrecaoLoading: false,
        carta: null,
        postCorrecaoError: true,
      };
    }
    case Types.POST_CORRECAO_RESET: {
      return {
        ...state,
        postCorrecaoLoading: false,
        carta: null,
        postCorrecaoError: false,
      };
    }

    case Types.POST_INUTILIZACAO: {
      return {
        ...state,
        postInutilizacaoLoading: true,
        postInutilizacaoSuccess: false,
        postInutilizacaoError: false,
      };
    }
    case Types.POST_INUTILIZACAO_SUCCESS: {
      return {
        ...state,
        postInutilizacaoLoading: false,
        postInutilizacaoSuccess: true,
        postInutilizacaoError: false,
      };
    }
    case Types.POST_INUTILIZACAO_ERROR: {
      return {
        ...state,
        postInutilizacaoLoading: false,
        postInutilizacaoSuccess: false,
        postInutilizacaoError: true,
      };
    }
    case Types.POST_INUTILIZACAO_RESET: {
      return {
        ...state,
        postInutilizacaoLoading: false,
        postInutilizacaoSuccess: false,
        postInutilizacaoError: false,
      };
    }

    case Types.SEND_MAIL: {
      return {
        ...state,
        sendMailLoading: true,
        sendMailSuccess: false,
        sendMailError: false,
      };
    }
    case Types.SEND_MAIL_SUCCESS: {
      return {
        ...state,
        sendMailLoading: false,
        sendMailSuccess: true,
        sendMailError: false,
      };
    }
    case Types.SEND_MAIL_ERROR: {
      return {
        ...state,
        sendMailLoading: false,
        sendMailSuccess: false,
        sendMailError: true,
      };
    }
    case Types.SEND_MAIL_RESET: {
      return {
        ...state,
        sendMailLoading: false,
        sendMailSuccess: false,
        sendMailError: false,
      };
    }

    case Types.GET_INFO_TRIBUTO: {
      return {
        ...state,
        infoTributoLoading: true,
        infoTributo: null,
        infoTributoError: false,
      };
    }
    case Types.GET_INFO_TRIBUTO_SUCCESS: {
      return {
        ...state,
        infoTributoLoading: false,
        infoTributo: action.payload,
        infoTributoError: false,
      };
    }
    case Types.GET_INFO_TRIBUTO_ERROR: {
      return {
        ...state,
        infoTributoLoading: false,
        infoTributo: null,
        infoTributoError: true,
      };
    }
    case Types.GET_INFO_TRIBUTO_RESET: {
      return {
        ...state,
        infoTributoLoading: false,
        infoTributo: null,
        infoTributoError: false,
      };
    }

    case Types.POST_FATURACAO: {
      return {
        ...state,
        postFaturacaoLoading: true,
        postFaturacaoSuccess: false,
        postFaturacaoError: false,
      };
    }
    case Types.POST_FATURACAO_SUCCESS: {
      return {
        ...state,
        postFaturacaoLoading: false,
        postFaturacaoSuccess: true,
        postFaturacaoError: false,
      };
    }
    case Types.POST_FATURACAO_ERROR: {
      return {
        ...state,
        postFaturacaoLoading: false,
        postFaturacaoSuccess: false,
        postFaturacaoError: true,
      };
    }
    case Types.POST_FATURACAO_RESET: {
      return {
        ...state,
        postFaturacaoLoading: false,
        postFaturacaoSuccess: false,
        postFaturacaoError: false,
      };
    }

    case Types.GET_DADOSADC: {
      return {
        ...state,
        getDadosAdcLoading: true,
        dadosAdc: null,
      };
    }
    case Types.GET_DADOSADC_SUCCESS: {
      return {
        ...state,
        getDadosAdcLoading: false,
        dadosAdc: action.payload,
      };
    }
    case Types.GET_DADOSADC_RESET: {
      return {
        ...state,
        getDadosAdcLoading: false,
        dadosAdc: null,
      };
    }

    case Types.POST_NFE: {
      return {
        ...state,
        postNfeLoading: true,
        postNfePreview: null,
      };
    }
    case Types.POST_NFE_SUCCESS: {
      return {
        ...state,
        postNfeLoading: false,
        postNfePreview: action.payload,
      };
    }
    case Types.POST_NFE_RESET: {
      return {
        ...state,
        postNfeLoading: false,
        postNfePreview: null,
      };
    }

    case Types.POST_ITEM_ORIGEM: {
      return {
        ...state,
        postItemOrigemLoading: true,
        postItemOrigemSuccess: false,
      };
    }
    case Types.POST_ITEM_ORIGEM_SUCCESS: {
      return {
        ...state,
        postItemOrigemLoading: false,
        postItemOrigemSuccess: true,
      };
    }
    case Types.POST_ITEM_ORIGEM_RESET: {
      return {
        ...state,
        postItemOrigemLoading: false,
        postItemOrigemSuccess: false,
      };
    }

    case Types.POST_BX_ORIGEM: {
      return {
        ...state,
        postBxOrigemLoading: true,
        postBxOrigemSuccess: false,
      };
    }
    case Types.POST_BX_ORIGEM_SUCCESS: {
      return {
        ...state,
        postBxOrigemLoading: false,
        postBxOrigemSuccess: true,
      };
    }
    case Types.POST_BX_ORIGEM_RESET: {
      return {
        ...state,
        postBxOrigemLoading: false,
        postBxOrigemSuccess: false,
      };
    }

    case Types.GET_CONFERENCIA: {
      return {
        ...state,
        getConferenciaLoading: true,
        getConferenciaSuccess: false,
      };
    }
    case Types.GET_CONFERENCIA_SUCCESS: {
      return {
        ...state,
        getConferenciaLoading: false,
        getConferenciaSuccess: true,
      };
    }
    case Types.GET_CONFERENCIA_RESET: {
      return {
        ...state,
        getConferenciaLoading: false,
        getConferenciaSuccess: false,
      };
    }

    case Types.POST_STATUSANT: {
      return {
        ...state,
        postStatusAntLoading: true,
        postStatusAntSuccess: false,
      };
    }
    case Types.POST_STATUSANT_SUCCESS: {
      return {
        ...state,
        postStatusAntLoading: false,
        postStatusAntSuccess: true,
      };
    }
    case Types.POST_STATUSANT_RESET: {
      return {
        ...state,
        postStatusAntLoading: false,
        postStatusAntSuccess: false,
      };
    }

    case Types.POST_EXPORTA_INUTILIZADAS: {
      return {
        ...state,
        postExportInutLoading: true,
        postExportInutSuccess: false,
      };
    }
    case Types.POST_EXPORTA_INUTILIZADAS_SUCCESS: {
      return {
        ...state,
        postExportInutLoading: false,
        postExportInutSuccess: true,
      };
    }
    case Types.POST_EXPORTA_INUTILIZADAS_RESET: {
      return {
        ...state,
        postExportInutLoading: false,
        postExportInutSuccess: false,
      };
    }

    case Types.POST_CARREGAMENTO: {
      return {
        ...state,
        postCarregamentoLoading: true,
        postCarregamentoSuccess: false,
      };
    }
    case Types.POST_CARREGAMENTO_SUCCESS: {
      return {
        ...state,
        postCarregamentoLoading: false,
        postCarregamentoSuccess: true,
      };
    }
    case Types.POST_CARREGAMENTO_RESET: {
      return {
        ...state,
        postCarregamentoLoading: false,
        postCarregamentoSuccess: false,
      };
    }

    case Types.GET_DOCUMENTO_MIDIAS: {
      return {
        ...state,
        midiaLoading: true,
        midia: [],
      };
    }
    case Types.GET_DOCUMENTO_MIDIAS_SUCCESS: {
      return {
        ...state,
        midiaLoading: false,
        midia: action.payload,
      };
    }
    case Types.GET_DOCUMENTO_MIDIAS_RESET: {
      return {
        ...state,
        midiaLoading: false,
        midia: [],
      };
    }

    case Types.PUT_DOCUMENTO_MIDIAS: {
      return {
        ...state,
        putMidiaLoading: true,
        putMidiaSuccess: false,
      };
    }
    case Types.PUT_DOCUMENTO_MIDIAS_SUCCESS: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: true,
      };
    }
    case Types.PUT_DOCUMENTO_MIDIAS_RESET: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: false,
      };
    }

    case Types.POST_DOCUMENTO_GERAR: {
      return {
        ...state,
        postDocumentoGerarLoading: true,
        postDocumentoGerarSuccess: false,
      };
    }
    case Types.POST_DOCUMENTO_GERAR_SUCCESS: {
      return {
        ...state,
        postDocumentoGerarLoading: false,
        postDocumentoGerarSuccess: true,
      };
    }
    case Types.POST_DOCUMENTO_GERAR_RESET: {
      return {
        ...state,
        postDocumentoGerarLoading: false,
        postDocumentoGerarSuccess: false,
      };
    }

    case Types.IMPRIMIR_DANFE_ETIQUETA: {
      return {
        ...state,
        imprimirDanfeEtiquetaLoading: true,
        imprimirDanfeEtiquetaSuccess: false,
      };
    }
    case Types.IMPRIMIR_DANFE_ETIQUETA_SUCCESS: {
      return {
        ...state,
        imprimirDanfeEtiquetaLoading: false,
        imprimirDanfeEtiquetaSuccess: true,
      };
    }
    case Types.IMPRIMIR_DANFE_ETIQUETA_RESET: {
      return {
        ...state,
        imprimirDanfeEtiquetaLoading: false,
        imprimirDanfeEtiquetaSuccess: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getDocumento: (payload) => ({
    type: Types.GET_DOCUMENTO,
    payload,
  }),
  getDocumentoSuccess: (payload) => ({
    type: Types.GET_DOCUMENTO_SUCCESS,
    payload,
  }),
  getDocumentoError: () => ({
    type: Types.GET_DOCUMENTO_ERROR,
  }),
  getDocumentoReset: () => ({
    type: Types.GET_DOCUMENTO_RESET,
  }),

  deleteDocumento: (payload) => ({
    type: Types.DELETE_DOCUMENTO,
    payload,
  }),
  deleteDocumentoSuccess: () => ({
    type: Types.DELETE_DOCUMENTO_SUCCESS,
  }),
  deleteDocumentoError: () => ({
    type: Types.DELETE_DOCUMENTO_ERROR,
  }),
  deleteDocumentoReset: () => ({
    type: Types.DELETE_DOCUMENTO_RESET,
  }),

  getItens: (payload) => ({
    type: Types.GET_ITENS,
    payload,
  }),
  getItensSuccess: (payload) => ({
    type: Types.GET_ITENS_SUCCESS,
    payload,
  }),
  getItensError: () => ({
    type: Types.GET_ITENS_ERROR,
  }),
  getItensReset: () => ({
    type: Types.GET_ITENS_RESET,
  }),

  postCapa: (payload) => ({
    type: Types.POST_CAPA,
    payload,
  }),
  postCapaSuccess: (payload) => ({
    type: Types.POST_CAPA_SUCCESS,
    payload,
  }),
  postCapaError: () => ({
    type: Types.POST_CAPA_ERROR,
  }),
  postCapaReset: () => ({
    type: Types.POST_CAPA_RESET,
  }),
  postCapaResetId: () => ({
    type: Types.POST_CAPA_RESET_ID,
  }),

  postReposicao: (payload) => ({
    type: Types.POST_REPOSICAO,
    payload,
  }),
  postReposicaoSuccess: () => ({
    type: Types.POST_REPOSICAO_SUCCESS,
  }),
  postReposicaoError: () => ({
    type: Types.POST_REPOSICAO_ERROR,
  }),
  postReposicaoReset: () => ({
    type: Types.POST_REPOSICAO_RESET,
  }),

  postCusto: (payload) => ({
    type: Types.POST_CUSTO,
    payload,
  }),
  postCustoSuccess: () => ({
    type: Types.POST_CUSTO_SUCCESS,
  }),
  postCustoError: () => ({
    type: Types.POST_CUSTO_ERROR,
  }),
  postCustoReset: () => ({
    type: Types.POST_CUSTO_RESET,
  }),

  postRegistro: (payload) => ({
    type: Types.POST_REGISTRO,
    payload,
  }),
  postRegistroSuccess: () => ({
    type: Types.POST_REGISTRO_SUCCESS,
  }),
  postRegistroError: () => ({
    type: Types.POST_REGISTRO_ERROR,
  }),
  postRegistroReset: () => ({
    type: Types.POST_REGISTRO_RESET,
  }),

  postAprovacao: (payload) => ({
    type: Types.POST_APROVACAO,
    payload,
  }),
  postAprovacaoSuccess: () => ({
    type: Types.POST_APROVACAO_SUCCESS,
  }),
  postAprovacaoError: () => ({
    type: Types.POST_APROVACAO_ERROR,
  }),
  postAprovacaoReset: () => ({
    type: Types.POST_APROVACAO_RESET,
  }),

  postStatus: (payload) => ({
    type: Types.POST_STATUS,
    payload,
  }),
  postStatusSuccess: () => ({
    type: Types.POST_STATUS_SUCCESS,
  }),
  postStatusError: () => ({
    type: Types.POST_STATUS_ERROR,
  }),
  postStatusReset: () => ({
    type: Types.POST_STATUS_RESET,
  }),

  getProdDoc: (payload) => ({
    type: Types.GET_PRODUTO_DOCUMENTO,
    payload,
  }),
  getProdDocSuccess: (payload) => ({
    type: Types.GET_PRODUTO_DOCUMENTO_SUCCESS,
    payload,
  }),
  getProdDocError: () => ({
    type: Types.GET_PRODUTO_DOCUMENTO_ERROR,
  }),
  getProdDocReset: () => ({
    type: Types.GET_PRODUTO_DOCUMENTO_RESET,
  }),

  getFields: (payload) => ({
    type: Types.GET_FIELDS_ITENS_DOCUMENTO,
    payload,
  }),
  getFieldsSuccess: (payload) => ({
    type: Types.GET_FIELDS_ITENS_DOCUMENTO_SUCCESS,
    payload,
  }),
  getFieldsError: () => ({
    type: Types.GET_FIELDS_ITENS_DOCUMENTO_ERROR,
  }),

  getCusto: (payload) => ({
    type: Types.GET_CUSTO_ITENS_DOCUMENTO,
    payload,
  }),
  getCustoSuccess: (payload) => ({
    type: Types.GET_CUSTO_ITENS_DOCUMENTO_SUCCESS,
    payload,
  }),
  getCustoError: () => ({
    type: Types.GET_CUSTO_ITENS_DOCUMENTO_ERROR,
  }),
  getCustoReset: () => ({
    type: Types.GET_CUSTO_ITENS_DOCUMENTO_RESET,
  }),

  getPlanoConta: (payload) => ({
    type: Types.GET_PLANOCONTA_DOCUMENTO,
    payload,
  }),
  getPlanoContaSuccess: (payload) => ({
    type: Types.GET_PLANOCONTA_DOCUMENTO_SUCCESS,
    payload,
  }),
  getPlanoContaError: () => ({
    type: Types.GET_PLANOCONTA_DOCUMENTO_ERROR,
  }),

  putDocGerar: (payload) => ({
    type: Types.PUT_DOCGERAR,
    payload,
  }),
  putDocGerarSuccess: (payload) => ({
    type: Types.PUT_DOCGERAR_SUCCESS,
    payload,
  }),
  putDocGerarError: () => ({
    type: Types.PUT_DOCGERAR_ERROR,
  }),
  putDocGerarResset: () => ({
    type: Types.PUT_DOCGERAR_RESET,
  }),

  postDocMovimentar: (payload) => ({
    type: Types.POST_DOCMOVIMENTAR,
    payload,
  }),
  postDocMovimentarSuccess: (payload) => ({
    type: Types.POST_DOCMOVIMENTAR_SUCCESS,
    payload,
  }),
  postDocMovimentarError: () => ({
    type: Types.POST_DOCMOVIMENTAR_ERROR,
  }),
  postDocMovimentarResset: () => ({
    type: Types.POST_DOCMOVIMENTAR_RESET,
  }),

  getDocItem: (payload) => ({
    type: Types.GET_DOC_ITEM,
    payload,
  }),
  getDocItemSuccess: (payload) => ({
    type: Types.GET_DOC_ITEM_SUCCESS,
    payload,
  }),
  getDocItemError: () => ({
    type: Types.GET_DOC_ITEM_ERROR,
  }),

  postDocItem: (payload) => ({
    type: Types.POST_DOC_ITEM,
    payload,
  }),
  postDocItemSuccess: () => ({
    type: Types.POST_DOC_ITEM_SUCCESS,
  }),
  postDocItemError: () => ({
    type: Types.POST_DOC_ITEM_ERROR,
  }),
  postDocItemReset: () => ({
    type: Types.POST_DOC_ITEM_RESET,
  }),

  deleteDocItem: (payload) => ({
    type: Types.DELETE_DOC_ITEM,
    payload,
  }),
  deleteDocItemSuccess: () => ({
    type: Types.DELETE_DOC_ITEM_SUCCESS,
  }),
  deleteDocItemError: () => ({
    type: Types.DELETE_DOC_ITEM_ERROR,
  }),
  deleteDocItemReset: () => ({
    type: Types.DELETE_DOC_ITEM_RESET,
  }),

  putDocItem: (payload) => ({
    type: Types.PUT_DOC_ITEM,
    payload,
  }),
  putDocItemSuccess: () => ({
    type: Types.PUT_DOC_ITEM_SUCCESS,
  }),
  putDocItemError: () => ({
    type: Types.PUT_DOC_ITEM_ERROR,
  }),
  putDocItemReset: () => ({
    type: Types.PUT_DOC_ITEM_RESET,
  }),

  attPrecoItem: (payload) => ({
    type: Types.ATT_PRECO_ITEM,
    payload,
  }),
  attPrecoItemSuccess: () => ({
    type: Types.ATT_PRECO_ITEM_SUCCESS,
  }),
  attPrecoItemError: () => ({
    type: Types.ATT_PRECO_ITEM_ERROR,
  }),
  attPrecoItemReset: () => ({
    type: Types.ATT_PRECO_ITEM_RESET,
  }),

  getMovimentacao: (payload) => ({
    type: Types.GET_MOVIMENTACAO,
    payload,
  }),
  getMovimentacaoSuccess: (payload) => ({
    type: Types.GET_MOVIMENTACAO_SUCCESS,
    payload,
  }),
  getMovimentacaoError: () => ({
    type: Types.GET_MOVIMENTACAO_ERROR,
  }),

  postItemOutros: (payload) => ({
    type: Types.POST_ITEM_OUTROS,
    payload,
  }),
  postItemOutrosSuccess: () => ({
    type: Types.POST_ITEM_OUTROS_SUCCESS,
  }),
  postItemOutrosError: () => ({
    type: Types.POST_ITEM_OUTROS_ERROR,
  }),
  postItemOutrosReset: () => ({
    type: Types.POST_ITEM_OUTROS_RESET,
  }),

  postCfo: (payload) => ({
    type: Types.POST_CFO,
    payload,
  }),
  postCfoSuccess: () => ({
    type: Types.POST_CFO_SUCCESS,
  }),
  postCfoError: () => ({
    type: Types.POST_CFO_ERROR,
  }),
  postCfoReset: () => ({
    type: Types.POST_CFO_RESET,
  }),

  postTributo: (payload) => ({
    type: Types.POST_TRIBUTO,
    payload,
  }),
  postTributoSuccess: () => ({
    type: Types.POST_TRIBUTO_SUCCESS,
  }),
  postTributoError: () => ({
    type: Types.POST_TRIBUTO_ERROR,
  }),
  postTributoReset: () => ({
    type: Types.POST_TRIBUTO_RESET,
  }),

  postXML: (payload) => ({
    type: Types.POST_XML,
    payload,
  }),
  postXMLSuccess: () => ({
    type: Types.POST_XML_SUCCESS,
  }),
  postXMLError: () => ({
    type: Types.POST_XML_ERROR,
  }),
  postXMLReset: () => ({
    type: Types.POST_XML_RESET,
  }),

  exportXml: (payload) => ({
    type: Types.EXPORT_XML,
    payload,
  }),
  exportXmlSuccess: (payload) => ({
    type: Types.EXPORT_XML_SUCCESS,
    payload,
  }),
  exportXmlReset: () => ({
    type: Types.EXPORT_XML_RESET,
  }),

  postFinanceiro: (payload) => ({
    type: Types.POST_FINANCEIRO,
    payload,
  }),
  postFinanceiroSuccess: () => ({
    type: Types.POST_FINANCEIRO_SUCCESS,
  }),
  postFinanceiroError: () => ({
    type: Types.POST_FINANCEIRO_ERROR,
  }),
  postFinanceiroReset: () => ({
    type: Types.POST_FINANCEIRO_RESET,
  }),

  postParcela: (payload) => ({
    type: Types.POST_PARCELA,
    payload,
  }),
  postParcelaSuccess: () => ({
    type: Types.POST_PARCELA_SUCCESS,
  }),
  postParcelaError: () => ({
    type: Types.POST_PARCELA_ERROR,
  }),
  postParcelaReset: () => ({
    type: Types.POST_PARCELA_RESET,
  }),

  putParcela: (payload) => ({
    type: Types.PUT_PARCELA,
    payload,
  }),
  putParcelaSuccess: () => ({
    type: Types.PUT_PARCELA_SUCCESS,
  }),
  putParcelaError: () => ({
    type: Types.PUT_PARCELA_ERROR,
  }),
  putParcelaReset: () => ({
    type: Types.PUT_PARCELA_RESET,
  }),

  deleteParcela: (payload) => ({
    type: Types.DELETE_PARCELA,
    payload,
  }),
  deleteParcelaSuccess: () => ({
    type: Types.DELETE_PARCELA_SUCCESS,
  }),
  deleteParcelaError: () => ({
    type: Types.DELETE_PARCELA_ERROR,
  }),
  deleteParcelaReset: () => ({
    type: Types.DELETE_PARCELA_RESET,
  }),

  postEntrega: (payload) => ({
    type: Types.POST_ENTREGA,
    payload,
  }),
  postEntregaSuccess: () => ({
    type: Types.POST_ENTREGA_SUCCESS,
  }),
  postEntregaError: () => ({
    type: Types.POST_ENTREGA_ERROR,
  }),
  postEntregaReset: () => ({
    type: Types.POST_ENTREGA_RESET,
  }),

  putEntrega: (payload) => ({
    type: Types.PUT_ENTREGA,
    payload,
  }),
  putEntregaSuccess: () => ({
    type: Types.PUT_ENTREGA_SUCCESS,
  }),
  putEntregaError: () => ({
    type: Types.PUT_ENTREGA_ERROR,
  }),
  putEntregaReset: () => ({
    type: Types.PUT_ENTREGA_RESET,
  }),

  deleteEntrega: (payload) => ({
    type: Types.DELETE_ENTREGA,
    payload,
  }),
  deleteEntregaSuccess: () => ({
    type: Types.DELETE_ENTREGA_SUCCESS,
  }),
  deleteEntregaError: () => ({
    type: Types.DELETE_ENTREGA_ERROR,
  }),
  deleteEntregaReset: () => ({
    type: Types.DELETE_ENTREGA_RESET,
  }),

  imprimirDocumento: (payload) => ({
    type: Types.IMPRIMIR_DOCUMENTO,
    payload,
  }),
  imprimirDocumentoSuccess: (payload) => ({
    type: Types.IMPRIMIR_DOCUMENTO_SUCCESS,
    payload,
  }),
  imprimirDocumentoError: () => ({
    type: Types.IMPRIMIR_DOCUMENTO_ERROR,
  }),
  imprimirDocumentoReset: () => ({
    type: Types.IMPRIMIR_DOCUMENTO_RESET,
  }),

  userPerfil: (payload) => ({
    type: Types.USER_PERFIL,
    payload,
  }),
  userPerfilSuccess: (payload) => ({
    type: Types.USER_PERFIL_SUCCESS,
    payload,
  }),
  userPerfilError: () => ({
    type: Types.USER_PERFIL_ERROR,
  }),
  userPerfilReset: () => ({
    type: Types.USER_PERFIL_RESET,
  }),

  postLancamento: (payload) => ({
    type: Types.POST_LANCAMENTO,
    payload,
  }),
  postLancamentoSuccess: () => ({
    type: Types.POST_LANCAMENTO_SUCCESS,
  }),
  postLancamentoError: () => ({
    type: Types.POST_LANCAMENTO_ERROR,
  }),
  postLancamentoReset: () => ({
    type: Types.POST_LANCAMENTO_RESET,
  }),

  getQtdMov: (payload) => ({
    type: Types.GET_QTD_MOV,
    payload,
  }),
  getQtdMovSuccess: (payload) => ({
    type: Types.GET_QTD_MOV_SUCCESS,
    payload,
  }),
  getQtdMovError: () => ({
    type: Types.GET_QTD_MOV_ERROR,
  }),

  postEmissao: (payload) => ({
    type: Types.POST_EMISSAO,
    payload,
  }),
  postEmissaoSuccess: (payload) => ({
    type: Types.POST_EMISSAO_SUCCESS,
    payload,
  }),
  postEmissaoError: () => ({
    type: Types.POST_EMISSAO_ERROR,
  }),
  postEmissaoReset: () => ({
    type: Types.POST_EMISSAO_RESET,
  }),

  postManifestacao: (payload) => ({
    type: Types.POST_MANIFESTACAO,
    payload,
  }),
  postManifestacaoSuccess: () => ({
    type: Types.POST_MANIFESTACAO_SUCCESS,
  }),
  postManifestacaoError: () => ({
    type: Types.POST_MANIFESTACAO_ERROR,
  }),
  postManifestacaoReset: () => ({
    type: Types.POST_MANIFESTACAO_RESET,
  }),

  getQualidade: (payload) => ({
    type: Types.GET_QUALIDADE,
    payload,
  }),
  getQualidadeSuccess: (payload) => ({
    type: Types.GET_QUALIDADE_SUCCESS,
    payload,
  }),
  getQualidadeError: () => ({
    type: Types.GET_QUALIDADE_ERROR,
  }),

  postResposta: (payload) => ({
    type: Types.POST_RESPOSTA,
    payload,
  }),
  postRespostaSuccess: () => ({
    type: Types.POST_RESPOSTA_SUCCESS,
  }),
  postRespostaError: () => ({
    type: Types.POST_RESPOSTA_ERROR,
  }),
  postRespostaReset: () => ({
    type: Types.POST_RESPOSTA_RESET,
  }),

  postConsultasNfe: (payload) => ({
    type: Types.POST_CONSULTAS_NFE,
    payload,
  }),
  postConsultasNfeSuccess: (payload) => ({
    type: Types.POST_CONSULTAS_NFE_SUCCESS,
    payload,
  }),
  postConsultasNfeError: () => ({
    type: Types.POST_CONSULTAS_NFE_ERROR,
  }),
  postConsultasNfeReset: () => ({
    type: Types.POST_CONSULTAS_NFE_RESET,
  }),

  postCorrecao: (payload) => ({
    type: Types.POST_CORRECAO,
    payload,
  }),
  postCorrecaoSuccess: (payload) => ({
    type: Types.POST_CORRECAO_SUCCESS,
    payload,
  }),
  postCorrecaoError: () => ({
    type: Types.POST_CORRECAO_ERROR,
  }),
  postCorrecaoReset: () => ({
    type: Types.POST_CORRECAO_RESET,
  }),

  postInutilizacao: (payload) => ({
    type: Types.POST_INUTILIZACAO,
    payload,
  }),
  postInutilizacaoSuccess: () => ({
    type: Types.POST_INUTILIZACAO_SUCCESS,
  }),
  postInutilizacaoError: () => ({
    type: Types.POST_INUTILIZACAO_ERROR,
  }),
  postInutilizacaoReset: () => ({
    type: Types.POST_INUTILIZACAO_RESET,
  }),

  sendMail: (payload) => ({
    type: Types.SEND_MAIL,
    payload,
  }),
  sendMailSuccess: () => ({
    type: Types.SEND_MAIL_SUCCESS,
  }),
  sendMailError: () => ({
    type: Types.SEND_MAIL_ERROR,
  }),
  sendMailReset: () => ({
    type: Types.SEND_MAIL_RESET,
  }),

  getInfoTributo: (payload) => ({
    type: Types.GET_INFO_TRIBUTO,
    payload,
  }),
  getInfoTributoSuccess: (payload) => ({
    type: Types.GET_INFO_TRIBUTO_SUCCESS,
    payload,
  }),
  getInfoTributoError: () => ({
    type: Types.GET_INFO_TRIBUTO_ERROR,
  }),
  getInfoTributoReset: () => ({
    type: Types.GET_INFO_TRIBUTO_RESET,
  }),

  postFaturacao: (payload) => ({
    type: Types.POST_FATURACAO,
    payload,
  }),
  postFaturacaoSuccess: () => ({
    type: Types.POST_FATURACAO_SUCCESS,
  }),
  postFaturacaoError: () => ({
    type: Types.POST_FATURACAO_ERROR,
  }),
  postFaturacaoReset: () => ({
    type: Types.POST_FATURACAO_RESET,
  }),

  getDadosAdc: (payload) => ({
    type: Types.GET_DADOSADC,
    payload,
  }),
  getDadosAdcSuccess: (payload) => ({
    type: Types.GET_DADOSADC_SUCCESS,
    payload,
  }),
  getDadosAdcReset: () => ({
    type: Types.GET_DADOSADC_RESET,
  }),

  postNfe: (payload) => ({
    type: Types.POST_NFE,
    payload,
  }),
  postNfeSuccess: (payload) => ({
    type: Types.POST_NFE_SUCCESS,
    payload,
  }),
  postNfeReset: () => ({
    type: Types.POST_NFE_RESET,
  }),

  postItemOrigem: (payload) => ({
    type: Types.POST_ITEM_ORIGEM,
    payload,
  }),
  postItemOrigemSuccess: () => ({
    type: Types.POST_ITEM_ORIGEM_SUCCESS,
  }),
  postItemOrigemReset: () => ({
    type: Types.POST_ITEM_ORIGEM_RESET,
  }),

  postBxOrigem: (payload) => ({
    type: Types.POST_BX_ORIGEM,
    payload,
  }),
  postBxOrigemSuccess: () => ({
    type: Types.POST_BX_ORIGEM_SUCCESS,
  }),
  postBxOrigemReset: () => ({
    type: Types.POST_BX_ORIGEM_RESET,
  }),

  getConferencia: (payload) => ({
    type: Types.GET_CONFERENCIA,
    payload,
  }),
  getConferenciaSuccess: () => ({
    type: Types.GET_CONFERENCIA_SUCCESS,
  }),
  getConferenciaReset: () => ({
    type: Types.GET_CONFERENCIA_RESET,
  }),

  postStatusAnt: (payload) => ({
    type: Types.POST_STATUSANT,
    payload,
  }),
  postStatusAntSuccess: () => ({
    type: Types.POST_STATUSANT_SUCCESS,
  }),
  postStatusAntReset: () => ({
    type: Types.POST_STATUSANT_RESET,
  }),

  postExportaInutilizadas: (payload) => ({
    type: Types.POST_EXPORTA_INUTILIZADAS,
    payload,
  }),
  postExportaInutilizadasSuccess: () => ({
    type: Types.POST_EXPORTA_INUTILIZADAS_SUCCESS,
  }),
  postExportaInutilizadasReset: () => ({
    type: Types.POST_EXPORTA_INUTILIZADAS_RESET,
  }),

  postCarregamento: (payload) => ({
    type: Types.POST_CARREGAMENTO,
    payload,
  }),
  postCarregamentoSuccess: () => ({
    type: Types.POST_CARREGAMENTO_SUCCESS,
  }),
  postCarregamentoReset: () => ({
    type: Types.POST_CARREGAMENTO_RESET,
  }),

  getMidia: (payload) => ({
    type: Types.GET_DOCUMENTO_MIDIAS,
    payload,
  }),
  getMidiaSuccess: (payload) => ({
    type: Types.GET_DOCUMENTO_MIDIAS_SUCCESS,
    payload,
  }),
  getMidiaReset: () => ({
    type: Types.GET_DOCUMENTO_MIDIAS_RESET,
  }),

  putMidia: (payload) => ({
    type: Types.PUT_DOCUMENTO_MIDIAS,
    payload,
  }),
  putMidiaSuccess: () => ({
    type: Types.PUT_DOCUMENTO_MIDIAS_SUCCESS,
  }),
  putMidiaReset: () => ({
    type: Types.PUT_DOCUMENTO_MIDIAS_RESET,
  }),

  postDocumentoGerar: (payload) => ({
    type: Types.POST_DOCUMENTO_GERAR,
    payload,
  }),
  postDocumentoGerarSuccess: () => ({
    type: Types.POST_DOCUMENTO_GERAR_SUCCESS,
  }),
  postDocumentoGerarReset: () => ({
    type: Types.POST_DOCUMENTO_GERAR_RESET,
  }),

  imprimirDanfeEtiqueta: (payload) => ({
    type: Types.IMPRIMIR_DANFE_ETIQUETA,
    payload,
  }),
  imprimirDanfeEtiquetaSuccess: () => ({
    type: Types.IMPRIMIR_DANFE_ETIQUETA_SUCCESS,
  }),
  imprimirDanfeEtiquetaReset: () => ({
    type: Types.IMPRIMIR_DANFE_ETIQUETA_RESET,
  }),
};
