import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { setDecimal, transformData } from 'util/index';
import SelectAuto from 'components/SelectAuto';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridRadio: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const LancDetalhesModal = ({
  open,
  setOpen,
  items,
  planoConta,
  setDetalhes,
  Detalhes,
  idLancto,
  contacp,
  classes,
}) => {
  const onConfirm = (values) => {
    setDetalhes([
      ...Detalhes,
      {
        cxlanctos_id: idLancto && Number(idLancto),
        historico: values?.historico,
        valor: transformData(values?.valor, 'number'),
        planoconta_id: values?.ccontabil?.value,
        ccusto_id: values?.ccusto?.value,
        aplicacao_id: values?.aplicacao?.value,
        frota_id: values?.frota?.value,
        contacp_id: values?.contacp_id?.value,
      },
    ]);
    setOpen(!open);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <DialogTitle>Novo Detalhe do Lançamento</DialogTitle>
      <Formik
        initialValues={{
          valor: null,
          ccontabil: null,
          ccusto: null,
          aplicacao: null,
          frota: null,
          historico: null,
          contacp_id: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                  <SelectAuto
                    label="Plano de Contas"
                    items={planoConta}
                    value={values.ccontabil}
                    onChange={(v) => {
                      setFieldValue('ccontabil', v);
                      setFieldValue('historico', v?.label);
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="valor"
                    label="Valor"
                    value={values.valor}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('valor', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Aplicação"
                    items={items[2]}
                    value={values.aplicacao}
                    onChange={(v) => setFieldValue('aplicacao', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Frota"
                    items={items[3]}
                    value={values.frota}
                    onChange={(v) => setFieldValue('frota', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Centro Custo"
                    items={items[1]}
                    value={values.ccusto}
                    onChange={(v) => setFieldValue('ccusto', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="historico"
                    label="Histórico"
                    value={values.historico || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                {!!values?.ccontabil?.contacp_id && (
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Conta Contra Partida"
                      items={
                        !!contacp
                          ? items[0]?.filter((f) => f?.value !== contacp) ||
                            items[0]
                          : items[0]
                      }
                      value={values.contacp_id}
                      onChange={(v) => setFieldValue('contacp_id', v)}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => onConfirm(values)}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(LancDetalhesModal);
