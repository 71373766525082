import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './styles';
import Loading from 'components/Loading';
import api from 'util/api';
import { setDecimal } from 'util/index';

function Totalizadores({ param, classes }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  async function loadItems() {
    const { data } = await api.post(`/csql/909`, {
      WhereClause: `di.id = ${param}`,
    });
    if (data) {
      setItems(data.dados || []);
    }
    setLoading(false);
  }

  const RenderWithPaginacao = () => {
    if (loading) {
      return (
        <div className="d-flex w-100 align-items-center justify-content-center">
          <Loading />
        </div>
      );
    }
    return (
      <Grid container spacing={0}>
        {items[0]?.custoproc && (
          <Grid container item xs={3} spacing={0}>
            <Paper
              elevation={5}
              className={`${classes.paper} bg-gradient-primary-x text-white`}
            >
              <h4 className={`${classes.titles} text-center`}>
                Custo Processo
              </h4>
              <h6 className={classes.valor}>
                {setDecimal(items[0]?.custoproc, 2)}
              </h6>
            </Paper>
          </Grid>
        )}
        {items[0]?.custonovo && (
          <Grid container item xs={3} spacing={0}>
            <Paper
              elevation={5}
              className={`${classes.paper} bg-gradient-primary-x text-white`}
            >
              <h4 className={`${classes.titles} text-center`}>Custo Novo</h4>
              <h6 className={classes.valor}>
                {setDecimal(items[0]?.custonovo, 2)}
              </h6>
            </Paper>
          </Grid>
        )}
        {items[0]?.custoatual && (
          <Grid container item xs={3} spacing={0}>
            <Paper
              elevation={5}
              className={`${classes.paper} bg-gradient-primary-x text-white`}
            >
              <h4 className={`${classes.titles} text-center`}>Custo Atual</h4>
              <h6 className={classes.valor}>
                {setDecimal(items[0]?.custoatual, 2)}
              </h6>
            </Paper>
          </Grid>
        )}
        {items[0]?.variacao && (
          <Grid container item xs={3} spacing={0}>
            <Paper
              elevation={5}
              className={`${classes.paper} bg-gradient-primary-x text-white`}
            >
              <h4 className={`${classes.titles} text-center`}>Variação</h4>
              <h6 className={classes.valor}>
                {setDecimal(items[0]?.variacao, 2)}%
              </h6>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <div className="mt-3">
      <ExpansionPanel
        elevation={3}
        onChange={(_, exp) => {
          if (exp) {
            loadItems();
          } else {
            setLoading(true);
          }
        }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h4 className={classes.titles}>Totais</h4>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <RenderWithPaginacao />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default withStyles(styles)(Totalizadores);
