import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import moment from 'moment';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import FilterReportModal from 'components/Modals/FilterReportModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import DocGerarModal from 'components/Modals/DocGerarModal';
import {
  checkManutencao,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const Inventário = ({ match }) => {
  const rotina = 681;
  const csql = 603;
  const relatorio = 682;
  const title = 'Inventários';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState('');
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [docGerarModal, putDocGerarModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const { docgerar, capaSuccess } = useSelector(({ documento }) => documento);

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = filtros.map((f) => {
        if (f.parametro === 'SIM') {
          return { ...f, conteudoi: param, conteudod: nameParam };
        }
        return f;
      });
      return filterWithParam;
    }
    return filtros;
  }

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: {
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
            OrderClause,
            Pagina: paginacao ? Pagina : undefined,
            PorPagina: paginacao ? PorPagina : undefined,
          },
        })
      );
    }
  }

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
    dispatch(TablesActions.getReport(relatorio));
  }, []);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length && param) {
      dispatch(TablesActions.setFiltros(setParamOnFilter()));
    } else {
      if (headerData.length) {
        getDataTable();
      }
    }
  }, [headerData, Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (docgerar) {
      let itensEntrada = [];
      let itensSaida = [];
      (docgerar.Itens || []).map((i) => {
        if (i.ajuste > 0) {
          itensEntrada.push(i);
        }
        if (i.ajuste < 0) {
          itensSaida.push(i);
        }
      });
      let entrada = {
        ...docgerar,
        Documento: {
          ...docgerar.Documento,
          especie_id: 14,
          natureza_operacao_id: 17,
        },
        Itens: itensEntrada,
      };
      let saida = {
        ...docgerar,
        Documento: {
          ...docgerar.Documento,
          especie_id: 38,
          natureza_operacao_id: 28,
        },
        Itens: itensSaida,
      };
      if (itensEntrada.length) {
        dispatch(DocumentoActions.postCapa(entrada));
      }
      if (itensSaida.length) {
        dispatch(DocumentoActions.postCapa(saida));
      }
    }
  }, [docgerar]);

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
    if (capaSuccess) {
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
      dispatch(DocumentoActions.putDocGerarResset());
    }
  }, [capaSuccess]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () =>
        param
          ? history.push(`/app/Manutenção?cod=${param}`)
          : history.push(`/app/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => setFilterModal(!filterModal),
      visible: filtros?.length,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Novo Documento',
      type: 'Gerar',
      action: () =>
        history.push(
          `/app/Cadastros/Documento?especie=${data[0]?.especie_id}&natureza=${data[0]?.natureza_operacao_id}`
        ),
      visible: true,
    },
    {
      name: 'Relação para Inventário',
      type: 'Imprimir',
      action: () => setFilterReportModal(!filterReportModal),
      visible: true,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Documento',
          action: (item) =>
            history.push(`/app/Cadastros/Documento?id=${item?.id}`),
          visible: true,
        },
        {
          name: 'Detalhes',
          varName: 'Detalhes',
          action: (item) =>
            history.push(`/app/Suprimentos/Inventário/Itens?id=
            ${item?.id}&descricao=${item.especie} - (${item.id}) - ${moment(
              item.dtemissao
            ).format('DD/MM/YYYY')}`),
          visible: true,
        },
        {
          name: 'Gerar Movimentação',
          varName: 'Gerar',
          action: (item) => {
            dispatch(
              DocumentoActions.putDocGerar({
                Documentos: [{ id: item?.id }],
                Rotina: 'INVENTARIO',
              })
            );
          },
          visible: true,
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filtros}
                reportFiltros={reportFiltros}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <DocGerarModal
                open={docGerarModal}
                setOpen={putDocGerarModal}
                selected={selected}
                despecie={14}
                dnatureza={17}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Inventário;
