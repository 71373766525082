import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { setDecimal, transformData } from 'util/index';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProdutoActions } from '../../../store/ducks/Produto';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '50%',
  },
  button: {
    margin: '0 5px',
  },
});

const DensidadeModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [percentual, setPercentual] = useState(null);
  const { putAjustaDensidadeLoading } = useSelector(({ produto }) => produto);

  const onSubmit = () => {
    const data = {
      documento_item_estrutura_id: selected?.id,
      percentual: transformData(percentual, 'number'),
    };
    dispatch(ProdutoActions.putAjustaDensidade(data));
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Ajustar Densidade</DialogTitle>
      <DialogContent>
        <TextField
          label="Percentual"
          value={percentual}
          onChange={(e) => setPercentual(e.target.value)}
          onBlur={(e) => setPercentual(setDecimal(e.target.value, 2))}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={putAjustaDensidadeLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(DensidadeModal);
