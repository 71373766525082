export const Types = {
  POST_FIN_HIST: 'POST_FIN_HIST',
  POST_FIN_HIST_SUCCESS: 'POST_FIN_HIST_SUCCESS',
  POST_FIN_HIST_ERROR: 'POST_FIN_HIST_ERROR',
  POST_FIN_HIST_RESET: 'POST_FIN_HIST_RESET',
  RESET_MOVIMENTO: 'RESET_MOVIMENTO',

  GET_FIN_MOV: 'GET_FIN_MOV',
  GET_FIN_MOV_SUCCESS: 'GET_FIN_MOV_SUCCESS',
  GET_FIN_MOV_ERROR: 'GET_FIN_MOV_ERROR',
  GET_FIN_MOV_RESET: 'GET_FIN_MOV_RESET',

  POST_FIN_OPRD: 'POST_FIN_OPRD',
  POST_FIN_OPRD_SUCCESS: 'POST_FIN_OPRD_SUCCESS',
  POST_FIN_OPRD_ERROR: 'POST_FIN_OPRD_ERROR',
  POST_FIN_OPRD_RESET: 'POST_FIN_OPRD_RESET',

  POST_FIN_CHEQUE: 'POST_FIN_CHEQUE',
  POST_FIN_CHEQUE_SUCCESS: 'POST_FIN_CHEQUE_SUCCESS',
  POST_FIN_CHEQUE_ERROR: 'POST_FIN_CHEQUE_ERROR',
  POST_FIN_CHEQUE_RESET: 'POST_FIN_CHEQUE_RESET',

  POST_FIN: 'POST_FIN',
  POST_FIN_SUCCESS: 'POST_FIN_SUCCESS',
  POST_FIN_ERROR: 'POST_FIN_ERROR',
  POST_FIN_RESET: 'POST_FIN_RESET',

  POST_FIN_COMP: 'POST_FIN_COMP',
  POST_FIN_COMP_SUCCESS: 'POST_FIN_COMP_SUCCESS',
  POST_FIN_COMP_ERROR: 'POST_FIN_COMP_ERROR',
  POST_FIN_COMP_RESET: 'POST_FIN_COMP_RESET',

  POST_CONCLUSAO: 'POST_CONCLUSAO',
  POST_CONCLUSAO_SUCCESS: 'POST_CONCLUSAO_SUCCESS',
  POST_CONCLUSAO_ERROR: 'POST_CONCLUSAO_ERROR',
  POST_CONCLUSAO_RESET: 'POST_CONCLUSAO_RESET',

  PUT_FIN_FORMA: 'PUT_FIN_FORMA',
  PUT_FIN_FORMA_SUCCESS: 'PUT_FIN_FORMA_SUCCESS',
  PUT_FIN_FORMA_ERROR: 'PUT_FIN_FORMA_ERROR',
  PUT_FIN_FORMA_RESET: 'PUT_FIN_FORMA_RESET',

  PUT_FIN_CONCI: 'PUT_FIN_CONCI',
  PUT_FIN_CONCI_SUCCESS: 'PUT_FIN_CONCI_SUCCESS',
  PUT_FIN_CONCI_ERROR: 'PUT_FIN_CONCI_ERROR',
  PUT_FIN_CONCI_RESET: 'PUT_FIN_CONCI_RESET',

  PUT_EXPORT_TEXT: 'PUT_EXPORT_TEXT',
  PUT_EXPORT_TEXT_SUCCESS: 'PUT_EXPORT_TEXT_SUCCESS',
  PUT_EXPORT_TEXT_RESET: 'PUT_EXPORT_TEXT_RESET',

  GET_FIN_LANCTO: 'GET_FIN_LANCTO',
  GET_FIN_LANCTO_SUCCESS: 'GET_FIN_LANCTO_SUCCESS',
  GET_FIN_LANCTO_ERROR: 'GET_FIN_LANCTO_ERROR',

  POST_FIN_CHEQUE_DEV: 'POST_FIN_CHEQUE_DEV',
  POST_FIN_CHEQUE_DEV_SUCCESS: 'POST_FIN_CHEQUE_DEV_SUCCESS',
  POST_FIN_CHEQUE_DEV_ERROR: 'POST_FIN_CHEQUE_DEV_ERROR',
  POST_FIN_CHEQUE_DEV_RESET: 'POST_FIN_CHEQUE_DEV_RESET',

  PUT_CANCELA_MOV: 'PUT_CANCELA_MOV',
  PUT_CANCELA_MOV_SUCCESS: 'PUT_CANCELA_MOV_SUCCESS',
  PUT_CANCELA_MOV_RESET: 'PUT_CANCELA_MOV_RESET',

  POST_CANCELA_VENDA: 'POST_CANCELA_VENDA',
  POST_CANCELA_VENDA_SUCCESS: 'POST_CANCELA_VENDA_SUCCESS',
  POST_CANCELA_VENDA_RESET: 'POST_CANCELA_VENDA_RESET',

  POST_EXCEL: 'POST_EXCEL',
  POST_EXCEL_SUCCESS: 'POST_EXCEL_SUCCESS',
  POST_EXCEL_RESET: 'POST_EXCEL_RESET',

  POST_BAIXA_HAVER: 'POST_BAIXA_HAVER',
  POST_BAIXA_HAVER_SUCCESS: 'POST_BAIXA_HAVER_SUCCESS',
  POST_BAIXA_HAVER_RESET: 'POST_BAIXA_HAVER_RESET',

  POST_CALENDARIO: 'POST_CALENDARIO',
  POST_CALENDARIO_SUCCESS: 'POST_CALENDARIO_SUCCESS',
  POST_CALENDARIO_RESET: 'POST_CALENDARIO_RESET',

  POST_CALENDARIO_MOEDA: 'POST_CALENDARIO_MOEDA',
  POST_CALENDARIO_MOEDA_SUCCESS: 'POST_CALENDARIO_MOEDA_SUCCESS',
  POST_CALENDARIO_MOEDA_RESET: 'POST_CALENDARIO_MOEDA_RESET',

  GET_MOVIMENTO: 'GET_MOVIMENTO',
  GET_MOVIMENTO_SUCCESS: 'GET_MOVIMENTO_SUCCESS',
  GET_MOVIMENTO_RESET: 'GET_MOVIMENTO_RESET',

  GET_LANC_DETALHE: 'GET_LANC_DETALHE',
  GET_LANC_DETALHE_SUCCESS: 'GET_LANC_DETALHE_SUCCESS',
  GET_LANC_DETALHE_RESET: 'GET_LANC_DETALHE_RESET',

  IMPORT_OFX: 'IMPORT_OFX',
  IMPORT_OFX_SUCCESS: 'IMPORT_OFX_SUCCESS',
  IMPORT_OFX_RESET: 'IMPORT_OFX_RESET',
};

const INIT_STATE = {
  postFinHistLoading: false,
  postFinHistSuccess: false,
  postFinHistError: false,

  movimento: null,

  getFinMovLoading: false,
  getFinMovSuccess: false,
  getFinMovError: false,

  postFinOprdLoading: false,
  postFinOprdSuccess: false,
  postFinOprdError: false,

  postFinChequeLoading: false,
  postFinChequeSuccess: false,
  postFinChequeError: false,

  postFinLoading: false,
  postFinSuccess: false,
  postFinError: false,

  postFinCompLoading: false,
  postFinCompSuccess: false,
  postFinCompError: false,

  postConclusaoLoading: false,
  postConclusaoSuccess: null,
  postConclusaoError: false,

  putFinFormaLoading: false,
  putFinFormaSuccess: false,
  putFinFormaError: false,

  putFinConciLoading: false,
  putFinConciSuccess: false,
  putFinConciError: false,

  putExportTextLoading: false,
  putExportTextSuccess: false,

  getFinLanctoLoading: false,
  lancamento: null,
  getFinLanctoError: false,

  postFinChequeDevLoading: false,
  postFinChequeDevSuccess: false,
  postFinChequeDevError: false,

  putCancelaMovLoading: false,
  putCancelaMovSuccess: false,

  postCancelaVendaLoading: false,
  postCancelaVendaSuccess: false,

  postExcelLoading: false,
  postExcelSuccess: false,
  excelRegistros: [],

  postBaixaHaverLoading: false,
  postBaixaHaverSuccess: false,

  postCalendarioLoading: false,
  postCalendarioSuccess: false,

  postCalendarioMoedaLoading: false,
  postCalendarioMoedaSuccess: false,

  getMovimentoLoading: false,
  movimentoFinanceiro: null,

  getLancDetalheLoading: false,
  lancDetalhe: null,

  importOfxLoading: false,
  ofx: [],
};

export default function Lancamento(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.POST_FIN_HIST: {
      return {
        ...state,
        postFinHistLoading: true,
        postFinHistSuccess: false,
        postFinHistError: false,
        movimento: null,
      };
    }
    case Types.POST_FIN_HIST_SUCCESS: {
      return {
        ...state,
        postFinHistLoading: false,
        postFinHistSuccess: true,
        postFinHistError: false,
        movimento: action.payload,
      };
    }
    case Types.POST_FIN_HIST_ERROR: {
      return {
        ...state,
        postFinHistLoading: false,
        postFinHistSuccess: false,
        postFinHistError: true,
        movimento: null,
      };
    }
    case Types.POST_FIN_HIST_RESET: {
      return {
        ...state,
        postFinHistLoading: false,
        postFinHistSuccess: false,
        postFinHistError: false,
      };
    }
    case Types.RESET_MOVIMENTO: {
      return {
        ...state,
        movimento: null,
      };
    }

    case Types.GET_FIN_MOV: {
      return {
        ...state,
        getFinMovLoading: true,
        getFinMovSuccess: false,
        getFinMovError: false,
        movimento: null,
      };
    }
    case Types.GET_FIN_MOV_SUCCESS: {
      return {
        ...state,
        getFinMovLoading: false,
        getFinMovSuccess: true,
        getFinMovError: false,
        movimento: action.payload,
      };
    }
    case Types.GET_FIN_MOV_ERROR: {
      return {
        ...state,
        getFinMovLoading: false,
        getFinMovSuccess: false,
        getFinMovError: true,
        movimento: null,
      };
    }
    case Types.GET_FIN_MOV_RESET: {
      return {
        ...state,
        getFinMovLoading: false,
        getFinMovSuccess: false,
        getFinMovError: false,
      };
    }

    case Types.POST_FIN_OPRD: {
      return {
        ...state,
        postFinOprdLoading: true,
        postFinOprdSuccess: false,
        postFinOprdError: false,
      };
    }
    case Types.POST_FIN_OPRD: {
      return {
        ...state,
        postFinOprdLoading: true,
        postFinOprdSuccess: false,
        postFinOprdError: false,
      };
    }
    case Types.POST_FIN_OPRD: {
      return {
        ...state,
        postFinOprdLoading: true,
        postFinOprdSuccess: false,
        postFinOprdError: false,
      };
    }
    case Types.POST_FIN_OPRD_SUCCESS: {
      return {
        ...state,
        postFinOprdLoading: false,
        postFinOprdSuccess: true,
        postFinOprdError: false,
      };
    }
    case Types.POST_FIN_OPRD_ERROR: {
      return {
        ...state,
        postFinOprdLoading: false,
        postFinOprdSuccess: false,
        postFinOprdError: true,
      };
    }
    case Types.POST_FIN_OPRD_RESET: {
      return {
        ...state,
        postFinOprdLoading: false,
        postFinOprdSuccess: false,
        postFinOprdError: false,
      };
    }

    case Types.POST_FIN_CHEQUE: {
      return {
        ...state,
        postFinChequeLoading: true,
        postFinChequeSuccess: false,
        postFinChequeError: false,
      };
    }
    case Types.POST_FIN_CHEQUE_SUCCESS: {
      return {
        ...state,
        postFinChequeLoading: false,
        postFinChequeSuccess: true,
        postFinChequeError: false,
      };
    }
    case Types.POST_FIN_CHEQUE_ERROR: {
      return {
        ...state,
        postFinChequeLoading: false,
        postFinChequeSuccess: false,
        postFinChequeError: true,
      };
    }
    case Types.POST_FIN_CHEQUE_RESET: {
      return {
        ...state,
        postFinChequeLoading: false,
        postFinChequeSuccess: false,
        postFinChequeError: false,
      };
    }

    case Types.POST_FIN: {
      return {
        ...state,
        postFinLoading: true,
        postFinSuccess: false,
        postFinError: false,
      };
    }
    case Types.POST_FIN_SUCCESS: {
      return {
        ...state,
        postFinLoading: false,
        postFinSuccess: true,
        postFinError: false,
      };
    }
    case Types.POST_FIN_ERROR: {
      return {
        ...state,
        postFinLoading: false,
        postFinSuccess: false,
        postFinError: true,
      };
    }
    case Types.POST_FIN_RESET: {
      return {
        ...state,
        postFinLoading: false,
        postFinSuccess: false,
        postFinError: false,
      };
    }

    case Types.POST_FIN_COMP: {
      return {
        ...state,
        postFinCompLoading: true,
        postFinCompSuccess: false,
        postFinCompError: false,
      };
    }
    case Types.POST_FIN_COMP_SUCCESS: {
      return {
        ...state,
        postFinCompLoading: false,
        postFinCompSuccess: true,
        postFinCompError: false,
      };
    }
    case Types.POST_FIN_COMP_ERROR: {
      return {
        ...state,
        postFinCompLoading: false,
        postFinCompSuccess: false,
        postFinCompError: true,
      };
    }
    case Types.POST_FIN_COMP_RESET: {
      return {
        ...state,
        postFinCompLoading: false,
        postFinCompSuccess: false,
        postFinCompError: false,
      };
    }

    case Types.POST_CONCLUSAO: {
      return {
        ...state,
        postConclusaoLoading: true,
        postConclusaoSuccess: null,
        postConclusaoError: false,
      };
    }
    case Types.POST_CONCLUSAO_SUCCESS: {
      return {
        ...state,
        postConclusaoLoading: false,
        postConclusaoSuccess: action.payload,
        postConclusaoError: false,
      };
    }
    case Types.POST_CONCLUSAO_ERROR: {
      return {
        ...state,
        postConclusaoLoading: false,
        postConclusaoSuccess: null,
        postConclusaoError: true,
      };
    }
    case Types.POST_CONCLUSAO_RESET: {
      return {
        ...state,
        postConclusaoLoading: false,
        postConclusaoSuccess: null,
        postConclusaoError: false,
      };
    }

    case Types.PUT_FIN_FORMA: {
      return {
        ...state,
        putFinFormaLoading: true,
        putFinFormaSuccess: false,
        putFinFormaError: false,
      };
    }
    case Types.PUT_FIN_FORMA_SUCCESS: {
      return {
        ...state,
        putFinFormaLoading: false,
        putFinFormaSuccess: true,
        putFinFormaError: false,
      };
    }
    case Types.PUT_FIN_FORMA_ERROR: {
      return {
        ...state,
        putFinFormaLoading: false,
        putFinFormaSuccess: false,
        putFinFormaError: true,
      };
    }
    case Types.PUT_FIN_FORMA_RESET: {
      return {
        ...state,
        putFinFormaLoading: false,
        putFinFormaSuccess: false,
        putFinFormaError: false,
      };
    }

    case Types.PUT_FIN_CONCI: {
      return {
        ...state,
        putFinConciLoading: true,
        putFinConciSuccess: false,
        putFinConciError: false,
      };
    }
    case Types.PUT_FIN_CONCI_SUCCESS: {
      return {
        ...state,
        putFinConciLoading: false,
        putFinConciSuccess: true,
        putFinConciError: false,
      };
    }
    case Types.PUT_FIN_CONCI_ERROR: {
      return {
        ...state,
        putFinConciLoading: false,
        putFinConciSuccess: false,
        putFinConciError: true,
      };
    }
    case Types.PUT_FIN_CONCI_RESET: {
      return {
        ...state,
        putFinConciLoading: false,
        putFinConciSuccess: false,
        putFinConciError: false,
      };
    }

    case Types.PUT_EXPORT_TEXT: {
      return {
        ...state,
        putExportTextLoading: true,
        putExportTextSuccess: false,
      };
    }
    case Types.PUT_EXPORT_TEXT_SUCCESS: {
      return {
        ...state,
        putExportTextLoading: false,
        putExportTextSuccess: true,
      };
    }
    case Types.PUT_EXPORT_TEXT_RESET: {
      return {
        ...state,
        putExportTextLoading: false,
        putExportTextSuccess: false,
      };
    }

    case Types.GET_FIN_LANCTO: {
      return {
        ...state,
        getFinLanctoLoading: true,
        lancamento: null,
        getFinLanctoError: false,
      };
    }
    case Types.GET_FIN_LANCTO_SUCCESS: {
      return {
        ...state,
        getFinLanctoLoading: false,
        lancamento: action.payload,
        getFinLanctoError: false,
      };
    }
    case Types.GET_FIN_LANCTO_ERROR: {
      return {
        ...state,
        getFinLanctoLoading: false,
        lancamento: null,
        getFinLanctoError: true,
      };
    }

    case Types.POST_FIN_CHEQUE_DEV: {
      return {
        ...state,
        postFinChequeDevLoading: true,
        postFinChequeDevSuccess: false,
        postFinChequeDevError: false,
      };
    }
    case Types.POST_FIN_CHEQUE_DEV_SUCCESS: {
      return {
        ...state,
        postFinChequeDevLoading: false,
        postFinChequeDevSuccess: true,
        postFinChequeDevError: false,
      };
    }
    case Types.POST_FIN_CHEQUE_DEV_ERROR: {
      return {
        ...state,
        postFinChequeDevLoading: false,
        postFinChequeDevSuccess: false,
        postFinChequeDevError: true,
      };
    }
    case Types.POST_FIN_CHEQUE_DEV_RESET: {
      return {
        ...state,
        postFinChequeDevLoading: false,
        postFinChequeDevSuccess: false,
        postFinChequeDevError: false,
      };
    }

    case Types.PUT_CANCELA_MOV: {
      return {
        ...state,
        putCancelaMovLoading: true,
        putCancelaMovSuccess: false,
      };
    }
    case Types.PUT_CANCELA_MOV_SUCCESS: {
      return {
        ...state,
        putCancelaMovLoading: false,
        putCancelaMovSuccess: true,
      };
    }
    case Types.PUT_CANCELA_MOV_RESET: {
      return {
        ...state,
        putCancelaMovLoading: false,
        putCancelaMovSuccess: false,
      };
    }

    case Types.POST_CANCELA_VENDA: {
      return {
        ...state,
        postCancelaVendaLoading: true,
        postCancelaVendaSuccess: false,
      };
    }
    case Types.POST_CANCELA_VENDA_SUCCESS: {
      return {
        ...state,
        postCancelaVendaLoading: false,
        postCancelaVendaSuccess: true,
      };
    }
    case Types.POST_CANCELA_VENDA_RESET: {
      return {
        ...state,
        postCancelaVendaLoading: false,
        postCancelaVendaSuccess: false,
      };
    }

    case Types.POST_EXCEL: {
      return {
        ...state,
        postExcelLoading: true,
        postExcelSuccess: false,
        excelRegistros: [],
      };
    }
    case Types.POST_EXCEL_SUCCESS: {
      return {
        ...state,
        postExcelLoading: false,
        postExcelSuccess: true,
        excelRegistros: action.payload,
      };
    }
    case Types.POST_EXCEL_RESET: {
      return {
        ...state,
        postExcelLoading: false,
        postExcelSuccess: false,
        excelRegistros: [],
      };
    }

    case Types.POST_BAIXA_HAVER: {
      return {
        ...state,
        postBaixaHaverLoading: true,
        postBaixaHaverSuccess: false,
      };
    }
    case Types.POST_BAIXA_HAVER_SUCCESS: {
      return {
        ...state,
        postBaixaHaverLoading: false,
        postBaixaHaverSuccess: true,
      };
    }
    case Types.POST_BAIXA_HAVER_RESET: {
      return {
        ...state,
        postBaixaHaverLoading: false,
        postBaixaHaverSuccess: false,
      };
    }

    case Types.POST_CALENDARIO: {
      return {
        ...state,
        postCalendarioLoading: true,
        postCalendarioSuccess: false,
      };
    }
    case Types.POST_CALENDARIO_SUCCESS: {
      return {
        ...state,
        postCalendarioLoading: false,
        postCalendarioSuccess: true,
      };
    }
    case Types.POST_CALENDARIO_RESET: {
      return {
        ...state,
        postCalendarioLoading: false,
        postCalendarioSuccess: false,
      };
    }

    case Types.POST_CALENDARIO_MOEDA: {
      return {
        ...state,
        postCalendarioMoedaLoading: true,
        postCalendarioMoedaSuccess: false,
      };
    }
    case Types.POST_CALENDARIO_MOEDA_SUCCESS: {
      return {
        ...state,
        postCalendarioMoedaLoading: false,
        postCalendarioMoedaSuccess: true,
      };
    }
    case Types.POST_CALENDARIO_MOEDA_RESET: {
      return {
        ...state,
        postCalendarioMoedaLoading: false,
        postCalendarioMoedaSuccess: false,
      };
    }

    case Types.GET_MOVIMENTO: {
      return {
        ...state,
        getMovimentoLoading: true,
        movimentoFinanceiro: null,
      };
    }
    case Types.GET_MOVIMENTO_SUCCESS: {
      return {
        ...state,
        getMovimentoLoading: false,
        movimentoFinanceiro: action.payload,
      };
    }
    case Types.GET_MOVIMENTO_RESET: {
      return {
        ...state,
        getMovimentoLoading: false,
        movimentoFinanceiro: null,
      };
    }

    case Types.GET_LANC_DETALHE: {
      return {
        ...state,
        getLancDetalheLoading: true,
        lancDetalhe: null,
      };
    }
    case Types.GET_LANC_DETALHE_SUCCESS: {
      return {
        ...state,
        getLancDetalheLoading: false,
        lancDetalhe: action.payload,
      };
    }
    case Types.GET_LANC_DETALHE_RESET: {
      return {
        ...state,
        getLancDetalheLoading: false,
        lancDetalhe: null,
      };
    }

    case Types.IMPORT_OFX: {
      return {
        ...state,
        importOfxLoading: true,
        ofx: [],
      };
    }
    case Types.IMPORT_OFX_SUCCESS: {
      return {
        ...state,
        importOfxLoading: false,
        ofx: action.payload,
      };
    }
    case Types.IMPORT_OFX_RESET: {
      return {
        ...state,
        importOfxLoading: false,
        ofx: [],
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  postFinHist: (payload) => ({
    type: Types.POST_FIN_HIST,
    payload,
  }),
  postFinHistSuccess: (payload) => ({
    type: Types.POST_FIN_HIST_SUCCESS,
    payload,
  }),
  postFinHistError: () => ({
    type: Types.POST_FIN_HIST_ERROR,
  }),
  postFinHistReset: () => ({
    type: Types.POST_FIN_HIST_RESET,
  }),
  resetMovimento: () => ({
    type: Types.RESET_MOVIMENTO,
  }),

  getFinMov: (payload) => ({
    type: Types.GET_FIN_MOV,
    payload,
  }),
  getFinMovSuccess: (payload) => ({
    type: Types.GET_FIN_MOV_SUCCESS,
    payload,
  }),
  getFinMovError: () => ({
    type: Types.GET_FIN_MOV_ERROR,
  }),
  getFinMovReset: () => ({
    type: Types.GET_FIN_MOV_RESET,
  }),

  postFinOprd: (payload) => ({
    type: Types.POST_FIN_OPRD,
    payload,
  }),
  postFinOprdSuccess: () => ({
    type: Types.POST_FIN_OPRD_SUCCESS,
  }),
  postFinOprdError: () => ({
    type: Types.POST_FIN_OPRD_ERROR,
  }),
  postFinOprdReset: () => ({
    type: Types.POST_FIN_OPRD_RESET,
  }),

  postFinCheque: (payload) => ({
    type: Types.POST_FIN_CHEQUE,
    payload,
  }),
  postFinChequeSuccess: () => ({
    type: Types.POST_FIN_CHEQUE_SUCCESS,
  }),
  postFinChequeError: () => ({
    type: Types.POST_FIN_CHEQUE_ERROR,
  }),
  postFinChequeReset: () => ({
    type: Types.POST_FIN_CHEQUE_RESET,
  }),

  postFin: (payload) => ({
    type: Types.POST_FIN,
    payload,
  }),
  postFinSuccess: () => ({
    type: Types.POST_FIN_SUCCESS,
  }),
  postFinError: () => ({
    type: Types.POST_FIN_ERROR,
  }),
  postFinReset: () => ({
    type: Types.POST_FIN_RESET,
  }),

  postFinComp: (payload) => ({
    type: Types.POST_FIN_COMP,
    payload,
  }),
  postFinCompSuccess: () => ({
    type: Types.POST_FIN_COMP_SUCCESS,
  }),
  postFinCompError: () => ({
    type: Types.POST_FIN_COMP_ERROR,
  }),
  postFinCompReset: () => ({
    type: Types.POST_FIN_COMP_RESET,
  }),

  postConclusao: (payload) => ({
    type: Types.POST_CONCLUSAO,
    payload,
  }),
  postConclusaoSuccess: (payload) => ({
    type: Types.POST_CONCLUSAO_SUCCESS,
    payload,
  }),
  postConclusaoError: () => ({
    type: Types.POST_CONCLUSAO_ERROR,
  }),
  postConclusaoReset: () => ({
    type: Types.POST_CONCLUSAO_RESET,
  }),

  putFinForma: (payload) => ({
    type: Types.PUT_FIN_FORMA,
    payload,
  }),
  putFinFormaSuccess: () => ({
    type: Types.PUT_FIN_FORMA_SUCCESS,
  }),
  putFinFormaError: () => ({
    type: Types.PUT_FIN_FORMA_ERROR,
  }),
  putFinFormaReset: () => ({
    type: Types.PUT_FIN_FORMA_RESET,
  }),

  putFinConci: (payload) => ({
    type: Types.PUT_FIN_CONCI,
    payload,
  }),
  putFinConciSuccess: () => ({
    type: Types.PUT_FIN_CONCI_SUCCESS,
  }),
  putFinConciError: () => ({
    type: Types.PUT_FIN_CONCI_ERROR,
  }),
  putFinConciReset: () => ({
    type: Types.PUT_FIN_CONCI_RESET,
  }),

  putExportText: (payload) => ({
    type: Types.PUT_EXPORT_TEXT,
    payload,
  }),
  putExportTextSuccess: () => ({
    type: Types.PUT_EXPORT_TEXT_SUCCESS,
  }),
  putExportTextReset: () => ({
    type: Types.PUT_EXPORT_TEXT_RESET,
  }),

  getFinLancto: (payload) => ({
    type: Types.GET_FIN_LANCTO,
    payload,
  }),
  getFinLanctoSuccess: (payload) => ({
    type: Types.GET_FIN_LANCTO_SUCCESS,
    payload,
  }),
  getFinLanctoError: () => ({
    type: Types.GET_FIN_LANCTO_ERROR,
  }),

  postFinChequeDev: (payload) => ({
    type: Types.POST_FIN_CHEQUE_DEV,
    payload,
  }),
  postFinChequeDevSuccess: () => ({
    type: Types.POST_FIN_CHEQUE_DEV_SUCCESS,
  }),
  postFinChequeDevError: () => ({
    type: Types.POST_FIN_CHEQUE_DEV_ERROR,
  }),
  postFinChequeDevReset: () => ({
    type: Types.POST_FIN_CHEQUE_DEV_RESET,
  }),

  putCancelaMov: (payload) => ({
    type: Types.PUT_CANCELA_MOV,
    payload,
  }),
  putCancelaMovSuccess: () => ({
    type: Types.PUT_CANCELA_MOV_SUCCESS,
  }),
  putCancelaMovReset: () => ({
    type: Types.PUT_CANCELA_MOV_RESET,
  }),

  postCancelaVenda: (payload) => ({
    type: Types.POST_CANCELA_VENDA,
    payload,
  }),
  postCancelaVendaSuccess: () => ({
    type: Types.POST_CANCELA_VENDA_SUCCESS,
  }),
  postCancelaVendaReset: () => ({
    type: Types.POST_CANCELA_VENDA_RESET,
  }),

  postExcel: (payload) => ({
    type: Types.POST_EXCEL,
    payload,
  }),
  postExcelSuccess: (payload) => ({
    type: Types.POST_EXCEL_SUCCESS,
    payload,
  }),
  postExcelReset: () => ({
    type: Types.POST_EXCEL_RESET,
  }),

  postBaixaHaver: (payload) => ({
    type: Types.POST_BAIXA_HAVER,
    payload,
  }),
  postBaixaHaverSuccess: () => ({
    type: Types.POST_BAIXA_HAVER_SUCCESS,
  }),
  postBaixaHaverReset: () => ({
    type: Types.POST_BAIXA_HAVER_RESET,
  }),

  postCalendario: (payload) => ({
    type: Types.POST_CALENDARIO,
    payload,
  }),
  postCalendarioSuccess: () => ({
    type: Types.POST_CALENDARIO_SUCCESS,
  }),
  postCalendarioReset: () => ({
    type: Types.POST_CALENDARIO_RESET,
  }),

  postCalendarioMoeda: (payload) => ({
    type: Types.POST_CALENDARIO_MOEDA,
    payload,
  }),
  postCalendarioMoedaSuccess: () => ({
    type: Types.POST_CALENDARIO_MOEDA_SUCCESS,
  }),
  postCalendarioMoedaReset: () => ({
    type: Types.POST_CALENDARIO_MOEDA_RESET,
  }),

  getMovimento: (payload) => ({
    type: Types.GET_MOVIMENTO,
    payload,
  }),
  getMovimentoSuccess: (payload) => ({
    type: Types.GET_MOVIMENTO_SUCCESS,
    payload,
  }),
  getMovimentoReset: () => ({
    type: Types.GET_MOVIMENTO_RESET,
  }),

  getLancDetalhe: (payload) => ({
    type: Types.GET_LANC_DETALHE,
    payload,
  }),
  getLancDetalheSuccess: (payload) => ({
    type: Types.GET_LANC_DETALHE_SUCCESS,
    payload,
  }),
  getLancDetalheReset: () => ({
    type: Types.GET_LANC_DETALHE_RESET,
  }),

  importOfx: (payload) => ({
    type: Types.IMPORT_OFX,
    payload,
  }),
  importOfxSuccess: (payload) => ({
    type: Types.IMPORT_OFX_SUCCESS,
    payload,
  }),
  importOfxReset: () => ({
    type: Types.IMPORT_OFX_RESET,
  }),
};
