export const Types = {
  POST_ENTIDADE: 'POST_ENTIDADE',
  POST_ENTIDADE_SUCCESS: 'POST_ENTIDADE_SUCCESS',
  POST_ENTIDADE_ERROR: 'POST_ENTIDADE_ERROR',
  POST_ENTIDADE_RESET: 'POST_ENTIDADE_RESET',

  POST_CONTROLESWEB: 'POST_CONTROLESWEB',
  POST_CONTROLESWEB_SUCCESS: 'POST_CONTROLESWEB_SUCCESS',
  POST_CONTROLESWEB_ERROR: 'POST_CONTROLESWEB_ERROR',
  POST_CONTROLESWEB_RESET: 'POST_CONTROLESWEB_RESET',

  PUT_ENTIDADE: 'PUT_ENTIDADE',
  PUT_ENTIDADE_SUCCESS: 'PUT_ENTIDADE_SUCCESS',
  PUT_ENTIDADE_ERROR: 'PUT_ENTIDADE_ERROR',
  PUT_ENTIDADE_RESET: 'PUT_ENTIDADE_RESET',

  GET_ENTIDADE: 'GET_ENTIDADE',
  GET_ENTIDADE_SUCCESS: 'GET_ENTIDADE_SUCCESS',
  GET_ENTIDADE_ERROR: 'GET_ENTIDADE_ERROR',
  GET_ENTIDADE_RESET: 'GET_ENTIDADE_RESET',

  DELETE_ENTIDADE: 'DELETE_ENTIDADE',
  DELETE_ENTIDADE_SUCCESS: 'DELETE_ENTIDADE_SUCCESS',
  DELETE_ENTIDADE_ERROR: 'DELETE_ENTIDADE_ERROR',
  DELETE_ENTIDADE_RESET: 'DELETE_ENTIDADE_RESET',

  GET_ENTIDADE_MIDIAS: 'GET_ENTIDADE_MIDIAS',
  GET_ENTIDADE_MIDIAS_SUCCESS: 'GET_ENTIDADE_MIDIAS_SUCCESS',
  GET_ENTIDADE_MIDIAS_ERROR: 'GET_ENTIDADE_MIDIAS_ERROR',

  PUT_ENTIDADE_MIDIA: 'PUT_ENTIDADE_MIDIA',
  PUT_ENTIDADE_MIDIA_SUCCESS: 'PUT_ENTIDADE_MIDIA_SUCCESS',
  PUT_ENTIDADE_MIDIA_ERROR: 'PUT_ENTIDADE_MIDIA_ERROR',
  PUT_ENTIDADE_MIDIA_RESET: 'PUT_ENTIDADE_MIDIA_RESET',

  SET_STATUS_ENTIDADE: 'SET_STATUS_ENTIDADE',
  SET_STATUS_ENTIDADE_SUCCESS: 'SET_STATUS_ENTIDADE_SUCCESS',
  SET_STATUS_ENTIDADE_ERROR: 'SET_STATUS_ENTIDADE_ERROR',
  SET_STATUS_ENTIDADE_RESET: 'SET_STATUS_ENTIDADE_RESET',

  GET_IDENTIFICACAO: 'GET_IDENTIFICACAO',
  GET_IDENTIFICACAO_SUCCESS: 'GET_IDENTIFICACAO_SUCCESS',
  GET_IDENTIFICACAO_ERROR: 'GET_IDENTIFICACAO_ERROR',
  GET_IDENTIFICACAO_RESET: 'GET_IDENTIFICACAO_RESET',
};

const INIT_STATE = {
  postLoading: false,
  postSuccess: false,
  postError: false,
  entRegistrada: null,

  putLoading: false,
  putSuccess: false,
  putError: false,

  getLoading: false,
  getError: false,
  entidade: null,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  midiaLoading: false,
  midiaError: false,
  midia: [],

  putMidiaLoading: false,
  putMidiaSuccess: false,
  putMidiaError: false,

  setLoading: false,
  setSuccess: false,
  setError: false,

  identificacaoLoading: false,
  identificacao: null,
  identificacaoError: false,
};

export default function Entidades(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.POST_ENTIDADE: {
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
        postError: false,
        entRegistrada: null,
      };
    }
    case Types.POST_ENTIDADE_SUCCESS: {
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
        postError: false,
        entRegistrada: action.payload,
      };
    }
    case Types.POST_ENTIDADE_ERROR: {
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: true,
        entRegistrada: null,
      };
    }
    case Types.POST_ENTIDADE_RESET: {
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: false,
        entRegistrada: null,
      };
    }

    case Types.POST_CONTROLESWEB: {
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
        postError: false,
      };
    }
    case Types.POST_CONTROLESWEB_SUCCESS: {
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
        postError: false,
      };
    }
    case Types.POST_CONTROLESWEB_ERROR: {
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: true,
      };
    }
    case Types.POST_CONTROLESWEB_RESET: {
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: false,
      };
    }

    case Types.PUT_ENTIDADE: {
      return {
        ...state,
        putLoading: true,
        putSuccess: false,
        putError: false,
      };
    }
    case Types.PUT_ENTIDADE_SUCCESS: {
      return {
        ...state,
        putLoading: false,
        putSuccess: true,
        putError: false,
      };
    }
    case Types.PUT_ENTIDADE_ERROR: {
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: true,
      };
    }
    case Types.PUT_ENTIDADE_RESET: {
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: false,
      };
    }

    case Types.GET_ENTIDADE: {
      return {
        ...state,
        getLoading: true,
        getError: false,
        entidade: null,
      };
    }
    case Types.GET_ENTIDADE_SUCCESS: {
      return {
        ...state,
        getLoading: false,
        getError: false,
        entidade: action.payload,
      };
    }
    case Types.GET_ENTIDADE_ERROR: {
      return {
        ...state,
        getLoading: false,
        getError: true,
        entidade: null,
      };
    }
    case Types.GET_ENTIDADE_RESET: {
      return {
        ...state,
        getLoading: false,
        getError: false,
        entidade: null,
      };
    }

    case Types.DELETE_ENTIDADE: {
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      };
    }
    case Types.DELETE_ENTIDADE_SUCCESS: {
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      };
    }
    case Types.DELETE_ENTIDADE_ERROR: {
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
      };
    }
    case Types.DELETE_ENTIDADE_RESET: {
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
      };
    }

    case Types.GET_ENTIDADE_MIDIAS: {
      return {
        ...state,
        midiaLoading: true,
        midiaError: false,
        midia: [],
      };
    }
    case Types.GET_ENTIDADE_MIDIAS_SUCCESS: {
      return {
        ...state,
        midiaLoading: false,
        midiaError: false,
        midia: action.payload,
      };
    }
    case Types.GET_ENTIDADE_MIDIAS_ERROR: {
      return {
        ...state,
        midiaLoading: false,
        midiaError: true,
        midia: [],
      };
    }

    case Types.PUT_ENTIDADE_MIDIA: {
      return {
        ...state,
        putMidiaLoading: true,
        putMidiaSuccess: false,
        putMidiaError: false,
      };
    }
    case Types.PUT_ENTIDADE_MIDIA_SUCCESS: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: true,
        putMidiaError: false,
      };
    }
    case Types.PUT_ENTIDADE_MIDIA_ERROR: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: false,
        putMidiaError: true,
      };
    }
    case Types.PUT_ENTIDADE_MIDIA_RESET: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: false,
        putMidiaError: false,
      };
    }

    case Types.SET_STATUS_ENTIDADE: {
      return {
        ...state,
        setLoading: true,
        setSuccess: false,
        setError: false,
      };
    }
    case Types.SET_STATUS_ENTIDADE_SUCCESS: {
      return {
        ...state,
        setLoading: false,
        setSuccess: true,
        setError: false,
      };
    }
    case Types.SET_STATUS_ENTIDADE_ERROR: {
      return {
        ...state,
        setLoading: false,
        setSuccess: false,
        setError: true,
      };
    }
    case Types.SET_STATUS_ENTIDADE_RESET: {
      return {
        ...state,
        setLoading: false,
        setSuccess: false,
        setError: false,
      };
    }

    case Types.GET_IDENTIFICACAO: {
      return {
        ...state,
        identificacaoLoading: true,
        identificacao: null,
        identificacaoError: false,
      };
    }
    case Types.GET_IDENTIFICACAO_SUCCESS: {
      return {
        ...state,
        identificacaoLoading: false,
        identificacao: action.payload,
        identificacaoError: false,
      };
    }
    case Types.GET_IDENTIFICACAO_ERROR: {
      return {
        ...state,
        identificacaoLoading: false,
        identificacao: null,
        identificacaoError: true,
      };
    }
    case Types.GET_IDENTIFICACAO_RESET: {
      return {
        ...state,
        identificacaoLoading: false,
        identificacao: null,
        identificacaoError: false,
      };
    }
    default:
      return state;
  }
}

export const Creators = {
  postEntidade: (payload) => ({
    type: Types.POST_ENTIDADE,
    payload,
  }),
  postEntidadeSuccess: (payload) => ({
    type: Types.POST_ENTIDADE_SUCCESS,
    payload,
  }),
  postEntidadeError: () => ({
    type: Types.POST_ENTIDADE_ERROR,
  }),
  postEntidadeReset: () => ({
    type: Types.POST_ENTIDADE_RESET,
  }),

  atualizarControlesWeb: (payload) => ({
    type: Types.POST_CONTROLESWEB,
    payload,
  }),
  atualizarControlesWebSuccess: (payload) => ({
    type: Types.POST_CONTROLESWEB_SUCCESS,
    payload,
  }),
  atualizarControlesWebReset: () => ({
    type: Types.POST_CONTROLESWEB_RESET,
  }),
  atualizarControlesWebError: () => ({
    type: Types.POST_CONTROLESWEB_ERROR,
  }),

  putEntidade: (payload) => ({
    type: Types.PUT_ENTIDADE,
    payload,
  }),
  putEntidadeSuccess: () => ({
    type: Types.PUT_ENTIDADE_SUCCESS,
  }),
  putEntidadeError: () => ({
    type: Types.PUT_ENTIDADE_ERROR,
  }),
  putEntidadeReset: () => ({
    type: Types.PUT_ENTIDADE_RESET,
  }),

  getEntidade: (payload) => ({
    type: Types.GET_ENTIDADE,
    payload,
  }),
  getEntidadeSuccess: (payload) => ({
    type: Types.GET_ENTIDADE_SUCCESS,
    payload,
  }),
  getEntidadeError: () => ({
    type: Types.GET_ENTIDADE_ERROR,
  }),
  getEntidadeReset: () => ({
    type: Types.GET_ENTIDADE_RESET,
  }),

  deleteEntidade: (payload) => ({
    type: Types.DELETE_ENTIDADE,
    payload,
  }),
  deleteEntidadeSuccess: () => ({
    type: Types.DELETE_ENTIDADE_SUCCESS,
  }),
  deleteEntidadeError: () => ({
    type: Types.DELETE_ENTIDADE_ERROR,
  }),
  deleteEntidadeReset: () => ({
    type: Types.DELETE_ENTIDADE_RESET,
  }),

  getMidia: (payload) => ({
    type: Types.GET_ENTIDADE_MIDIAS,
    payload,
  }),
  getMidiaSuccess: (payload) => ({
    type: Types.GET_ENTIDADE_MIDIAS_SUCCESS,
    payload,
  }),
  getMidiaError: () => ({
    type: Types.GET_ENTIDADE_MIDIAS_ERROR,
  }),

  putMidia: (payload) => ({
    type: Types.PUT_ENTIDADE_MIDIA,
    payload,
  }),
  putMidiaSuccess: () => ({
    type: Types.PUT_ENTIDADE_MIDIA_SUCCESS,
  }),
  putMidiaError: () => ({
    type: Types.PUT_ENTIDADE_MIDIA_ERROR,
  }),
  putMidiaReset: () => ({
    type: Types.PUT_ENTIDADE_MIDIA_RESET,
  }),

  setStatusEntidade: (payload) => ({
    type: Types.SET_STATUS_ENTIDADE,
    payload,
  }),
  setStatusEntidadeSuccess: () => ({
    type: Types.SET_STATUS_ENTIDADE_SUCCESS,
  }),
  setStatusEntidadeError: () => ({
    type: Types.SET_STATUS_ENTIDADE_ERROR,
  }),
  setStatusEntidadeReset: () => ({
    type: Types.SET_STATUS_ENTIDADE_RESET,
  }),

  getIdentificacao: (payload) => ({
    type: Types.GET_IDENTIFICACAO,
    payload,
  }),
  getIdentificacaoSuccess: (payload) => ({
    type: Types.GET_IDENTIFICACAO_SUCCESS,
    payload,
  }),
  getIdentificacaoError: () => ({
    type: Types.GET_IDENTIFICACAO_ERROR,
  }),
  getIdentificacaoReset: () => ({
    type: Types.GET_IDENTIFICACAO_RESET,
  }),
};
