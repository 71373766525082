import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
    Creators as CalculoNSActions,
    Types as CalculoNSTypes,
} from 'store/ducks/CalculoNS';
import api from '../../util/api';

function* getCalc() {
    try {
        const { status, data } = yield call(api.post, `/csql/694`);
        if (status === 200 || status === 201) {
            yield put(CalculoNSActions.getCalcSuccess(data.dados[0]));
        } else {
            yield put(CalculoNSActions.getCalcError());
        }
    } catch (error) {
        yield put(CalculoNSActions.getCalcError());
    }
}

function* postCalc({ payload }) {
    try {
        const { status } = yield call(api.post, `/suprimentos-calcnec`, payload);
        if (status === 200 || status === 201) {
            yield put(CalculoNSActions.postCalcSuccess());
        } else {
            yield put(CalculoNSActions.postCalcError());
        }
    } catch (error) {
        yield put(CalculoNSActions.postCalcError());
    }
}

function* getCalcWatcher() {
    yield takeLatest(CalculoNSTypes.GET_CALC, getCalc);
}

function* postCalcWatcher() {
    yield takeLatest(CalculoNSTypes.POST_CALC, postCalc);
}

export default function* rootSaga() {
    yield all([fork(getCalcWatcher), fork(postCalcWatcher)]);
}
