export const Types = {
  POST_PRODUTO: 'POST_PRODUTO',
  POST_PRODUTO_SUCCESS: 'POST_PRODUTO_SUCCESS',
  POST_PRODUTO_ERROR: 'POST_PRODUTO_ERROR',
  POST_PRODUTO_RESET: 'POST_PRODUTO_RESET',

  PUT_PRODUTO: 'PUT_PRODUTO',
  PUT_PRODUTO_SUCCESS: 'PUT_PRODUTO_SUCCESS',
  PUT_PRODUTO_ERROR: 'PUT_PRODUTO_ERROR',
  PUT_PRODUTO_RESET: 'PUT_PRODUTO_RESET',

  GET_PRODUTO: 'GET_PRODUTO',
  GET_PRODUTO_SUCCESS: 'GET_PRODUTO_SUCCESS',
  GET_PRODUTO_ERROR: 'GET_PRODUTO_ERROR',
  GET_PRODUTO_RESET: 'GET_PRODUTO_RESET',

  DELETE_PRODUTO: 'DELETE_PRODUTO',
  DELETE_PRODUTO_SUCCESS: 'DELETE_PRODUTO_SUCCESS',
  DELETE_PRODUTO_ERROR: 'DELETE_PRODUTO_ERROR',
  DELETE_PRODUTO_RESET: 'DELETE_PRODUTO_RESET',

  SET_STATUS_PRODUTO: 'SET_STATUS_PRODUTO',
  SET_STATUS_PRODUTO_SUCCESS: 'SET_STATUS_PRODUTO_SUCCESS',
  SET_STATUS_PRODUTO_ERROR: 'SET_STATUS_PRODUTO_ERROR',
  SET_STATUS_PRODUTO_RESET: 'SET_STATUS_PRODUTO_RESET',

  GET_DESCRICAO: 'GET_DESCRICAO',
  GET_DESCRICAO_SUCCESS: 'GET_DESCRICAO_SUCCESS',
  GET_DESCRICAO_ERROR: 'GET_DESCRICAO_ERROR',

  PUT_DESCRICAO: 'PUT_DESCRICAO',
  PUT_DESCRICAO_SUCCESS: 'PUT_DESCRICAO_SUCCESS',
  PUT_DESCRICAO_ERROR: 'PUT_DESCRICAO_ERROR',
  PUT_DESCRICAO_RESET: 'PUT_DESCRICAO_RESET',

  GET_VARIACAO: 'GET_VARIACAO',
  GET_VARIACAO_SUCCESS: 'GET_VARIACAO_SUCCESS',
  GET_VARIACAO_ERROR: 'GET_VARIACAO_ERROR',

  PUT_VARIACAO: 'PUT_VARIACAO',
  PUT_VARIACAO_SUCCESS: 'PUT_VARIACAO_SUCCESS',
  PUT_VARIACAO_ERROR: 'PUT_VARIACAO_ERROR',
  PUT_VARIACAO_RESET: 'PUT_VARIACAO_RESET',

  GET_MIDIAS: 'GET_MIDIAS',
  GET_MIDIAS_SUCCESS: 'GET_MIDIAS_SUCCESS',
  GET_MIDIAS_ERROR: 'GET_MIDIAS_ERROR',

  PUT_MIDIA: 'PUT_MIDIA',
  PUT_MIDIA_SUCCESS: 'PUT_MIDIA_SUCCESS',
  PUT_MIDIA_ERROR: 'PUT_MIDIA_ERROR',
  PUT_MIDIA_RESET: 'PUT_MIDIA_RESET',

  /* Obter ator de conversáo entre Unidades de Medida */
  GET_CONVERSAOUM: 'GET_CONVERSAOUM',
  GET_CONVERSAOUM_SUCCESS: 'GET_CONVERSAOUM_SUCCESS',
  GET_CONVERSAOUM_ERROR: 'GET_CONVERSAOUM_ERROR',

  POST_COPIA: 'POST_COPIA',
  POST_COPIA_SUCCESS: 'POST_COPIA_SUCCESS',
  POST_COPIA_ERROR: 'POST_COPIA_ERROR',
  POST_COPIA_RESET: 'POST_COPIA_RESET',

  POST_CALCULA: 'POST_CALCULA',
  POST_CALCULA_SUCCESS: 'POST_CALCULA_SUCCESS',
  POST_CALCULA_ERROR: 'POST_CALCULA_ERROR',
  POST_CALCULA_RESET: 'POST_CALCULA_RESET',

  POST_REAJUSTE: 'POST_REAJUSTE',
  POST_REAJUSTE_SUCCESS: 'POST_REAJUSTE_SUCCESS',
  POST_REAJUSTE_ERROR: 'POST_REAJUSTE_ERROR',
  POST_REAJUSTE_RESET: 'POST_REAJUSTE_RESET',

  PUT_AJUSTA_VOLUME: 'PUT_AJUSTA_VOLUME',
  PUT_AJUSTA_VOLUME_SUCCESS: 'PUT_AJUSTA_VOLUME_SUCCESS',
  PUT_AJUSTA_VOLUME_RESET: 'PUT_AJUSTA_VOLUME_RESET',

  PUT_AJUSTA_DENSIDADE: 'PUT_AJUSTA_DENSIDADE',
  PUT_AJUSTA_DENSIDADE_SUCCESS: 'PUT_AJUSTA_DENSIDADE_SUCCESS',
  PUT_AJUSTA_DENSIDADE_RESET: 'PUT_AJUSTA_DENSIDADE_RESET',

  POST_ATT_CUSTO: 'POST_ATT_CUSTO',
  POST_ATT_CUSTO_SUCCESS: 'POST_ATT_CUSTO_SUCCESS',
  POST_ATT_CUSTO_RESET: 'POST_ATT_CUSTO_RESET',

  POST_ETIQUETA: 'POST_ETIQUETA',
  POST_ETIQUETA_SUCCESS: 'POST_ETIQUETA_SUCCESS',
  POST_ETIQUETA_RESET: 'POST_ETIQUETA_RESET',

  POST_MANUT_SEL: 'POST_MANUT_SEL',
  POST_MANUT_SEL_SUCCESS: 'POST_MANUT_SEL_SUCCESS',
  POST_MANUT_SEL_RESET: 'POST_MANUT_SEL_RESET',

  POST_REFERENCIA: 'POST_REFERENCIA',
  POST_REFERENCIA_SUCCESS: 'POST_REFERENCIA_SUCCESS',
  POST_REFERENCIA_RESET: 'POST_REFERENCIA_RESET',

  POST_PRECOS: 'POST_PRECOS',
  POST_PRECOS_SUCCESS: 'POST_PRECOS_SUCCESS',
  POST_PRECOS_RESET: 'POST_PRECOS_RESET',
};

const INIT_STATE = {
  postLoading: false,
  postSuccess: false,
  postError: false,

  putLoading: false,
  putSuccess: false,
  putError: false,

  getLoading: false,
  getError: false,
  produto: null,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  setLoading: false,
  setSuccess: false,
  setError: false,

  descLoading: false,
  descError: false,
  descricao: [],

  putDescLoading: false,
  putDescError: false,
  putDescSuccess: false,

  varLoading: false,
  varError: false,
  variacao: [],

  putVarLoading: false,
  putVarError: false,
  putVarSuccess: false,

  midiaLoading: false,
  midiaError: false,
  midia: [],

  putMidiaLoading: false,
  putMidiaSuccess: false,
  putMidiaError: false,

  conversaoumLoading: false,
  conversaoum: [],
  conversaoumError: false,

  postCopiaLoading: false,
  postCopiaSuccess: false,
  postCopiaError: false,

  postCalculaLoading: false,
  postCalculaSuccess: false,
  postCalculaError: false,

  postReajusteLoading: false,
  postReajusteSuccess: false,
  postReajusteError: false,

  putAjustaVolumeLoading: false,
  putAjustaVolumeSuccess: false,

  putAjustaDensidadeLoading: false,
  putAjustaDensidadeSuccess: false,

  postAttCustoLoading: false,
  postAttCustoSuccess: false,

  postEtiquetaLoading: false,
  postEtiquetaSuccess: false,

  postManutSelLoading: false,
  postManutSelSuccess: false,

  postReferenciaLoading: false,
  postReferenciaSuccess: false,

  postPrecosLoading: false,
  postPrecosSuccess: false,
};

export default function Produto(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.POST_PRODUTO: {
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
        postError: false,
      };
    }
    case Types.POST_PRODUTO_SUCCESS: {
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
        postError: false,
      };
    }
    case Types.POST_PRODUTO_ERROR: {
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: true,
      };
    }
    case Types.POST_PRODUTO_RESET: {
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: false,
      };
    }

    case Types.PUT_PRODUTO: {
      return {
        ...state,
        putLoading: true,
        putSuccess: false,
        putError: false,
      };
    }
    case Types.PUT_PRODUTO_SUCCESS: {
      return {
        ...state,
        putLoading: false,
        putSuccess: true,
        putError: false,
      };
    }
    case Types.PUT_PRODUTO_ERROR: {
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: true,
      };
    }
    case Types.PUT_PRODUTO_RESET: {
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: false,
      };
    }

    case Types.GET_PRODUTO: {
      return {
        ...state,
        getLoading: true,
        getError: false,
        produto: null,
      };
    }
    case Types.GET_PRODUTO_SUCCESS: {
      return {
        ...state,
        getLoading: false,
        getError: false,
        produto: action.payload,
      };
    }
    case Types.GET_PRODUTO_ERROR: {
      return {
        ...state,
        getLoading: false,
        getError: true,
        produto: null,
      };
    }
    case Types.GET_PRODUTO_RESET: {
      return {
        ...state,
        getLoading: false,
        getError: false,
        produto: null,
      };
    }

    case Types.DELETE_PRODUTO: {
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      };
    }
    case Types.DELETE_PRODUTO_SUCCESS: {
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      };
    }
    case Types.DELETE_PRODUTO_ERROR: {
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
      };
    }
    case Types.DELETE_PRODUTO_RESET: {
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
      };
    }

    case Types.SET_STATUS_PRODUTO: {
      return {
        ...state,
        setLoading: true,
        setSuccess: false,
        setError: false,
      };
    }
    case Types.SET_STATUS_PRODUTO_SUCCESS: {
      return {
        ...state,
        setLoading: false,
        setSuccess: true,
        setError: false,
      };
    }
    case Types.SET_STATUS_PRODUTO_ERROR: {
      return {
        ...state,
        setLoading: false,
        setSuccess: false,
        setError: true,
      };
    }
    case Types.SET_STATUS_PRODUTO_RESET: {
      return {
        ...state,
        setLoading: false,
        setSuccess: false,
        setError: false,
      };
    }

    case Types.GET_DESCRICAO: {
      return {
        ...state,
        descLoading: true,
        descError: false,
        descricao: [],
      };
    }
    case Types.GET_DESCRICAO_SUCCESS: {
      return {
        ...state,
        descLoading: false,
        descError: false,
        descricao: action.payload,
      };
    }
    case Types.GET_DESCRICAO_ERROR: {
      return {
        ...state,
        descLoading: false,
        descError: true,
        descricao: [],
      };
    }

    case Types.PUT_DESCRICAO: {
      return {
        ...state,
        putDescLoading: true,
        putDescError: false,
        putDescSuccess: false,
      };
    }
    case Types.PUT_DESCRICAO_SUCCESS: {
      return {
        ...state,
        putDescLoading: false,
        putDescError: false,
        putDescSuccess: true,
      };
    }
    case Types.PUT_DESCRICAO_ERROR: {
      return {
        ...state,
        putDescLoading: false,
        putDescError: true,
        putDescSuccess: false,
      };
    }
    case Types.PUT_DESCRICAO_RESET: {
      return {
        ...state,
        putDescLoading: false,
        putDescError: false,
        putDescSuccess: false,
      };
    }

    case Types.GET_VARIACAO: {
      return {
        ...state,
        varLoading: true,
        varError: false,
        variacao: [],
      };
    }
    case Types.GET_VARIACAO_SUCCESS: {
      return {
        ...state,
        varLoading: false,
        varError: false,
        variacao: action.payload,
      };
    }
    case Types.GET_VARIACAO_ERROR: {
      return {
        ...state,
        varLoading: false,
        varError: true,
        variacao: [],
      };
    }

    case Types.PUT_VARIACAO: {
      return {
        ...state,
        putVarLoading: true,
        putVarError: false,
        putVarSuccess: false,
      };
    }
    case Types.PUT_VARIACAO_SUCCESS: {
      return {
        ...state,
        putVarLoading: false,
        putVarError: false,
        putVarSuccess: true,
      };
    }
    case Types.PUT_VARIACAO_ERROR: {
      return {
        ...state,
        putVarLoading: false,
        putVarError: true,
        putVarSuccess: false,
      };
    }
    case Types.PUT_VARIACAO_RESET: {
      return {
        ...state,
        putVarLoading: false,
        putVarError: false,
        putVarSuccess: false,
      };
    }

    case Types.GET_MIDIAS: {
      return {
        ...state,
        midiaLoading: true,
        midiaError: false,
        midia: [],
      };
    }
    case Types.GET_MIDIAS_SUCCESS: {
      return {
        ...state,
        midiaLoading: false,
        midiaError: false,
        midia: action.payload,
      };
    }
    case Types.GET_MIDIAS_ERROR: {
      return {
        ...state,
        midiaLoading: false,
        midiaError: true,
        midia: [],
      };
    }

    case Types.PUT_MIDIA: {
      return {
        ...state,
        putMidiaLoading: true,
        putMidiaSuccess: false,
        putMidiaError: false,
      };
    }
    case Types.PUT_MIDIA_SUCCESS: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: true,
        putMidiaError: false,
      };
    }
    case Types.PUT_MIDIA_ERROR: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: false,
        putMidiaError: true,
      };
    }
    case Types.PUT_MIDIA_RESET: {
      return {
        ...state,
        putMidiaLoading: false,
        putMidiaSuccess: false,
        putMidiaError: false,
      };
    }

    case Types.GET_CONVERSAOUM: {
      return {
        ...state,
        conversaoumLoading: true,
        conversaoum: [],
        conversaoumError: false,
      };
    }
    case Types.GET_CONVERSAOUM_SUCCESS: {
      return {
        ...state,
        conversaoumLoading: false,
        conversaoum: action.payload,
        conversaoumError: false,
      };
    }
    case Types.GET_CONVERSAOUM_ERROR: {
      return {
        ...state,
        conversaoumLoading: false,
        conversaoum: [],
        conversaoumError: true,
      };
    }
    case Types.GET_CONVERSAOUM_RESET: {
      return {
        ...state,
        conversaoumLoading: false,
        conversaoum: [],
        conversaoumError: false,
      };
    }

    case Types.POST_COPIA: {
      return {
        ...state,
        postCopiaLoading: true,
        postCopiaSuccess: false,
        postCopiaError: false,
      };
    }
    case Types.POST_COPIA_SUCCESS: {
      return {
        ...state,
        postCopiaLoading: false,
        postCopiaSuccess: true,
        postCopiaError: false,
      };
    }
    case Types.POST_COPIA_ERROR: {
      return {
        ...state,
        postCopiaLoading: false,
        postCopiaSuccess: false,
        postCopiaError: true,
      };
    }
    case Types.POST_COPIA_RESET: {
      return {
        ...state,
        postCopiaLoading: false,
        postCopiaSuccess: false,
        postCopiaError: false,
      };
    }

    case Types.POST_CALCULA: {
      return {
        ...state,
        postCalculaLoading: true,
        postCalculaSuccess: false,
        postCalculaError: false,
      };
    }
    case Types.POST_CALCULA_SUCCESS: {
      return {
        ...state,
        postCalculaLoading: false,
        postCalculaSuccess: true,
        postCalculaError: false,
      };
    }
    case Types.POST_CALCULA_ERROR: {
      return {
        ...state,
        postCalculaLoading: false,
        postCalculaSuccess: false,
        postCalculaError: true,
      };
    }
    case Types.POST_CALCULA_RESET: {
      return {
        ...state,
        postCalculaLoading: true,
        postCalculaSuccess: false,
        postCalculaError: false,
      };
    }

    case Types.POST_REAJUSTE: {
      return {
        ...state,
        postReajusteLoading: true,
        postReajusteSuccess: false,
        postReajusteError: false,
      };
    }
    case Types.POST_REAJUSTE_SUCCESS: {
      return {
        ...state,
        postReajusteLoading: false,
        postReajusteSuccess: true,
        postReajusteError: false,
      };
    }
    case Types.POST_REAJUSTE_ERROR: {
      return {
        ...state,
        postReajusteLoading: false,
        postReajusteSuccess: false,
        postReajusteError: true,
      };
    }
    case Types.POST_REAJUSTE_RESET: {
      return {
        ...state,
        postReajusteLoading: true,
        postReajusteSuccess: false,
        postReajusteError: false,
      };
    }

    case Types.PUT_AJUSTA_VOLUME: {
      return {
        ...state,
        putAjustaVolumeLoading: true,
        putAjustaVolumeSuccess: false,
      };
    }
    case Types.PUT_AJUSTA_VOLUME_SUCCESS: {
      return {
        ...state,
        putAjustaVolumeLoading: false,
        putAjustaVolumeSuccess: true,
      };
    }
    case Types.PUT_AJUSTA_VOLUME_RESET: {
      return {
        ...state,
        putAjustaVolumeLoading: false,
        putAjustaVolumeSuccess: false,
      };
    }

    case Types.PUT_AJUSTA_DENSIDADE: {
      return {
        ...state,
        putAjustaDensidadeLoading: true,
        putAjustaDensidadeSuccess: false,
      };
    }
    case Types.PUT_AJUSTA_DENSIDADE_SUCCESS: {
      return {
        ...state,
        putAjustaDensidadeLoading: false,
        putAjustaDensidadeSuccess: true,
      };
    }
    case Types.PUT_AJUSTA_DENSIDADE_RESET: {
      return {
        ...state,
        putAjustaDensidadeLoading: false,
        putAjustaDensidadeSuccess: false,
      };
    }

    case Types.POST_ATT_CUSTO: {
      return {
        ...state,
        postAttCustoLoading: true,
        postAttCustoSuccess: false,
      };
    }
    case Types.POST_ATT_CUSTO_SUCCESS: {
      return {
        ...state,
        postAttCustoLoading: false,
        postAttCustoSuccess: true,
      };
    }
    case Types.POST_ATT_CUSTO_RESET: {
      return {
        ...state,
        postAttCustoLoading: false,
        postAttCustoSuccess: false,
      };
    }

    case Types.POST_ETIQUETA: {
      return {
        ...state,
        postEtiquetaLoading: true,
        postEtiquetaSuccess: false,
      };
    }
    case Types.POST_ETIQUETA_SUCCESS: {
      return {
        ...state,
        postEtiquetaLoading: false,
        postEtiquetaSuccess: true,
      };
    }
    case Types.POST_ETIQUETA_RESET: {
      return {
        ...state,
        postEtiquetaLoading: false,
        postEtiquetaSuccess: false,
      };
    }

    case Types.POST_MANUT_SEL: {
      return {
        ...state,
        postManutSelLoading: true,
        postManutSelSuccess: false,
      };
    }
    case Types.POST_MANUT_SEL_SUCCESS: {
      return {
        ...state,
        postManutSelLoading: false,
        postManutSelSuccess: true,
      };
    }
    case Types.POST_MANUT_SEL_RESET: {
      return {
        ...state,
        postManutSelLoading: false,
        postManutSelSuccess: false,
      };
    }

    case Types.POST_REFERENCIA: {
      return {
        ...state,
        postReferenciaLoading: true,
        postReferenciaSuccess: false,
      };
    }
    case Types.POST_REFERENCIA_SUCCESS: {
      return {
        ...state,
        postReferenciaLoading: false,
        postReferenciaSuccess: true,
      };
    }
    case Types.POST_REFERENCIA_RESET: {
      return {
        ...state,
        postReferenciaLoading: false,
        postReferenciaSuccess: false,
      };
    }

    case Types.POST_PRECOS: {
      return {
        ...state,
        postPrecosLoading: true,
        postPrecosSuccess: false,
      };
    }
    case Types.POST_PRECOS_SUCCESS: {
      return {
        ...state,
        postPrecosLoading: false,
        postPrecosSuccess: true,
      };
    }
    case Types.POST_PRECOS_RESET: {
      return {
        ...state,
        postPrecosLoading: false,
        postPrecosSuccess: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  postProduto: (payload) => ({
    type: Types.POST_PRODUTO,
    payload,
  }),
  postProdutoSuccess: () => ({
    type: Types.POST_PRODUTO_SUCCESS,
  }),
  postProdutoError: () => ({
    type: Types.POST_PRODUTO_ERROR,
  }),
  postProdutoReset: () => ({
    type: Types.POST_PRODUTO_RESET,
  }),

  putProduto: (payload) => ({
    type: Types.PUT_PRODUTO,
    payload,
  }),
  putProdutoSuccess: () => ({
    type: Types.PUT_PRODUTO_SUCCESS,
  }),
  putProdutoError: () => ({
    type: Types.PUT_PRODUTO_ERROR,
  }),
  putProdutoReset: () => ({
    type: Types.PUT_PRODUTO_RESET,
  }),

  getProduto: (payload) => ({
    type: Types.GET_PRODUTO,
    payload,
  }),
  getProdutoSuccess: (payload) => ({
    type: Types.GET_PRODUTO_SUCCESS,
    payload,
  }),
  getProdutoError: () => ({
    type: Types.GET_PRODUTO_ERROR,
  }),
  getProdutoReset: () => ({
    type: Types.GET_PRODUTO_RESET,
  }),

  deleteProduto: (payload) => ({
    type: Types.DELETE_PRODUTO,
    payload,
  }),
  deleteProdutoSuccess: () => ({
    type: Types.DELETE_PRODUTO_SUCCESS,
  }),
  deleteProdutoError: () => ({
    type: Types.DELETE_PRODUTO_ERROR,
  }),
  deleteProdutoReset: () => ({
    type: Types.DELETE_PRODUTO_RESET,
  }),

  setStatusProduto: (payload) => ({
    type: Types.SET_STATUS_PRODUTO,
    payload,
  }),
  setStatusProdutoSuccess: () => ({
    type: Types.SET_STATUS_PRODUTO_SUCCESS,
  }),
  setStatusProdutoError: () => ({
    type: Types.SET_STATUS_PRODUTO_ERROR,
  }),
  setStatusProdutoReset: () => ({
    type: Types.SET_STATUS_PRODUTO_RESET,
  }),

  getDescricao: (payload) => ({
    type: Types.GET_DESCRICAO,
    payload,
  }),
  getDescricaoSuccess: (payload) => ({
    type: Types.GET_DESCRICAO_SUCCESS,
    payload,
  }),
  getDescricaoError: () => ({
    type: Types.GET_DESCRICAO_ERROR,
  }),

  putDescricao: (payload) => ({
    type: Types.PUT_DESCRICAO,
    payload,
  }),
  putDescricaoSuccess: () => ({
    type: Types.PUT_DESCRICAO_SUCCESS,
  }),
  putDescricaoError: () => ({
    type: Types.PUT_DESCRICAO_ERROR,
  }),
  putDescricaoReset: () => ({
    type: Types.PUT_DESCRICAO_RESET,
  }),

  getVariacao: (payload) => ({
    type: Types.GET_VARIACAO,
    payload,
  }),
  getVariacaoSuccess: (payload) => ({
    type: Types.GET_VARIACAO_SUCCESS,
    payload,
  }),
  getVariacaoError: () => ({
    type: Types.GET_VARIACAO_ERROR,
  }),

  putVariacao: (payload) => ({
    type: Types.PUT_VARIACAO,
    payload,
  }),
  putVariacaoSuccess: () => ({
    type: Types.PUT_VARIACAO_SUCCESS,
  }),
  putVariacaoError: () => ({
    type: Types.PUT_VARIACAO_ERROR,
  }),
  putVariacaoReset: () => ({
    type: Types.PUT_VARIACAO_RESET,
  }),

  getMidia: (payload) => ({
    type: Types.GET_MIDIAS,
    payload,
  }),
  getMidiaSuccess: (payload) => ({
    type: Types.GET_MIDIAS_SUCCESS,
    payload,
  }),
  getMidiaError: () => ({
    type: Types.GET_MIDIAS_ERROR,
  }),

  putMidia: (payload) => ({
    type: Types.PUT_MIDIA,
    payload,
  }),
  putMidiaSuccess: () => ({
    type: Types.PUT_MIDIA_SUCCESS,
  }),
  putMidiaError: () => ({
    type: Types.PUT_MIDIA_ERROR,
  }),
  putMidiaReset: () => ({
    type: Types.PUT_MIDIA_RESET,
  }),

  getConversaoum: (payload) => ({
    type: Types.GET_CONVERSAOUM,
    payload,
  }),
  getConversaoumSuccess: (payload) => ({
    type: Types.GET_CONVERSAOUM_SUCCESS,
    payload,
  }),
  getCnversaoumError: () => ({
    type: Types.GET_CONVERSAOUM_ERROR,
  }),

  postCopia: (payload) => ({
    type: Types.POST_COPIA,
    payload,
  }),
  postCopiaSuccess: () => ({
    type: Types.POST_COPIA_SUCCESS,
  }),
  postCopiaError: () => ({
    type: Types.POST_COPIA_ERROR,
  }),
  postCopiaReset: () => ({
    type: Types.POST_COPIA_RESET,
  }),

  postCalcula: (payload) => ({
    type: Types.POST_CALCULA,
    payload,
  }),
  postCalculaSuccess: () => ({
    type: Types.POST_CALCULA_SUCCESS,
  }),
  postCalculaError: () => ({
    type: Types.POST_CALCULA_ERROR,
  }),
  postCalculaReset: () => ({
    type: Types.POST_CALCULA_RESET,
  }),

  postReajuste: (payload) => ({
    type: Types.POST_REAJUSTE,
    payload,
  }),
  postReajusteSuccess: () => ({
    type: Types.POST_REAJUSTE_SUCCESS,
  }),
  postReajusteError: () => ({
    type: Types.POST_REAJUSTE_ERROR,
  }),
  postReajusteReset: () => ({
    type: Types.POST_REAJUSTE_RESET,
  }),

  putAjustaVolume: (payload) => ({
    type: Types.PUT_AJUSTA_VOLUME,
    payload,
  }),
  putAjustaVolumeSuccess: () => ({
    type: Types.PUT_AJUSTA_VOLUME_SUCCESS,
  }),
  putAjustaVolumeReset: () => ({
    type: Types.PUT_AJUSTA_VOLUME_RESET,
  }),

  putAjustaDensidade: (payload) => ({
    type: Types.PUT_AJUSTA_DENSIDADE,
    payload,
  }),
  putAjustaDensidadeSuccess: () => ({
    type: Types.PUT_AJUSTA_DENSIDADE_SUCCESS,
  }),
  putAjustaDensidadeReset: () => ({
    type: Types.PUT_AJUSTA_DENSIDADE_RESET,
  }),

  postAttCusto: (payload) => ({
    type: Types.POST_ATT_CUSTO,
    payload,
  }),
  postAttCustoSuccess: () => ({
    type: Types.POST_ATT_CUSTO_SUCCESS,
  }),
  postAttCustoReset: () => ({
    type: Types.POST_ATT_CUSTO_RESET,
  }),

  postEtiqueta: (payload) => ({
    type: Types.POST_ETIQUETA,
    payload,
  }),
  postEtiquetaSuccess: () => ({
    type: Types.POST_ETIQUETA_SUCCESS,
  }),
  postEtiquetaReset: () => ({
    type: Types.POST_ETIQUETA_RESET,
  }),

  postManutSel: (payload) => ({
    type: Types.POST_MANUT_SEL,
    payload,
  }),
  postManutSelSuccess: () => ({
    type: Types.POST_MANUT_SEL_SUCCESS,
  }),
  postManutSelReset: () => ({
    type: Types.POST_MANUT_SEL_RESET,
  }),

  postReferencia: (payload) => ({
    type: Types.POST_REFERENCIA,
    payload,
  }),
  postReferenciaSuccess: () => ({
    type: Types.POST_REFERENCIA_SUCCESS,
  }),
  postReferenciaReset: () => ({
    type: Types.POST_REFERENCIA_RESET,
  }),

  postPrecos: (payload) => ({
    type: Types.POST_PRECOS,
    payload,
  }),
  postPrecosSuccess: () => ({
    type: Types.POST_PRECOS_SUCCESS,
  }),
  postPrecosReset: () => ({
    type: Types.POST_PRECOS_RESET,
  }),
};
