import React from 'react';
import Lock from '@material-ui/icons/Lock';
import Edit from '@material-ui/icons/Edit';
import Build from '@material-ui/icons/Build';
import PlayArrow from '@material-ui/icons/PlayArrow';
import ViewModule from '@material-ui/icons/ViewModule';

export const dataGuia = [
  {
    modulo: 'Acesso ao Sistema',
    icone: <Lock color="primary" />,
    children: [
      {
        modulo: 'Acessar',
        children: [
          {
            modulo: 'Como entrar no sistema e redefinir a senha',
            link: 'https://player.vimeo.com/video/625492776?h=870d6d1e85',
            redirect: '/app',
          },
        ],
      },
    ],
  },
  {
    modulo: 'Cadastros Básicos',
    icone: <Edit color="primary" />,
    children: [
      {
        modulo: 'Usuários',
        children: [
          {
            modulo: 'Como cadastrar os usuários que irão utilizar o sistema',
            link: 'https://player.vimeo.com/video/625493708?h=223fe450d7',
            redirect: '/app/Cadastros/Entidades',
          },
        ],
      },
      {
        modulo: 'Entidades',
        children: [
          {
            modulo:
              'Itens importantes para serem usados nos cadastros de pessoas',
            children: [
              {
                modulo: 'Como cadastrar UF',
                link: 'https://player.vimeo.com/video/625493210?h=a8dbba6166',
                redirect: '/app/Tabela?title=UF&rot=13&csql=73',
              },
              {
                modulo: 'Como cadastrar cidades',
                link: 'https://player.vimeo.com/video/625493210?h=a8dbba6166',
                redirect: '/app/Tabela?title=Cidades&rot=4&csql=69',
              },
              {
                modulo: 'Como definir tipos de vínculos que as pessoas terão',
                link: 'https://player.vimeo.com/video/625493302?h=1e89b465c8',
                redirect:
                  '/app/Tabela?title=Tipo%20de%20vinculo&rot=12&csql=72',
              },
            ],
          },
          {
            modulo: 'Como cadastrar uma pessoa física',
            link: 'https://player.vimeo.com/video/625492965?h=0170f28f8f',
            redirect: '/app/Cadastros/Entidades/Manutenção',
          },
          {
            modulo: 'Como cadastrar uma pessoa jurídica',
            link: 'https://player.vimeo.com/video/625493095?h=1540037e75',
            redirect: '/app/Cadastros/Entidades/Manutenção',
          },
          {
            modulo:
              'Como cadastrar uma instituição financeira (Banco, por exemplo)',
            link: 'https://player.vimeo.com/video/625492826?h=33e1c4de5f',
            redirect: '/app/Cadastros/Entidades/Manutenção',
          },
          {
            modulo: 'Como cadastrar vendedores e representantes',
            link: 'https://player.vimeo.com/video/625493379?h=8eae302f04',
            redirect: '/app/Cadastros/Entidades/Manutenção',
          },
        ],
      },
      {
        modulo: 'Produtos',
        children: [
          {
            modulo:
              'Itens importantes para serem usados nos cadastros de produtos',
            children: [
              {
                modulo: 'Como dar manutenção nas classificações fiscais',
                link: 'https://player.vimeo.com/video/625773582?h=5d398ee135',
                redirect:
                  '/app/Tabela?title=NCM%20(Classificação%20fiscal)&rot=316&csql=161',
              },
              {
                modulo: 'Como criar e dar manutenção em grupos de produtos',
                link: 'https://player.vimeo.com/video/625790399?h=32789b5695',
                redirect:
                  '/app/Tabela?title=Grupo%20de%20produtos&rot=314&csql=159',
              },
              {
                modulo: 'Como criar e dar manutenção em linhas de produtos',
                link: 'https://player.vimeo.com/video/625815572?h=f488e875f7',
                redirect: 'Tabela?title=Linha%20de%20produtos&rot=315&csql=160',
              },
              {
                modulo: 'Como criar e dar manutenção em unidades de medidas',
                link: 'https://player.vimeo.com/video/626410092?h=f87cf99e3c',
                redirect:
                  '/app/Tabela?title=Unidade%20de%20Medida&rot=213&csql=99',
              },
            ],
          },
          {
            modulo: 'Produtos',
            link: 'https://player.vimeo.com/video/625493512?h=a5979fe396',
            redirect: '/app/Cadastros/Produto/Manutenção',
          },
        ],
      },
    ],
  },
  {
    modulo: 'Como começar usar o sistema',
    icone: <Build color="primary" />,
    children: [
      {
        modulo: 'Itens importantes para começar usar o sistema',
        children: [
          {
            modulo:
              'Como cadastrar e dar manutenção nas condições de pagamentos que serão usadas nas compras, nas vendas e nas baixas do financeiro',
            link: 'https://player.vimeo.com/video/630975128?h=ba95395742',
            redirect:
              '/app/Tabela?title=Condição%20de%20pagamento&rot=492&csql=374',
          },
          {
            modulo:
              'Como cadastrar e dar manutenção nas formas de pagamentos (similar às carteiras de pagamentos e de recebimentos) que serão usadas nas compras e nas vendas',
            link: 'https://player.vimeo.com/video/630979103?h=fb16a90557',
            redirect: '/app/Financeiro/Formadepagamento',
          },
          {
            modulo:
              'Como colocar o preço base nos produtos para começar as vendas',
            link: 'https://player.vimeo.com/video/630981876?h=bf625b8c4b',
            redirect: '/app/Cadastros/Produto',
          },
          {
            modulo:
              'Como definir os Cfop que serão utilizados nos faturamentos de vendas, e nas inclusões de notas fiscais de compras',
            link: 'https://player.vimeo.com/video/630986296?h=7d5b56c409',
            redirect: '/app/Fiscal/RegrasCfos',
          },
          {
            modulo:
              'Como cadastrar e dar manutenção nos centros de custos, para utilização nas notas fiscais de compras e de despesas, quando for o caso',
            link: 'https://player.vimeo.com/video/632236449?h=c030b40b11',
            redirect: '/app/Suprimentos/CentrosdeCustos',
          },
        ],
      },
      {
        modulo: 'Fazer entradas de compras e despesas',
        children: [
          {
            modulo:
              'Como incluir um documento de entrada de compras ou despesas, gerando movimentações de estoques, contas a pagar, custos etc',
            link: 'https://player.vimeo.com/video/626520622?h=64759f5a88',
            redirect: '/app/Suprimentos/NotasRecebidas',
          },
        ],
      },
      {
        modulo: 'Fazer pedidos de vendas e faturamentos',
        children: [
          {
            modulo: 'Como digitar nota fiscal de venda',
            link: 'https://player.vimeo.com/video/635591533?h=3766107ef1',
            redirect: '/app/Comercial/NotasEmitidas',
          },
          {
            modulo: 'Como digitar pedidos de vendas',
            link: 'https://player.vimeo.com/video/632241902?h=929d02e549',
            redirect: '/app/Comercial/Comercialização',
          },
          {
            modulo: 'Como faturar pedidos de vendas',
            link: 'https://player.vimeo.com/video/635588625?h=03e997e73d',
            redirect: '/app/Comercial/Comercialização',
          },
          {
            modulo: 'Como finalizar e transmitir um faturamento',
            link: 'https://player.vimeo.com/video/632248397?h=bd435afc72',
            redirect: '/app/Comercial/NotasEmitidas',
          },
        ],
      },
      {
        modulo: 'Fazer pagamentos, recebimentos e lançamentos financeiros',
        children: [
          {
            modulo:
              'Itens importantes para trabalhar com o financeiro do sistema',
            children: [
              {
                modulo:
                  'Como cadastrar contas correntes para fazer a movimentação financeira (conta caixa ou conta bancária)',
                link: 'https://player.vimeo.com/video/632865988?h=3d05d33167',
                redirect: '/app/Financeiro/CaixaeBancos',
              },
              {
                modulo:
                  'Como definir a conta contábil automática para lançamentos na conta quando baixar do contas a receber',
                link: 'https://player.vimeo.com/video/632867914?h=f0090866d2',
                redirect: '/app/Financeiro/Formadepagamento',
              },
            ],
          },
          {
            modulo:
              'Como baixar do contas a pagar e do contas a receber (fazer o recebimento e fazer o pagamento)',
            link: 'https://player.vimeo.com/video/632302235?h=6943fa692c',
            redirect: '/app/Financeiro/Pendências',
          },
          {
            modulo: 'Como fazer o estorno de um pagamento ou recebimento',
            link: 'https://player.vimeo.com/video/632304123?h=bd6a03bef8',
            redirect: '/app/Financeiro/Pendências',
          },
          {
            modulo:
              'Como fazer lançamentos de valores na conta corrente ou caixa',
            link: 'https://player.vimeo.com/video/632305399?h=394778931a',
            redirect: '/app/Financeiro/CaixaeBancos',
          },
        ],
      },
    ],
  },
  {
    modulo: 'Padrões e recursos do sistema',
    icone: <PlayArrow color="primary" />,
    children: [
      {
        modulo: 'Documentos',
        children: [
          {
            modulo:
              'Como fazer um cadastro de pessoas diretamente pela tela de documentos',
            link: 'https://player.vimeo.com/video/635603815?h=e0671fba6b',
            redirect: '/app/Cadastros/Documento',
          },
          {
            modulo: 'Como adicionar itens à um documento',
            link: 'https://player.vimeo.com/video/635599945?h=cfa48211c6',
            redirect: '/app/Cadastros/Documento',
          },
          {
            modulo:
              'Como aplicar um desconto, ou colocar frete, ou outro tipo de valor em todos os itens do documento de uma só vez',
            link: 'https://player.vimeo.com/video/635594906?h=f61127d41f',
            redirect: '/app/Cadastros/Documento',
          },
          {
            modulo:
              'Como atualizar os preços e os impostos nos itens de um documento',
            link: 'https://player.vimeo.com/video/637962940?h=7f0148bcb4',
            redirect: '/app/Cadastros/Documento',
          },
          {
            modulo:
              'Como consultar a disponibilidade de estoque do produto, e o histórico de vendas deste produto à partir dos itens do documento',
            link: 'https://player.vimeo.com/video/635598257?h=0e90fa9ea6',
            redirect: '/app/Cadastros/Documento',
          },
          {
            modulo:
              'Como aplicar um desconto ou colocar frete ou outro valor, em um item específico do documento',
            link: 'https://player.vimeo.com/video/635601870?h=61a77d430d',
            redirect: '/app/Cadastros/Documento',
          },
        ],
      },
    ],
  },
  {
    modulo: 'Módulos do sistema',
    icone: <ViewModule color="primary" />,
    children: [
      {
        modulo: 'Suprimentos',
        children: [
          {
            modulo: 'Notas Recebidas',
            children: [
              {
                modulo:
                  'Itens importantes para o uso dos recursos das telas do módulo de suprimentos',
                children: [
                  {
                    modulo:
                      'Como cadastrar e dar manutenção nas condições de pagamentos para uso nos documentos de compras',
                    link:
                      'https://player.vimeo.com/video/630975128?h=ba95395742',
                    redirect:
                      '/app/Tabela?title=Condição%20de%20pagamento&rot=492&csql=374',
                  },
                  {
                    modulo:
                      'Como cadastrar e dar manutenção nas formas de pagamentos (equivalentes às carteiras de pagamentos), para serem usadas nos documentos de compras',
                    link:
                      'https://player.vimeo.com/video/630979103?h=fb16a90557',
                    redirect: '/app/Financeiro/Formadepagamento',
                  },
                  {
                    modulo:
                      'Como definir o Cfop que será usado nos documentos de compras e de despesas',
                    link:
                      'https://player.vimeo.com/video/630986296?h=7d5b56c409',
                    redirect: '/app/Fiscal/RegrasCfos',
                  },
                  {
                    modulo:
                      'Como cadastrar e dar manutenção nos centros de custos para serem usados nos documentos de compras e de despesas, quando for o caso',
                    link:
                      'https://player.vimeo.com/video/632236449?h=c030b40b11',
                    redirect: '/app/Suprimentos/CentrosdeCustos',
                  },
                ],
              },
              {
                modulo: 'Como digitar um documento de compra ou despesa',
                link: 'https://player.vimeo.com/video/626520622?h=64759f5a88',
                redirect: '/app/Suprimentos/NotasRecebidas',
              },
            ],
          },
          {
            modulo: 'Inventário',
            children: [
              {
                modulo:
                  'Como digitar o inventário (contagem de estoques) para ajustar o saldo de estoques dos produtos no sistema',
                link: 'https://player.vimeo.com/video/650084349?h=0a9a9bd741',
                redirect: '/app/Suprimentos/Inventário',
              },
            ],
          },
          {
            modulo: 'Movimentação de estoques',
            children: [
              {
                modulo:
                  'Como fazer a movimentação de entradas e saídas no estoque, digitando manualmente a movimentação',
                link: 'https://player.vimeo.com/video/650084431?h=f1adaa167b',
                redirect: '/app/Suprimentos/MovimentaçãodeEstoque',
              },
            ],
          },
        ],
      },
      {
        modulo: 'Comercial',
        children: [
          {
            modulo: 'Comercialização',
            children: [
              {
                modulo:
                  'Itens importantes para o uso dos recursos das telas da comercialização',
                children: [
                  {
                    modulo:
                      'Como cadastrar e dar manutenção nas condições de pagamentos que serão utilizadas nas telas do comercial',
                    link:
                      'https://player.vimeo.com/video/630975128?h=ba95395742',
                    redirect:
                      '/app/Tabela?title=Condição%20de%20pagamento&rot=492&csql=374',
                  },
                  {
                    modulo:
                      'Como cadastrar e dar manutenção nas formas de pagamentos (equivalente às carteiras de cobrança), para serem utilizadas nas telas do comercial',
                    link:
                      'https://player.vimeo.com/video/630979103?h=fb16a90557',
                    redirect: '/app/Financeiro/Formadepagamento',
                  },
                  {
                    modulo:
                      'Como cadastrar preços básicos nos produtos para utilizar nos documentos de vendas do comercial',
                    link:
                      'https://player.vimeo.com/video/630981876?h=bf625b8c4b',
                    redirect: '/app/Cadastros/Produto',
                  },
                  {
                    modulo:
                      'Como definir o Cfop que deverá ir automaticamente para o documento de venda',
                    link:
                      'https://player.vimeo.com/video/630986296?h=7d5b56c409',
                    redirect: '/app/Fiscal/RegrasCfos',
                  },
                ],
              },
              {
                modulo: 'Como digitar um pedido de venda',
                link: 'https://player.vimeo.com/video/632241902?h=929d02e549',
                redirect: '/app/Comercial/Comercialização',
              },
              {
                modulo:
                  'Como fazer o faturamento a partir de um pedido de venda',
                link: 'https://player.vimeo.com/video/635588625?h=03e997e73d',
                redirect: '/app/Comercial/Comercialização',
              },
            ],
          },
          {
            modulo: 'Notas Emitidas',
            children: [
              {
                modulo: 'Como digitar uma nota fiscal de venda',
                link: 'https://player.vimeo.com/video/635591533?h=3766107ef1',
                redirect: '/app/Comercial/NotasEmitidas',
              },
              {
                modulo:
                  'Como fazer o faturamento a partir de um pedido de venda',
                link: 'https://player.vimeo.com/video/635588625?h=03e997e73d',
                redirect: '/app/Comercial/Comercialização',
              },
              {
                modulo: 'Como finalizar e transmitir um faturamento',
                link: 'https://player.vimeo.com/video/632248397?h=bd435afc72',
                redirect: '/app/Comercial/NotasEmitidas',
              },
              {
                modulo: 'Como fazer um faturamento de importação',
                children: [
                  {
                    modulo:
                      'Parte 01 – Como cadastrar o fornecedor estrangeiro para fazer o faturamento de importação',
                    link:
                      'https://player.vimeo.com/video/649471255?h=78fb704947',
                    redirect: '/app/Tabela?title=Países&rot=183&csql=80',
                  },
                  {
                    modulo:
                      'Parte 02 – Como fazer algumas configurações quando você for fazer a primeira importação no sistema',
                    link:
                      'https://player.vimeo.com/video/650084190?h=a1107aa27d',
                    redirect:
                      '/app/Tabela?title=Natureza%20da%20Operação&rot=370&csql=242',
                  },
                  {
                    modulo:
                      'Parte 03 – Como digitar e finalizar a nota fiscal de importação',
                    link:
                      'https://player.vimeo.com/video/650084271?h=16dc627ffd',
                    redirect: '/app/Comercial/NotasEmitidas',
                  },
                ],
              },
              {
                modulo: 'Tela de Notas Emitidas',
                children: [
                  {
                    modulo:
                      'Exportar Xml/Danfe: Como exportar (extrair) Xml/Danfe das notas fiscais emitidas em um período',
                    link:
                      'https://player.vimeo.com/video/659676696?h=2f56073821',
                    redirect: '/app/Comercial/NotasEmitidas',
                  },
                  {
                    modulo:
                      'Emissão do documento: Como finalizar e transmitir um faturamento',
                    link:
                      'https://player.vimeo.com/video/632248397?h=bd435afc72',
                    redirect: '/app/Comercial/NotasEmitidas',
                  },
                ],
              },
            ],
          },
          {
            modulo: 'Atendimentos',
            children: [
              {
                modulo: 'Como digitar uma venda pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/647848742?h=def7230a18',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda aplicando descontos ou acréscimos, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/647849902?h=09f7d1aeb9',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda gerando troco ao cliente, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/649470919?h=35fa4cf271',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda recebendo com cheques do cliente ou de terceiros, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/647851821?h=e19bcbcd9f',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda recebendo com cartão de crédito, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/649470971?h=e8b87e6cac',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda recebendo com cartão de débito, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/649471131?h=bd950f6c10',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda a prazo, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/647851022?h=90b7d230f5',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda utilizando um haver que o cliente tinha para abater no valor, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/649471204?h=ec306cac44',
                redirect: '/app/Comercial/Atendimento',
              },
              {
                modulo:
                  'Como fazer o fechamento de uma venda recebendo um valor maior do cliente, e por isto, gerando haver para este cliente, pela tela de atendimentos',
                link: 'https://player.vimeo.com/video/647853088?h=74b6c8cf6a',
                redirect: '/app/Comercial/Atendimento',
              },
            ],
          },
        ],
      },
      {
        modulo: 'Financeiro',
        children: [
          {
            modulo: 'Pendências (Contas a pagar e Contas a receber)',
            children: [
              {
                modulo:
                  'Itens importantes para a utilização dos recursos do módulo financeiro',
                children: [
                  {
                    modulo:
                      'Como cadastrar uma conta corrente ou conta caixa, para utilização na movimentação financeira',
                    link:
                      'https://player.vimeo.com/video/632865988?h=3d05d33167',
                    redirect: '/app/Financeiro/CaixaeBancos',
                  },
                  {
                    modulo:
                      'Como definir a conta contábil que será utilizada automaticamente nas movimentações financeiras quando for feita a baixa de títulos do contas a receber',
                    link:
                      'https://player.vimeo.com/video/632867914?h=f0090866d2',
                    redirect: '/app/Financeiro/Formadepagamento',
                  },
                  {
                    modulo:
                      'Como cadastrar uma entidade do tipo "Instituição Financeira" (Banco), para ser utilizada na inclusão de cheques do cliente ou de terceiros nos recebimentos do contas a receber',
                    link:
                      'https://player.vimeo.com/video/625492826?h=33e1c4de5f',
                    redirect: '/app/Cadastros/Entidades/Manutenção',
                  },
                ],
              },
              {
                modulo:
                  'Como fazer o pagamento ou recebimento movimentando a conta caixa ou conta corrente',
                link: 'https://player.vimeo.com/video/632302235?h=6943fa692c',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como fazer o pagamento ou recebimento parcial de um título',
                link: 'https://player.vimeo.com/video/656993173?h=3d12e7c1b0',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como fazer a baixa do Contas a Pagar compensando com o Contas a Receber da mesma entidade (encontro de contas)',
                link: 'https://player.vimeo.com/video/656993266?h=bb3fbe1c91',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como fazer composição de títulos (juntar títulos ou redividir)',
                link: 'https://player.vimeo.com/video/656993338?h=1648e0a3e3',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como fazer o pagamento de compromissos utilizando cheques de terceiros recebidos de clientes',
                link: 'https://player.vimeo.com/video/656993405?h=b9133046aa',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como fazer o pagamento de compromissos usando cheques próprios pré-datados',
                link: 'https://player.vimeo.com/video/647904543?h=966e988c9d',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como fazer o recebimento de títulos com cartão de débito do cliente',
                link: 'https://player.vimeo.com/video/656993464?h=f1ecd20634',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como fazer o recebimento de títulos, em dinheiro, gerando troco ao cliente',
                link: 'https://player.vimeo.com/video/656993123?h=5b5e7bd366',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo:
                  'Como estornar uma baixa feita no contas a pagar ou no contas a receber',
                link: 'https://player.vimeo.com/video/632304123?h=bd6a03bef8',
                redirect: '/app/Financeiro/Pendências',
              },
              {
                modulo: 'Tela de Pendências',
                children: [
                  {
                    modulo: 'Opções',
                    children: [
                      {
                        modulo:
                          'Como filtrar os dados na tela de pendências para alternar entre o contas a pagar, contas a receber, controle de cheques, títulos em aberto e títulos baixados',
                        link:
                          'https://player.vimeo.com/video/656993039?h=fb26f281dd',
                        redirect: '/app/Financeiro/Pendências',
                      },
                    ],
                  },
                  {
                    modulo:
                      'Alterar Dados: Como alterar o vencimento, forma de pagamento de observação dos títulos do contas a pagar ou do contas a receber',
                    link:
                      'https://player.vimeo.com/video/656993514?h=fd1646503a',
                    redirect: '/app/Financeiro/Pendências',
                  },
                  {
                    modulo:
                      'Contatos: Como registrar o histórico de contatos com o cliente para cobrança ou outra ocorrência referente ao título',
                    link:
                      'https://player.vimeo.com/video/659676327?h=8ac1843ea3',
                    redirect: '/app/Financeiro/Pendências',
                  },
                  {
                    modulo:
                      'Documento: Como consultar o documento que deu origem ao título, seja ele do contas a pagar ou do contas a receber',
                    link:
                      'https://player.vimeo.com/video/659676353?h=8a3f99269c',
                    redirect: '/app/Financeiro/Pendências',
                  },
                  {
                    modulo:
                      'Emissão de boletos: Como fazer a emissão de boletos bancários de títulos do contas a receber, pela tela de pendências',
                    link:
                      'https://player.vimeo.com/video/659676396?h=0a9ccc5576',
                    redirect: '/app/Financeiro/Pendências',
                  },
                  {
                    modulo:
                      'Movimento: Como consultar a movimentação financeira relacionada ao título, seja do contas a pagar ou do contas a receber',
                    link:
                      'https://player.vimeo.com/video/659676476?h=c709e922bc',
                    redirect: '/app/Financeiro/Pendências',
                  },
                  {
                    modulo:
                      'Outros valores: Como adicionar valores ao título, tais como juros, descontos, acréscimos, taxas etc',
                    link:
                      'https://player.vimeo.com/video/659676524?h=044609bb4b',
                    redirect: '/app/Financeiro/Pendências',
                  },
                  {
                    modulo:
                      'Remessa bancária: Como gerar o arquivo de remessa bancária para registro de títulos do contas a receber e o banco fazer a cobrança',
                    link:
                      'https://player.vimeo.com/video/659676440?h=a02772a101',
                    redirect: '/app/Financeiro/Pendências',
                  },
                  {
                    modulo: 'Relatórios',
                    children: [
                      {
                        modulo:
                          'Extrato de Débito: Como emitir relatório que demonstra os títulos baixados de um cliente ou fornecedor, e os títulos ainda em aberto deste',
                        link:
                          'https://player.vimeo.com/video/656993081?h=6d9c2ce464',
                        redirect: '/app/Financeiro/Pendências',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            modulo: 'Caixa e Bancos',
            children: [
              {
                modulo:
                  'Itens importantes para a utilização dos recursos das telas do controle de caixa e bancos (contas correntes)',
                children: [
                  {
                    modulo:
                      'Como cadastrar uma conta corrente ou conta caixa para utilização na movimentação financeira',
                    link:
                      'https://player.vimeo.com/video/632865988?h=3d05d33167',
                    redirect: '/app/Financeiro/CaixaeBancos',
                  },
                ],
              },
              {
                modulo:
                  'Como fazer lançamento de valores à débito ou à crédito diretamente na tela da conta ou do caixa',
                link: 'https://player.vimeo.com/video/632305399?h=394778931a',
                redirect: '/app/Financeiro/CaixaeBancos',
              },
              {
                modulo: 'Tela de Caixa e Bancos',
                children: [
                  {
                    modulo:
                      'Conciliar Lançamentos: Como fazer a conciliação (conferência/ajuste) dos lançamentos feitos na conta correte ou no caixa',
                    link:
                      'https://player.vimeo.com/video/656992872?h=83891d3317',
                    redirect: '/app/Financeiro/CaixaeBancos',
                  },
                  {
                    modulo:
                      'Duplicar: Como fazer a cópia de uma conta corrente para gerar uma conta nova com dados parecidos',
                    link:
                      'https://player.vimeo.com/video/656992941?h=1e60f2e2a4',
                    redirect: '/app/Financeiro/CaixaeBancos',
                  },
                  {
                    modulo:
                      'Lançamento: Como visualizar e dar manutenção nos lançamentos feitos em uma conta corrente ou caixa',
                    link:
                      'https://player.vimeo.com/video/656992991?h=dde9cdd8a3',
                    redirect: '/app/Financeiro/CaixaeBancos',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        modulo: 'Cadastros',
        children: [
          {
            modulo: 'Entidades (pessoas físicas ou jurídicas)',
            children: [
              {
                modulo:
                  'Itens importantes para utilização nos cadastros de pessoas',
                children: [
                  {
                    modulo: 'Como cadastrar e dar manutenção nas UFs',
                    link:
                      'https://player.vimeo.com/video/625493210?h=a8dbba6166',
                    redirect: '/app/Tabela?title=UF&rot=13&csql=73',
                  },
                  {
                    modulo: 'Como cadastrar e dar manutenção nas cidades',
                    link:
                      'https://player.vimeo.com/video/625493210?h=a8dbba6166',
                    redirect: '/app/Tabela?title=Cidades&rot=4&csql=69',
                  },
                  {
                    modulo:
                      'Como definir tipos de vínculos que as pessoas terão',
                    link:
                      'https://player.vimeo.com/video/625493302?h=1e89b465c8',
                    redirect:
                      '/app/Tabela?title=Tipo%20de%20vinculo&rot=12&csql=72',
                  },
                ],
              },
              {
                modulo:
                  'Pessoa Física: Como cadastrar uma entidade pessoa física',
                link: 'https://player.vimeo.com/video/625492965?h=0170f28f8f',
                redirect: '/app/Cadastros/Entidades/Manutenção',
              },
              {
                modulo:
                  'Pessoa Jurídica: Como cadastrar uma entidade pessoa jurídica',
                link: 'https://player.vimeo.com/video/625493095?h=1540037e75',
                redirect: '/app/Cadastros/Entidades/Manutenção',
              },
              {
                modulo:
                  'Instituições Financeiras: Como cadastrar uma instituição financeira, como por exemplo, banco, operadora de cartão de crédito, factoring etc',
                link: 'https://player.vimeo.com/video/625492826?h=33e1c4de5f',
                redirect: '/app/Cadastros/Entidades/Manutenção',
              },
              {
                modulo:
                  'Vendedores e Representantes: Como cadastrar pessoa física ou jurídica com perfil e permissão de vendedor ou de representante comercial',
                link: 'https://player.vimeo.com/video/625493379?h=8eae302f04',
                redirect: '/app/Cadastros/Entidades/Manutenção',
              },
              {
                modulo:
                  'Usuários: Como fazer o cadastro de pessoa física que terá permissão e acesso para uso do sistema e seus recursos',
                link: 'https://player.vimeo.com/video/625493708?h=223fe450d7',
                redirect: '/app/Cadastros/Entidades/Manutenção',
              },
              {
                modulo: 'Tela de Entidades',
                children: [
                  {
                    modulo:
                      'Como ativar, inativar e o que são cadastros pendentes',
                    link:
                      'https://player.vimeo.com/video/659676800?h=d733b06673',
                    redirect: '/app/Cadastros/Entidades',
                  },
                  {
                    modulo:
                      'Aprovação: Como fazer limites de créditos, bloquear um cliente e configurar para que ele passe por aprovação',
                    link:
                      'https://player.vimeo.com/video/659676739?h=1beaeff07b',
                    redirect: '/app/Cadastros/Entidades',
                  },
                  {
                    modulo:
                      'Comissão: Como definir uma regra de comissionamento específico para um cliente',
                    link:
                      'https://player.vimeo.com/video/659676269?h=e4cacd83a8',
                    redirect: '/app/Cadastros/Entidades',
                  },
                ],
              },
            ],
          },
          {
            modulo: 'Produtos',
            children: [
              {
                modulo: 'Definições',
                children: [
                  {
                    modulo:
                      'Classificação Fiscal: Como trabalhar e dar manutenção na tabela de classificação fiscal (Ncm) para usar no cadastro do produto',
                    link:
                      'https://player.vimeo.com/video/625773582?h=5d398ee135',
                    redirect:
                      '/app/Tabela?title=NCM%20(Classificação%20fiscal)&rot=316&csql=161',
                  },
                  {
                    modulo:
                      'Grupos: Como incluir e dar manutenção nos grupos de produtos para usar nos cadastros de produtos',
                    link:
                      'https://player.vimeo.com/video/625790399?h=32789b5695',
                    redirect:
                      '/app/Tabela?title=Grupo%20de%20produtos&rot=314&csql=159',
                  },
                  {
                    modulo:
                      'Linhas: Como incluir e dar manutenção nas linhas de produtos para usar nos cadastros de produtos',
                    link:
                      'https://player.vimeo.com/video/625815572?h=f488e875f7',
                    redirect:
                      '/app/Tabela?title=Linha%20de%20produtos&rot=315&csql=160',
                  },
                  {
                    modulo:
                      'Unidades de Medida: Como incluir e dar manutenção nas unidades de medidas para usar nos cadastros de produtos',
                    link:
                      'https://player.vimeo.com/video/626410092?h=f87cf99e3c',
                    redirect:
                      '/app/Tabela?title=Unidade%20de%20Medida&rot=213&csql=99',
                  },
                ],
              },
              {
                modulo: 'Produtos',
                link: 'https://player.vimeo.com/video/625493512?h=a5979fe396',
                redirect: '/app/Cadastros/Produto/Manutenção',
              },
              {
                modulo: 'Tela de Produtos',
                children: [
                  {
                    modulo: 'Estruturas',
                    children: [
                      {
                        modulo:
                          'Cadastrar estruturas: Como cadastrar estruturas de produtos fabricados (componentes e matérias primas que são utilizados na produção)',
                        link:
                          'https://player.vimeo.com/video/659676571?h=4e5550fab2',
                        redirect: '/app/Cadastros/Produto',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
