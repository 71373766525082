import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Creators as AuthActions } from '../store/ducks/Auth';
import { isAuth } from 'util/index';
import { useLocation } from 'react-router-dom'

const SignIn = () => {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const cnpjLogin = '43381636000133'; //'00424336000181'
  const cnpjGetParm = searchParams.get('cnpj')
  const cnpjGet=(cnpjGetParm !==null ? cnpjGetParm : process.env.NODE_ENV === 'production' ? '' : cnpjLogin )
  const dispatch = useDispatch();
  const [cnpj, setCnpj] = useState(cnpjGet);
  const [email, setEmail] = useState(process.env.NODE_ENV === 'production' ? '' : 'desenvolvimento@eprom.com.br');
  const [password, setPassword] = useState(process.env.NODE_ENV === 'production' ? '' : '&proMMorp&');
  const { authUserLoading, authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (authUser) {
      window.location.reload();
    }
  }, [authUser]);

  const onSubmit = (event) => {
    if (event && event?.key === 'Enter') {
      dispatch(AuthActions.login({ cnpj, email, password }));
    }
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div
          className="app-logo-content d-flex align-items-center justify-content-center"
          style={{ backgroundColor: '#c5c5c5' }}
        >
          <Link className="logo-lg" to="/" title="Logo">
            <img
              src={require('assets/images/eprom-logo-3d.png')}
              alt="Eprom Informática"
              title="Eprom Informática"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>Entrar</h1>
          </div>

          <div className="app-login-form">
            <form
              onSubmit={() =>
                dispatch(AuthActions.login({ cnpj, email, password }))
              }
            >
              <TextField
                label="Código de Acesso"
                fullWidth
                onChange={(event) => setCnpj(event.target.value)}
                defaultValue={cnpj}
                margin="normal"
                className="mt-1 my-sm-3"
                hidden={ cnpjGetParm!==null }
                onKeyPress={onSubmit}
              />
              <TextField
                label="E-mail"
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                className="mt-1 my-sm-3"
                onKeyPress={onSubmit}
              />
              <TextField
                type="password"
                label="Senha"
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
                defaultValue={password}
                margin="normal"
                className="mt-1 my-sm-3"
                onKeyPress={onSubmit}
              />

              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button
                  onClick={() =>
                    dispatch(AuthActions.login({ cnpj, email, password }))
                  }
                  variant="contained"
                  color="primary"
                  disabled={authUserLoading}
                >
                  Entrar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
