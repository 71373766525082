import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import reducers from './ducks';
import rootSagas from './sagas';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

let persistedState = {};
const settings = localStorage.getItem('EpromApp:settings') || {};
const filterState = localStorage.getItem('EpromApp:filterState') || {};
persistedState = { ...settings, ...filterState };

export default function configureStore() {
  const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSagas);

  store.subscribe(() => {
    const { settings, filterState } = store.getState();
    localStorage.setItem('EpromApp:settings', JSON.stringify({ settings }));
    localStorage.setItem(
      'EpromApp:filterState',
      JSON.stringify({ filterState })
    );
  });

  return store;
}
export { history };
