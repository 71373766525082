import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { TablePagination } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WarningIcon from '@material-ui/icons/Warning';
import TableItemOptions from 'components/TableItemOptions';
import Loading from 'components/Loading';
import FilterDescription from 'components/FilterDescription';
import { setDecimal, transformData } from 'util/index';

const CustomCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 0,
    paddingRight: 5,
    paddingLeft: 5,
  },
  body: {
    padding: 0,
    paddingRight: 5,
    paddingLeft: 5,
  },
}))(TableCell);

const formats = (header) => {
  const formatos = [];
  header.map((f) => {
    f.itens.map((i) => {
      if (i.restricao !== 'INVISIVEL') {
        formatos.push({ coluna: i.coluna, formato: i.formato, tipo: i.tipo });
      }
    });
  });
  return formatos;
};

const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

const actionIcon = {
  Editar: <EditIcon color="action" style={{ paddingRight: '5px' }} />,
  Deletar: <DeleteIcon color="action" style={{ paddingRight: '5px' }} />,
};

const CustomTable = ({
  headerData,
  data,
  onOrder,
  actions,
  selected,
  setSelected,
  Pagina,
  setPagina,
  PorPagina,
  setPorPagina,
  stringExport,
  fill,
}) => {
  const [rpp, setRpp] = useState(10);
  const [pg, setPg] = useState(0);
  const [orderItem, setOrderItem] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);
  const [menuOpcoes, setMenuOpcoes] = useState({ open: false });
  const { dataLoading } = useSelector(({ tables }) => tables);
  const hasPageParams = Pagina && setPagina && PorPagina && setPorPagina;

  useEffect(() => {
    if (orderBy !== null) {
      const expressao =
        orderItem.expressao !== '' && orderItem.expressao !== null
          ? `${orderItem.expressao}`
          : `${orderItem.alias}.${
              orderItem.campo === '' ? orderItem.coluna : orderItem.campo
            }`;
      onOrder(`${expressao} ${order}`);
    }
  }, [order, orderBy]);

  useEffect(() => {
    if (!hasPageParams) {
      setPg(0);
    }
  }, [data]);

  function onSelectAll() {
    if (equals(selected, data)) {
      setSelected([]);
    } else {
      setSelected(data);
    }
  }

  function onOrderPress(item) {
    if (item.coluna === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      setOrderBy(item.coluna);
      setOrder('asc');
    }
    setOrderItem(item);
  }

  function setValue(col, val) {
    const obj = formats(headerData).filter((f) => f.coluna === col)[0];
    if (val === null) {
      return '-';
    }
    if (obj?.formato === ',0.00') {
      return setDecimal(Number(val).toFixed(2), 2);
    }
    if (obj?.formato === ',0.##') {
      const parseVal = parseFloat(Number(val).toFixed(2))
        .toString()
        .split('.');
      if (parseVal[1]) {
        return setDecimal(
          parseFloat(Number(val).toFixed(2)),
          parseVal[1].length
        );
      }
      return parseFloat(Number(val).toFixed(2));
    }
    if (obj?.formato === ',0.###') {
      const parseVal = parseFloat(Number(val).toFixed(3))
        .toString()
        .split('.');
      if (parseVal[1]) {
        return setDecimal(
          parseFloat(Number(val).toFixed(3)),
          parseVal[1].length
        );
      }
      return parseFloat(Number(val).toFixed(3));
    }
    if (obj?.formato === ',0.####') {
      const parseVal = parseFloat(Number(val).toFixed(4))
        .toString()
        .split('.');
      if (parseVal[1]) {
        return setDecimal(
          parseFloat(Number(val).toFixed(4)),
          parseVal[1].length
        );
      }
      return parseFloat(Number(val).toFixed(4));
    }
    if (obj?.formato === ',0.######') {
      const parseVal = parseFloat(Number(val).toFixed(6))
        .toString()
        .split('.');
      if (parseVal[1]) {
        return setDecimal(
          parseFloat(Number(val).toFixed(6)),
          parseVal[1].length
        );
      }
      return parseFloat(Number(val).toFixed(6));
    }
    if (obj?.formato === ',0.00####') {
      const parseVal = parseFloat(Number(val).toFixed(6))
        .toString()
        .split('.');
      if (parseVal[1] && parseVal[1].length > 1) {
        return setDecimal(
          parseFloat(Number(val).toFixed(6)),
          parseVal[1].length
        );
      }
      return setDecimal(Number(val).toFixed(6), 2);
    }
    if (obj?.tipo === 'DATA') {
      if (obj?.formato === 'dd/mm/yyyy hh:mm:ss')
        return moment(val).format('DD/MM/YYYY HH:mm:ss');
      else return moment(val).format('DD/MM/YYYY');
    }
    return val;
  }

  const setFill = (item) => {
    if (item && fill === 'PENDENCIAS') {
      const finder = item?.find((f) => f[0] === 'dtvenc');
      if (finder) {
        const expired = moment(finder[1], 'YYYY-MM-DD').isBefore(
          moment().format('YYYY-MM-DD')
        );
        if (!!expired) {
          return { color: 'red' };
        }
      }
    }
    if (item && fill === 'CAIXAEBANCO') {
      const finder = item?.find((f) => f[0] === 'saldo');
      if (finder && finder[1] < 0) {
        return { color: 'red' };
      }
    }
    if (item && fill === 'LANCAMENTOS') {
      const finder = item?.find((f) => f[0] === 'valortot');
      if (finder && finder[1] < 0) {
        return { color: 'red' };
      }
    }
    return {};
  };

  function setColor(item, type) {
    if (type === 'Produto') {
      if (item?.ativo === 'SIM') {
        return 'green';
      }
      if (item?.ativo === 'PENDENTE') {
        return 'ORANGE';
      }
      if (item?.ativo === 'NAO') {
        return 'red';
      }
    }
    if (type === 'Vencimento') {
      if (item?.legenda === 'VENCIDO') {
        return 'red';
      }
      if (item?.legenda === 'A VENCER EM ATÉ 3 DIAS') {
        return 'orange';
      }
    }
    if (type === 'Producao') {
      if (item?.situacao === 'FINALIZADAS' || item?.situacao === 'CONCLUIDAS') {
        return 'red';
      }
      if (item?.qtapont) {
        return 'green';
      }
      if (
        (item?.analisa !== '' || item?.aprova !== '') &&
        item?.aprovado !== 'SIM'
      ) {
        return 'blue';
      }
      if (item?.aprovado === 'SIM') {
        return 'black';
      }
    }
    if (type === 'Aprovacao') {
      if (item?.aprovacao > 0) {
        return 'orange';
      }
    }
    if (type === 'Documento') {
      if (item?.status === 'CIÊNCIA DA OPERAÇÃO') {
        return 'blue';
      }
      if (item?.status === 'DESACORDO') {
        return 'red';
      }
      if (item?.status === 'CONFIRMAÇÃO DA OPERAÇÃO') {
        return 'green';
      }
      if (item?.status === 'OPERAÇÃO NÃO REALIZADA') {
        return 'orange';
      }
      if (item?.status === 'DESCONHECIMENTO DA OPERAÇÃO') {
        return 'yellow';
      }

    }
    if (type === 'Notas') {
      if (item?.situacao === 'CANCELADOS') {
        return 'red';
      }
      if (item?.situacao === 'REGISTRAR' && item?.aprovacao > 0) {
        return 'orange';
      }

      if (item?.carta === 'SIM') {
        return 'blue';
      }
      if (item?.stsdoc === 'CONCLUIDO') {
        return 'green';
      }
      if (item?.nfe_situacao === 103 || item?.nfe_situacao === 105) {
        return 'pink';
      }
      if (item?.nfe_situacao === 99) {
        return 'brown';
      }
    }
    if (type === 'GNRE') {
      if (item?.csituacao === 1 || item?.csituacao === 2) {
        return 'red';
      }
      if (item?.csituacao === 4) {
        return 'orange';
      }
      if (item?.csituacao === 0) {
        return 'green';
      }
    }
    if (type === 'GNRE Ambiente') {
      if (item?.wsambiente === 'HOMOLOGACAO') {
        return 'red';
      }
    }
    if (type === 'Plano de Contas') {
      if (item?.operacional === 'SIM') {
        return 'blue';
      }
    }
    if (type === 'Gestão de Serviço') {
      if (item?.execucao !== 0) {
        return 'green';
      }
    }

    return 'transparent';
  }

  function renderHeader() {
    return (
      <TableHead>
        <TableRow>
          <CustomCell>
            {selected && (
              <Checkbox
                style={{ padding: '0 5px' }}
                color="default"
                checked={equals(selected, data)}
                onChange={() => onSelectAll()}
              />
            )}
          </CustomCell>
          {headerData?.map((h) => {
            let itens = h.itens.filter((j) => j.restricao !== 'INVISIVEL');
            return itens.map((s) => {
              return (
                <CustomCell
                  key={s.id}
                  style={{
                    minWidth: `${s.tamanho * 0.6}em`,
                    maxWidth: `${s.tamanho * 0.6}em`,
                  }}
                  align={s.tipo === 'NUMERO' ? 'right' : 'left'}
                >
                  <TableSortLabel
                    active={orderBy === s.coluna}
                    direction={order}
                    onClick={() => onOrderPress(s)}
                  >
                    {h.grupo !== '' && h.grupo}
                    {h.grupo !== '' && <br />}
                    {s.titulo}
                  </TableSortLabel>
                </CustomCell>
              );
            });
          })}
        </TableRow>
      </TableHead>
    );
  }

  function renderBody() {
    let parseData = hasPageParams ? data : data.slice(pg * rpp, pg * rpp + rpp);
    return (
      <TableBody>
        {parseData.map((d, index) => {
          const parseData = Object.entries(d);
          const style = setFill(parseData);
          return (
            <TableRow hover selected={selected?.includes(d)}>
              <CustomCell>
                <div className="d-flex align-items-center">
                  {selected && (
                    <Checkbox
                      style={{ padding: '0 5px' }}
                      color="primary"
                      checked={selected?.includes(d)}
                      onChange={() => {
                        if (selected?.includes(d)) {
                          setSelected(selected?.filter((i) => d !== i));
                        } else {
                          setSelected([...selected, d]);
                        }
                      }}
                    />
                  )}
                  {actions?.map(
                    ({ name, action, visible, items, statScreen }) => {
                      if (visible) {
                        if (name === 'Menu') {
                          return (
                            <TableItemOptions
                              menuOpcoes={menuOpcoes}
                              setMenuOpcoes={setMenuOpcoes}
                              data={d}
                              items={items}
                              index={index}
                            />
                          );
                        }
                        if ( name === 'Details' ){
                          return (
                          <WarningIcon
                                style={{
                                  paddingRight: '5px',
                                  color: setColor(d, statScreen),
                                }}
                          />
                          );
                        }
                        return (
                          <div onClick={() => action(d)}>
                            {name === 'Status' ? (
                              <FiberManualRecordIcon
                                style={{
                                  paddingRight: '5px',
                                  color: setColor(d, statScreen),
                                }}
                              />
                            ) : (
                              <>{actionIcon[name]}</>
                            )}
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              </CustomCell>
              {formats(headerData).map((m) => {
                const src = parseData.find((f) => f[0] === m.coluna);
                if (src) {
                  return (
                    <CustomCell
                      align={m?.tipo === 'NUMERO' ? 'right' : 'left'}
                      style={style}
                    >
                      {setValue(src[0], src[1])}
                    </CustomCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  function renderFooter() {
    let maxPage =
      (data.length / rpp) % 1 === 0
        ? data.length / rpp - 1
        : parseInt(data.length / rpp);
    return (
      <Grid container>
        {stringExport && (
          <Grid
            xs="12"
            sm="12"
            md="6"
            lg="6"
            className="d-flex align-items-center"
          >
            <FilterDescription text={stringExport} />
          </Grid>
        )}
        <Grid
          xs="12"
          sm="12"
          md={stringExport ? '6' : '12'}
          lg={stringExport ? '6' : '12'}
        >
          <TablePagination
            className="d-flex w-100 justify-content-end"
            style={{ borderBottom: 0 }}
            count={hasPageParams ? undefined : data.length}
            labelRowsPerPage="Registros por página"
            backIconButtonProps={{
              disabled:
                (hasPageParams && Pagina === 1) || (!hasPageParams && pg === 0),
            }}
            nextIconButtonProps={{
              disabled:
                (hasPageParams && data.length < PorPagina) ||
                (!hasPageParams && pg === maxPage),
            }}
            labelDisplayedRows={({ from, to, count }) => {
              if (hasPageParams) {
                return `Página: ${Pagina}`;
              } else {
                return `${from}-${to} de ${count}`;
              }
            }}
            rowsPerPage={hasPageParams ? PorPagina : rpp}
            onChangeRowsPerPage={(e) => {
              if (hasPageParams) {
                setPorPagina(e.target.value);
                setPagina(1);
              } else {
                setRpp(e.target.value);
                setPg(0);
              }
            }}
            page={hasPageParams ? Pagina : pg}
            onChangePage={(e, p) => {
              if (hasPageParams) {
                setPagina(p);
              } else {
                setPg(p);
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <div className="w-100">
        {renderFooter()}
        <div style={{ maxHeight: window.innerHeight, overflow: 'auto' }}>
          <Table stickyHeader aria-label="sticky table">
            {renderHeader()}
            {renderBody()}
          </Table>
          {dataLoading && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
