import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as DocumentoActions } from '../../../store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const CartaModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [carta, setCarta] = useState('');
  const { carta: cartaFile } = useSelector(({ documento }) => documento);

  useEffect(() => {
    if (cartaFile) {
      const file = new Blob([cartaFile], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatch(DocumentoActions.postCorrecaoReset());
    }
  }, [cartaFile]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExited={() => setCarta('')}
    >
      <DialogTitle>Carta de Correção</DialogTitle>

      <DialogContent>
        <DialogContentText>
          A Carta de Correção é disciplinada pelo § 1º-A do art. 7º do Convênio
          S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularização
          de erro ocorrido na emissão de documento fiscal, desde que o erro não
          esteja relacionado com:
        </DialogContentText>
        <DialogContentText>
          I - as variáveis que determinam o valor do imposto tais como: base de
          cálculo, alíquota, diferença de preço, quantidade, valor da operação
          ou da prestação;
        </DialogContentText>
        <DialogContentText>
          II - a correção de dados cadastrais que implique mudança do remetente
          ou do destinatário;
        </DialogContentText>
        <DialogContentText>
          III - a data de emissão ou de saída;
        </DialogContentText>
        <TextField
          value={carta}
          onChange={(e) => setCarta(e.target.value)}
          inputProps={{ maxLength: 1000 }}
          fullWidth
          multiline
          rows={8}
          variant="outlined"
        />
        <div className="d-flex align-items-center justify-content-end">
          <DialogContentText>{carta.length}/1000</DialogContentText>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() =>
            dispatch(
              DocumentoActions.postCorrecao({
                chdfe: selected?.nfechaveacesso,
                correcao: carta,
              })
            )
          }
          color="primary"
        >
          Enviar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CartaModal);
