import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import TwoSimplePieChart from './Charts/TwoSimplePieChart';
import SimpleBarChart from './Charts/SimpleBarChart';
import { Creators as AuthActions } from 'store/ducks/Auth';
import Loading from 'components/Loading';
import moment from 'moment';

const DashChart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const { updateLoading, dashboard } = useSelector(({ auth }) => auth);

  const InfoModal = () => (
    <Dialog open={openModal} onClose={() => setOpenModal(!openModal)}>
      <DialogTitle>{selected?.descricao}</DialogTitle>
      <DialogContent>
        <DialogContentText>{selected?.orientacao}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenModal(!openModal)} color="primary">
          VOLTAR
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderChart = (type, values) => {
    if (type === 'TwoSimplePieChart') {
      return <TwoSimplePieChart values={values || []} />;
    }
    if (type === 'SimpleBarChart') {
      return <SimpleBarChart values={values || []} />;
    }
    return null;
  };

  if (updateLoading) {
    return <Loading />;
  }
  return (
    <Grid container>
      {dashboard.map((c) => (
        <Grid xs="12" sm="6" md="6" lg="6">
          <Paper
            elevation={3}
            className="d-flex flex-column justify-content-center align-items-center m-2"
          >
            <h4 className="my-2">{c?.descricao}</h4>
            {renderChart(c?.tipografico, c?.valores)}
            <div className="d-flex w-100 p-2 align-items-center justify-content-between">
              <div>
                <IconButton
                  className="icon-btn"
                  onClick={() =>
                    dispatch(
                      AuthActions.updateChart({ perfil_indicador_id: c?.id })
                    )
                  }
                >
                  <i
                    class="zmdi zmdi-refresh-alt"
                    style={{ color: theme.palette.primary.main }}
                  />
                </IconButton>
                <span className="mx-2" style={{ opacity: 0.5 }}>
                  Atualizado em {moment(c?.updated_at).format('DD/MM/YYYY')}
                </span>
              </div>
              <IconButton
                className="icon-btn"
                onClick={() => {
                  setSelected(c);
                  setOpenModal(!openModal);
                }}
              >
                <i
                  class="zmdi zmdi-info-outline"
                  style={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            </div>
          </Paper>
        </Grid>
      ))}
      <InfoModal />
    </Grid>
  );
};

export default DashChart;
