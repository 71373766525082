import { combineReducers } from 'redux';
import auth from './Auth';
import settings from './Setting';
import tables from './Tables';
import ibge from './Ibge';
import entidades from './Entidades';
import produto from './Produto';
import midia from './Midia';
import documento from './Documento';
import calculoNS from './CalculoNS';
import steps from './Steps';
import resumo from './Resumo';
import lancamento from './Lancamento';
import catalogo from './Catalogo';
import agenda from './Agenda';
import fiscal from './Fiscal';
import relatorio from './Relatorio';
import usuarios from './Usuarios';
import integra from './Integra';
import filtrosStore from './FiltrosStore';
import filterState from './FilterState';
import notificacoes from './Notificacoes';
import contabil from './Contabil';
import drops from './Drops';
import gnreretorno from './Gnre';

const rootReducer = combineReducers({
  auth,
  settings,
  tables,
  ibge,
  entidades,
  produto,
  midia,
  documento,
  calculoNS,
  steps,
  resumo,
  lancamento,
  catalogo,
  agenda,
  fiscal,
  relatorio,
  usuarios,
  integra,
  filtrosStore,
  notificacoes,
  contabil,
  drops,
  filterState,
  gnreretorno
});

export default rootReducer;
