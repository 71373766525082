import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import Legendas from 'components/Legendas';
import ViewModal from 'components/Modals/ViewModal';
import FilterAccordion from 'components/FilterAccordion';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import {
  checkManutencao,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as EntidadesActions } from 'store/ducks/Entidades';
import { Creators as FilterActions } from 'store/ducks/FilterState';

const Entidades = ({ match }) => {
  const { filter } = useSelector(({ filterState }) => filterState);
  const { authUser } = useSelector(({ auth }) => auth);
  const filterPath = useLocation().pathname;
  const rotina = 318;
  const csql = 166;
  const title = 'Entidades';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState(
    filter[filterPath]?.dataTable?.Parametros[0]?.valor || ''
  );
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const { deleteSuccess, setSuccess } = useSelector(
    ({ entidades }) => entidades
  );

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros: filter[filterPath]?.filtros || filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      const params = {
        Parametros: [{ nome: 'likevalue', valor: search }],
        WhereClause: getWhereClause({
          filtros: filter[filterPath]?.filtros || filtros,
          setStringExport,
        }),
        OrderClause,
        Pagina: paginacao ? Pagina : undefined,
        PorPagina: paginacao ? PorPagina : undefined,
      };
      dispatch(
        FilterActions.setFilterState({
          [filterPath]: { ...filter[filterPath], dataTable: params },
        })
      );
    }
  }

  useEffect(() => {
    if (Boolean(filter[filterPath]?.dataTable)) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: filter[filterPath]?.dataTable,
        })
      );
      //getTotais();
    }
  }, [filter[filterPath]]);

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    getDataTable();
  }, [Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(EntidadesActions.deleteEntidadeReset());
      dispatch(TablesActions.getHeader(rotina));
      setDeleteModal(!deleteModal);
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (setSuccess) {
      getDataTable();
    }
  }, [setSuccess]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () => history.push(`/app/Cadastros/Entidades/Manutenção`),
      visible: true,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Tipo de Documento',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Tipo%20de%20documento&rot=8&csql=38`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Tipo de Enderreço',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Tipo%20de%20endereço&rot=10&csql=71`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Tipo de Contato',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Tipo%20de%20contato&rot=9&csql=70`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Tipo de Vínculo',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Tipo%20de%20vinculo&rot=12&csql=72`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Ramo de Atividade',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Ramo%20de%20atividade&rot=56&csql=74`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Contribuinte',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Contribuinte&rot=373&csql=245`,
          '_blank'
        ),
      visible: true,
    },

    {
      name: 'Grupo',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Grupo%20da%20entidade&rot=360&csql=232`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Região',
      type: 'Nova',
      action: () =>
        window.open(
          `/app/Tabela?title=Região%20da%20Entidade&rot=359&csql=231`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Cidade',
      type: 'Nova',
      action: () =>
        window.open(`/app/Tabela?title=Cidades&rot=4&csql=69`, '_blank'),
      visible: true,
    },
    {
      name: 'UF',
      type: 'Nova',
      action: () =>
        window.open(`/app/Tabela?title=UF&rot=13&csql=73`, '_blank'),
      visible: true,
    },
    {
      name: 'País',
      type: 'Nova',
      action: () =>
        window.open(`/app/Tabela?title=Países&rot=183&csql=80`, '_blank'),
      visible: true,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) =>
        history.push(`/app/Cadastros/Entidades/Manutenção?id=${item?.id}`),
      visible: true,
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: true,
    },
    {
      name: 'Duplicar',
      action: (item) => {
        setSelected(item);
        setDuplicateModal(!duplicateModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Documentos',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Documentos?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Endereços',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Enderecos?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Contatos',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Contatos?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Vinculos',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Vinculos?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Registros',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Registros?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Agenda',
          action: (item) =>
            history.push(`/app/Comercial/GestãodeAgendas`, {
              entidade_id: item?.id,
            }),

          visible: true,
        },
        {
          name: 'Comissão',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Comissão?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Aprovação',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Aprovação?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Perfil',
          varName: 'Perfil',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Perfil?id=${item?.id}&name=${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Filiais',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Filiais?id=${item?.id}&name=${item.fantasia}`
            ),
          funcVis: (item) => item?.perfil?.toString()?.includes('FILIAL'),
        },
        {
          name: 'Configurações da Operadora',
          varName: 'Calculo',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Operadora?id=${item?.id}&name=${item.nome}`
            ),
          funcVis: (item) => item?.perfil?.toString()?.includes('OPERADORA'),
        },
        {
          name: 'Atualizar Controles',
          varName: 'Calculo',
          action: (item) => {
            dispatch(EntidadesActions.atualizarControlesWeb(item?.id));
          },
          funcVis: (item) => item?.perfil?.toString()?.includes('CLIENTE') && 
                             ( authUser?.storedcnpj === "00424336000181" || 
                               authUser?.storedcnpj === "04640922000114" ),
        },
      ],
      visible: true,
    },
    {
      name: 'Status',
      statScreen: 'Produto',
      action: (item) => {
        dispatch(EntidadesActions.setStatusEntidade(item?.id));
      },
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <FilterAccordion filtros={filter[filterPath]?.filtros || filtros} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Legendas screen="Produto" />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(EntidadesActions.deleteEntidade(selected?.id))
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Entidades;
