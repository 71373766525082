import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import moment from 'moment';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Checkbox,
  InputLabel,
  Button,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import { Search } from '@material-ui/icons';
import InputMask from 'components/InputMask';
import Loading from 'components/Loading';
import TableVinculos from 'components/TableVinculos';
import SearchAdressModal from 'components/Modals/SearchAdressModal';
import api from 'util/api';
import { useQuery, findOnArray } from 'util/index';
import { Creators as IbgeActions } from 'store/ducks/Ibge';
import { Creators as EntidadesActions } from 'store/ducks/Entidades';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const Manutenção = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [searchAdressModal, setSearchAdressModal] = useState(false);
  const [vinculos, setVinculos] = useState([]);
  const [fill, setFill] = useState(false);
  const param = query.get('id');
  const { estados, dataCep } = useSelector(({ ibge }) => ibge);
  const { consultas } = useSelector(({ documento }) => documento);
  const {
    postLoading,
    postSuccess,
    putLoading,
    putSuccess,
    getLoading,
    entidade,
  } = useSelector(({ entidades }) => entidades);

  const itensTipoPessoa = [
    { value: 'FISICA', label: 'FÍSICA' },
    { value: 'JURIDICA', label: 'JURÍDICA' },
  ];

  const itensEstadoCivil = [
    { value: 'SOLTEIRO', label: 'SOLTEIRO' },
    { value: 'CASADO', label: 'CASADO' },
    { value: 'DIVORCIADO', label: 'DIVORCIADO' },
    { value: 'DESQUITADO', label: 'DESQUITADO' },
    { value: 'VIÚVO', label: 'VIÚVO' },
  ];

  const itensSexo = [
    { value: 'MASCULINO', label: 'MASCULINO' },
    { value: 'FEMININO', label: 'FEMININO' },
  ];

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([655, 633, 669, 629, 625, 699]);
    dispatch(EntidadesActions.getEntidadeReset());
    dispatch(IbgeActions.getCepReset());
    if (param) {
      dispatch(EntidadesActions.getEntidade(param));
    }
  }, []);

  useEffect(() => {
    if (postSuccess) {
      dispatch(EntidadesActions.postEntidadeReset());
      history.go(-1);
    }
    if (putSuccess) {
      dispatch(EntidadesActions.putEntidadeReset());
      history.go(-1);
    }
  }, [postSuccess, putSuccess]);

  const SetFields = () => {
    const { setValues, setFieldValue, values } = useFormikContext();

    useEffect(() => {
      if (entidade !== null && !fill) {
        setFill(true);
        setValues({
          ...values,
          TipoPessoa: findOnArray(entidade.Entidade.pessoa, itensTipoPessoa),
          cpf: entidade.Documentos
            ? entidade.Documentos.find((i) => i.tipo_documento_id === 2)
                ?.documento
            : null,
          rg: entidade.Documentos
            ? entidade.Documentos.find((i) => i.tipo_documento_id === 4)
                ?.documento
            : null,
          cnpj: entidade.Documentos
            ? entidade.Documentos.find((i) => i.tipo_documento_id === 1)
                ?.documento
            : null,
          rne: entidade.Documentos
            ? entidade.Documentos.find((i) => i.tipo_documento_id === 8)
                ?.documento
            : null,
          inscricao: entidade.Documentos
            ? entidade.Documentos.find((i) => i.tipo_documento_id === 3)
                ?.documento
            : null,
          foreign: entidade.Enderecos
            ? entidade.Enderecos.find((i) => i.principal === 'SIM')
                ?.pais_codigo !== 1058
            : false,
          RazaoSocial: entidade.Entidade.nome,
          NomeFantasia: entidade.Entidade.fantasia,
          registerDate: entidade.Entidade.dtcad
            ? moment(entidade.Entidade.dtcad, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          Codigo: entidade.Entidade.codigo,
          AtividadeComercial: findOnArray(
            entidade.Entidade.atividade_entidade_id,
            items[0]
          ),
          perfis: entidade.Perfis
            ? entidade.Perfis.map((p) => findOnArray(p.tipo, items[2]))
            : [],
          DataNascimento: entidade?.Fisica?.dtnasc
            ? moment(entidade.Fisica.dtnasc, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          EstadoCivil: entidade.Fisica
            ? findOnArray(entidade.Fisica.estado_civil, itensEstadoCivil)
            : null,
          Sexo: entidade.Fisica
            ? findOnArray(entidade.Fisica.sexo, itensSexo)
            : null,
          UfNascimento: entidade.Fisica
            ? items[4].find(
                (e) =>
                  e?.label?.substring(0, 2) === entidade?.Fisica?.nascimento_uf
              )
            : null,
          CidadeNascimento: entidade.Fisica
            ? items[5].find(
                (c) =>
                  Number(c?.codigo_ibge) ===
                  Number(entidade?.Fisica?.nascimento_codigo_ibge)
              )
            : null,
          LocalTrabalho: entidade.Fisica ? entidade.Fisica.loctrabalho : null,
          residencial: entidade.Contatos
            ? entidade.Contatos.find((i) => i.tipo_contato_id === 1)?.conteudo
            : null,
          comercial: entidade.Contatos
            ? entidade.Contatos.find((i) => i.tipo_contato_id === 4)?.conteudo
            : null,
          celular: entidade.Contatos
            ? entidade.Contatos.find((i) => i.tipo_contato_id === 2)?.conteudo
            : null,
          email: entidade.Contatos
            ? entidade.Contatos.find((i) => i.tipo_contato_id === 3)?.conteudo
            : null,
          Cep: entidade.Enderecos
            ? entidade.Enderecos.find((i) => i.principal === 'SIM')?.cep
            : null,
          UF: entidade.Enderecos
            ? items[4].find(
                (e) =>
                  e?.label?.substring(0, 2) ===
                  entidade.Enderecos.find((i) => i.principal === 'SIM')?.uf
              )
            : null,
          Cidade: entidade.Enderecos
            ? items[5].find(
                (c) =>
                  Number(c?.codigo_ibge) ===
                  Number(
                    entidade.Enderecos.find((i) => i.principal === 'SIM')
                      ?.codigo_ibge
                  )
              )
            : null,
          Bairro: entidade.Enderecos
            ? entidade.Enderecos.find((i) => i.principal === 'SIM')?.bairro
            : null,
          Logradouro: entidade.Enderecos
            ? entidade.Enderecos.find((i) => i.principal === 'SIM')?.logradouro
            : null,
          Numero: entidade.Enderecos
            ? entidade.Enderecos.find((i) => i.principal === 'SIM')?.numero
            : null,
          complemento: entidade.Enderecos
            ? entidade.Enderecos.find((i) => i.principal === 'SIM')?.complemento
            : null,
          vinculo: '',
          vinculoEntidade: '',
        });
        if (entidade.Vinculos) {
          setVinculos(entidade.Vinculos);
        }
      }
    }, [entidade]);

    useEffect(() => {
      if (dataCep) {
        const estado = items[4].find(
          (e) => e.label.substring(0, 2) === dataCep?.uf
        );
        const cidade = items[5].find(
          (c) => Number(c?.codigo_ibge) === Number(dataCep?.ibge)
        );
        setFieldValue('UF', estado);
        setFieldValue('Cidade', cidade);
        setFieldValue('Bairro', dataCep?.bairro);
        setFieldValue('Logradouro', dataCep?.logradouro);
        dispatch(IbgeActions.getCepReset());
      }
    }, [dataCep]);

    useEffect(() => {
      if (consultas?.infCons) {
        setValues({
          ...values,
          UF:
            items[4].find(
              (e) =>
                e?.label?.substring(0, 2) === consultas?.infCons?.infCad?.UF
            ) || null,
          inscricao: consultas?.infCons?.infCad?.IE,
          NomeFantasia: consultas?.infCons?.infCad?.xNome,
          RazaoSocial: consultas?.infCons?.infCad?.xNome,
          AtividadeComercial: items[0]?.find(
            (a) => a?.codigo === consultas?.infCons?.infCad?.CNAE
          ),
          Cep: consultas?.infCons?.infCad?.ender?.CEP,
          Cidade:
            items[5].find(
              (c) =>
                Number(c?.codigo_ibge) ===
                Number(consultas?.infCons?.infCad?.ender?.cMun)
            ) || null,
          Bairro: consultas?.infCons?.infCad?.ender?.xBairro,
          Logradouro: consultas?.infCons?.infCad?.ender?.xLgr,
          Numero: consultas?.infCons?.infCad?.ender?.nro,
          complemento: consultas?.infCons?.infCad?.ender?.xCpl,
        });
        dispatch(DocumentoActions.postConsultasNfeReset());
      }
    }, [consultas]);

    return null;
  };

  function registrarVinculos(vinculo, entidade, setFieldValue) {
    if (
      vinculo !== null &&
      entidade !== null &&
      vinculo !== '' &&
      entidade !== ''
    ) {
      setFieldValue('vinculo', '');
      setFieldValue('vinculoEntidade', '');
      setVinculos([
        ...vinculos,
        { tipo_vinculo_id: vinculo?.value, entidade_id_vinc: entidade?.value },
      ]);
    }
  }

  function handleSubmit(values) {
    const localContatos = [
      { tipo_contato_id: 4, conteudo: values?.comercial, principal: 'NAO' },
      { tipo_contato_id: 1, conteudo: values?.residencial, principal: 'SIM' },
      { tipo_contato_id: 2, conteudo: values?.celular, principal: 'NAO' },
      {
        tipo_contato_id: 3,
        conteudo: values?.email,
        principal: 'NAO',
      },
    ];

    const localDocumentos = [
      { tipo_documento_id: 2, documento: values?.cpf, principal: 'SIM' },
      { tipo_documento_id: 4, documento: values?.rg },
      { tipo_documento_id: 1, documento: values?.cnpj, principal: 'SIM' },
      { tipo_documento_id: 3, documento: values?.inscricao },
      { tipo_documento_id: 8, documento: values?.rne, principal: 'SIM' },
    ];

    let form = {
      Entidade: {
        codigo: Number(values?.Codigo),
        pessoa: values?.TipoPessoa?.value,
        nome: values?.RazaoSocial,
        fantasia: values?.NomeFantasia,
        dtcad: moment(values?.registerDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        atividade_entidade_id: values?.AtividadeComercial?.value,
      },
      Perfis: values?.perfis.map((p) => {
        return { tipo: p.value };
      }),
      Enderecos: [
        {
          tipo_endereco_id: 1,
          logradouro: values?.Logradouro,
          numero: values?.Numero,
          complemento: values?.complemento,
          bairro: values?.Bairro,
          uf_id: values?.UF?.value,
          cep: values?.Cep,
          principal: 'SIM',
          codigo_ibge: values?.Cidade?.codigo_ibge,
        },
      ],
      Contatos: localContatos
        .map((l) => {
          if (l.conteudo && l.conteudo !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean),
      Documentos: localDocumentos
        .map((l) => {
          if (l.documento && l.documento !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean),
      Vinculos: vinculos,
    };
    if (values?.TipoPessoa?.value === 'FISICA') {
      form = {
        ...form,
        Fisica: {
          sexo: values?.Sexo?.value,
          dtnasc: values?.DataNascimento
            ? moment(values?.DataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
          nascimento_cidade: values?.CidadeNascimento?.label,
          nascimento_uf: values?.UfNascimento?.label?.substring(0, 2),
          nascimento_codigo_ibge: values?.CidadeNascimento?.codigo_ibge,
          estado_civil: values?.EstadoCivil?.value,
          loctrabalho: values?.LocalTrabalho,
        },
      };
    }
    dispatch(EntidadesActions.postEntidade(form));
  }

  function handleEdit(values) {
    const localContatos = [
      { tipo_contato_id: 4, conteudo: values?.comercial, principal: 'NAO' },
      { tipo_contato_id: 1, conteudo: values?.residencial, principal: 'SIM' },
      { tipo_contato_id: 2, conteudo: values?.celular, principal: 'NAO' },
      {
        tipo_contato_id: 3,
        conteudo: values?.email,
        principal: 'NAO',
      },
    ];

    function loadContacts() {
      let newList = entidade?.Contatos || [];
      let newLocalContatos = localContatos
        .map((l) => {
          if (l.conteudo && l.conteudo !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean);
      newLocalContatos.map((l) => {
        let finder = newList.find(
          (f) => f.tipo_contato_id === l.tipo_contato_id
        );
        if (!finder) {
          newList.push(l);
        }
      });
      return newList;
    }

    const localDocumentos = [
      { tipo_documento_id: 2, documento: values?.cpf, principal: 'SIM' },
      { tipo_documento_id: 4, documento: values?.rg },
      { tipo_documento_id: 1, documento: values?.cnpj, principal: 'SIM' },
      { tipo_documento_id: 3, documento: values?.inscricao },
      { tipo_documento_id: 8, documento: values?.rne, principal: 'SIM' },
    ];

    function loadDocs() {
      let newList = entidade?.Documentos || [];
      let newLocalDocs = localDocumentos
        .map((l) => {
          if (l.documento && l.documento !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean);
      newLocalDocs.map((l) => {
        let finder = newList.find(
          (f) => f.tipo_documento_id === l.tipo_documento_id
        );
        if (!finder) {
          newList.push(l);
        }
      });
      return newList;
    }

    function loadPerfis(perfis) {
      let newPerfis = entidade.Perfis
        ? entidade.Perfis.map((v) => {
            let finder = perfis.find((f) => f.value === v.tipo);
            if (!finder) {
              return { ...v, excluir: 'SIM' };
            }
            return v;
          })
        : [];
      perfis.map((p) => {
        let finder = newPerfis.find((f) => p.value === f.tipo);
        if (!finder) {
          newPerfis.push({ tipo: p.value });
        }
      });
      return newPerfis;
    }

    let form = {
      ...entidade,
      Entidade: {
        ...entidade.Entidade,
        codigo: Number(values?.Codigo),
        pessoa: values?.TipoPessoa?.value,
        nome: values?.RazaoSocial,
        fantasia: values?.NomeFantasia,
        dtcad: moment(values?.registerDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        atividade_entidade_id: values?.AtividadeComercial?.value,
      },
      Vinculos: vinculos,
      Perfis: loadPerfis(values?.perfis || []),
      Contatos: loadContacts().map((c) => {
        let locFinder = localContatos.find(
          (l) => l.tipo_contato_id === c.tipo_contato_id
        );
        if (locFinder) {
          return { ...c, conteudo: locFinder.conteudo };
        }
        return c;
      }),
      Documentos: loadDocs().map((d) => {
        let locFinder = localDocumentos.find(
          (l) => l.tipo_documento_id === d.tipo_documento_id
        );
        if (locFinder) {
          return { ...d, documento: locFinder.documento };
        }
        return d;
      }),
      Enderecos: entidade.Enderecos
        ? entidade.Enderecos.map((e) => {
            if (e.principal === 'SIM') {
              return {
                ...e,
                tipo_endereco_id: 1,
                logradouro: values?.Logradouro,
                numero: values?.Numero,
                complemento: values?.complemento,
                bairro: values?.Bairro,
                uf_id: values?.UF?.value,
                uf: values?.UF?.label?.slice(0, 2),
                cep: values?.Cep,
                principal: 'SIM',
                cidade_id: values?.Cidade?.value,
                codigo_ibge: values?.Cidade?.codigo_ibge,
              };
            }
            return e;
          })
        : !!values?.Cep && values?.Cep !== ''
        ? [
            {
              tipo_endereco_id: 1,
              logradouro: values?.Logradouro,
              numero: values?.Numero,
              complemento: values?.complemento,
              bairro: values?.Bairro,
              uf_id: values?.UF?.value,
              cep: values?.Cep,
              principal: 'SIM',
              codigo_ibge: values?.Cidade?.codigo_ibge,
            },
          ]
        : [],
    };
    if (values?.TipoPessoa?.value === 'FISICA') {
      form = {
        ...form,
        Fisica: {
          ...form.Fisica,
          sexo: values?.Sexo?.value,
          dtnasc: values?.DataNascimento
            ? moment(values?.DataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
          nascimento_cidade: values?.CidadeNascimento?.label,
          nascimento_uf: values?.UfNascimento?.label?.substring(0, 2),
          nascimento_codigo_ibge: values?.CidadeNascimento?.codigo_ibge,
          estado_civil: values?.EstadoCivil?.value,
          loctrabalho: values?.LocalTrabalho,
        },
      };
    }
    dispatch(EntidadesActions.putEntidade({ data: form, id: param }));
  }

  if (localLoading || getLoading) {
    return <Loading />;
  } else {
    return (
      <div className="app-wrapper">
        <HeaderPage
          title={param ? 'Editar Entidade' : 'Cadastro de Entidade'}
          history={history}
        />
        <Formik
          initialValues={{
            TipoPessoa: { value: 'FISICA', label: 'FÍSICA' },
            cpf: '',
            rg: '',
            cnpj: '',
            rne: '',
            inscricao: '',
            foreign: false,
            RazaoSocial: '',
            NomeFantasia: '',
            registerDate: moment().format('DD/MM/YYYY'),
            Codigo: '',
            AtividadeComercial: '',
            perfis: [],
            DataNascimento: '',
            EstadoCivil: '',
            Sexo: '',
            UfNascimento: '',
            CidadeNascimento: '',
            LocalTrabalho: '',
            residencial: '',
            comercial: '',
            celular: '',
            email: '',
            Cep: '',
            UF: '',
            Cidade: '',
            Bairro: '',
            Logradouro: '',
            Numero: '',
            complemento: '',
            vinculo: '',
            vinculoEntidade: '',
          }}
          onSubmit={(values) =>
            param ? handleEdit(values) : handleSubmit(values)
          }
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <form>
              <SearchAdressModal
                open={searchAdressModal}
                setOpen={setSearchAdressModal}
                items={items}
                setFieldValue={setFieldValue}
              />
              <Card className="shadow">
                <CardContent>
                  <Grid container>
                    {values.foreign ? (
                      <>
                        <Grid
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.grid}
                        >
                          <TextField
                            label="RNE"
                            name="rne"
                            value={values.rne}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        {values?.TipoPessoa?.value === 'JURIDICA' ? (
                          <>
                            <Grid
                              xs="12"
                              sm="6"
                              md="6"
                              lg="3"
                              className={classes.grid}
                            >
                              <InputMask
                                name="cnpj"
                                label="CNPJ"
                                mask="99.999.999/9999-99"
                                value={values.cnpj}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  if (values.cnpj.length === 18) {
                                    dispatch(
                                      DocumentoActions.postConsultasNfe({
                                        path: 'nfe/consulta-cadastral',
                                        body: {
                                          operacao: param
                                            ? 'ALTERACAO'
                                            : 'INCLUSAO',
                                          tipo_documento: 'CNPJ',
                                          numero_documento: e.target.value.replace(
                                            /([\/\.\-])/g,
                                            ''
                                          ),
                                        },
                                      })
                                    );
                                  }
                                }}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              xs="12"
                              sm="6"
                              md="6"
                              lg="3"
                              className={classes.grid}
                            >
                              <TextField
                                name="inscricao"
                                label="Inscrição Estadual"
                                value={values.inscricao}
                                onChange={handleChange}
                                fullWidth
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid
                              xs="12"
                              sm="6"
                              md="6"
                              lg="3"
                              className={classes.grid}
                            >
                              <InputMask
                                name="cpf"
                                label="CPF"
                                mask="999.999.999-99"
                                value={values.cpf}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  if (values.cpf.length === 14) {
                                    dispatch(
                                      DocumentoActions.postConsultasNfe({
                                        path: 'nfe/consulta-cadastral',
                                        body: {
                                          operacao: param
                                            ? 'ALTERACAO'
                                            : 'INCLUSAO',
                                          tipo_documento: 'CPF',
                                          numero_documento: e.target.value.replace(
                                            /([\/\.\-])/g,
                                            ''
                                          ),
                                        },
                                      })
                                    );
                                  }
                                }}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              xs="12"
                              sm="6"
                              md="6"
                              lg="3"
                              className={classes.grid}
                            >
                              <InputMask
                                name="rg"
                                label="RG"
                                mask="99.999.999-*"
                                value={values.rg}
                                onChange={handleChange}
                                fullWidth
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    <Grid xs="6" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Tipo de Pessoa"
                        items={itensTipoPessoa}
                        value={values.TipoPessoa}
                        onChange={(v) => {
                          setFieldValue('TipoPessoa', v);
                          setFieldValue('cpf', '');
                          setFieldValue('rg', '');
                          setFieldValue('inscricao', '');
                          setFieldValue('cnpj', '');
                          setFieldValue('rne', '');
                        }}
                      />
                    </Grid>
                    <Grid
                      xs="6"
                      sm="3"
                      md="3"
                      lg="3"
                      className="d-flex align-items-center px-2"
                    >
                      <Checkbox
                        name="foreign"
                        id="foreign"
                        label="Estrangeiro"
                        color="primary"
                        checked={values.foreign}
                        onChange={() => {
                          setFieldValue('foreign', !values.foreign);
                          setFieldValue('cpf', '');
                          setFieldValue('rg', '');
                          setFieldValue('inscricao', '');
                          setFieldValue('cnpj', '');
                          setFieldValue('rne', '');
                        }}
                      />
                      <InputLabel htmlFor="foreign">Estrangeiro</InputLabel>
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="RazaoSocial"
                        label="Razão Social"
                        value={values.RazaoSocial}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                      className={classes.grid}
                    >
                      <TextField
                        name="NomeFantasia"
                        label="Nome Fantasia"
                        value={values.NomeFantasia}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                      <InputMask
                        name="registerDate"
                        label="Data de Cadastro"
                        mask="99/99/9999"
                        value={values.registerDate}
                        onChange={handleChange}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="Codigo"
                        label="Código"
                        value={values.Codigo}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="6"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Atividades"
                        items={items[0]}
                        value={values.AtividadeComercial}
                        onChange={(v) => setFieldValue('AtividadeComercial', v)}
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        multiple
                        label="Perfis"
                        items={items[2]}
                        value={values.perfis}
                        onChange={(v) => setFieldValue('perfis', v)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {values?.TipoPessoa?.value === 'FISICA' && (
                <Card className="shadow" style={{ marginTop: '1rem' }}>
                  <CardContent>
                    <h3>Informações para Pessoa Fisica</h3>
                    <Grid container>
                      <Grid
                        xs="12"
                        sm="6"
                        md="6"
                        lg="4"
                        className={classes.grid}
                      >
                        <InputMask
                          name="DataNascimento"
                          label="Data de Nascimento"
                          mask="99/99/9999"
                          value={values.DataNascimento}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs="12"
                        sm="6"
                        md="6"
                        lg="4"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Estado Civil"
                          items={itensEstadoCivil}
                          value={values.EstadoCivil}
                          onChange={(v) => setFieldValue('EstadoCivil', v)}
                        />
                      </Grid>
                      <Grid
                        xs="12"
                        sm="6"
                        md="6"
                        lg="4"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Sexo"
                          items={itensSexo}
                          value={values.Sexo}
                          onChange={(v) => setFieldValue('Sexo', v)}
                        />
                      </Grid>

                      <Grid
                        xs="12"
                        sm="6"
                        md="6"
                        lg="4"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Estado de Nascimento"
                          items={items[4]}
                          value={values.UfNascimento}
                          onChange={(v) => setFieldValue('UfNascimento', v)}
                        />
                      </Grid>
                      <Grid
                        xs="12"
                        sm="6"
                        md="6"
                        lg="4"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Cidade de Nascimento"
                          items={
                            items[5].filter(
                              (c) =>
                                c?.sigla ===
                                values?.UfNascimento?.label?.substring(0, 2)
                            ) || items[5]
                          }
                          value={values.CidadeNascimento}
                          onChange={(v) => setFieldValue('CidadeNascimento', v)}
                        />
                      </Grid>
                      <Grid
                        xs="12"
                        sm="6"
                        md="6"
                        lg="4"
                        className={classes.grid}
                      >
                        <TextField
                          name="LocalTrabalho"
                          label="Local de Trabalho"
                          value={values.LocalTrabalho}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
              <Card className="shadow" style={{ marginTop: '1rem' }}>
                <CardContent>
                  <h3>Contatos / Telefones</h3>
                  <Grid container>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <InputMask
                        name="residencial"
                        label="Residencial"
                        mask="(99) 9999-9999"
                        value={values.residencial}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <InputMask
                        name="comercial"
                        label="Comercial"
                        mask="(99) 9999-9999"
                        value={values.comercial}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <InputMask
                        name="celular"
                        label="Celular"
                        mask="(99) 99999-9999"
                        value={values.celular}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <TextField
                        name="email"
                        label="E-mail"
                        value={values.email}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className="shadow" style={{ marginTop: '1rem' }}>
                <CardContent>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Endereço</h3>
                    <IconButton onClick={() => setSearchAdressModal(true)}>
                      <Search color="primary" />
                    </IconButton>
                  </div>
                  <Grid container>
                    <Grid xs="12" sm="6" md="6" lg="2" className={classes.grid}>
                      <InputMask
                        name="Cep"
                        label="CEP"
                        mask="99.999-999"
                        value={values.Cep}
                        onChange={(e) => {
                          const v = e.target.value.replace(/[.-]/g, '');
                          if (v.length === 8) dispatch(IbgeActions.getCep(v));
                          setFieldValue('Cep', v);
                        }}
                        fullWidth
                      />
                      <SetFields />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="2" className={classes.grid}>
                      <SelectAuto
                        label="Estado"
                        items={items[4]}
                        value={values.UF}
                        onChange={(v) => setFieldValue('UF', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Cidade"
                        items={
                          items[5].filter(
                            (c) =>
                              c?.sigla === values?.UF?.label?.substring(0, 2)
                          ) || items[5]
                        }
                        value={values.Cidade}
                        onChange={(v) => setFieldValue('Cidade', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="4" className={classes.grid}>
                      <TextField
                        name="Bairro"
                        label="Bairro"
                        value={values.Bairro || ''}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="6"
                      className={classes.grid}
                    >
                      <TextField
                        name="Logradouro"
                        label="Endereço"
                        value={values.Logradouro || ''}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="2" className={classes.grid}>
                      <TextField
                        name="Numero"
                        label="Número"
                        value={values.Numero}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="4" className={classes.grid}>
                      <TextField
                        name="complemento"
                        label="Complemento"
                        value={values.complemento}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className="shadow" style={{ marginTop: '1rem' }}>
                <CardContent>
                  <h3>Vínculos</h3>
                  <Grid container>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Tipo de Vínculo"
                        items={items[3]}
                        value={values.vinculo}
                        onChange={(v) => setFieldValue('vinculo', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Nome da Entidade"
                        items={items[1]}
                        value={values.vinculoEntidade}
                        onChange={(v) => setFieldValue('vinculoEntidade', v)}
                      />
                    </Grid>
                    <div
                      className="d-flex justify-content-end"
                      style={{ flex: 1, marginBottom: '1rem' }}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          registrarVinculos(
                            values.vinculo,
                            values.vinculoEntidade,
                            setFieldValue
                          )
                        }
                      >
                        Adicionar
                      </Button>
                    </div>
                    <TableVinculos
                      vinculos={vinculos}
                      setVinculos={setVinculos}
                      tipos={items[3]}
                      entidades={items[1]}
                      param={param}
                    />
                  </Grid>
                </CardContent>
              </Card>
              <div
                className="d-flex justify-content-end"
                style={{ flex: 1, marginTop: '1rem', padding: '0 16px' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disable={postLoading || putLoading}
                >
                  Finalizar
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};
export default withStyles(styles)(Manutenção);
