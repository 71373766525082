import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as TablesActions } from 'store/ducks/Tables';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  button: {
    margin: '0 5px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const ope = [
  { value: 'ENTRADA', label: 'ENTRADA' },
  { value: 'SAIDA', label: 'SAIDA' },
];

const integracao = [
  { value: '203668870', label: 'Mercado Livre' },  
  { value: 'MercadoLivre (Full)', label: 'MercadoLivre (Full)' },
  { value: '203623815', label: 'Magalu' },    
  { value: 'Magalu (Full)', label: 'Magalu (Full)' },
  { value: '203604298', label: 'Amazon' },    
  { value: '204213782', label: 'Amazon (Full)' },    
  { value: '203598899', label: 'Shopee' },    
  { value: '203636101', label: 'Americanas' },      
  { value: '203619063', label: 'Carrefour' },       
  { value: '204243089', label: 'Kabum' }        
];

const DocImportXMLModal = ({ open, setOpen, rotina, op, classes }) => {
  const dispatch = useDispatch();
  const [filiais, setFiliais] = useState([]);
  const [plano, setPlano] = useState([]);
  const fileInput = React.createRef();
  const { postXmlSuccess, postXmlLoading } = useSelector(
    ({ documento }) => documento
  );

  async function loadFiliais(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setFiliais(drops);
  }

  async function loadPlano(valor) {
    if (valor) {
      const { data } = await api.post(`/csql/606`, {
        Parametros: [
          {
            nome: ':filial_id',
            valor,
          },
        ],
      });
      setPlano(data?.dados || []);
    }
  }

  useEffect(() => {
    loadFiliais([621]);
  }, []);

  useEffect(() => {
    if (postXmlSuccess) {
      dispatch(DocumentoActions.postXMLReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(false);
    }
  }, [postXmlSuccess]);

  function sendData(values) {
    if (values.arquivos) {
      const files = Array.from(values.arquivos);
      const formData = new FormData();
      if (values?.operacao?.value) {
        formData.append('operacao', values?.operacao?.value);
      }
      if (values?.cadprod?.value) {
        formData.append('produto', values?.cadprod?.value);
      }
      if (values?.cadtransp?.value) {
        formData.append('transportador', values?.cadtransp?.value);
      }
      //Adicionando arquivos ao formulário
      files.map((file) => {
        formData.append('xml', file);
      });

      formData.append('planoconta_id', values?.planoconta_id?.value || '');
      if (values?.integracao?.value) {
        formData.append('integracao', values?.integracao?.value);
      }
      dispatch(DocumentoActions.postXML(formData));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          arquivos: null,
          filial: null,
          operacao: { value: op, label: op },
          cadprod: null,
          cadtransp: null,
          planoconta_id: null,
          integracao: null
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <DialogTitle>Importação de arquivos XML</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Selecione a Filial correspondente"
                    items={filiais[0]}
                    value={values.filial}
                    onChange={(v) => {
                      setFieldValue('filial', v);
                      if (v?.value) {
                        loadPlano(v?.value);
                      }
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Selecione o tipo de Operação"
                    items={ope}
                    value={values.operacao}
                    onChange={(v) => {
                      setFieldValue('operacao', v);
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    name="cadastramentoprod"
                    label="Cadastramento automático dos produtos"
                    items={yn}
                    value={values.cadprod}
                    onChange={(v) => setFieldValue('cadprod', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    name="cadastramentotransp"
                    label="Cadastramento automático do transportador"
                    items={yn}
                    value={values.cadtransp}
                    onChange={(v) => setFieldValue('cadtransp', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    name="planoconta_id"
                    label="Plano de contas"
                    items={plano}
                    value={values.planoconta_id}
                    onChange={(v) => setFieldValue('planoconta_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Integração"
                    items={integracao}
                    value={values.integracao}
                    onChange={(v) => {
                      setFieldValue('integracao', v);
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <input
                    type="file"
                    accept=".xml"
                    ref={fileInput}
                    onChange={(e) => {
                      setFieldValue('arquivos', e.target.files);
                    }}
                    multiple
                    className="w-100"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row align-items-end">
                {!!postXmlLoading && <CircularProgress size={25} />}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => sendData(values)}
                  disabled={postXmlLoading}
                >
                  IMPORTAR
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setOpen(!open)}
                >
                  CANCELAR
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocImportXMLModal);
