import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  Grid,
  TextField,
  IconButton,
  InputLabel,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import api from 'util/api';
import Loading from 'components/Loading';
import { setDecimal } from 'util/index';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  grPlus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const ClienteModal = ({ open, setOpen, onSubmit, documento, classes }) => {
  const [search, setSearch] = useState('');
  const [boxes, setBoxes] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prodLoading, setProdLoading] = useState(false);

  const loadDrop = async () => {
    setLoading(true);
    setSelected(null);
    const { data } = await api.post(`/csql/609`, {
      Parametros: [{ nome: 'likevalue', valor: search }],
    });
    if (data) {
      const { dados } = data;
      setBoxes(dados || []);
    }
    setLoading(false);
  };

  const loadAdditionalProdInfo = async (prod) => {
    setProdLoading(true);
    setSelected(prod);
    if (Boolean(prod?.value)) {
      const { data } = await api.post(`/csql/981`, {
        Parametros: [
          { nome: ':produto_id', valor: prod?.value },
          { nome: ':documento_id', valor: documento?.Documento?.id },
        ],
      });
      if (data) {
        const { dados } = data;
        if (Boolean(dados[0])) {
          setSelected((prev) => ({ ...prev, ...dados[0] }));
        }
      }
    }
    setProdLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setBoxes([]);
      setSearch('');
      setSelected(null);
    }
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>
        Buscar Produto (Descrição ou Id: / Ref: / Grupo: / Marca: / EAN:)
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              label="Digite para pesquisar"
              value={search || ''}
              onChange={(e) => setSearch(e?.target?.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton className="icon-btn" onClick={loadDrop}>
              <SearchIcon color="primary" />
            </IconButton>
          </Grid>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Grid item xs={8}>
                <div style={{ maxHeight: 400, overflow: 'auto' }}>
                  {boxes.map((i) => (
                    <div className="d-flex align-items-center py-3 w-100">
                      <Checkbox
                        name={i.value}
                        id={i.value}
                        color="primary"
                        checked={selected?.value === i.value}
                        value={i.value}
                        style={{ padding: '0 10px 0 0' }}
                        onChange={(e) => loadAdditionalProdInfo(i)}
                      />
                      <InputLabel htmlFor={i.value}>{i.label}</InputLabel>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={4}>
                {!!selected && (
                  <Card>
                    <CardMedia
                      component="img"
                      height="200"
                      image={selected?.url}
                      style={{ objectFit: 'contain' }}
                    />
                    <CardContent>
                      <Typography variant="subtitle2">
                        Saldo disponivel:
                        <Typography variant="subtitle1">
                          {parseFloat(selected?.saldo)}
                        </Typography>
                      </Typography>
                      
                      <Typography variant="subtitle2">
                        Custo atualizado em:
                        <Typography variant="subtitle1">
                          {moment(selected?.dtcusto || null).format('DD/MM/YYYY')}
                        </Typography>
                      </Typography>

                      <Typography variant="subtitle2">
                        Preço atualizado em:
                        <Typography variant="subtitle1">
                          {moment(selected?.dtpreco || null).format('DD/MM/YYYY')}
                        </Typography>
                      </Typography>
                      
                      
                      {!!selected?.complemento1 && (
                        <Typography variant="subtitle2">
                          Complemento 1:
                          <Typography variant="subtitle1">
                            {selected?.complemento1}
                          </Typography>
                        </Typography>
                      )}
                      {!!selected?.complemento2 && (
                        <Typography variant="subtitle2">
                          Complemento 2:
                          <Typography variant="subtitle1">
                            {selected?.complemento2}
                          </Typography>
                        </Typography>
                      )}
                      {!!selected?.registros && (
                        <Typography variant="subtitle2">
                          Registros:
                          <Typography variant="subtitle1">
                            {selected?.registros}
                          </Typography>
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={prodLoading}
          onClick={() => onSubmit(selected)}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ClienteModal);
