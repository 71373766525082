import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Button,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import ApontamentoModal from 'components/Modals/ApontamentoModal';
import { setDecimal, useQuery } from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import DeleteIcon from '@material-ui/icons/Delete';

const Apontamentos = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery(useLocation);
  const id = query.get('id');
  const rotina = 432;
  const csql = 285;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const { data, dataLoading, saveSuccess, deleteSuccess } = useSelector(
    ({ tables }) => tables
  );

  const getData = () => {
    dispatch(
      TablesActions.getDataTable({
        csql,
        params: {
          WhereClause: `dipa.documento_item_processo_id = ${Number(id)}`,
        },
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (saveSuccess) {
      dispatch(TablesActions.saveDataReset());
      setOpenModal(!openModal);
      getData();
    }
    if (deleteSuccess) {
      dispatch(TablesActions.deleteDataReset());
      getData();
    }
  }, [saveSuccess, deleteSuccess]);

  return (
    <div className="app-wrapper">
      <HeaderPage title="Apontamentos" history={history} rotina={rotina} />
      <Card className="shadow">
        {dataLoading ? (
          <CardContent>
            <Loading />
          </CardContent>
        ) : (
          <CardContent>
            <div className="d-flex justify-content-end mb-3">
              <Button
                variant="contained"
                color="primary"
                className="jr-btn"
                color="primary"
                onClick={() => setOpenModal(!openModal)}
              >
                NOVO APONTAMENTO
              </Button>
            </div>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Início</TableCell>
                  <TableCell>Fim</TableCell>
                  <TableCell>Tempo</TableCell>
                  <TableCell>Quantidade</TableCell>
                  <TableCell>Executante</TableCell>
                  <TableCell>Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((d, index) => (
                    <TableRow>
                      <TableCell>
                        {moment(d?.inicio, 'YYYY-MM-DD HH:mm:ss').isValid() &&
                          moment(d?.inicio, 'YYYY-MM-DD HH:mm:ss').format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                      </TableCell>
                      <TableCell>
                        {moment(d?.fim, 'YYYY-MM-DD HH:mm:ss').isValid() &&
                          moment(d?.fim, 'YYYY-MM-DD HH:mm:ss').format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                      </TableCell>
                      <TableCell>{d?.tempomin}</TableCell>
                      <TableCell>
                        {d?.quantidade && setDecimal(d?.quantidade, 3)}
                      </TableCell>
                      <TableCell>{d?.executante}</TableCell>
                      <TableCell>
                        <DeleteIcon
                          color="action"
                          onClick={() =>
                            dispatch(
                              TablesActions.deleteData({
                                table: 'documento_item_processo_apont',
                                id: d?.id,
                              })
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TableFooter className="d-flex w-100">
              <TableRow className="d-flex w-100">
                <TablePagination
                  className="d-flex w-100 justify-content-end"
                  style={{ borderBottom: 0 }}
                  count={data.length}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={(e) => {
                    setRowsPerPage(e.target.value);
                    setPage(0);
                  }}
                  page={page}
                  onChangePage={(e, p) => setPage(p)}
                />
              </TableRow>
            </TableFooter>
          </CardContent>
        )}
      </Card>
      <ApontamentoModal open={openModal} setOpen={setOpenModal} id={id} />
    </div>
  );
};

export default Apontamentos;
