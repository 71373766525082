import { Typography, IconButton } from '@material-ui/core';
import React from 'react';

export default function HeaderPage({ title, size, rotina, history, link }) {
  return (
    <div className="d-flex flex-row align-items-center justify-content-between">
      {title && (
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          style={{ fontSize: size }}
        >
          {title}
        </Typography>
      )}
      <div className="d-flex align-items-center justify-content-center">
        {history && !link && (
          <Typography
            variant="h7"
            component="h5"
            color="primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => history.goBack()}
          >
            Voltar
          </Typography>
        )}
        {link && !history && (
          <Typography
            variant="h7"
            component="h5"
            color="primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={link?.action}
          >
            {link?.text}
          </Typography>
        )}
        {rotina && (
          <IconButton
            style={{ marginLeft: '20px', padding: '5px' }}
            onClick={() => {
              window.open(
                `http://help.eprom.com.br/webApp/${rotina}.html`,
                '_blank'
              );
            }}
          >
            <i class="zmdi zmdi-help text-primary"></i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
