import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as AgendaActions,
  Types as AgendaTypes,
} from 'store/ducks/Agenda';
import api from '../../util/api';

function* getPerfil() {
  try {
    const { status, data } = yield call(api.get, `/agenda-perfil`);
    if (status === 200 || status === 201) {
      yield put(AgendaActions.getPerfilSuccess(data?.Perfil));
    } else {
      yield put(AgendaActions.getPerfilError());
    }
  } catch (error) {
    yield put(AgendaActions.getPerfilError());
  }
}

function* getAgenda({payload}) {
  try {
    const { status, data } = yield call(api.post, `/agenda`, payload);
    if (status === 200 || status === 201) {
      yield put(AgendaActions.getAgendaSuccess(data?.Agenda));
    } else {
      yield put(AgendaActions.getAgendaError());
    }
  } catch (error) {
    yield put(AgendaActions.getAgendaError());
  }
}

function* postTarefa({ payload }) {
  try {
    const { status } = yield call(api.post, `/task`, payload);
    if (status === 200 || status === 201) {
      yield put(AgendaActions.postTarefaSuccess());
    } else {
      yield put(AgendaActions.postTarefaError());
    }
  } catch (error) {
    yield put(AgendaActions.postTarefaError());
  }
}

function* putTarefa({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.put, `/task/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(AgendaActions.putTarefaSuccess());
    } else {
      yield put(AgendaActions.putTarefaError());
    }
  } catch (error) {
    yield put(AgendaActions.putTarefaError());
  }
}

function* deleteTarefa({ payload }) {
  try {
    const { status } = yield call(api.delete, `/task/${payload}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(AgendaActions.deleteTarefaSuccess());
    } else {
      yield put(AgendaActions.deleteTarefaError());
    }
  } catch (error) {
    yield put(AgendaActions.deleteTarefaError());
  }
}

function* putStatus({ payload }) {
  try {
    const { status } = yield call(api.put, `/task-status/${payload}`);
    if (status === 200 || status === 201) {
      yield put(AgendaActions.putStatusSuccess());
    } else {
      yield put(AgendaActions.putStatusError());
    }
  } catch (error) {
    yield put(AgendaActions.putStatusError());
  }
}

function* getPerfilWatcher() {
  yield takeLatest(AgendaTypes.GET_PERFIL, getPerfil);
}

function* getAgendaWatcher() {
  yield takeLatest(AgendaTypes.GET_AGENDA, getAgenda);
}

function* postTarefaWatcher() {
  yield takeLatest(AgendaTypes.POST_TAREFA, postTarefa);
}

function* putTarefaWatcher() {
  yield takeLatest(AgendaTypes.PUT_TAREFA, putTarefa);
}

function* deleteTarefaWatcher() {
  yield takeLatest(AgendaTypes.DELETE_TAREFA, deleteTarefa);
}

function* putStatusWatcher() {
  yield takeLatest(AgendaTypes.PUT_STATUS, putStatus);
}

export default function* rootSaga() {
  yield all([
    fork(getPerfilWatcher),
    fork(getAgendaWatcher),
    fork(postTarefaWatcher),
    fork(putTarefaWatcher),
    fork(deleteTarefaWatcher),
    fork(putStatusWatcher),
  ]);
}
