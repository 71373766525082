import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import InputMask from 'components/InputMask';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as FiscalActions } from '../../../store/ducks/Fiscal';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import api from 'util/api';
import { findOnArray, setDecimal, transformData } from 'util/index';
import moment from 'moment';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const regimeDrop = [
  { value: 0, label: 'RPA' },
  { value: 1, label: 'REGIME DE ESTIMATIVA' },
  { value: 2, label: 'APURAÇÃO MENSAL NORMAL' },
];

const ApuracaoModal = ({ open, setOpen, rotina, filtros, classes }) => {
  const dispatch = useDispatch();
  const { apuracao, postAprLoading, postAprSuccess } = useSelector(
    ({ fiscal }) => fiscal
  );

  useEffect(() => {
    if (postAprSuccess) {
      setOpen(!open);
      dispatch(FiscalActions.getApuracaoError());
      dispatch(FiscalActions.postAprReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postAprSuccess]);

  useEffect(() => {
    const filtro = filtros.find((f) => f.titulo.toUpperCase() === 'FILIAL DE ')
      ?.conteudoi;
    const tributo = filtros.find((f) => f.titulo.toUpperCase() === 'TRIBUTO')
      ?.conteudoi;
    if (filtro && tributo) {
      dispatch(
        FiscalActions.getApuracao({
          filial_id: filtro,
          tributo_id: tributo,
          descricao: 'APURACAO',
        })
      );
    }
  }, [open]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    const getContent = (value) => {
      return apuracao.find((a) => a.identificacao === value)?.conteudo;
    };

    useEffect(() => {
      if (!open) {
        setValues({
          regime: null,
          pagInicial: null,
          saldo: null,
          data: null,
          responsavel: null,
          qualificacao: null,
          rg: null,
        });
      }
      if (!!apuracao.length && open) {
        setValues({
          ...values,
          regime: getContent('REGIME')
            ? findOnArray(Number(getContent('REGIME')), regimeDrop)
            : null,
          pagInicial: getContent('PAGINI')
            ? setDecimal(getContent('PAGINI'), 2)
            : null,
          saldo: getContent('SALDO')
            ? setDecimal(getContent('SALDO'), 2)
            : null,
          data: getContent('DATAAPR')
            ? moment(getContent('DATAAPR'), 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          responsavel: getContent('RESPONSAVEL')
            ? getContent('RESPONSAVEL')
            : null,
          qualificacao: getContent('QUALIFICACAO')
            ? getContent('QUALIFICACAO')
            : null,
          rg: getContent('RG') ? getContent('RG') : null,
        });
      } else {
        setValues({
          ...values,
          regime: null,
          pagInicial: null,
          saldo: null,
          data: null,
          responsavel: null,
          qualificacao: null,
          rg: null,
        });
      }
    }, [apuracao, open]);
    return null;
  };

  const onSendPost = (values) => {
    const filiali_id = filtros.find(
      (f) => f.titulo.toUpperCase() === 'FILIAL DE '
    )?.conteudoi;
    const filialf_id = filtros.find(
      (f) => f.titulo.toUpperCase() === 'FILIAL ATÉ '
    )?.conteudoi;
    const tributo_id = filtros.find((f) => f.titulo.toUpperCase() === 'TRIBUTO')
      ?.conteudoi;
    const dtapuracaoi = moment(
      filtros.find((f) => f.titulo.toUpperCase() === 'PERIODO DE')?.conteudoi,
      'DD-MM-YYYY'
    ).isValid()
      ? moment(
          filtros.find((f) => f.titulo.toUpperCase() === 'PERIODO DE')
            ?.conteudoi,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD')
      : null;
    const dtapuracaof = moment(
      filtros.find((f) => f.titulo.toUpperCase() === 'PERIODO ATÉ')?.conteudoi,
      'DD-MM-YYYY'
    ).isValid()
      ? moment(
          filtros.find((f) => f.titulo.toUpperCase() === 'PERIODO ATÉ')
            ?.conteudoi,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD')
      : null;

    const data = {
      filiali_id,
      filialf_id,
      tributo_id,
      dtapuracaoi,
      dtapuracaof,
      regime: values?.regime?.value,
      pagini: transformData(values?.pagInicial, 'number'),
      dataapr: moment(values?.data, 'DD/MM/YYYY').isValid()
        ? moment(values?.data, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      responsavel: values?.responsavel,
      qualificacao: values?.qualificacao,
      rg: values?.rg,
      saldocant: transformData(values?.saldo, 'number'),
    };
    dispatch(FiscalActions.postApr(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExited={() => dispatch(FiscalActions.getApuracaoError())}
    >
      <Formik
        initialValues={{
          regime: null,
          pagInicial: null,
          saldo: null,
          data: null,
          responsavel: null,
          qualificacao: null,
          rg: null,
        }}
      >
        {({ values, handleChange, setFieldValue, setValues }) => (
          <>
            <SetFields />
            <DialogTitle>Apuração</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Regime"
                    items={regimeDrop}
                    value={values.regime}
                    onChange={(v) => setFieldValue('regime', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="pagInicial"
                    label="Página Inicial"
                    value={values?.pagInicial || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="saldo"
                    label="011 - Saldo Credor Anterior"
                    value={values?.saldo || ''}
                    onChange={handleChange}
                    fullWidth
                    onBlur={(v) =>
                      setFieldValue('saldo', setDecimal(v.target.value, 2))
                    }
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <InputMask
                    name="data"
                    label="Data de Apuração"
                    mask="99/99/9999"
                    value={values.data || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="responsavel"
                    label="Responsável"
                    value={values?.responsavel || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="qualificacao"
                    label="Qualificação"
                    value={values?.qualificacao || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="rg"
                    label="RG"
                    value={values?.rg || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onSendPost(values)}
                color="primary"
                disabled={postAprLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(ApuracaoModal);
