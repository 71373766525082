import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { setDecimal, transformData } from 'util/index';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as ProdutoActions } from '../../../store/ducks/Produto';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const SelecionadosModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    celinha_id: null,
    cegrupo_id: null,
    cemarca_id: null,    
    cfiscal_id: null,
    origem_tributaria_id: null,
    unidade_medida_id: null,
    tabpreco_id: null,
    margemc: null,
    margemp: null,
    complemento1: null,
    complemento2: null,
    lotemin: null,
    lotemult: null,
    loterepos: null,
    temporepos: null,
    estmin: null,
    estmax: null,
    volume: null,
  });
  const { postManutSelLoading } = useSelector(({ produto }) => produto);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) loadItems([638, 639, 654, 611, 636, 831, 962]);
  }, [open]);

  const onSubmit = () => {
    const data = {
      Produtos: selected?.map((s) => s?.id),
      celinha_id: values?.celinha_id?.value,
      cegrupo_id: values?.cegrupo_id?.value,
      cemarca_id: values?.cemarca_id?.value,
      cfiscal_id: values?.cfiscal_id?.value,
      origem_tributaria_id: values?.origem_tributaria_id?.value,
      unidade_medida_id: values?.unidade_medida_id?.value,
      tabpreco_id: values?.tabpreco_id?.value,
      margemc: transformData(values?.margemc, 'number'),
      margemp: transformData(values?.margemp, 'number'),
      complemento1: values?.complemento1,
      complemento2: values?.complemento2,
      lotemin: transformData(values?.lotemin, 'number'),
      lotemult: transformData(values?.lotemult, 'number'),
      loterepos: transformData(values?.loterepos, 'number'),
      temporepos: transformData(values?.temporepos, 'number'),
      estmin: transformData(values?.estmin, 'number'),
      estmax: transformData(values?.estmax, 'number'),
      volume: transformData(values?.volume, 'number'),
    };
    dispatch(ProdutoActions.postManutSel(data));
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      fullWidth
      onClose={() => setOpen(!open)}
    >
      <DialogTitle>Manutenção dos Produtos Selecionados</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Linha"
              items={items[0] || []}
              value={values.celinha_id}
              onChange={(v) => setValues({ ...values, celinha_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Grupo"
              items={items[1] || []}
              value={values.cegrupo_id}
              onChange={(v) => setValues({ ...values, cegrupo_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Marca"
              items={items[6] || []}
              value={values.cemarca_id}
              onChange={(v) => setValues({ ...values, cemarca_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="NCM"
              items={items[2] || []}
              value={values.cfiscal_id}
              onChange={(v) => setValues({ ...values, cfiscal_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Origem Tributária"
              items={items[3] || []}
              value={values.origem_tributaria_id}
              onChange={(v) =>
                setValues({ ...values, origem_tributaria_id: v })
              }
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Unidade de Medida"
              items={items[4] || []}
              value={values.unidade_medida_id}
              onChange={(v) => setValues({ ...values, unidade_medida_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <SelectAuto
              label="Tabela de Preço"
              items={items[5] || []}
              value={values.tabpreco_id}
              onChange={(v) => setValues({ ...values, tabpreco_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              label="Margem sobre custo"
              value={values.margemp}
              onChange={(e) =>
                setValues({ ...values, margemp: e.target.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  margemp: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              label="Margem para preço"
              value={values.margemc}
              onChange={(e) =>
                setValues({ ...values, margemc: e.target.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  margemc: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Complemento 1"
              value={values.complemento1}
              onChange={(e) =>
                setValues({ ...values, complemento1: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Complemento 2"
              value={values.complemento2}
              onChange={(e) =>
                setValues({ ...values, complemento2: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Lote Mínimo"
              value={values.lotemin}
              onChange={(e) =>
                setValues({ ...values, lotemin: e.target.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  lotemin: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Lote Múltiplo"
              value={values.lotemult}
              onChange={(e) =>
                setValues({ ...values, lotemult: e.target.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  lotemult: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Lote de Reposição"
              value={values.loterepos}
              onChange={(e) =>
                setValues({ ...values, loterepos: e.target.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  loterepos: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Tempo de Reposição"
              value={values.temporepos}
              onChange={(e) =>
                setValues({ ...values, temporepos: e.target.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  temporepos: setDecimal(e.target.value, 2),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Estoque Mínimo"
              value={values.estmin}
              onChange={(e) => setValues({ ...values, estmin: e.target.value })}
              onBlur={(e) =>
                setValues({
                  ...values,
                  estmin: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Estoque Máximo"
              value={values.estmax}
              onChange={(e) => setValues({ ...values, estmax: e.target.value })}
              onBlur={(e) =>
                setValues({
                  ...values,
                  estmax: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <TextField
              label="Volume"
              value={values.volume}
              onChange={(e) => setValues({ ...values, volume: e.target.value })}
              onBlur={(e) =>
                setValues({
                  ...values,
                  volume: setDecimal(e.target.value, 3),
                })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={postManutSelLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(SelecionadosModal);
