import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import {
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import OfxLancModal from 'components/Modals/OfxLancModal';
import OfxConcModal from 'components/Modals/OfxConcModal';
import OfxBaixaModal from 'components/Modals/OfxBaixaModal';
import Loading from 'components/Loading';
import { setDecimal } from 'util/index';
import moment from 'moment';

const Restrições = ({ match }) => {
  const dispatch = useDispatch();
  const title = 'Lançamento / Conciliação (OFX)';
  const history = useHistory();
  const { state } = useLocation();
  const {
    ofx,
    importOfxLoading,
    putFinConciLoading,
    putFinConciSuccess,
  } = useSelector(({ lancamento }) => lancamento);
  const { postLancamentoLoading, postLancamentoSuccess } = useSelector(
    ({ documento }) => documento
  );

  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(LancamentoActions.importOfxReset());
  }, []);

  useEffect(() => {
    if (postLancamentoSuccess) {
      dispatch(DocumentoActions.postLancamentoReset());
      submitFile(file);
      setOpenModal(null);
    }
  }, [postLancamentoSuccess]);

  useEffect(() => {
    if (putFinConciSuccess) {
      dispatch(LancamentoActions.putFinConciReset());
      submitFile(file);
      setOpenModal(null);
    }
  }, [putFinConciSuccess]);

  const onFileChange = (e) => {
    setFile(e?.target?.files[0]);
    submitFile(e?.target?.files[0]);
  };

  const submitFile = (file) => {
    const formData = new FormData();
    formData.append('xml', file);
    dispatch(LancamentoActions.importOfx(formData));
  };

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={history} />
      <Card className="shadow">
        {!!importOfxLoading ? (
          <Loading />
        ) : (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => document.getElementById('loadOfx')?.click()}
                >
                  Importar
                </Button>
              </Grid>
              {!!ofx?.length && (
                <Grid item xs={12}>
                  <Box sx={{ overflow: 'auto' }} component={Paper}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'table',
                        tableLayout: 'fixed',
                      }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Documento</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell align="center">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ofx?.map((item, index) => (
                            <TableRow key={index?.toString()}>
                              <TableCell>
                                {moment(item?.data).isValid() &&
                                  moment(item?.data).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell>{item?.documento}</TableCell>
                              <TableCell>{item?.memo}</TableCell>
                              <TableCell>
                                {setDecimal(item?.valor, 2)}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  className="m-2"
                                  onClick={() => {
                                    setSelected(item);
                                    setOpenModal('CONCILIAR');
                                  }}
                                >
                                  Conciliar
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  className="m-2"
                                  onClick={() => {
                                    setSelected(item);
                                    setOpenModal('LANCAMENTO');
                                  }}
                                >
                                  Lançar
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  className="m-2"
                                  onClick={() => {
                                    setSelected(item);
                                    setOpenModal('BAIXAR');
                                  }}
                                >
                                  Baixar
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        )}
        <OfxLancModal
          open={openModal === 'LANCAMENTO'}
          setOpen={setOpenModal}
          selected={selected}
          loading={postLancamentoLoading}
          conta={state?.item?.conta_id}
        />
        <OfxConcModal
          open={openModal === 'CONCILIAR'}
          setOpen={setOpenModal}
          selected={selected}
          loading={putFinConciLoading}
          conta={state?.item?.conta_id}
        />
        <OfxBaixaModal
          open={openModal === 'BAIXAR'}
          setOpen={setOpenModal}
          selected={selected}
          conta={state?.item?.conta_id}
          callback={() => submitFile(file)}
        />
      </Card>
      <input
        style={{ display: 'none' }}
        id="loadOfx"
        type="file"
        onChange={onFileChange}
      />
    </div>
  );
};

export default Restrições;
