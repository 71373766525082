import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Creators as AuthActions, Types as AuthTypes } from 'store/ducks/Auth';
import {
  Creators as MidiaActions,
  Types as MidiaTypes,
} from 'store/ducks/Midia';
import apiLogin from '../../util/apiLogin';
import api from '../../util/api';
import { setMenuItems } from '../../util';

function* login({ payload }) {
  const { cnpj, email, password } = payload;

  try {
    const { data, status } = yield call(apiLogin.post, '/service-client', {
      cnpj,
      email,
      password,
    });
    if (status === 200 || status === 201) {
      localStorage.setItem('EpromApp:token', data.api_auth.access_token);
      localStorage.setItem(
        'EpromApp:refreshToken',
        data.api_auth.access_token_refresh
      );
      localStorage.setItem('EpromApp:cliURL', data.api_url);
      if (data.api_auth.storedFundoTelaId) {
        yield put(MidiaActions.getMidia(data.api_auth.storedFundoTelaId));
      }
      const {
        data: { perfil, dashboard },
      } = yield call(api.get, '/user-menu');
      yield put(AuthActions.setMenu(setMenuItems(perfil)));
      yield put(AuthActions.setDashboard(dashboard));
      yield put(AuthActions.loginSuccess(data.api_auth));
    }
  } catch (error) {
    localStorage.clear();
    yield put(AuthActions.loginReset());
  }
}

function* refresh({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/auth/token', payload);
    if (status === 200 || status === 201) {
      localStorage.setItem('EpromApp:token', data.access_token);
      localStorage.setItem('EpromApp:refreshToken', data.access_token_refresh);
      if (data.storedFundoTelaId) {
        yield put(MidiaActions.getMidia(data.storedFundoTelaId));
      }
      const {
        data: { perfil, dashboard },
      } = yield call(api.get, '/user-menu');
      yield put(AuthActions.setMenu(setMenuItems(perfil)));
      yield put(AuthActions.setDashboard(dashboard));
      yield put(AuthActions.loginSuccess(data));
    }
  } catch (error) {
    localStorage.clear();
    window.location.reload();
    yield put(AuthActions.loginReset());
  }
}

function* updateChart({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/supdec-atualizar`, payload);
    if (status === 200 || status === 201) {
      yield put(AuthActions.updateChartSuccess(data.dashboard));
    } else {
      yield put(AuthActions.updateChartError());
    }
  } catch (error) {
    yield put(AuthActions.updateChartError());
  }
}

function* updateUser({ payload }) {
  try {
    const { status } = yield call(api.post, `/user-perfil-acesso`, payload);
    if (status === 200 || status === 201) {
      yield put(AuthActions.updateUserSuccess());
      yield put(
        AuthActions.setAuthUser({
          storedMail: payload?.email,
          storedName: payload?.nome,
        })
      );
    } else {
      yield put(AuthActions.updateUserError());
    }
  } catch (error) {
    yield put(AuthActions.updateUserError());
  }
}

function* loginWatcher() {
  yield takeLatest(AuthTypes.LOGIN, login);
}

function* refreshWatcher() {
  yield takeLatest(AuthTypes.REFRESH, refresh);
}

function* updateChartWatcher() {
  yield takeLatest(AuthTypes.UPDATE_CHART, updateChart);
}

function* updateUserWatcher() {
  yield takeLatest(AuthTypes.UPDATE_USER, updateUser);
}

export default function* rootSaga() {
  yield all([
    fork(loginWatcher),
    fork(updateChartWatcher),
    fork(updateUserWatcher),
    fork(refreshWatcher),
  ]);
}
