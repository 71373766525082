import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from 'util/api';
import { Table } from 'reactstrap';
import moment from 'moment';
import { setDecimal } from 'util/index';
import Loading from 'components/Loading';
import SearchBox from 'components/SearchBox';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const ListaPedOrcModal = ({ open, setOpen, onSubmit, classes }) => {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [tipo, setTipo] = useState('Pedido');
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  async function loadItems() {
    setLoading(true);
    const { data } = await api.post(`/csql/973`);
    if (data) {
      const { dados } = data;
      setItems(dados || []);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Documentos</DialogTitle>
      <DialogContent>
        {!!loading ? (
          <Loading />
        ) : (
          <Grid container>
            <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
              <div className="d-flex align-items-center ">
                <Checkbox
                  color="primary"
                  checked={tipo === 'Pedido'}
                  onChange={() => setTipo('Pedido')}
                />
                <InputLabel>Pedido</InputLabel>
              </div>
            </Grid>
            <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
              <div className="d-flex align-items-center ">
                <Checkbox
                  color="primary"
                  checked={tipo === 'Orçamento'}
                  onChange={() => setTipo('Orçamento')}
                />
                <InputLabel>Orçamento</InputLabel>
              </div>
            </Grid>
            <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
              <SearchBox
                placeholder="Buscar..."
                onChange={(evt) => setSearch(evt.target.value)}
                value={search}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
              <Box sx={{ overflow: 'auto' }} component={Paper}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'table',
                    tableLayout: 'fixed',
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>ID</TableCell>
                        <TableCell>Espécie</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Filial</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items
                        ?.filter(
                          (f) =>
                            f?.especie === tipo &&
                            f?.infopesq
                              ?.toUpperCase()
                              ?.includes(search.toUpperCase())
                        )
                        ?.map((item) => (
                          <TableRow key={item?.id?.toString()}>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                checked={selected?.id === item?.id}
                                onChange={() => {
                                  if (selected?.id === item?.id) {
                                    setSelected(null);
                                  } else {
                                    setSelected(item);
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell>{item?.id}</TableCell>
                            <TableCell>{item?.especie}</TableCell>
                            <TableCell>{item?.nome}</TableCell>
                            <TableCell>{setDecimal(item?.valor, 2)}</TableCell>
                            <TableCell>
                              {item?.data &&
                                moment(item?.data).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>{item?.status}</TableCell>
                            <TableCell>{item?.filial}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(selected, 26)} color="primary">
          Emitir Cupom
        </Button>
        <Button onClick={() => onSubmit(selected, 10)} color="primary">
          Emitir Nota Fiscal
        </Button>
        <Button onClick={() => onSubmit(selected, 25)} color="primary">
          Emitir Fatura
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ListaPedOrcModal);
