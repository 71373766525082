import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Creators as IntegraActions } from 'store/ducks/Integra';
import { useDispatch } from 'react-redux';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '70%',
  },
});

const IntegraDocModal = ({ open, setOpen, integra, classes }) => {
  const dispatch = useDispatch();
  const [documento, setDocumento] = useState('');
  const [acao, setAcao] = useState('');

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Integrar Documentos</DialogTitle>
      <DialogContent>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <TextField
            label="Documento"
            value={documento}
            onChange={(e) => setDocumento(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <SelectAuto
            label="Ação"
            items={[
              { value: 'insert', label: 'INSERIR' },
              { value: 'update', label: 'ATUALIZAR' },
            ]}
            value={acao}
            onChange={(v) => setAcao(v)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (integra === '2RS') {
              dispatch(IntegraActions.postIntegra2rs(documento));
            } else if (integra === 'TRAY') {
              dispatch(
                IntegraActions.postNotificacao({
                  id: Number(documento),
                  data: {
                    scope_name: 'order',
                    act: acao?.value,
                  },
                })
              );
            }
          }}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(IntegraDocModal);
