export const Types = {
  SET_FILTER_RESUMO: 'SET_FILTER_RESUMO',
};

const INIT_STATE = {
  filter: null,
};

export default function Resumo(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.SET_FILTER_RESUMO: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  setFilterResumo: (payload) => ({
    type: Types.SET_FILTER_RESUMO,
    payload,
  }),
};
