import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from 'util/api';
import { setDecimal, transformData } from 'util/index';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const CompensacaoModal = ({
  open,
  setOpen,
  onSubmit,
  movimento,
  isRecebimento,
  teste,
  classes,
}) => {
  const [items, setItems] = useState([]);
  const [compensacao, setCompensacao] = useState([]);

  async function loadItems() {
    const { data } = await api.post(`/csql/293`, {
      WhereClause: `and d.cadastro_id = ${
        movimento[0]?.cadastro_id
      } and n.operacao=${
        isRecebimento ? "'ENTRADA'" : "'SAIDA'"
      } and abs(f.valor)-
      abs(coalesce((select sum(fh.valor) from financeiro_historico fh 
      where fh.financeiro_id=f.id),0))>0`,
    });
    if (data) {
      setItems(data.dados || []);
    }
  }

  useEffect(() => {
    if (open) {
      loadItems();
    } else {
      setCompensacao([]);
    }
  }, [open]);

  const parseSubmit = () => {
    const valor = setDecimal(
      compensacao?.length
        ? compensacao
            ?.map((i) => transformData(setDecimal(i?.valor, 2), 'number'))
            ?.reduce((prev, next) => prev + next) > teste
          ? teste
          : compensacao
              ?.map((i) => transformData(setDecimal(i?.valor, 2), 'number'))
              ?.reduce((prev, next) => prev + next)
        : '0',
      2
    );
    const data = {
      valor,
      compensacao: compensacao?.map((c) => ({
        id: c?.id,
        valor: setDecimal(c?.valor, 2),
      })),
    };
    if (transformData(data?.valor, 'number') > 0) {
      onSubmit(data);
    }
    setOpen(null);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Registros de Compensação</DialogTitle>
      <DialogContent>
        {!!items?.length ? (
          <TableContainer component={Paper} className="my-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Filial</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell>Data de Emissão</TableCell>
                  <TableCell>Data de Vencimento</TableCell>
                  <TableCell>Parcela</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Valor Pago</TableCell>
                  <TableCell>Valor à Baixar</TableCell>
                  <TableCell>Valor Outros</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map((i) => (
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        style={{ padding: '0 5px' }}
                        color="default"
                        checked={compensacao?.some((c) => c?.id === i?.id)}
                        onChange={() => {
                          if (compensacao?.some((c) => c?.id === i?.id)) {
                            setCompensacao((prev) =>
                              prev?.filter((f) => f?.id !== i?.id)
                            );
                          } else {
                            setCompensacao((prev) => [...prev, i]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{i?.filial}</TableCell>
                    <TableCell>{i?.documento}</TableCell>
                    <TableCell>
                      {moment(i?.dtemissao).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(i?.dtvenc).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{i?.parcela}</TableCell>
                    <TableCell>{setDecimal(i?.valor, 2)}</TableCell>
                    <TableCell>{setDecimal(i?.vpago, 2)}</TableCell>
                    <TableCell>{setDecimal(i?.vlrbaixarc, 2)}</TableCell>
                    <TableCell>{setDecimal(i?.voutros, 2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <DialogContentText>Nenhum registro encontrado</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row align-items-end">
          <Button variant="contained" color="primary" onClick={parseSubmit}>
            SALVAR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="ml-2"
            onClick={() => setOpen(null)}
          >
            FECHAR
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CompensacaoModal);
