import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProdutoActions } from '../../../store/ducks/Produto';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const CopiarModal = ({ open, setOpen, produto_id, type, classes }) => {
  const dispatch = useDispatch();
  const [produto, setProduto] = useState(null);
  const [items, setItems] = useState([]);
  const { postCopiaLoading, postCopiaSuccess } = useSelector(
    ({ produto }) => produto
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([712]);
    }
  }, [open]);

  useEffect(() => {
    if (postCopiaSuccess) {
      dispatch(ProdutoActions.postCopiaReset());
      setOpen(!open);
    }
  }, [postCopiaSuccess]);

  const onSubmit = () => {
    let data = {
      origem_id: Number(produto_id),
      destino_id: produto?.value,
    };

    dispatch(ProdutoActions.postCopia({ data, type }));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>
        Copiar {type === 'ESTRUTURA' ? 'Estrutura' : 'Processo'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <DialogContentText>
            Selecione para qual produto deseja copiar{' '}
            {type === 'ESTRUTURA' ? 'a estrutura' : 'o processo'}
          </DialogContentText>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              name="produto"
              label="Produto"
              items={items[0] || []}
              value={produto}
              onChange={(v) => setProduto(v)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmit()}
          color="primary"
          disabled={postCopiaLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CopiarModal);
