import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as LancamentoActions,
  Types as LancamentoTypes,
} from 'store/ducks/Lancamento';
import api from '../../util/api';

function* postFinHist({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/financeiro-historico`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(
        LancamentoActions.postFinHistSuccess(
          data?.movimento?.mov || data?.movimento
        )
      );
    } else {
      yield put(LancamentoActions.postFinHistError());
    }
  } catch (error) {
    yield put(LancamentoActions.postFinHistError());
  }
}

function* getFinMov({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `financeiro-movimento/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.getFinMovSuccess(data?.mov));
    } else {
      yield put(LancamentoActions.getFinMovError());
    }
  } catch (error) {
    yield put(LancamentoActions.getFinMovError());
  }
}

function* postFinOprd({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro-operadora`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postFinOprdSuccess());
    } else {
      yield put(LancamentoActions.postFinOprdError());
    }
  } catch (error) {
    yield put(LancamentoActions.postFinOprdError());
  }
}

function* postFinCheque({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro-cheque`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postFinChequeSuccess());
    } else {
      yield put(LancamentoActions.postFinChequeError());
    }
  } catch (error) {
    yield put(LancamentoActions.postFinChequeError());
  }
}

function* postFin({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postFinSuccess());
    } else {
      yield put(LancamentoActions.postFinError());
    }
  } catch (error) {
    yield put(LancamentoActions.postFinError());
  }
}

function* postFinComp({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro-compensacao`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postFinCompSuccess());
    } else {
      yield put(LancamentoActions.postFinCompError());
    }
  } catch (error) {
    yield put(LancamentoActions.postFinCompError());
  }
}

function* postConclusao({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      `/financeiro-concluimovimento`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postConclusaoSuccess(data));
    } else {
      yield put(LancamentoActions.postConclusaoError());
    }
  } catch (error) {
    yield put(LancamentoActions.postConclusaoError());
  }
}

function* putFinForma({ payload }) {
  try {
    const { status } = yield call(api.put, `/financeiro-formapg`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.putFinFormaSuccess());
    } else {
      yield put(LancamentoActions.putFinFormaError());
    }
  } catch (error) {
    yield put(LancamentoActions.putFinFormaError());
  }
}

function* putFinConci({ payload }) {
  try {
    const { status } = yield call(api.put, `/financeiro-conciliar`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.putFinConciSuccess());
    } else {
      yield put(LancamentoActions.putFinConciError());
    }
  } catch (error) {
    yield put(LancamentoActions.putFinConciError());
  }
}

function* putExportText({ payload }) {
  try {
    const { status } = yield call(api.put, `/exporta-texto`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.putExportTextSuccess());
    } else {
      yield put(LancamentoActions.putExportTextReset());
    }
  } catch (error) {
    yield put(LancamentoActions.putExportTextReset());
  }
}

function* getFinLancto({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/financeiro-cxlanctos/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.getFinLanctoSuccess(data?.Lancamento));
    } else {
      yield put(LancamentoActions.getFinLanctoError());
    }
  } catch (error) {
    yield put(LancamentoActions.getFinLanctoError());
  }
}

function* postFinChequeDev({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro-chequedev`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postFinChequeDevSuccess());
    } else {
      yield put(LancamentoActions.postFinChequeDevError());
    }
  } catch (error) {
    yield put(LancamentoActions.postFinChequeDevError());
  }
}

function* putCancelaMov({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `/financeiro-cancelamovimento/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.putCancelaMovSuccess());
    } else {
      yield put(LancamentoActions.putCancelaMovReset());
    }
  } catch (error) {
    yield put(LancamentoActions.putCancelaMovReset());
  }
}

function* postCancelaVenda({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/sat-web/${payload}/cancelar-ultima-venda`
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postCancelaVendaSuccess());
    } else {
      yield put(LancamentoActions.postCancelaVendaReset());
    }
  } catch (error) {
    yield put(LancamentoActions.postCancelaVendaReset());
  }
}

function* postExcel({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      `/financeiro-baixa-ecommerce`,
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postExcelSuccess(data?.registros));
    } else {
      yield put(LancamentoActions.postExcelReset());
    }
  } catch (error) {
    yield put(LancamentoActions.postExcelReset());
  }
}

function* postBaixaHaver({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro-baixa-haver`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postBaixaHaverSuccess());
    } else {
      yield put(LancamentoActions.postBaixaHaverReset());
    }
  } catch (error) {
    yield put(LancamentoActions.postBaixaHaverReset());
  }
}

function* postCalendario({ payload }) {
  try {
    const { status } = yield call(api.post, `/financeiro-calendario`, payload);
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postCalendarioSuccess());
    } else {
      yield put(LancamentoActions.postCalendarioReset());
    }
  } catch (error) {
    yield put(LancamentoActions.postCalendarioReset());
  }
}

function* postCalendarioMoeda({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/financeiro-calendariomoeda`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.postCalendarioMoedaSuccess());
    } else {
      yield put(LancamentoActions.postCalendarioMoedaReset());
    }
  } catch (error) {
    yield put(LancamentoActions.postCalendarioMoedaReset());
  }
}

function* getMovimento({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/financeiro-getmovimento`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(LancamentoActions.getMovimentoSuccess(data?.movimento || []));
    } else {
      yield put(LancamentoActions.getMovimentoReset());
    }
  } catch (error) {
    yield put(LancamentoActions.getMovimentoReset());
  }
}

function* getLancDetalhe({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/contabil-detalhamento/${payload}`
    );
    if (status === 200 || status === 201) {
      yield put(
        LancamentoActions.getLancDetalheSuccess(data?.detalhes || null)
      );
    } else {
      yield put(LancamentoActions.getLancDetalheReset());
    }
  } catch (error) {
    yield put(LancamentoActions.getLancDetalheReset());
  }
}

function* importOfx({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/financeiro-importofx`,
      payload,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    if (status === 200 || status === 201) {
      yield put(LancamentoActions.importOfxSuccess(data?.json || []));
    } else {
      yield put(LancamentoActions.importOfxReset());
    }
  } catch (error) {
    yield put(LancamentoActions.importOfxReset());
  }
}

function* postFinHistWatcher() {
  yield takeLatest(LancamentoTypes.POST_FIN_HIST, postFinHist);
}

function* getFinMovWatcher() {
  yield takeLatest(LancamentoTypes.GET_FIN_MOV, getFinMov);
}

function* postFinOprdWatcher() {
  yield takeLatest(LancamentoTypes.POST_FIN_OPRD, postFinOprd);
}

function* postFinChequeWatcher() {
  yield takeLatest(LancamentoTypes.POST_FIN_CHEQUE, postFinCheque);
}

function* postFinWatcher() {
  yield takeLatest(LancamentoTypes.POST_FIN, postFin);
}

function* postFinCompWatcher() {
  yield takeLatest(LancamentoTypes.POST_FIN_COMP, postFinComp);
}

function* postConclusaoWatcher() {
  yield takeLatest(LancamentoTypes.POST_CONCLUSAO, postConclusao);
}

function* putFinFormaWatcher() {
  yield takeLatest(LancamentoTypes.PUT_FIN_FORMA, putFinForma);
}

function* putFinConciWatcher() {
  yield takeLatest(LancamentoTypes.PUT_FIN_CONCI, putFinConci);
}

function* putExportTextWatcher() {
  yield takeLatest(LancamentoTypes.PUT_EXPORT_TEXT, putExportText);
}

function* getFinLanctoWatcher() {
  yield takeLatest(LancamentoTypes.GET_FIN_LANCTO, getFinLancto);
}

function* postFinChequeDevWatcher() {
  yield takeLatest(LancamentoTypes.POST_FIN_CHEQUE_DEV, postFinChequeDev);
}

function* putCancelaMovWatcher() {
  yield takeLatest(LancamentoTypes.PUT_CANCELA_MOV, putCancelaMov);
}

function* postCancelaVendaWatcher() {
  yield takeLatest(LancamentoTypes.POST_CANCELA_VENDA, postCancelaVenda);
}

function* postExcelWatcher() {
  yield takeLatest(LancamentoTypes.POST_EXCEL, postExcel);
}

function* postBaixaHaverWatcher() {
  yield takeLatest(LancamentoTypes.POST_BAIXA_HAVER, postBaixaHaver);
}

function* postCalendarioWatcher() {
  yield takeLatest(LancamentoTypes.POST_CALENDARIO, postCalendario);
}

function* postCalendarioMoedaWatcher() {
  yield takeLatest(LancamentoTypes.POST_CALENDARIO_MOEDA, postCalendarioMoeda);
}

function* getMovimentoWatcher() {
  yield takeLatest(LancamentoTypes.GET_MOVIMENTO, getMovimento);
}

function* getLancDetalheWatcher() {
  yield takeLatest(LancamentoTypes.GET_LANC_DETALHE, getLancDetalhe);
}

function* importOfxWatcher() {
  yield takeLatest(LancamentoTypes.IMPORT_OFX, importOfx);
}

export default function* rootSaga() {
  yield all([
    fork(postFinHistWatcher),
    fork(getFinMovWatcher),
    fork(postFinOprdWatcher),
    fork(postFinChequeWatcher),
    fork(postFinWatcher),
    fork(postFinCompWatcher),
    fork(postConclusaoWatcher),
    fork(putFinFormaWatcher),
    fork(putFinConciWatcher),
    fork(putExportTextWatcher),
    fork(getFinLanctoWatcher),
    fork(postFinChequeDevWatcher),
    fork(putCancelaMovWatcher),
    fork(postCancelaVendaWatcher),
    fork(postExcelWatcher),
    fork(postBaixaHaverWatcher),
    fork(postCalendarioWatcher),
    fork(postCalendarioMoedaWatcher),
    fork(getMovimentoWatcher),
    fork(getLancDetalheWatcher),
    fork(importOfxWatcher),
  ]);
}
