import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { transformData, setDecimal, findOnArray } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Paper,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {},
  button: {
    margin: '0 5px',
  },
  infoContainer: {
    margin: '0 0 20px 0',
    padding: '1rem',
    backgroundColor: '#c5c5c5',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0 5px',
  },
});

const OSResumoValores = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  async function loadItems() {
    const clause = {
      WhereClause: `and di.documento_id = ${selected?.documento_id}`,
    }
    const { data } = await api.post(`/csql/975`, clause);
    if (data) {
      const { dados } = data;
      setItems(dados[0]);
    }
  }

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <>
        <DialogTitle>Resumo de Valores da Ordem de Serviço</DialogTitle>
        <DialogContent>
          <Paper className={classes.infoContainer}>
            <DialogContentText color="primary">
              Tempo apontado: {items?.tempo}
            </DialogContentText>
            <DialogContentText color="primary">
              Serviços: {setDecimal(items?.servico, 2)}
            </DialogContentText>
            <DialogContentText color="primary">
              Peças: {setDecimal(items?.peca, 2)}
            </DialogContentText>
            <DialogContentText color="primary">
              Apontamentos: {setDecimal(items?.apontamento, 2)}
            </DialogContentText>
            <DialogContentText color="primary">
              Insumos: {setDecimal(items?.insumo, 2)}
            </DialogContentText>
            <DialogContentText color="secondary">
              Total: {setDecimal(Number(items?.servico) + Number(items?.peca) + Number(items?.apontamento) + Number(items?.insumo), 2)}
            </DialogContentText>
            <DialogContentText color="primary">
              Financeiro programado: {setDecimal(items?.financeiroprog, 2)}
            </DialogContentText>
            <DialogContentText color="primary">
              Financeiro recebido: {setDecimal(items?.financeirorec, 2)}
            </DialogContentText>


          </Paper>
        </DialogContent>
        <DialogActions>
          <div className="d-flex flex-row align-items-end">
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => setOpen(!open)}

            >
              FECHAR
                </Button>
          </div>
        </DialogActions>
      </>
    </Dialog>
  );
};
export default withStyles(styles)(OSResumoValores);
