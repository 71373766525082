import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { replaceConteudoi } from 'util/index';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import moment from 'moment';
import api from 'util/api';

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NAO' },
];

const inventarioDrop = [
  { value: 1, label: '01 – No final no período' },
  {
    value: 2,
    label: '02 – Na mudança de forma de tributação da mercadoria (ICMS)',
  },
  {
    value: 3,
    label:
      '03 – Na solicitação da baixa cadastral, paralisação temporária e outras situações',
  },
  {
    value: 4,
    label:
      '04 – Na alteração de regime de pagamento – condição do contribuinte',
  },
  { value: 5, label: '05 – Por determinação dos fiscos' },
  {
    value: 6,
    label:
      '06 – Para controle das mercadorias sujeitas ao regime de substituição tributária – restituição / ressarcimento / complementação',
  },
];

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const GerarDadosIpiModal = ({ open, setOpen, filtros, rotina, classes }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const onSend = async (values) => {
    try {
      setLoading(true)
      let dt = (p) =>
        replaceConteudoi(
          filtros.find((f) => f.titulo.toUpperCase() === p)?.conteudoi
        );
      const data = {
        filiali_id: filtros.find(
          (f) => f.titulo.toUpperCase() === 'FILIAL INICIO'
        )?.conteudoi,
        filialf_id: filtros.find((f) => f.titulo.toUpperCase() === 'FILIAL FIM')
          ?.conteudoi,
        retificadora: values?.retificadora?.value,
        c170: values?.completo?.value,
        dtinicio: moment(dt('PERIODO INICIO'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
        dtfim: moment(dt('PERIODO FIM'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
        inventario: values?.inventario?.value,
        dtinv: moment(values?.dtinv, 'DD/MM/YYYY').isValid()
          ? moment(values?.dtinv, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
      };
      await api.post(`/fiscal-efd`, data);
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    } catch (error) {
    } finally {
      setLoading(false)
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          retificadora: null,
          completo: null,
          inventario: null,
          dtinv: null,
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <>
            <DialogTitle>Gerar Dados</DialogTitle>
            {loading ? (
              <Loading />
            ) : (
              <DialogContent>
                <Grid container>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Retificadora"
                      items={yn}
                      value={values.retificadora}
                      onChange={(v) => setFieldValue('retificadora', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Completo (Com Registro 170)"
                      items={yn}
                      value={values.completo}
                      onChange={(v) => setFieldValue('completo', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Motivo do Inventário"
                      items={inventarioDrop}
                      value={values.inventario}
                      onChange={(v) => setFieldValue('inventario', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <InputMask
                      mask="99/99/9999"
                      name="dtinv"
                      label="Data do Inventário"
                      value={values.dtinv}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={() => onSend(values)}
                color="primary"
                disabled={loading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(GerarDadosIpiModal);
