import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { findOnArray } from 'util/index';
import { withStyles } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import api from 'util/api';
import InputMask from 'components/InputMask';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as IbgeActions } from '../../../store/ducks/Ibge';
import { Creators as EntidadesActions } from '../../../store/ducks/Entidades';

const styles = () => ({
  paper: {
    minWidth: '90%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const EnderecoModal = ({
  open,
  setOpen,
  identificacao,
  doc,
  refresh,
  classes,
}) => {
  const dispatch = useDispatch();
  const [UF, setUF] = useState(null);
  const [items, setItems] = useState([]);
  const { estados, cidades, dataCep } = useSelector(({ ibge }) => ibge);
  const { putLoading, putSuccess } = useSelector(({ entidades }) => entidades);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([849]);
      dispatch(IbgeActions.getCepReset());
      dispatch(IbgeActions.getEstados());
    }
  }, [open]);

  useEffect(() => {
    if (open && UF && UF !== null) {
      dispatch(IbgeActions.getCidades({ uf: UF.value || UF }));
    }
  }, [UF]);

  const SetFields = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (dataCep) {
        setFieldValue('estado', findOnArray(dataCep?.uf, estados));
        setUF(findOnArray(dataCep?.uf, estados));
      }
    }, [dataCep]);

    useEffect(() => {
      if (dataCep && !!cidades.length) {
        setFieldValue('cidade', findOnArray(Number(dataCep?.ibge), cidades));
        setFieldValue('bairro', dataCep?.bairro);
        setFieldValue('logradouro', dataCep?.logradouro);
        dispatch(IbgeActions.getCepReset());
      }
    }, [dataCep, cidades]);

    return null;
  };

  useEffect(() => {
    if (putSuccess) {
      refresh();
      dispatch(EntidadesActions.putEntidadeReset());
      dispatch(
        EntidadesActions.getIdentificacao(doc.replace(/([\/\.\-])/g, ''))
      );
      setOpen(false);
    }
  }, [putSuccess]);

  const onSubmit = (values) => {
    const data = {
      Enderecos: [
        {
          entidade_id: identificacao?.Entidade?.id,
          tipo_endereco_id: values?.tipo?.value,
          logradouro: values?.logradouro,
          numero: values?.numero,
          complemento: values?.complemento,
          bairro: values?.bairro,
          uf: values?.estado?.value,
          cep: values?.cep,
          principal: 'NAO',
          codigo_ibge: values?.cidade?.value,
        },
      ],
    };

    dispatch(
      EntidadesActions.putEntidade({ id: identificacao?.Entidade?.id, data })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          tipo: null,
          cep: null,
          estado: null,
          cidade: null,
          bairro: null,
          logradouro: null,
          numero: null,
          complemento: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <DialogTitle>Endereço</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Tipo de Endereço"
                    items={items[0] || []}
                    value={values.tipo}
                    onChange={(v) => setFieldValue('tipo', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="cep"
                    label="CEP"
                    mask="99.999-999"
                    value={values.cep}
                    onChange={(e) => {
                      const v = e.target.value.replace(/[.-]/g, '');
                      if (v.length === 8) dispatch(IbgeActions.getCep(v));
                      setFieldValue('cep', v);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Estado"
                    items={estados}
                    value={values.estado}
                    onChange={(v) => {
                      setUF(v);
                      setFieldValue('estado', v);
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Cidade"
                    items={cidades}
                    value={values.cidade}
                    onChange={(v) => setFieldValue('cidade', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="bairro"
                    label="Bairro"
                    value={values.bairro || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="logradouro"
                    label="Endereço"
                    value={values.logradouro || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="numero"
                    label="Número"
                    value={values.numero}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="complemento"
                    label="Complemento"
                    value={values.complemento}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onSubmit(values)}
                color="primary"
                disabled={putLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(EnderecoModal);
