import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import InputMask from 'components/InputMask';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const WhatsappModal = ({ open, setOpen, selected, onSubmit, classes }) => {
  const [values, setValues] = useState({
    whatsapp: null,
  });

  useEffect(() => {
    if (selected) {
      setValues({
        whatsapp: selected?.whatsapp,
      });
    }
  }, [selected]);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Envio</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <InputMask
              label="WhatsApp"
              mask="(99) 99999-9999"
              value={values.whatsapp || ''}
              onChange={(e) =>
                setValues({ ...values, whatsapp: e?.target?.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onSubmit(values.whatsapp)}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(WhatsappModal);
