export const Types = {
  GET_CATALOGO: 'GET_CATALOGO',
  GET_CATALOGO_SUCCESS: 'GET_CATALOGO_SUCCESS',
  GET_CATALOGO_RESET: 'GET_CATALOGO_RESET',
};

const INITIAL_STATE = {
  getLoading: false,
  catalogo: [],
};

export default function catalogo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CATALOGO:
      return {
        ...state,
        getLoading: true,
        catalogo: [],
      };
    case Types.GET_CATALOGO_SUCCESS:
      return {
        ...state,
        getLoading: false,
        catalogo: action.payload.catalogo,
      };
    case Types.GET_CATALOGO_RESET:
      return {
        ...state,
        getLoading: false,
        catalogo: [],
      };
    default:
      return state;
  }
}

export const Creators = {
  getCatalogo: (payload) => ({
    type: Types.GET_CATALOGO,
    payload,
  }),
  getCatalogoSuccess: (payload) => ({
    type: Types.GET_CATALOGO_SUCCESS,
    payload,
  }),
  getCatalogoReset: () => ({
    type: Types.GET_CATALOGO_RESET,
  }),
};
