import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { findOnArray } from 'util/index';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const DinheiroModal = ({ open, setOpen, onSubmit, movimento, classes }) => {
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    cxconta_id: null,
  });

  async function loadItems() {
    const { data } = await api.post(`/csql/607`);
    if (data) {
      setItems(data.dados || []);
    }
  }

  useEffect(() => {
    if (open) {
      loadItems();
    } else {
      setValues({
        cxconta_id: null,
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && !!items?.length) {
      setValues({
        cxconta_id: findOnArray(movimento[0]?.cxconta_id, items) || null,
      });
    }
  }, [open, items]);

  const parseSubmit = () => {
    const data = {
      cxconta_id: values?.cxconta_id?.value,
    };
    onSubmit(data);
    setOpen(null);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Caixa / Banco</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Caixa / Banco"
              items={items}
              value={values.cxconta_id}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, cxconta_id: v }))
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row align-items-end">
          <Button variant="contained" color="primary" onClick={parseSubmit}>
            SALVAR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="ml-2"
            onClick={() => setOpen(null)}
          >
            FECHAR
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DinheiroModal);
