import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const CartaoModal = ({ open, setOpen, formaPagto, onSubmit, classes }) => {
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    operadora: null,
    parcelas: '',
    autenticacao: '',
  });

  async function loadItems(valor) {
    const { data } = await api.post(`/csql/748`, {
      Parametros: [{ nome: ':tipocartao', valor }],
    });
    if (data) {
      setItems(data.dados || []);
    }
  }

  useEffect(() => {
    if (open) {
      const param =
        formaPagto === '03-CARTAO DE CREDITO'
          ? 'CREDITO'
          : formaPagto === '04-CARTAO DE DEBITO'
          ? 'DEBITO'
          : 'TICKET';
      loadItems(param);
    } else {
      setValues({
        operadora: null,
        parcelas: '',
        autenticacao: '',
      });
    }
  }, [open]);

  const parseSubmit = () => {
    const data = {
      entidade_operadora_id: values?.operadora?.value,
      parcelas: Number(values?.parcelas),
      autenticacao: values?.autenticacao,
    };
    onSubmit(data);
    setOpen(null);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(null)} fullWidth>
      <DialogTitle>
        Dados do {open === 'TICKET' ? 'Ticket' : 'Cartão'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Operadora"
              items={items}
              value={values.operadora}
              onChange={(v) => setValues((prev) => ({ ...prev, operadora: v }))}
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              name="parcelas"
              label="Parcelas"
              value={values.parcelas}
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, parcelas: value }));
              }}
              onBlur={(e) => {
                const { value } = e?.target;
                if (Number(value) > values?.operadora?.parcelas) {
                  setValues((prev) => ({
                    ...prev,
                    parcelas: values?.operadora?.parcelas,
                  }));
                } else {
                  setValues((prev) => ({
                    ...prev,
                    parcelas: value,
                  }));
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              name="autenticacao"
              label="Autenticação"
              value={values.autenticacao}
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({
                  ...prev,
                  autenticacao: value,
                }));
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row align-items-end">
          <Button variant="contained" color="primary" onClick={parseSubmit}>
            SALVAR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="ml-2"
            onClick={() => setOpen(null)}
          >
            FECHAR
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CartaoModal);
