import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import AddItemModal from 'components/Modals/AddItemModal';
import DescontoModal from 'components/Modals/DescontoModal';
import api from 'util/api';
import { setDecimal, useQuery } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  subtitle: {
    padding: '0 0.5rem',
  },
  modal: {
    maxWidth: '80%',
  },
  acc: {
    cursor: 'default',
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titles: {
    margin: 0,
    fontWeight: 600,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '5px',
    padding: '15px',
  },
  valor: {
    margin: '15px 0 0 0',
    textAlign: 'center',
  },
  grPlus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  cell: {
    color: 'red',
  },
});

const Itens = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [items, setItems] = useState([]);
  const [menuOpcoes, setMenuOpcoes] = useState({ open: false });
  const [itemOpcoes, setItemOpcoes] = useState({ open: false });
  const [localLoading, setLocalLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openDesconto, setOpenDesconto] = useState(null);
  const [selected, setSelected] = useState(null);
  const { authUser } = useSelector(({ auth }) => auth);
  const param = query.get('id');
  const steps = ['Documento', 'Itens', 'Pagamento', 'Entrega'];
  const {
    itensFields,
    documento,
    loading,
    deleteDocItemSuccess,
    attPrecoItemSuccess,
    postOutrosSuccess,
    postCfoSuccess,
    postTributoSuccess,
  } = useSelector(({ documento }) => documento);
  const produtos = documento?.Itens || [];

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([
      642,
      621,
      633,
      616,
      636,
      658,
      709,
      646,
      632,
      816,
      817,
      614,
      880,
    ]);
    if (param) {
      dispatch(DocumentoActions.getDocumento(param));
    }
  }, []);

  useEffect(() => {
    if (documento) {
      dispatch(
        DocumentoActions.getFields({
          Parametros: [{ nome: ':id', valor: `${documento?.Documento?.id}` }],
        })
      );
      dispatch(
        DocumentoActions.getPlanoConta({
          Parametros: [
            { nome: ':filial_id', valor: `${documento?.Documento?.filial_id}` },
          ],
        })
      );
      dispatch(DocumentoActions.getProdDoc());
    }
  }, [documento]);

  useEffect(() => {
    if (deleteDocItemSuccess) {
      dispatch(DocumentoActions.deleteDocItemReset());
      dispatch(DocumentoActions.getDocumento(param));
    }
    if (attPrecoItemSuccess) {
      dispatch(DocumentoActions.attPrecoItemReset());
      dispatch(DocumentoActions.getDocumento(param));
    }
    if (postOutrosSuccess) {
      dispatch(DocumentoActions.postItemOutrosReset());
      dispatch(DocumentoActions.getDocumento(param));
    }
    if (postCfoSuccess) {
      dispatch(DocumentoActions.postCfoReset());
      dispatch(DocumentoActions.getDocumento(param));
    }
    if (postTributoSuccess) {
      dispatch(DocumentoActions.postTributoReset());
      dispatch(DocumentoActions.getDocumento(param));
    }
  }, [
    deleteDocItemSuccess,
    attPrecoItemSuccess,
    postOutrosSuccess,
    postCfoSuccess,
    postTributoSuccess,
  ]);

  const getParams = (itemId, prdId) => {
    let filial = documento?.Documento?.filial_id;
    let cadastro = documento?.Documento?.cadastro_id;
    let produto = prdId || selected?.produto_id;
    let operacao = documento?.Documento?.doc_operacao;
    let devolucao = documento?.Documento?.doc_devolucao;
    let terceiro = documento?.Documento?.doc_terceiro;

    if (operacao === 'SAIDA') {
      operacao = 'ENTRADA';
    } else {
      operacao = 'SAIDA';
    }

    if (devolucao === 'SIM') {
      operacao = null;
    }

    if (terceiro !== 'SIM' && devolucao !== 'SIM') {
      produto = null;
      cadastro = null;
      operacao = null;
    }

    let string = `?id=${documento?.Documento?.id}&filial=${filial}`;

    if (itemId) {
      string = string + `&itemId=${itemId}`;
    }
    if (cadastro) {
      string = string + `&entidade=${cadastro}`;
    }
    if (produto) {
      string = string + `&produto=${produto}`;
    }
    if (operacao) {
      string = string + `&operacao='${operacao}'`;
    }
    return string;
  };

  if (localLoading || loading || !documento) {
    return <Loading />;
  }

  return (
    <div className="app-wrapper">
      <HeaderPage title="Itens do Documento" history={param && history} />
      <Card className="shadow">
        <CardContent>
          <Grid container>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Espécie:</span>
              <span> {documento?.Documento?.doc_especie}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Natureza da Operação:</span>
              <span>{documento?.Documento?.doc_naturezaop}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Documento:</span>
              <span> {documento?.Documento?.id}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Entidade:</span>
              <span> {documento?.Documento?.doc_nome}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Status:</span>
              <span> {documento?.Documento?.doc_status}</span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className="shadow" style={{ marginTop: '1rem' }}>
        <CardContent>
          <Stepper activeStep={1} alternativeLabel className="p-0">
            {steps.map((label) => {
              return (
                <Step
                  key={label}
                  onClick={() => {
                    if (param) {
                      history.push(
                        `/app/Cadastros/Documento${
                          label !== 'Documento' ? `/${label}` : ''
                        }?id=${param}`
                      );
                    }
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </CardContent>
      </Card>
      <Card className="shadow" style={{ marginTop: '1rem' }}>
        <CardContent>
          <div className="my-4">
            {documento?.Documento?.sleitura !== 'SIM' && (
              <div className="d-flex w-100 justify-content-end mb-4">
                <Button
                  variant="outlined"
                  color="primary"
                  className="jr-btn"
                  onClick={({ currentTarget }) => {
                    setMenuOpcoes({ open: true, currentTarget });
                  }}
                >
                  <MoreHorizIcon />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="jr-btn"
                  onClick={() => setOpenModal(!openModal)}
                >
                  ADICIONAR PRODUTO
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="jr-btn"
                  onClick={() => history.push(`/app/Catalogo?id=${param}`)}
                >
                  <MenuBookIcon />
                </Button>
              </div>
            )}
            <Menu
              id="long-menu"
              anchorEl={menuOpcoes?.currentTarget}
              open={menuOpcoes?.open}
              onClose={() => setMenuOpcoes({ open: false })}
              MenuListProps={{
                style: {
                  padding: 0,
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  setMenuOpcoes({ open: false });
                  setOpenDesconto('doc');
                }}
              >
                Desconto / Outros / Margem / Adicionais
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(
                    DocumentoActions.attPrecoItem(documento?.Documento.id)
                  );
                  setMenuOpcoes({ open: false });
                }}
              >
                Atualizar Preço
              </MenuItem>
              {(itensFields[0]?.devolucao === 'SIM' ||
                itensFields[0]?.terceiro === 'SIM' ||
                itensFields[0]?.vinculo === 'SIM') && (
                <MenuItem
                  onClick={() => {
                    history.push(`/app/Devolução${getParams()}`);
                    setMenuOpcoes({ open: false });
                  }}
                >
                  Selecionar documentos vinculados
                </MenuItem>
              )}
              {documento?.Documento?.doc_fiscal === 'SIM' && (
                <MenuItem
                  onClick={() => {
                    setMenuOpcoes({ open: false });
                    dispatch(
                      DocumentoActions.postCfo({ id: documento?.Documento?.id })
                    );
                  }}
                >
                  Carregar CFO
                </MenuItem>
              )}
              {documento?.Documento?.doc_fiscal === 'SIM' && (
                <MenuItem
                  onClick={() => {
                    setMenuOpcoes({ open: false });
                    dispatch(
                      DocumentoActions.postTributo({
                        documento_id: documento?.Documento?.id,
                      })
                    );
                  }}
                >
                  Carregar Tributação
                </MenuItem>
              )}
              {documento?.Documento?.doc_fiscal === 'SIM' && (
                <MenuItem
                  onClick={() =>
                    history.push(
                      `/app/DocItem/Tributação/TributacaoDocumento?id=${documento?.Documento?.id}`
                    )
                  }
                >
                  Tributação
                </MenuItem>
              )}
            </Menu>
            <div style={{ overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Seq</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Referência</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Quantidade</TableCell>
                    {itensFields[0]?.iipreco === 'SIM' && (
                      <TableCell align="right">Preço</TableCell>
                    )}
                    {itensFields[0]?.iipreco === 'SIM' && (
                      <TableCell align="right">Preço Total</TableCell>
                    )}
                    <TableCell align="right">Tributos</TableCell>
                    <TableCell align="center">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {produtos.map((p, index) => (
                    <TableRow>
                      <TableCell
                        className={p?.saldo < p?.quantidade && classes.cell}
                      >
                        {p?.sequencia}
                      </TableCell>
                      <TableCell
                        className={p?.saldo < p?.quantidade && classes.cell}
                      >
                        {p?.produto_id}
                      </TableCell>
                      <TableCell
                        className={p?.saldo < p?.quantidade && classes.cell}
                      >
                        {p?.refint}
                      </TableCell>
                      <TableCell
                        className={p?.saldo < p?.quantidade && classes.cell}
                      >
                        {p?.descricao}
                      </TableCell>
                      <TableCell
                        className={p?.saldo < p?.quantidade && classes.cell}
                      >
                        {p?.quantidade} {p?.unidade_medida}
                      </TableCell>
                      {itensFields[0]?.iipreco === 'SIM' && (
                        <TableCell
                          align="right"
                          className={p?.saldo < p?.quantidade && classes.cell}
                        >
                          {setDecimal(
                            p?.preco,
                            documento?.Documento?.doc_operacao === 'ENTRADA' ||
                              documento?.Documento?.doc_devolucao === 'SIM'
                              ? 6
                              : (authUser?.storedcnpj === "01717900000117") ? 4 : 2
                          )}
                        </TableCell>
                      )}
                      {itensFields[0]?.iipreco === 'SIM' && (
                        <TableCell
                          align="right"
                          className={p?.saldo < p?.quantidade && classes.cell}
                        >
                          {p?.quantidade
                            ? setDecimal(p?.preco * p?.quantidade, 2)
                            : setDecimal(p?.preco, 2)}
                        </TableCell>
                      )}
                      <TableCell
                        align="right"
                        className={p?.saldo < p?.quantidade && classes.cell}
                      >
                        {setDecimal(p?.tributos || 0, 2)}
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center">
                          <IconButton
                            onClick={({ currentTarget }) =>
                              setItemOpcoes({ open: index, currentTarget })
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>

                          <Menu
                            id="long-menu"
                            anchorEl={itemOpcoes?.currentTarget}
                            open={itemOpcoes?.open === index}
                            onClose={() => setItemOpcoes({ open: false })}
                            MenuListProps={{
                              style: {
                                padding: 0,
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                history.push(
                                  `/app/Disponibilidade?id=${p?.produto_id}&descricao=${p.descricao}`
                                )
                              }
                            >
                              Disponibilidade
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                history.push(
                                  `/app/Histórico?operacao='${documento?.Documento?.doc_operacao}'
                      &especie=${documento?.Documento?.doc_especie_id}
                      &entidade=${documento?.Documento?.doc_cadastro_id}
                      &produto=${p?.produto_id}`
                                )
                              }
                            >
                              Histórico
                            </MenuItem>
                            {itensFields[0]?.iipreco === 'SIM' && (
                              <MenuItem
                                onClick={() => {
                                  setSelected(p);
                                  setOpenDesconto('item');
                                }}
                              >
                                Desconto / Outros / Margem / Adicionais
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() =>
                                history.push(`/app/Qualidade?id=${p?.id}`)
                              }
                            >
                              Qualidade
                            </MenuItem>
                            {p.prd_fiscal !== 'NAO' && (
                              <MenuItem
                                onClick={() =>
                                  history.push(
                                    `/app/DocItem/Tributação/Tributos?id=${p?.id}&descricao=${p.descricao}&doc=${p?.documento_id}`
                                  )
                                }
                              >
                                Tributos
                              </MenuItem>
                            )}
                            {p.prd_vinculo !== 'NAO' && (
                              <MenuItem
                                onClick={() => {
                                  history.push(
                                    `/app/VinculoItens${getParams(
                                      p?.id,
                                      p?.produto_id
                                    )}`
                                  );
                                }}
                              >
                                Vínculo
                              </MenuItem>
                            )}
                            {p.prd_estrutura !== 'NAO' && (
                              <MenuItem
                                onClick={() =>
                                  history.push(
                                    `/app/Produção/Estrutura?id=${p?.id}&descricao=${p.descricao}`
                                  )
                                }
                              >
                                Estrutura
                              </MenuItem>
                            )}
                            {p.prd_processo !== 'NAO' && (
                              <MenuItem
                                onClick={() =>
                                  history.push(
                                    `/app/Produção/Processo?id=${p?.id}&descricao=${p.descricao}`
                                  )
                                }
                              >
                                Processos
                              </MenuItem>
                            )}
                            {itensFields[0]?.iipreco === 'SIM' && (
                              <MenuItem
                                onClick={() =>
                                  history.push(
                                    `/app/FormaçãoDeCusto?id=${p?.id}`
                                  )
                                }
                              >
                                Formação do CMV
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() =>
                                history.push(
                                  `/app/ComplementoItens?id=${p?.id}&item=${p?.descricao}`
                                )
                              }
                            >
                              Dados Complementares
                            </MenuItem>
                          </Menu>
                          {documento?.Documento?.sleitura !== 'SIM' && (
                            <>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    DocumentoActions.deleteDocItem(p?.id)
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSelected(p);
                                  setOpenModal(!openModal);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            {documento?.Totais && (
              <div className="mt-4">
                <ExpansionPanel elevation={3}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <h4 className={classes.titles}>Totais</h4>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={0}>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Peso Total
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.peso, 3)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Volume
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.volume, 3)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Preço Total
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.preco, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Frete
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.frete, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Desconto
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.desconto, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Seguro
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.seguro, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Despesas Acesssórias
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.despac, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Outros
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.outros, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Eventuais
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.eventuais, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Impostos
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.imposto, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                      <Grid container item xs={3} spacing={0}>
                        <Paper
                          elevation={5}
                          className={`${classes.paper} bg-gradient-primary-x text-white`}
                        >
                          <h4 className={`${classes.titles} text-center`}>
                            Total Documento
                          </h4>
                          <h6 className={classes.valor}>
                            {setDecimal(documento?.Totais?.documento, 2)}
                          </h6>
                        </Paper>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            )}
            <AddItemModal
              open={openModal}
              setOpen={setOpenModal}
              classes={classes}
              items={items}
              selected={selected}
              onChooseProd={(v) =>
                dispatch(
                  DocumentoActions.getCusto({
                    Parametros: [
                      { nome: ':produto_id', valor: v?.value || v },
                      { nome: ':documento_id', valor: Number(param) },
                    ],
                  })
                )
              }
              onClose={() => {
                setSelected(null);
                dispatch(DocumentoActions.getDocumento(param));
              }}
            />
            <DescontoModal
              open={openDesconto}
              setOpen={setOpenDesconto}
              selected={selected}
              documento={documento}
              onClose={() => {
                setSelected(null);
                setItemOpcoes({ open: false });
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Itens);
