import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './Header/index';
import SideBar from 'containers/SideBar/index';
import Footer from 'components/Footer';
import { isIOS, isMobile } from 'react-device-detect';

const Vertical = (props) => {
  const { drawerType } = useSelector(({ settings }) => settings);
  const drawerStyle = drawerType?.includes('fixed_drawer')
    ? 'fixed-drawer'
    : drawerType?.includes('collapsible')
    ? 'collapsible-drawer'
    : 'mini-drawer';

  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height');
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height');
  }

  return (
    <div className={`app-container ${drawerStyle}`}>
      <SideBar />
      <div className="app-main-container">
        <div className="app-header">
          <Header />
        </div>

        <main className="app-main-content-wrapper">
          <div className="app-main-content">{props.children}</div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default withRouter(Vertical);
