import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import DeleteIcon from '@material-ui/icons/Delete';
import { useQuery } from 'util/index';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import { setDecimal } from 'util/index';

const Manutenção = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const cod = query.get('cod');
  const [items, setItems] = useState([]);
  const [id, setId] = useState('');
  const [variacao, setVariacao] = useState('');
  const { postReferenciaLoading, postReferenciaSuccess } = useSelector(
    ({ produto }) => produto
  );

  useEffect(() => {
    if (postReferenciaSuccess) {
      dispatch(ProdutoActions.postReferenciaReset());
      history.goBack();
    }
  }, [postReferenciaSuccess]);

  const onSubmit = () => {
    const data = {
      produto_id: Number(cod),
      Referencias: items,
    };
    dispatch(ProdutoActions.postReferencia(data));
  };

  return (
    <div className="app-wrapper">
      <HeaderPage title="Adicionar Referências" history={history} />
      <Card className="shadow">
        <CardContent>
          <Grid container justifyContent="space-between">
            <div>
              <TextField
                label="Código da Integração"
                value={id}
                onChange={(e) => setId(e.target.value)}
                onBlur={(e) => {
                  if (!isNaN(e?.target?.value)) {
                    setId(+e?.target?.value);
                  } else {
                    setId('');
                  }
                }}
              />
              <TextField
                label="Variação"
                value={variacao}
                onChange={(e) => setVariacao(e.target.value)}
                onBlur={(e) => {
                  if (!isNaN(e?.target?.value)) {
                    setVariacao(+e?.target?.value);
                  } else {
                    setVariacao('');
                  }
                }}
                className="mx-4"
              />
            </div>
            <div className="d-flex align-items-center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  if (id !== '') {
                    setItems((prev) => [...prev, { id, variacao }]);
                  }
                  setId('');
                  setVariacao('');
                }}
              >
                Adicionar
              </Button>
            </div>
          </Grid>
          <TableContainer component={Paper} className="my-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Referência</TableCell>
                  <TableCell>Variação</TableCell>
                  <TableCell align="right">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((m, mIndex) => (
                  <TableRow>
                    <TableCell>{m?.id}</TableCell>
                    <TableCell>{m?.variacao}</TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <DeleteIcon
                        color="action"
                        onClick={() => {
                          setItems(
                            items.filter((_, pIndex) => mIndex !== pIndex)
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={postReferenciaLoading}
            >
              Confirmar
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Manutenção;
