import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { withStyles } from '@material-ui/core/styles';
import api from 'util/api';
import moment from 'moment';

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const DevolucaoChequeModal = ({ open, setOpen, selected, classes }) => {
  const [data, setData] = useState(moment().format('DD/MM/YYYY'));
  const [alinea, setAlinea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([914]);
    }
  }, [open]);

  const onSubmit = async() => {
    const form = {
      operacao: 'CHEQUES',
      situacao: 'BAIXADO',
      dtlanc: moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      forma_pagto_id: selected[0]?.fpdevolucao,
      alinea_id: alinea?.value,
      financeiro: selected.map((s) => ({
        financeiro_id: s?.id,
        valor: Math.abs(s?.valor),
      })),
      formapagamento: [{
        lancado: false,
        forma_pagto_id: selected[0]?.fpdevolucao,
        forma_pagto: 'DEVOLUCAO',
        valor: Math.abs(selected[0]?.valor),
      }]
    };
    setLoading(true)
    await api.post(`/financeiro-registramov`, form);
    setLoading(false)
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Devolução de Cheques</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Alínea"
              items={items[0] || []}
              value={alinea}
              onChange={(v) => setAlinea(v)}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <InputMask
              name="przent"
              label="Data de Ocorrência"
              mask="99/99/9999"
              value={data}
              onChange={(v) => setData(v.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          color="primary"
          disabled={loading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DevolucaoChequeModal);
