import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from '@material-ui/core/styles';
import { setDecimal } from 'util/index';

const SimpleBarChart = ({ values }) => {
  const theme = useTheme();
  const data = values.map((v) => ({ name: v?.indicador, Valor: v?.valor }));

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={(value) => `R$ ${setDecimal(value, 2)}`} />
          <Legend />
          <Bar dataKey="Valor" fill={theme.palette.primary.main} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SimpleBarChart;
