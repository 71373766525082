import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import api from 'util/api';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ContabilActions } from 'store/ducks/Contabil';
import { findOnArray } from 'util/index';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const IntegrarDadosModal = ({ open, setOpen, filtros, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([885,846]);
    }
  }, [open]);

  const onSubmit = (values) => {
    const data = {
      planoentidade_id: values?.planoentidade_id?.value,
      datai: moment(values?.datai, 'DD/MM/YYYY')?.isValid()
        ? moment(values?.datai, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
        : null,
      dataf: moment(values?.dataf, 'DD/MM/YYYY')?.isValid()
        ? moment(values?.dataf, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
        : null,
      planoconta_id: values?.planoconta_id?.value,      
      comercializacao: values?.comercializacao?.value,
      tributacao: values?.tributacao?.value,
      baixas: values?.baixas?.value,
      financeiro: values?.financeiro?.value,
      caixabanco: values?.caixabanco?.value,
      estoque: values?.estoque?.value,
      pessoal: values?.pessoal?.value,
    };
    dispatch(ContabilActions.postDados(data));
  };

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (items.length && open) {
        const plano = filtros.find(
          (f) => f?.titulo === 'Entidade / Plano de contas'
        )?.conteudoi;
        const datai = filtros.find(
          (f) => f?.titulo?.toUpperCase() === 'DATA INICIO'
        )?.conteudoi;
        const dataf = filtros.find(
          (f) => f?.titulo?.toUpperCase() === 'DATA FIM'
        )?.conteudoi;
        setValues({
          ...values,
          planoentidade_id: findOnArray(Number(plano), items[0] || []),
          datai,
          dataf,
        });
      }
    }, [items, open]);
    return null;
  };

  return (
    <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
      <DialogTitle>Integrar Dados</DialogTitle>
      <Formik
        initialValues={{
          planoentidade_id: null,
          datai: null,
          dataf: null,
          planoconta_id: null,          
          comercializacao: { value: 'SIM', label: 'SIM' },
          tributacao: { value: 'SIM', label: 'SIM' },
          baixas: { value: 'SIM', label: 'SIM' },
          financeiro: { value: 'SIM', label: 'SIM' },
          caixabanco: { value: 'SIM', label: 'SIM' },
          estoque: { value: 'SIM', label: 'SIM' },
          pessoal: { value: 'SIM', label: 'SIM' },
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, handleSubmit, handleChange }) => (
          <>
            <SetFields />
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade / Plano de Contas"
                    items={items[0] || []}
                    value={values.planoentidade_id}
                    onChange={(v) => setFieldValue('planoentidade_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                    name="datai"
                    label="Data de Início"
                    mask="99/99/9999"
                    value={values.datai || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                    name="dataf"
                    label="Data Final"
                    mask="99/99/9999"
                    value={values.dataf || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Conta"
                    items={items[1] || []}
                    value={values.planoconta_id}
                    onChange={(v) => setFieldValue('planoconta_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Comercialização"
                    items={yn}
                    value={values.comercializacao}
                    onChange={(v) => setFieldValue('comercializacao', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Tributação"
                    items={yn}
                    value={values.tributacao}
                    onChange={(v) => setFieldValue('tributacao', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Baixas"
                    items={yn}
                    value={values.baixas}
                    onChange={(v) => setFieldValue('baixas', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Financeiro"
                    items={yn}
                    value={values.financeiro}
                    onChange={(v) => setFieldValue('financeiro', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Caixa e Banco"
                    items={yn}
                    value={values.caixabanco}
                    onChange={(v) => setFieldValue('caixabanco', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Estoque"
                    items={yn}
                    value={values.estoque}
                    onChange={(v) => setFieldValue('estoque', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Pessoal"
                    items={yn}
                    value={values.pessoal}
                    onChange={(v) => setFieldValue('pessoal', v)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleSubmit}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(IntegrarDadosModal);
