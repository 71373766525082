import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { findOnArray, setDecimal, transformData } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import ChooseModal from './ChooseModal';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridRadio: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const transpDrop = [
  { value: 'DESPACHO', label: 'DESPACHO' },
  { value: 'REDESPACHO', label: 'REDESPACHO' },
];

const EntregaModal = ({
  open,
  setOpen,
  documento,
  items,
  selected,
  setSelected,
  title,
  classes,
}) => {
  const dispatch = useDispatch();
  const [openChooseModal, setOpenChooseModal] = useState(false);
  const [fill, setFill] = useState(false);

  useEffect(() => {
    if (open) {
      setFill(false);
    }
  }, [open]);

  const SetFields = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (selected && !fill) {
        setFill(true);
        setValues({
          frete_id: findOnArray(documento?.Saida?.forma_envio_id, parseForma()),
          freteenv: documento?.Saida?.freteenv,
          fretecob: setDecimal(documento?.Saida?.fretecob, 2),
          freterec: setDecimal(documento?.Saida?.freterec, 2),
          fretepc: findOnArray(selected?.fretepc, items[5]),
          tipotransp: findOnArray(selected?.tipotransp, transpDrop),
          viatransp: findOnArray(selected?.viatransp, items[6]),
          natureza: findOnArray(selected?.natureza, items[7]),
          entidadeTransporte: findOnArray(selected?.entidade_id, items[8]),
          frota: findOnArray(selected?.frota_id, items[9]),
          motorista: findOnArray(selected?.motorista_id, items[10]),
          localEntrega: selected?.locentrega,
          qtdEntrega: selected?.quantidade,
          especieEntrega: selected?.especie,
          marca: selected?.marca,
          numero: selected?.numero,
          volume: selected?.volume,
          tara: setDecimal(selected?.tara, 3),
          liquido: setDecimal(selected?.pesoliq, 3),
          bruto: setDecimal(selected?.pesobrt, 3),
        });
      }
    }, [selected]);
    return null;
  };

  const parseForma = () => {
    if (Array.isArray(items[4])) {
      return items[4].map((i) => ({ ...i, value: i.id, label: i.descricao }));
    }
    return [];
  };

  const onSend = (values) => {
    let data = {
      id: selected?.id,
      documento_id: documento?.Documento?.id,
      forma_envio_id: values?.frete_id?.value,
      freteenv: values?.freteenv,
      fretecob: transformData(values?.fretecob, 'number'),
      freterec: transformData(values?.freterec, 'number'),
      fretepc: values?.fretepc?.value,
      tipotransp: values?.tipotransp?.value,
      viatransp: values?.viatransp?.value,
      natureza: values?.natureza?.value,
      entidade_id: values?.entidadeTransporte?.value,
      frota_id: values?.frota?.value,
      motorista_id: values?.motorista?.value,
      locentrega: values?.localEntrega,
      quantidade: transformData(values?.qtdEntrega),
      especie: values?.especieEntrega,
      marca: values?.marca,
      numero: values?.numero,
      volume: values?.volume,
      tara: transformData(values?.tara, 'number'),
      pesoliq: transformData(values?.liquido, 'number'),
      pesobrt: transformData(values?.bruto, 'number'),
    };

    if (selected) {
      dispatch(DocumentoActions.putEntrega({ data, id: selected?.id }));
    } else {
      dispatch(DocumentoActions.postEntrega(data));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      onExit={() => setSelected(null)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <Formik
        initialValues={{
          frete_id: null,
          freteenv: null,
          fretecob: null,
          freterec: null,
          fretepc: null,
          tipotransp: null,
          viatransp: null,
          natureza: null,
          entidadeTransporte: null,
          frota: null,
          motorista: null,
          localEntrega: null,
          qtdEntrega: null,
          especieEntrega: null,
          marca: null,
          numero: null,
          volume: null,
          tara: null,
          liquido: null,
          bruto: null,
        }}
        onSubmit={(values) => onSend(values)}
      >
        {({ values, handleChange, submitForm, setFieldValue, setValues }) => (
          <>
            <SetFields />
            <DialogTitle>
              {selected ? `Editar ${title}` : `Adicionar ${title}`}
            </DialogTitle>
            <DialogContent>
              <Grid container className="my-4">
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <SelectAuto
                    label="Forma de Envio"
                    items={parseForma()}
                    value={values?.frete_id}
                    onChange={(v) => setFieldValue('frete_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="5" md="5" lg="5" className={classes.grid}>
                  <TextField
                    name="freteenv"
                    label="Detalhes do Envio"
                    value={values.freteenv || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="fretecob"
                    label="Valor Frete Cobrado"
                    value={values.fretecob || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('fretecob', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="freterec"
                    label="Valor Frete Recebido"
                    value={values.freterec || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('freterec', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container className="mt-8 mb-4">
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Tipo de Transporte"
                    items={transpDrop}
                    value={values?.tipotransp}
                    onChange={(v) => setFieldValue('tipotransp', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Frete por Conta"
                    items={items[5]}
                    value={values?.fretepc}
                    onChange={(v) => setFieldValue('fretepc', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Via de Transporte"
                    items={items[6]}
                    value={values?.viatransp}
                    onChange={(v) => setFieldValue('viatransp', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Natureza do Frete"
                    items={items[7]}
                    value={values?.natureza}
                    onChange={(v) => setFieldValue('natureza', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade"
                    items={items[8]}
                    value={values?.entidadeTransporte}
                    onChange={(v) => setFieldValue('entidadeTransporte', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Frota"
                    items={items[9]}
                    value={values?.frota}
                    onChange={(v) => setFieldValue('frota', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Motorista"
                    items={items[10]}
                    value={values?.motorista}
                    onChange={(v) => setFieldValue('motorista', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="localEntrega"
                    label="Local de Entrega"
                    value={values.localEntrega || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container className="mt-8 mb-4">
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="qtdEntrega"
                    label="Quantidade"
                    value={values.qtdEntrega || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <TextField
                    name="marca"
                    label="Marca"
                    value={values.marca || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <TextField
                    name="especieEntrega"
                    label="Especie"
                    value={values.especieEntrega || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="numero"
                    label="Número"
                    value={values.numero || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <TextField
                    name="volume"
                    label="Volume"
                    value={values.volume || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container className="mt-8 mb-4">
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="tara"
                    label="Tara"
                    value={values.tara || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('tara', setDecimal(e.target.value, 3))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="liquido"
                    label="Líquido"
                    value={values.liquido || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('liquido', setDecimal(e.target.value, 3))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="bruto"
                    label="Bruto"
                    value={values.bruto || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('bruto', setDecimal(e.target.value, 3))
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {values?.fretepc?.value !==
                '9 - SEM OCORRENCIA DE TRANSPORTE' && (
                <Button
                  color="primary"
                  onClick={() => setOpenChooseModal(true)}
                >
                  Opções de transporte
                </Button>
              )}
              {selected && (
                <Button
                  color="primary"
                  onClick={() => {
                    setFieldValue(
                      'liquido',
                      setDecimal(documento?.Documento?.doc_totpeso, 3)
                    );
                    setFieldValue(
                      'bruto',
                      setDecimal(documento?.Documento?.doc_totpeso, 3)
                    );
                  }}
                >
                  Calcular Peso
                </Button>
              )}
              {selected && (
                <Button
                  color="secondary"
                  onClick={() =>
                    dispatch(DocumentoActions.deleteEntrega(selected?.id))
                  }
                >
                  Excluir
                </Button>
              )}
              <Button color="primary" onClick={submitForm}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
            <ChooseModal
              open={openChooseModal}
              setOpen={setOpenChooseModal}
              documento={documento}
              type={title?.toUpperCase()}
              onSubmit={(params) => {
                setOpenChooseModal(false);
                setValues({
                  ...values,
                  entidadeTransporte: findOnArray(
                    params?.entidade_id,
                    items[8]
                  ),
                  frota: findOnArray(params?.frota_id, items[9]),
                  motorista: findOnArray(params?.motorista_id, items[10]),
                  localEntrega: params?.locentrega,
                });
              }}
            />
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(EntregaModal);
