import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
});

const item = [
  { value: '01', label: 'Remessa' },
  { value: '02', label: 'Baixa no Banco' },
  { value: '03', label: 'Alteração de Vencimento no banco' },
  { value: '04', label: 'Cancelar registro Remessa no sistema' },
];

const RemessaModal = ({
  open,
  setOpen,
  filtros,
  rotina,
  selected,
  classes,
}) => {
  const dispatch = useDispatch();
  const [ocorrencia, setOcorrencia] = useState(null);
  const { putExportTextLoading, putExportTextSuccess } = useSelector(
    ({ lancamento }) => lancamento
  );

  useEffect(() => {
    if (putExportTextSuccess) {
      dispatch(LancamentoActions.putExportTextReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [putExportTextSuccess]);

  const onSubmit = () => {
    const dtinicio = filtros.find((f) => f.titulo === 'Dt. Emissão Inicial');
    const dtfim = filtros.find((f) => f.titulo === 'Dt. Emissão Final');
    const selecao = selected.map((s) => s?.id);
    const data = {
      tipo: 'REMESSA',
      ocorrencia: ocorrencia?.value,
      dtinicio: moment(dtinicio?.conteudoi, 'DD/MM/YYYY').isValid()
        ? moment(dtinicio?.conteudoi, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      dtfim: moment(dtfim?.conteudoi, 'DD/MM/YYYY').isValid()
        ? moment(dtfim?.conteudoi, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      selecao,
      nomearquivo: selected[0]?.nomearqrem,
    };
    dispatch(LancamentoActions.putExportText(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <DialogTitle>Remessa Bancária</DialogTitle>
      {putExportTextLoading ? (
        <Loading />
      ) : (
        <DialogContent>
          <SelectAuto
            label="Ocorrência"
            items={item}
            value={ocorrencia}
            onChange={(v) => setOcorrencia(v)}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onSubmit()}
          color="primary"
          disabled={putExportTextLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RemessaModal);
