import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import FiscalModal from 'components/Modals/FiscalModal';
import api from 'util/api';
import { transformData, setDecimal, findOnArray, useQuery } from 'util/index';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  button: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
  },
});

const Manutenção = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const param = query.get('id');
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [openFiscal, setOpenFiscal] = useState(false);
  const [fill, setFill] = useState(false);
  const [cFiscal, setCFiscal] = useState({});
  const {
    postLoading,
    postSuccess,
    putLoading,
    putSuccess,
    getLoading,
    produto,
  } = useSelector(({ produto }) => produto);

  const yn = [
    { value: 'SIM', label: 'SIM' },
    { value: 'NAO', label: 'NÃO' },
  ];
  const ynp = [
    { value: 'SIM', label: 'SIM' },
    { value: 'NAO', label: 'NÃO' },
    { value: 'PENDENTE', label: 'PENDENTE' },
  ];
  const mov = [
    { value: 'UNIDADE', label: 'UNIDADE' },
    { value: 'ESTRUTURA', label: 'ESTRUTURA' },
  ];

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([638, 639, 611, 636, 654, 962, 963]);
    dispatch(ProdutoActions.getProdutoReset());
    if (param) {
      dispatch(ProdutoActions.getProduto(param));
    }
  }, []);

  useEffect(() => {
    if (postSuccess) {
      dispatch(ProdutoActions.postProdutoReset());
      dispatch(ProdutoActions.getProdutoReset());
      history.goBack();
    }
    if (putSuccess) {
      dispatch(ProdutoActions.putProdutoReset());
      dispatch(ProdutoActions.getProdutoReset());
      history.goBack();
    }
  }, [postSuccess, putSuccess]);

  const SetCf = () => {
    const { setFieldValue, setValues } = useFormikContext();

    useEffect(() => {
      if (cFiscal?.value) {
        setFieldValue('cfiscal', cFiscal?.label);
      }
    }, [cFiscal]);

    useEffect(() => {
      if (produto !== null && !fill) {
        setFill(true);
        setCFiscal(items[4].find((i) => i.value === produto.cfiscal_id));
        setValues({
          descricaocomp: produto.descricaocomp,
          descricao: produto.descricao,
          linha: findOnArray(produto.celinha_id, items[0]),
          grupo: findOnArray(produto.cegrupo_id, items[1]),
          marca: findOnArray(produto.cemarca_id, items[5]),
          anp: findOnArray(produto.anp_id, items[6]),
          fci: produto.fci,
          referencia: produto.referencia,
          cdbarra: produto.cdbarra,
          localizador: produto.localizador,
          complemento1: produto.complemento1,
          complemento2: produto.complemento2,
          origem: findOnArray(produto.origem_tributaria_id, items[2]),
          medida: findOnArray(produto.unidade_medida_id, items[3]),
          altura: setDecimal(produto.altura, 3),
          largura: setDecimal(produto.largura, 3),
          comprimento: setDecimal(produto.comprimento, 3),
          custoInicio: setDecimal(produto.custoini, 6),
          custoAtual: setDecimal(produto.custo, 6),
          preco: setDecimal(produto.preco, 2),
          rastreavel: findOnArray(produto.rastreavel, yn),
          variacao: findOnArray(produto.variacao, yn),
          retornavel: findOnArray(produto.retornavel, yn),
          movimentacao: findOnArray(produto.movimentacao, mov),
          ativo: findOnArray(produto.ativo, ynp),
          cest: produto.cest,
          qtrib: setDecimal(produto.qtrib, 3),
          peso: setDecimal(produto.peso, 3),
          volume: setDecimal(produto.volume, 3),
          lotemult: setDecimal(produto.lotemult, 3),
          lotemin: setDecimal(produto.lotemin, 3),
          loterepos: setDecimal(produto.loterepos, 3),
          temporepos: produto.temporepos,
          estmin: setDecimal(produto.estmin, 3),
          estmax: setDecimal(produto.estmax, 3),
        });
      }
    }, [produto]);
    return null;
  };

  function handleSubmit(values) {
    let data = {
      descricao: transformData(values?.descricao),
      descricaocomp: transformData(values?.descricaocomp),
      celinha_id: transformData(values?.linha),
      cegrupo_id: transformData(values?.grupo),
      cemarca_id: transformData(values?.marca),
      anp_id: transformData(values?.anp),
      fci: transformData(values?.fci),
      cfiscal_id: cFiscal?.value,
      origem_tributaria_id: transformData(values?.origem),
      unidade_medida_id: transformData(values?.medida),
      referencia: transformData(values?.referencia),
      cdbarra: transformData(values?.cdbarra, 'number'),
      peso: transformData(values?.peso, 'number'),
      volume: transformData(values?.volume, 'number'),
      altura: transformData(values?.altura, 'number'),
      largura: transformData(values?.largura, 'number'),
      comprimento: transformData(values?.comprimento, 'number'),
      lotemult: transformData(values?.lotemult, 'number'),
      lotemin: transformData(values?.lotemin, 'number'),
      loterepos: transformData(values?.loterepos, 'number'),
      temporepos: transformData(values?.temporepos, 'number'),
      estmin: transformData(values?.estmin, 'number'),
      estmax: transformData(values?.estmax, 'number'),
      cest: transformData(values?.cest, 'number'),
      qtrib: transformData(values?.qtrib, 'number'),
      localizador: transformData(values?.localizador),
      complemento1: transformData(values?.complemento1),
      complemento2: transformData(values?.complemento2),
      rastreavel: transformData(values?.rastreavel),
      variacao: transformData(values?.variacao),
      retornavel: transformData(values?.retornavel),
      movimentacao: transformData(values?.movimentacao),
      custo: transformData(values?.custoAtual, 'number'),
      preco: transformData(values?.preco, 'number'),
      dtcad: moment().format('YYYY-MM-DD'),
      ativo: transformData(values?.ativo),
    };

    if (param) {
      data = {
        id: Number(param),
        ...data,
        custoini: transformData(values?.custoInicio, 'number'),
      };
      dispatch(
        ProdutoActions.putProduto({ id: param, data: { ...produto, ...data } })
      );
    } else {
      dispatch(ProdutoActions.postProduto(data));
    }
  }

  if (localLoading || getLoading) {
    return <Loading />;
  } else {
    return (
      <div className="app-wrapper">
        <HeaderPage
          title={`${param ? 'Editar' : 'Cadastro de'} Produto`}
          history={history}
        />
        <Card className="shadow">
          <CardContent>
            <Formik
              initialValues={{
                descricaocomp: '',
                descricao: '',
                linha: '',
                grupo: '',
                marca: '',
                anp: '',
                fci: '',
                referencia: '',
                cdbarra: '',
                localizador: '',
                complemento1: '',
                complemento2: '',
                cfiscal: '',
                origem: '',
                medida: '',
                custoInicio: '',
                custoAtual: '',
                preco: '',
                rastreavel: '',
                variacao: '',
                retornavel: '',
                movimentacao: '',
                ativo: { value: 'PENDENTE', label: 'PENDENTE' },
                cest: '',
                qtrib: '',
                peso: '',
                volume: '',
                altura: '',
                largura: '',
                comprimento: '',
                lotemult: '',
                lotemin: '',
                loterepos: '',
                temporepos: '',
                estmin: '',
                estmax: '',
              }}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, handleChange, submitForm, setFieldValue }) => (
                <>
                  <Grid container>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <TextField
                        name="descricaocomp"
                        label="Descrição Completa"
                        value={values.descricaocomp}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <TextField
                        name="descricao"
                        label="Descrição Reduzida"
                        value={values.descricao}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Linha"
                        items={items[0]}
                        value={values.linha}
                        onChange={(v) => setFieldValue('linha', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Grupo"
                        items={items[1]}
                        value={values.grupo}
                        onChange={(v) => setFieldValue('grupo', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Marca"
                        items={items[5]}
                        value={values.marca}
                        onChange={(v) => setFieldValue('marca', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="ANP"
                        items={items[6]}
                        value={values.anp}
                        onChange={(v) => setFieldValue('anp', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="fci"
                        label="FCI"
                        value={values.fci}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="referencia"
                        label="Referência"
                        value={values.referencia}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="cdbarra"
                        label="Código de Barras"
                        value={values.cdbarra}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="complemento1"
                        label="Informação Adicional 1"
                        value={values.complemento1}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="complemento2"
                        label="Informação Adicional 2"
                        value={values.complemento2}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <button
                        onClick={() => setOpenFiscal(!openFiscal)}
                        className={classes.button}
                        type="button"
                      >
                        <TextField
                          name="cfiscal"
                          label="Classificação Fiscal"
                          value={values.cfiscal}
                          fullWidth
                        />
                        <SetCf />
                      </button>
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Origem Tributária"
                        items={items[2]}
                        value={values.origem}
                        onChange={(v) => setFieldValue('origem', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="qtrib"
                        label="Fator Unidade Tributária"
                        value={values.qtrib}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue('qtrib', setDecimal(e.target.value, 3))
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="3" lg="3" className={classes.grid}>
                      <TextField
                        name="cest"
                        label="CEST"
                        value={values.cest}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <SelectAuto
                        label="Unidade de Medida"
                        items={items[3]}
                        value={values.medida}
                        onChange={(v) => setFieldValue('medida', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="peso"
                        label="Peso"
                        value={values.peso}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue('peso', setDecimal(e.target.value, 3))
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="volume"
                        label="Volume"
                        value={values.volume}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue('volume', setDecimal(e.target.value, 3))
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="altura"
                        label="Altura"
                        value={values.altura}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'altura',
                            setDecimal(e.target.value, 3)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="largura"
                        label="Largura"
                        value={values.largura}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'largura',
                            setDecimal(e.target.value, 3)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="comprimento"
                        label="Comprimento"
                        value={values.comprimento}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'comprimento',
                            setDecimal(e.target.value, 3)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    {param && (
                      <Grid
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        className={classes.grid}
                      >
                        <TextField
                          name="custoInicio"
                          label="Custo Inicial"
                          value={values.custoInicio}
                          onChange={handleChange}
                          onBlur={(e) =>
                            setFieldValue(
                              'custoInicio',
                              setDecimal(e.target.value, 6)
                            )
                          }
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid
                      xs="12"
                      sm={param ? '4' : '6'}
                      md={param ? '4' : '6'}
                      lg={param ? '4' : '6'}
                      className={classes.grid}
                    >
                      <TextField
                        name="custoAtual"
                        label="Custo Atual"
                        value={values.custoAtual}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'custoAtual',
                            setDecimal(e.target.value, 6)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm={param ? '4' : '6'}
                      md={param ? '4' : '6'}
                      lg={param ? '4' : '6'}
                      className={classes.grid}
                    >
                      <TextField
                        name="preco"
                        label="Preço de Venda"
                        value={values.preco}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue('preco', setDecimal(e.target.value, 2))
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="lotemin"
                        label="Lote Mínimo"
                        value={values.lotemin}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'lotemin',
                            setDecimal(e.target.value, 3)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="lotemult"
                        label="Lote Múltiplo"
                        value={values.lotemult}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'lotemult',
                            setDecimal(e.target.value, 3)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="loterepos"
                        label="Lote de Reposição"
                        value={values.loterepos}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue(
                            'loterepos',
                            setDecimal(e.target.value, 3)
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="temporepos"
                        label="Tempo de Reposição (Dias)"
                        value={values.temporepos}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="estmin"
                        label="Estoque Mínimo"
                        value={values.estmin}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue('estmin', setDecimal(e.target.value, 3))
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <TextField
                        name="estmax"
                        label="Estoque Máximo"
                        value={values.estmax}
                        onChange={handleChange}
                        onBlur={(e) =>
                          setFieldValue('estmax', setDecimal(e.target.value, 3))
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Rastreável"
                        items={yn}
                        value={values.rastreavel}
                        onChange={(v) => setFieldValue('rastreavel', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Variação"
                        items={yn}
                        value={values.variacao}
                        onChange={(v) => setFieldValue('variacao', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Retornável"
                        items={yn}
                        value={values.retornavel}
                        onChange={(v) => setFieldValue('retornavel', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Tipo Movimentação"
                        items={mov}
                        value={values.movimentacao}
                        onChange={(v) => setFieldValue('movimentacao', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="9" md="9" lg="9" className={classes.grid}>
                      <TextField
                        name="localizador"
                        label="Localizador (Endereço)"
                        value={values.localizador}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                      <SelectAuto
                        label="Ativo"
                        items={ynp}
                        value={values.ativo}
                        onChange={(v) => setFieldValue('ativo', v)}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <div
                    className="d-flex justify-content-end"
                    style={{ flex: 1, marginTop: '1rem', padding: '0 0.5rem' }}
                  >
                    {postLoading || putLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Button
                        color="primary"
                        onClick={submitForm}
                        variant="contained"
                      >
                        Finalizar
                      </Button>
                    )}
                  </div>
                </>
              )}
            </Formik>
          </CardContent>
        </Card>
        <FiscalModal
          open={openFiscal}
          setOpen={setOpenFiscal}
          setCFiscal={setCFiscal}
        />
      </div>
    );
  }
};

export default withStyles(styles)(Manutenção);
