import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Paper,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Fab,
  IconButton,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import RestoreIcon from '@material-ui/icons/Restore';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import AddAgendaModal from 'components/Modals/AddAgendaModal';
import AddIcon from '@material-ui/icons/Add';
import { Creators as AgendaActions } from 'store/ducks/Agenda';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  ViewState,
  EditingState,
  IntegratedEditing,
  Resources,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';

const styles = () => ({
  card: {
    margin: '0.25rem',
    display: 'flex',
    justifyContent: 'center',
  },
  calendar: {
    border: '0px !important',
    width: 'auto !important',
  },
  fab: {
    position: 'absolute',
    zIndex: 999,
    bottom: 50,
    right: 50,
  },
});

const GestaoDeAgendas = ({ match, classes }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const rotina = 649;
  const [selectedData, setSelectedData] = useState(new Date());
  const [tab, setTab] = useState(0);
  const [type, setType] = useState(null);
  const [selected, setSelected] = useState(null);
  const [typeDrop, setTypeDrop] = useState([]);
  const [multiSelected, setMultiSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [schedulerData, setSchedulerData] = useState([]);
  const {
    perfilLoading,
    perfil,
    agendaLoading,
    agenda,
    postTarefaSuccess,
    putTarefaSuccess,
    deleteTarefaSuccess,
    putStatusSuccess,
  } = useSelector(({ agenda }) => agenda);
  const resources = [
    {
      fieldName: 'status',
      title: 'Status',
      instances: [
        { id: 'NAO INICIADA', text: 'NAO INICIADA', color: 'orange' },
        {
          id: 'EM ANDAMENTO',
          text: 'EM ANDAMENTO',
          color: 'rgb(100, 181, 246)',
        },
        { id: 'CONCLUIDA', text: 'CONCLUIDA', color: 'green' },
        {
          id: 'AGUARDANDO OUTRA PESSOA',
          text: 'AGUARDANDO OUTRA PESSOA',
          color: 'red',
        },
        { id: 'ADIADA', text: 'ADIADA', color: 'red' },
      ],
    },
  ];

  useEffect(() => {
    dispatch(AgendaActions.getPerfil());
  }, []);

  useEffect(() => {
    if (!!perfil.length) {
      perfil.map((p) => {
        setTypeDrop((prev) => [
          ...prev,
          { ...p, value: p?.apresentacao, label: p?.apresentacao },
        ]);
        if (p?.inicializar === 'SIM') {
          setType({ ...p, value: p?.apresentacao, label: p?.apresentacao });
        }
      });
    }
  }, [perfil]);

  useEffect(() => {
    if (type) {
      setMultiSelected(type?.selecao);
      if (type?.visualizacao === 'DIA') {
        setTab(0);
      }
      if (type?.visualizacao === 'SEMANA') {
        setTab(1);
      }
      if (type?.visualizacao === 'MES') {
        setTab(2);
      }
    }
  }, [type]);

  useEffect(() => {
    if (type) {
      dispatch(
        AgendaActions.getAgenda({
          data: selectedData,
          perfil_agenda_id: type?.id,
        })
      );
    }
  }, [type, selectedData, tab]);

  useEffect(() => {
    if (
      postTarefaSuccess ||
      putTarefaSuccess ||
      deleteTarefaSuccess ||
      putStatusSuccess
    ) {
      if (type) {
        dispatch(
          AgendaActions.getAgenda({
            data: selectedData,
            perfil_agenda_id: type?.id,
          })
        );
      }
      dispatch(AgendaActions.postTarefaReset());
      dispatch(AgendaActions.putTarefaReset());
      dispatch(AgendaActions.deleteTarefaReset());
      dispatch(AgendaActions.putStatusReset());
      setOpenModal(false);
    }
  }, [
    postTarefaSuccess,
    deleteTarefaSuccess,
    putTarefaSuccess,
    putStatusSuccess,
  ]);

  useEffect(() => {
    if (!!agenda.length) {
      setSchedulerData(
        agenda.map((a) => ({
          ...a,
          title: a?.txtcurto,
          startDate: moment(a?.datahora).format(),
          endDate: a?.conclusao
            ? moment(a?.conclusao).format()
            : moment(a?.datahora)
                .add(1, 'minute')
                .format(),
        }))
      );
    }
  }, [agenda]);

  const commitChanges = ({ deleted }) => {
    if (deleted) {
      dispatch(AgendaActions.deleteTarefa(deleted));
    }
  };

  const Appointment = ({ children, style, data, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...style,
        backgroundColor:
          data?.status === 'NAO INICIADA'
            ? 'orange'
            : data?.status === 'CONCLUIDA'
            ? 'green'
            : data?.status === 'AGUARDANDO OUTRA PESSOA' ||
              data?.status === 'ADIADA'
            ? 'red'
            : null,
      }}
    >
      {children}
    </Appointments.Appointment>
  );

  const TooltipContent = ({ appointmentData, ...restProps }) => (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      <div className="m-4">
        <span>{appointmentData?.txtlongo}</span>
      </div>
    </AppointmentTooltip.Content>
  );

  const TooltipHeader = ({ appointmentData, ...restProps }) => (
    <AppointmentTooltip.Header {...restProps} appointmentData={appointmentData}>
      <IconButton
        onClick={() => {
          setSelected(appointmentData);
          setOpenModal(true);
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() => dispatch(AgendaActions.putStatus(appointmentData?.id))}
      >
        <RestoreIcon />
      </IconButton>
    </AppointmentTooltip.Header>
  );

  return (
    <div className="app-wrapper">
      <HeaderPage title="Gestão de Agendas" rotina={rotina} />
      {perfilLoading || agendaLoading ? (
        <Loading />
      ) : (
        <Grid container>
          <Grid xs="12" sm="12" md="8" lg="8" className="d-flex">
            <Card className={classes.card}>
              <CardContent>
                <AppBar position="static" color="white" className="mb-4">
                  <Tabs
                    value={tab}
                    onChange={(_, v) => setTab(v)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label="DIA" />
                    <Tab label="SEMANA" />
                    <Tab label="MÊS" />
                  </Tabs>
                </AppBar>
                {tab === 0 && (
                  <Paper>
                    <Scheduler data={schedulerData} locale="pt-BR">
                      <ViewState currentDate={selectedData} />
                      <EditingState
                        onCommitChanges={(props) => commitChanges(props)}
                      />
                      <DayView
                        startDayHour={0}
                        endDayHour={24}
                        cellDuration={60}
                      />
                      <Appointments appointmentComponent={Appointment} />
                      <IntegratedEditing />
                      <AppointmentTooltip
                        showCloseButton
                        showDeleteButton
                        contentComponent={TooltipContent}
                        headerComponent={TooltipHeader}
                      />
                      <Resources data={resources} mainResourceName="status" />
                    </Scheduler>
                  </Paper>
                )}
                {tab === 1 && (
                  <Paper>
                    <Scheduler data={schedulerData} locale="pt-BR">
                      <ViewState currentDate={selectedData} />
                      <EditingState
                        onCommitChanges={(props) => commitChanges(props)}
                      />
                      <WeekView
                        startDayHour={0}
                        endDayHour={24}
                        cellDuration={60}
                      />
                      <Appointments appointmentComponent={Appointment} />
                      <IntegratedEditing />
                      <AppointmentTooltip
                        showCloseButton
                        showDeleteButton
                        contentComponent={TooltipContent}
                        headerComponent={TooltipHeader}
                      />
                      <Resources data={resources} mainResourceName="status" />
                    </Scheduler>
                  </Paper>
                )}
                {tab === 2 && (
                  <Paper>
                    <Scheduler data={schedulerData} locale="pt-BR">
                      <ViewState currentDate={selectedData} />
                      <EditingState
                        onCommitChanges={(props) => commitChanges(props)}
                      />
                      <MonthView />
                      <Appointments appointmentComponent={Appointment} />
                      <IntegratedEditing />
                      <AppointmentTooltip
                        showCloseButton
                        showDeleteButton
                        contentComponent={TooltipContent}
                        headerComponent={TooltipHeader}
                      />
                      <Resources data={resources} mainResourceName="status" />
                    </Scheduler>
                  </Paper>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid xs="12" sm="12" md="4" lg="4" className="d-flex">
            <Card className={classes.card}>
              <CardContent>
                <Calendar
                  onChange={setSelectedData}
                  value={selectedData}
                  calendarType="US"
                  className={classes.calendar}
                />
                <SelectAuto
                  className="my-4"
                  label="Agenda"
                  items={typeDrop || []}
                  value={type}
                  onChange={(v) => setType(v)}
                />
                {!!multiSelected?.length && (
                  <>
                    <h3>Selecionados</h3>
                    {multiSelected.map((m) => (
                      <div>
                        <span>• {m?.descricao}</span>
                      </div>
                    ))}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <Fab
        color="primary"
        classes={{ root: classes.fab }}
        onClick={() => setOpenModal(!openModal)}
      >
        <AddIcon />
      </Fab>
      <AddAgendaModal
        open={openModal}
        setOpen={setOpenModal}
        selected={selected}
        setSelected={setSelected}
        state={state}
      />
    </div>
  );
};

export default withStyles(styles)(GestaoDeAgendas);
