import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const ListaDocumentoModal = ({ open, setOpen, values, onSubmit, classes }) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [faturar, setFaturar] = useState(false);

  async function loadItems() {
    const clause = values?.especie?.value
      ? {
          WhereClause: `and d.especie_id = ${values?.especie?.value}`,
        }
      : null;
    const { data } = await api.post(`/csql/850`, clause);
    if (data) {
      const { dados } = data;
      setItems(dados || []);
    }
  }

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Documentos</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Selecionar Documento"
              items={items}
              value={selected}
              onChange={(v) => setSelected(v)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Checkbox
            color="primary"
            checked={faturar}
            onChange={() => setFaturar(!faturar)}
          />
          <DialogContentText style={{ margin: 0 }}>Faturar</DialogContentText>
        </div>
        <Button
          onClick={() => onSubmit(selected?.value, faturar)}
          color="primary"
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ListaDocumentoModal);
