import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import api from 'util/api';
import { findOnArray } from 'util/index';
import moment from 'moment';

const styles = () => ({
  paper: {
    minWidth: '90%',
  },
});

const FaturarModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    especie_id: null,
    serie_id: null,
    dhsaida: '',
  });
  const { postDocumentoGerarLoading } = useSelector(
    ({ documento }) => documento
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([642, 652]);
    }
  }, [open]);

  useEffect(() => {
    if (!!items?.length) {
      setValues((prev) => ({
        ...prev,
        especie_id: findOnArray(10, items[0]),
        serie_id: findOnArray(1, items[1]),
        dhsaida: moment().format('DD/MM/YYYY HH:mm:ss'),
      }));
    }
  }, [items]);

  const onSubmit = () => {
    const data = {
      especie_id: values?.especie_id?.value,
      serie_id: values?.serie_id?.value,
      emitir: values?.serie_id?.transmiteaut || 'NAO',
      dhsaida: moment(values?.dhsaida, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.dhsaida, 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : null,
      Documentos: selected?.map((s) => s?.documento_id),
    };
    dispatch(DocumentoActions.postDocumentoGerar(data));
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Faturar</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectAuto
              label="Espécie"
              items={items[0] || []}
              value={values.especie_id}
              onChange={(especie_id) =>
                setValues((prev) => ({ ...prev, especie_id }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SelectAuto
              label="Série"
              items={items[1] || []}
              value={values.serie_id}
              onChange={(serie_id) =>
                setValues((prev) => ({ ...prev, serie_id }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputMask
              name="data"
              label="Data / Hora da Saída"
              mask="99/99/9999 99:99:99"
              value={values.dhsaida}
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, dhsaida: value }));
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={postDocumentoGerarLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(FaturarModal);
