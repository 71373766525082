import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import api from 'util/api';
import { toast } from 'react-toastify';
import { setDecimal, transformData } from 'util/index';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const BoletoCrediarioModal = ({
  open,
  setOpen,
  onSubmit,
  formaPagto,
  isPagamento,
  isAtendimento,
  isRecebimento,
  isCheque,
  pagamentos,
  classes,
}) => {
  const [items, setItems] = useState([]);
  const [cadastros, setCadastros] = useState([]);
  const [values, setValues] = useState({
    condicao_pagamento_id: null,
    forma_pagto_id: '',
    parcela: '',
    dtvenc: '',
    valor: '',
    observacao: '',
  });
  async function loadItems(ids) {
    const promises = ids.map(async (id) => {
      if (id !== null) {
        let Parametros = [];
        if (id !== 640) {
          Parametros = [
            {
              nome: ':tipocartao',
              valor: getTitle() === 'Boleto' ? 'BOLETO' : 'CREDIARIO',
            },
          ];
        }
        const { data } = await api.post(`/csql/${id}`, {
          Parametros,
        });
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setItems(results);
  }

  const getTitle = () => {
    if (formaPagto === '15-BOLETO BANCÁRIO') {
      return 'Boleto';
    }
    if (formaPagto === '99-OUTROS') {
      return 'Crediário';
    }
    return '';
  };

  const resetValues = () => {
    setValues({
      condicao_pagamento_id: null,
      forma_pagto_id: '',
      parcela: '',
      dtvenc: '',
      valor: '',
      observacao: '',
    });
  };

  useEffect(() => {
    if (open) {
      setValues((prev) => ({ ...prev, valor: pagamentos?.valor }));
      const sql =
        formaPagto === '15-BOLETO BANCÁRIO' && (isRecebimento || isAtendimento)
          ? 750
          : formaPagto === '15-BOLETO BANCÁRIO' && isPagamento
          ? 970
          : isRecebimento || isAtendimento
          ? 646
          : isPagamento
          ? 647
          : isCheque
          ? 749
          : null;
      loadItems([sql, 640]);
    } else {
      resetValues();
      setCadastros([]);
    }
  }, [open]);

  const parseSubmit = () => {
    const valor = setDecimal(
      cadastros?.length
        ? cadastros
            ?.map((i) => transformData(i?.valor, 'number'))
            ?.reduce((prev, next) => prev + next)
        : '0',
      2
    );
    const data = {
      valor,
      crediario: cadastros?.map((c) => ({
        ...c,
        condicao_pagamento_id: c?.condicao_pagamento_id?.value,
        dtvenc: moment(c?.dtvenc, 'DD/MM/YYYY').isValid()
          ? moment(c?.dtvenc, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        forma_pagto_id: c?.forma_pagto_id?.value,
        parcela: Number(c?.parcela),
      })),
    };
    onSubmit(data);
    setOpen(null);
  };

  const onConfirm = () => {
    if (!values?.condicao_pagamento_id) {
      if (!Boolean(values?.parcela)) {
        toast.warning('Digite a parcela');
        return;
      } else if (!Boolean(values?.forma_pagto_id)) {
        toast.warning('Escolha a forma de pagamento');
        return;
      } else if (!Boolean(values?.dtvenc)) {
        toast.warning('Digite a data de vencimento');
        return;
      } else if (!Boolean(values?.valor)) {
        toast.warning('Digite o valor');
        return;
      } else {
        setCadastros((prev) => [...prev, values]);
      }
    } else {
      if (!Boolean(values?.valor)) {
        toast.warning('Digite o valor');
        return;
      }
      if (!Boolean(values?.dtvenc)) {
        toast.warning('Digite a data de vencimento');
        return;
      }
      let vezes = Number(values?.parcela);
      let intervalo = values?.condicao_pagamento_id?.intervalo;
      let parcela = 1;
      let resto = transformData(values?.valor, 'number');
      let dtvenc = moment(values?.dtvenc, 'DD/MM/YYYY').isValid()
        ? moment(values?.dtvenc, 'DD/MM/YYYY').format('DD/MM/YYYY')
        : null;
      while (vezes > 0) {
        const valorParcela = setDecimal(
          transformData(values?.valor, 'number') /
            values?.condicao_pagamento_id?.nvezes,
          2
        );

        const data = {
          ...values,
          parcela,
          dtvenc,
          valor: vezes === 1 ? setDecimal(resto, 2) : valorParcela,
        };
        setCadastros((prev) => [...prev, data]);
        parcela++;
        vezes--;
        dtvenc = moment(dtvenc, 'DD/MM/YYYY').isValid()
          ? moment(dtvenc, 'DD/MM/YYYY')
              .add(intervalo, 'days')
              .format('DD/MM/YYYY')
          : null;
        if (values?.condicao_pagamento_id?.dia) {
          while (
            moment(dtvenc, 'DD/MM/YYYY').date() !==
            values?.condicao_pagamento_id?.dia
          ) {
            dtvenc = moment(dtvenc, 'DD/MM/YYYY')
              .add(1, 'day')
              .format('DD/MM/YYYY');
          }
        }
        resto = resto - transformData(valorParcela, 'number');
      }
    }
    resetValues();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        Dados do {getTitle()} - Valor: R$ {pagamentos?.valor}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Forma de Pagamento"
              items={items[0] || []}
              value={values.forma_pagto_id}
              onChange={(v) =>
                setValues((prev) => ({ ...prev, forma_pagto_id: v }))
              }
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <SelectAuto
              label="Condição de Pagamento"
              items={items[1] || []}
              value={values.condicao_pagamento_id}
              onChange={(v) => {
                let dtvenc = moment()
                  .add(v?.intervalop, 'days')
                  .format('DD/MM/YYYY');
                if (v?.dia) {
                  while (moment(dtvenc, 'DD/MM/YYYY').date() !== v?.dia) {
                    dtvenc = moment(dtvenc, 'DD/MM/YYYY')
                      .add(1, 'day')
                      .format('DD/MM/YYYY');
                  }
                }
                setValues((prev) => ({
                  ...prev,
                  condicao_pagamento_id: v,
                  parcela: v?.nvezes,
                  dtvenc,
                }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              label="Parcela"
              value={values.parcela}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, parcela: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <InputMask
              label="Data de Vencimento"
              mask="99/99/9999"
              value={values.dtvenc}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, dtvenc: value }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              label="Valor"
              value={values.valor}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, valor: value }));
              }}
              onBlur={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({
                  ...prev,
                  valor: setDecimal(value, 2),
                }));
              }}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <TextField
              label="Observação"
              value={values.observacao}
              fullWidth
              onChange={(e) => {
                const { value } = e?.target;
                setValues((prev) => ({ ...prev, observacao: value }));
              }}
            />
          </Grid>
        </Grid>
        <div className="d-flex flex-row align-items-end justify-content-end">
          <Button variant="outlined" color="primary" onClick={onConfirm}>
            CONFIRMAR
          </Button>
        </div>
        <TableContainer component={Paper} className="my-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Parcela</TableCell>
                <TableCell>Forma de Pagamento</TableCell>
                <TableCell>Data de Vencimento</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell align="right">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cadastros?.map((m, i) => (
                <TableRow>
                  <TableCell>{m?.parcela}</TableCell>
                  <TableCell>{m?.forma_pagto_id?.label}</TableCell>
                  <TableCell>{m?.dtvenc}</TableCell>
                  <TableCell>{m?.valor}</TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <DeleteIcon
                      color="action"
                      onClick={() =>
                        setCadastros((prev) =>
                          prev?.filter((_, idx) => idx !== i)
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row align-items-end">
          <Button variant="contained" color="primary" onClick={parseSubmit}>
            SALVAR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="ml-2"
            onClick={() => setOpen(null)}
          >
            FECHAR
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BoletoCrediarioModal);
