import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { findOnArray } from 'util/index';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '50%',
  },
});

const ManutencaoModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const { postInutilizacaoLoading } = useSelector(({ documento }) => documento);
  const [items, setItems] = useState([]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([621, 622, 652]);
    }
  }, [open]);

  const onSend = (values) => {
    let data = {
      filial_id: values?.filial_id?.value,
      modelo: Number(values?.modelo?.codigo),
      serie: values?.serie?.value,
      numero_inicial: Number(values?.numero_inicial),
      numero_final: Number(values?.numero_final),
      justificativa: values?.justificativa,
    };

    dispatch(DocumentoActions.postInutilizacao(data));
  };

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (open && !!items?.length) {
        setValues({
          ...values,
          modelo: findOnArray(31, items[1]),
        });
      }
    }, [open, items]);
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          filial_id: null,
          modelo: null,
          serie: null,
          numero_inicial: null,
          numero_final: null,
          justificativa: null,
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <>
            <DialogTitle>Adicionar Documentos Inutilizados</DialogTitle>
            <SetFields />
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Filial"
                    items={items[0]}
                    value={values.filial_id}
                    onChange={(v) => setFieldValue('filial_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Modelo"
                    items={items[1]}
                    value={values.modelo}
                    onChange={(v) => setFieldValue('modelo', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Série"
                    items={items[2]}
                    value={values.serie}
                    onChange={(v) => setFieldValue('serie', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="numero_inicial"
                    label="Número Inicial"
                    value={values?.numero_inicial}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="numero_final"
                    label="Número Final"
                    value={values?.numero_final}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="justificativa"
                    label="Justificativa"
                    value={values?.justificativa}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => onSend(values)}
                color="primary"
                disabled={postInutilizacaoLoading}
              >
                Adicionar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(ManutencaoModal);
