import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as UsuariosActions } from 'store/ducks/Usuarios';
import api from 'util/api';

const CaracteristicasModal = ({ open, setOpen, selected, copia }) => {
  const dispatch = useDispatch();
  const [perfil, setPerfil] = useState(null);
  const [items, setItems] = useState([]);
  const { postCopiaLoading } = useSelector(({ usuarios }) => usuarios);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  const onSubmit = () => {
    const data = {
      perfil_id: perfil?.value,
      Registros: selected?.map((s) => ({ id: s?.id })),
    };
    dispatch(UsuariosActions.postCopiaPerfil({ copia, data }));
  };

  useEffect(() => {
    if (open) {
      loadItems([641]);
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Copiar características para outro perfil</DialogTitle>
      <DialogContent>
        <SelectAuto
          label="Perfil"
          items={items[0] || []}
          value={perfil}
          onChange={(v) => setPerfil(v)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit} disabled={postCopiaLoading}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CaracteristicasModal;
