import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as ContabilActions,
  Types as ContabilTypes,
} from 'store/ducks/Contabil';
import api from '../../util/api';

function* postCopiaRegraFilial({ payload }) {
  try {
    const { status } = yield call(api.post, `/copia-regra-integracao`, payload);
    if (status === 200 || status === 201) {
      yield put(ContabilActions.postCopiaRegraFilialSuccess());
    } else {
      yield put(ContabilActions.postCopiaRegraFilialReset());
    }
  } catch (error) {
    yield put(ContabilActions.postCopiaRegraFilialReset());
  }
}

function* postDados({ payload }) {
  try {
    const { status } = yield call(api.post, `/contabil-integrar`, payload);
    if (status === 200 || status === 201) {
      yield put(ContabilActions.postDadosSuccess());
    } else {
      yield put(ContabilActions.postDadosReset());
    }
  } catch (error) {
    yield put(ContabilActions.postDadosReset());
  }
}

function* postContabilLanc({ payload }) {
  try {
    const { status } = yield call(api.post, `/contabil-contabilizar`, payload);
    if (status === 200 || status === 201) {
      yield put(ContabilActions.postContabilLancSuccess());
    } else {
      yield put(ContabilActions.postContabilLancReset());
    }
  } catch (error) {
    yield put(ContabilActions.postContabilLancReset());
  }
}

function* postFechamento({ payload }) {
  try {
    const { status } = yield call(api.post, `/contabil-fechamento`, payload);
    if (status === 200 || status === 201) {
      yield put(ContabilActions.postFechamentoSuccess());
    } else {
      yield put(ContabilActions.postFechamentoReset());
    }
  } catch (error) {
    yield put(ContabilActions.postFechamentoReset());
  }
}

function* postCopiaRegraFilialWatcher() {
  yield takeLatest(ContabilTypes.POST_COPIA_REGRA_FILIAL, postCopiaRegraFilial);
}

function* postDadosWatcher() {
  yield takeLatest(ContabilTypes.POST_DADOS, postDados);
}

function* postContabilLancWatcher() {
  yield takeLatest(ContabilTypes.POST_CONTABIL_LANC, postContabilLanc);
}

function* postFechamentoWatcher() {
  yield takeLatest(ContabilTypes.POST_FECHAMENTO, postFechamento);
}

export default function* rootSaga() {
  yield all([
    fork(postCopiaRegraFilialWatcher),
    fork(postDadosWatcher),
    fork(postContabilLancWatcher),
    fork(postFechamentoWatcher),
  ]);
}
