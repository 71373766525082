import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { setDecimal, transformData } from 'util/index';
import api from 'util/api';
import InputMask from 'components/InputMask';
import SelectAuto from 'components/SelectAuto';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const ApontamentoModal = ({ open, setOpen, id, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { authUser } = useSelector(({ auth }) => auth);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([635]);
    }
  }, [open]);

  const onSend = (values) => {
    let campos = {
      inicio: moment(values?.inicio, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.inicio, 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : null,
      fim: moment(values?.fim, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.fim, 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : null,
      quantidade: transformData(values?.quantidade, 'number'),
      perda: transformData(values?.perda, 'number'),
      executante_id: values?.executante?.value,
      documento_item_processo_id: Number(id),
      users_id: Number(authUser?.storedId),
    };

    dispatch(
      TablesActions.saveData({
        table: 'documento_item_processo_apont',
        campos,
      })
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <Formik
        initialValues={{
          inicio: null,
          fim: null,
          quantidade: null,
          perda: null,
          executante: null,
        }}
      >
        {({ values, handleChange, submitForm, setFieldValue }) => (
          <>
            <DialogTitle>Novo Apontamento</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <InputMask
                    name="inicio"
                    label="Data / Hora Início"
                    mask="99/99/9999 99:99:99"
                    value={values.inicio || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <InputMask
                    name="fim"
                    label="Data / Hora Fim"
                    mask="99/99/9999 99:99:99"
                    value={values.fim || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="quantidade"
                    label="Quantidade"
                    value={values?.quantidade || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('quantidade', setDecimal(e.target.value, 3))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="perda"
                    label="Perda (%)"
                    value={values?.perda || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('perda', setDecimal(e.target.value, 3))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Executante"
                    items={items[0] || []}
                    value={values.executante}
                    onChange={(v) => setFieldValue('executante', v)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onSend(values)}
                color="primary"
                disabled={false}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(ApontamentoModal);
