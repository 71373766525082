import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import api from 'util/api';
import { getWhereClause, replaceConteudoi, findOnArray } from 'util/index';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import { Creators as MidiaActions } from '../../../store/ducks/Midia';

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
});

const FilterReportModal = ({
  open,
  setOpen,
  reportFiltros,
  report,
  classes,
  paramId = 0,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fill, setFill] = useState(false);
  const [items, setItems] = useState([]);
  const [tipoExp, setTipoExp] = useState(false);
  const { reportId, reportUrl, postReportLoading } = useSelector(
    ({ tables }) => tables
  );
  const { deleteSuccess } = useSelector(({ midia }) => midia);

  async function loadItems(ids) {
    const promises = ids.map(async (id) => {
      if (id !== null) {
        const { data } = await api.post(`/csql/${id}`, {
          Parametros: [
            {
              nome: ':id',
              valor: paramId,
            },
          ],
        });
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setItems(results);
    setLoading(false);
  }

  useEffect(() => {
    setItems([]);
    const ids = [];
    if (open && reportFiltros?.length) {
      reportFiltros.map((i) => {
        if (i.pesquisa_id && i.pesquisa_id !== null) {
          ids.push(i.pesquisa_id);
        } else {
          ids.push(null);
        }
      });
      setLoading(true);
      loadItems(ids);
    }
  }, [open, reportFiltros]);

  useEffect(() => {
    if (reportUrl) {
      window.open(reportUrl);
      if (reportId) {
        setTimeout(() => {
          dispatch(MidiaActions.deleteMidia(reportId));
          dispatch(TablesActions.postReportReset());
        }, 20000);
      } else {
        dispatch(TablesActions.postReportReset());
      }
    }
  }, [reportUrl]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(MidiaActions.deleteMidiaReset());
      setOpen(false);
    }
  }, [deleteSuccess]);

  const onSubmit = (data) => {
    const newFilters = reportFiltros;
    if (newFilters?.length > 0) {
      newFilters.map((m, index) => {
        if (!data[index]) {
          m.conteudoi = null;
        } else {
          if (m.tipo === 'TEXTO' || m.tipo === 'ENUM') {
            if (m.pesquisa_id || m.tipo === 'ENUM') {
              m.conteudoi = `'${data[index]?.value}'`;
              m.conteudod = `'${data[index]?.label}'`;
            } else {
              m.conteudoi = `'${data[index]}'`;
            }
          } else {
            m.conteudoi = data[index]?.value || data[index];
            m.conteudod = `'${data[index]?.label || data[index]}'`;
          }
        }
      });
    }

    const { whereString, filterString } = getWhereClause({
      filtros: newFilters,
      relatorio: true,
    });

    dispatch(
      TablesActions.postReport({
        rotina_id: report,
        WhereClause: whereString,
        Parametros: getWhereClause({ filtros: newFilters, param: true }),
        filtrorelatorio: filterString,
        tipoExp: !!tipoExp ? 'XLSX' : undefined,
      })
    );
  };

  const setInitialValues = () => {
    const initialValues = {};
    reportFiltros.map((f, index) => {
      if (f.pesquisa_id) {
        initialValues[index] =
          replaceConteudoi(f.conteudoi, items[index]) || null;
      } else {
        initialValues[index] = replaceConteudoi(f.conteudoi) || null;
      }
      if (f.tipo === 'ENUM' && !!f?.padrao?.length) {
        initialValues[index] =
          findOnArray(f.conteudoi, setDropDown(f.padrao)) || null;
      }
    });
    return initialValues;
  };

  const SetFields = () => {
    const { setValues } = useFormikContext();

    useEffect(() => {
      if (open && !fill) {
        setValues(setInitialValues());
      }
      if (!open) {
        setFill(false);
      }
    }, [open]);
    return null;
  };

  function setDropDown(value) {
    let parseData = [];
    if (value) {
      value.map((i) => {
        parseData.push({
          value: i,
          label: i,
        });
      });
    }
    return parseData;
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.paper }}
    >
      <Formik>
        {({ values, handleChange, submitForm, setFieldValue }) => (
          <>
            <SetFields />
            <DialogTitle>Filtros do Relatório</DialogTitle>
            {loading ? (
              <Loading />
            ) : (
              <DialogContent className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-around">
                  <FormControlLabel
                    className="d-flex"
                    control={
                      <Checkbox
                        color="primary"
                        checked={!tipoExp}
                        onChange={() => setTipoExp(!tipoExp)}
                      />
                    }
                    label="PDF"
                  />
                  <FormControlLabel
                    className="d-flex"
                    control={
                      <Checkbox
                        color="primary"
                        checked={tipoExp}
                        onChange={() => setTipoExp(!tipoExp)}
                      />
                    }
                    label="XLS"
                  />
                </div>
                {setFill(true)}
                {reportFiltros?.map((f, index) => {
                  if (f.pesquisa_id && f.pesquisa_id !== null) {
                    return (
                      <div className="mb-4 d-flex">
                        <div className="w-100">
                          <SelectAuto
                            style={{
                              width: f.tamanho ? `${f.tamanho}em` : null,
                            }}
                            name={index}
                            label={f?.titulo}
                            items={items[index] || []}
                            value={values[index]}
                            onChange={(v) => setFieldValue(`${index}`, v)}
                            disabled={f.parametro === 'SIM'}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (f.tipo === 'ENUM') {
                    return (
                      <SelectAuto
                        className="mb-4"
                        style={{ width: f.tamanho ? `${f.tamanho}em` : null }}
                        name={index}
                        label={f?.titulo}
                        items={setDropDown(f.padrao) || []}
                        value={values[index]}
                        onChange={(v) => setFieldValue(`${index}`, v)}
                        disabled={f.parametro === 'SIM'}
                      />
                    );
                  }
                  if (f.tipo === 'DATA') {
                    return (
                      <InputMask
                        mask="99/99/9999"
                        name={index}
                        label={f?.titulo}
                        value={values[index]}
                        onChange={handleChange}
                        className="mb-4"
                        style={{ width: f.tamanho ? `${f.tamanho}em` : null }}
                        disabled={f.parametro === 'SIM'}
                      />
                    );
                  }
                  return (
                    <TextField
                      name={index}
                      label={f?.titulo}
                      value={values[index]}
                      onChange={handleChange}
                      className="mb-4"
                      style={{ width: f.tamanho ? `${f.tamanho}em` : null }}
                      disabled={f.parametro === 'SIM'}
                    />
                  );
                })}
              </DialogContent>
            )}
            <DialogActions>
              {postReportLoading ? (
                <CircularProgress disableShrink size={20} />
              ) : (
                <Button color="primary" onClick={() => onSubmit(values)}>
                  Confirmar
                </Button>
              )}
              <Button
                onClick={() => setOpen(false)}
                color="secondary"
                disabled={postReportLoading}
              >
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(FilterReportModal);
