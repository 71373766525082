import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchBox from 'components/SearchBox';
import api from 'util/api';
import { setDecimal, transformData } from 'util/index';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const ChequeListaModal = ({ open, setOpen, onSubmit, classes }) => {
  const [items, setItems] = useState([]);
  const [cheques, setCheques] = useState([]);
  const [search, setSearch] = useState('');

  async function loadItems() {
    const { data } = await api.post(`/csql/293`, {
      WhereClause: `and coalesce(fc.cheque,'')<>'' and abs(f.valor)- abs(coalesce((select sum(fh.valor) from financeiro_historico fh where fh.financeiro_id=f.id),0))>0`,
    });
    if (data) {
      const { dados } = data;
      setItems(dados || []);
    }
  }

  const filterItens = (arr) => {
    let filtered = arr;
    filtered = filtered.filter((f) =>
      f?.infopesq?.toLowerCase()?.includes(search?.toLowerCase())
    );
    return filtered;
  };

  useEffect(() => {
    if (open) {
      loadItems();
    } else {
      setSearch('');
      setCheques([]);
    }
  }, [open]);

  const parseSubmit = () => {
    const valor = setDecimal(
      cheques?.length
        ? cheques
            ?.map((i) => transformData(setDecimal(i?.valor, 2), 'number'))
            ?.reduce((prev, next) => prev + next)
        : '0',
      2
    );
    const data = {
      valor,
      cheques: cheques?.map((c) => ({
        id: c?.id,
        valor: setDecimal(c?.valor, 2),
      })),
    };
    onSubmit(data);
    setOpen(null);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Cheques</DialogTitle>
      <DialogContent>
        <SearchBox
          placeholder="Buscar..."
          onChange={(evt) => setSearch(evt.target.value)}
          value={search}
          onSubmit={() => {}}
        />
        {!!items?.length ? (
          <TableContainer component={Paper} className="my-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Nº Cheque</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Vencimento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterItens(items).map((i) => (
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        style={{ padding: '0 5px' }}
                        color="default"
                        checked={cheques?.some((c) => c?.id === i?.id)}
                        onChange={() => {
                          if (cheques?.some((c) => c?.id === i?.id)) {
                            setCheques((prev) =>
                              prev?.filter((f) => f?.id !== i?.id)
                            );
                          } else {
                            setCheques((prev) => [...prev, i]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{i.nome}</TableCell>
                    <TableCell>{i.cheque}</TableCell>
                    <TableCell>{setDecimal(i?.valor, 2)}</TableCell>
                    <TableCell>
                      {moment(i.dtvenc, 'YYYY-MM-DD').isValid()
                        ? moment(i.dtvenc, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <DialogContentText>Nenhum registro encontrado</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row align-items-end">
          <Button variant="contained" color="primary" onClick={parseSubmit}>
            SALVAR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="ml-2"
            onClick={() => setOpen(null)}
          >
            FECHAR
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ChequeListaModal);
