import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import { setDecimal, transformData, useQuery } from 'util/index';
import api from 'util/api';

const styles = (theme) => ({
  grid: {
    padding: '0 0.5rem',
    margin: '1rem 0',
  },
  paper: {
    minWidth: '50%',
  },
});

const FormacaoPreco = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const id = query.get('id');
  const numero = query.get('numero');
  const nome = query.get('nome');
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [frete, setFrete] = useState(null);
  const [markup, setMarkup] = useState(null);
  const [loading, setLoading] = useState(false);
  const { postOutrosLoading, postOutrosSuccess } = useSelector(
    ({ documento }) => documento
  );
  const { postPrecosLoading, postPrecosSuccess } = useSelector(
    ({ produto }) => produto
  );

  const summarizer = (itens = []) =>
    itens?.length &&
    itens
      ?.map((i) => transformData(setDecimal(i?.frete, 2), 'number'))
      ?.reduce((prev, next) => prev + next);

  async function loadItems() {
    try {
      setLoading(true);
      const { data } = await api.post(`/csql/989`, {
        Parametros: [{ nome: ':id', valor: id }],
      });
      if (data) {
        setItems(data?.dados || []);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    setFrete(setDecimal(summarizer(items)?.toString(), 2));
  }, [items]);

  useEffect(() => {
    if (postOutrosSuccess) {
      dispatch(DocumentoActions.postItemOutrosReset());
      loadItems();
    }
  }, [postOutrosSuccess]);

  useEffect(() => {
    if (postPrecosSuccess) {
      dispatch(ProdutoActions.postPrecosReset());
      loadItems();
    }
  }, [postPrecosSuccess]);

  const onSubmitFrete = () => {
    const data = {
      conteudo: 'frete',
      informado: 'Valor',
      valor: transformData(frete, 'number'),
      documento_id: Number(id),
    };
    dispatch(DocumentoActions.postItemOutros(data));
  };

  const onSubmitMarkup = () => {
    setItems(
      items?.map((i) => ({
        ...i,
        margem: markup,
        preco:
          (1 + transformData(markup, 'number') / 100) *
          transformData(setDecimal(i?.custo, 2), 'number'),
      }))
    );
  };

  const onSubmit = () => {
    const data = {
      Produtos: items.map((m) => ({
        produto_id: m?.produto_id,
        documento_item_id: m?.id,
        preco: transformData(setDecimal(m?.preco, 2), 'number'),
        icmsst_pc: transformData(setDecimal(m?.icmsst_pc, 2), 'number'),
      })),
    };
    dispatch(ProdutoActions.postPrecos(data));
  };

  const Modal = () => {
    const [preco, setPreco] = useState(null);
    const [icmsst_pc, setIcmsst_pc] = useState(null);

    useEffect(() => {
      if (selected) {
        setPreco(setDecimal(selected?.preco, 2));
        setIcmsst_pc(setDecimal(selected?.icmsst_pc, 2));
      } else {
        setPreco(null);
        setIcmsst_pc(null);
      }
    }, [selected]);

    const onSubmitPreco = () => {
      setItems(
        items.map((m, i) => {
          if (selected?.index === i) {
            return {
              ...m,
              preco,
              icmsst_pc,
              margem:
                (transformData(preco, 'number') /
                  transformData(setDecimal(m?.custo, 2), 'number') -
                  1) *
                100,
            };
          } else {
            return m;
          }
        })
      );
      setSelected(null);
    };

    return (
      <Dialog
        open={Boolean(selected)}
        onClose={() => setSelected(null)}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>Alterar Preço</DialogTitle>
        <DialogContent>
          <TextField
            label="Preço"
            value={preco || ''}
            onChange={(v) => setPreco(v?.target?.value)}
            onBlur={(e) => setPreco(setDecimal(e.target.value, 2))}
            fullWidth
          />
          {selected?.aticmsst === 'SIM' && (
            <TextField
              label="% ICMS ST"
              value={icmsst_pc || ''}
              onChange={(v) => setIcmsst_pc(v?.target?.value)}
              onBlur={(e) => setIcmsst_pc(setDecimal(e.target.value, 2))}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onSubmitPreco}>
            SALVAR
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setSelected(null)}
          >
            VOLTAR
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className="app-wrapper">
      <HeaderPage
        title={`Formação de Preço - Documento: ${numero} - ${nome}`}
        history={history}
      />
      <Card className="shadow">
        <Grid container>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              label="Frete"
              value={frete || ''}
              onChange={(v) => setFrete(v?.target?.value)}
              onBlur={(e) => setFrete(setDecimal(e.target.value, 2))}
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <Button
              variant="outlined"
              color="primary"
              className="ml-2"
              onClick={() => onSubmitFrete()}
              disabled={postOutrosLoading}
            >
              ATUALIZAR
            </Button>
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <TextField
              label="Markup Geral"
              value={markup || ''}
              onChange={(v) => setMarkup(v?.target?.value)}
              onBlur={(e) => setMarkup(setDecimal(e.target.value, 2))}
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <Button
              variant="outlined"
              color="primary"
              className="ml-2"
              onClick={() => onSubmitMarkup()}
            >
              ATUALIZAR
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Card className="shadow mt-4">
        {loading ? (
          <Loading />
        ) : (
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Seq</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Referencia</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell>Unidade</TableCell>
                    <TableCell align="right">Preço</TableCell>
                    <TableCell align="right">St/Difal</TableCell>
                    <TableCell align="right">Custo</TableCell>
                    <TableCell align="right">Markup</TableCell>
                    <TableCell>Lojas</TableCell>
                    <TableCell>Tributação</TableCell>
                    <TableCell>Ativo</TableCell>
                    <TableCell align="center">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!items.length &&
                    items.map((i, index) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {i?.sequencia}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i?.descricao}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i?.referencia}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {setDecimal(i?.quantidade, 3)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i?.unidade}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {setDecimal(i?.preco, 2)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {setDecimal(i?.icmsst_vl, 2)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {setDecimal(i?.custo, 2)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {setDecimal(i?.margem, 2)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i?.integra}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i?.tribvenda}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {i?.ativo}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <PlaylistAddCheckIcon
                            color="action"
                            onClick={() => setSelected({ ...i, index })}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="contained"
                color="primary"
                className="mr-2"
                onClick={() => onSubmit()}
                disabled={postPrecosLoading}
              >
                ATUALIZAR PREÇOS
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                className="ml-2"
                onClick={() => loadItems()}
              >
                CANCELAR
              </Button>
            </div>
          </CardContent>
        )}
      </Card>
      <Modal />
    </div>
  );
};

export default withStyles(styles)(FormacaoPreco);
