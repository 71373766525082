import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { dataGuia } from './data';
import HeaderPage from 'components/HeaderPage';
import VideocamIcon from '@material-ui/icons/Videocam';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VideoModal from 'components/Modals/VideoModal';

const styles = () => ({
  card: { paddingBottom: '16px !important' },
});

const Guia = ({ match, classes }) => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const history = useHistory();

  return (
    <div className="app-wrapper">
      <HeaderPage title="Guia completo" history={history} />
      {dataGuia.map((d) => (
        <Accordion>
          <AccordionSummary>
            <div className="d-flex align-items-center w-100">
              {d?.icone}
              <h3 className="m-0 ml-2 font-weight-bold">{d?.modulo}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex flex-column w-100">
              {d?.children?.map((c) => (
                <Card className="shadow my-2">
                  <CardContent classes={{ root: classes.card }}>
                    <h4 className="m-0 font-weight-bold">• {c?.modulo}</h4>
                    {c?.children?.map((sc) => (
                      <>
                        {sc?.modulo && (
                          <div
                            className={`${!!sc?.link &&
                              'border-bottom'} ml-4 py-1 d-flex align-items-center justify-content-between`}
                          >
                            <h5 className="m-0">{sc?.modulo}</h5>
                            <div style={{ cursor: 'pointer' }}>
                              {sc?.link && (
                                <VideocamIcon
                                  className="mx-1"
                                  color="primary"
                                  fontSize="small"
                                  onClick={() => {
                                    setOpenVideoModal(true);
                                    setSelected(sc);
                                  }}
                                />
                              )}
                              {sc?.redirect && (
                                <OpenInNewIcon
                                  className="mx-1"
                                  color="primary"
                                  fontSize="small"
                                  onClick={() => window.open(sc?.redirect)}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {sc?.children?.map((ssc) => (
                          <div className="ml-4">
                            {ssc?.modulo && (
                              <div
                                className={`${!!ssc?.link &&
                                  'border-bottom'} ml-4 py-1 d-flex align-items-center justify-content-between`}
                              >
                                <h5 className="m-0">{ssc?.modulo}</h5>
                                <div style={{ cursor: 'pointer' }}>
                                  {ssc?.link && (
                                    <VideocamIcon
                                      className="mx-1"
                                      color="primary"
                                      fontSize="small"
                                      onClick={() => {
                                        setOpenVideoModal(true);
                                        setSelected(ssc);
                                      }}
                                    />
                                  )}
                                  {ssc?.redirect && (
                                    <OpenInNewIcon
                                      className="mx-1"
                                      color="primary"
                                      fontSize="small"
                                      onClick={() => window.open(ssc?.redirect)}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            {ssc?.children?.map((sssc) => (
                              <div className="ml-4">
                                {sssc?.modulo && (
                                  <div
                                    className={`${!!sssc?.link &&
                                      'border-bottom'} ml-4 py-1 d-flex align-items-center justify-content-between`}
                                  >
                                    <h5 className="m-0">{sssc?.modulo}</h5>
                                    <div style={{ cursor: 'pointer' }}>
                                      {sssc?.link && (
                                        <VideocamIcon
                                          className="mx-1"
                                          color="primary"
                                          fontSize="small"
                                          onClick={() => {
                                            setOpenVideoModal(true);
                                            setSelected(sssc);
                                          }}
                                        />
                                      )}
                                      {sssc?.redirect && (
                                        <OpenInNewIcon
                                          className="mx-1"
                                          color="primary"
                                          fontSize="small"
                                          onClick={() =>
                                            window.open(sssc?.redirect)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                                {sssc?.children?.map((ssssc) => (
                                  <div className="ml-4">
                                    {ssssc?.modulo && (
                                      <div
                                        className={`${!!ssssc?.link &&
                                          'border-bottom'} ml-4 py-1 d-flex align-items-center justify-content-between`}
                                      >
                                        <h5 className="m-0">{ssssc?.modulo}</h5>
                                        <div style={{ cursor: 'pointer' }}>
                                          {ssssc?.link && (
                                            <VideocamIcon
                                              className="mx-1"
                                              color="primary"
                                              fontSize="small"
                                              onClick={() => {
                                                setOpenVideoModal(true);
                                                setSelected(ssssc);
                                              }}
                                            />
                                          )}
                                          {ssssc?.redirect && (
                                            <OpenInNewIcon
                                              className="mx-1"
                                              color="primary"
                                              fontSize="small"
                                              onClick={() =>
                                                window.open(ssssc?.redirect)
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </>
                    ))}
                  </CardContent>
                </Card>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <VideoModal
        open={openVideoModal}
        setOpen={setOpenVideoModal}
        selected={selected}
      />
    </div>
  );
};

export default withStyles(styles)(Guia);
