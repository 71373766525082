import axios from 'axios';
import { getToken, getUrl } from './index';
import { parseSuccess, parseError } from 'util/index';

const api = axios.create({});

api.interceptors.request.use(
  async (cfg) => {
    cfg.baseURL = getUrl();
    const token = getToken();
    if (token) {
      cfg.headers.x_access_token = token;
    }
    return cfg;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    if (response?.status !== 200) {
      parseSuccess(response);
    }
    return response;
  },
  (error) => {
    parseError(error);
    return Promise.reject(error);
  }
);

export default api;
