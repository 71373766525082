import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik } from 'formik';
import api from 'util/api';
import { useDispatch } from 'react-redux';
import { Creators as ContabilActions } from 'store/ducks/Contabil';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const CopiarFilialModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([893, 621]);
    }
  }, [open]);

  const onSubmit = (values) => {
    const data = {
      plano_id: values?.plano_id?.value,
      entidadeorg_id: values?.entidadeorg_id?.value,
      entidadedest_id: values?.entidadedest_id?.value,
    };
    dispatch(ContabilActions.postCopiaRegraFilial(data));
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Cópia de Regras da filial</DialogTitle>
      <Formik
        initialValues={{
          plano_id: null,
          entidadeorg_id: null,
          entidadedest_id: null,
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Plano de Contas"
                    items={items[0] || []}
                    value={values.plano_id}
                    onChange={(v) => setFieldValue('plano_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade de Origem"
                    items={items[1] || []}
                    value={values.entidadeorg_id}
                    onChange={(v) => setFieldValue('entidadeorg_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade de Origem"
                    items={items[1] || []}
                    value={values.entidadedest_id}
                    onChange={(v) => setFieldValue('entidadedest_id', v)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleSubmit}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(CopiarFilialModal);
