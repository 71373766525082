import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import api from 'util/api';
import { Formik, useFormikContext } from 'formik';
import SelectAuto from 'components/SelectAuto';
import { transformData, setDecimal } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from 'components/Loading';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const AvaliacaoModal = ({ open, setOpen, selected, classes }) => {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const {
    postRespostaSuccess,
    postRespostaLoading,
    getQualidadeLoading,
  } = useSelector(({ documento }) => documento);

  async function loadItems() {
    const { data } = await api.post(`/csql/722`, {
      Parametros: [
        { nome: ':id', valor: Number(selected?.qualidade_indicador_id) },
      ],
    });
    if (data) {
      const { dados } = data;
      setItems(dados || []);
    }
  }

  const onSend = (values) => {
    const campos = {
      documento_item_qualidade_id: Number(
        selected?.documento_item_qualidade_id
      ),
      qualidade_indicador_resp_id: values?.resposta?.value,
      quantidade: transformData(values?.quantidade, 'number'),
      observacao: values?.observacao,
      resultado: transformData(values?.resultado, 'number'),
    };
    dispatch(DocumentoActions.postResposta(campos));
  };

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);

  const SetFields = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (postRespostaSuccess) {
        dispatch(DocumentoActions.postRespostaReset());
        setValues({
          resposta: null,
          resultado: null,
          quantidade: null,
          observacao: null,
        });
      }
    }, [postRespostaSuccess]);
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <Formik
        initialValues={{
          resposta: null,
          resultado: null,
          quantidade: null,
          observacao: null,
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <>
            <SetFields />
            <DialogTitle>
              Avaliar Indicador - {selected?.qualidade_indicador}
            </DialogTitle>
            <DialogContent>
              <Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Resposta do Indicador"
                    items={items}
                    value={values.resposta}
                    onChange={(v) => setFieldValue('resposta', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="resultado"
                    label="Resultado"
                    value={values.resultado}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue('resultado', setDecimal(e.target.value, 3));
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="quantidade"
                    label="Quantidade"
                    value={values.quantidade}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue(
                        'quantidade',
                        setDecimal(e.target.value, 3)
                      );
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="observacao"
                    label="Observação"
                    value={values.observacao}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSend(values)}
                  disabled={postRespostaLoading}
                  className="mr-2"
                >
                  Confirmar
                </Button>
              </div>
              {getQualidadeLoading ? (
                <Loading />
              ) : (
                <>
                  <DialogTitle className="p-0">Respostas</DialogTitle>
                  <TableContainer component={Paper} className="my-3">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Resultado</TableCell>
                          <TableCell>Observação</TableCell>
                          <TableCell align="right">Ação</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selected.Respostas.map((m) => (
                          <TableRow>
                            <TableCell>
                              {m?.qualidade_indicador_resp_id}
                            </TableCell>
                            <TableCell>
                              {m?.quantidade
                                ? setDecimal(m?.quantidade, 2)
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {m?.resultado ? setDecimal(m?.resultado, 2) : '-'}
                            </TableCell>
                            <TableCell>
                              {m?.observacao ? m?.observacao : '-'}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              <DeleteIcon
                                color="action"
                                onClick={() =>
                                  dispatch(
                                    TablesActions.deleteData({
                                      table: 'documento_item_qualidade_resp',
                                      id: m?.id,
                                    })
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(AvaliacaoModal);
