import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import HeaderPage from 'components/HeaderPage';
import SearchBox from 'components/SearchBox';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  searchGrid: {
    padding: '0 0.5rem',
    marginBottom: '3rem',
  },
  btnLabel: {
    justifyContent: 'flex-start',
  },
});

const Tabelas = ({ match, classes }) => {
  const history = useHistory();
  const { tabelas } = useSelector(({ auth }) => auth);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!!tabelas.length) {
      setList(tabelas);
    }
  }, [tabelas]);

  useEffect(() => {
    setList(
      tabelas.filter((f) =>
        f?.descrotina?.toUpperCase()?.includes(search?.toUpperCase())
      )
    );
  }, [search]);

  return (
    <div className="app-wrapper">
      <HeaderPage title="Tabelas" />
      <Card className="shadow">
        <CardContent>
          <Grid container>
            <Grid
              xs="12"
              sm="12"
              md="12"
              lg="12"
              className={classes.searchGrid}
            >
              <SearchBox
                placeholder="Buscar Tabelas..."
                onChange={(evt) => setSearch(evt.target.value)}
                value={search}
                onSubmit={() => {}}
              />
            </Grid>
            {list.map((t) => (
              <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                <Button
                  size="small"
                  classes={{ label: classes.btnLabel }}
                  onClick={() =>
                    history.push(
                      `/app/Tabela?title=${t?.descrotina}&rot=${t?.rotina_id}&csql=${t?.csql_id}`
                    )
                  }
                >
                  <h4 className="mb-0">• {t?.descrotina}</h4>
                </Button>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Tabelas);
