import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { Formik } from 'formik';
import { transformData, setDecimal, findOnArray } from 'util/index';
import moment from 'moment';
import api from 'util/api';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as ProdutoActions } from 'store/ducks/Produto';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '50%',
  },
});

const ReajusteModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { postReajusteLoading, postReajusteSuccess } = useSelector(
    ({ produto }) => produto
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    loadItems([712, 638, 639, 632, 633, 623, 825, 640, 831]);
  }, []);

  useEffect(() => {
    if (postReajusteSuccess) {
      dispatch(ProdutoActions.postReajusteReset());
      setOpen(false);
    }
  }, [postReajusteSuccess]);

  const onSend = (values) => {
    const form = {
      produto_id: values?.produto_id?.value,
      celinha_id: values?.celinha_id?.value,
      cegrupo_id: values?.cegrupo_id?.value,
      moeda_id: values?.moeda_id?.value,
      cliente_id: values?.cliente_id?.value,
      vendedor_id: values?.vendedor_id?.value,
      grupo_entidade_id: values?.grupo_entidade_id?.value,
      condicao_pagamento_id: values?.condicao_pagamento_id?.value,
      tabpreco_id: values?.tabpreco_id?.value,
      inicio: moment(values?.inicio, 'DD/MM/YYYY').isValid()
        ? moment(values?.inicio, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      percentual: transformData(values?.percentual, 'number'),
    };
    dispatch(ProdutoActions.postReajuste(form));
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Reajuste</DialogTitle>
      <Formik
        initialValues={{
          produto_id: null,
          celinha_id: null,
          cegrupo_id: null,
          moeda_id: null,
          cliente_id: null,
          vendedor_id: null,
          grupo_entidade_id: null,
          condicao_pagamento_id: null,
          tabpreco_id: null,
          inicio: moment().format('DD/MM/YYYY'),
          percentual: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Produto"
                    items={items[0]}
                    value={values?.produto_id}
                    onChange={(v) => setFieldValue('produto_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Linha"
                    items={items[1]}
                    value={values?.celinha_id}
                    onChange={(v) => setFieldValue('celinha_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Grupo"
                    items={items[2]}
                    value={values?.cegrupo_id}
                    onChange={(v) => setFieldValue('cegrupo_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Moeda"
                    items={items[3]}
                    value={values?.moeda_id}
                    onChange={(v) => setFieldValue('moeda_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Cliente"
                    items={items[4]}
                    value={values?.cliente_id}
                    onChange={(v) => setFieldValue('cliente_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Vendedor"
                    items={items[5]}
                    value={values?.vendedor_id}
                    onChange={(v) => setFieldValue('vendedor_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Grupo Entidade"
                    items={items[6]}
                    value={values?.grupo_entidade_id}
                    onChange={(v) => setFieldValue('grupo_entidade_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Condição de Pagamento"
                    items={items[7]}
                    value={values?.condicao_pagamento_id}
                    onChange={(v) => setFieldValue('condicao_pagamento_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Preço de Tabela"
                    items={items[8]}
                    value={values?.tabpreco_id}
                    onChange={(v) => setFieldValue('tabpreco_id', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                    name="inicio"
                    label="Início"
                    mask="99/99/9999"
                    value={values.inicio || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="percentual"
                    label="Percentual"
                    value={values.percentual || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('percentual', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => onSend(values)}
                disabled={postReajusteLoading}
              >
                Enviar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(ReajusteModal);
