import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';

const DeleteModal = ({ open, setOpen, id, rotina, onDelete, loading }) => {
  const dispatch = useDispatch();
  const { deleteLoading, deleteSuccess } = useSelector(({ tables }) => tables);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(TablesActions.deleteDataReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(false);
    }
  }, [deleteSuccess]);

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Confirmar Exclusão</DialogTitle>
      {deleteLoading || loading ? (
        <Loading />
      ) : (
        <DialogContent>
          <DialogContentText>
            Deseja realmente excluir o registro {id}?
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onDelete} color="primary" disabled={deleteLoading}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteModal;
