import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  DialogContentText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from 'util/api';
import { setDecimal } from 'util/index';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const HaverModal = ({ open, setOpen, movimento, classes }) => {
  const [items, setItems] = useState([]);

  async function loadItems() {
    if (movimento[0]?.cadastro_id) {
      const { data } = await api.post(`/csql/591`, {
        WhereClause: `and d.cadastro_id = ${movimento[0]?.cadastro_id}`,
      });
      if (data) {
        setItems(data.dados || []);
      }
    }
  }

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Registros em Haver</DialogTitle>
      <DialogContent>
        {!!items?.length ? (
          <TableContainer component={Paper} className="my-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Número</TableCell>
                  <TableCell>Data de Emissão</TableCell>
                  <TableCell>Espécie</TableCell>
                  <TableCell>Operação</TableCell>
                  <TableCell>Natureza</TableCell>
                  <TableCell>Valor Haver</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((m) => (
                  <TableRow>
                    <TableCell>{m?.id}</TableCell>
                    <TableCell>{m?.numero}</TableCell>
                    <TableCell>
                      {moment(m?.dtemissao).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{m?.especie}</TableCell>
                    <TableCell>{m?.operacao}</TableCell>
                    <TableCell>{m?.natureza}</TableCell>
                    <TableCell>{setDecimal(m?.vlhaver, 2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <DialogContentText>Nenhum registro encontrado</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row align-items-end">
          <Button
            variant="outlined"
            color="primary"
            className="ml-2"
            onClick={() => setOpen(null)}
          >
            VOLTAR
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(HaverModal);
