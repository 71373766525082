import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Paper,
  Step,
  InputLabel,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import HeaderPage from 'components/HeaderPage';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { useQuery, setDecimal, transformData } from 'util/index';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridTitle: {
    padding: '0 0.5rem',
    marginTop: '1rem',
  },
  gridText: {
    padding: '0.5rem',
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const FormacaoCusto = ({ match, classes }) => {
  const formRef = useRef();
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const id = query.get('id');
  const { docItem, putDocItemSuccess } = useSelector(
    ({ documento }) => documento
  );

  useEffect(() => {
    dispatch(DocumentoActions.getDocItem(id));
  }, []);

  useEffect(() => {
    if (putDocItemSuccess) {
      dispatch(DocumentoActions.putDocItemReset());
      history.goBack();
    }
  }, [putDocItemSuccess]);

  const calculoPorcentagem = (valor, total) => {
    const pctg = (valor * 100) / total;
    return setDecimal(pctg, 3);
  };

  const calculoValor = (pctg, total) => {
    const valor = (pctg / 100) * total;
    return setDecimal(valor, 2);
  };

  const calculoDesconto = () => {
    const values = formRef.current.values;
    values.precoDescTotal = setDecimal(
      (1 -
        (1 - transformData(values.desc1, 'number') / 100) *
          (1 - transformData(values.desc2, 'number') / 100) *
          (1 - transformData(values.desc3, 'number') / 100) *
          (1 - transformData(values.desc4, 'number') / 100) *
          (1 - transformData(values.desc5, 'number') / 100)) *
        transformData(values.precoTotal, 'number'),
      2
    );
  };

  const recalc = () => {
    const values = formRef.current.values;
    values.precoTotal = setDecimal(
      transformData(values.preco, 'number') *
        transformData(values.qtd, 'number'),
      2
    );
    calculoDesconto();
    values.frete_nd = calculoValor(
      transformData(values.frete_nd_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.frete_d = calculoValor(
      transformData(values.frete_d_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.seguro_nd = calculoValor(
      transformData(values.seguro_nd_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.seguro_d = calculoValor(
      transformData(values.seguro_d_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.comissao_nd = calculoValor(
      transformData(values.comissao_nd_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.comissao_d = calculoValor(
      transformData(values.comissao_d_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.impostos_nd = calculoValor(
      transformData(values.impostos_nd_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.impostos_d = calculoValor(
      transformData(values.impostos_d_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.custofin = calculoValor(
      transformData(values.custofin_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.eventuais = calculoValor(
      transformData(values.eventuais_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.acessorias = calculoValor(
      transformData(values.acessorias_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.outros = calculoValor(
      transformData(values.outros_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
    values.internacionalizacao = calculoValor(
      transformData(values.internacionalizacao_p, 'number'),
      transformData(values.precoTotal, 'number')
    );
  };

  const SetFields = () => {
    const { setFieldValue, setValues, values } = useFormikContext();
    useEffect(() => {
      if (docItem) {
        setValues({
          preco: setDecimal(docItem?.preco, 2),
          qtd: setDecimal(docItem?.quantidade, 3),
          precoTotal: setDecimal(docItem?.precotot, 2),
          desc1: setDecimal(docItem.desconto1_pc, 3),
          desc2: setDecimal(docItem.desconto2_pc, 3),
          desc3: setDecimal(docItem.desconto3_pc, 3),
          desc4: setDecimal(docItem.desconto4_pc, 3),
          desc5: setDecimal(docItem.desconto5_pc, 3),
          precoDescTotal: setDecimal(docItem?.desconto_vlr, 2),
          frete_nd_p: calculoPorcentagem(docItem?.fretend, docItem?.precotot),
          frete_nd: setDecimal(docItem?.fretend, 2),
          frete_d_p: calculoPorcentagem(docItem?.frete, docItem?.precotot),
          frete_d: setDecimal(docItem?.frete, 2),
          seguro_nd_p: calculoPorcentagem(docItem?.segurond, docItem?.precotot),
          seguro_nd: setDecimal(docItem?.segurond, 2),
          seguro_d_p: calculoPorcentagem(docItem?.seguro, docItem?.precotot),
          seguro_d: setDecimal(docItem?.seguro, 2),
          comissao_nd_p: calculoPorcentagem(
            docItem?.comissaond,
            docItem?.precotot
          ),
          comissao_nd: setDecimal(docItem?.comissaond, 2),
          comissao_d_p: setDecimal(docItem?.comissao_pc, 3),
          comissao_d: calculoValor(docItem?.comissao_pc, docItem?.precotot),
          impostos_nd_p: calculoPorcentagem(
            docItem?.impfednd,
            docItem?.precotot
          ),
          impostos_nd: setDecimal(docItem?.impfednd, 2),
          impostos_d_p: calculoPorcentagem(docItem?.impfed, docItem?.precotot),
          impostos_d: setDecimal(docItem?.impfed, 2),
          custofin: setDecimal(docItem?.cstfin, 2),
          custofin_p: calculoPorcentagem(docItem?.cstfin, docItem?.precotot),
          eventuais: setDecimal(docItem?.eventuais, 2),
          eventuais_p: calculoPorcentagem(
            docItem?.eventuais,
            docItem?.precotot
          ),
          acessorias: setDecimal(docItem?.despac, 2),
          acessorias_p: calculoPorcentagem(docItem?.despac, docItem?.precotot),
          outros: setDecimal(docItem?.outros, 2),
          outros_p: calculoPorcentagem(docItem?.outros, docItem?.precotot),
          internacionalizacao: setDecimal(docItem?.internacional, 2),
          internacionalizacao_p: calculoPorcentagem(
            docItem?.internacional,
            docItem?.precotot
          ),
          liquido: setDecimal(docItem?.custoliq, 2),
          margem: setDecimal(docItem?.vlrlucro, 2),
          margem_p: setDecimal(docItem?.pclucro, 3),
        });
      }
    }, [docItem]);
    return null;
  };

  const onSend = (values) => {
    const data = {
      documento_id: docItem?.documento_id,
      preco: transformData(values?.preco, 'number'),
      quantidade: transformData(values?.qtd, 'number'),
      precotot: transformData(values?.precoTotal, 'number'),
      desconto1_pc: transformData(values?.desc1, 'number'),
      desconto2_pc: transformData(values?.desc2, 'number'),
      desconto3_pc: transformData(values?.desc3, 'number'),
      desconto4_pc: transformData(values?.desc4, 'number'),
      desconto5_pc: transformData(values?.desc5, 'number'),
      desconto_vlr: transformData(values?.precoDescTotal, 'number'),
      fretend: transformData(values?.frete_nd, 'number'),
      frete: transformData(values?.frete_d, 'number'),
      segurond: transformData(values?.seguro_nd, 'number'),
      seguro: transformData(values?.seguro_d, 'number'),
      comissaond: transformData(values?.comissao_nd, 'number'),
      comissao_pc: transformData(values?.comissao_d, 'number'),
      impfednd: transformData(values?.impostos_nd, 'number'),
      impfed: transformData(values?.impostos_d, 'number'),
      cstfin: transformData(values?.custofin, 'number'),
      eventuais: transformData(values?.eventuais, 'number'),
      despac: transformData(values?.acessorias, 'number'),
      outros: transformData(values?.outros, 'number'),
      internacional: transformData(values?.internacionalizacao, 'number'),
    };

    dispatch(DocumentoActions.putDocItem({ id, data }));
  };

  return (
    <div className="app-wrapper">
      <HeaderPage
        title={`Formação do Custo ${docItem && '- ' + docItem?.descricao}`}
        history={history}
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          preco: null,
          qtd: null,
          precoTotal: null,
          desc1: null,
          desc2: null,
          desc3: null,
          desc4: null,
          desc5: null,
          precoDescTotal: null,
          frete_nd_p: null,
          frete_nd: null,
          frete_d_p: null,
          frete_d: null,
          seguro_nd_p: null,
          seguro_nd: null,
          seguro_d_p: null,
          seguro_d: null,
          comissao_nd_p: null,
          comissao_nd: null,
          comissao_d_p: null,
          comissao_d: null,
          impostos_nd_p: null,
          impostos_nd: null,
          impostos_d_p: null,
          impostos_d: null,
          custofin: null,
          custofin_p: null,
          eventuais: null,
          eventuais_p: null,
          acessorias: null,
          acessorias_p: null,
          outros: null,
          outros_p: null,
          internacionalizacao: null,
          internacionalizacao_p: null,
          liquido: null,
          margem: null,
          margem_p: null,
        }}
      >
        {({ values, handleChange, setFieldValue, setValues }) => (
          <>
            <SetFields />
            <Card className="shadow">
              <CardContent>
                <Grid container className="mb-4">
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="preco"
                      label="Preco de Venda"
                      value={values?.preco || ''}
                      onChange={handleChange}
                      onBlur={() => {
                        setFieldValue('preco', setDecimal(values?.preco, 2));
                        recalc();
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>*</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="qtd"
                      label="Quantidade"
                      value={values?.qtd || ''}
                      onChange={handleChange}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>{docItem?.unidade_medida || 'Um'} =</InputLabel>
                  </Grid>
                  <Grid xs="4" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="precoTotal"
                      label="Total"
                      value={values?.precoTotal || ''}
                      onChange={handleChange}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid container className="mb-4">
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desc1"
                      label="Desconto 1"
                      value={values?.desc1 || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setFieldValue('desc1', setDecimal(values?.desc1, 3));
                        calculoDesconto();
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desc2"
                      label="Desconto 2"
                      value={values?.desc2 || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setFieldValue('desc2', setDecimal(values?.desc2, 3));
                        calculoDesconto();
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desc3"
                      label="Desconto 3"
                      value={values?.desc3 || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setFieldValue('desc3', setDecimal(values?.desc3, 3));
                        calculoDesconto();
                      }}
                    />
                  </Grid>

                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desc4"
                      label="Desconto 4"
                      value={values?.desc4 || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setFieldValue('desc4', setDecimal(values?.desc4, 3));
                        calculoDesconto();
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desc5"
                      label="Desconto 5"
                      value={values?.desc5 || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setFieldValue('desc5', setDecimal(values?.desc5, 3));
                        calculoDesconto();
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="precoDescTotal"
                      label="Total"
                      value={values?.precoDescTotal || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          precoDescTotal: setDecimal(values?.precoDescTotal, 2),
                          desc1: calculoPorcentagem(
                            transformData(values?.precoDescTotal, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                          desc2: '0,000',
                          desc3: '0,000',
                          desc4: '0,000',
                          desc5: '0,000',
                        });
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container className="mb-4">
                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Frete Não Destacado</InputLabel>
                  </Grid>
                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Frete Destacado</InputLabel>
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="frete_nd_p"
                      value={values?.frete_nd_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          frete_nd_p: setDecimal(values?.frete_nd_p, 3),
                          frete_nd: calculoValor(
                            transformData(values.frete_nd_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="frete_nd"
                      value={values?.frete_nd || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          frete_nd: setDecimal(values?.frete_nd, 2),
                          frete_nd_p: calculoPorcentagem(
                            transformData(values.frete_nd, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="frete_d_p"
                      value={values?.frete_d_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          frete_d_p: setDecimal(values?.frete_d_p, 3),
                          frete_d: calculoValor(
                            transformData(values.frete_d_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="frete_d"
                      value={values?.frete_d || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          frete_d: setDecimal(values?.frete_d, 2),
                          frete_d_p: calculoPorcentagem(
                            transformData(values.frete_d, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Seguro Não Destacado</InputLabel>
                  </Grid>
                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Seguro Destacado</InputLabel>
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="seguro_nd_p"
                      value={values?.seguro_nd_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          seguro_nd_p: setDecimal(values?.seguro_nd_p, 3),
                          seguro_nd: calculoValor(
                            transformData(values.seguro_nd_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="seguro_nd"
                      value={values?.seguro_nd || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          seguro_nd: setDecimal(values?.seguro_nd, 2),
                          seguro_nd_p: calculoPorcentagem(
                            transformData(values.seguro_nd, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="seguro_d_p"
                      value={values?.seguro_d_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          seguro_d_p: setDecimal(values?.seguro_d_p, 3),
                          seguro_d: calculoValor(
                            transformData(values.seguro_d_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="seguro_d"
                      value={values?.seguro_d || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          seguro_d: setDecimal(values?.seguro_d, 2),
                          seguro_d_p: calculoPorcentagem(
                            transformData(values.seguro_d, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Comissão Não Destacada</InputLabel>
                  </Grid>
                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Comissão Destacada</InputLabel>
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="comissao_nd_p"
                      value={values?.comissao_nd_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          comissao_nd_p: setDecimal(values?.comissao_nd_p, 3),
                          comissao_nd: calculoValor(
                            transformData(values.comissao_nd_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="comissao_nd"
                      value={values?.comissao_nd || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          comissao_nd: setDecimal(values?.comissao_nd, 2),
                          comissao_nd_p: calculoPorcentagem(
                            transformData(values.comissao_nd, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="comissao_d_p"
                      value={values?.comissao_d_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          comissao_d_p: setDecimal(values?.comissao_d_p, 3),
                          comissao_d: calculoValor(
                            transformData(values.comissao_d_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="comissao_d"
                      value={values?.comissao_d || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          comissao_d: setDecimal(values?.comissao_d, 2),
                          comissao_d_p: calculoPorcentagem(
                            transformData(values.comissao_d, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Impostos Federais Não Destacados</InputLabel>
                  </Grid>
                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Impostos Federais Destacados</InputLabel>
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="impostos_nd_p"
                      value={values?.impostos_nd_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          impostos_nd_p: setDecimal(values?.impostos_nd_p, 3),
                          impostos_nd: calculoValor(
                            transformData(values.impostos_nd_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="impostos_nd"
                      value={values?.impostos_nd || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          impostos_nd: setDecimal(values?.impostos_nd, 2),
                          impostos_nd_p: calculoPorcentagem(
                            transformData(values.impostos_nd, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="impostos_d_p"
                      value={values?.impostos_d_p || ''}
                      onChange={handleChange}
                      fullWidth
                      disabled
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          impostos_d_p: setDecimal(values?.impostos_d_p, 3),
                          impostos_d: calculoValor(
                            transformData(values.impostos_d_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="impostos_d"
                      value={values?.impostos_d || ''}
                      onChange={handleChange}
                      fullWidth
                      disabled
                      onBlur={() => {
                        setValues({
                          ...values,
                          impostos_d: setDecimal(values?.impostos_d, 2),
                          impostos_d_p: calculoPorcentagem(
                            transformData(values.impostos_d, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Custo Financeiro</InputLabel>
                  </Grid>
                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Eventuais</InputLabel>
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="custofin_p"
                      value={values?.custofin_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          custofin_p: setDecimal(values?.custofin_p, 3),
                          custofin: calculoValor(
                            transformData(values.custofin_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="custofin"
                      value={values?.custofin || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          custofin: setDecimal(values?.custofin, 2),
                          custofin_p: calculoPorcentagem(
                            transformData(values.custofin, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="eventuais_p"
                      value={values?.eventuais_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          eventuais_p: setDecimal(values?.eventuais_p, 3),
                          eventuais: calculoValor(
                            transformData(values.eventuais_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="eventuais"
                      value={values?.eventuais || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          eventuais: setDecimal(values?.eventuais, 2),
                          eventuais_p: calculoPorcentagem(
                            transformData(values.eventuais, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Acessórias</InputLabel>
                  </Grid>
                  <Grid
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Outros</InputLabel>
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="acessorias_p"
                      value={values?.acessorias_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          acessorias_p: setDecimal(values?.acessorias_p, 3),
                          acessorias: calculoValor(
                            transformData(values.acessorias_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="acessorias"
                      value={values?.acessorias || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          acessorias: setDecimal(values?.acessorias, 2),
                          acessorias_p: calculoPorcentagem(
                            transformData(values.acessorias, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="outros_p"
                      value={values?.outros_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          outros_p: setDecimal(values?.outros_p, 3),
                          outros: calculoValor(
                            transformData(values.outros_p, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="outros"
                      value={values?.outros || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          outros: setDecimal(values?.outros, 2),
                          outros_p: calculoPorcentagem(
                            transformData(values.outros, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.gridTitle}
                  >
                    <InputLabel>Internacionalização</InputLabel>
                  </Grid>
                  <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="internacionalizacao_p"
                      value={values?.internacionalizacao_p || ''}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{ endAdornment: '%' }}
                      onBlur={() => {
                        setValues({
                          ...values,
                          internacionalizacao_p: setDecimal(
                            values?.internacionalizacao_p,
                            3
                          ),
                          internacionalizacao: calculoValor(
                            transformData(
                              values.internacionalizacao_p,
                              'number'
                            ),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    xs="1"
                    sm="1"
                    md="1"
                    lg="1"
                    className={classes.gridText}
                  >
                    <InputLabel>=</InputLabel>
                  </Grid>
                  <Grid xs="3" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="internacionalizacao"
                      value={values?.internacionalizacao || ''}
                      onChange={handleChange}
                      fullWidth
                      onBlur={() => {
                        setValues({
                          ...values,
                          internacionalizacao: setDecimal(
                            values?.internacionalizacao,
                            2
                          ),
                          internacionalizacao_p: calculoPorcentagem(
                            transformData(values.internacionalizacao, 'number'),
                            transformData(values.precoTotal, 'number')
                          ),
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn"
                    color="primary"
                    onClick={() => onSend(values)}
                  >
                    ENVIAR
                  </Button>
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(FormacaoCusto);
