import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { setDecimal, replaceConteudoi } from 'util/index';
import styles from './styles';
import Loading from 'components/Loading';
import moment from 'moment';
import { Creators as TablesActions } from 'store/ducks/Tables';

function Resultados({ filtros, classes }) {
  const dispatch = useDispatch();
  const { resultsLoading, results } = useSelector(({ tables }) => tables);

  const res = [
    { value: setDecimal(results?.recbrt || '', 2), label: 'Receita Bruta' },
    {
      value: setDecimal(results?.imposto || '', 2),
      label: 'Impostos',
      pc: setDecimal(results?.pcimposto || '', 2),
    },
    {
      value: setDecimal(results?.custo || '', 2),
      label: 'Custos',
      pc: setDecimal(results?.pccusto || '', 2),
    },
    { value: setDecimal(results?.recliq || '', 2), label: 'Receita Líquida' },
    {
      value: setDecimal(results?.despfix || '', 2),
      label: 'Despesas Fixas',
      pc: setDecimal(results?.pcdespfix || '', 2),
    },
    {
      value: setDecimal(results?.despvar || '', 2),
      label: 'Despesas Variáveis',
      pc: setDecimal(results?.pcdespvar || '', 2),
    },
    {
      value: setDecimal(results?.mrgcont || '', 2),
      label: 'Margem Contribuição',
      pc: setDecimal(results?.pcmrgcont || '', 2),
    },
    {
      value: setDecimal(results?.equilibrio || '', 2),
      label: 'Equilíbrio Economico',
    },
  ];

  const onLoad = () => {
    const datai = replaceConteudoi(
      filtros.find((f) => f?.titulo === 'Data inicio')?.conteudoi
    );
    const dataf = replaceConteudoi(
      filtros.find((f) => f?.titulo === 'Data fim')?.conteudoi
    );
    const data = {
      filiali_id: filtros.find((f) => f?.titulo === 'Filial inicio')?.conteudoi,
      filialf_id: filtros.find((f) => f?.titulo === 'Filial fim')?.conteudoi,
      datai: moment(datai, 'DD/MM/YYYY').isValid()
        ? moment(datai, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      dataf: moment(dataf, 'DD/MM/YYYY').isValid()
        ? moment(dataf, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      contabiliza: filtros.find((f) => f?.titulo === 'Contabiliza')?.conteudoi,
      foraresultado: 'NAO',
      resultadoacc: 'SIM',
    };
    dispatch(TablesActions.getResults(data));
  };

  return (
    <div className="mt-3">
      <ExpansionPanel
        elevation={3}
        onChange={(_, exp) => {
          if (exp) {
            onLoad();
          }
        }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h4 className={classes.titles}>Resultados</h4>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={0}>
            {resultsLoading ? (
              <Loading />
            ) : (
              <>
                {res.map((r) => (
                  <Grid container item xs={3} spacing={0}>
                    <Paper
                      elevation={5}
                      className={`${classes.paper} bg-gradient-primary-x text-white`}
                    >
                      <h4 className={`${classes.titles} text-center`}>
                        {r.label}
                      </h4>
                      {r.pc ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className={classes.valor}>{r.value}</h6>
                          <h6 className={classes.valor}>{r.pc}%</h6>
                        </div>
                      ) : (
                        <h6 className={classes.valor}>{r.value}</h6>
                      )}
                    </Paper>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default withStyles(styles)(Resultados);
