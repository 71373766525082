import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import moment from 'moment';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import Legendas from 'components/Legendas';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import FilterReportModal from 'components/Modals/FilterReportModal';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import ResumoModal from 'components/Modals/ResumoModal';
import DocStatusModal from 'components/Modals/DocStatusModal';
import DocGerarModal from 'components/Modals/DocGerarModal';
import {
  checkManutencao,
  checkAgrupamento,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ResumoActions } from 'store/ducks/Resumo';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as ProdutoActions } from 'store/ducks/Produto';

const GestãodeProdução = ({ match }) => {
  const rotina = 442;
  const csql = 321;
  const [relatorio, setRelatorio] = useState(null);
  const title = 'Gestão da Produção';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState('');
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [multiSelected, setMultiSelected] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [docStatusModal, setDocStatusModal] = useState(false);
  const [docGerarModal, putDocGerarModal] = useState(false);
  const [docGerarNumbers, setDocGerarNumbers] = useState({
    despecie: 0,
    dnatureza: 0,
  });
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const { imprimirData, statusSuccess, docgerar, capaSuccess } = useSelector(
    ({ documento }) => documento
  );
  const { postAttCustoSuccess } = useSelector(({ produto }) => produto);

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = filtros.map((f) => {
        if (f.parametro === 'SIM') {
          return { ...f, conteudoi: param, conteudod: nameParam };
        }
        return f;
      });
      return filterWithParam;
    }
    return filtros;
  }

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      dispatch(ResumoActions.setFilterResumo(setParamOnFilter()));

      dispatch(
        TablesActions.getDataTable({
          csql,
          params: {
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
            OrderClause,
            Pagina: paginacao ? Pagina : undefined,
            PorPagina: paginacao ? PorPagina : undefined,
          },
        })
      );
    }
  }

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length && param) {
      dispatch(TablesActions.setFiltros(setParamOnFilter()));
    } else {
      if (headerData.length) {
        getDataTable();
      }
    }
  }, [headerData, Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (imprimirData) {
      const file = new Blob([imprimirData], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatch(DocumentoActions.imprimirDocumentoReset());
    }
  }, [imprimirData]);

  useEffect(() => {
    if (postAttCustoSuccess) {
      dispatch(ProdutoActions.postAttCustoReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postAttCustoSuccess]);

  useEffect(() => {
    if (statusSuccess) {
      dispatch(DocumentoActions.postStatusReset());
      dispatch(TablesActions.getHeader(rotina));
    }
    if (capaSuccess) {
      dispatch(TablesActions.getHeader(rotina));
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
    }
  }, [statusSuccess, capaSuccess]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (docgerar) {
      putDocGerarModal(!docGerarModal);
    }
  }, [docgerar]);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    if ((relatorio === 617 || relatorio === 566) && reportFiltros.length) {
      dispatch(
        TablesActions.postReport({
          rotina_id: relatorio,
          WhereClause: getWhereClause({
            filtros: [
              {
                ...reportFiltros[0],
                conteudoi:
                  relatorio === 617 ? selected?.documento_id : selected?.iditem,
              },
            ],
          }),
        })
      );
      setRelatorio(null);
    }
  }, [reportFiltros]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () =>
        param
          ? history.push(`/app/Manutenção?cod=${param}`)
          : history.push(`/app/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => setFilterModal(!filterModal),
      visible: filtros?.length,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: checkAgrupamento(headerData),
    },
    {
      name: 'Novo Documento',
      type: 'Gerar',
      action: () =>
        history.push(
          `/app/Cadastros/Documento?especie=${data[0]?.especie_id}&natureza=${data[0]?.natureza_operacao_id}`
        ),
      visible: true,
    },
    {
      name: 'Atualizar Custos',
      type: 'Cheque',
      action: () => {
        let Itens = multiSelected.map((i) => i?.id);
        if (!!Itens?.length) {
          dispatch(ProdutoActions.postAttCusto({ Itens }));
        }
      },
      visible: data[0]?.vercusto != 'NAO',
    },
    {
      name: 'Requisitar Materiais',
      type: 'Gerar',
      action: () => {
        if (!!multiSelected?.length) {
          setDocGerarNumbers({ despecie: 38, dnatureza: 28 });
          let Itens = multiSelected.map((i) => ({ id: i?.id }));
          dispatch(
            DocumentoActions.putDocGerar({ Itens, Rotina: 'REQUISICAO' })
          );
        }
      },
      visible: true,
    },
    {
      name: 'Registrar Produção',
      type: 'Gerar',
      action: () => {
        if (!!multiSelected?.length) {
          setDocGerarNumbers({ despecie: 0, dnatureza: 0 });
          let Documentos = multiSelected.map((i) => ({ id: i?.documento_id }));
          dispatch(
            DocumentoActions.putDocGerar({ Documentos, Rotina: 'PRODUCAO' })
          );
        }
      },
      visible: true,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Documento',
          action: (item) =>
            history.push(`/app/Cadastros/Documento?id=${item?.documento_id}`),
          visible: true,
        },
        {
          name: 'Registros',
          action: (item) =>
            history.push(
              `/app/Suprimentos/GestãodeCompras/Registros?id=${item?.documento_id}&name=${item.documento_id}' - '${item.descricao}`
            ),
          visible: true,
        },
        {
          name: 'Copiar Documento',
          varName: 'Gerar',
          action: (item) => {
            setDocGerarNumbers({ despecie: 0, dnatureza: 0 });
            setMultiSelected([item]);
            let Documentos = [{ id: item?.iddoc }];
            dispatch(
              DocumentoActions.putDocGerar({ Documentos, Rotina: 'COPIA' })
            );
          },
          visible: true,
        },
        {
          name: 'Estrutura do Produto',
          varName: 'EstruturaDoProduto',
          action: (item) =>
            history.push(
              `/app/Produção/Estrutura?id=${item?.id}&name=${item.descricao}`
            ),
          visible: true,
        },
        {
          name: 'Processos de produção',
          varName: 'ProcessosDeProdução',
          action: (item) =>
            history.push(
              `/app/Produção/Processo?id=${item?.id}&name=${item.descricao}`
            ),
          visible: true,
        },
        {
          name: 'Aprovação',
          varName: 'Aprovação',
          action: (item) =>
            history.push(
              `/app/Aprovação?id=${item?.documento_id}&name=${item.especie} - Id: ${item.documento_id}`
            ),
          visible: true,
        },
        {
          name: 'Status',
          varName: 'DocStatus',
          action: (item) => {
            setSelected(item);
            setDocStatusModal(!docStatusModal);
          },
          visible: true,
        },
        {
          name: 'Indicadores de qualidade',
          varName: 'Qualidade',
          action: (item) =>
            history.push(
              `/app/DocItem/Qualidade/Indicadores?id=${item?.id}&descricao=${
                item.especie
              } - ${item.natureza} (${item.documento_id}) - ${
                item.entidade
              } - ${moment(item.dtemissao).format('DD/MM/YYYY')}&item=ITEM: ${
                item.descricao
              }`
            ),
          visible: true,
        },
        {
          name: 'Ordem de Fabricação',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(566);
          },
          visible: true,
        },
        {
          name: 'Etiqueta',
          varName: 'Imprimir',
          action: () => {},
          visible: true,
        },
        {
          name: 'Mapa de Produção',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(617);
          },
          visible: true,
        },
        {
          name: 'Formação do Custo',
          varName: 'Preço',
          action: (item) => {
            history.push(
              `/app/Produção/GestãodeProdução/FormacaoCusto?id=${
                item?.iditem
              }&name=${item?.descricao} - ${parseFloat(
                Number(item?.quantidade).toFixed(3)
              )}  ${item?.unidade_medida}`
            );
          },
          funcVis: (item) => item?.vercusto != 'NAO',
        },
      ],
      visible: true,
    },
    {
      name: 'Status',
      statScreen: 'Producao',
      action: () => {},
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                selected={multiSelected}
                setSelected={setMultiSelected}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Legendas screen="Producao" />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filtros}
                reportFiltros={reportFiltros}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <DocStatusModal
                open={docStatusModal}
                setOpen={setDocStatusModal}
                selected={selected}
              />
              <DocGerarModal
                open={docGerarModal}
                setOpen={putDocGerarModal}
                selected={multiSelected}
                despecie={docGerarNumbers?.despecie}
                dnatureza={docGerarNumbers?.dnatureza}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default GestãodeProdução;
