import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import api from 'util/api';
import { Formik, useFormikContext } from 'formik';
import SelectAuto from 'components/SelectAuto';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { useSelector, useDispatch } from 'react-redux';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '50%',
  },
});

const QualidadeModal = ({ open, setOpen, idItem, classes }) => {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const { saveLoading, saveSuccess } = useSelector(({ tables }) => tables);
  const { qualidade } = useSelector(({ documento }) => documento);

  async function loadItems(ids) {
    const promises = ids.map(async (id) => {
      if (id !== null) {
        const { data } = await api.post(
          `/csql/${id}`,
          id === 819 && {
            Parametros: [{ nome: ':id', valor: Number(idItem) }],
          }
        );
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setItems(results);
  }

  const onSend = (values) => {
    const campos = {
      documento_item_id: Number(idItem),
      documento_item_processo_id: values?.processo?.value,
      sequencia: (qualidade[qualidade.length - 1]?.sequencia || 0) + 1,
      qualidade_indicador_id: values?.indicador?.value,
      instrumento_id: values?.instrumento?.value,
    };
    dispatch(
      TablesActions.saveData({
        table: 'documento_item_qualidade',
        campos,
      })
    );
  };

  useEffect(() => {
    loadItems([818, 819, 712]);
  }, []);

  const SetFields = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (saveSuccess) {
        dispatch(TablesActions.saveDataReset());
        setValues({
          indicador: null,
          processo: null,
          instrumento: null,
        });
      }
    }, [saveSuccess]);
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <Formik
        initialValues={{
          indicador: null,
          processo: null,
          instrumento: null,
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <SetFields />
            <DialogTitle>Adicionar Indicadores de Qualidade</DialogTitle>
            <DialogContent>
              <Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Indicador de Avaliação"
                    items={items[0]}
                    value={values.indicador}
                    onChange={(v) => setFieldValue('indicador', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Processo de Produção"
                    items={items[1]}
                    value={values.processo}
                    onChange={(v) => setFieldValue('processo', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Instrumento de Aferição"
                    items={items[2]}
                    value={values.instrumento}
                    onChange={(v) => setFieldValue('instrumento', v)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => onSend(values)}
                disabled={saveLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(QualidadeModal);
