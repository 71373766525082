import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { toast } from 'react-toastify';
import SelectAuto from 'components/SelectAuto';
import axios from 'axios';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const SearchAdressModal = ({
  open,
  setOpen,
  items,
  setFieldValue,
  classes,
}) => {
  const [adress, setAdress] = useState([]);
  const [selected, setSelected] = useState(null);
  const [values, setValues] = useState({
    endereco: null,
    uf: null,
    cidade: null,
  });

  async function loadAdress() {
    if (
      Boolean(values?.endereco) &&
      Boolean(values?.uf) &&
      Boolean(values?.cidade)
    ) {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${values?.uf?.label?.substring(0, 2)}/${
          values?.cidade?.label?.split('(')[0]
        }/${values?.endereco}/json/`
      );
      setAdress(data);
    } else {
      toast.warning('Preencha todos os campos');
    }
  }

  useEffect(() => {
    if (!open) {
      setValues(null);
      setAdress([]);
    }
  }, [open]);

  const onSubmit = () => {
    const estado = items[4].find(
      (e) => e.label.substring(0, 2) === selected?.uf
    );
    const cidade = items[5].find(
      (c) => Number(c?.codigo_ibge) === Number(selected?.ibge)
    );

    setFieldValue('Cep', selected?.cep);
    setFieldValue('UF', estado);
    setFieldValue('Cidade', cidade);
    setFieldValue('Bairro', selected?.bairro);
    setFieldValue('Logradouro', selected?.logradouro);
    setOpen(false);
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Pesquisar Endereço</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
            <TextField
              label="Endereço"
              value={values?.endereco || ''}
              onChange={(e) =>
                setValues({ ...values, endereco: e?.target?.value })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <SelectAuto
              label="Estado"
              items={items[4]}
              value={values?.uf}
              onChange={(v) => setValues({ ...values, uf: v })}
            />
          </Grid>
          <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
            <SelectAuto
              label="Cidade"
              items={
                items[5].filter(
                  (c) => c?.sigla === values?.uf?.label?.substring(0, 2)
                ) || items[5]
              }
              value={values?.cidade}
              onChange={(v) => setValues({ ...values, cidade: v })}
            />
          </Grid>
          <Grid xs="12" sm="2" md="2" lg="2" className={classes.gridButton}>
            <IconButton onClick={loadAdress}>
              <Search color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="my-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>CEP</TableCell>
                <TableCell>Logradouro</TableCell>
                <TableCell>Bairro</TableCell>
                <TableCell>Cidade</TableCell>
                <TableCell>UF</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adress.map((m) => (
                <TableRow>
                  <TableCell>
                    <Checkbox
                      style={{ padding: '0 5px' }}
                      color="default"
                      checked={selected?.cep === m?.cep}
                      onChange={() => {
                        if (selected?.cep === m?.cep) {
                          setSelected(null);
                        } else {
                          setSelected(m);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{m?.cep}</TableCell>
                  <TableCell>{m?.logradouro}</TableCell>
                  <TableCell>{m?.bairro}</TableCell>
                  <TableCell>{m?.localidade}</TableCell>
                  <TableCell>{m?.uf}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(SearchAdressModal);
