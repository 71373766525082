import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { toast } from 'react-toastify';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import FilterReportModal from 'components/Modals/FilterReportModal';
import ResumoModal from 'components/Modals/ResumoModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import DocGerarModal from 'components/Modals/DocGerarModal';
import DocStatusModal from 'components/Modals/DocStatusModal';
import IntegraDocModal from 'components/Modals/IntegraDocModal';
import FaturarModal from 'components/Modals/FaturarModal';
import WhatsappModal from 'components/Modals/WhatsappModal';
import moment from 'moment';
import {
  checkManutencao,
  checkAgrupamento,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as IntegraActions } from 'store/ducks/Integra';
import { Creators as FilterActions } from 'store/ducks/FilterState';

const OrçamentosVenda = ({ match }) => {
  const { filter } = useSelector(({ filterState }) => filterState);
  const filterPath = useLocation().pathname;
  const rotina = 820;
  const csql = 985;
  const [relatorio, setRelatorio] = useState(null);
  const title = 'Orçamentos de venda';
  const dispatch = useDispatch();
  const query = useQuery(useLocation);
  const history = useHistory();
  const [search, setSearch] = useState(
    filter[filterPath]?.dataTable?.Parametros[0]?.valor || ''
  );
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [multiSelected, setMultiSelected] = useState([]);
  const [totais, setTotais] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [docGerarModal, putDocGerarModal] = useState(false);
  const [docStatusModal, setDocStatusModal] = useState(false);
  const [integrarDocModal, setIntegrarDocModal] = useState(false);
  const [faturarModal, setFaturarModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [whatsapp, setWhatsapp] = useState(null);
  const [whatsappModal, setWhatsappModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const [link, setLink] = useState(false);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
    reportLink,
  } = useSelector(({ tables }) => tables);
  const {
    docgerar,
    capaSuccess,
    getConferenciaSuccess,
    statusSuccess,
    postDocumentoGerarSuccess,
  } = useSelector(({ documento }) => documento);
  const {
    postIntegraPedidosSuccess,
    postNotificacaoSuccess,
    postIntegra2rsSuccess,
  } = useSelector(({ integra }) => integra);
  const { authUser } = useSelector(({ auth }) => auth);

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = (filter[filterPath]?.filtros || filtros).map(
        (f) => {
          if (f.parametro === 'SIM') {
            return { ...f, conteudoi: param, conteudod: nameParam };
          }
          return f;
        }
      );
      return filterWithParam;
    }
    return filter[filterPath]?.filtros || filtros;
  }

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros: filter[filterPath]?.filtros || filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      setMultiSelected([]);
      const params = {
        Parametros: [{ nome: 'likevalue', valor: search }],
        WhereClause: getWhereClause({
          filtros: filter[filterPath]?.filtros || filtros,
          setStringExport,
        }),
        OrderClause,
        Pagina: paginacao ? Pagina : undefined,
        PorPagina: paginacao ? PorPagina : undefined,
      };
      dispatch(
        FilterActions.setFilterState({
          [filterPath]: { ...filter[filterPath], dataTable: params },
        })
      );
    }
  }

  useEffect(() => {
    if (Boolean(filter[filterPath]?.dataTable)) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: filter[filterPath]?.dataTable,
        })
      );
      getTotais();
    }
  }, [filter[filterPath]]);

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    if (
      (relatorio === 663 || relatorio === 590 || relatorio === 790) &&
      reportFiltros.length
    ) {
      dispatch(
        TablesActions.postReport({
          rotina_id: relatorio,
          WhereClause: getWhereClause({
            filtros: [{ ...reportFiltros[0], conteudoi: selected?.id }],
          }),
        })
      );
      setRelatorio(null);
    }
    if (relatorio === 491 && reportFiltros.length) {
      dispatch(
        TablesActions.postReport({
          rotina_id: relatorio,
          gerar_link: !!link ? 'Sim' : undefined,
          WhereClause: getWhereClause({
            filtros: [{ ...reportFiltros[0], conteudoi: selected?.id }],
          }),
        })
      );
      setRelatorio(null);
      setLink(false);
    }
  }, [reportFiltros]);

  useEffect(() => {
    if (capaSuccess) {
      getDataTable();
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
    }
  }, [capaSuccess]);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length && param) {
      dispatch(TablesActions.setFiltros(setParamOnFilter()));
      dispatch(
        FilterActions.setFilterState({
          [filterPath]: { ...filter[filterPath], filtros: setParamOnFilter() },
        })
      );
    } else {
      if (headerData.length) {
        getDataTable();
      }
    }
  }, [headerData, Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (docgerar) {
      putDocGerarModal(!docGerarModal);
    }
  }, [docgerar]);

  useEffect(() => {
    if (postIntegraPedidosSuccess) {
      dispatch(IntegraActions.postIntegraPedidosReset());
      dispatch(TablesActions.getHeader(rotina));
    }
    if (postNotificacaoSuccess) {
      setIntegrarDocModal(false);
      dispatch(IntegraActions.postNotificacaoReset());
      dispatch(TablesActions.getHeader(rotina));
    }
    if (postIntegra2rsSuccess) {
      setIntegrarDocModal(false);
      dispatch(IntegraActions.postIntegra2rsReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [
    postIntegraPedidosSuccess,
    postNotificacaoSuccess,
    postIntegra2rsSuccess,
  ]);

  useEffect(() => {
    if (getConferenciaSuccess) {
      dispatch(DocumentoActions.getConferenciaReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [getConferenciaSuccess]);

  useEffect(() => {
    if (statusSuccess) {
      dispatch(DocumentoActions.postStatusReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [statusSuccess]);

  useEffect(() => {
    if (postDocumentoGerarSuccess) {
      dispatch(DocumentoActions.postDocumentoGerarReset());
      setFaturarModal(false);
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postDocumentoGerarSuccess]);

  useEffect(() => {
    if (reportLink && !whatsapp) {
      let redirect = `mailto:${selected?.email?.replace(/;/g,'%3B')}?subject=Pedido de Venda&body=Segue pedido de venda número: ${selected?.documento_id}, conforme solicitado.%0D%0D
Para visualização acesse o link: %0D%0D
${reportLink}`;
      window.open(redirect);
      dispatch(TablesActions.postReportReset());
      setSelected(null);
    }
    if (whatsapp) {
      const msg = `Prezado ${selected?.nome}%0a%0aSegue ${selected?.especie} ID. ${selected?.documento_id} conforme a sua solicitação (clique no link abaixo para visualizá-lo).%0a%0aFicamos no aguardo do seu retorno e nos colocamos a disposição.%0a%0aAt. ${selected?.usuario}%0a${selected?.filial}%0a%0aLink: ${reportLink}`;
      let redirect = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${msg}`;
      window.open(redirect);
      dispatch(TablesActions.postReportReset());
      setWhatsappModal(false);
      setSelected(null);
      setWhatsapp(null);
    }
  }, [reportLink]);

  const buttonActions = [
    {
      name: 'Novo Orçamento (Simplificado)',
      type: 'Adicionar',
      action: () =>
      history.push(`/app/Comercial/Atendimento`, {
        state: {
          backTo: 'Orçamentos',
          especie: 8,
          natureza: 6,
        },
      }),
      visible: true,      
    },  
    {
      name: 'Novo Orçamento (Completo)',
      type: 'Adicionar',
      action: () =>
        history.push(
          `/app/Cadastros/Documento?especie=${data[0]?.especie_id}&natureza=${data[0]?.natureza_operacao_id}`
        ),
      visible: true,
    },
    {
      name: 'Gerar Documento',
      type: 'Gerar',
      action: () => {
        if (!!multiSelected.length) {
          let Documentos = multiSelected.map((i) => ({ id: i?.id }));
          dispatch(DocumentoActions.putDocGerar({ Documentos, Rotina: '' }));
        } else {
          toast.warning('Selecione ao menos um item');
        }
      },
      visible: true,
    },
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () =>
        param
          ? history.push(`/app/Manutenção?cod=${param}`)
          : history.push(`/app/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => setFilterModal(!filterModal),
      visible: (filter[filterPath]?.filtros || filtros)?.length,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: checkAgrupamento(headerData),
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Orçamento completo',
          varName: 'Documento',          
          action: (item) =>
            history.push(`/app/Cadastros/Documento?id=${item?.id}`),
          visible: true,
        },
  
        {
          name: 'Orçamento Simplificado',
          varName: 'Liquida',
          action: (item) =>
            history.push(`/app/Comercial/Atendimento/Vendas?id=${item?.id}`),
          visible: true,
        },
        {
          name: 'Copiar Orçamento',
          varName: 'Gerar',
          action: (item) => {
            setMultiSelected([item]);
            let Documentos = [{ id: item?.id }];
            dispatch(
              DocumentoActions.putDocGerar({ Documentos, Rotina: 'COPIA' })
            );
          },
          visible: true,
        },
        {
          name: 'Imprimir o orçamento',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(491);
          },
          visible: true,
        },
        {
          name: 'Disponibilidades',
          varName: 'Detalhes',
          action: (item) =>
            history.push(
              `/app/DocItem?id=${item?.id}&descricao=${item.especie} - ${
                item.natureza
              } (${item.id}) - ${item.nome} - ${moment(item.dtemissao).format(
                'DD/MM/YYYY'
              )}`
            ),
            visible: true,
        },
        {
          name: 'Status do orçamento',
          varName: 'DocStatus',
          action: (item) => {
            setSelected(item);
            setDocStatusModal(!docStatusModal);
          },
          visible: true,
        },
        {
          name: 'Anotações',
          varName: 'Aprovação',
          action: (item) =>
            history.push(
              `/app/Suprimentos/GestãodeCompras/Registros?id=${item?.id}&name=${item.id}' - '${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Email',
          varName: 'Contato',
          action: (item) => {
            dispatch(
              DocumentoActions.postStatus({
                data: { status_id: 5 },
                id: item?.documento_id,
              })
            );
            setSelected(item);
            setRelatorio(491);
            setLink(true);
          },
          visible: true,
        },
        {
          name: 'Whatsapp',
          varName: 'Contato',
          action: (item) => {
            setSelected(item);
            setWhatsappModal(true);
          },
          visible: true,
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                selected={multiSelected}
                setSelected={setMultiSelected}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filter[filterPath]?.filtros || filtros}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <DocGerarModal
                open={docGerarModal}
                setOpen={putDocGerarModal}
                selected={multiSelected}
                despecie={0}
                dnatureza={0}
              />
              <DocStatusModal
                open={docStatusModal}
                setOpen={setDocStatusModal}
                selected={selected}
              />
              <IntegraDocModal
                open={integrarDocModal}
                setOpen={setIntegrarDocModal}
                integra={authUser?.storedIntegra}
              />
              <FaturarModal
                open={faturarModal}
                setOpen={setFaturarModal}
                selected={multiSelected}
              />
              <WhatsappModal
                open={whatsappModal}
                setOpen={setWhatsappModal}
                selected={selected}
                onSubmit={(number) => {
                  setRelatorio(491);
                  setLink(true);
                  setWhatsapp(number);
                }}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default OrçamentosVenda;
