export const Types = {
    GET_PERFIL: 'GET_PERFIL',
    GET_PERFIL_SUCCESS: 'GET_PERFIL_SUCCESS',
    GET_PERFIL_ERROR: 'GET_PERFIL_ERROR',
  
    GET_AGENDA: 'GET_AGENDA',
    GET_AGENDA_SUCCESS: 'GET_AGENDA_SUCCESS',
    GET_AGENDA_ERROR: 'GET_AGENDA_ERROR',
  
    POST_TAREFA: 'POST_TAREFA',
    POST_TAREFA_SUCCESS: 'POST_TAREFA_SUCCESS',
    POST_TAREFA_ERROR: 'POST_TAREFA_ERROR',
    POST_TAREFA_RESET: 'POST_TAREFA_RESET',
  
    PUT_TAREFA: 'PUT_TAREFA',
    PUT_TAREFA_SUCCESS: 'PUT_TAREFA_SUCCESS',
    PUT_TAREFA_ERROR: 'PUT_TAREFA_ERROR',
    PUT_TAREFA_RESET: 'PUT_TAREFA_RESET',
  
    DELETE_TAREFA: 'DELETE_TAREFA',
    DELETE_TAREFA_SUCCESS: 'DELETE_TAREFA_SUCCESS',
    DELETE_TAREFA_ERROR: 'DELETE_TAREFA_ERROR',
    DELETE_TAREFA_RESET: 'DELETE_TAREFA_RESET',
  
    PUT_STATUS: 'PUT_STATUS',
    PUT_STATUS_SUCCESS: 'PUT_STATUS_SUCCESS',
    PUT_STATUS_ERROR: 'PUT_STATUS_ERROR',
    PUT_STATUS_RESET: 'PUT_STATUS_RESET',
  };
  
  const INIT_STATE = {
    perfilLoading: false,
    perfil: [],
    perfilError: false,
  
    agendaLoading: false,
    agenda: [],
    agendaError: false,
  
    postTarefaLoading: false,
    postTarefaSuccess: false,
    postTarefaError: false,
  
    putTarefaLoading: false,
    putTarefaSuccess: false,
    putTarefaError: false,
  
    deleteTarefaLoading: false,
    deleteTarefaSuccess: false,
    deleteTarefaError: false,
  
    putStatusLoading: false,
    putStatusSuccess: false,
    putStatusError: false,
  };
  
  export default function Agenda(state = INIT_STATE, action) {
    switch (action.type) {
      case Types.GET_PERFIL: {
        return {
          ...state,
          perfilLoading: true,
          perfil: [],
          perfilError: false,
        };
      }
      case Types.GET_PERFIL_SUCCESS: {
        return {
          ...state,
          perfilLoading: false,
          perfil: action.payload,
          perfilError: false,
        };
      }
      case Types.GET_PERFIL_ERROR: {
        return {
          ...state,
          perfilLoading: false,
          perfil: [],
          perfilError: true,
        };
      }
  
      case Types.GET_AGENDA: {
        return {
          ...state,
          agendaLoading: true,
          agenda: [],
          agendaError: false,
        };
      }
      case Types.GET_AGENDA_SUCCESS: {
        return {
          ...state,
          agendaLoading: false,
          agenda: action.payload,
          agendaError: false,
        };
      }
      case Types.GET_AGENDA_ERROR: {
        return {
          ...state,
          agendaLoading: false,
          agenda: [],
          agendaError: true,
        };
      }
  
      case Types.POST_TAREFA: {
        return {
          ...state,
          postTarefaLoading: true,
          postTarefaSuccess: false,
          postTarefaError: false,
        };
      }
      case Types.POST_TAREFA_SUCCESS: {
        return {
          ...state,
          postTarefaLoading: false,
          postTarefaSuccess: true,
          postTarefaError: false,
        };
      }
      case Types.POST_TAREFA_ERROR: {
        return {
          ...state,
          postTarefaLoading: false,
          postTarefaSuccess: false,
          postTarefaError: true,
        };
      }
      case Types.POST_TAREFA_RESET: {
        return {
          ...state,
          postTarefaLoading: false,
          postTarefaSuccess: false,
          postTarefaError: false,
        };
      }
  
      case Types.PUT_TAREFA: {
        return {
          ...state,
          putTarefaLoading: true,
          putTarefaSuccess: false,
          putTarefaError: false,
        };
      }
      case Types.PUT_TAREFA_SUCCESS: {
        return {
          ...state,
          putTarefaLoading: false,
          putTarefaSuccess: true,
          putTarefaError: false,
        };
      }
      case Types.PUT_TAREFA_ERROR: {
        return {
          ...state,
          putTarefaLoading: false,
          putTarefaSuccess: false,
          putTarefaError: true,
        };
      }
      case Types.PUT_TAREFA_RESET: {
        return {
          ...state,
          putTarefaLoading: false,
          putTarefaSuccess: false,
          putTarefaError: false,
        };
      }
  
      case Types.DELETE_TAREFA: {
        return {
          ...state,
          deleteTarefaLoading: true,
          deleteTarefaSuccess: false,
          deleteTarefaError: false,
        };
      }
      case Types.DELETE_TAREFA_SUCCESS: {
        return {
          ...state,
          deleteTarefaLoading: false,
          deleteTarefaSuccess: true,
          deleteTarefaError: false,
        };
      }
      case Types.DELETE_TAREFA_ERROR: {
        return {
          ...state,
          deleteTarefaLoading: false,
          deleteTarefaSuccess: false,
          deleteTarefaError: true,
        };
      }
      case Types.DELETE_TAREFA_RESET: {
        return {
          ...state,
          deleteTarefaLoading: false,
          deleteTarefaSuccess: false,
          deleteTarefaError: false,
        };
      }
      
      case Types.PUT_STATUS: {
        return {
          ...state,
          putStatusLoading: true,
          putStatusSuccess: false,
          putStatusError: false,
        };
      }
      case Types.PUT_STATUS_SUCCESS: {
        return {
          ...state,
          putStatusLoading: false,
          putStatusSuccess: true,
          putStatusError: false,
        };
      }
      case Types.PUT_STATUS_ERROR: {
        return {
          ...state,
          putStatusLoading: false,
          putStatusSuccess: false,
          putStatusError: true,
        };
      }
      case Types.PUT_STATUS_RESET: {
        return {
          ...state,
          putStatusLoading: false,
          putStatusSuccess: false,
          putStatusError: false,
        };
      }
  
      default:
        return state;
    }
  }
  
  export const Creators = {
    getPerfil: () => ({
      type: Types.GET_PERFIL,
    }),
    getPerfilSuccess: (payload) => ({
      type: Types.GET_PERFIL_SUCCESS,
      payload,
    }),
    getPerfilError: () => ({
      type: Types.GET_PERFIL_ERROR,
    }),
  
    getAgenda: (payload) => ({
      type: Types.GET_AGENDA,
      payload,
    }),
    getAgendaSuccess: (payload) => ({
      type: Types.GET_AGENDA_SUCCESS,
      payload,
    }),
    getAgendaError: () => ({
      type: Types.GET_AGENDA_ERROR,
    }),
  
    postTarefa: (payload) => ({
      type: Types.POST_TAREFA,
      payload,
    }),
    postTarefaSuccess: () => ({
      type: Types.POST_TAREFA_SUCCESS,
    }),
    postTarefaError: () => ({
      type: Types.POST_TAREFA_ERROR,
    }),
    postTarefaReset: () => ({
      type: Types.POST_TAREFA_RESET,
    }),
  
    putTarefa: (payload) => ({
      type: Types.PUT_TAREFA,
      payload,
    }),
    putTarefaSuccess: () => ({
      type: Types.PUT_TAREFA_SUCCESS,
    }),
    putTarefaError: () => ({
      type: Types.PUT_TAREFA_ERROR,
    }),
    putTarefaReset: () => ({
      type: Types.PUT_TAREFA_RESET,
    }),
  
    deleteTarefa: (payload) => ({
      type: Types.DELETE_TAREFA,
      payload,
    }),
    deleteTarefaSuccess: () => ({
      type: Types.DELETE_TAREFA_SUCCESS,
    }),
    deleteTarefaError: () => ({
      type: Types.DELETE_TAREFA_ERROR,
    }),
    deleteTarefaReset: () => ({
      type: Types.DELETE_TAREFA_RESET,
    }),
    
    putStatus: (payload) => ({
      type: Types.PUT_STATUS,
      payload,
    }),
    putStatusSuccess: () => ({
      type: Types.PUT_STATUS_SUCCESS,
    }),
    putStatusError: () => ({
      type: Types.PUT_STATUS_ERROR,
    }),
    putStatusReset: () => ({
      type: Types.PUT_STATUS_RESET,
    }),
  };
  