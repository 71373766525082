import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { transformData, setDecimal, findOnArray } from 'util/index';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  DialogContentText,
  Paper,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridItens: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
  },
  itensText: {
    margin: 0,
  },
  itemPaper: {
    display: 'flex',
    flex: 1,
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
  infoContainer: {
    margin: '0 0 20px 0',
    padding: '1rem',
    backgroundColor: '#c5c5c5',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0 5px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const divisao = [
  { value: 'NENHUMA', label: 'NENHUMA' },
  { value: 'QUANTIDADE', label: 'QUANTIDADE' },
  { value: 'VALOR', label: 'VALOR' },
];

const DocGerarmodal = ({
  open,
  setOpen,
  selected,
  despecie,
  dnatureza,
  classes,
}) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [fill, setFill] = useState(false);
  const [deletedItens, setDeletedItens] = useState([]);
  const { docgerar, capaSuccess, capaLoading } = useSelector(
    ({ documento }) => documento
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (capaSuccess) {
      dispatch(DocumentoActions.postCapaReset());
    }
  }, [capaSuccess]);

  useEffect(() => {
    if (!open) {
      setFill(false);
    } else {
      loadItems([644, 642, 621, 980, 616, 624]);
    }
  }, [open]);

  const SetValues = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (docgerar) {
        // Setar Espécie do documento a ser gerado
        let mdespecie_id = docgerar?.Documento?.doc_especie_id;
        if (
          docgerar?.Documento?.doc_despecie_id &&
          docgerar?.Documento?.doc_despecie_id != null &&
          docgerar?.Documento?.doc_despecie_id != 0
        )
          mdespecie_id = docgerar?.Documento?.doc_despecie_id;
        if (despecie && despecie != 0) mdespecie_id = despecie;

        // Setar Espécie do documento a ser gerado
        let mdnatureza_operacao_id =
          docgerar?.Documento?.doc_natureza_operacao_id;
        if (
          docgerar?.Documento?.doc_dnatureza_operacao_id &&
          docgerar?.Documento?.doc_dnatureza_operacao_id != null &&
          docgerar?.Documento?.doc_dnatureza_operacao_id != 0
        )
          mdnatureza_operacao_id =
            docgerar?.Documento?.doc_dnatureza_operacao_id;

        if (dnatureza && dnatureza != 0) mdnatureza_operacao_id = dnatureza;

        // Setar contribuinte
        let mcontribuinte_id = 1;
        if (
          docgerar?.Documento?.doc_contribuinte_id &&
          docgerar?.Documento?.doc_contribuinte_id != null &&
          docgerar?.Documento?.doc_contribuinte_id != 0
        )
          mcontribuinte_id = docgerar?.Documento?.doc_contribuinte_id;

        // Setar usofinal
        let musofinal = 'NAO';
        if (
          docgerar?.Documento?.doc_usofinal &&
          docgerar?.Documento?.doc_usofinal != null &&
          docgerar?.Documento?.doc_usofinal != 0
        )
          musofinal = docgerar?.Documento?.doc_usofinal;
        if (open && !fill && items?.length) {
          setFill(true);
          setValues({
            ...values,
            dtemissao: moment().format('DD-MM-YYYY HH:mm:ss'),
            natureza_operacao_id: findOnArray(mdnatureza_operacao_id, items[0]),
            especie_id: findOnArray(mdespecie_id, items[1]),
            filial_id: findOnArray(
              docgerar?.Documento?.doc_filial_id,
              items[2]
            ),
            cadastro_id: findOnArray(
              docgerar?.Documento?.doc_cadastro_id,
              items[3]
            ),
            contribuinte_id: findOnArray(mcontribuinte_id, items[4]),
            usofinal: findOnArray(musofinal, yn),
            celocal_id: findOnArray(docgerar?.Documento?.celocal_id, items[5]),
            divisao: findOnArray(
              docgerar?.Documento?.divisaotp || 'NENHUMA',
              divisao
            ),
            percentual: setDecimal(docgerar?.Documento?.divisaopc || 0, 2),
          });
        }
      }
    }, [docgerar]);
    return null;
  };

  function sendRegistro(values) {
    // Correndo registro selecionados
    let mobservacao = '';
    let mdadosadc = '';
    let Origem = [];
    selected.map((s) => {
      let mobs = docgerar?.Documento?.observacao || '';
      if (!mobservacao?.includes(mobs)) {
        if (mobservacao) {
          mobservacao = mobservacao + '\r\n' + mobs;
        } else {
          mobservacao = mobs;
        }
      }
      let mdad = docgerar?.Documento?.dadosadc || '';
      if (!mdadosadc?.includes(mdad)) {
        if (mdadosadc) {
          mdadosadc = mdadosadc + '\r\n' + mdad;
        } else {
          mdadosadc = mdad;
        }
      }
      const fdocto = Origem.find((d) => d?.documento_id === s?.documento_id);

      if (!fdocto) {
        Origem.push({
          documento_id: s?.documento_id,
          especie_id: docgerar?.Documento?.especie_id,
          especie: docgerar?.Documento?.doc_especie,
          natureza_operacao_id: docgerar?.Documento?.natureza_operacao_id,
          natureza: docgerar?.Documento?.doc_naturezaop,
        });
      }
    });

    let mconferido = 'NAO';
    let rotina = docgerar?.Documento?.rotina || null;

    //let mconferido = 'SIM';
    //if (docgerar?.Documento?.doc_registrar == 'SIM') mconferido = 'NAO';

    let data = {
      Documento: {
        especie_id: values?.especie_id?.value,
        natureza_operacao_id: values?.natureza_operacao_id?.value,
        filial_id: values?.filial_id?.value,
        cadastro_id: values?.cadastro_id?.value,
        dtemissao: moment(values?.dtemissao, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        contribuinte_id: values?.contribuinte_id?.value,
        usofinal: values?.usofinal?.value,
        celocal_id: values?.celocal_id?.value,
        observacao: mobservacao,
        dadosadc: mdadosadc,
        conferido: mconferido,
        gerado: 'SIM',
        rotina: rotina,
      },
    };
    if (docgerar?.Moeda) {
      data = {
        ...data,
        Moeda: {
          moeda_id: docgerar?.Moeda?.moeda_id,
          cotacao: docgerar?.Moeda?.cotacao,
        },
      };
    }
    if (docgerar?.Entrada) {
      data = {
        ...data,
        Entrada: {
          serie: docgerar?.Entrada?.serie,
          dtentrada: moment().format('YYYY-MM-DD'),
          custo: docgerar?.Entrada?.custo,
          moeda_id: docgerar?.Entrada?.moeda_id,
          cotacao: docgerar?.Entrada?.cotacao,
        },
      };
    }
    if (docgerar?.Saida) {
      data = {
        ...data,
        Saida: {
          serie_id: docgerar?.Documento?.especie_id != 25 ? docgerar?.Saida?.serie_id : null,
          condicao_pagamento_id: docgerar?.Saida?.condicao_pagamento_id,
          entidade_endereco_id: docgerar?.Saida?.entidade_endereco_id,
          vendedor_id: docgerar?.Saida?.vendedor_id,
          ped_cliente: docgerar?.Saida?.ped_cliente,
          cpf: docgerar?.Saida?.cpf,
          dtatend: docgerar?.Saida?.dtatend,
          validade: docgerar?.Saida?.validade,
          desc1: docgerar?.Saida?.desc1,
          desc2: docgerar?.Saida?.desc2,
          desc3: docgerar?.Saida?.desc3,
          desc4: docgerar?.Saida?.desc4,
          desc5: docgerar?.Saida?.desc5,
          forma_envio_id: docgerar?.Saida?.forma_envio_id,
          freteenv: docgerar?.Saida?.freteenv,
          fretecob: docgerar?.Saida?.fretecob,
          freterec: docgerar?.Saida?.freterec,
        },
      };
    }
    if (docgerar?.Contrato) {
      data = {
        ...data,
        Contrato: {
          tipo: docgerar?.Contrato?.tipo,
          reajuste: docgerar?.Contrato?.reajuste,
          dtinicont: docgerar?.Contrato?.dtinicont,
        },
      };
    }
    if (docgerar?.Exportacao) {
      data = {
        ...data,
        Exportacao: {
          ufembarque_id: docgerar?.Exportacao?.ufembarque_id,
          locembarque: docgerar?.Exportacao?.locembarque,
        },
      };
    }
    if (docgerar?.Importacao) {
      data = {
        ...data,
        Importacao: {
          numero: docgerar?.Importacao?.numero,
          data: docgerar?.Importacao?.data,
          desembaraco_loc: docgerar?.Importacao?.desembaraco_loc,
          desembaraco_uf: docgerar?.Importacao?.desembaaco_uf,
          desembaraco_dat: docgerar?.Importacao?.desmbaraco_dat,
          tpviatransp: docgerar?.Importacao?.tpviatransp,
          vafrmm: docgerar?.Importacao?.vafrmm,
          tpintermedio: docgerar?.Importacao?.tpintermedio,
          cnpjadq: docgerar?.Importacao?.cnpjadq,
        },
      };
    }

    // Itens
    let Itens = [];
    let Estrutura = [];
    let Processo = [];
    let mpesoliq = 0;
    let mpesobrt = 0;
    let mseqitm = 0;
    if (docgerar?.Itens) {
      for (let w = 0; w < docgerar?.Itens.length; w++) {
        let g = docgerar?.Itens[w];
        mseqitm = mseqitm + 1;

        // Quantidade
        let mqte = transformData(g?.quantidade, 'number');
        if (values[`q${w}`] && values[`q${w}`] != null) {
          mqte = transformData(values[`q${w}`], 'number');
        }
        if (
          values?.divisao?.value == 'QUANTIDADE' &&
          transformData(values?.percentual, 'number') != 0
        ) {
          mqte =
            (transformData(values[`q${w}`], 'number') *
              transformData(values?.percentual, 'number')) /
            100;
          if (g?.fracionada == 'NAO') mqte = Number(mqte.toFixed(0));
        }

        // Preço
        let mprc = transformData(g?.preco, 'number');

        if (values[`p${w}`] && values[`p${w}`] != null)
          mprc = transformData(values[`p${w}`], 'number');
        if (
          values?.divisao?.value == 'VALOR' &&
          transformData(values?.percentual, 'number') != 0
        )
          mprc = (mprc * transformData(values?.percentual, 'number')) / 100;

        // Desconto
        let mdsc = 0;
        if (
          values?.divisao?.value == 'QUANTIDADE' &&
          g?.desconto_vlr != null &&
          g?.desconto_vlr != 0 &&
          g?.quantidade != null &&
          g?.quantidade != 0
        )
          mdsc = (g?.desconto_vlr / g?.quantidade) * mqte;
        else {
          mdsc = Number(g?.desconto_vlr);
          if (g?.dcgerar && g?.dcgerar != null && g?.dcgerar != 0)
            mdsc = Number(g?.dcgerar);
          if (
            g?.vldescgerado &&
            g?.vldescgerado != null &&
            g?.vldescgerado != 0
          )
            mdsc = Number(g?.desconto_vlr) - Number(g?.vldescgerado);
          else {
            if (transformData(values?.percentual, 'number') != 0)
              mdsc = (mdsc * transformData(values?.percentual, 'number')) / 100;
          }
        }

        // Frete
        let mfrt = Number(g?.frete);
        if (g?.frgerar && g?.frgerar != null && g?.frgerar != 0)
          mfrt = Number(g?.frgerar);
        if (
          g?.vlfretegerado &&
          g?.vlfretegerado != null &&
          g?.vlfretegerado != 0
        )
          mfrt = Number(g?.frete) - Number(g?.vlfretegerado);
        else {
          if (transformData(values?.percentual, 'number') != 0)
            mfrt = (mfrt * transformData(values?.percentual, 'number')) / 100;
        }

        // Seguro
        let mseg = Number(g?.seguro);
        if (g?.sggerar && g?.sggerar != null && g?.sggerar != 0)
          mseg = Number(g?.sggerar);
        if (g?.vlseggerado && g?.vlseggerado != null && g?.vlseggerado != 0)
          mseg = Number(g?.seguro) - Number(g?.vlseggerado);
        else {
          if (transformData(values?.percentual, 'number') != 0)
            mseg = (mseg * transformData(values?.percentual, 'number')) / 100;
        }

        // Despesas Acessórias
        let mdac = Number(g?.despac);
        if (g?.dagerar && g?.dagerar != null && g?.dagerar != 0)
          mdac = Number(g?.dagerar);
        if (
          g?.vldespacgerado &&
          g?.vldespacgerado != null &&
          g?.vldespacgerado != 0
        )
          mdac = Number(g?.despac) - Number(g?.vldespacgerado);
        else {
          if (transformData(values?.percentual, 'number') != 0)
            mdac = (mdac * transformData(values?.percentual, 'number')) / 100;
        }

        // Custo Financeiro
        let mcfn = Number(g?.cstfin);
        if (g?.cfgerar && g?.cfgerar != null && g?.cftgerar != 0)
          mcfn = Number(g?.cfgerar);
        if (
          g?.vlcstfingerado &&
          g?.vlcstfingerado != null &&
          g?.vlcstfingerado != 0
        )
          mcfn = Number(g?.cstfin) - Number(g?.vlcstfingerado);
        else {
          if (transformData(values?.percentual, 'number') != 0)
            mcfn = (mcfn * transformData(values?.percentual, 'number')) / 100;
        }

        // Eventuais
        let meve = Number(g?.eventuais);
        if (g?.evgerar && g?.evgerar != null && g?.evgerar != 0)
          meve = Number(g?.evgerar);
        if (
          g?.vleventgerado &&
          g?.vleventgerado != null &&
          g?.vleventgerado != 0
        )
          meve = Number(g?.eventuais) - Number(g?.vleventgerado);
        else {
          if (transformData(values?.percentual, 'number') != 0)
            meve = (meve * transformData(values?.percentual, 'number')) / 100;
        }

        // Outros
        let mout = Number(g?.outros);
        if (g?.ougerar && g?.ougerar != null && g?.ougerar != 0)
          mout = Number(g?.ougerar);
        if (
          g?.vloutrosgerado &&
          g?.vloutrosgerado != null &&
          g?.vloutrosgerado != 0
        )
          mout = Number(g?.outros) - Number(g?.vloutrosgerado);
        else {
          if (transformData(values?.percentual, 'number') != 0)
            mout = (mout * transformData(values?.percentual, 'number')) / 100;
        }

        // Estrutura e processo de fabricação do item
        if (values?.especie_id?.value != 14) {
          if (g?.Estrutura) {
            Estrutura = [];
            let mseq = 0;
            for (let r = 0; r < g.Estrutura.length; r++) {
              let ett = g?.Estrutura[r];
              mseq = mseq + 1;
              Estrutura.push({
                sequencia: mseq,
                componente_id: ett?.componente_id,
                operacao: ett?.operacao,
                quantidade: ett?.quantidade,
                perda: ett?.preda,
              });
            }
          }
          if (g?.Processo) {
            Processo = [];
            for (let p = 0; p < g.Processo.length; p++) {
              let prc = g?.Processo[p];
              Processo.push({
                operacao: prc?.operacao,
                descricao: prc?.descricao,
                ccusto_id: prc?.ccusto_id,
                tpreparacao: prc?.tpreparacao,
                texecucao: prc?.texecucao,
                apontamento: prc?.apontamento,
                analisa_id: prc?.analisa_id,
                aprova_id: prc?.aprova_id,
              });
            }
          }
        }
        Itens.push({
          sequencia: mseqitm,
//        cfo_id: g?.cfo_id,
          produto_id: g?.produto_id,
          descricao: g?.descricao,
          cfiscal_id: g?.cfiscal_id,
          quantidade: mqte,
          unidade_medida_id: g?.unidade_medida_id,
          referencia: g?.referencia,
          preco: mprc,
          precobase_id: g?.precobase_id,
          comissao_id: g?.comissao_id,
          comissao_pc: g?.comissao_pc,
          desconto_vlr: mdsc,
          desconto1_pc: g?.desconto1_pc,
          desconto2_pc: g?.desconto2_pc,
          desconto3_pc: g?.desconto3_pc,
          desconto4_pc: g?.desconto4_pc,
          desconto5_pc: g?.desconto5_pc,
          frete: mfrt,
          seguro: mseg,
          despac: mdac,
          cstfin: mcfn,
          eventuais: meve,
          outros: mout,
          peso: g?.peso,
          przent: g?.przent,
          infadc: g?.infadc,
          custo_id: g?.custo_id,
          ccusto_id: g?.ccusto_id,
          aplicacao_id: g?.aplicacao_id,
          planoconta_id: g?.planoconta_id,
          frota_id: g?.frota_id,
          dhsaldo: g?.dhsaldo,
          calccom: true,
          dhsaldo: g?.dhsaldo,
          rotina: g?.rotina,

          Origem:
            g?.Origem &&
            g?.Origem[0]?.documento_item_org_id &&
            docgerar?.Documento?.especie_id !== values?.especie_id?.value
              ? [
                  {
                    documento_item_org_id: g?.Origem[0]?.documento_item_org_id,
                  },
                ]
              : [],

          Vinculo:
            g?.Vinculo &&
            g?.Vinculo[0]?.documento_item_id_vinculo &&
            docgerar?.Documento?.especie_id !== values?.especie_id?.value
              ? [
                  {
                    documento_item_id_vinculo:
                      g?.Vinculo[0]?.documento_item_id_vinculo,
                  },
                ]
              : [],

          Estrutura,
          Processo,
        });
        mpesoliq = mpesoliq + g?.peso * mqte;
        mpesobrt = mpesobrt + g?.peso * mqte;
      }
    }
    if (!!Itens.length) {
      let newItens = [];
      Itens.map((m, i) => {
        if (deletedItens.some((f) => i === f)) {
        } else {
          newItens.push(m);
        }
      });
      data = { ...data, Itens: newItens };
    }

    // Financeiro
    let Financeiro = [];
    if (docgerar?.Financeiro) {
      for (let f = 0; f < docgerar?.Financeiro.length; f++) {
        let df = docgerar?.Financeiro[f];
        Financeiro.push({
          forma_pagto_id: df?.forma_pagto_id,
          parcela: df?.parcela,
          dtvenc: df?.dtvenc,
          valor: df?.valor,
          observacao_id: df?.observacao_id,
        });
      }
    }
    if (!!Financeiro.length) data = { ...data, Financeiro };

    // Transporte
    if (
      docgerar?.Documento?.especie_id == 26 &&
      values?.especie_id?.value == 10
    ) {
      data = {
        ...data,
        Transporte: [
          {
            tipotransp: 'DESPACHO',
            fretepc: '9 - SEM OCORRENCIA DE TRANSPORTE',
            natureza: '0 - OP.VENDAS COM ONUS SUPORTADO PELO ESTAB.VENDEDOR',
            pesoliq: mpesoliq,
            pesobrt: mpesobrt,
          },
        ],
      };
    } else {
      if (docgerar?.Transporte) {
        data = {
          ...data,
          Transporte: docgerar?.Transporte?.map((t) => ({
            entidade_id: t?.entidade_id,
            tipotransp: t?.tipotransp,
            viatransp: t?.viatransp,
            fretepc: t?.fretepc,
            natureza: t?.natureza,
            locentrega: t?.locentrega,
            volume: t?.volume,
            tara: t?.tara,
            pesoliq: mpesoliq,
            pesobrt: mpesobrt,
            quantidade: t?.quantidade,
            especie: t?.especie,
            marca: t?.marca,
            numero: t?.numero,
            frota_id: t?.frota_id,
            motorista_id: t?.motorista_id,
          })),
        };
      }
    }

    // Registros
    let Registros = [];
    if (docgerar?.Registros) {
      const finder = docgerar?.Registros.find((r) => {
        const mhst = r?.historico || '';
        return mhst?.includes('GERADO POR ANALISE DE NECESSIDADE');
      });
      if (finder) {
        Registros.push({
          status_id: docgerar?.Registros?.status_id,
          datahora: docgerar?.Registros?.datahora,
          historico: docgerar?.Registros?.historico,
        });
      }
    }

    if (docgerar?.Documento?.especie_id == values?.especie_id?.value) {
      Registros.push({
        status_id: 1,
        datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
        historico: 'COPIA DO DOCUMENTO Id. ' + selected[0]?.documento_id,
      });
    }

    if (!!Registros.length) data = { ...data, Registros };

    if (!!Origem.length) data = { ...data, Origem };

    dispatch(DocumentoActions.postCapa(data));
    setOpen(!open);
  }

  const setInitialValues = () => {
    const initialValues = {
      dtemissao: null,
      natureza_operacao_id: null,
      especie_id: null,
      filial_id: null,
      cadastro_id: null,
      contribuinte_id: null,
      usofinal: null,
      divisao: null,
      celocal_id: null,
    };
    if (docgerar?.Itens) {
      docgerar.Itens.map((i, index) => {
        initialValues[`q${index}`] =
          docgerar?.Documento?.rotina === 'COPIA' ||
          docgerar?.Documento?.rotina === 'FATURARCUPOM'
            ? setDecimal(i.quantidade, 10)
            : i.qtgerar
            ? setDecimal(i.qtgerar, 10)
            : setDecimal('0', 10);
        initialValues[`p${index}`] =
          docgerar?.Documento?.rotina === 'COPIA' ||
          docgerar?.Documento?.rotina === 'FATURARCUPOM'
            ? setDecimal(i.preco, 2)
            : i.prgerar
            ? setDecimal(i.prgerar, 2)
            : setDecimal('0', 2);
      });
    }
    return initialValues;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExited={() => {
        dispatch(DocumentoActions.putDocGerarResset());
        setDeletedItens([]);
      }}
    >
      <Formik initialValues={setInitialValues()}>
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetValues />
            <DialogTitle>Dados para geração do documento</DialogTitle>
            <DialogContent>
              <Paper className={classes.infoContainer}>
                {docgerar?.Documento?.doc_especie && (
                  <div className={classes.textContainer}>
                    <DialogContentText color="primary">
                      {docgerar?.Documento?.doc_especie}
                      {'   ('}
                      {docgerar?.Documento?.id ||
                        docgerar?.Documento?.documento_id}
                      {')'}
                    </DialogContentText>
                    <DialogContentText color="primary">
                      Natureza: {docgerar?.Documento?.doc_naturezaop}
                    </DialogContentText>
                    <DialogContentText color="primary">
                      Emissao:{' '}
                      {moment(docgerar?.Documento?.dtemissao).format(
                        'DD/MM/YYYY'
                      )}
                    </DialogContentText>
                    <DialogContentText color="primary">
                      Número: {docgerar?.Documento?.documento}
                    </DialogContentText>
                  </div>
                )}
                {docgerar?.Documento?.dtemissao && (
                  <div className={classes.textContainer}></div>
                )}
                {docgerar?.Documento?.doc_nome && (
                  <DialogContentText color="primary">
                    Entidade: {docgerar?.Documento?.doc_nome}
                  </DialogContentText>
                )}
                {docgerar?.Documento?.doc_cidade && (
                  <DialogContentText color="primary">
                    Cidade: {docgerar?.Documento?.doc_cidade}
                    {' / '}
                    {docgerar?.Documento?.doc_uf}
                  </DialogContentText>
                )}
              </Paper>
              <Grid container>
                <Grid xs="12" sm="5" md="5" lg="5" className={classes.grid}>
                  <SelectAuto
                    label="Natureza da Operação"
                    items={items[0]}
                    value={values.natureza_operacao_id}
                    onChange={(v) => setFieldValue('natureza_operacao_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="5" md="5" lg="5" className={classes.grid}>
                  <SelectAuto
                    label="Espécie do documento"
                    items={items[1]}
                    value={values.especie_id}
                    onChange={(v) => setFieldValue('especie_id', v)}
                  />
                </Grid>
                <Grid xs="2" sm="2" md="2" lg="2" className={classes.grid}>
                  <InputMask
                    mask="99/99/9999 99:99:99"
                    name="dtemissao"
                    label="Data de Emissão"
                    value={values.dtemissao || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Filial"
                    items={items[2]}
                    value={values.filial_id}
                    onChange={(v) => setFieldValue('filial_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade"
                    items={items[3]}
                    value={values.cadastro_id}
                    onChange={(v) => {
                      if (v) {
                        setFieldValue('cadastro_id', v);
                        setFieldValue('divisao', v.divisaotp);
                        setFieldValue('percentual', v.divisaopc);
                      }
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Contribuinte"
                    items={items[4]}
                    value={values.contribuinte_id}
                    onChange={(v) => setFieldValue('contribuinte_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                  <SelectAuto
                    label="Uso Final"
                    items={yn}
                    value={values.usofinal}
                    onChange={(v) => setFieldValue('usofinal', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Local de Estoque"
                    items={items[5]}
                    value={values.celocal_id}
                    onChange={(v) => setFieldValue('celocal_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Divisão do documento por"
                    items={divisao}
                    value={values.divisao}
                    onChange={(v) => setFieldValue('divisao', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="percentual"
                    label="% Divisão"
                    value={values.percentual}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('percentual', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <DialogTitle>Itens</DialogTitle>
              {docgerar?.Itens &&
                docgerar?.Itens.map((i, index) => {
                  const bRot =
                    docgerar?.Documento?.rotina === 'COPIA' ||
                    docgerar?.Documento?.rotina === 'FATURARCUPOM';
                  const bQtd = i?.quantidade && i?.quantidade > 0;
                  const bQtg =
                    (i?.qtgerar && i?.qtgerar > 0) ||
                    (i?.prgerar && i?.prgerar > 0);
                  const cond = bRot ? bQtd : bQtg;

                  if (cond && !deletedItens.some((f) => index === f))
                    return (
                      <Paper className={classes.itemPaper} elevation={5}>
                        <Grid
                          xs="6"
                          sm="6"
                          md="6"
                          lg="6"
                          className={classes.gridItens}
                        >
                          <h3 className={classes.itensText}>{i?.descricao}</h3>
                        </Grid>
                        <Grid
                          xs="2"
                          sm="2"
                          md="2"
                          lg="2"
                          className={classes.gridItens}
                        >
                          <TextField
                            name={`q${index}`}
                            label="Quantidade"
                            value={values[`q${index}`]}
                            onChange={handleChange}
                            onBlur={(e) => {
                              let qtdMax =
                                docgerar?.Documento?.rotina === 'COPIA' ||
                                docgerar?.Documento?.rotina === 'FATURARCUPOM'
                                  ? docgerar?.Itens[index]?.quantidade
                                  : docgerar?.Itens[index]?.qtgerar || 0;
                              if (
                                transformData(e.target.value, 'number') > qtdMax
                              ) {
                                setFieldValue(
                                  `q${index}`,
                                  setDecimal(qtdMax, 10)
                                );
                              } else if (e.target.value === '') {
                                setFieldValue(`q${index}`, setDecimal('0', 10));
                              } else {
                                setFieldValue(
                                  `q${index}`,
                                  setDecimal(e.target.value, 10)
                                );
                              }
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          xs="2"
                          sm="2"
                          md="2"
                          lg="2"
                          className={classes.gridItens}
                        >
                          <TextField
                            name={`p${index}`}
                            label="Preço"
                            value={values[`p${index}`]}
                            onChange={handleChange}
                            onBlur={(e) => {
                              let max =
                                docgerar?.Itens[index]?.prgerar ||
                                docgerar?.Itens[index]?.preco;
                              if (
                                transformData(e.target.value, 'number') > max
                              ) {
                                setFieldValue(`p${index}`, setDecimal(max, 2));
                              } else if (e.target.value === '') {
                                setFieldValue(`p${index}`, setDecimal('0', 2));
                              } else {
                                setFieldValue(
                                  `p${index}`,
                                  setDecimal(e.target.value, 2)
                                );
                              }
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          xs="2"
                          sm="2"
                          md="2"
                          lg="2"
                          className={classes.gridItens}
                          style={{ justifyContent: 'flex-end' }}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={() =>
                              setDeletedItens([...deletedItens, index])
                            }
                          >
                            EXCLUIR
                          </Button>
                        </Grid>
                      </Paper>
                    );
                })}
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row align-items-end">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => sendRegistro(values)}
                  disabled={capaLoading}
                >
                  CONFIRMAR
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setOpen(!open)}
                >
                  CANCELAR
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocGerarmodal);
