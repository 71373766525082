import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import {
  Creators as TablesActions,
  Types as TablesTypes,
} from 'store/ducks/Tables';
import { Creators as FiltrosStoreActions } from 'store/ducks/FiltrosStore';
import api from '../../util/api';

function* getHeader({ payload }) {
  const {
    filtrosStore: { lastCalled, stored },
  } = yield select();

  try {
    const {
      data: { definicao, filtro, paginacao },
      status,
    } = yield call(api.get, `/user-def-rot/${payload}`);
    if (status === 200 || status === 201) {
      if (lastCalled !== payload && !stored[payload]) {
        yield put(
          FiltrosStoreActions.setFiltrosStore({
            filtro: { [payload]: filtro },
            lastCalled: payload,
          })
        );
      }
      yield put(
        TablesActions.getHeaderSuccess({
          headerData: definicao,
          filtros: stored[payload] || filtro,
          paginacao,
          renderRotina: payload,
        })
      );
    } else {
      yield put(TablesActions.getHeaderError());
    }
  } catch (error) {
    yield put(TablesActions.getHeaderError());
  }
}

function* getDataTable({ payload }) {
  const { csql, params } = payload;
  try {
    const { data, status } = yield call(api.post, `/csql/${csql}`, params);
    if (status === 200 || status === 201) {
      yield put(TablesActions.getDataTableSuccess(data.dados || []));
    } else {
      yield put(TablesActions.getDataTableError());
    }
  } catch (error) {
    yield put(TablesActions.getDataTableError());
  }
}

function* setVisibleHeader({ payload }) {
  try {
    const { status } = yield call(api.put, `/user-rot-obj`, payload);
    if (status === 200 || status === 201) {
      yield put(TablesActions.setVisibleHeaderSuccess());
    } else {
      yield put(TablesActions.setVisibleHeaderError());
    }
  } catch (error) {
    yield put(TablesActions.setVisibleHeaderError());
  }
}

function* deleteData({ payload }) {
  const { table, id } = payload;
  try {
    const { status } = yield call(api.delete, `/manutencao/${table}/${id}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(TablesActions.deleteDataSuccess());
    } else {
      yield put(TablesActions.deleteDataError());
    }
  } catch (error) {
    yield put(TablesActions.deleteDataError());
  }
}

function* exportPdf({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/exportar/PDF`, payload, {
      responseType: 'blob',
    });
    if (status === 200 || status === 201) {
      yield put(TablesActions.exportPdfSuccess(data));
    } else {
      yield put(TablesActions.exportPdfError());
    }
  } catch (error) {
    yield put(TablesActions.exportPdfError());
  }
}

function* exportXls({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/exportar/XLSX`, payload, {
      responseType: 'blob',
    });
    if (status === 200 || status === 201) {
      yield put(TablesActions.exportXlsSuccess(data));
    } else {
      yield put(TablesActions.exportXlsError());
    }
  } catch (error) {
    yield put(TablesActions.exportXlsError());
  }
}

function* saveData({ payload }) {
  const { table, campos } = payload;
  try {
    const { status } = yield call(api.post, `/manutencao/${table}`, {
      campos,
    });
    if (status === 200 || status === 201) {
      yield put(TablesActions.saveDataSuccess());
    } else {
      yield put(TablesActions.saveDataError());
    }
  } catch (error) {
    yield put(TablesActions.saveDataError());
  }
}

function* editData({ payload }) {
  const { table, campos, id } = payload;
  try {
    const { status } = yield call(api.put, `/manutencao/${table}/${id}`, {
      campos,
    });
    if (status === 200 || status === 201) {
      yield put(TablesActions.editDataSuccess());
    } else {
      yield put(TablesActions.editDataError());
    }
  } catch (error) {
    yield put(TablesActions.editDataError());
  }
}

function* getReport({ payload }) {
  try {
    const {
      data: { definicao, filtro },
      status,
    } = yield call(api.get, `/user-def-rel/${payload}`);
    if (status === 200 || status === 201) {
      yield put(TablesActions.getReportSuccess({ definicao, filtro }));
    } else {
      yield put(TablesActions.getReportReset());
    }
  } catch (error) {
    yield put(TablesActions.getReportReset());
  }
}

function* postReport({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/relatorio-padrao`, payload);
    if (status === 200 || status === 201) {
      yield put(
        TablesActions.postReportSuccess({
          url: data?.url || null,
          link: data?.link || null,
          id: data?.id || null,
        })
      );
    } else {
      yield put(TablesActions.postReportError());
    }
  } catch (error) {
    yield put(TablesActions.postReportError());
  }
}

function* getTotais({ payload }) {
  const { csql, params } = payload;
  try {
    const { data, status } = yield call(api.post, `/csqltot/${csql}`, params);
    if (status === 200 || status === 201) {
      yield put(TablesActions.getTotaisSuccess(data.dados || []));
    } else {
      yield put(TablesActions.getTotaisError());
    }
  } catch (error) {
    yield put(TablesActions.getTotaisError());
  }
}

function* getResults({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/supdec-reseconomico`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(TablesActions.getResultsSuccess(data?.re || []));
    } else {
      yield put(TablesActions.getResultsError());
    }
  } catch (error) {
    yield put(TablesActions.getResultsError());
  }
}

function* postBoleto({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      `/relatorio-boleto`,
      payload,
      {
        responseType: 'blob',
      }
    );
    if (status === 200 || status === 201) {
      yield put(TablesActions.postBoletoSuccess(data));
    } else {
      yield put(TablesActions.postBoletoError());
    }
  } catch (error) {
    yield put(TablesActions.postBoletoError());
  }
}

function* postCsql({ payload }) {
  try {
    const { status } = yield call(api.post, `/gravacsql`, payload);
    if (status === 200 || status === 201) {
      yield put(TablesActions.postCsqlSuccess());
    } else {
      yield put(TablesActions.postCsqlReset());
    }
  } catch (error) {
    yield put(TablesActions.postCsqlReset());
  }
}

function* postCustomRotina({ payload }) {
  const { data, type } = payload;
  const endpoint = type === 'SQL' ? '/customizar-sql' : '/customizar-rotina';
  try {
    const { status } = yield call(api.post, endpoint, data);
    if (status === 200 || status === 201) {
      yield put(TablesActions.postCustomRotinaSuccess());
    } else {
      yield put(TablesActions.postCustomRotinaReset());
    }
  } catch (error) {
    yield put(TablesActions.postCustomRotinaReset());
  }
}

function* getHeaderWatcher() {
  yield takeLatest(TablesTypes.GET_HEADER, getHeader);
}

function* getDataTableWatcher() {
  yield takeLatest(TablesTypes.GET_DATA_TABLE, getDataTable);
}

function* setVisibleHeaderWatcher() {
  yield takeLatest(TablesTypes.SET_VISIBLE_HEADER, setVisibleHeader);
}

function* deleteDataWatcher() {
  yield takeLatest(TablesTypes.DELETE_DATA, deleteData);
}

function* exportPdfWatcher() {
  yield takeLatest(TablesTypes.EXPORT_PDF, exportPdf);
}

function* exportXlsWatcher() {
  yield takeLatest(TablesTypes.EXPORT_XLS, exportXls);
}

function* saveDataWatcher() {
  yield takeLatest(TablesTypes.SAVE_DATA, saveData);
}

function* editDataWatcher() {
  yield takeLatest(TablesTypes.EDIT_DATA, editData);
}

function* getReportWatcher() {
  yield takeLatest(TablesTypes.GET_REPORT, getReport);
}

function* postReportWatcher() {
  yield takeLatest(TablesTypes.POST_REPORT, postReport);
}

function* getTotaisWatcher() {
  yield takeLatest(TablesTypes.GET_TOTAIS, getTotais);
}

function* getResultsWatcher() {
  yield takeLatest(TablesTypes.GET_RESULTS, getResults);
}

function* postBoletoWatcher() {
  yield takeLatest(TablesTypes.POST_BOLETO, postBoleto);
}

function* postCsqlWatcher() {
  yield takeLatest(TablesTypes.POST_CSQL, postCsql);
}

function* postCustomRotinaWatcher() {
  yield takeLatest(TablesTypes.POST_CUSTOM_ROTINA, postCustomRotina);
}

export default function* rootSaga() {
  yield all([
    fork(getHeaderWatcher),
    fork(getDataTableWatcher),
    fork(setVisibleHeaderWatcher),
    fork(deleteDataWatcher),
    fork(exportPdfWatcher),
    fork(exportXlsWatcher),
    fork(saveDataWatcher),
    fork(editDataWatcher),
    fork(getReportWatcher),
    fork(postReportWatcher),
    fork(getTotaisWatcher),
    fork(getResultsWatcher),
    fork(postBoletoWatcher),
    fork(postCsqlWatcher),
    fork(postCustomRotinaWatcher),
  ]);
}
