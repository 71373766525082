import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import FilterReportModal from 'components/Modals/FilterReportModal';
import DocStatusModal from 'components/Modals/DocStatusModal';
import DocGerarModal from 'components/Modals/DocGerarModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import {
  checkManutencao,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const GestãodeCompras = ({ match }) => {
  const rotina = 498;
  const csql = 384;
  const [relatorio, setRelatorio] = useState(null);
  const title = 'Gestão de Compras';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState('');
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [docStatusModal, setDocStatusModal] = useState(false);
  const [docGerarModal, putDocGerarModal] = useState(false);
  const [multiSelected, setMultiSelected] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const {
    aprovSuccess,
    statusSuccess,
    docgerar,
    capaSuccess,
    idRegistred,
  } = useSelector(({ documento }) => documento);

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = filtros.map((f) => {
        if (f.parametro === 'SIM') {
          return { ...f, conteudoi: param, conteudod: nameParam };
        }
        return f;
      });
      return filterWithParam;
    }
    return filtros;
  }

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: {
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
            OrderClause,
            Pagina: paginacao ? Pagina : undefined,
            PorPagina: paginacao ? PorPagina : undefined,
          },
        })
      );
    }
  }

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    if ((relatorio === 496 || relatorio === 824) && reportFiltros.length) {
      dispatch(
        TablesActions.postReport({
          rotina_id: relatorio,
          WhereClause: getWhereClause({
            filtros: [
              { ...reportFiltros[0], conteudoi: selected?.documento_id },
            ],
          }),
        })
      );
      setRelatorio(null);
    }
  }, [reportFiltros]);

  useEffect(() => {
    if (docgerar) {
      putDocGerarModal(!docGerarModal);
    }
  }, [docgerar]);

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
    if (aprovSuccess) {
      dispatch(DocumentoActions.postAprovacaoReset());
    }
    if (statusSuccess) {
      dispatch(DocumentoActions.postStatusReset());
    }
    if (capaSuccess) {
      history.push(`/app/Cadastros/Documento?id=${idRegistred}`);
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
    }
  }, [aprovSuccess, statusSuccess, capaSuccess]);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length && param) {
      dispatch(TablesActions.setFiltros(setParamOnFilter()));
    } else {
      if (headerData.length) {
        getDataTable();
      }
    }
  }, [headerData, Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () =>
        param
          ? history.push(`/app/Manutenção?cod=${param}`)
          : history.push(`/app/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => setFilterModal('tela'),
      visible: filtros?.length,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Novo Documento',
      type: 'Gerar',
      action: () =>
        history.push(
          `/app/Cadastros/Documento?especie=${data[0]?.especie_id}&natureza=${data[0]?.natureza_operacao_id}`
        ),
      visible: true,
    },
    {
      name: 'Gerar Documento',
      type: 'Gerar',
      action: () => {
        let Itens = [];
        multiSelected.map((s) => {
          Itens.push({ id: s?.documento_item_id });
        });
        dispatch(DocumentoActions.putDocGerar({ Itens }));
      },
      visible: !!multiSelected.length,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Documento',
          action: (item) =>
            history.push(`/app/Cadastros/Documento?id=${item?.documento_id}`),
          visible: true,
        },
        {
          name: 'Registros',
          action: (item) =>
            history.push(
              `/app/Suprimentos/GestãodeCompras/Registros?id=${item?.documento_id}&name=${item.documento_id}' - '${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Aprovação',
          varName: 'Aprovação',
          action: (item) =>
            history.push(
              `/app/Aprovação?id=${item?.documento_id}&name${item.especie}' - Id: '${item.documento_id}`
            ),
          funcVis: (item) => item?.temaprovacao === 'SIM',
        },
        {
          name: 'Status',
          varName: 'DocStatus',
          action: (item) => {
            setSelected(item);
            setDocStatusModal(!docStatusModal);
          },
          visible: true,
        },
        {
          name: 'NFs Vinculadas',
          varName: 'Documento',
          action: (item) => {
            history.push(
              `/app/Suprimentos/GestãodeCompras/NFsVinculadas?id=${
                item?.documento_item_id
              }&descricao=${item?.descricao +
                ', Quantidade: ' +
                item?.quantidade}&filial=${item?.filial_id}&cadastro=${
                item?.cadastro_id
              }&produto=${item?.produto_id}`
            );
          },
          visible: true,
        },
        {
          name: 'Mapa de concorrência',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(496);
          },
          visible: true,
        },
        {
          name: 'Documento',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(824);
          },
          visible: true,
        },
        {
          name: 'Copiar Documento',
          varName: 'Gerar',
          action: (item) => {
            setMultiSelected([item]);
            let Documentos = [{ id: item?.documento_id }];
            dispatch(
              DocumentoActions.putDocGerar({ Documentos, Rotina: 'COPIA' })
            );
          },
          visible: true,
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                selected={multiSelected}
                setSelected={setMultiSelected}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filtros}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <DocStatusModal
                open={docStatusModal}
                setOpen={setDocStatusModal}
                selected={selected}
              />
              <DocGerarModal
                open={docGerarModal}
                setOpen={putDocGerarModal}
                selected={multiSelected}
                despecie={0}
                dnatureza={0}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default GestãodeCompras;
