import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const iconStyle = { borderWidth: 1, position: 'absolute', right: 5, top: 8 };
const input = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.main,
  },
}));

const SearchBox = ({ styleName, placeholder, onChange, value, onSubmit }) => {
  return (
    <div className={`search-bar left-side-icon bg-transparent ${styleName}`}>
      <div className="form-group">
        <input
          className={`form-control ${input().root}`}
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onKeyPress={(evt) => {
            if (evt.key === 'Enter') onSubmit();
          }}
        />
        <SearchIcon
          fontSize="small"
          style={iconStyle}
          color="primary"
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: '',
  value: '',
};
