import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { transformData, setDecimal, findOnArray } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '50%',
  },
  button: {
    margin: '0 5px',
  },
});

const cont = [
  { value: 4, label: 'SIM' },
  { value: 0, label: 'NÃO' },
];

const amb = [
  { value: 1, label: 'PRODUÇÃO' },
  { value: 2, label: 'HOMOLOGAÇÃO' },
];

const DocEmissaoModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [fill, setFill] = useState(false);
  const { postEmissaoLoading } = useSelector(({ documento }) => documento);

  const conditional =
    selected?.modelo?.toLowerCase() === 'nfe' ||
    selected?.modelo?.toLowerCase() === 'nfse' ||
    selected?.modelo?.toLowerCase() === 'cte' ||
    selected?.modelo?.toLowerCase() === 'cfe' ||
    selected?.modelo?.toLowerCase() === 'mdfe';

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([652]);
    }
  }, [open]);

  const onSubmit = (values) => {
    const data = {
      documento_id: selected.documento_id,
      documento: values?.documento,
      serie_id: values?.serie_id?.value,
      tpcont: conditional ? values?.tpcont?.value : undefined,
      tpamb: conditional ? values?.tpamb?.value : undefined,
      dhemissao: moment(values?.dhemissao, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.dhemissao, 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : null,
      dhsaida: moment(values?.dhsaida, 'DD/MM/YYYY HH:mm:ss').isValid()
        ? moment(values?.dhsaida, 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : null,
      justificativa:
        conditional && values?.tpcont?.value === 4
          ? values?.justificativa
          : undefined,
    };
    dispatch(DocumentoActions.postEmissao(data));
    setOpen(!open);
  };

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (open && !!items.length && !fill) {
        setFill(true);
        setValues({
          ...values,
          dhemissao: moment(selected?.data, 'YYYY-MM-DD HH:mm:ss').isValid()
            ? moment(selected?.data, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )
            : null,
          documento: selected?.numero,
          serie_id: findOnArray(selected?.serie_id, items[0]),
        });
      }
    }, [open, items]);
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExited={() => setFill(false)}
    >
      <Formik
        initialValues={{
          documento: null,
          serie_id: null,
          dhemissao: null,
          dhsaida: moment().format('DD/MM/YYYY HH:mm:ss'),
          tpcont: { value: 0, label: 'NÃO' },
          tpamb: { value: 1, label: 'PRODUÇÃO' },
          justificativa: null,
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <>
            <SetFields />
            <DialogTitle>Emissão do documento</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="documento"
                    label="Documento"
                    value={values.documento || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Série"
                    items={items[0] || []}
                    value={values.serie_id}
                    onChange={(v) => setFieldValue('serie_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                    mask="99/99/9999 99:99:99"
                    name="dhemissao"
                    label="Data de Emissão"
                    value={values.dhemissao || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                    mask="99/99/9999 99:99:99"
                    name="dhsaida"
                    label="Data / Hora - Saída"
                    value={values.dhsaida}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                {conditional && (
                  <>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Contingência"
                        items={cont}
                        value={values.tpcont}
                        onChange={(v) => setFieldValue('tpcont', v)}
                      />
                    </Grid>
                    {values?.tpcont?.value === 4 && (
                      <Grid
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        className={classes.grid}
                      >
                        <TextField
                          name="justificativa"
                          label="Justificativa da Contingência"
                          value={values.justificativa || ''}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Ambiente"
                        items={amb}
                        value={values.tpamb}
                        onChange={(v) => setFieldValue('tpamb', v)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row align-items-center justify-content-between w-100">
                <div>
                  {values?.serie_id?.documento &&
                    moment(values?.serie_id?.dtsaida).isValid() && (
                      <span>
                        Último documento emitido: {values?.serie_id?.documento}{' '}
                        em{' '}
                        {moment(values?.serie_id?.dtsaida).format(
                          'DD/MM/YYYY [às] HH:mm'
                        )}
                      </span>
                    )}
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => onSubmit(values)}
                    disabled={postEmissaoLoading}
                  >
                    CONFIRMAR
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={() => setOpen(!open)}
                  >
                    CANCELAR
                  </Button>
                </div>
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocEmissaoModal);
