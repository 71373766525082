import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Creators as AuthActions } from '../../store/ducks/Auth';
import { Creators as SettingActions } from '../../store/ducks/Setting';
import { Creators as FiltrosStoreActions } from '../../store/ducks/FiltrosStore';

const UserInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div
      className="user-profile d-flex flex-row align-items-center"
      style={{ maxHeight: 70, height: 75 }}
    >
      <div className="user-detail">
        <h4 className="user-name d-flex mb-0" onClick={handleClick}>
          <span className="text-truncate" style={{ whiteSpace: 'pre-wrap' }}>
            {authUser?.storedEmpresa}
          </span>{' '}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle align-self-center" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleRequestClose();
            dispatch(SettingActions.toggleCollapsedNav());
            dispatch(FiltrosStoreActions.resetFiltrosStore());
            history.push('/app/Perfil');
          }}
        >
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          Alterar Perfil
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRequestClose();
            dispatch(AuthActions.cleanAuthUser());
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
          Sair
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
