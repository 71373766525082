import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import EntregaModal from 'components/Modals/EntregaModal';
import api from 'util/api';
import { findOnArray, setDecimal, useQuery } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { isDocumento } from './mock';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  subtitle: {
    padding: '0 0.5rem',
  },
  modal: {
    maxWidth: '80%',
  },
  acc: {
    cursor: 'default',
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const Entrega = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [title, setTitle] = useState('');
  const param = query.get('id');
  const steps = ['Documento', 'Itens', 'Pagamento', 'Entrega'];
  const {
    documento,
    loading,
    postEntregaSuccess,
    putEntregaSuccess,
    deleteEntregaSuccess,
  } = useSelector(({ documento }) => documento);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([642, 621, 633, 616, 730, 725, 726, 727, 728, 710, 729, 632]);
    if (param) {
      dispatch(DocumentoActions.getDocumento(param));
    }
  }, []);

  useEffect(() => {
    if (postEntregaSuccess) {
      dispatch(DocumentoActions.postEntregaReset());
      dispatch(DocumentoActions.getDocumento(param));
      setOpenModal(false);
    }
    if (putEntregaSuccess) {
      dispatch(DocumentoActions.putEntregaReset());
      dispatch(DocumentoActions.getDocumento(param));
      setOpenModal(false);
    }
    if (deleteEntregaSuccess) {
      dispatch(DocumentoActions.deleteEntregaReset());
      dispatch(DocumentoActions.getDocumento(param));
      setOpenModal(false);
    }
  }, [postEntregaSuccess, putEntregaSuccess, deleteEntregaSuccess]);

  const searchDespacho = (v) => {
    if (documento?.Transporte) {
      const a = documento?.Transporte.find((i) => i?.tipotransp === v);
      if (a) {
        return a;
      }
      return undefined;
    }
    return undefined;
  };

  if (localLoading || loading || !documento) {
    return <Loading />;
  }

  return (
    <div className="app-wrapper">
      <HeaderPage title="Entrega do Documento" history={param && history} />
      <Card className="shadow">
        <CardContent>
          <Grid container>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Espécie:</span>
              <span> {documento?.Documento?.doc_especie}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Natureza da Operação:</span>
              <span>{documento?.Documento?.doc_naturezaop}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Documento:</span>
              <span> {documento?.Documento?.id}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Entidade:</span>
              <span> {documento?.Documento?.doc_nome}</span>
            </Grid>
            <Grid xs="12" sm="6" md="4" lg="4">
              <span className="font-weight-bold">Status:</span>
              <span> {documento?.Documento?.doc_status}</span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className="shadow" style={{ marginTop: '1rem' }}>
        <CardContent>
          <Stepper activeStep={3} alternativeLabel className="p-0">
            {steps.map((label) => {
              return (
                <Step
                  key={label}
                  onClick={() => {
                    if (param) {
                      history.push(
                        `/app/Cadastros/Documento${
                          label !== 'Documento' ? `/${label}` : ''
                        }?id=${param}`
                      );
                    }
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </CardContent>
      </Card>
      <Card className="shadow" style={{ marginTop: '1rem' }}>
        <CardContent>
          <div className="my-4">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <Button
                variant="outlined"
                color="primary"
                style={{ flex: 1, minHeight: '100px' }}
                className="mr-1"
                onClick={() => {
                  setOpenModal(!openModal);
                  setTitle('Despacho');
                  setSelected(searchDespacho('DESPACHO'));
                }}
                disabled={documento?.Documento?.sleitura === 'SIM'}
              >
                {searchDespacho('DESPACHO')
                  ? 'Editar Despacho'
                  : 'Adicionar Despacho'}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{ flex: 1, minHeight: '100px' }}
                className="ml-1"
                onClick={() => {
                  setOpenModal(!openModal);
                  setTitle('Redespacho');
                  setSelected(searchDespacho('REDESPACHO'));
                }}
                disabled={documento?.Documento?.sleitura === 'SIM'}
              >
                {searchDespacho('REDESPACHO')
                  ? 'Editar Redespacho'
                  : 'Adicionar Redespacho'}
              </Button>
            </div>
            <EntregaModal
              open={openModal}
              setOpen={setOpenModal}
              selected={selected}
              setSelected={setSelected}
              items={items}
              documento={documento}
              title={title}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Entrega);
