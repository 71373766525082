import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import URLSearchParams from 'url-search-params';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideNavOption from './SideNavOption';
import { Creators as SettingActions } from '../../store/ducks/Setting';

const ColorOption = (props) => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, isDirectionRTL } = useSelector(
    ({ settings }) => settings
  );

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (params.has('theme-name')) {
      document.body.classList.add(params.get('theme-name'));
    } else {
      document.body.classList.add(themeColor);
    }
  }, [props.location.search, themeColor]);

  const toggleCustomizer = () => {
    props.setDrawerStatus(!props.drawerStatus);
  };
  const closeCustomizer = () => {
    props.setDrawerStatus(false);
  };
  const handleThemeColor = (colorCode) => {
    dispatch(SettingActions.setThemeColor(colorCode));
    const body = document.body.classList;
    body.remove(themeColor);
    body.remove('dark-theme');
    body.add(colorCode);
  };

  const handleDarkTheme = () => {
    dispatch(SettingActions.setDarkTheme());
    const body = document.body.classList;
    body.toggle(themeColor);
    body.toggle('dark-theme');
  };

  return (
    <div className="theme-option">
      <Drawer
        className="app-sidebar-content right-sidebar"
        anchor="right"
        open={props.drawerStatus}
        onClose={closeCustomizer}
      >
        <div className="color-theme">
          <div className="color-theme-header">
            <h3 className="color-theme-title">CONFIGURAÇÕES</h3>

            <IconButton className="icon-btn" onClick={closeCustomizer}>
              <i className="zmdi zmdi-close text-white" />
            </IconButton>
          </div>
          <div className="color-theme-body">
            <h3>Menu Claro</h3>
            <ul className="color-option">
              <li>
                <span
                  onClick={() => handleThemeColor('indigo')}
                  className={`jr-link bg-indigo ${themeColor === 'indigo' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('cyan')}
                  className={`jr-link bg-cyan ${themeColor === 'cyan' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('amber')}
                  className={`jr-link bg-amber ${themeColor === 'amber' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('deep-orange')}
                  className={`jr-link bg-deep-orange ${themeColor ===
                    'deep-orange' && 'active'}`}
                />
              </li>

              <li>
                <span
                  onClick={() => handleThemeColor('pink')}
                  className={`jr-link bg-pink ${themeColor === 'pink' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('blue')}
                  className={`jr-link bg-blue ${themeColor === 'blue' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('deep-purple')}
                  className={`jr-link bg-deep-purple ${themeColor ===
                    'deep-purple' && 'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('green')}
                  className={`jr-link bg-green ${themeColor === 'green' &&
                    'active'}`}
                />
              </li>
            </ul>
            <h3>Menu Escuro</h3>
            <ul className="color-option cr-op-dark-sidebar">
              <li>
                <span
                  onClick={() => handleThemeColor('dark-indigo')}
                  className={`jr-link bg-indigo ${themeColor ===
                    'dark-indigo' && 'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('dark-cyan')}
                  className={`jr-link bg-cyan ${themeColor === 'dark-cyan' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('dark-amber')}
                  className={`jr-link bg-amber ${themeColor === 'dark-amber' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('dark-deep-orange')}
                  className={`jr-link bg-deep-orange ${themeColor ===
                    'dark-deep-orange' && 'active'}`}
                />
              </li>

              <li>
                <span
                  onClick={() => handleThemeColor('dark-pink')}
                  className={`jr-link bg-pink ${themeColor === 'dark-pink' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('dark-blue')}
                  className={`jr-link bg-blue ${themeColor === 'dark-blue' &&
                    'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('dark-deep-purple')}
                  className={`jr-link bg-deep-purple ${themeColor ===
                    'dark-deep-purple' && 'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor('dark-green')}
                  className={`jr-link bg-green ${themeColor === 'dark-green' &&
                    'active'}`}
                />
              </li>
            </ul>
            <div className="row text-left">
              <div className="col-4 text-left">
                <h3 className="mb-1">RTL</h3>
                <Switch
                  color="primary"
                  checked={isDirectionRTL}
                  onChange={() => dispatch(SettingActions.changeDirection())}
                />
              </div>
              <div className="col-8 text-right">
                <h3 className="mb-1">Modo Noturno</h3>
                <Switch
                  color="primary"
                  checked={darkTheme}
                  onChange={handleDarkTheme}
                />
              </div>
            </div>
            <div className="mt-3">
              <SideNavOption closeCustomizer={closeCustomizer} />
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default withRouter(ColorOption);
