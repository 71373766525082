import React from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';

const MaskedInput = ({
  name,
  label,
  type,
  mask,
  disabled,
  value,
  onChange,
  onBlur,
  className,
  style,
}) => {
  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      maskChar={null}
      maskPlaceholder={null}
      disabled={disabled}
      onBlur={onBlur}
    >
      {() => (
        <TextField
          name={name}
          type={type || 'text'}
          label={label}
          disabled={disabled}
          className={className}
          style={style}
          fullWidth={!style && !className}
        />
      )}
    </InputMask>
  );
};

export default MaskedInput;
