export const Types = {
  POST_RELATORIO: 'POST_RELATORIO',
  POST_RELATORIO_SUCCESS: 'POST_RELATORIO_SUCCESS',
  POST_RELATORIO_ERROR: 'POST_RELATORIO_ERROR',
  POST_RELATORIO_RESET: 'POST_RELATORIO_RESET',
};

const INITIAL_STATE = {
  postLoading: false,
  relatorio: null,
  postError: false,
};

export default function relatorio(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_RELATORIO:
      return {
        ...state,
        postLoading: true,
        relatorio: null,
        postError: false,
      };
    case Types.POST_RELATORIO_SUCCESS:
      return {
        ...state,
        postLoading: false,
        relatorio: action.payload,
        postError: false,
      };

    case Types.POST_RELATORIO_ERROR:
      return {
        ...state,
        postLoading: false,
        relatorio: null,
        postError: true,
      };
    case Types.POST_RELATORIO_RESET:
      return {
        ...state,
        postLoading: false,
        relatorio: null,
        postError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  postRelatorio: (payload) => ({
    type: Types.POST_RELATORIO,
    payload,
  }),
  postRelatorioSuccess: (payload) => ({
    type: Types.POST_RELATORIO_SUCCESS,
    payload,
  }),
  postRelatorioError: () => ({
    type: Types.POST_RELATORIO_ERROR,
  }),
  postRelatorioReset: () => ({
    type: Types.POST_RELATORIO_RESET,
  }),
};
