import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';

const DuplicateModal = ({
  open,
  setOpen,
  selected,
  table,
  headerData,
  rotina,
  onDuplicate,
}) => {
  const dispatch = useDispatch();
  const { saveLoading, saveSuccess } = useSelector(({ tables }) => tables);

  useEffect(() => {
    if (saveSuccess) {
      dispatch(TablesActions.saveDataReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [saveSuccess]);

  const duplicateData = () => {
    let submitData = {};
    headerData.map((d) => {
      d.itens.map((i) => {
        if (i.manutencao === 'SIM' && selected[`${i.coluna}`] !== null) {
          if (i?.tipo === 'NUMERO') {
            submitData[`${i.coluna}`] = Number(selected[`${i.coluna}`]);
          } else {
            submitData[`${i.coluna}`] = selected[`${i.coluna}`];
          }
        }
      });
    });
    dispatch(
      TablesActions.saveData({
        table,
        campos: submitData,
      })
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Duplicar Registro</DialogTitle>
      {saveLoading ? (
        <Loading />
      ) : (
        <DialogContent>
          <DialogContentText>
            Deseja realmente duplicar o registro {selected?.id}?
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={onDuplicate || duplicateData}
          color="primary"
          disabled={saveLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DuplicateModal;
