import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Grid,
  Button,
} from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useQuery } from 'util/index';

const styles = (theme) => ({
  noBefore: {
    margin: '2rem 0 0 0',
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  add: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  border: {
    borderTop: '1px solid',
    borderColor: '#c5c5c5',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  btn: {
    marginTop: '1rem',
    padding: '0 0.5rem',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
});

const Variação = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const id = query.get('id');
  const tabela = query.get('from');
  const nomeItem = query.get('item');
  const { variacao, varLoading, putVarSuccess } = useSelector(
    ({ produto }) => produto
  );
  const [varValue, setvarValue] = useState('');

  useEffect(() => {
    dispatch(
      ProdutoActions.getVariacao({ tabela, filtro: `produto_id=${id}` })
    );
  }, []);

  useEffect(() => {
    if (putVarSuccess) {
      dispatch(ProdutoActions.putVariacaoReset());
      dispatch(
        ProdutoActions.getVariacao({ tabela, filtro: `produto_id=${id}` })
      );
    }
  }, [putVarSuccess]);

  function onDelete(item, index) {
    var localVars = variacao;
    localVars[index].variacao = '';
    dispatch(
      ProdutoActions.putVariacao({
        id,
        registros: localVars,
      })
    );
  }
  function onStatus(item, index) {
    var localVars = variacao;
    localVars[index].ativo = localVars[index].ativo === 'SIM' ? 'NAO' : 'SIM';
    dispatch(
      ProdutoActions.putVariacao({
        id,
        registros: localVars,
      })
    );
  }

  return (
    <div className="app-wrapper">
      <HeaderPage title={`Variação - ${nomeItem}`} />
      <Card className="shadow">
        <CardContent>
          {varLoading ? (
            <Loading />
          ) : (
            <>
              {variacao.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Variação</TableCell>
                        <TableCell align="right">Ativo</TableCell>
                        <TableCell align="right">Ação</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {variacao.map((d, index) => {
                        if (d.variacao !== '') {
                          return (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {d.variacao}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                <FiberManualRecordIcon
                                  fontSize="small"
                                  onClick={() => onStatus(d, index)}
                                  style={{
                                    color: d.ativo === 'SIM' ? 'green' : 'red',
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                <DeleteIcon
                                  color="action"
                                  onClick={() => onDelete(d, index)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Accordion className={classes.noBefore}>
                <AccordionSummary
                  expandIcon={<AddRoundedIcon color="primary" />}
                >
                  <span className={classes.add}>Nova Variação</span>
                </AccordionSummary>
                <AccordionDetails className={classes.border}>
                  <Grid container>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <TextField
                        name="varValue"
                        label="Variação"
                        value={varValue}
                        onChange={(e) => setvarValue(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <div className={classes.btn}>
                      <Button
                        color="primary"
                        onClick={() =>
                          dispatch(
                            ProdutoActions.putVariacao({
                              id,
                              registros: [
                                ...variacao,
                                {
                                  produto_id: id,
                                  variacao: varValue,
                                },
                              ],
                            })
                          )
                        }
                        variant="contained"
                      >
                        Adicionar
                      </Button>
                    </div>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Variação);
