import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import moment from 'moment';
import api from 'util/api';

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const GerarDadosEcdModal = ({ open, setOpen, rotina, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { putExportTextLoading, putExportTextSuccess } = useSelector(
    ({ lancamento }) => lancamento
  );

  async function loadItems() {
    const { data } = await api.post(`/csql/877`);
    if (data) {
      setItems(data.dados || []);
    }
  }

  useEffect(() => {
    if (open) {
      loadItems();
    }
  }, [open]);

  useEffect(() => {
    if (putExportTextSuccess) {
      dispatch(LancamentoActions.putExportTextReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [putExportTextSuccess]);

  const onSend = (values) => {
    const data = {
      tipo: 'ECD',
      dtinicio: moment(values?.dtinicio, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dtfim: moment(values?.dtfim, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      planoentidade_id: values?.planoconta?.value,
    };
    dispatch(LancamentoActions.putExportText(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          planoconta: null,
          dtinicio: moment()
            .startOf('year')
            .format('DD/MM/YYYY'),
          dtfim: moment()
            .endOf('year')
            .format('DD/MM/YYYY'),
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <>
            <DialogTitle>Gerar Dados</DialogTitle>
            {putExportTextLoading ? (
              <Loading />
            ) : (
              <DialogContent>
                <Grid container>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Entidade / Plano de Contas"
                      items={items}
                      value={values.planoconta}
                      onChange={(v) => setFieldValue('planoconta', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <InputMask
                      mask="99/99/9999"
                      name="dtinicio"
                      label="Data Início"
                      value={values.dtinicio}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <InputMask
                      mask="99/99/9999"
                      name="dtfim"
                      label="Data Fim"
                      value={values.dtfim}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={() => onSend(values)}
                color="primary"
                disabled={putExportTextLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(GerarDadosEcdModal);
