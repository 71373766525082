import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { replaceConteudoi } from 'util/index';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import moment from 'moment';

const regimeDrop = [
  { value: 0, label: 'RPA (REGIME PERIÓDICO DE APURAÇÃO)' },
  { value: 1, label: 'RES (REGIME POR ESTIMATIVA)' },
  { value: 2, label: 'RPA - DISPENSADO' },
  {
    value: 3,
    label:
      'SIMPLES ST (ME, EPPA, EPPB - Operações com mercadorias sujeitas ao regime de SUBSTITUIÇÃO TRIBUTÁRIA)',
  },
];
const tipoDrop = [
  { value: 0, label: 'NORMAL' },
  { value: 1, label: 'SUBSTITUTIVA' },
  { value: 2, label: 'CORRIGIDA (GERADA PELO FISCAL)' },
];

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const GerarDadosGiaModal = ({ open, setOpen, filtros, rotina, classes }) => {
  const dispatch = useDispatch();
  const { putExportTextLoading, putExportTextSuccess } = useSelector(
    ({ lancamento }) => lancamento
  );

  useEffect(() => {
    if (putExportTextSuccess) {
      dispatch(LancamentoActions.putExportTextReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [putExportTextSuccess]);

  const onSend = (values) => {
    let dt = (p) =>
      replaceConteudoi(
        filtros.find((f) => f.titulo.toUpperCase() === p)?.conteudoi
      );
    const data = {
      tipo: 'GIA',
      filiali_id: filtros.find(
        (f) => f.titulo.toUpperCase() === 'FILIAL INICIAL'
      )?.conteudoi,
      filialf_id: filtros.find((f) => f.titulo.toUpperCase() === 'FILIAL FINAL')
        ?.conteudoi,
      regime: values?.regime?.value,
      tipogia: values?.tipo?.value,
      dtinicio: moment(dt('PERIODO INICIAL'), 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      dtfim: moment(dt('PERIODO FINAL'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };
    dispatch(LancamentoActions.putExportText(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik initialValues={{ regime: null, tipo: null }}>
        {({ values, setFieldValue }) => (
          <>
            <DialogTitle>Gerar Dados</DialogTitle>
            {putExportTextLoading ? (
              <Loading />
            ) : (
              <DialogContent>
                <Grid container>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Regime Tributário"
                      items={regimeDrop}
                      value={values.regime}
                      onChange={(v) => setFieldValue('regime', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Tipo da Gia"
                      items={tipoDrop}
                      value={values.tipo}
                      onChange={(v) => setFieldValue('tipo', v)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={() => onSend(values)}
                color="primary"
                disabled={putExportTextLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(GerarDadosGiaModal);
