import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { setDecimal } from 'util/index';
import styles from './styles';
import Loading from 'components/Loading';

function Totalizadores({ headerData, data, onLoad, paginacao, classes }) {
  const [totais, setTotais] = useState([]);
  const { totais: totaisStore } = useSelector(({ tables }) => tables);

  useEffect(() => {
    let tt = [];
    headerData.map((h) => {
      h.itens.map((i) => {
        if (
          i.agrupamento !== null &&
          i.agrupamento !== 'NENHUM' &&
          i.agrupamento !== 'ACUMULADO'
        ) {
          tt.push({
            coluna: i.coluna,
            valor: 0,
            titulo: i.titulo,
            formato: i.formato,
          });
        }
      });
    });

    if (data) {
      data.map((d) => {
        for (var dataProps in d) {
          tt.map((t) => {
            if (dataProps === t.coluna) {
              t.valor = t.valor + Number(d[dataProps]);
            }
          });
        }
      });
      setTotais(tt);
    }
  }, [data]);

  function setFormats(formato) {
    if (formato && formato !== '') {
      if (formato === ',0.0') {
        return 1;
      }
      if (
        formato === ',0.00' ||
        formato === '0,00' ||
        formato === ',0.##' ||
        formato === '0,##'
      ) {
        return 2;
      }
      if (formato === ',0.###' || formato === ',0.000') {
        return 3;
      }
      if (
        formato === ',0.00##' ||
        formato === ',0.####' ||
        formato === ',0.0000'
      ) {
        return 4;
      }
      if (
        formato === ',0.######' ||
        formato === ',0.00####' ||
        formato === ',0.000000'
      ) {
        return 6;
      }
      if (formato === ',0.#########') {
        return 9;
      }
    }
  }

  const RenderWithoutPaginacao = () => (
    <Grid container spacing={0}>
      {totais.map((t) => (
        <Grid container item xs={3} spacing={0}>
          <Paper
            elevation={5}
            className={`${classes.paper} bg-gradient-primary-x text-white`}
          >
            <h4 className={`${classes.titles} text-center`}>{t.titulo}</h4>
            <h6 className={classes.valor}>
              {setDecimal(t.valor, setFormats(t.formato))}
            </h6>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  const RenderWithPaginacao = () => {
    if (!!totaisStore.length) {
      return (
        <Grid container spacing={0}>
          {Object.entries(totaisStore[0]).map((t) => {
            const total = totais.find((f) => f?.coluna === t[0]);
            if (total) {
              return (
                <Grid container item xs={3} spacing={0}>
                  <Paper
                    elevation={5}
                    className={`${classes.paper} bg-gradient-primary-x text-white`}
                  >
                    <h4 className={`${classes.titles} text-center`}>
                      {total.titulo}
                    </h4>
                    <h6 className={classes.valor}>
                      {setDecimal(t[1], setFormats(total.formato))}
                    </h6>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
      );
    }
    return (
      <div className="d-flex w-100 align-items-center justify-content-center">
        <Loading />
      </div>
    );
  };

  if (!totais.length) return <></>;

  return (
    <div className="mt-3">
      <ExpansionPanel
        elevation={3}
        onChange={(_, exp) => {
          if (exp && paginacao) {
            onLoad();
          }
        }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h4 className={classes.titles}>Totais</h4>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {paginacao ? <RenderWithPaginacao /> : <RenderWithoutPaginacao />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default withStyles(styles)(Totalizadores);
