import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { replaceConteudoi } from 'util/index';
import moment from 'moment';

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const DapiModal = ({ open, setOpen, filtros, onSubmit }) => {
  const [dapi, setDapi] = useState({ value: 'NAO', label: 'NÃO' });

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>DAPI para substituição</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12">
            <SelectAuto
              name="dapi"
              label="DAPI para substituição"
              items={yn}
              value={dapi}
              onChange={(v) => setDapi(v)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            let dt = (p) =>
              replaceConteudoi(
                filtros.find((f) => f.titulo.toUpperCase() === p)?.conteudoi
              );
            const data = {
              tipo: 'DAPI',
              substituicao: dapi?.value,
              filiali_id: filtros.find(
                (f) => f.titulo.toUpperCase() === 'FILIAL INICIO'
              )?.conteudoi,
              filialf_id: filtros.find(
                (f) => f.titulo.toUpperCase() === 'FILIAL FIM'
              )?.conteudoi,
              dtinicio: moment(dt('DATA INICIO APURAÇÃO'), 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
              dtfim: moment(dt('DATA FIM APURAÇÃO'), 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
              dtapuracao: moment(dt('DTAPURACAO'), 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
            };
            onSubmit(data);
          }}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DapiModal;
