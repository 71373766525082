import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { Card, CardContent, Grid, TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import api from 'util/api';
import { useQuery, findOnArray, setDecimal, transformData } from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const SitTribManitencao = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const param = query.get('id');
  const tributo = query.get('tributo');
  const { saveLoading, saveSuccess, editLoading, editSuccess } = useSelector(
    ({ tables }) => tables
  );

  async function loadItems(ids) {
    const promises = ids.map(async (id) => {
      if (id) {
        const { data } = await api.post(
          `/csql/${id}`,
          id === 615 && {
            Parametros: [
              {
                nome: ':tributo',
                valor: tributo || null,
              },
            ],
          }
        );
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setItems(results);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([615, 370]);
  }, []);

  useEffect(() => {
    if (saveSuccess) {
      dispatch(TablesActions.saveDataReset());
      history.goBack();
    }
    if (editSuccess) {
      dispatch(TablesActions.editDataReset());
      history.goBack();
    }
  }, [saveSuccess, editSuccess]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();

    useEffect(() => {
      if (!!items[1] && !!items[0]) {
        let finder = items[1]?.find((i) => i?.id === Number(param));
        if (finder) {
          setValues({
            ...values,
            baseteto: setDecimal(finder?.baseteto, 2),
            cst: findOnArray(finder?.cst, items[0]),
            cstemitente_id: findOnArray(finder?.cstemitente_id, items[0]),
            cstteto_id: findOnArray(finder?.cstteto_id, items[0]),
            descricao: finder?.descricao,
            ntributado: findOnArray(finder?.ntributado, nTributadoDrop),
            operacaotd: findOnArray(finder?.operacaotd, operacaoDrop),
            vlrteto: setDecimal(finder?.vlrteto, 2),
          });
        }
      }
    }, [items]);
    return null;
  };

  const onSubmit = (values) => {
    const campos = {
      baseteto: transformData(values?.baseteto, 'number'),
      cst: values?.cst?.value,
      cstemitente_id: values?.cstemitente_id?.value,
      cstteto_id: values?.cstteto_id?.value,
      descricao: values?.descricao,
      ntributado: values?.ntributado?.value,
      operacaotd: values?.operacaotd?.value,
      tributo_id: tributo ? Number(tributo) : null,
      vlrteto: transformData(values?.vlrteto, 'number'),
    };

    if (param) {
      dispatch(
        TablesActions.editData({
          table: 'situacao_tributaria',
          campos,
          id: param,
        })
      );
    } else {
      dispatch(
        TablesActions.saveData({
          table: 'situacao_tributaria',
          campos,
        })
      );
    }
  };

  const nTributadoDrop = [
    {
      value: 'ISENTO',
      label: 'ISENTO',
    },
    {
      value: 'OUTROS',
      label: 'OUTROS',
    },
    {
      value: 'NENHUM',
      label: 'NENHUM',
    },
  ];

  const operacaoDrop = [
    {
      value: 'SOMA',
      label: 'SOMA',
    },
    {
      value: 'SUBTRAI',
      label: 'SUBTRAI',
    },
    {
      value: 'NENHUMA',
      label: 'NENHUMA',
    },
  ];

  if (localLoading) {
    return <Loading />;
  }
  return (
    <div className="app-wrapper">
      <HeaderPage
        title={param ? 'Editar Registro' : 'Cadastrar Registro'}
        history={history}
      />
      <Formik
        initialValues={{
          baseteto: null,
          cst: null,
          cstemitente_id: null,
          cstteto_id: null,
          descricao: null,
          ntributado: null,
          operacaotd: null,
          vlrteto: null,
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Card className="shadow">
            <SetFields />
            <CardContent>
              <Grid container>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Sit. Trib. Código"
                    items={items[0] || []}
                    value={values.cst}
                    onChange={(v) => setFieldValue('cst', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    label="Sit. Trib. Descrição"
                    name="descricao"
                    value={values.descricao}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Não Tributado"
                    items={nTributadoDrop}
                    value={values.ntributado}
                    onChange={(v) => setFieldValue('ntributado', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Operação (Total documento)"
                    items={operacaoDrop}
                    value={values.operacaotd}
                    onChange={(v) => setFieldValue('operacaotd', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Sit. Trib. Emit. Id."
                    items={items[0] || []}
                    value={values.cstemitente_id}
                    onChange={(v) => setFieldValue('cstemitente_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    label="Teto (Vlr.mposto)"
                    name="vlrteto"
                    value={values.vlrteto}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('vlrteto', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    label="Teto (Base Cálculo)"
                    name="baseteto"
                    value={values.baseteto}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('baseteto', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Teto (Id. S. T.)"
                    items={items[0] || []}
                    value={values.cstteto_id}
                    onChange={(v) => setFieldValue('cstteto_id', v)}
                  />
                </Grid>
              </Grid>
              <div
                className="d-flex justify-content-end"
                style={{ flex: 1, marginTop: '1rem' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disable={saveLoading || editLoading}
                >
                  Finalizar
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
      </Formik>
    </div>
  );
};
export default withStyles(styles)(SitTribManitencao);
