import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import Legendas from 'components/Legendas';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import FilterReportModal from 'components/Modals/FilterReportModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import DocGerarModal from 'components/Modals/DocGerarModal';
import DocRegistroModal from 'components/Modals/DocRegistroModal';
import DocEmissaoModal from 'components/Modals/DocEmissaoModal';
import CartaModal from 'components/Modals/CartaModal';
import MessageModal from 'components/Modals/MessageModal';
import ResumoModal from 'components/Modals/ResumoModal';
import DocImportXMLModal from 'components/Modals/DocImportXMLModal';
import DocStatusModal from 'components/Modals/DocStatusModal';
import FormaEnvioModal from 'components/Modals/FormaEnvioModal';
import CarregamentoModal from 'components/Modals/CarregamentoModal';
import {
  checkManutencao,
  checkAgrupamento,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ResumoActions } from 'store/ducks/Resumo';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import { Creators as MidiaActions } from 'store/ducks/Midia';
import { Creators as FilterActions } from 'store/ducks/FilterState';
import { toast } from 'react-toastify';

const NotasEmitidas = ({ match }) => {
  const { filter } = useSelector(({ filterState }) => filterState);
  const filterPath = useLocation().pathname;
  const rotina = 398;
  const csql = 274;
  const [relatorio, setRelatorio] = useState(null);
  const title = 'Notas Emitidas';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState(
    filter[filterPath]?.dataTable?.Parametros[0]?.valor || ''
  );
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [multiSelected, setMultiSelected] = useState([]);
  const [totais, setTotais] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [importXmlModal, setImportXmlModal] = useState(false);
  const [docGerarModal, putDocGerarModal] = useState(false);
  const [docRegistroModal, setDocRegistroModal] = useState(false);
  const [docEmissaoModal, setDocEmissaoModal] = useState(false);
  const [cartaModal, setCartaModal] = useState(false);
  const [formaEnvioModal, setFormaEnvioModal] = useState(false);
  const [docStatusModal, setDocStatusModal] = useState(false);
  const [carregamentoModal, setCarregamentoModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const {
    capaSuccess,
    idRegistred,
    docgerar,
    emissao,
    registroSuccess,
    consultas,
    sendMailSuccess,
    exportXmlSuccess,
    postNfePreview,
    imprimirDanfeEtiquetaSuccess,
  } = useSelector(({ documento }) => documento);
  const { authUser } = useSelector(({ auth }) => auth);
  const { deleteSuccess } = useSelector(({ midia }) => midia);
  const { postEtiquetaSuccess } = useSelector(({ produto }) => produto);

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = (filter[filterPath]?.filtros || filtros).map(
        (f) => {
          if (f.parametro === 'SIM') {
            return { ...f, conteudoi: param, conteudod: nameParam };
          }
          return f;
        }
      );
      return filterWithParam;
    }
    return filter[filterPath]?.filtros || filtros;
  }

  function getTotais() {
    if (rotina === renderRotina) {
      const newParams = getWhereClause({
        filtros: setParamOnFilter(),
        param: true,
      });
      if (paginacao) {
        dispatch(
          TablesActions.getTotais({
            csql,
            params: {
              rotina,
              Parametros: [{ nome: 'likevalue', valor: search }, ...newParams],
              WhereClause: getWhereClause({
                filtros: filter[filterPath]?.filtros || filtros,
                setStringExport,
              }),
            },
          })
        );
      }
    }
  }

  function getDataTable(props) {
    dispatch(ResumoActions.setFilterResumo(setParamOnFilter()));
    setMultiSelected([]);
    const newParams = getWhereClause({
      filtros: setParamOnFilter(),
      param: true,
    });
    const params = {
      Parametros: [{ nome: 'likevalue', valor: search }, ...newParams],
      WhereClause: getWhereClause({
        filtros,
        setStringExport,
      }),
      OrderClause,
      Pagina: paginacao ? Pagina : undefined,
      PorPagina: paginacao ? PorPagina : undefined,
    };
    dispatch(
      FilterActions.setFilterState({
        [filterPath]: { ...filter[filterPath], dataTable: params },
      })
    );
  }

  useEffect(() => {
    if (Boolean(filter[filterPath]?.dataTable)) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: filter[filterPath]?.dataTable,
        })
      );
      getTotais();
    }
  }, [filter[filterPath]]);

  const formatText = (e) => {
    var reader = new FileReader();
    reader.onload = (event) => {
      const body = event?.target?.result
        ?.replace(/(?:\r\n|\r|\n)/g, '%0D%0A')
        .replace('#DTSAIDA', selected?.dtsaida)
        .replace('#NF', selected?.numero)
        .replace('#CLIENTE', selected?.nome);
      const mailto = `mailto:${selected?.email?.replace(/;/g,'%3B')}?body=${body}`;
      window.open(mailto);
    };
    reader.readAsText(e?.target?.files[0]);
  };

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    if (reportFiltros.length) {
      if (relatorio === 590) {
        dispatch(
          TablesActions.postReport({
            rotina_id: relatorio,
            WhereClause: getWhereClause({
              filtros: [
                { ...reportFiltros[0], conteudoi: selected?.documento_id },
              ],
            }),
          })
        );
        setRelatorio(null);
      }
      if (relatorio === 778) {
        dispatch(
          TablesActions.postReport({
            rotina_id: relatorio,
            WhereClause: getWhereClause({
              filtros: [
                {
                  ...reportFiltros[0],
                  conteudoi: `(${multiSelected?.map(
                    (i) => `${i?.documento_id}`
                  )})`,
                },
              ],
            })?.replace(/=/g, 'in'),
          })
        );
        setRelatorio(null);
      }
    }
  }, [reportFiltros]);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length && param) {
      dispatch(TablesActions.setFiltros(setParamOnFilter()));
    } else {
      if (
        headerData.length &&
        (filter[filterPath]?.filtros || filtros).length
      ) {
        getDataTable();
      }
    }
  }, [headerData, Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (
      headerDataStore.length &&
      (filter[filterPath]?.filtros || filtros).length
    ) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (docgerar) {
      putDocGerarModal(!docGerarModal);
    }
  }, [docgerar]);

  useEffect(() => {
    if (capaSuccess) {
      dispatch(TablesActions.getHeader(rotina));
      history.push(`/app/Cadastros/Documento?id=${idRegistred}`);
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
    }
    if (emissao) {
      if (Boolean(emissao?.midiaurl)) {
        window.open(emissao?.midiaurl);
      }
      if (Boolean(emissao?.midiaid)) {
        dispatch(MidiaActions.deleteMidia(emissao?.midiaid));
      }
      if (emissao?.formato_saida === 'mail') {
        if (emissao?.metodo_email === 'LOCAL') {
          let redirect = `mailto:${emissao?.email?.replace(/;/g,'%3B')}?`;
          if (emissao?.assunto) {
            redirect = redirect + `subject=${emissao?.assunto}&`;
          }
          if (emissao?.body) {
            redirect = redirect + `body=${emissao?.body}&`;
          }
          window.open(redirect);
        } else {
          dispatch(
            DocumentoActions.sendMail({
              para: emissao?.email,
              assunto: emissao?.assunto,
              msg: emissao?.body,
            })
          );
        }
      }

      dispatch(TablesActions.getHeader(rotina));
      dispatch(DocumentoActions.postEmissaoReset());
    }
    if (registroSuccess) {
      dispatch(TablesActions.getHeader(rotina));
      dispatch(DocumentoActions.postRegistroReset());
    }
    if (sendMailSuccess) {
      dispatch(TablesActions.getHeader(rotina));
      dispatch(DocumentoActions.sendMailReset());
    }
  }, [capaSuccess, emissao, registroSuccess, sendMailSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(MidiaActions.deleteMidiaReset());
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (exportXmlSuccess) {
      dispatch(DocumentoActions.exportXmlReset());
    }
  }, [exportXmlSuccess]);

  useEffect(() => {
    if (postNfePreview) {
      const file = new Blob([postNfePreview], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatch(DocumentoActions.postNfeReset());
    }
  }, [postNfePreview]);

  useEffect(() => {
    if (imprimirDanfeEtiquetaSuccess) {
      dispatch(DocumentoActions.imprimirDanfeEtiquetaReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [imprimirDanfeEtiquetaSuccess]);

  useEffect(() => {
    if (postEtiquetaSuccess) {
      dispatch(ProdutoActions.postEtiquetaReset());
      setMultiSelected([]);
    }
  }, [postEtiquetaSuccess]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () =>
        param
          ? history.push(`/app/Manutenção?cod=${param}`)
          : history.push(`/app/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => setFilterModal(!filterModal),
      visible: (filter[filterPath]?.filtros || filtros)?.length,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: checkAgrupamento(headerData),
    },
    {
      name: 'Notas Inutilizadas',
      type: 'Limpar',
      action: () => history.push(`/app/Comercial/NotasEmitidas/Inutilizados`),
      visible: true,
    },
    {
      name: 'Carregamento',
      type: 'Carga',
      action: () =>
        multiSelected?.length
          ? setCarregamentoModal(true)
          : history.push(`/app/Comercial/NotasEmitidas/Carregamento`),
      visible: (filter[filterPath]?.filtros || filtros)
        .find((f) => f.titulo === 'Situação')
        ?.conteudoi?.includes('REGISTRADOS'),
    },
    {
      name: 'Lucratividade',
      type: 'Imprimir',
      action: () => {
        setRelatorio(592);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },

    {
      name: 'Conferência de documentos',
      type: 'Imprimir',
      action: () => {
        setRelatorio(815);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Etiqueta Destinatário',
      type: 'Imprimir',
      action: () => {
        if (multiSelected.length) {
          if (multiSelected?.some((s) => Boolean(s?.modelo_id))) {
            dispatch(
              ProdutoActions.postEtiqueta({
                modelo_id: multiSelected[0]?.modelo_id,
                impressora_id: multiSelected[0]?.impressoraweb_id,
                dado_impressao: multiSelected?.map((m) => ({
                  id: m?.id,
                  qtde: Number(m?.qtvolume) || 1,
                })),
              })
            );
          } else {
            dispatch(
              TablesActions.postReport({
                rotina_id: 501,
                Parametros: [
                  { nome: ':invalue', valor: multiSelected.map((i) => i?.id) },
                ],
              })
            );
          }
        }
      },
      visible: true,
    },
    {
      name: 'Importar XML',
      type: 'Reposicao',
      action: () => setImportXmlModal(!importXmlModal),
      visible: true,
    },
    {
      name: 'Exportar XML e Danfe',
      type: 'Reposicao',
      action: () => {
        if (!!multiSelected.length) {
          dispatch(
            DocumentoActions.exportXml({
              gerarPDF: true,
              Documentos: multiSelected.map((i) => ({
                id: i?.id,
              })),
            })
          );
        }
      },
      visible: true,
    },
    {      
      name: 'Exportar XML',
      type: 'Reposicao',
      action: () => {
        if (!!multiSelected.length) {
          dispatch(
            DocumentoActions.exportXml({
              Documentos: multiSelected.map((i) => ({
                id: i?.id,
              })),
            })
          );
        }
      },
      visible: true,
    },

    {
      name: 'Novo Documento',
      type: 'Gerar',
      action: () =>
        history.push(
          `/app/Cadastros/Documento?especie=${data[0]?.especie_id ||
            10}&natureza=${data[0]?.natureza_operacao_id || 6}`
        ),
      visible: true,
    },
    {
      name: 'Consultar Certificados',
      type: 'XLS',
      action: () =>
        dispatch(
          DocumentoActions.postConsultasNfe({
            path: 'nfe/listar-certificados',
            body: { filial_id: authUser?.storedEntidadeId },
          })
        ),
      visible: true,
    },
    {
      name: 'Status do Serviço',
      type: 'XLS',
      action: () =>
        dispatch(
          DocumentoActions.postConsultasNfe({
            path: 'nfe/status-servico',
            body: { filial_id: authUser?.storedEntidadeId },
          })
        ),
      visible: true,
    },
    {
      name: 'Danfe Simplificada',
      type: 'Imprimir',
      action: () => {
        const finder = multiSelected.find((i) => i?.modelo !== 'NFe');
        if (!!finder) {
          toast.warning('Selecione apenas itens com modelo NF-e');
        } else {
          setRelatorio(778);
        }
      },
      visible: (filter[filterPath]?.filtros || filtros)
        .find((f) => f.titulo === 'Situação')
        ?.conteudoi?.includes('REGISTRADOS'),
    },
    {
      name: 'Danfe Simplificada e Etiqueta',
      type: 'Imprimir',
      action: () => {
        const Documentos = multiSelected.map((i) => i?.id);
        dispatch(DocumentoActions.imprimirDanfeEtiqueta({ Documentos }));
      },
      visible: (filter[filterPath]?.filtros || filtros)
        .find((f) => f.titulo === 'Situação')
        ?.conteudoi?.includes('REGISTRADOS'),
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Documento',
          action: (item) =>
            history.push(`/app/Cadastros/Documento?id=${item?.id}`),
          visible: true,
        },
        {
          name: 'Status',
          varName: 'DocStatus',
          action: (item) => {
            setSelected(item);
            setDocStatusModal(!docStatusModal);
          },
          visible: true,
        },
        {
          name: 'Aprovação',
          varName: 'Aprovação',
          action: (item) =>
            history.push(
              `/app/Aprovação?id=${item?.documento_id}&name${item.especie} - Id: ${item.documento_id}`
            ),
          funcVis: (item) => item?.temaprovacao === 'SIM',
        },
        {
          name: 'Registros',
          action: (item) =>
            history.push(
              `/app/Suprimentos/GestãodeCompras/Registros?id=${item?.documento_id}&name=${item.documento_id}' - '${item.nome}`
            ),
          visible: true,
        },
        {
          name: 'Gerar NF',
          varName: 'Documento',
          action: (item) => {
            setSelected([item]);
            let Documentos = [{ id: item?.id }];
            dispatch(DocumentoActions.putDocGerar({ Documentos }));
          },
          funcVis: (item) =>
            (item?.especie === 'CF-e' && item?.situacao === 'REGISTRADOS') ||
            item?.especie_id === 25,
        },
        {
          name: 'Registrar Documento',
          varName: 'Documento',
          action: (item) => {
            setSelected(item);
            setDocRegistroModal(!docRegistroModal);
          },
          visible: true,
        },
        {
          name: 'Emissão do Documento',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setDocEmissaoModal(!docEmissaoModal);
          },
          visible: true,
        },
        {
          name: 'Lucratividade',
          varName: 'Imprimir',
          action: (item) => {
            setSelected(item);
            setRelatorio(590);
          },
          visible: true,
        },
        {
          name: 'Copiar Documento',
          varName: 'Gerar',
          action: (item) => {
            setSelected([item]);
            let Documentos = [{ id: item?.id }];
            dispatch(
              DocumentoActions.putDocGerar({ Documentos, Rotina: 'COPIA' })
            );
          },
          visible: true,
        },
        {
          name: 'Faturar cupom',
          varName: 'Gerar',
          action: (item) => {
            setSelected([item]);
            let Documentos = [{ id: item?.id }];
            dispatch(
              DocumentoActions.putDocGerar({
                Documentos,
                Rotina: 'FATURARCUPOM',
              })
            );
          },
          funcVis: (item) => item?.modelo === 'CFe' || item?.modelo === 'CUPOM',
        },
        {
          name: 'Expedição de Materiais',
          varName: 'DocRecebimento',
          action: (item) => history.push(`/app/Movimentação/?id=${item?.id}`),
          visible: true,
        },
        {
          name: 'Carta de Correção',
          varName: 'Documento',
          action: (item) => {
            setSelected(item);
            setCartaModal(!cartaModal);
          },
          visible: true,
        },
        {
          name: 'Impressão da Carta de Correção',
          varName: 'Imprimir',
          action: (item) =>
            dispatch(
              DocumentoActions.postCorrecao({
                documentoid: item?.documento_id,
                sequencia: item?.cartaseq,
              })
            ),
          funcVis: (item) => item?.carta === 'SIM',
        },
        {
          name: 'Consultar Situação da NF-e',
          varName: 'Documento',
          action: (item) => {
            if (item?.modelo === 'NFe') {
              dispatch(
                DocumentoActions.postConsultasNfe({
                  path: 'nfe/situacao-dfe',
                  body: { chdfe: item?.nfechaveacesso },
                })
              );
            }
            if (item?.modelo === 'NFSe') {
              dispatch(
                DocumentoActions.postConsultasNfe({
                  path: 'nfse/situacao-nfse',
                  body: {
                    documentoId: item?.documento_id,
                    filialId: item?.filial_id,
                  },
                })
              );
            }
          },

          funcVis: (item) => item?.dfe === 'SIM',
        },
        {
          name: 'Pre visualizar NFe',
          varName: 'Documento',
          action: (item) =>
            dispatch(DocumentoActions.postNfe(item?.documento_id)),
          funcVis: (item) => item?.situacao === 'REGISTRAR',
        },
        {
          name: 'Email',
          action: (item) => {
            setSelected(item);
            const input = document.getElementById('addtxt');
            input.click();
          },
          visible: true,
        },
        {
          name: 'Forma de Envio',
          varName: 'Documento',
          action: (item) => {
            setSelected(item);
            setFormaEnvioModal(true);
          },
          visible: true,
        },
      ],
      visible: true,
    },
    {
      name: 'Status',
      statScreen: 'Notas',
      action: (item) => {},
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                selected={multiSelected}
                setSelected={setMultiSelected}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Legendas screen="Notas" />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filter[filterPath]?.filtros || filtros}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <DocGerarModal
                open={docGerarModal}
                setOpen={putDocGerarModal}
                selected={selected}
                despecie={10}
              />
              <DocRegistroModal
                open={docRegistroModal}
                setOpen={setDocRegistroModal}
                selected={selected}
              />
              <DocEmissaoModal
                open={docEmissaoModal}
                setOpen={setDocEmissaoModal}
                selected={selected}
              />
              <CartaModal
                open={cartaModal}
                setOpen={setCartaModal}
                selected={selected}
              />
              <MessageModal
                open={consultas}
                onClose={() => {
                  dispatch(DocumentoActions.postConsultasNfeReset());
                  dispatch(TablesActions.getHeader(rotina));
                }}
                consultas={consultas}
              />
              <DocImportXMLModal
                open={importXmlModal}
                setOpen={setImportXmlModal}
                selected={selected}
                rotina={rotina}
                op="SAIDA"
              />
              <DocStatusModal
                open={docStatusModal}
                setOpen={setDocStatusModal}
                selected={selected}
              />
              <FormaEnvioModal
                open={formaEnvioModal}
                setOpen={setFormaEnvioModal}
                selected={selected}
              />
              <CarregamentoModal
                open={carregamentoModal}
                setOpen={setCarregamentoModal}
                selected={multiSelected}
                history={history}
              />
            </>
          )}
        </CardContent>
      </Card>
      <input
        accept="text/plain"
        style={{ display: 'none' }}
        id="addtxt"
        type="file"
        onChange={(e) => formatText(e)}
        onClick={(event) => {
          event.target.value = '';
        }}
      />
    </div>
  );
};

export default NotasEmitidas;
