import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const JustificativaModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    justificativa: null,
  });
  const { postConsultasLoading } = useSelector(({ documento }) => documento);

  const onSubmit = () => {
    const path =
      selected?.codigomodelo === '57'
        ? 'cte/prestacao-servico-desacordo'
        : 'nfe/operacao-nao-realizada';
    dispatch(
      DocumentoActions.postConsultasNfe({
        path,
        body: {
          chdfe: selected?.chavenfe,
          justificativa: values?.justificativa,
        },
      })
    );
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Justificativa</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <TextField
              label="Justificativa"
              value={values.justificativa || ''}
              onChange={(e) =>
                setValues({ ...values, justificativa: e?.target?.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={postConsultasLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(JustificativaModal);
