import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as FiscalActions } from '../../../store/ducks/Fiscal';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import api from 'util/api';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const selectDrop = [
  { value: 'NATUREZA', label: 'NATUREZA DA OPERAÇÃO' },
  { value: 'FILIAL', label: 'FILIAL' },
  { value: 'NCM', label: 'CLASSIFICAÇÃO FISCAL' },
];

const CopiarRegraModal = ({ open, setOpen, rotina, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const { postCopiaRegraSuccess, postCopiaRegraLoading } = useSelector(
    ({ fiscal }) => fiscal
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    if (open) {
      loadItems([644, 621, 654, 614]);
    }
  }, [open]);

  useEffect(() => {
    if (postCopiaRegraSuccess) {
      dispatch(FiscalActions.postCopiaRegraReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [postCopiaRegraSuccess]);

  const onSubmit = (values) => {
    let data = {};
    if (values?.select?.value === 'NATUREZA') {
      data = {
        org_natureza_operacao_id: values?.origem?.value,
        dest_natureza_operacao_id: values?.destino?.value,
      };
    }
    if (values?.select?.value === 'FILIAL') {
      data = {
        org_filial_id: values?.origem?.value,
        dest_filial_id: values?.destino?.value,
      };
    }
    if (values?.select?.value === 'NCM') {
      data = {
        org_cfiscal_id: values?.origem?.value,
        dest_cfiscal_id: values?.destino?.value,
      };
    }
    if (!!values?.tributo_id?.value) {
      data = {
        ...data,
        tributo_id: values?.tributo_id?.value,
      };
    }
    dispatch(FiscalActions.postCopiaRegra(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          select: null,
          origem: null,
          destino: null,
          tributo_id: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogTitle>Copiar Regra</DialogTitle>
            {localLoading ? (
              <Loading />
            ) : (
              <DialogContent>
                <Grid container>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Tributo"
                      items={items[3] || []}
                      value={values.tributo_id}
                      onChange={(v) => setFieldValue('tributo_id', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Copiar Por..."
                      items={selectDrop}
                      value={values.select}
                      onChange={(v) => setFieldValue('select', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Origem"
                      items={
                        values?.select?.value === 'NATUREZA'
                          ? items[0]
                          : values?.select?.value === 'FILIAL'
                          ? items[1]
                          : values?.select?.value === 'NCM'
                          ? items[2]
                          : []
                      }
                      value={values.origem}
                      onChange={(v) => setFieldValue('origem', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Destino"
                      items={
                        values?.select?.value === 'NATUREZA'
                          ? items[0]
                          : values?.select?.value === 'FILIAL'
                          ? items[1]
                          : values?.select?.value === 'NCM'
                          ? items[2]
                          : []
                      }
                      value={values.destino}
                      onChange={(v) => setFieldValue('destino', v)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={() => onSubmit(values)}
                color="primary"
                disabled={postCopiaRegraLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(CopiarRegraModal);
