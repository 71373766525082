import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import Legendas from 'components/Legendas';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import FilterAccordion from 'components/FilterAccordion';
import ViewModal from 'components/Modals/ViewModal';
import FilterReportModal from 'components/Modals/FilterReportModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import AjusteVolumeModal from 'components/Modals/AjusteVolumeModal';
import EtiquetaModal from 'components/Modals/EtiquetaModal';
import SelecionadosModal from 'components/Modals/SelecionadosModal';
import AttPrecoModal from 'components/Modals/AttPrecoModal';
import { getWhereClause, viewBoxes, useQuery, checkVariacao } from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import { Creators as FilterActions } from 'store/ducks/FilterState';

const Produto = ({ match }) => {
  const { filter } = useSelector(({ filterState }) => filterState);
  const filterPath = useLocation().pathname;
  const rotina = 375;
  const csql = 247;
  const relatorio = 738;
  const title = 'Produto';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState(
    filter[filterPath]?.dataTable?.Parametros[0]?.valor || ''
  );
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [multiSelected, setMultiSelected] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [ajusteVolumeModal, setAjusteVolumeModal] = useState(false);
  const [etiquetaModal, setEtiquetaModal] = useState(false);
  const [selecionadosModal, setSelecionadosModal] = useState(false);
  const [attPrecoModal, setAttPrecoModal] = useState(false);
  const [attCustoModal, setAttCustoModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const {
    deleteSuccess,
    postSuccess,
    produto,
    setSuccess,
    putAjustaVolumeSuccess,
    postEtiquetaSuccess,
    postManutSelSuccess,
    postPrecosSuccess,
  } = useSelector(({ produto }) => produto);
  const { authUser } = useSelector(({ auth }) => auth);

  function getTotais() {
    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }],
            WhereClause: getWhereClause({
              filtros: filter[filterPath]?.filtros || filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    setMultiSelected([]);
    if (rotina === renderRotina) {
      const params = {
        Parametros: [{ nome: 'likevalue', valor: search }],
        WhereClause: getWhereClause({
          filtros: filter[filterPath]?.filtros || filtros,
          setStringExport,
        }),
        OrderClause,
        Pagina: paginacao ? Pagina : undefined,
        PorPagina: paginacao ? PorPagina : undefined,
      };
      dispatch(
        FilterActions.setFilterState({
          [filterPath]: { ...filter[filterPath], dataTable: params },
        })
      );
    }
  }

  useEffect(() => {
    if (Boolean(filter[filterPath]?.dataTable)) {
      dispatch(
        TablesActions.getDataTable({
          csql,
          params: filter[filterPath]?.dataTable,
        })
      );
      getTotais();
    }
  }, [filter[filterPath]]);

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
    dispatch(TablesActions.getReport(relatorio));
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(ProdutoActions.deleteProdutoReset());
      dispatch(TablesActions.getHeader(rotina));
      setDeleteModal(!deleteModal);
    }
    if (postSuccess) {
      dispatch(ProdutoActions.postProdutoReset());
      dispatch(TablesActions.getHeader(rotina));
      setDuplicateModal(false);
    }
  }, [deleteSuccess, postSuccess]);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    getDataTable();
  }, [Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (setSuccess) {
      getDataTable();
    }
  }, [setSuccess]);

  useEffect(() => {
    if (produto && selected) {
      setDuplicateModal(true);
    }
  }, [produto]);

  useEffect(() => {
    if (putAjustaVolumeSuccess) {
      dispatch(ProdutoActions.putAjustaVolumeReset());
      setAjusteVolumeModal(false);
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [putAjustaVolumeSuccess]);

  useEffect(() => {
    if (postEtiquetaSuccess) {
      dispatch(ProdutoActions.postEtiquetaReset());
      setEtiquetaModal(false);
      setMultiSelected([]);
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postEtiquetaSuccess]);

  useEffect(() => {
    if (postManutSelSuccess) {
      dispatch(ProdutoActions.postManutSelReset());
      setSelecionadosModal(false);
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postManutSelSuccess]);

  useEffect(() => {
    if (postPrecosSuccess) {
      setMultiSelected([]);
      dispatch(ProdutoActions.postPrecosReset());
      setAttPrecoModal(false);
      setAttCustoModal(false);
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postPrecosSuccess]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () => history.push(`/app/Cadastros/Produto/Manutenção`),
      visible: true,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Produtos',
      type: 'Imprimir',
      action: () => setFilterReportModal(!filterReportModal),
      visible: true,
    },
    {
      name: 'Impressão de Etiquetas',
      type: 'Imprimir',
      action: () => setEtiquetaModal(true),
      visible: true,
    },
    {
      name: 'Manutenção de Selecionados',
      type: 'Gerar',
      action: () => setSelecionadosModal(true),
      visible: !!multiSelected?.length,
    },
    {
      name: 'Atualizar Preços',
      type: 'Preço',
      action: () => setAttPrecoModal(true),
      visible: !!multiSelected?.length,
    },
    {
      name: 'Atualizar Custos',
      type: 'Preço',
      action: () => setAttCustoModal(true),
      visible: !!multiSelected?.length,
    },
    {
      name: 'Linhas',
      type: 'Reposicao',
      action: () =>
        window.open(
          `/app/Tabela?title=Linha%20de%20produtos&rot=315&csql=160`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Grupos',
      type: 'Reposicao',
      action: () =>
        window.open(
          `/app/Tabela?title=Grupo%20de%20produtos&rot=314&csql=159`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Marcas',
      type: 'Reposicao',
      action: () =>
        window.open(
          `/app/Tabela?title=Marca%20de%20produtos&rot=807&csql=964`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'NCM',
      type: 'Reposicao',
      action: () =>
        window.open(
          `/app/Tabela?title=NCM%20(Classificação%20fiscal)&rot=316&csql=161`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Origem Tributária',
      type: 'Reposicao',
      action: () =>
        window.open(
          `/app/Tabela?title=Origem%20tributária&rot=317&csql=162`,
          '_blank'
        ),
      visible: true,
    },
    {
      name: 'Unidade de Medida',
      type: 'Reposicao',
      action: () =>
        window.open(
          `/app/Tabela?title=Unidade%20de%20Medida&rot=213&csql=99`,
          '_blank'
        ),
      visible: true,
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) =>
        history.push(`/app/Cadastros/Produto/Manutenção?id=${item?.id}`),
      visible: true,
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: true,
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            dispatch(ProdutoActions.getProdutoReset());
            dispatch(ProdutoActions.getProduto(item?.id));
          },
          visible: true,
        },
        {
          name: 'Descrição',
          action: (item) =>
            window.open(
              `/app/Cadastros/Produto/Descrição?id=${item?.id}&from=produto_descricao&item=${item.descricao}`,
              '_blank'
            ),
          visible: true,
        },
        {
          name: 'Variação',
          action: (item) =>
            window.open(
              `/app/Cadastros/Produto/Variação?id=${item?.id}&from=produto_variacao&item=${item.descricao}`,
              '_blank'
            ),
          visible: checkVariacao(headerData),
        },
        {
          name: 'Mídias',
          action: (item) =>
            window.open(
              `/app/Cadastros/Produto/Mídias?id=${item?.id}&item=${item.descricao}`,
              '_blank'
            ),
          visible: true,
        },
        {
          name: 'Registros',
          action: (item) =>
            window.open(
              `/app/Cadastros/Produto/Registros?id=${item?.id}&name=${item.descricao}`,
              '_blank'
            ),
          visible: true,
        },
        {
          name: 'Estrutura do Produto',
          varName: 'EstruturaDoProduto',
          action: (item) =>
            window.open(
              `/app/Produção/EstruturaPadrao?id=${item?.id}&name=${item.descricao}`,
              '_blank'
            ),
          visible: true,
        },
        {
          name: 'Processos de produção',
          varName: 'ProcessosDeProdução',
          action: (item) =>
            window.open(
              `/app/Produção/ProcessoPadrao?id=${item?.id}&name=${item.descricao}`,
              '_blank'
            ),
          visible: true,
        },
        {
          name: 'Comissionamento',
          action: (item) =>
            window.open(
              `/app/Cadastros/Produto/Comissões?id=${item?.id}&name=${item.descricao}`,
              '_blank'
            ),
          visible: true,
        },
        // {
        //   name: 'Catalogo (Integrações)',
        //   varName: 'Remessa',
        //   action: (item) =>
        //     window.open(
        //       `/app/Cadastros/Produto/Catalogo?id=${item?.id}&item=${item.descricao}`,
        //       '_blank'
        //     ),
        //   visible: true,
        // },
        {
          name: 'Ajustar Volume do Produto',
          varName: 'Comissionamento',
          action: (item) => {
            setSelected(item);
            setAjusteVolumeModal(true);
          },
          visible: true,
        },
        {
          name: 'Referenciar Integrações',
          varName: 'Descrição',
          action: (item) =>
            history.push(
              `/app/Cadastros/Produto/Refintegra?id=${item?.id}&descricao=${item.descricao}`
            ),
          visible: authUser?.storedIntegra && authUser?.storedIntegra !== '',
        },
      ],
      visible: true,
    },
    {
      name: 'Status',
      statScreen: 'Produto',
      action: (item) => {
        dispatch(ProdutoActions.setStatusProduto(item?.id));
      },
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <FilterAccordion filtros={filter[filterPath]?.filtros || filtros} />
      <Card className="shadow mt-4">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                selected={multiSelected}
                setSelected={setMultiSelected}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              <Legendas screen="Produto" />
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(ProdutoActions.deleteProduto(selected?.id))
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
                onDuplicate={() =>
                  dispatch(
                    ProdutoActions.postProduto({
                      ...produto,
                      id: undefined,
                      produtoc_id: selected?.id,
                    })
                  )
                }
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <AjusteVolumeModal
                open={ajusteVolumeModal}
                setOpen={setAjusteVolumeModal}
                selected={selected}
              />
              <EtiquetaModal
                open={etiquetaModal}
                setOpen={setEtiquetaModal}
                produtos={multiSelected}
              />
              <SelecionadosModal
                open={selecionadosModal}
                setOpen={setSelecionadosModal}
                selected={multiSelected}
              />
              <AttPrecoModal
                open={attPrecoModal}
                setOpen={setAttPrecoModal}
                selected={multiSelected}
              />
              <AttPrecoModal
                open={attCustoModal}
                setOpen={setAttCustoModal}
                selected={multiSelected}
                custo
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Produto;
