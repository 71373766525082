export const Types = {
  POST_MIDIA: 'POST_MIDIA',
  POST_MIDIA_SUCCESS: 'POST_MIDIA_SUCCESS',
  POST_MIDIA_ERROR: 'POST_MIDIA_ERROR',
  POST_MIDIA_RESET: 'POST_MIDIA_RESET',

  GET_MIDIA: 'GET_MIDIA',
  GET_MIDIA_SUCCESS: 'GET_MIDIA_SUCCESS',
  GET_MIDIA_ERROR: 'GET_MIDIA_ERROR',

  DELETE_MIDIA: 'DELETE_MIDIA',
  DELETE_MIDIA_SUCCESS: 'DELETE_MIDIA_SUCCESS',
  DELETE_MIDIA_ERROR: 'DELETE_MIDIA_ERROR',
  DELETE_MIDIA_RESET: 'DELETE_MIDIA_RESET',
};

const INITIAL_STATE = {
  postLoading: false,
  postMidiaId: null,
  postError: false,

  getLoading: false,
  midia: null,
  getError: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,
};

export default function midia(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_MIDIA:
      return {
        ...state,
        postLoading: true,
        postMidiaId: null,
        postError: false,
      };
    case Types.POST_MIDIA_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postMidiaId: action.payload,
        postError: false,
      };
    case Types.POST_MIDIA_RESET:
      return {
        ...state,
        postLoading: false,
        postMidiaId: null,
        postError: false,
      };
    case Types.POST_MIDIA_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postMidiaId: null,
      };

    case Types.GET_MIDIA:
      return {
        ...state,
        getLoading: true,
        midia: null,
        getError: false,
      };
    case Types.GET_MIDIA_SUCCESS:
      return {
        ...state,
        getLoading: false,
        midia: action.payload,
        getError: false,
      };
    case Types.GET_MIDIA_ERROR:
      return {
        ...state,
        getLoading: false,
        midia: null,
        getError: true,
      };

    case Types.DELETE_MIDIA:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      };
    case Types.DELETE_MIDIA_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      };
    case Types.DELETE_MIDIA_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
      };
    case Types.DELETE_MIDIA_RESET:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  postMidia: (payload) => ({
    type: Types.POST_MIDIA,
    payload,
  }),
  postMidiaSuccess: (payload) => ({
    type: Types.POST_MIDIA_SUCCESS,
    payload,
  }),
  postMidiaFail: () => ({
    type: Types.POST_MIDIA_ERROR,
  }),
  postMidiaReset: () => ({
    type: Types.POST_MIDIA_RESET,
  }),

  getMidia: (payload) => ({
    type: Types.GET_MIDIA,
    payload,
  }),
  getMidiaSuccess: (payload) => ({
    type: Types.GET_MIDIA_SUCCESS,
    payload,
  }),
  getMidiaError: () => ({
    type: Types.GET_MIDIA_ERROR,
  }),

  deleteMidia: (payload) => ({
    type: Types.DELETE_MIDIA,
    payload,
  }),
  deleteMidiaSuccess: () => ({
    type: Types.DELETE_MIDIA_SUCCESS,
  }),
  deleteMidiaError: () => ({
    type: Types.DELETE_MIDIA_ERROR,
  }),
  deleteMidiaReset: () => ({
    type: Types.DELETE_MIDIA_RESET,
  }),
};
