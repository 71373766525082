import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, Grid, TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import HeaderPage from 'components/HeaderPage';
import InputMask from 'components/InputMask';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { transformData, setDecimal, findOnArray, useQuery } from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const Manutenção = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const param = query.get('id');
  const [items, setItems] = useState([]);
  const [contas, setContas] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [fill, setFill] = useState(false);
  const {
    headerData,
    saveLoading,
    saveSuccess,
    data,
    editLoading,
    editSuccess,
  } = useSelector(({ tables }) => tables);

  const yn = [
    { value: 'SIM', label: 'SIM' },
    { value: 'NAO', label: 'NÃO' },
  ];

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  async function getConta(planoId) {
    const promises = [878, 879].map(async (id) => {
      if (id !== null) {
        const { data } = await api.post(`/csql/${id}`, {
          Parametros: [
            {
              nome: ':plano_id',
              valor: planoId,
            },
          ],
        });
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setContas(results);
  }

  useEffect(() => {
    loadItems([877, 658]);
  }, []);

  useEffect(() => {
    if (saveSuccess) {
      dispatch(TablesActions.saveDataReset());
      history.goBack();
    }
    if (editSuccess) {
      dispatch(TablesActions.editDataReset());
      history.goBack();
    }
  }, [saveSuccess, editSuccess]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();

    useEffect(() => {
      if (param !== null && !fill) {
        let idAlt = null;
        headerData.map((h) => {
          let campo = h.itens.find((i) => i?.parametro === 'SIM')?.campo;
          if (campo) {
            idAlt = campo;
          }
        });
        const finder = data.find(
          (d) => d?.id === Number(param) || d?.[idAlt] === Number(param)
        );
        if (findOnArray(finder?.planoentidade_id, items[0])?.plano_id) {
          getConta(findOnArray(finder?.planoentidade_id, items[0])?.plano_id);
        }
        if (finder && !!contas.length) {
          setFill(true);
          setValues({
            ...values,
            contabilizado: findOnArray(finder?.contabilizado, yn),
            dtlanc: moment(finder?.dtlanc, 'YYYY-MM-DD').isValid()
              ? moment(finder?.dtlanc, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : null,
            planoentidade_id: findOnArray(finder?.planoentidade_id, items[0]),
            ctadeb_id: findOnArray(finder?.ctadeb_id, contas[0]),
            ctacred_id: findOnArray(finder?.ctacred_id, contas[1]),
            valor: setDecimal(finder?.valor || 0, 2),
            historico: finder?.historico,
            documento: finder?.documento,
            ccustodeb_id: findOnArray(finder?.ccustodeb_id, items[1]),
            ccustocred_id: findOnArray(finder?.ccustocred_id, items[1]),
          });
        }
      }
    }, [contas]);
    return null;
  };

  function handleSubmit(values) {
    let campos = {
      contabilizado: values?.contabilizado?.value,
      dtlanc: moment(values?.dtlanc, 'DD/MM/YYYY').isValid()
        ? moment(values?.dtlanc, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      planoentidade_id: values?.planoentidade_id?.value,
      ctadeb_id: values?.ctadeb_id?.value,
      ctacred_id: values?.ctacred_id?.value,
      valor: transformData(values?.valor, 'number'),
      historico: values?.historico || '',
      documento: values?.documento || '',
      ccustodeb_id: values?.ccustodeb_id?.value,
      ccustocred_id: values?.ccustocred_id?.value,
    };

    if (param) {
      dispatch(
        TablesActions.editData({
          table: 'cglanctos',
          campos,
          id: param,
        })
      );
    } else {
      dispatch(
        TablesActions.saveData({
          table: 'cglanctos',
          campos,
        })
      );
    }
  }

  if (localLoading) {
    return <Loading />;
  } else {
    return (
      <div className="app-wrapper">
        <HeaderPage
          title={`${param ? 'Editar' : 'Novo'} Registro`}
          history={history}
        />
        <Card className="shadow">
          <CardContent>
            <Formik
              initialValues={{
                contabilizado: null,
                dtlanc: null,
                planoentidade_id: null,
                ctadeb_id: null,
                ctacred_id: null,
                valor: null,
                historico: null,
                documento: null,
                ccustodeb_id: null,
                ccustocred_id: null,
              }}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, handleChange, submitForm, setFieldValue }) => (
                <>
                  <SetFields />
                  <Grid container>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Contabilizado"
                        items={yn}
                        value={values.contabilizado}
                        onChange={(v) => setFieldValue('contabilizado', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <InputMask
                        name="dtlanc"
                        label="Data"
                        mask="99/99/9999"
                        value={values.dtlanc || ''}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      className={classes.grid}
                    >
                      <SelectAuto
                        label="Entidade / Plano"
                        items={items[0] || []}
                        value={values.planoentidade_id}
                        onChange={(v) => {
                          setFieldValue('planoentidade_id', v);
                          getConta(v?.plano_id);
                        }}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Conta Débito"
                        items={contas[0] || []}
                        value={values.ctadeb_id}
                        onChange={(v) => setFieldValue('ctadeb_id', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="Conta Crédito"
                        items={contas[1] || []}
                        value={values.ctacred_id}
                        onChange={(v) => setFieldValue('ctacred_id', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="valor"
                        label="Valor"
                        value={values.valor || ''}
                        onChange={handleChange}
                        onBlur={(e) => setDecimal(e?.target?.value, 2)}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="historico"
                        label="Histórico"
                        value={values.historico || ''}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="documento"
                        label="Documento"
                        value={values.documento || ''}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="C. Custo Débito"
                        items={items[1] || []}
                        value={values.ccustodeb_id}
                        onChange={(v) => setFieldValue('ccustodeb_id', v)}
                      />
                    </Grid>
                    <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                      <SelectAuto
                        label="C. Custo Crédito"
                        items={items[1] || []}
                        value={values.ccustocred_id}
                        onChange={(v) => setFieldValue('ccustocred_id', v)}
                      />
                    </Grid>
                  </Grid>
                  <div className="d-flex justify-content-end w-100">
                    <Button
                      color="primary"
                      onClick={submitForm}
                      variant="contained"
                      disabled={saveLoading || editLoading}
                    >
                      Finalizar
                    </Button>
                  </div>
                </>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    );
  }
};

export default withStyles(styles)(Manutenção);
