import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { transformData, setDecimal } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Paper,
} from '@material-ui/core';
import { Creators as ProdutoActions } from 'store/ducks/Produto';

const styles = () => ({
  gridItens: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.5rem',
  },
  itensText: {
    margin: 0,
  },
  itensTitleText: {
    fontWeight: 'bold',
    margin: 0,
  },
  itemPaper: {
    display: 'flex',
    flex: 1,
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
  button: {
    margin: '0 5px',
  },
});

const AttPrecoModal = ({ open, setOpen, selected, custo, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { postPrecosLoading } = useSelector(({ produto }) => produto);

  useEffect(() => {
    if (open) {
      setItems(
        selected?.map((s) => ({
          descricao: s?.descricao,
          produto_id: s?.id,
          preco: setDecimal(s?.preco, 2),
          custo: setDecimal(s?.custo, 2),
          margem: setDecimal(s?.margem, 2),
        }))
      );
    } else {
      setItems([]);
    }
  }, [open]);

  const onSubmit = () => {
    const data = {
      Produtos: custo
        ? items.map((m) => ({
            produto_id: m?.produto_id,
            custo: transformData(m?.custo, 'number'),
            margem: transformData(m?.margem, 'number'),
          }))
        : items.map((m) => ({
            produto_id: m?.produto_id,
            preco: transformData(m?.preco, 'number'),
          })),
    };
    dispatch(ProdutoActions.postPrecos(data));
  };

  const getMargem = (item) => {
    if (!transformData(item?.preco, 'number')) {
      return 0;
    }
    return (
      ((transformData(item?.preco, 'number') -
        transformData(item?.custo, 'number')) /
        transformData(item?.preco, 'number')) *
      100
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Atualizar {custo ? 'Custos' : 'Preços'}</DialogTitle>
      <DialogContent>
        {items.map((i, index) => (
          <Paper className={classes.itemPaper} elevation={5}>
            <Grid container>
              <Grid xs="6" sm="6" md="6" lg="6" className={classes.gridItens}>
                <h3 className={classes.itensTitleText}>{i?.descricao}</h3>
                <h5 className={classes.itensText}>
                  Custo: {setDecimal(i?.custo, 2)} - Margem:{' '}
                  {setDecimal(getMargem(i), 2)}
                </h5>
              </Grid>
              <Grid xs="2" sm="2" md="2" lg="2" className={classes.gridItens}>
                {custo ? (
                  <TextField
                    label="Custo"
                    value={i?.custo}
                    onChange={(e) => {
                      const newValues = [...items];
                      newValues[index].custo = e?.target?.value;
                      setItems(newValues);
                    }}
                    onBlur={(e) => {
                      const newValues = [...items];
                      newValues[index].custo = setDecimal(e?.target?.value, 2);
                      setItems(newValues);
                    }}
                    fullWidth
                  />
                ) : (
                  <TextField
                    label="Preço"
                    value={i?.preco}
                    onChange={(e) => {
                      const newValues = [...items];
                      newValues[index].preco = e?.target?.value;
                      setItems(newValues);
                    }}
                    onBlur={(e) => {
                      const newValues = [...items];
                      newValues[index].preco = setDecimal(e?.target?.value, 2);
                      setItems(newValues);
                    }}
                    fullWidth
                  />
                )}
              </Grid>

              <Grid xs="2" sm="2" md="2" lg="2" className={classes.gridItens}>
                {custo ? (
                  <TextField
                    label="Margem"
                    value={i?.margem}
                    onChange={(e) => {
                      const newValues = [...items];
                      newValues[index].margem = e?.target?.value;
                      setItems(newValues);
                    }}
                    onBlur={(e) => {
                      const newValues = [...items];
                      newValues[index].margem = setDecimal(e?.target?.value, 2);
                      setItems(newValues);
                    }}
                    fullWidth
                  />
                ) : (
                  ''
                )}
              </Grid>

              <Grid
                xs="2"
                sm="2"
                md="2"
                lg="2"
                className={classes.gridItens}
                style={{ alignItems: 'flex-end' }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={() =>
                    setItems(
                      items?.filter((f) => f?.produto_id !== i?.produto_id)
                    )
                  }
                >
                  EXCLUIR
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onSubmit}
          disabled={postPrecosLoading}
        >
          CONFIRMAR
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => setOpen(!open)}
        >
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(AttPrecoModal);
