import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardContent,
  Tooltip,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import api from 'util/api';
import { setDecimal, transformData, useQuery } from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const ManutençãoSql = ({ match, classes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery(useLocation);
  const [items, setItems] = useState([]);
  const [campos, setCampos] = useState([]);
  const [valores, setValores] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    headerData,
    saveLoading,
    saveSuccess,
    data,
    editLoading,
    editSuccess,
  } = useSelector(({ tables }) => tables);
  const {
    authUser: { storedId, storedName },
  } = useSelector(({ auth }) => auth);
  const param = query.get('id');
  const codigo = query.get('cod');

  async function loadItems(ids) {
    const promises = ids.map(async (id) => {
      if (id !== null) {
        const { data } = await api.post(`/csql/${id}`, {
          Parametros: [
            {
              nome: ':id',
              valor: codigo,
            },
          ],
        });
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setItems(results);
    setLoading(false);
  }

  function getData() {
    let idAlt = null;
    headerData.map((h) => {
      let campo = h.itens.find((i) => i?.parametro === 'SIM')?.campo;
      if (campo) {
        idAlt = campo;
      }
    });
    let finder = data.find(
      (d) => d?.id === Number(param) || d?.[idAlt] === Number(param)
    );
    for (var property in finder) {
      headerData.map((h) => {
        h.itens.map((i) => {
          if (i.coluna === property && i.tipo === 'DATA') {
            if (i.formato === 'dd/mm/yyyy hh:mm:ss') {
              finder[property] = moment(
                finder[property],
                'YYYY-MM-DD HH:mm:ss'
              ).isValid()
                ? moment(finder[property], 'YYYY-MM-DD HH:mm:ss').format(
                    'DD/MM/YYYY HH:mm:ss'
                  )
                : null;
            } else {
              finder[property] = moment(
                finder[property],
                'YYYY-MM-DD'
              ).isValid()
                ? moment(finder[property], 'YYYY-MM-DD').format('DD/MM/YYYY')
                : null;
            }
          }
          if (i.coluna === property && i.tipo === 'NUMERO') {
            if (i.formato && i.formato !== '') {
              if (i.formato === ',0.0') {
                finder[property] = setDecimal(finder[property], 1);
              }
              if (
                i.formato === ',0.00' ||
                i.formato === '0,00' ||
                i.formato === ',0.##' ||
                i.formato === '0,##'
              ) {
                finder[property] = setDecimal(finder[property], 2);
              }
              if (i.formato === ',0.###' || i.formato === ',0.000') {
                finder[property] = setDecimal(finder[property], 3);
              }
              if (
                i.formato === ',0.00##' ||
                i.formato === ',0.####' ||
                i.formato === ',0.0000'
              ) {
                finder[property] = setDecimal(finder[property], 4);
              }
              if (
                i.formato === ',0.######' ||
                i.formato === ',0.00####' ||
                i.formato === ',0.000000'
              ) {
                finder[property] = setDecimal(finder[property], 6);
              }
              if (i.formato === ',0.#########') {
                finder[property] = setDecimal(finder[property], 9);
              }
            }
          }
        });
      });
    }
    return finder;
  }

  useEffect(() => {
    if (saveSuccess) {
      dispatch(TablesActions.saveDataReset());
    }
    if (editSuccess) {
      dispatch(TablesActions.editDataReset());
      history.goBack();
    }
  }, [saveSuccess, editSuccess]);

  useEffect(() => {
    var fields = [];
    var ids = [];
    let values = {};

    headerData.map((h) => {
      h.itens.map((i) => {
        if (i.manutencao === 'SIM') fields.push({ ...i, grupo: h.grupo });
      });
    });
    fields.map((i) => {
      if (i.parametro === 'SIM') {
        values[`${i.coluna}`] = codigo || i.conteudoi;
      } else {
        if (i.conteudoi === 'DATAHORA') {
          values[`${i.coluna}`] = moment().format('DD/MM/YYYY HH:mm:ss');
        } else if (i.conteudoi === 'USUARIO') {
          values[`${i.coluna}`] = { value: storedId, label: storedName };
        } else {
          values[`${i.coluna}`] = i.conteudoi;
        }
      }
      if (i.pesquisa_id && i.pesquisa_id !== null) {
        ids.push(i.pesquisa_id);
      } else {
        ids.push(null);
      }
    });

    if (param) {
      const item = getData();
      setValores(item);
    } else {
      setValores(values);
    }
    setCampos(fields);
    loadItems(ids);
  }, []);

  function setDropDown(value) {
    let parseData = [];
    if (value) {
      value.map((i) => {
        parseData.push({
          value: i,
          label: i,
        });
      });
    }
    return parseData;
  }

  const replaceConteudo = (conteudo, arr) => {
    if (arr) {
      return arr.find(
        (i) => i.value === conteudo || i.value === conteudo?.value
      );
    }
    return conteudo;
  };

  function setFormats(setFieldValue, field, formato, value) {
    if (formato && formato !== '') {
      if (formato === ',0.0') {
        setFieldValue(field, setDecimal(value, 1));
      }
      if (
        formato === ',0.00' ||
        formato === '0,00' ||
        formato === ',0.##' ||
        formato === '0,##'
      ) {
        setFieldValue(field, setDecimal(value, 2));
      }
      if (formato === ',0.###' || formato === ',0.000') {
        setFieldValue(field, setDecimal(value, 3));
      }
      if (
        formato === ',0.00##' ||
        formato === ',0.####' ||
        formato === ',0.0000'
      ) {
        setFieldValue(field, setDecimal(value, 4));
      }
      if (
        formato === ',0.######' ||
        formato === ',0.00####' ||
        formato === ',0.000000'
      ) {
        setFieldValue(field, setDecimal(value, 6));
      }
      if (formato === ',0.#########') {
        setFieldValue(field, setDecimal(value, 9));
      }
    }
  }

  function onSubmit(values) {
    let form = {};
    for (var property in values) {
      headerData.map((h) => {
        h.itens.map((i) => {
          if (
            i.coluna === property &&
            i.tipo === 'NUMERO' &&
            values[property]
          ) {
            form[property] = transformData(values[property], 'number');
          } else if (i.coluna === property && i.tipo === 'DATA') {
            if (i.formato === 'dd/mm/yyyy hh:mm:ss') {
              form[property] = moment(
                values[property],
                'DD/MM/YYYY HH:mm:ss'
              ).isValid()
                ? moment(values[property], 'DD/MM/YYYY HH:mm:ss').format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                : null;
            } else {
              form[property] = moment(values[property], 'DD/MM/YYYY').isValid()
                ? moment(values[property], 'DD/MM/YYYY').format('YYYY-MM-DD')
                : null;
            }
          } else if (typeof values[property] === 'object') {
            form[property] = values[property]?.value || null;
          } else if (i.coluna === property) {
            form[property] = values[property];
          }
        });
      });
      if (!campos.some((i) => i.coluna === property)) {
        form[property] = undefined;
      }
    }

    if (param) {
      dispatch(
        TablesActions.editData({
          table: headerData[0]?.tabela,
          campos: form,
          id: param,
        })
      );
    } else {
      dispatch(
        TablesActions.saveData({
          table: headerData[0]?.tabela,
          campos: form,
        })
      );
    }
  }

  const isMultiple = (value) => {
    if (
      value?.coluna === 'csql' ||
      value?.coluna === 'condicao' ||
      value?.coluna === 'ordenacao'
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="app-wrapper">
      <HeaderPage
        title={`${param ? 'Editar' : 'Novo'} Registro`}
        history={history}
      />
      <Card className="shadow">
        <CardContent>
          {loading || valores === null || saveSuccess ? (
            <Loading />
          ) : (
            <Formik
              initialValues={valores}
              onSubmit={(values) => onSubmit(values)}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <>
                  {campos.map((c, index) => {
                    if (c.tipo === 'ENUM') {
                      return (
                        <>
                          {c.dica ? (
                            <Grid
                              xs="12"
                              sm="12"
                              md="12"
                              lg="12"
                              className={classes.grid}
                            >
                              <Tooltip title={c?.dica} placement="top">
                                <SelectAuto
                                  label={
                                    c.grupo !== '' ? `${c.grupo}` : c.titulo
                                  }
                                  items={setDropDown(c.padrao)}
                                  value={replaceConteudo(
                                    values[c.coluna],
                                    setDropDown(c.padrao)
                                  )}
                                  onChange={(v) =>
                                    setFieldValue(`${c.coluna}`, v)
                                  }
                                  disabled={
                                    (param && c.parametro === 'SIM') ||
                                    (codigo && c.parametro === 'SIM')
                                  }
                                />
                              </Tooltip>
                            </Grid>
                          ) : (
                            <Grid
                              xs="12"
                              sm="12"
                              md="12"
                              lg="12"
                              className={classes.grid}
                            >
                              <SelectAuto
                                label={c.grupo !== '' ? `${c.grupo}` : c.titulo}
                                items={setDropDown(c.padrao)}
                                value={replaceConteudo(
                                  values[c.coluna],
                                  setDropDown(c.padrao)
                                )}
                                onChange={(v) =>
                                  setFieldValue(`${c.coluna}`, v)
                                }
                                disabled={
                                  (param && c.parametro === 'SIM') ||
                                  (codigo && c.parametro === 'SIM')
                                }
                              />
                            </Grid>
                          )}
                        </>
                      );
                    }
                    if (c.pesquisa_id && c.pesquisa_id !== null) {
                      return (
                        <>
                          {c.dica ? (
                            <Grid
                              xs="12"
                              sm="12"
                              md="12"
                              lg="12"
                              className={classes.grid}
                            >
                              <Tooltip title={c?.dica} placement="top">
                                <SelectAuto
                                  label={
                                    c.grupo !== '' ? `${c.grupo}` : c.titulo
                                  }
                                  items={items[index] || []}
                                  value={replaceConteudo(
                                    values[c.coluna],
                                    items[index]
                                  )}
                                  onChange={(v) =>
                                    setFieldValue(`${c.coluna}`, v)
                                  }
                                  disabled={
                                    (param && c.parametro === 'SIM') ||
                                    (codigo && c.parametro === 'SIM')
                                  }
                                />
                              </Tooltip>
                            </Grid>
                          ) : (
                            <Grid
                              xs="12"
                              sm="12"
                              md="12"
                              lg="12"
                              className={classes.grid}
                            >
                              <SelectAuto
                                label={c.grupo !== '' ? `${c.grupo}` : c.titulo}
                                items={items[index] || []}
                                value={replaceConteudo(
                                  values[c.coluna],
                                  items[index]
                                )}
                                onChange={(v) =>
                                  setFieldValue(`${c.coluna}`, v)
                                }
                                disabled={
                                  (param && c.parametro === 'SIM') ||
                                  (codigo && c.parametro === 'SIM')
                                }
                              />
                            </Grid>
                          )}
                        </>
                      );
                    }
                    if (c.tipo === 'DATA') {
                      if (c.formato === 'dd/mm/yyyy hh:mm:ss') {
                        return (
                          <>
                            {c.dica ? (
                              <Grid
                                xs="12"
                                sm="12"
                                md="12"
                                lg="12"
                                className={classes.grid}
                              >
                                <Tooltip title={c?.dica} placement="top">
                                  <InputMask
                                    name={c.coluna}
                                    label={
                                      c.grupo !== '' ? `${c.grupo}` : c.titulo
                                    }
                                    mask="99/99/9999 99:99:99"
                                    value={values[c.coluna]}
                                    onChange={handleChange}
                                    disabled={
                                      (param && c.parametro === 'SIM') ||
                                      (codigo && c.parametro === 'SIM')
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                            ) : (
                              <Grid
                                xs="12"
                                sm="12"
                                md="12"
                                lg="12"
                                className={classes.grid}
                              >
                                <InputMask
                                  name={c.coluna}
                                  label={
                                    c.grupo !== '' ? `${c.grupo}` : c.titulo
                                  }
                                  mask="99/99/9999 99:99:99"
                                  value={values[c.coluna]}
                                  onChange={handleChange}
                                  disabled={
                                    (param && c.parametro === 'SIM') ||
                                    (codigo && c.parametro === 'SIM')
                                  }
                                />
                              </Grid>
                            )}
                          </>
                        );
                      } else {
                        return (
                          <>
                            {c.dica ? (
                              <Grid
                                xs="12"
                                sm="12"
                                md="12"
                                lg="12"
                                className={classes.grid}
                              >
                                <Tooltip title={c?.dica} placement="top">
                                  <InputMask
                                    name={c.coluna}
                                    label={
                                      c.grupo !== '' ? `${c.grupo}` : c.titulo
                                    }
                                    mask="99/99/9999"
                                    value={values[c.coluna]}
                                    onChange={handleChange}
                                    disabled={
                                      (param && c.parametro === 'SIM') ||
                                      (codigo && c.parametro === 'SIM')
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                            ) : (
                              <Grid
                                xs="12"
                                sm="12"
                                md="12"
                                lg="12"
                                className={classes.grid}
                              >
                                <InputMask
                                  name={c.coluna}
                                  label={
                                    c.grupo !== '' ? `${c.grupo}` : c.titulo
                                  }
                                  mask="99/99/9999"
                                  value={values[c.coluna]}
                                  onChange={handleChange}
                                  disabled={
                                    (param && c.parametro === 'SIM') ||
                                    (codigo && c.parametro === 'SIM')
                                  }
                                />
                              </Grid>
                            )}
                          </>
                        );
                      }
                    }
                    return (
                      <>
                        {c.dica ? (
                          <Grid
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            className={classes.grid}
                          >
                            <Tooltip title={c?.dica} placement="top">
                              <TextField
                                name={c.coluna}
                                label={c.grupo !== '' ? `${c.grupo}` : c.titulo}
                                className="w-100"
                                value={values[c.coluna]}
                                onChange={handleChange}
                                disabled={
                                  (param && c.parametro === 'SIM') ||
                                  (codigo && c.parametro === 'SIM')
                                }
                                multiline={isMultiple(c)}
                                rows={5}
                                variant={isMultiple(c) ? 'outlined' : undefined}
                              />
                            </Tooltip>
                          </Grid>
                        ) : (
                          <Grid
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            className={classes.grid}
                          >
                            <TextField
                              name={c.coluna}
                              label={c.grupo !== '' ? `${c.grupo}` : c.titulo}
                              className="w-100"
                              value={values[c.coluna]}
                              onChange={handleChange}
                              onBlur={(e) =>
                                setFormats(
                                  setFieldValue,
                                  c.coluna,
                                  c.formato,
                                  e.target.value
                                )
                              }
                              disabled={
                                (param && c.parametro === 'SIM') ||
                                (codigo && c.parametro === 'SIM')
                              }
                              multiline={isMultiple(c)}
                              rows={5}
                              variant={isMultiple(c) ? 'outlined' : undefined}
                            />
                          </Grid>
                        )}
                      </>
                    );
                  })}
                  <div className="d-flex flex-row justify-content-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      className="jr-btn"
                      onClick={handleSubmit}
                      disabled={saveLoading || editLoading}
                    >
                      Salvar
                    </Button>
                  </div>
                </>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(ManutençãoSql);
