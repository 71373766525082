import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as FiscalActions } from '../../../store/ducks/Fiscal';
import { Creators as TablesActions } from '../../../store/ducks/Tables';

const styles = () => ({
  paper: {
    minWidth: '50%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const ImportIbptModal = ({ open, setOpen, rotina, classes }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [uf, setUf] = useState();
  const [items, setItems] = useState([]);
  const { importSuccess, importLoading } = useSelector(({ fiscal }) => fiscal);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([625]);
    }
  }, [open]);

  useEffect(() => {
    if (importSuccess) {
      dispatch(FiscalActions.importIbptReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [importSuccess]);

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('ufdest_id', uf?.value || 0);
    dispatch(FiscalActions.importIbpt(formData));
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Importar Tabela IBPT</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="UF"
              items={items[0] || []}
              value={uf}
              onChange={(v) => setUf(v)}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12">
            <input
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              className="w-100"
              onChange={(e) => onImageChange(e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmit()}
          color="primary"
          disabled={importLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ImportIbptModal);
