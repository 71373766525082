import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputMask from 'components/InputMask';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import api from 'util/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';

const AjusteFormaPgtoModal = ({ open, setOpen, filtros, rotina, selected }) => {
  const dispatch = useDispatch();
  const [forma, setForma] = useState(null);
  const [dtVenc, setDtVenc] = useState('');
  const [observacao, setObservacao] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { putFinFormaLoading, putFinFormaSuccess } = useSelector(
    ({ lancamento }) => lancamento
  );

  useEffect(() => {
    if (putFinFormaSuccess) {
      dispatch(LancamentoActions.putFinFormaReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [putFinFormaSuccess]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      const financeiro = filtros.find((f) => f.titulo === 'Financeiro');
      let sql = 646;
      if (financeiro?.conteudoi?.includes('CHEQUE')) {
        sql = 749;
      } else if (financeiro?.conteudoi?.includes('PAGAMENTOS')) {
        sql = 647;
      }
      loadItems([sql]);
    }
  }, [open]);

  const onSubmit = () => {
    const situacao = filtros.find((f) => f.titulo === 'Situação');
    const Financeiro = selected.map((s) => ({ financeiro_id: s?.id }));
    const data = {
      situacao: situacao?.conteudoi?.replace(/'/g, ''),
      forma_pagto_id: forma?.value,
      dtvenc: moment(dtVenc, 'DD/MM/YYYY').isValid()
        ? moment(dtVenc, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      observacao: observacao || undefined,
      Financeiro,
    };
    dispatch(LancamentoActions.putFinForma(data));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Alterar Dados</DialogTitle>
      {loading || putFinFormaLoading ? (
        <Loading />
      ) : (
        <DialogContent>
          <SelectAuto
            label="Forma de Pagamento"
            items={items[0] || []}
            value={forma}
            onChange={(v) => setForma(v)}
            style={{
              padding: '0 0.5rem',
              marginBottom: '1rem',
            }}
          />
          <InputMask
            name="dtVenc"
            label="Data de Vencimento"
            mask="99/99/9999"
            value={dtVenc}
            onChange={(e) => setDtVenc(e.target.value)}
            fullWidth
            style={{
              padding: '0 0.5rem',
              marginBottom: '1rem',
              width: '100%',
            }}
          />
          <TextField
            label="Observação"
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
            fullWidth
            style={{
              padding: '0 0.5rem',
              marginBottom: '1rem',
            }}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onSubmit()}
          color="primary"
          disabled={putFinFormaLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AjusteFormaPgtoModal;
