import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  InputLabel,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import api from 'util/api';

const FiscalModal = ({ open, setOpen, setCFiscal }) => {
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);

  async function loadItems() {
    const { data } = await api.post(`/csql/654`, {
      Parametros: [{ nome: 'likevalue', valor: search }],
    });
    if (data) {
      setItems(data.dados || []);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(!open)} fullWidth>
      <DialogTitle>Pesquisar</DialogTitle>
      <DialogContent>
        <div className="d-flex align-items-end mb-4">
          <TextField
            name="search"
            label="Classificação Fiscal"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            className="ml-2"
            onClick={() => loadItems()}
            disabled={search === ''}
          >
            <SearchIcon />
          </Button>
        </div>
        {items.length > 0 && (
          <>
            <DialogContentText>Resultados encontrados:</DialogContentText>

            {items.map((i) => (
              <div className="d-flex align-items-center py-3">
                <Checkbox
                  name={i.value}
                  id={i.value}
                  color="primary"
                  checked={Number(selected?.value) === i.value}
                  value={i.value}
                  style={{ padding: '0 10px 0 0' }}
                  onChange={(e) => setSelected(i)}
                />
                <InputLabel htmlFor={i.value}>{i.label}</InputLabel>
              </div>
            ))}
          </>
        )}
      </DialogContent>
      {items.length > 0 && (
        <DialogActions>
          <div className="d-flex flex-column align-items-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCFiscal(selected);
                setOpen(!open);
              }}
            >
              SELECIONAR
            </Button>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default FiscalModal;
