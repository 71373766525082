import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DocAprovacaoModal from 'components/Modals/DocAprovacaoModal';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import FilterReportModal from 'components/Modals/FilterReportModal';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import DocStatusModal from 'components/Modals/DocStatusModal';
import DocEmissaoModal from 'components/Modals/DocEmissaoModal';
import ExportModal from 'components/Modals/ExportModal';
import ResumoModal from 'components/Modals/ResumoModal';
import ListaPedOrcModal from 'components/Modals/ListaPedOrcModal';
import FaturarModal from 'components/Modals/FaturarModal';
import {
  checkManutencao,
  checkAgrupamento,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import api from 'util/api';
import { Creators as MidiaActions } from 'store/ducks/Midia';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ResumoActions } from 'store/ducks/Resumo';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import moment from 'moment';

const FaturamentosLiberados = ({ match }) => {
  const rotina = 819;
  const csql = 984;
  const [relatorio, setRelatorio] = useState(null);
  const title = 'Documentos liberados para Faturamento';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const [search, setSearch] = useState('');
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  const [multiSelected, setMultiSelected] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [docAprovacaoModal, setDocAprovacaoModal] = useState(false);
  const [docEmissaoModal, setDocEmissaoModal] = useState(false);
  const [docStatusModal, setDocStatusModal] = useState(false);
  const [orcPedModal, setOrcPedModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importDocLoading, setImportDocLoading] = useState(false);
  const [faturarModal, setFaturarModal] = useState(false);
  const param = query.get('id');
  const nameParam = query.get('name');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);
  const { authUser } = useSelector(({ auth }) => auth);
  const {
    aprovSuccess,
    idRegistred,
    emissao,
    postNfePreview,
    postDocumentoGerarSuccess,
  } = useSelector(({ documento }) => documento);

  const onImportDoc = async (doc, especie_id) => {
    try {
      setImportDocLoading(true);
      const form = {
        especie_id,
        dhsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
        Documentos: [doc?.id],
        emitir: 'NAO',
      };
      const { data } = await api.post(`/documento-gerar`, form);
      if (data && data?.documento_id) {
        history.push(
          `/app/Comercial/Atendimento/Vendas?id=${data?.documento_id}`,
          {
            state: {
              backTo: 'Faturamentos',
            },
          }
        );
      }
    } catch (error) {
    } finally {
      setImportDocLoading(false);
    }
  };

  const gerarDocumento = () => {
    setRedirect(true);
    const data = {
      Documento: {
        cadastro_id: Number(param),
        contribuinte_id: 1,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        especie_id: authUser?.storedEspecieId,
        filial_id: authUser?.storedFilialId,
        natureza_operacao_id: authUser?.storedNatureza_operacaoId,
        usofinal: 'NAO',
      },
      Saida: {
        vendedor_id: authUser?.storedEntidadeId,
        condicao_pagamento_id: 1,
      },
    };
    dispatch(DocumentoActions.postCapa(data));
  };

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = filtros.map((f) => {
        if (f.parametro === 'SIM') {
          return { ...f, conteudoi: param, conteudod: nameParam };
        }
        return f;
      });
      return filterWithParam;
    }
    return filtros;
  }

  function getTotais() {
    const newParams = getWhereClause({
      filtros: setParamOnFilter(),
      param: true,
    });

    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            Parametros: [{ nome: 'likevalue', valor: search }, ...newParams],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      dispatch(ResumoActions.setFilterResumo(setParamOnFilter()));
      const newParams = getWhereClause({
        filtros: setParamOnFilter(),
        param: true,
      });

      dispatch(
        TablesActions.getDataTable({
          csql,
          params: {
            Parametros: [{ nome: 'likevalue', valor: search }, ...newParams],
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
            OrderClause,
            Pagina: paginacao ? Pagina : undefined,
            PorPagina: paginacao ? PorPagina : undefined,
          },
        })
      );
    }
  }

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  useEffect(() => {
    if (aprovSuccess) {
      dispatch(DocumentoActions.postAprovacaoReset());
      getDataTable();
    }
  }, [aprovSuccess]);

  useEffect(() => {
    if (relatorio) {
      dispatch(TablesActions.getReport(relatorio));
    }
  }, [relatorio]);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length && param) {
      dispatch(TablesActions.setFiltros(setParamOnFilter()));
    } else {
      if (headerData.length) {
        getDataTable();
      }
    }
  }, [headerData, Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (headerDataStore.length) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  useEffect(() => {
    if (idRegistred) {
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
      if (redirect) {
        history.push(`/app/Comercial/Atendimento/Vendas?id=${idRegistred}`, {
          state: {
            backTo: 'Faturamentos',
          },
        });
      } else {
        dispatch(TablesActions.getHeader(rotina));
      }
    }
  }, [idRegistred]);

  useEffect(() => {
    if (emissao) {
      if (Boolean(emissao?.midiaurl)) {
        window.open(emissao?.midiaurl);
      }
      if (Boolean(emissao?.midiaid)) {
        dispatch(MidiaActions.deleteMidia(emissao?.midiaid));
      }
      if (!!emissao?.financeiro_id?.length) {
        history.push(`/app/Finalizar_Venda`, {
          state: {
            financeiro_id: emissao?.financeiro_id,
            operacao: 'RECEBIMENTOS',
            backTo: 'Faturamentos',
          },
        });
      }
      if (emissao?.formato_saida === 'mail') {
        if (emissao?.metodo_email === 'LOCAL') {
          let redirect = `mailto:${emissao?.email?.replace(/;/g,'%3B')}?`;
          if (emissao?.assunto) {
            redirect = redirect + `subject=${emissao?.assunto}&`;
          }
          if (emissao?.body) {
            redirect = redirect + `body=${emissao?.body}&`;
          }
          window.open(redirect);
        } else {
          dispatch(
            DocumentoActions.sendMail({
              para: emissao?.email,
              assunto: emissao?.assunto,
              msg: emissao?.body,
            })
          );
        }
      }

      dispatch(TablesActions.getHeader(rotina));
      dispatch(DocumentoActions.postEmissaoReset());
    }
  }, [emissao]);

  useEffect(() => {
    if (postNfePreview) {
      const file = new Blob([postNfePreview], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatch(DocumentoActions.postNfeReset());
    }
  }, [postNfePreview]);

  useEffect(() => {
    if (postDocumentoGerarSuccess) {
      setFaturarModal(false);
      dispatch(DocumentoActions.postDocumentoGerarReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postDocumentoGerarSuccess]);

  const buttonActions = [
    {
      name: 'NF-e',
      type: 'Adicionar',
      action: () =>
        history.push(`/app/Comercial/Atendimento`, {
          state: {
            backTo: 'Faturamentos',
            especie: 10,
            natureza: 6,
          },
        }),
      visible: true,
    },
    {
      name: 'Fatura',
      type: 'Adicionar',
      action: () =>
        history.push(`/app/Comercial/Atendimento`, {
          state: {
            backTo: 'Faturamentos',
            especie: 25,
            natureza: 6,
          },
        }),
      visible: true,
    },
    {
      name: 'Consumidor',
      type: 'Adicionar',
      action: () =>
        history.push(`/app/Comercial/Atendimento`, {
          state: {
            backTo: 'Faturamentos',
            especie: 50,
            natureza: 6,
          },
        }),
      visible: true,
    },
    {
      name: 'Devolução',
      type: 'Limpar',
      action: () =>
        history.push(`/app/Comercial/Atendimento`, {
          state: {
            backTo: 'Faturamentos',
            especie: 25,
            natureza: 3,
          },
        }),
      visible: true,
    },
    {
      name: 'Importar Orçamento / Pedido',
      type: 'Recebimento',
      action: () => {
        setOrcPedModal(true);
      },
      visible: true,
    },
    // {
    //   name: 'Recebimento',
    //   type: 'Recebimento',
    //   action: () => {
    //     const financeiro_id = multiSelected
    //       ?.filter((f) => Boolean(f?.financeiro_id))
    //       ?.map((m) => m?.financeiro_id);
    //     history.push(`/app/Finalizar_Venda`, {
    //       state: {
    //         financeiro_id,
    //         operacao: 'RECEBIMENTOS',
    //         backTo: 'Faturamentos',
    //       },
    //     });
    //   },
    //   visible: true,
    // },
    {
      name: 'Recebimentos (Baixar financeiro)',
      type: 'Recebimento',
      action: () => history.push(`/app/Financeiro/Pendências`),
      visible: true,
    },
    {
      name: 'Lançamento no caixa',
      type: 'Preço',
      action: () =>
        history.push(`/app/Novo_Lancamento?conta=${authUser?.storedCxContaId}`),
      visible: true,
    },
    {
      name: 'Relatório de Caixa',
      type: 'Imprimir',
      action: () => {
        setRelatorio(811);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Resumo do Caixa',
      type: 'Imprimir',
      action: () => {
        setRelatorio(670);
        setFilterReportModal(!filterReportModal);
      },
      visible: true,
    },
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => setFilterModal(!filterModal),
      visible: filtros?.length,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: checkAgrupamento(headerData),
    },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
        {
          name: 'Documento',
          action: (item) =>
            history.push(`/app/Cadastros/Documento?id=${item?.id}`),
          visible: true,
        },
        {
          name: 'Entidade',
          varName: 'Terceiros',
          action: (item) =>
            history.push(
              `/app/Cadastros/Entidades/Manutenção?id=${item?.cadastro_id}`
            ),
          visible: true,
        },
        // {
        //   name: 'Aprovação do documento',
        //   varName: 'Aprovação',
        //   action: (item) => {
        //     setSelected(item);
        //     setDocAprovacaoModal(!docAprovacaoModal);
        //   },
        //   funcVis: (item) => item?.motivo,
        //   visible: true,
        // },
        {
          name: 'Status do documento',
          varName: 'DocStatus',
          action: (item) => {
            setSelected(item);
            setDocStatusModal(!docStatusModal);
          },
          visible: true,
        },
        {
          name: 'Anotações',
          varName: 'Aprovação',
          action: (item) =>
            history.push(
              `/app/Suprimentos/GestãodeCompras/Registros?id=${item?.id}&name=${item.id}' - '${item.entidade}`
            ),
          visible: true,
        },
        {
          name: 'CF-e',
          varName: 'Gerar',
          action: (item) => {
            dispatch(
              DocumentoActions.postDocumentoGerar({
                especie_id: 26,
                dhsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
                Documentos: [item?.id],
                emitir: 'NAO',
              })
            );
          },
          funcVis: (item) => item?.natureza === 'VENDA',
        },
        {
          name: 'NF-e',
          varName: 'Gerar',
          action: (item) => {
            dispatch(
              DocumentoActions.postDocumentoGerar({
                especie_id: 10,
                dhsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
                Documentos: [item?.id],
                emitir: 'NAO',
              })
            );
          },
          funcVis: (item) => item?.natureza === 'VENDA',
        },
        {
          name: 'Fatura',
          varName: 'Gerar',
          action: (item) => {
            dispatch(
              DocumentoActions.postDocumentoGerar({
                especie_id: 25,
                dhsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
                Documentos: [item?.id],
                emitir: 'NAO',
              })
            );
          },
          funcVis: (item) => item?.natureza === 'VENDA',
        },
        {
          name: 'Faturar',
          varName: 'Gerar',
          action: (item) => {
            setSelected(item);
            setFaturarModal(true);
          },
          funcVis: (item) => item?.natureza === 'SERVICO',
        },
        {
          name: 'Emissão do Documento',
          varName: 'Concluir',
          action: (item) => {
            setSelected(item);
            setDocEmissaoModal(!docEmissaoModal);
          },
          funcVis: (item) => item?.natureza === 'FATURA',
        },
        // {
        //   name: 'Receber o documento',
        //   varName: 'Gerar',
        //   action: async (item) => {
        //     try {
        //       setLoading(true);
        //       const { data } = await api.post(
        //         `/documento-finalizaatend/${item?.documento_id}`
        //       );
        //       if (data && data?.financeiro_id) {
        //         history.push(`/app/Finalizar_Venda`, {
        //           state: {
        //             financeiro_id: data?.financeiro_id,
        //             operacao: 'RECEBIMENTO',
        //             transmiteaut: item?.transmiteaut,
        //             backTo: 'Faturamentos',
        //           },
        //         });
        //       }
        //     } catch (error) {
        //     } finally {
        //       setLoading(false);
        //     }
        //   },
        //   visible: true,
        // },
        {
          name: 'Pre visualizar NFe',
          varName: 'Documento',
          action: (item) =>
            dispatch(DocumentoActions.postNfe(item?.documento_id)),
          funcVis: (item) => item?.modelo === 'NFe',
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                selected={multiSelected}
                setSelected={setMultiSelected}
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              {!!loading && <Loading />}
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filtros}
                reportFiltros={reportFiltros}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
              <DocAprovacaoModal
                open={docAprovacaoModal}
                setOpen={setDocAprovacaoModal}
                selected={selected}
                docId={param}
                data={data}
              />
              <DocStatusModal
                open={docStatusModal}
                setOpen={setDocStatusModal}
                selected={selected}
              />
              <DocEmissaoModal
                open={docEmissaoModal}
                setOpen={setDocEmissaoModal}
                selected={selected}
              />
              <ListaPedOrcModal
                open={orcPedModal}
                setOpen={setOrcPedModal}
                onSubmit={onImportDoc}
              />
              <FaturarModal
                open={faturarModal}
                setOpen={setFaturarModal}
                selected={
                  selected?.especie_id === 16
                    ? [selected, selected]
                    : [selected]
                }
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default FaturamentosLiberados;
