import React from 'react';
import { Dialog } from '@material-ui/core';

const VideoModal = ({ open, setOpen, selected }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(!open)} fullWidth>
      <iframe
        src={selected?.link}
        height="335px"
        frameborder="0"
        allowFullScreen
      />
    </Dialog>
  );
};
export default VideoModal;
