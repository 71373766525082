import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { transformData, setDecimal, findOnArray } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import api from 'util/api';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {},
  button: {
    margin: '0 5px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const DocCustomodal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    loadItems([632]);
  }, []);

  const SetValues = () => {
    const { setFieldValue, setValues } = useFormikContext();
    useEffect(() => {
      if (selected) {
        setValues({
          custo: findOnArray(selected?.custo, yn),
          moeda_id: findOnArray(selected?.moeda_id, items[0]),
          cotacao: selected?.cotacao ? setDecimal(selected?.cotacao, 4) : null,
        });
      }
    }, [selected]);
    return null;
  };

  function sendCusto(values) {
    const data = {
      custo: values?.custo?.value,
      moeda_id: values?.moeda_id?.value,
      cotacao: transformData(values?.cotacao, 'number'),
    };
    dispatch(DocumentoActions.postCusto({ data, id: selected?.id }));
    setOpen(!open);
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          custo: null,
          moeda_id: null,
          cotacao: null,
        }}
      >
        {({ values, handleChange, submitForm, setFieldValue }) => (
          <>
            <SetValues />
            <DialogTitle>Indicativo de custo para o documento</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Considerar documento para custo dos produtos"
                    items={yn}
                    value={values.custo}
                    onChange={(v) => setFieldValue('custo', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Moeda"
                    items={items[0] || []}
                    value={values.moeda_id}
                    onChange={(v) => setFieldValue('moeda_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="cotacao"
                    label="Cotação da Moeda em R$"
                    value={values.cotacao}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('cotacao', setDecimal(e.target.value, 4))
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row align-items-end">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => sendCusto(values)}
                >
                  REGISTRAR
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setOpen(!open)}
                >
                  CANCELAR
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(DocCustomodal);
