import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Creators as IbgeActions, Types as IbgeTypes } from 'store/ducks/Ibge';
import axios from 'axios';

function* getEstados() {
  try {
    const { status, data } = yield call(
      axios.get,
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados`
    );
    if (status === 200 || status === 201) {
      const estados = data.map((i) => {
        return { value: i.sigla, label: i.sigla };
      });
      yield put(
        IbgeActions.getEstadosSuccess(
          estados.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          )
        )
      );
    } else {
      yield put(IbgeActions.getEstadosError());
    }
  } catch (err) {
    yield put(IbgeActions.getEstadosError());
  }
}

function* getCidades({ payload }) {
  try {
    const { status, data } = yield call(
      axios.get,
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${payload.uf}/municipios`
    );
    if (status === 200 || status === 201) {
      const cidades = data.map((i) => {
        return { value: i.id, label: i.nome };
      });
      yield put(IbgeActions.getCidadesSuccess(cidades));
    } else {
      yield put(IbgeActions.getCidadesError());
    }
  } catch (err) {
    yield put(IbgeActions.getCidadesError());
  }
}

function* getCep({ payload }) {
  try {
    const { status, data } = yield call(
      axios.get,
      `https://viacep.com.br/ws/${payload}/json/`
    );
    if (status === 200 || status === 201) {
      yield put(IbgeActions.getCepSuccess(data));
    } else {
      yield put(IbgeActions.getCepError());
    }
  } catch (err) {
    yield put(IbgeActions.getCepError());
  }
}

function* getEstadosWatcher() {
  yield takeLatest(IbgeTypes.GET_ESTADOS, getEstados);
}

function* getCidadesWatcher() {
  yield takeLatest(IbgeTypes.GET_CIDADES, getCidades);
}

function* getCepWatcher() {
  yield takeLatest(IbgeTypes.GET_CEP, getCep);
}

export default function* rootSaga() {
  yield all([
    fork(getEstadosWatcher),
    fork(getCidadesWatcher),
    fork(getCepWatcher),
  ]);
}
