export const Types = {
  POST_COPIA_REGRA_FILIAL: 'POST_COPIA_REGRA_FILIAL',
  POST_COPIA_REGRA_FILIAL_SUCCESS: 'POST_COPIA_REGRA_FILIAL_SUCCESS',
  POST_COPIA_REGRA_FILIAL_RESET: 'POST_COPIA_REGRA_FILIAL_RESET',

  POST_DADOS: 'POST_DADOS',
  POST_DADOS_SUCCESS: 'POST_DADOS_SUCCESS',
  POST_DADOS_RESET: 'POST_DADOS_RESET',

  POST_CONTABIL_LANC: 'POST_CONTABIL_LANC',
  POST_CONTABIL_LANC_SUCCESS: 'POST_CONTABIL_LANC_SUCCESS',
  POST_CONTABIL_LANC_RESET: 'POST_CONTABIL_LANC_RESET',

  POST_FECHAMENTO: 'POST_FECHAMENTO',
  POST_FECHAMENTO_SUCCESS: 'POST_FECHAMENTO_SUCCESS',
  POST_FECHAMENTO_RESET: 'POST_FECHAMENTO_RESET',
};

const INIT_STATE = {
  postCopiaRegraFilialLoading: false,
  postCopiaRegraFilialSuccess: false,

  postDadosLoading: false,
  postDadosSuccess: false,

  postContabilLancLoading: false,
  postContabilLancSuccess: false,

  postFechamentoLoading: false,
  postFechamentoSuccess: false,
};

export default function Contabil(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.POST_COPIA_REGRA_FILIAL: {
      return {
        ...state,
        postCopiaRegraFilialLoading: true,
        postCopiaRegraFilialSuccess: false,
      };
    }
    case Types.POST_COPIA_REGRA_FILIAL_SUCCESS: {
      return {
        ...state,
        postCopiaRegraFilialLoading: false,
        postCopiaRegraFilialSuccess: true,
      };
    }
    case Types.POST_COPIA_REGRA_FILIAL_RESET: {
      return {
        ...state,
        postCopiaRegraFilialLoading: false,
        postCopiaRegraFilialSuccess: false,
      };
    }

    case Types.POST_DADOS: {
      return {
        ...state,
        postDadosLoading: true,
        postDadosSuccess: false,
      };
    }
    case Types.POST_DADOS_SUCCESS: {
      return {
        ...state,
        postDadosLoading: false,
        postDadosSuccess: true,
      };
    }
    case Types.POST_DADOS_RESET: {
      return {
        ...state,
        postDadosLoading: false,
        postDadosSuccess: false,
      };
    }

    case Types.POST_CONTABIL_LANC: {
      return {
        ...state,
        postContabilLancLoading: true,
        postContabilLancSuccess: false,
      };
    }
    case Types.POST_CONTABIL_LANC_SUCCESS: {
      return {
        ...state,
        postContabilLancLoading: false,
        postContabilLancSuccess: true,
      };
    }
    case Types.POST_CONTABIL_LANC_RESET: {
      return {
        ...state,
        postContabilLancLoading: false,
        postContabilLancSuccess: false,
      };
    }

    case Types.POST_FECHAMENTO: {
      return {
        ...state,
        postFechamentoLoading: true,
        postFechamentoSuccess: false,
      };
    }
    case Types.POST_FECHAMENTO_SUCCESS: {
      return {
        ...state,
        postFechamentoLoading: false,
        postFechamentoSuccess: true,
      };
    }
    case Types.POST_FECHAMENTO_RESET: {
      return {
        ...state,
        postFechamentoLoading: false,
        postFechamentoSuccess: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  postCopiaRegraFilial: (payload) => ({
    type: Types.POST_COPIA_REGRA_FILIAL,
    payload,
  }),
  postCopiaRegraFilialSuccess: () => ({
    type: Types.POST_COPIA_REGRA_FILIAL_SUCCESS,
  }),
  postCopiaRegraFilialReset: () => ({
    type: Types.POST_COPIA_REGRA_FILIAL_RESET,
  }),

  postDados: (payload) => ({
    type: Types.POST_DADOS,
    payload,
  }),
  postDadosSuccess: () => ({
    type: Types.POST_DADOS_SUCCESS,
  }),
  postDadosReset: () => ({
    type: Types.POST_DADOS_RESET,
  }),

  postContabilLanc: (payload) => ({
    type: Types.POST_CONTABIL_LANC,
    payload,
  }),
  postContabilLancSuccess: () => ({
    type: Types.POST_CONTABIL_LANC_SUCCESS,
  }),
  postContabilLancReset: () => ({
    type: Types.POST_CONTABIL_LANC_RESET,
  }),

  postFechamento: (payload) => ({
    type: Types.POST_FECHAMENTO,
    payload,
  }),
  postFechamentoSuccess: () => ({
    type: Types.POST_FECHAMENTO_SUCCESS,
  }),
  postFechamentoReset: () => ({
    type: Types.POST_FECHAMENTO_RESET,
  }),
};
