import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as ProdutoActions,
  Types as ProdutoTypes,
} from 'store/ducks/Produto';
import api from '../../util/api';

function* postProduto({ payload }) {
  try {
    const { status } = yield call(api.post, `/produto`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postProdutoSuccess());
    } else {
      yield put(ProdutoActions.postProdutoError());
    }
  } catch (error) {
    yield put(ProdutoActions.postProdutoError());
  }
}

function* putProduto({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/produto/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.putProdutoSuccess());
    } else {
      yield put(ProdutoActions.putProdutoError());
    }
  } catch (error) {
    yield put(ProdutoActions.putProdutoError());
  }
}

function* getProduto({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/produto/${payload}`);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.getProdutoSuccess(data.mproduto));
    } else {
      yield put(ProdutoActions.getProdutoError());
    }
  } catch (error) {
    yield put(ProdutoActions.getProdutoError());
  }
}

function* deleteProduto({ payload }) {
  try {
    const { status } = yield call(api.delete, `/produto/${payload}`);
    if (status === 200 || status === 201 || status === 203) {
      yield put(ProdutoActions.deleteProdutoSuccess());
    } else {
      yield put(ProdutoActions.deleteProdutoError());
    }
  } catch (error) {
    yield put(ProdutoActions.deleteProdutoError());
  }
}

function* setStatusProduto({ payload }) {
  try {
    const { status } = yield call(api.put, `/produto-ativacao/${payload}`);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.setStatusProdutoSuccess());
    } else {
      yield put(ProdutoActions.setStatusProdutoError());
    }
  } catch (error) {
    yield put(ProdutoActions.setStatusProdutoError());
  }
}

function* getDescricao({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/getTabela`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.getDescricaoSuccess(data.registros));
    } else {
      yield put(ProdutoActions.getDescricaoError());
    }
  } catch (error) {
    yield put(ProdutoActions.getDescricaoError());
  }
}

function* putDescricao({ payload }) {
  const { id, registros } = payload;
  try {
    const { status } = yield call(api.put, `/produto-descricao/${id}`, {
      registros,
    });
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.putDescricaoSuccess());
    } else {
      yield put(ProdutoActions.putDescricaoError());
    }
  } catch (error) {
    yield put(ProdutoActions.putDescricaoError());
  }
}

function* getVariacao({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/getTabela`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.getVariacaoSuccess(data.registros));
    } else {
      yield put(ProdutoActions.getVariacaoError());
    }
  } catch (error) {
    yield put(ProdutoActions.getVariacaoError());
  }
}

function* putVariacao({ payload }) {
  const { id, registros } = payload;
  try {
    const { status } = yield call(api.put, `/produto-variacao/${id}`, {
      registros,
    });
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.putVariacaoSuccess());
    } else {
      yield put(ProdutoActions.putVariacaoError());
    }
  } catch (error) {
    yield put(ProdutoActions.putVariacaoError());
  }
}

function* getMidia({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/produto-imagem/${payload}`);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.getMidiaSuccess(data.imagens || []));
    } else {
      yield put(ProdutoActions.getMidiaError());
    }
  } catch (error) {
    yield put(ProdutoActions.getMidiaError());
  }
}

function* putMidia({ payload }) {
  const { data, id } = payload;
  try {
    const { status } = yield call(api.put, `/produto-imagem/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.putMidiaSuccess());
    } else {
      yield put(ProdutoActions.putMidiaError());
    }
  } catch (error) {
    yield put(ProdutoActions.putMidiaError());
  }
}

function* getConversaoum({ payload }) {
  try {
    const { data, status } = yield call(api.post, `/csql/5`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.getConversaoumSuccess(data.dados || []));
    } else {
      yield put(ProdutoActions.getConversaoumError());
    }
  } catch (error) {
    yield put(ProdutoActions.getConversaoumError());
  }
}

function* postCopia({ payload }) {
  const { data, type } = payload;
  try {
    const { status } = yield call(
      api.post,
      `${
        type === 'ESTRUTURA'
          ? '/producao-copiaestrutura'
          : '/producao-copiaprocesso'
      }`,
      data
    );
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postCopiaSuccess());
    } else {
      yield put(ProdutoActions.postCopiaError());
    }
  } catch (error) {
    yield put(ProdutoActions.postCopiaError());
  }
}

function* postCalcula({ payload }) {
  try {
    const { status } = yield call(api.post, `/producao-calculacusto`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postCalculaSuccess());
    } else {
      yield put(ProdutoActions.postCalculaError());
    }
  } catch (error) {
    yield put(ProdutoActions.postCalculaError());
  }
}

function* postReajuste({ payload }) {
  try {
    const { status } = yield call(api.post, `/ReajustePreco`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postReajusteSuccess());
    } else {
      yield put(ProdutoActions.postReajusteError());
    }
  } catch (error) {
    yield put(ProdutoActions.postReajusteError());
  }
}

function* putAjustaVolume({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/AjustaVolume/${id}`, data);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.putAjustaVolumeSuccess());
    } else {
      yield put(ProdutoActions.putAjustaVolumeReset());
    }
  } catch (error) {
    yield put(ProdutoActions.putAjustaVolumeReset());
  }
}

function* putAjustaDensidade({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `/producao-ajustaquantidade`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.putAjustaDensidadeSuccess());
    } else {
      yield put(ProdutoActions.putAjustaDensidadeReset());
    }
  } catch (error) {
    yield put(ProdutoActions.putAjustaDensidadeReset());
  }
}

function* postAttCusto({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/producao-atualizacustoproducao`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postAttCustoSuccess());
    } else {
      yield put(ProdutoActions.postAttCustoReset());
    }
  } catch (error) {
    yield put(ProdutoActions.postAttCustoReset());
  }
}

function* postEtiqueta({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/imprime-etiqueta-termica`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postEtiquetaSuccess());
    } else {
      yield put(ProdutoActions.postEtiquetaReset());
    }
  } catch (error) {
    yield put(ProdutoActions.postEtiquetaReset());
  }
}

function* postManutSel({ payload }) {
  try {
    const { status } = yield call(api.post, `/produto-ajustesel`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postManutSelSuccess());
    } else {
      yield put(ProdutoActions.postManutSelReset());
    }
  } catch (error) {
    yield put(ProdutoActions.postManutSelReset());
  }
}

function* postReferencia({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/produto-referencia-integracao`,
      payload
    );
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postReferenciaSuccess());
    } else {
      yield put(ProdutoActions.postReferenciaReset());
    }
  } catch (error) {
    yield put(ProdutoActions.postReferenciaReset());
  }
}

function* postPrecos({ payload }) {
  try {
    const { status } = yield call(api.post, `/produto-preco`, payload);
    if (status === 200 || status === 201) {
      yield put(ProdutoActions.postPrecosSuccess());
    } else {
      yield put(ProdutoActions.postPrecosReset());
    }
  } catch (error) {
    yield put(ProdutoActions.postPrecosReset());
  }
}

function* postProdutoWatcher() {
  yield takeLatest(ProdutoTypes.POST_PRODUTO, postProduto);
}

function* putProdutoWatcher() {
  yield takeLatest(ProdutoTypes.PUT_PRODUTO, putProduto);
}

function* getProdutoWatcher() {
  yield takeLatest(ProdutoTypes.GET_PRODUTO, getProduto);
}

function* deleteProdutoWatcher() {
  yield takeLatest(ProdutoTypes.DELETE_PRODUTO, deleteProduto);
}

function* setStatusProdutoWatcher() {
  yield takeLatest(ProdutoTypes.SET_STATUS_PRODUTO, setStatusProduto);
}

function* getDescricaoWatcher() {
  yield takeLatest(ProdutoTypes.GET_DESCRICAO, getDescricao);
}

function* putDescricaoWatcher() {
  yield takeLatest(ProdutoTypes.PUT_DESCRICAO, putDescricao);
}

function* getVariacaoWatcher() {
  yield takeLatest(ProdutoTypes.GET_VARIACAO, getVariacao);
}

function* putVariacaoWatcher() {
  yield takeLatest(ProdutoTypes.PUT_VARIACAO, putVariacao);
}

function* getMidiaWatcher() {
  yield takeLatest(ProdutoTypes.GET_MIDIAS, getMidia);
}

function* putMidiaWatcher() {
  yield takeLatest(ProdutoTypes.PUT_MIDIA, putMidia);
}

function* getConversaoumWatcher() {
  yield takeLatest(ProdutoTypes.GET_CONVERSAOUM, getConversaoum);
}

function* postCopiaWatcher() {
  yield takeLatest(ProdutoTypes.POST_COPIA, postCopia);
}

function* postCalculaWatcher() {
  yield takeLatest(ProdutoTypes.POST_CALCULA, postCalcula);
}

function* postReajusteWatcher() {
  yield takeLatest(ProdutoTypes.POST_REAJUSTE, postReajuste);
}

function* putAjustaVolumeWatcher() {
  yield takeLatest(ProdutoTypes.PUT_AJUSTA_VOLUME, putAjustaVolume);
}

function* putAjustaDensidadeWatcher() {
  yield takeLatest(ProdutoTypes.PUT_AJUSTA_DENSIDADE, putAjustaDensidade);
}

function* postAttCustoWatcher() {
  yield takeLatest(ProdutoTypes.POST_ATT_CUSTO, postAttCusto);
}

function* postEtiquetaWatcher() {
  yield takeLatest(ProdutoTypes.POST_ETIQUETA, postEtiqueta);
}

function* postManutSelWatcher() {
  yield takeLatest(ProdutoTypes.POST_MANUT_SEL, postManutSel);
}

function* postReferenciaWatcher() {
  yield takeLatest(ProdutoTypes.POST_REFERENCIA, postReferencia);
}

function* postPrecosWatcher() {
  yield takeLatest(ProdutoTypes.POST_PRECOS, postPrecos);
}

export default function* rootSaga() {
  yield all([
    fork(postProdutoWatcher),
    fork(putProdutoWatcher),
    fork(getProdutoWatcher),
    fork(deleteProdutoWatcher),
    fork(setStatusProdutoWatcher),
    fork(getDescricaoWatcher),
    fork(putDescricaoWatcher),
    fork(getVariacaoWatcher),
    fork(putVariacaoWatcher),
    fork(getMidiaWatcher),
    fork(putMidiaWatcher),
    fork(getConversaoumWatcher),
    fork(postCopiaWatcher),
    fork(postCalculaWatcher),
    fork(postReajusteWatcher),
    fork(putAjustaVolumeWatcher),
    fork(putAjustaDensidadeWatcher),
    fork(postAttCustoWatcher),
    fork(postEtiquetaWatcher),
    fork(postManutSelWatcher),
    fork(postReferenciaWatcher),
    fork(postPrecosWatcher),
  ]);
}
