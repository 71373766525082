export const Types = {
  POST_COPIA_PERFIL: 'COPIA_PERFIL',
  POST_COPIA_PERFIL_SUCCESS: 'COPIA_PERFIL_SUCCESS',
  POST_COPIA_PERFIL_ERROR: 'COPIA_PERFIL_ERROR',
  POST_COPIA_PERFIL_RESET: 'COPIA_PERFIL_RESET',
};

const INITIAL_STATE = {
  postCopiaLoading: false,
  postCopiaSuccess: false,
  postCopiaError: false,
};

export default function Usuarios(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_COPIA_PERFIL:
      return {
        ...state,
        postCopiaLoading: true,
        postCopiaSuccess: false,
        postCopiaError: false,
      };
    case Types.POST_COPIA_PERFIL_SUCCESS:
      return {
        ...state,
        postCopiaLoading: false,
        postCopiaSuccess: true,
        postCopiaError: false,
      };
    case Types.POST_COPIA_PERFIL_ERROR:
      return {
        ...state,
        postCopiaLoading: false,
        postCopiaSuccess: false,
        postCopiaError: true,
      };
    case Types.POST_COPIA_PERFIL_RESET:
      return {
        ...state,
        postCopiaLoading: false,
        postCopiaSuccess: false,
        postCopiaError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  postCopiaPerfil: (payload) => ({
    type: Types.POST_COPIA_PERFIL,
    payload,
  }),
  postCopiaPerfilSuccess: () => ({
    type: Types.POST_COPIA_PERFIL_SUCCESS,
  }),
  postCopiaPerfilError: () => ({
    type: Types.POST_COPIA_PERFIL_ERROR,
  }),
  postCopiaPerfilReset: () => ({
    type: Types.POST_COPIA_PERFIL_RESET,
  }),
};
