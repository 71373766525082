import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as NotificacoesActions,
  Types as NotificacoesTypes,
} from 'store/ducks/Notificacoes';
import api from '../../util/api';

function* getNotificacoes({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/notificacao/${payload}`);
    if (status === 200 || status === 201) {
      yield put(
        NotificacoesActions.getNotificacoesSuccess(data?.notificacoes || [])
      );
    } else {
      yield put(NotificacoesActions.getNotificacoesError());
    }
  } catch (error) {
    yield put(NotificacoesActions.getNotificacoesError());
  }
}

function* putNotificacoes({ payload }) {
  const { item, user_id } = payload;
  try {
    const { status } = yield call(api.put, `/notificacao/${item?.id}`, item);
    if (status === 200 || status === 201) {
      const { data } = yield call(api.get, `/notificacao/${user_id}`);
      if (data?.notificacoes) {
        yield put(
          NotificacoesActions.putNotificacoesSuccess(data?.notificacoes || [])
        );
      } else {
        yield put(NotificacoesActions.putNotificacoesError());
      }
    } else {
      yield put(NotificacoesActions.putNotificacoesError());
    }
  } catch (error) {
    yield put(NotificacoesActions.putNotificacoesError());
  }
}

function* deleteNotificacoes({ payload }) {
  const { id, user_id } = payload;
  try {
    const { status } = yield call(api.delete, `/manutencao/notificacao/${id}`);
    if (status === 200 || status === 201 || status === 203) {
      const { data } = yield call(api.get, `/notificacao/${user_id}`);
      if (data?.notificacoes) {
        yield put(
          NotificacoesActions.deleteNotificacoesSuccess(
            data?.notificacoes || []
          )
        );
      } else {
        yield put(NotificacoesActions.deleteNotificacoesError());
      }
    } else {
      yield put(NotificacoesActions.deleteNotificacoesError());
    }
  } catch (error) {
    yield put(NotificacoesActions.deleteNotificacoesError());
  }
}

function* getNotificacoesWatcher() {
  yield takeLatest(NotificacoesTypes.GET_NOTIFICACOES, getNotificacoes);
}

function* putNotificacoesWatcher() {
  yield takeLatest(NotificacoesTypes.PUT_NOTIFICACOES, putNotificacoes);
}

function* deleteNotificacoesWatcher() {
  yield takeLatest(NotificacoesTypes.DELETE_NOTIFICACOES, deleteNotificacoes);
}

export default function* rootSaga() {
  yield all([
    fork(getNotificacoesWatcher),
    fork(putNotificacoesWatcher),
    fork(deleteNotificacoesWatcher),
  ]);
}
