import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { setDecimal, transformData } from 'util/index';
import api from 'util/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '80%',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const MoedaModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    data: null,
    util: null,
    moeda_id: null,
    cotacao: null,
  });
  const { postCalendarioMoedaLoading } = useSelector(
    ({ lancamento }) => lancamento
  );

  async function loadItems() {
    const { data } = await api.post(`/csql/632`);
    if (data) {
      setItems(data.dados || []);
    }
  }

  useEffect(() => {
    if (open) loadItems();
  }, [open]);

  const onSend = () => {
    const data = {
      data: moment(values?.data, 'DD/MM/YYYY').isValid()
        ? moment(values?.data, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      util: values?.util?.value,
      moeda_id: values?.moeda_id?.value,
      cotacao: transformData(values?.cotacao, 'number'),
    };
    dispatch(LancamentoActions.postCalendarioMoeda(data));
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Cotação da Moeda</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <InputMask
              label="Data"
              mask="99/99/9999"
              value={values.data}
              onChange={(e) => setValues({ ...values, data: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Util"
              items={yn}
              value={values.util}
              onChange={(v) => setValues({ ...values, util: v })}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Moeda"
              items={items || []}
              value={values.moeda_id}
              onChange={(v) => setValues({ ...values, moeda_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <TextField
              label="Cotação"
              value={values.cotacao}
              onChange={(e) =>
                setValues({ ...values, cotacao: e.target.value })
              }
              onBlur={(e) =>
                setValues({ ...values, cotacao: setDecimal(e.target.value, 6) })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSend}
          disabled={postCalendarioMoedaLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(MoedaModal);
