import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import HeaderPage from 'components/HeaderPage';
import InputMask from 'components/InputMask';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { transformData, setDecimal, findOnArray, useQuery } from 'util/index';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const Detalhes = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const param = query.get('id');
  const { getLancDetalheLoading, lancDetalhe } = useSelector(
    ({ lancamento }) => lancamento
  );

  useEffect(() => {
    if (param) {
      dispatch(LancamentoActions.getLancDetalhe(param));
    }
  }, []);

  if (getLancDetalheLoading) {
    return <Loading />;
  } else {
    return (
      <div className="app-wrapper">
        <HeaderPage title="Detalhamento do Documento" history={history} />

        <Grid container spacing={2}>
          {!!lancDetalhe?.Documento && (
            <Grid item xs={12}>
              <Card className="shadow">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center justify-content-center">
                        <Typography variant="h6">Documento</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Origem"
                        value={lancDetalhe?.Origem?.origem}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Documento"
                        value={lancDetalhe?.Documento?.documento}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Grupo"
                        value={lancDetalhe?.Documento?.grupo}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Data"
                        value={
                          moment(lancDetalhe?.Documento?.data).isValid()
                            ? moment(lancDetalhe?.Documento?.data).format(
                                'DD/MM/YYYY [às] HH:mm:ss'
                              )
                            : ''
                        }
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Valor"
                        value={setDecimal(lancDetalhe?.Documento?.valor, 2)}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Espécie do Documento"
                        value={lancDetalhe?.Documento?.especie}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Natureza da Operação"
                        value={lancDetalhe?.Documento?.natureza}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Filial"
                        value={lancDetalhe?.Documento?.filial}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Entidade"
                        value={lancDetalhe?.Documento?.entidade}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {!!lancDetalhe?.Financeiro?.length && (
            <Grid item xs={12} sm={6}>
              <Card className="shadow">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center justify-content-center">
                        <Typography variant="h6">Financeiro</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ overflow: 'auto' }} component={Paper}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'table',
                            tableLayout: 'fixed',
                          }}
                        >
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Parcela</TableCell>
                                <TableCell>Vencimento</TableCell>
                                <TableCell>Valor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lancDetalhe?.Financeiro?.map((item) => (
                                <TableRow key={item?.id?.toString()}>
                                  <TableCell>{item?.id}</TableCell>
                                  <TableCell>{item?.parcela}</TableCell>
                                  <TableCell>
                                    {moment(item?.dtvenc).isValid() &&
                                      moment(item?.dtvenc).format('DD/MM/YYYY')}
                                  </TableCell>
                                  <TableCell>
                                    {setDecimal(item?.valor, 2)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {!!lancDetalhe?.Tributos?.length && (
            <Grid item xs={12} sm={6}>
              <Card className="shadow">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center justify-content-center">
                        <Typography variant="h6">Tributos</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ overflow: 'auto' }} component={Paper}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'table',
                            tableLayout: 'fixed',
                          }}
                        >
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Tributo</TableCell>
                                <TableCell>Valor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lancDetalhe?.Tributos?.map((item) => (
                                <TableRow key={item?.id?.toString()}>
                                  <TableCell>{item?.tributo}</TableCell>
                                  <TableCell>
                                    {setDecimal(item?.valor, 2)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {!!lancDetalhe?.Contabilizacao?.length && (
            <Grid item xs={12}>
              <Card className="shadow">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center justify-content-center">
                        <Typography variant="h6">Contabilização</Typography>
                      </div>
                    </Grid>
                    {!!lancDetalhe?.Contabilizacao?.length && (
                      <Grid item xs={12}>
                        <Box sx={{ overflow: 'auto' }} component={Paper}>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'table',
                              tableLayout: 'fixed',
                            }}
                          >
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Data do Lançamento</TableCell>
                                  <TableCell>Nª Conta Débito</TableCell>
                                  <TableCell>Conta Débito</TableCell>
                                  <TableCell>Nª Conta Crédito</TableCell>
                                  <TableCell>Conta Crédito</TableCell>
                                  <TableCell>Valor</TableCell>
                                  <TableCell>Histórico</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {lancDetalhe?.Contabilizacao?.map((item) => (
                                  <TableRow key={item?.id?.toString()}>
                                    <TableCell>
                                      {moment(item?.dtlanc).isValid() &&
                                        moment(item?.dtlanc).format(
                                          'DD/MM/YYYY'
                                        )}
                                    </TableCell>
                                    <TableCell>{item?.contad}</TableCell>
                                    <TableCell>{item?.ctadeb}</TableCell>
                                    <TableCell>{item?.contac}</TableCell>
                                    <TableCell>{item?.ctacred}</TableCell>
                                    <TableCell>
                                      {setDecimal(item?.valor, 2)}
                                    </TableCell>
                                    <TableCell>{item?.historico}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
};

export default withStyles(styles)(Detalhes);
