import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableOptions from 'components/TableOptions';
import SearchBox from 'components/SearchBox';
import Totalizadores from 'components/Totalizadores';
import FilterReportModal from 'components/Modals/FilterReportModal';
import ViewModal from 'components/Modals/ViewModal';
import FilterModal from 'components/Modals/FilterModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DuplicateModal from 'components/Modals/DuplicateModal';
import ExportModal from 'components/Modals/ExportModal';
import ResumoModal from 'components/Modals/ResumoModal';
import {
  checkManutencao,
  checkAgrupamento,
  getWhereClause,
  viewBoxes,
  useQuery,
} from 'util/index';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { Creators as ResumoActions } from 'store/ducks/Resumo';

const Operação = ({ match }) => {
  const query = useQuery(useLocation);
  const rotina = 477;
  const csql = 359;
  const title = 'Movimentação do produto - Notas de Entrada e Saída';
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [stringExport, setStringExport] = useState('');
  const [selected, setSelected] = useState(null);
  const [totais, setTotais] = useState([]);
  // const [multiSelected, setMultiSelected] = useState([]); // CHECKBOX
  const [headerData, setHeaderData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [filterReportModal, setFilterReportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [xlsModal, setXlsModal] = useState(false);
  const [Pagina, setPagina] = useState(1);
  const [PorPagina, setPorPagina] = useState(10);
  const [OrderClause, setOrderClause] = useState(null);
  const param = query.get('id');
  const nameParam = query.get('descricao');
  const {
    headerLoading,
    headerData: headerDataStore,
    data,
    filtros,
    filtrosChanged,
    reportFiltros,
    paginacao,
    renderRotina,
  } = useSelector(({ tables }) => tables);

  function setParamOnFilter() {
    if (param) {
      var filterWithParam = filtros.map((f) => {
        if (f.parametro === 'SIM') {
          return { ...f, conteudoi: param, conteudod: nameParam };
        }
        return f;
      });
      return filterWithParam;
    }
    return filtros;
  }

  function getTotais() {
    // SE TIVER PARAMETRO
    const newParams = getWhereClause({
      filtros: setParamOnFilter(),
      param: true,
    });

    if (paginacao) {
      dispatch(
        TablesActions.getTotais({
          csql,
          params: {
            rotina,
            // Parametros: [{ nome: 'likevalue', valor: search }],
            Parametros: [{ nome: 'likevalue', valor: search }, ...newParams], // SE TIVER PARAMETRO
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
          },
        })
      );
    }
  }

  function getDataTable(props) {
    if (rotina === renderRotina) {
      dispatch(ResumoActions.setFilterResumo(setParamOnFilter()));

      // SE TIVER PARAMETRO
      const newParams = getWhereClause({
        filtros: setParamOnFilter(),
        param: true,
      });

      dispatch(
        TablesActions.getDataTable({
          csql,
          params: {
            // Parametros: [{ nome: 'likevalue', valor: search }],
            Parametros: [{ nome: 'likevalue', valor: search }, ...newParams], // SE TIVER PARAMETRO
            WhereClause: getWhereClause({
              filtros,
              setStringExport,
            }),
            OrderClause,
            Pagina: paginacao ? Pagina : undefined,
            PorPagina: paginacao ? PorPagina : undefined,
          },
        })
      );
    }
  }

  useEffect(() => {
    dispatch(TablesActions.getHeader(rotina));
  }, []);

  // Caso tenha relatórios 
  // useEffect(() => {
  //   if (relatorio) {
  //     dispatch(TablesActions.getReport(relatorio));
  //   }
  // }, [relatorio]);

  // useEffect(() => {
  //   if (reportFiltros.length) {
  //     if (relatorio === 590) {
  //       dispatch(
  //         TablesActions.postReport({
  //           rotina_id: relatorio,
  //           WhereClause: getWhereClause({
  //             filtros: [
  //               { ...reportFiltros[0], conteudoi: selected?.documento_id },
  //             ],
  //           }),
  //         })
  //       );
  //       setRelatorio(null);
  //     }
  //     if (relatorio === 778) {
  //       dispatch(
  //         TablesActions.postReport({
  //           rotina_id: relatorio,
  //           WhereClause: getWhereClause({
  //             filtros: [
  //               {
  //                 ...reportFiltros[0],
  //                 conteudoi: `(${multiSelected?.map(
  //                   (i) => `${i?.documento_id}`
  //                 )})`,
  //               },
  //             ],
  //           })?.replace(/=/g, 'in'),
  //         })
  //       );
  //       setRelatorio(null);
  //     }
  //   }
  // }, [reportFiltros]);

  useEffect(() => {
    if (filtrosChanged) {
      setPagina(1);
      getDataTable();
      dispatch(TablesActions.resetFiltros());
    }
  }, [filtrosChanged]);

  useEffect(() => {
    if (headerData.length && param) {
      dispatch(TablesActions.setFiltros(setParamOnFilter()));
    } else {
      if (headerData.length) {
        getDataTable();
      }
    }
  }, [headerData, Pagina, PorPagina, OrderClause]);

  useEffect(() => {
    if (
      headerDataStore.length
      && filtros.length //SE TIVER FILTRO NA TELA
    ) {
      setHeaderData(headerDataStore);
      if (paginacao) {
        setPorPagina(paginacao);
      }
    }
  }, [headerDataStore]);

  const buttonActions = [
    {
      name: 'Adicionar',
      type: 'Adicionar',
      action: () =>
        param
          ? history.push(`/app/Manutenção?cod=${param}`)
          : history.push(`/app/Manutenção`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Filtrar',
      type: 'Filtrar',
      action: () => setFilterModal(!filterModal),
      visible: filtros?.length,
    },
    {
      name: 'Visualizar',
      type: 'Visualizar',
      action: () => setViewModal(!viewModal),
      visible: true,
    },
    {
      name: 'Exportar PDF',
      type: 'PDF',
      action: () => setPdfModal(!pdfModal),
      visible: true,
    },
    {
      name: 'Exportar XLS',
      type: 'XLS',
      action: () => setXlsModal(!xlsModal),
      visible: true,
    },
    {
      name: 'Agrupar',
      type: 'Calculo',
      action: () => setResumoModal(!resumoModal),
      visible: checkAgrupamento(headerData),
    },
    // {
    //   name: 'Lucratividade',
    //   type: 'Imprimir',
    //   action: () => {
    //     setRelatorio(592);
    //     setFilterReportModal(!filterReportModal);
    //   },
    //   visible: true,
    // },
  ];

  const tablesActions = [
    {
      name: 'Editar',
      action: (item) => history.push(`/app/Manutenção?id=${item?.id}`),
      visible: checkManutencao(headerData),
    },
    {
      name: 'Deletar',
      action: (item) => {
        setSelected(item);
        setDeleteModal(!deleteModal);
      },
      visible: checkManutencao(headerData),
    },
    {
      name: 'Menu',
      items: [
        {
          name: 'Duplicar',
          action: (item) => {
            setSelected(item);
            setDuplicateModal(!duplicateModal);
          },
          visible: checkManutencao(headerData),
        },
      ],
      visible: true,
    },
  ];

  return (
    <div className="app-wrapper">
      <HeaderPage title={title} history={param && history} rotina={rotina} />
      <Card className="shadow">
        <CardContent>
          {headerLoading ? (
            <Loading />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <TableOptions actions={buttonActions} />
                <SearchBox
                  placeholder="Buscar..."
                  onChange={(evt) => setSearch(evt.target.value)}
                  value={search}
                  onSubmit={() => {
                    if (Pagina === 1) {
                      getDataTable();
                    } else {
                      setPagina(1);
                    }
                  }}
                />
              </div>
              <Table
                headerData={headerData}
                data={data}
                actions={tablesActions}
                onOrder={(OrderClause) => setOrderClause(OrderClause)}
                // selected={multiSelected} // CHECKBOX
                // setSelected={setMultiSelected} // CHECKBOX
                Pagina={paginacao ? Pagina : null}
                setPagina={paginacao ? setPagina : null}
                PorPagina={paginacao ? PorPagina : null}
                setPorPagina={paginacao ? setPorPagina : null}
                stringExport={stringExport}
              />
              {/* <Legendas screen="Produto" />  LEGENDA*/}
              <Totalizadores
                headerData={headerData}
                data={data}
                totais={totais}
                setTotais={setTotais}
                onLoad={getTotais}
                paginacao={!!paginacao}
              />
              <ViewModal
                open={viewModal}
                setOpen={setViewModal}
                boxes={viewBoxes(headerDataStore)}
                objeto={headerData[0]?.objeto}
                rotina={rotina}
              />
              <ResumoModal
                open={resumoModal}
                setOpen={setResumoModal}
                boxes={viewBoxes(headerDataStore)}
                history={history}
                headerData={headerData}
                data={data}
                csql={csql}
                rotina={rotina}
              />
              <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                filtros={filtros}
                reportFiltros={reportFiltros}
              />
              <FilterReportModal
                open={filterReportModal}
                setOpen={setFilterReportModal}
                reportFiltros={reportFiltros}
                // report={relatorio}
              />
              <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                rotina={rotina}
                id={selected?.id}
                onDelete={() =>
                  dispatch(
                    TablesActions.deleteData({
                      table: headerData[0]?.tabela,
                      id: selected?.id,
                    })
                  )
                }
              />
              <DuplicateModal
                open={duplicateModal}
                setOpen={setDuplicateModal}
                rotina={rotina}
                headerData={headerData}
                selected={selected}
                table={headerData[0]?.tabela}
              />
              <ExportModal
                open={pdfModal}
                setOpen={setPdfModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
                pdf
              />
              <ExportModal
                open={xlsModal}
                setOpen={setXlsModal}
                boxes={viewBoxes(headerDataStore)}
                titulo={title}
                stringExport={stringExport}
                dados={data}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Operação;
