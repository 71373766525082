import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as GnreActions,
  Types as GnreTypes,
} from 'store/ducks/Gnre';
import api from '../../util/api';

function* postGNRERetorno({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/fiscal-gnre-retorno`, payload);
    if (status === 200 || status === 201) {
      yield put(
        GnreActions.postGNRERetornoSuccess({
          gnre: data?.registros,
        })
      );
    } else {
      yield put(GnreActions.postGNRERetornoReset());
    }
  } catch (error) {
    yield put(GnreActions.postGNRERetornoReset());
  }
}

function* postGNRERetornoWatcher() {
  yield takeLatest(GnreTypes.POST_GNRERETORNO, postGNRERetorno);
}

export default function* rootSaga() {
  yield all([fork(postGNRERetornoWatcher)]);
}
