import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  DialogContentText,
  Paper,
} from '@material-ui/core';
import { Formik } from 'formik';
import moment from 'moment';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { setDecimal, findOnArray, transformData } from 'util/index';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '50%',
  },
  infoContainer: {
    margin: '0 0 20px 0',
    padding: '1rem',
    backgroundColor: '#c5c5c5',
  },
});

const conteudo = [
  { value: 'desconto', label: 'Desconto' },
  { value: 'frete', label: 'Frete' },
  { value: 'fFretend', label: 'Frete não destacado' },
  { value: 'seguro', label: 'Seguro' },
  { value: 'segurond', label: 'Seguro não destacado' },
  { value: 'despac', label: 'Despesas Acessorias' },
  { value: 'outros', label: 'Outros' },
  { value: 'cstfin', label: 'Custo Financeiro' },
  { value: 'eventuais', label: 'Eventuais' },
  { value: 'impfednd', label: 'Imp. federais não destacado' },
  { value: 'comissaond', label: 'Comissão não destacado' },
  { value: 'internacional', label: 'Internacionalização' },
];

const informado = [
  { value: 'Percentual', label: 'Percentual' },
  { value: 'Valor', label: 'Valor' },
];

const DescontoModal = ({
  open,
  setOpen,
  onClose,
  selected,
  documento,
  classes,
}) => {
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    let data = {
      infadc: values?.infadc,
      conteudo: values?.conteudo?.value,
      informado: values?.informado?.value,
    };
    if (values?.informado?.value === 'Percentual') {
      data = {
        ...data,
        pc1: transformData(values?.pc1, 'number'),
        pc2: transformData(values?.pc2, 'number'),
        pc3: transformData(values?.pc3, 'number'),
        pc4: transformData(values?.pc4, 'number'),
        pc5: transformData(values?.pc5, 'number'),
      };
    }
    if (values?.informado?.value === 'Valor') {
      data = {
        ...data,
        valor: transformData(values?.valor, 'number'),
      };
    }
    if (selected) {
      data = {
        ...data,
        documento_item_id: selected?.id,
      };
    } else {
      data = { ...data, documento_id: documento?.Documento?.id };
    }
    dispatch(DocumentoActions.postItemOutros(data));
    setOpen(!open);
  };

  return (
    <Dialog open={open} onExit={onClose} fullWidth>
      <Formik
        initialValues={{
          infadc: null,
          conteudo: null,
          informado: null,
          pc1: null,
          pc2: null,
          pc3: null,
          pc4: null,
          pc5: null,
          valor: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogTitle>Desconto / Outros / Margem / Adicionais</DialogTitle>
            {selected && (
              <Paper className={classes.infoContainer}>
                <div className="d-flex justify-content-between align-items-center">
                  <DialogContentText color="primary">
                    Produto: {selected?.descricao}
                  </DialogContentText>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <DialogContentText color="primary">
                    Preço: {setDecimal(selected?.preco, 2)}
                  </DialogContentText>
                  <DialogContentText color="primary">
                    Quantidade: {selected.quantidade}
                  </DialogContentText>
                  <DialogContentText color="primary">
                    Total:{' '}
                    {setDecimal(selected?.preco * selected?.quantidade, 2)}
                  </DialogContentText>
                </div>
              </Paper>
            )}
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                      name="infadc"
                      label="Informação adicional"
                      value={values.infadc}
                      onChange={handleChange}
                      fullWidth
                  />
                  </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Conteúdo"
                    items={conteudo}
                    value={values.conteudo}
                    onChange={(v) => setFieldValue('conteudo', v)}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <SelectAuto
                    label="Informar por"
                    items={informado}
                    value={values.informado}
                    onChange={(v) => setFieldValue('informado', v)}
                  />
                </Grid>
                {values?.informado?.value === 'Valor' && (
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <TextField
                      name="valor"
                      label="Valor de desconto"
                      value={values.valor}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('valor', setDecimal(e.target.value, 2))
                      }
                      fullWidth
                    />
                  </Grid>
                )}
                {values?.informado?.value === 'Percentual' && (
                  <>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="pc1"
                        label="Percentual 1"
                        value={values.pc1}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="pc2"
                        label="Percentual 2"
                        value={values.pc2}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="pc3"
                        label="Percentual 3"
                        value={values.pc3}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="pc4"
                        label="Percentual 4"
                        value={values.pc4}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                      <TextField
                        name="pc5"
                        label="Percentual 5"
                        value={values.pc5}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onSubmit(values)} color="primary">
                Confirmar
              </Button>
              <Button onClick={() => setOpen(null)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(DescontoModal);
