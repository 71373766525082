import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Button, Grid, TextField } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import HeaderPage from 'components/HeaderPage';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as TablesActions } from 'store/ducks/Tables';
import { useQuery, setDecimal, transformData, findOnArray } from 'util/index';
import api from 'util/api';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const ComplementoItens = ({ match, classes }) => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const dispatch = useDispatch();
  const id = query.get('id');
  const item = query.get('item');
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const { documento } = useSelector(({ documento }) => documento);
  const { editSuccess } = useSelector(({ tables }) => tables);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    loadItems([637, 654, 636, 658, 709, 778, 710]);
    let finder = documento?.Itens.find((i) => i?.id === Number(id));
    setSelected(finder);
  }, []);

  useEffect(() => {
    if (editSuccess) {
      dispatch(TablesActions.editDataReset());
      history.goBack();
    }
  }, [editSuccess]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (selected && !!items?.length) {
        setValues({
          ...values,
          aplicacao_id: findOnArray(selected?.aplicacao_id, items[4]),
          ccusto_id: findOnArray(selected?.ccusto_id, items[3]),
          cfiscal_id: findOnArray(selected?.cfiscal_id, items[1]),
          cfo_id: findOnArray(selected?.cfo_id, items[0]),
          comissao_pc: setDecimal(selected?.comissao_pc, 2),
          cstfin: setDecimal(selected?.cstfin, 2),
          desconto1_pc: setDecimal(selected?.desconto1_pc, 2),
          desconto2_pc: setDecimal(selected?.desconto2_pc, 2),
          desconto3_pc: setDecimal(selected?.desconto3_pc, 2),
          desconto4_pc: setDecimal(selected?.desconto4_pc, 2),
          desconto5_pc: setDecimal(selected?.desconto5_pc, 2),
          desconto_vlr: setDecimal(selected?.desconto_vlr, 2),
          despac: setDecimal(selected?.despac, 2),
          eventuais: setDecimal(selected?.eventuais, 2),
          frete: setDecimal(selected?.frete, 2),
          frota_id: findOnArray(selected?.frota_id, items[6]),
          infadc: selected?.infadc,
          outros: setDecimal(selected?.outros, 2),
          peso: setDecimal(selected?.peso, 3),
          planoconta_id: findOnArray(selected?.planoconta_id, items[5]),
          przent: selected?.przent
            ? moment(selected?.przent).format('DD/MM/YYYY')
            : null,
          referencia: selected?.referencia,
          seguro: setDecimal(selected?.seguro, 2),
          sequencia: selected?.sequencia,
          unidade_medida_id: findOnArray(selected?.unidade_medida_id, items[2]),
          reserva: findOnArray(selected?.reserva, yn),
          pedcomp: selected?.pedcomp,
          itempedcomp: selected?.itempedcomp,
        });
      }
    }, [selected, items]);
    return null;
  };

  const onSend = (values) => {
    let form = {
      aplicacao_id: values?.aplicacao_id?.value,
      ccusto_id: values?.ccusto_id?.value,
      cfiscal_id: values?.cfiscal_id?.value,
      cfo_id: values?.cfo_id?.value,
      comissao_pc: values?.comissao_pc
        ? transformData(values?.comissao_pc, 'number')
        : null,
      cstfin: values?.cstfin ? transformData(values?.cstfin, 'number') : null,
      desconto1_pc: values?.desconto1_pc
        ? transformData(values?.desconto1_pc, 'number')
        : null,
      desconto2_pc: values?.desconto2_pc
        ? transformData(values?.desconto2_pc, 'number')
        : null,
      desconto3_pc: values?.desconto3_pc
        ? transformData(values?.desconto3_pc, 'number')
        : null,
      desconto4_pc: values?.desconto4_pc
        ? transformData(values?.desconto4_pc, 'number')
        : null,
      desconto5_pc: values?.desconto5_pc
        ? transformData(values?.desconto5_pc, 'number')
        : null,
      desconto_vlr: values?.desconto_vlr
        ? transformData(values?.desconto_vlr, 'number')
        : null,
      despac: values?.despac ? transformData(values?.despac, 'number') : null,
      eventuais: values?.eventuais
        ? transformData(values?.eventuais, 'number')
        : null,
      frete: values?.frete ? transformData(values?.frete, 'number') : null,
      frota_id: values?.frota_id?.value,
      infadc: values?.infadc,
      outros: values?.outros ? transformData(values?.outros, 'number') : null,
      peso: values?.peso ? transformData(values?.peso, 'number') : null,
      planoconta_id: values?.planoconta_id?.value,
      przent: moment(values?.przent, 'DD/MM/YYYY').isValid
        ? moment(values?.przent, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      referencia: values?.referencia,
      seguro: values?.seguro ? transformData(values?.seguro, 'number') : null,
      sequencia: values?.sequencia
        ? transformData(values?.sequencia, 'number')
        : null,
      unidade_medida_id: values?.unidade_medida_id?.value,
      reserva: values?.reserva?.value,
      pedcomp: values?.pedcomp,
      itempedcomp: transformData(values?.itempedcomp, 'number'),
    };
    dispatch(
      TablesActions.editData({
        table: 'documento_item',
        campos: form,
        id,
      })
    );
  };

  return (
    <div className="app-wrapper">
      <HeaderPage title={`Complemento do Item - ${item}`} history={history} />
      <Formik
        initialValues={{
          aplicacao_id: null,
          ccusto_id: null,
          cfiscal_id: null,
          cfo_id: null,
          comissao_pc: null,
          cstfin: null,
          desconto1_pc: null,
          desconto2_pc: null,
          desconto3_pc: null,
          desconto4_pc: null,
          desconto5_pc: null,
          desconto_vlr: null,
          despac: null,
          eventuais: null,
          frete: null,
          frota_id: null,
          infadc: null,
          outros: null,
          peso: null,
          planoconta_id: null,
          przent: null,
          referencia: null,
          seguro: null,
          sequencia: null,
          unidade_medida_id: null,
          reserva: null,
          pedcomp: null,
          itempedcomp: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <Card className="shadow">
              <CardContent>
                <Grid container>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="sequencia"
                      label="Sequencia"
                      value={values?.sequencia || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <InputMask
                      name="przent"
                      label="Prazo de Entrega"
                      mask="99/99/9999"
                      value={values.przent || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="CFO"
                      items={items[0]}
                      value={values.cfo_id}
                      onChange={(v) => setFieldValue('cfo_id', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="NCM"
                      items={items[1]}
                      value={values.cfiscal_id}
                      onChange={(v) => setFieldValue('cfiscal_id', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="referencia"
                      label="Referência"
                      value={values?.referencia || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="infadc"
                      label="Informações Adicionais"
                      value={values?.infadc || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <SelectAuto
                      label="Unidade"
                      items={items[2]}
                      value={values.unidade_medida_id}
                      onChange={(v) => setFieldValue('unidade_medida_id', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desconto1_pc"
                      label="% Desconto 1"
                      value={values?.desconto1_pc || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'desconto1_pc',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desconto2_pc"
                      label="% Desconto 2"
                      value={values?.desconto2_pc || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'desconto2_pc',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desconto3_pc"
                      label="% Desconto 3"
                      value={values?.desconto3_pc || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'desconto3_pc',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desconto4_pc"
                      label="% Desconto 4"
                      value={values?.desconto4_pc || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'desconto4_pc',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desconto5_pc"
                      label="% Desconto 5"
                      value={values?.desconto5_pc || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'desconto5_pc',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="2" md="2" lg="2" className={classes.grid}>
                    <TextField
                      name="desconto_vlr"
                      label="Valor de Desconto"
                      value={values?.desconto_vlr || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'desconto_vlr',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="frete"
                      label="Frete"
                      value={values?.frete || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('frete', setDecimal(e.target.value, 2))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="seguro"
                      label="Seguro"
                      value={values?.seguro || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('seguro', setDecimal(e.target.value, 2))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="despac"
                      label="Acessórias"
                      value={values?.despac || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('despac', setDecimal(e.target.value, 2))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="cstfin"
                      label="Custo Financeiro"
                      value={values?.cstfin || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('cstfin', setDecimal(e.target.value, 2))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="eventuais"
                      label="Eventuais"
                      value={values?.eventuais || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'eventuais',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="outros"
                      label="Outros"
                      value={values?.outros || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('outros', setDecimal(e.target.value, 2))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="comissao_pc"
                      label="% Comissão"
                      value={values?.comissao_pc || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue(
                          'comissao_pc',
                          setDecimal(e.target.value, 2)
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                    <TextField
                      name="peso"
                      label="Peso Unitário"
                      value={values?.peso || ''}
                      onChange={handleChange}
                      onBlur={(e) =>
                        setFieldValue('peso', setDecimal(e.target.value, 3))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="C. Custo"
                      items={items[3]}
                      value={values.ccusto_id}
                      onChange={(v) => setFieldValue('ccusto_id', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Aplicação"
                      items={items[4]}
                      value={values.aplicacao_id}
                      onChange={(v) => setFieldValue('aplicacao_id', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Plano Conta"
                      items={items[5]}
                      value={values.planoconta_id}
                      onChange={(v) => setFieldValue('planoconta_id', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Frota"
                      items={items[6]}
                      value={values.frota_id}
                      onChange={(v) => setFieldValue('frota_id', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Reserva"
                      items={yn}
                      value={values.reserva}
                      onChange={(v) => setFieldValue('reserva', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="pedcomp"
                      label="Pedido de Compra"
                      value={values?.pedcomp || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="itempedcomp"
                      label="Item do Pedido de Compra"
                      value={values?.itempedcomp || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn"
                    onClick={() => onSend(values)}
                  >
                    SALVAR
                  </Button>
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(ComplementoItens);
