import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { replaceConteudoi } from 'util/index';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import moment from 'moment';

const GerarDadosModal = ({ open, setOpen, filtros, rotina }) => {
  const dispatch = useDispatch();
  const { putExportTextLoading, putExportTextSuccess } = useSelector(
    ({ lancamento }) => lancamento
  );

  useEffect(() => {
    if (putExportTextSuccess) {
      dispatch(LancamentoActions.putExportTextReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [putExportTextSuccess]);

  const onSend = (ret) => {
    let dt = (p) =>
      replaceConteudoi(
        filtros.find((f) => f.titulo.toUpperCase() === p)?.conteudoi
      );
    const data = {
      tipo: 'EFD CONTRIBUICOES',
      filial_id: filtros.find((f) => f.titulo.toUpperCase() === 'FILIAL')
        ?.conteudoi,
      retificadora: ret,
      dtinicio: moment(dt('DATA INICIO'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dtfim: moment(dt('DATA FIM'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };
    dispatch(LancamentoActions.putExportText(data));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Gerar Dados</DialogTitle>
      {putExportTextLoading ? (
        <Loading />
      ) : (
        <DialogContent>
          <DialogContentText>
            Deseja garar os dados com Retificadora?
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onSend('SIM')}
          color="primary"
          disabled={putExportTextLoading}
        >
          Sim
        </Button>
        <Button
          onClick={() => onSend('NAO')}
          color="primary"
          disabled={putExportTextLoading}
        >
          Não
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default GerarDadosModal;
