export const Types = {
  POST_INTEGRA_PEDIDOS: 'POST_INTEGRA_PEDIDOS',
  POST_INTEGRA_PEDIDOS_SUCCESS: 'POST_INTEGRA_PEDIDOS_SUCCESS',
  POST_INTEGRA_PEDIDOS_RESET: 'POST_INTEGRA_PEDIDOS_RESET',

  POST_NOTIFICACAO: 'POST_NOTIFICACAO',
  POST_NOTIFICACAO_SUCCESS: 'POST_NOTIFICACAO_SUCCESS',
  POST_NOTIFICACAO_RESET: 'POST_NOTIFICACAO_RESET',

  POST_INTEGRA_2RS: 'POST_INTEGRA_2RS',
  POST_INTEGRA_2RS_SUCCESS: 'POST_INTEGRA_2RS_SUCCESS',
  POST_INTEGRA_2RS_RESET: 'POST_INTEGRA_2RS_RESET',
};

const INITIAL_STATE = {
  postIntegraPedidosLoading: false,
  postIntegraPedidosSuccess: false,

  postNotificacaoLoading: false,
  postNotificacaoSuccess: false,

  postIntegra2rsLoading: false,
  postIntegra2rsSuccess: false,
};

export default function Integra(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_INTEGRA_PEDIDOS:
      return {
        ...state,
        postIntegraPedidosLoading: true,
        postIntegraPedidosSuccess: false,
      };
    case Types.POST_INTEGRA_PEDIDOS_SUCCESS:
      return {
        ...state,
        postIntegraPedidosLoading: false,
        postIntegraPedidosSuccess: true,
      };
    case Types.POST_INTEGRA_PEDIDOS_RESET:
      return {
        ...state,
        postIntegraPedidosLoading: false,
        postIntegraPedidosSuccess: false,
      };

    case Types.POST_NOTIFICACAO:
      return {
        ...state,
        postNOtificacaoLoading: true,
        postNotificacaoSuccess: false,
      };
    case Types.POST_NOTIFICACAO_SUCCESS:
      return {
        ...state,
        postNOtificacaoLoading: false,
        postNotificacaoSuccess: true,
      };
    case Types.POST_NOTIFICACAO_RESET:
      return {
        ...state,
        postNOtificacaoLoading: false,
        postNotificacaoSuccess: false,
      };

    case Types.POST_INTEGRA_2RS:
      return {
        ...state,
        postIntegra2rsLoading: true,
        postIntegra2rsSuccess: false,
      };
    case Types.POST_INTEGRA_2RS_SUCCESS:
      return {
        ...state,
        postIntegra2rsLoading: false,
        postIntegra2rsSuccess: true,
      };
    case Types.POST_INTEGRA_2RS_RESET:
      return {
        ...state,
        postIntegra2rsLoading: false,
        postIntegra2rsSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  postIntegraPedidos: (payload) => ({
    type: Types.POST_INTEGRA_PEDIDOS,
    payload,
  }),
  postIntegraPedidosSuccess: () => ({
    type: Types.POST_INTEGRA_PEDIDOS_SUCCESS,
  }),
  postIntegraPedidosReset: () => ({
    type: Types.POST_INTEGRA_PEDIDOS_RESET,
  }),

  postNotificacao: (payload) => ({
    type: Types.POST_NOTIFICACAO,
    payload,
  }),
  postNotificacaoSuccess: () => ({
    type: Types.POST_NOTIFICACAO_SUCCESS,
  }),
  postNotificacaoReset: () => ({
    type: Types.POST_NOTIFICACAO_RESET,
  }),

  postIntegra2rs: (payload) => ({
    type: Types.POST_INTEGRA_2RS,
    payload,
  }),
  postIntegra2rsSuccess: () => ({
    type: Types.POST_INTEGRA_2RS_SUCCESS,
  }),
  postIntegra2rsReset: () => ({
    type: Types.POST_INTEGRA_2RS_RESET,
  }),
};
