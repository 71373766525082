import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const TableVinculos = ({ vinculos, setVinculos, tipos, entidades, param }) => {
  if (vinculos.length > 0) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ação</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vinculos.map((item, index) => {
              if (item.excluir === 'SIM') return null;
              return (
                <TableRow>
                  <TableCell>
                    <DeleteIcon
                      color="action"
                      onClick={() => {
                        if (param && item?.id) {
                          const newVincs = vinculos.map((v) => {
                            if (v.id === item.id) {
                              return { ...v, excluir: 'SIM' };
                            } else {
                              return v;
                            }
                          });
                          setVinculos(newVincs);
                        } else {
                          setVinculos(
                            vinculos.filter((i, idx) => idx !== index)
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {tipos.find((e) => e.value === item.tipo_vinculo_id)?.label}
                  </TableCell>
                  <TableCell>
                    {
                      entidades.find((e) => e.value === item.entidade_id_vinc)
                        ?.label
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
};

export default TableVinculos;
