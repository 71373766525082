import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from 'store/ducks/Tables';
import api from 'util/api';
import Loading from 'components/Loading';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const SqlModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [csql, setCsql] = useState('');
  const [descricao, setDescricao] = useState('');
  const [condicao, setCondicao] = useState('');
  const [ordenacao, setOrdenacao] = useState('');
  const [localLoading, setLocalLoading] = useState(true);
  const { postCsqlLoading } = useSelector(({ tables }) => tables);

  const loadSql = async (sql) => {
    const { data } = await api.get(`/csql/${sql}`);
    setCsql(data?.dadosSQL?.SQL);
    //setDescricao(selected?.descricao);
    setCondicao(data?.dadosSQL?.WhereClause);
    setOrdenacao(data?.dadosSQL?.OrderClause);
    setLocalLoading(false);
  };

  useEffect(() => {
    if (open) {
      if (selected && typeof selected === 'object') {
        setCsql(selected?.csql);
        setDescricao(selected?.descricao);
        setCondicao(selected?.condicao);
        setOrdenacao(selected?.ordenacao);
        setLocalLoading(false);
      }
      if (selected && typeof selected === 'string') {
        loadSql(selected);
      }
    } else {
      setCsql('');
      setDescricao('');
      setCondicao('');
      setOrdenacao('');
    }
  }, [open, selected]);

  const onSubmit = () => {
    let data = {
      descricao,
      csql,
      condicao,
      ordenacao,
    };
    if (selected) {
      data = {
        ...data,
        id: selected?.csql_id || selected,
      };
    }
    dispatch(TablesActions.postCsql(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      {!!selected?.csqldescricao && (
        <DialogTitle>{selected?.csqldescricao}</DialogTitle>
      )}
      <DialogContent>
        {localLoading ? (
          <Loading />
        ) : (
          <Grid container>
            <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
              <TextField
                label="Descrição"
                value={descricao}
                onChange={(v) => setDescricao(v?.target?.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
              <TextField
                label="SQL"
                value={csql}
                onChange={(v) => setCsql(v?.target?.value)}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Grid>
            <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
              <TextField
                label="Condição"
                value={condicao}
                onChange={(v) => setCondicao(v?.target?.value)}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Grid>
            <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
              <TextField
                label="Ordenação"
                value={ordenacao}
                onChange={(v) => setOrdenacao(v?.target?.value)}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit} disabled={postCsqlLoading}>
          Salvar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(SqlModal);
