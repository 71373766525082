export const Types = {
  GET_NOTIFICACOES: 'GET_NOTIFICACOES',
  GET_NOTIFICACOES_SUCCESS: 'GET_NOTIFICACOES_SUCCESS',
  GET_NOTIFICACOES_ERROR: 'GET_NOTIFICACOES_ERROR',

  PUT_NOTIFICACOES: 'PUT_NOTIFICACOES',
  PUT_NOTIFICACOES_SUCCESS: 'PUT_NOTIFICACOES_SUCCESS',
  PUT_NOTIFICACOES_ERROR: 'PUT_NOTIFICACOES_ERROR',
  PUT_NOTIFICACOES_RESET: 'PUT_NOTIFICACOES_RESET',

  DELETE_NOTIFICACOES: 'DELETE_NOTIFICACOES',
  DELETE_NOTIFICACOES_SUCCESS: 'DELETE_NOTIFICACOES_SUCCESS',
  DELETE_NOTIFICACOES_ERROR: 'DELETE_NOTIFICACOES_ERROR',
  DELETE_NOTIFICACOES_RESET: 'DELETE_NOTIFICACOES_RESET',
};

const INITIAL_STATE = {
  getNotificacoesLoading: false,
  notificacoes: [],
  getNotificacoesError: false,

  putNotificacoesLoading: false,
  putNotificacoesSuccess: false,
  putNotificacoesError: false,

  deleteNotificacoesLoading: false,
  deleteNotificacoesSuccess: false,
  deleteNotificacoesError: false,
};

export default function Notificacoes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_NOTIFICACOES:
      return {
        ...state,
        getNotificacoesLoading: true,
        notificacoes: [],
        getNotificacoesError: false,
      };
    case Types.GET_NOTIFICACOES_SUCCESS:
      return {
        ...state,
        getNotificacoesLoading: false,
        notificacoes: action.payload,
        getNotificacoesError: false,
      };
    case Types.GET_NOTIFICACOES_ERROR:
      return {
        ...state,
        getNotificacoesLoading: false,
        notificacoes: [],
        getNotificacoesError: true,
      };

    case Types.PUT_NOTIFICACOES:
      return {
        ...state,
        putNotificacoesLoading: true,
        putNotificacoesSuccess: false,
        putNotificacoesError: false,
      };
    case Types.PUT_NOTIFICACOES_SUCCESS:
      return {
        ...state,
        putNotificacoesLoading: false,
        putNotificacoesSuccess: true,
        putNotificacoesError: false,
        notificacoes: action.payload,
      };
    case Types.PUT_NOTIFICACOES_ERROR:
      return {
        ...state,
        putNotificacoesLoading: false,
        putNotificacoesSuccess: false,
        putNotificacoesError: true,
      };
    case Types.PUT_NOTIFICACOES_RESET:
      return {
        ...state,
        putNotificacoesLoading: false,
        putNotificacoesSuccess: false,
        putNotificacoesError: false,
      };

    case Types.DELETE_NOTIFICACOES:
      return {
        ...state,
        deleteNotificacoesLoading: true,
        deleteNotificacoesSuccess: false,
        deleteNotificacoesError: false,
      };
    case Types.DELETE_NOTIFICACOES_SUCCESS:
      return {
        ...state,
        deleteNotificacoesLoading: false,
        deleteNotificacoesSuccess: true,
        deleteNotificacoesError: false,
        notificacoes: action.payload,
      };
    case Types.DELETE_NOTIFICACOES_ERROR:
      return {
        ...state,
        deleteNotificacoesLoading: false,
        deleteNotificacoesSuccess: false,
        deleteNotificacoesError: true,
      };
    case Types.DELETE_NOTIFICACOES_RESET:
      return {
        ...state,
        deleteNotificacoesLoading: false,
        deleteNotificacoesSuccess: false,
        deleteNotificacoesError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getNotificacoes: (payload) => ({
    type: Types.GET_NOTIFICACOES,
    payload,
  }),
  getNotificacoesSuccess: (payload) => ({
    type: Types.GET_NOTIFICACOES_SUCCESS,
    payload,
  }),
  getNotificacoesError: () => ({
    type: Types.GET_NOTIFICACOES_ERROR,
  }),

  putNotificacoes: (payload) => ({
    type: Types.PUT_NOTIFICACOES,
    payload,
  }),
  putNotificacoesSuccess: (payload) => ({
    type: Types.PUT_NOTIFICACOES_SUCCESS,
    payload,
  }),
  putNotificacoesError: () => ({
    type: Types.PUT_NOTIFICACOES_ERROR,
  }),
  putNotificacoesReset: () => ({
    type: Types.PUT_NOTIFICACOES_RESET,
  }),

  deleteNotificacoes: (payload) => ({
    type: Types.DELETE_NOTIFICACOES,
    payload,
  }),
  deleteNotificacoesSuccess: (payload) => ({
    type: Types.DELETE_NOTIFICACOES_SUCCESS,
    payload,
  }),
  deleteNotificacoesError: () => ({
    type: Types.DELETE_NOTIFICACOES_ERROR,
  }),
  deleteNotificacoesReset: () => ({
    type: Types.DELETE_NOTIFICACOES_RESET,
  }),
};
