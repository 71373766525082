import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import api from 'util/api';
import { findOnArray, replaceConteudoi } from 'util/index';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import { Creators as FilterActions } from 'store/ducks/FilterState';

const styles = () => ({
  paper: {
    minWidth: '40%',
  },
});

const FilterModal = ({ open, setOpen, filtros, classes, paramId = 0 }) => {
  const { filter } = useSelector(({ filterState }) => filterState);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fill, setFill] = useState(false);
  const [items, setItems] = useState([]);
  const filterPath = useLocation().pathname;

  async function loadItems(ids) {
    const promises = ids.map(async (id) => {
      if (id !== null) {
        const { data } = await api.post(`/csql/${id}`, {
          Parametros: [
            {
              nome: ':id',
              valor: paramId,
            },
          ],
        });
        if (data) {
          const { dados } = data;
          return dados || [];
        }
      }
    });
    const results = await Promise.all(promises);
    setItems(results);
    setLoading(false);
  }

  useEffect(() => {
    setItems([]);
    const ids = [];
    filtros.map((i) => {
      if (i.pesquisa_id && i.pesquisa_id !== null) {
        ids.push(i.pesquisa_id);
      } else {
        ids.push(null);
      }
    });
    setLoading(true);
    loadItems(ids);
  }, [open]);

  const onSubmit = (data) => {
    const newFilters = filtros;
    if (newFilters.length > 0) {
      newFilters.map((m, index) => {
        if (!data[index]) {
          m.conteudoi = null;
        } else {
          if (m.tipo === 'TEXTO' || m.tipo === 'ENUM') {
            if (m.pesquisa_id || m.tipo === 'ENUM') {
              m.conteudoi = `'${data[index]?.value}'`;
              m.conteudod = `'${data[index]?.label}'`;
            } else {
              m.conteudoi = `'${data[index]}'`;
            }
          } else {
            m.conteudoi = data[index]?.value || data[index];
            m.conteudod = `'${data[index]?.label || data[index]}'`;
          }
        }
      });
    }
    dispatch(TablesActions.setFiltros(newFilters));
    dispatch(
      FilterActions.setFilterState({
        [filterPath]: { ...filter[filterPath], filtros: newFilters },
      })
    );

    setOpen(null);
  };

  const setInitialValues = () => {
    const initialValues = {};
    filtros.map((f, index) => {
      if (f.pesquisa_id) {
        initialValues[index] =
          replaceConteudoi(f.conteudoi, items[index]) || null;
      } else {
        initialValues[index] = replaceConteudoi(f.conteudoi) || null;
      }
      if (f.tipo === 'ENUM' && !!f?.padrao?.length) {
        initialValues[index] =
          findOnArray(f.conteudoi, setDropDown(f.padrao)) || null;
      }
    });
    return initialValues;
  };

  const SetFields = () => {
    const { setValues } = useFormikContext();

    useEffect(() => {
      if (open && !fill) {
        setValues(setInitialValues());
      }
      if (!open) {
        setFill(false);
      }
    }, [open]);
    return null;
  };

  function setDropDown(value) {
    let parseData = [];
    if (value) {
      value.map((i) => {
        parseData.push({
          value: i,
          label: i,
        });
      });
    }
    return parseData;
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.paper }}
    >
      <Formik>
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <DialogTitle>Filtros</DialogTitle>
            {loading ? (
              <Loading />
            ) : (
              <DialogContent className="d-flex flex-column">
                {setFill(true)}
                {filtros?.map((f, index) => {
                  if (f.restricao === 'VISIVEL') {
                    if (f.pesquisa_id && f.pesquisa_id !== null) {
                      return (
                        <div className="mb-4 d-flex">
                          <div className="w-100">
                            <SelectAuto
                              style={{
                                width: f.tamanho ? `${f.tamanho}em` : null,
                              }}
                              name={index}
                              label={f?.titulo}
                              items={items[index] || []}
                              value={values[index]}
                              onChange={(v) => setFieldValue(`${index}`, v)}
                              disabled={f.parametro === 'SIM'}
                            />
                          </div>
                        </div>
                      );
                    }
                    if (f.tipo === 'ENUM') {
                      return (
                        <SelectAuto
                          className="mb-4"
                          style={{ width: f.tamanho ? `${f.tamanho}em` : null }}
                          name={index}
                          label={f?.titulo}
                          items={setDropDown(f.padrao) || []}
                          value={values[index]}
                          onChange={(v) => setFieldValue(`${index}`, v)}
                          disabled={f.parametro === 'SIM'}
                        />
                      );
                    }
                    if (f.tipo === 'DATA') {
                      return (
                        <InputMask
                          mask="99/99/9999"
                          name={index}
                          label={f?.titulo}
                          value={values[index]}
                          onChange={handleChange}
                          className="mb-4"
                          style={{ width: f.tamanho ? `${f.tamanho}em` : null }}
                          disabled={f.parametro === 'SIM'}
                        />
                      );
                    }
                    return (
                      <TextField
                        name={index}
                        label={f?.titulo}
                        value={values[index]}
                        onChange={handleChange}
                        className="mb-4"
                        style={{ width: f.tamanho ? `${f.tamanho}em` : null }}
                        disabled={f.parametro === 'SIM'}
                      />
                    );
                  }
                })}
              </DialogContent>
            )}
            <DialogActions>
              <Button color="primary" onClick={() => onSubmit(values)}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(null)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(FilterModal);
