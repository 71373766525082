import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import AppNotification from '../../../../components/AppNotification';
import CardHeader from 'components/Card/CardHeader';
import { Creators as SettingActions } from '../../../../store/ducks/Setting';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import ColorOption from 'containers/Customizer/ColorOption';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState(false);
  const [appNotification, setAppNotification] = useState(false);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const { notificacoes } = useSelector(({ notificacoes }) => notificacoes);

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification);
  };

  const onUserInfoSelect = () => {
    setUserInfo(!userInfo);
  };

  const onToggleCollapsedNav = (e) => {
    dispatch(SettingActions.toggleCollapsedNav());
  };

  return (
    <AppBar className="app-main-header app-main-header-top">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <div
          className="d-block d-md-none pointer mr-3"
          onClick={onToggleCollapsedNav}
        >
          <span className="jr-menu-icon">
            <span className="menu-icon" />
          </span>
        </div>

        {/* <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img
            src={require('assets/images/logo.png')}
            alt="Jambo"
            title="Jambo"
          />
        </Link> */}

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item app-tour">
            <IconButton
              style={{ padding: '6px' }}
              onClick={() => history.push(`/app/Guia`)}
            >
              <i class="zmdi zmdi-apps text-white"></i>
            </IconButton>
          </li>
          <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i
                    className={`zmdi zmdi-notifications-none infinite wobble ${notificacoes.length &&
                      'icon-alert'}`}
                  />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader
                  styleName="align-items-center"
                  heading="Notificações"
                />
                <AppNotification notificacoes={notificacoes} />
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="list-inline-item mail-tour">
            <IconButton
              style={{ padding: '6px' }}
              onClick={() => setDrawerStatus(!drawerStatus)}
            >
              <i className="zmdi zmdi-settings  text-white" />
            </IconButton>
          </li>
          <li className="list-inline-item user-nav">
            <Dropdown
              className="quick-menu"
              isOpen={userInfo}
              toggle={onUserInfoSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn size-30">
                  <Avatar
                    alt="..."
                    src="https://via.placeholder.com/150x150"
                    className="size-30"
                  />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <UserInfoPopup />
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
        <ColorOption
          drawerStatus={drawerStatus}
          setDrawerStatus={setDrawerStatus}
        />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Header);
