import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { transformData, setDecimal, findOnArray } from 'util/index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import { Formik, useFormikContext } from 'formik';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  gridRadio: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const ParcelaModal = ({
  open,
  setOpen,
  dropPgto,
  documento,
  selected,
  setSelected,
  classes,
}) => {
  const dispatch = useDispatch();

  const SetFields = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (selected) {
        setValues({
          parcela: selected?.parcela,
          vencimento:
            selected?.dtvenc && moment(selected?.dtvenc).format('DD/MM/YYYY'),
          formaPagamento:
            selected?.forma_pagto_id &&
            findOnArray(selected?.forma_pagto_id, dropPgto),
          valor: selected?.valor && setDecimal(selected?.valor, 2),
          observacao: selected?.observacao,
        });
      }
    }, [selected]);
    return null;
  };

  const onSend = (values) => {
    const data = {
      documento_id: documento?.Documento?.id,
      parcela: Number(values?.parcela),
      dtvenc: moment(values?.vencimento, 'DD/MM/YYYY').isValid()
        ? moment(values?.vencimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      forma_pagto_id: values?.formaPagamento?.value,
      valor: transformData(values?.valor, 'number'),
      observacao: values?.observacao,
    };
    if (selected) {
      dispatch(DocumentoActions.putParcela({ data, id: selected?.id }));
    } else {
      dispatch(DocumentoActions.postParcela(data));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      onExit={() => setSelected(null)}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <Formik
        initialValues={{
          parcela: null,
          vencimento: null,
          formaPagamento: null,
          valor: null,
          observacao: null,
        }}
        onSubmit={(values) => onSend(values)}
      >
        {({ values, handleChange, submitForm, setFieldValue }) => (
          <>
            <SetFields />
            <DialogTitle>Nova Parcela</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <TextField
                    name="parcela"
                    label="Parcela"
                    value={values?.parcela || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <InputMask
                    name="vencimento"
                    label="Vencimento"
                    mask="99/99/9999"
                    value={values.vencimento || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <SelectAuto
                    label="Forma de Pagamento"
                    items={dropPgto}
                    value={values?.formaPagamento}
                    onChange={(v) => setFieldValue('formaPagamento', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
                  <TextField
                    name="valor"
                    label="Valor"
                    value={values.valor || ''}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('valor', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="observacao"
                    label="Observação"
                    value={values?.observacao || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={submitForm}>
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(ParcelaModal);
