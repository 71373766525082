import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as CatalogoActions,
  Types as CatalogoTypes,
} from 'store/ducks/Catalogo';
import api from '../../util/api';

function* getCatalogo({ payload }) {
  try {
    const { status, data } = yield call(api.post, `/produto-catalogo`, payload);
    if (status === 200 || status === 201) {
      yield put(
        CatalogoActions.getCatalogoSuccess({
          catalogo: data?.registros,
        })
      );
    } else {
      yield put(CatalogoActions.getCatalogoReset());
    }
  } catch (error) {
    yield put(CatalogoActions.getCatalogoReset());
  }
}

function* getCatalogoWatcher() {
  yield takeLatest(CatalogoTypes.GET_CATALOGO, getCatalogo);
}

export default function* rootSaga() {
  yield all([fork(getCatalogoWatcher)]);
}
