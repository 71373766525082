import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import { findOnArray } from 'util/index';
import { Formik, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputMask from 'components/InputMask';
import SelectAuto from 'components/SelectAuto';
import SearchEnderecoModal from 'components/Modals/SearchEnderecoModal';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as IbgeActions } from '../../../store/ducks/Ibge';
import { Creators as EntidadesActions } from '../../../store/ducks/Entidades';

const styles = () => ({
  paper: {
    minWidth: '90%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const EntidadeModal = ({
  open,
  setOpen,
  identificacao,
  doc,
  refresh,
  classes,
}) => {
  const dispatch = useDispatch();
  const [UF, setUF] = useState(null);
  const [fill, setFill] = useState(false);
  const [searchEnderecoModal, setSearchEnderecoModal] = useState(false);
  const { estados, cidades, dataCep } = useSelector(({ ibge }) => ibge);
  const { postLoading, postSuccess, putLoading, putSuccess } = useSelector(
    ({ entidades }) => entidades
  );

  useEffect(() => {
    if (open) {
      dispatch(IbgeActions.getCepReset());
      dispatch(IbgeActions.getEstados());
    }
  }, [open]);

  useEffect(() => {
    if (open && UF && UF !== null) {
      dispatch(IbgeActions.getCidades({ uf: UF.value || UF }));
    }
  }, [UF]);

  useEffect(() => {
    if (postSuccess) {
      if (refresh) {
        refresh();
      }
      dispatch(EntidadesActions.postEntidadeReset());
      if (doc) {
        dispatch(
          EntidadesActions.getIdentificacao(doc.replace(/([\/\.\-])/g, ''))
        );
      }
      setOpen(false);
    }
  }, [postSuccess]);

  useEffect(() => {
    if (putSuccess) {
      if (refresh) {
        refresh();
      }
      dispatch(EntidadesActions.putEntidadeReset());
      if (doc) {
        dispatch(
          EntidadesActions.getIdentificacao(doc.replace(/([\/\.\-])/g, ''))
        );
      }
      setOpen(false);
    }
  }, [putSuccess]);

  const SetFields = () => {
    const { setValues, setFieldValue, values } = useFormikContext();

    useEffect(() => {
      if (identificacao && !fill) {
        setFill(true);
        setValues({
          ...values,
          social: identificacao?.Entidade?.nome,
          fixo: identificacao.Contatos
            ? identificacao.Contatos.find((i) => i.tipo_contato_id === 1)
                ?.conteudo
            : null,
          celular: identificacao.Contatos
            ? identificacao.Contatos.find((i) => i.tipo_contato_id === 2)
                ?.conteudo
            : null,
          email: identificacao.Contatos
            ? identificacao.Contatos.find((i) => i.tipo_contato_id === 3)
                ?.conteudo
            : null,
          cep: identificacao.Enderecos
            ? identificacao.Enderecos.find((i) => i.principal === 'SIM')?.cep
            : null,
          estado: identificacao.Enderecos
            ? findOnArray(
                identificacao.Enderecos.find((i) => i.principal === 'SIM')?.uf,
                estados
              )
            : null,
          cidade: identificacao.Enderecos
            ? {
                value: identificacao.Enderecos.find(
                  (i) => i.principal === 'SIM'
                )?.cidade,
                label: identificacao.Enderecos.find(
                  (i) => i.principal === 'SIM'
                )?.cidade,
              }
            : null,
          bairro: identificacao.Enderecos
            ? identificacao.Enderecos.find((i) => i.principal === 'SIM')?.bairro
            : null,
          logradouro: identificacao.Enderecos
            ? identificacao.Enderecos.find((i) => i.principal === 'SIM')
                ?.logradouro
            : null,
          numero: identificacao.Enderecos
            ? identificacao.Enderecos.find((i) => i.principal === 'SIM')?.numero
            : null,
          complemento: identificacao.Enderecos
            ? identificacao.Enderecos.find((i) => i.principal === 'SIM')
                ?.complemento
            : null,
        });
      }
    }, [identificacao]);

    useEffect(() => {
      if (dataCep) {
        setFieldValue('estado', findOnArray(dataCep?.uf, estados));
        setUF(findOnArray(dataCep?.uf, estados));
      }
    }, [dataCep]);

    useEffect(() => {
      if (dataCep && !!cidades.length) {
        setFieldValue('cidade', findOnArray(Number(dataCep?.ibge), cidades));
        setFieldValue('bairro', dataCep?.bairro);
        setFieldValue('logradouro', dataCep?.logradouro);
        dispatch(IbgeActions.getCepReset());
      }
    }, [dataCep, cidades]);

    return null;
  };

  const onPost = (values) => {
    const localContatos = [
      { tipo_contato_id: 1, conteudo: values?.fixo, principal: 'SIM' },
      { tipo_contato_id: 2, conteudo: values?.celular, principal: 'NAO' },
      { tipo_contato_id: 3, conteudo: values?.email, principal: 'NAO' },
    ];
    const localDocumentos = [
      {
        tipo_documento_id:
          values?.numDoc && values?.numDoc.length === 14 ? 2 : 1,
        documento: values?.numDoc,
        principal: 'SIM',
      },
    ];

    const form = {
      Entidade: {
        nome: values?.social,
      },
      Enderecos: [
        {
          tipo_endereco_id: 1,
          logradouro: values?.logradouro,
          numero: values?.numero,
          complemento: values?.complemento,
          bairro: values?.bairro,
          uf: values?.estado?.value,
          cep: values?.cep,
          principal: 'SIM',
          codigo_ibge: values?.cidade?.value,
        },
      ],
      Contatos: localContatos
        .map((l) => {
          if (l.conteudo && l.conteudo !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean),
      Documentos: localDocumentos
        .map((l) => {
          if (l.documento && l.documento !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean),
    };

    dispatch(EntidadesActions.postEntidade(form));
  };

  const onPut = (values) => {
    const localContatos = [
      { tipo_contato_id: 1, conteudo: values?.fixo, principal: 'SIM' },
      { tipo_contato_id: 2, conteudo: values?.celular, principal: 'NAO' },
      { tipo_contato_id: 3, conteudo: values?.email, principal: 'NAO' },
    ];

    function loadContacts() {
      let newList = identificacao?.Contatos || [];
      let newLocalContatos = localContatos
        .map((l) => {
          if (l.conteudo && l.conteudo !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean);
      newLocalContatos.map((l) => {
        let finder = newList.find(
          (f) => f.tipo_contato_id === l.tipo_contato_id
        );
        if (!finder) {
          newList.push(l);
        }
      });
      return newList;
    }

    const localDocumentos = [
      {
        tipo_documento_id:
          values?.numDoc && values?.numDoc.length === 14 ? 2 : 1,
        documento: values?.numDoc,
        principal: 'SIM',
      },
    ];

    function loadDocs() {
      let newList = identificacao?.Documentos || [];
      let newLocalDocs = localDocumentos
        .map((l) => {
          if (l.documento && l.documento !== '') {
            return l;
          } else {
            return undefined;
          }
        })
        .filter(Boolean);
      newLocalDocs.map((l) => {
        let finder = newList.find(
          (f) => f.tipo_documento_id === l.tipo_documento_id
        );
        if (!finder) {
          newList.push(l);
        }
      });
      return newList;
    }

    const form = {
      ...identificacao,
      Entidade: {
        ...identificacao?.Entidade,
        nome: values?.social,
      },
      Enderecos: identificacao.Enderecos
        ? identificacao.Enderecos.map((e) => {
            if (e.principal === 'SIM') {
              return {
                ...e,
                tipo_endereco_id: 1,
                logradouro: values?.logradouro,
                numero: values?.numero,
                complemento: values?.complemento,
                bairro: values?.bairro,
                uf: values?.estado?.value,
                cep: values?.cep,
                principal: 'SIM',
                codigo_ibge: values?.cidade?.value,
              };
            }
            return e;
          })
        : [],
      Contatos: loadContacts().map((c) => {
        let locFinder = localContatos.find(
          (l) => l.tipo_contato_id === c.tipo_contato_id
        );
        if (locFinder) {
          return { ...c, conteudo: locFinder.conteudo };
        }
        return c;
      }),
      Documentos: loadDocs().map((d) => {
        let locFinder = localDocumentos.find(
          (l) => l.tipo_documento_id === d.tipo_documento_id
        );
        if (locFinder) {
          return { ...d, documento: locFinder.documento };
        }
        return d;
      }),
    };

    dispatch(
      EntidadesActions.putEntidade({
        data: form,
        id: identificacao?.Entidade?.id,
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.paper }}
      onExited={() => setFill(false)}
    >
      <Formik
        initialValues={{
          numDoc: doc || null,
          social: null,
          fixo: null,
          celular: null,
          email: null,
          cep: null,
          estado: null,
          cidade: null,
          bairro: null,
          logradouro: null,
          numero: null,
          complemento: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <SearchEnderecoModal
              open={searchEnderecoModal}
              setOpen={setSearchEnderecoModal}
              setFieldValue={setFieldValue}
            />
            <div className="d-flex align-items-center justify-content-between">
              <DialogTitle>Entidade</DialogTitle>
              <IconButton
                className="icon-btn mx-2"
                onClick={() => setSearchEnderecoModal(true)}
              >
                <SearchIcon color="primary" />
              </IconButton>
            </div>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="numDoc"
                    label="Nº Documento do Cliente"
                    value={values.numDoc}
                    onChange={handleChange}
                    fullWidth
                    disabled={!!doc}
                    onBlur={(e) => {
                      let parseDoc = e.target.value.replace(/([\/\.\-])/g, '');
                      if (parseDoc.length === 14) {
                        parseDoc = `${parseDoc.substr(0, 2)}.${parseDoc.substr(
                          2,
                          3
                        )}.${parseDoc.substr(5, 3)}/${parseDoc.substr(
                          8,
                          4
                        )}-${parseDoc.substr(12, 2)}`;
                      }
                      if (parseDoc.length === 11) {
                        parseDoc = `${parseDoc.substr(0, 3)}.${parseDoc.substr(
                          3,
                          3
                        )}.${parseDoc.substr(6, 3)}-${parseDoc.substr(9, 2)}`;
                      }
                      setFieldValue('numDoc', parseDoc);
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="social"
                    label="Razão Social"
                    value={values.social}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="fixo"
                    label="Residencial"
                    mask="(99) 9999-9999"
                    value={values.fixo}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="celular"
                    label="Celular"
                    mask="(99) 99999-9999"
                    value={values.celular}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="email"
                    label="E-mail"
                    value={values.email}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <InputMask
                    name="cep"
                    label="CEP"
                    mask="99.999-999"
                    value={values.cep || ''}
                    onChange={(e) => {
                      const v = e.target.value.replace(/[.-]/g, '');
                      if (v.length === 8) dispatch(IbgeActions.getCep(v));
                      setFieldValue('cep', v);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Estado"
                    items={estados}
                    value={values.estado}
                    onChange={(v) => {
                      setUF(v);
                      setFieldValue('estado', v);
                    }}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <SelectAuto
                    label="Cidade"
                    items={cidades}
                    value={values.cidade}
                    onChange={(v) => setFieldValue('cidade', v)}
                  />
                </Grid>
                <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                  <TextField
                    name="bairro"
                    label="Bairro"
                    value={values.bairro || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                  <TextField
                    name="logradouro"
                    label="Endereço"
                    value={values.logradouro || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="numero"
                    label="Número"
                    value={values.numero}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                  <TextField
                    name="complemento"
                    label="Complemento"
                    value={values.complemento}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => (identificacao ? onPut(values) : onPost(values))}
                color="primary"
                disabled={postLoading || putLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(false)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(EntidadeModal);
