import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Grid,
  Fab,
  Badge,
  TextField,
  Button,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CheckIcon from '@material-ui/icons/Check';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import SearchBox from 'components/SearchBox';
import SelectAuto from 'components/SelectAuto';
import DetailsModal from 'components/Modals/CatalogoDetailModal';
import ShopModal from 'components/Modals/ShopModal';
import { useQuery, setDecimal, transformData } from 'util/index';
import { Creators as CatalogoActions } from 'store/ducks/Catalogo';
import { Creators as DocumentoActions } from 'store/ducks/Documento';

const styles = (theme) => ({
  icon: {
    color: 'white',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  root: {
    flex: 1,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tile: {
    marginBottom: '4em',
  },
  tileBar: {
    backgroundColor: 'rgba(0, 0, 0)',
  },
  pos: {
    marginRight: '10px',
  },
  fab: {
    position: 'absolute',
    zIndex: 999,
    bottom: 50,
    right: 50,
  },
  titletile: {
    fontSize: 12,
  },
});

const Catalogo = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const param = query.get('id');
  const [count, setCount] = useState({ number: 0, index: 0 });
  const [search, setSearch] = useState('');
  const [days, setDays] = useState('');
  const [saldo, setSaldo] = useState(null);
  const [shopCart, setShopCart] = useState([]);
  const [openDetails, setOpenDetails] = useState(null);
  const [openShop, setOpenShop] = useState(null);
  const { capaSuccess } = useSelector(({ documento }) => documento);
  const { catalogo, getLoading } = useSelector(({ catalogo }) => catalogo);
  const largeMatch = useMediaQuery('(min-width:1200px)');
  const mediumMatch = useMediaQuery('(min-width:992px)');
  const smallMatch = useMediaQuery('(min-width:768px)');

  const getCols = () => {
    if (largeMatch) {
      return 4;
    }
    if (mediumMatch) {
      return 3;
    }
    if (smallMatch) {
      return 2;
    }
    return 1;
  };

  const getCatalogo = () => {
    let data = {
      documento_id: Number(param),
    };
    if (Boolean(days)) {
      data = { ...data, ultcomp: transformData(days, 'number') };
    }
    if (Boolean(saldo)) {
      data = { ...data, saldo: saldo?.value };
    }
    if (!!search && search !== '') {
      data = { ...data, pesquisa: search };
    }
    dispatch(CatalogoActions.getCatalogo(data));
  };

  useEffect(() => {
    if (capaSuccess) {
      dispatch(DocumentoActions.postCapaReset());
      dispatch(DocumentoActions.postCapaResetId());
      dispatch(CatalogoActions.getCatalogoReset());
      history.goBack();
    }
  }, [capaSuccess]);

  return (
    <div className="app-wrapper">
      <HeaderPage title="Catálogo de Produtos" history={history} />
      <Card className="shadow mb-4">
        <CardContent>
          <h3>Filtrar por...</h3>
          <Grid container>
            <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
              <SearchBox
                placeholder="Buscar..."
                onChange={(evt) => setSearch(evt.target.value)}
                value={search}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
              <TextField
                name="days"
                label="Comprados nos últimos (Dias)"
                value={days}
                onChange={(evt) => setDays(evt.target.value)}
                fullWidth
              />
            </Grid>
            <Grid xs="12" sm="3" md="3" lg="3" className={classes.grid}>
              <SelectAuto
                label="Somente produto com saldo"
                items={[
                  { value: 'SIM', label: 'SIM' },
                  { value: 'NAO', label: 'NÃO' },
                ]}
                value={saldo}
                onChange={(v) => setSaldo(v)}
              />
            </Grid>
          </Grid>
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              color="primary"
              className="jr-btn"
              onClick={getCatalogo}
            >
              BUSCAR
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card className="shadow">
        <CardContent>
          {getLoading && !catalogo.length ? (
            <Loading />
          ) : (
            <div className={classes.root}>
              <GridList
                cols={getCols()}
                cellHeight={300}
                className={classes.gridList}
              >
                {catalogo.map((tile, index) => {
                  return (
                    <GridListTile
                      key={tile.id}
                      classes={{ root: classes.tile }}
                    >
                      {tile.Imagens.length && (
                        <img
                          src={
                            tile?.Imagens[
                              count.index === index ? count.number : 0
                            ]?.imagem
                          }
                          alt={tile.descricaoc}
                          className={classes.img}
                        />
                      )}
                      <GridListTileBar
                        titlePosition="top"
                        title={tile.descricaoc}
                        subtitle={
                          shopCart.find((f) => f.id === tile.id) ? (
                            <div className="d-flex align-items-center">
                              <CheckIcon fontSize="small" color="white" />
                              <div className="MuiGridListTileBar-subtitle">
                                Já acidionado
                              </div>
                            </div>
                          ) : (
                            ' '
                          )
                        }
                        classes={{
                          root: classes.tileBar,
                          title: classes.titletile,
                          titleWrapActionPosRight: classes.pos,
                        }}
                        actionIcon={
                          <IconButton
                            size="small"
                            className={classes.icon}
                            onClick={() => setOpenDetails(tile)}
                          >
                            <div className={classes.buttonContainer}>
                              <AddShoppingCartIcon fontSize="small" />
                            </div>
                          </IconButton>
                        }
                      />
                      <GridListTileBar
                        title={`R$ ${setDecimal(tile.preco, 2)}`}
                        subtitle={
                          tile?.ucquantidade
                            ? `Comprou ${tile?.ucquantidade} à ${tile?.ucdias} dias`
                            : ' '
                        }
                        classes={{
                          root: classes.tileBar,
                          titleWrapActionPosRight: classes.pos,
                        }}
                        actionIcon={
                          <div className={classes.buttonContainer}>
                            <IconButton
                              size="small"
                              className={classes.icon}
                              onClick={() => {
                                if (count.number > 0)
                                  setCount({
                                    ...count,
                                    index,
                                    number: count.number - 1,
                                  });
                              }}
                            >
                              <ArrowBackIosIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              className={classes.icon}
                              onClick={() => {
                                if (
                                  index !== count.index &&
                                  tile.Imagens.length > 1
                                ) {
                                  setCount({
                                    ...count,
                                    index,
                                    number: 1,
                                  });
                                } else if (
                                  count.number <
                                  tile.Imagens.length - 1
                                ) {
                                  setCount({
                                    ...count,
                                    index,
                                    number: count.number + 1,
                                  });
                                }
                              }}
                            >
                              <ArrowForwardIosIcon fontSize="small" />
                            </IconButton>
                          </div>
                        }
                      />
                    </GridListTile>
                  );
                })}
              </GridList>
            </div>
          )}
        </CardContent>
      </Card>
      <Fab
        color="primary"
        classes={{ root: classes.fab }}
        onClick={() => setOpenShop(true)}
      >
        <Badge badgeContent={shopCart.length} color="secondary">
          <ShoppingCartIcon fontSize="small" setShopCart={setShopCart} />
        </Badge>
      </Fab>
      <DetailsModal
        open={openDetails}
        setOpen={setOpenDetails}
        shopCart={shopCart}
        setShopCart={setShopCart}
      />
      <ShopModal
        open={openShop}
        setOpen={setOpenShop}
        shopCart={shopCart}
        setShopCart={setShopCart}
        documentoId={param}
      />
    </div>
  );
};

export default withStyles(styles)(Catalogo);
