import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid, TextField, Button } from '@material-ui/core';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import { Creators as AuthActions } from '../../../store/ducks/Auth';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const Perfil = ({ match, classes }) => {
  const dispatch = useDispatch();
  const { authUser, updateUserLoading, updateUserSuccess } = useSelector(
    ({ auth }) => auth
  );

  const SetFields = () => {
    const { setValues } = useFormikContext();
    useEffect(() => {
      if (authUser) {
        setValues({
          nome: authUser?.storedName,
          email: authUser?.storedMail,
        });
      }
    }, [authUser]);
    return null;
  };

  const onSend = (values) => {
    let data = {
      nome: values?.nome,
      email: values?.email,
      senha: values?.senha && values?.senha !== '' ? values?.senha : undefined,
      novasenha:
        values?.novasenha && values?.novasenha !== ''
          ? values?.novasenha
          : undefined,
      confsenha:
        values?.confsenha && values?.confsenha !== ''
          ? values?.confsenha
          : undefined,
    };
    dispatch(AuthActions.updateUser(data));
  };

  return (
    <div className="app-wrapper text-center">
      <HeaderPage title="Alterar Perfil do Usuário" />
      <Card>
        <CardContent>
          {updateUserLoading ? (
            <Loading />
          ) : (
            <Formik
              initialValues={{
                nome: null,
                email: null,
                senha: null,
                novasenha: null,
                confsenha: null,
              }}
            >
              {({ values, handleChange }) => (
                <Grid container>
                  <SetFields />
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="nome"
                      label="Nome"
                      value={values.nome}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      type="password"
                      name="senha"
                      label="Senha"
                      value={values.senha}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      type="password"
                      name="novasenha"
                      label="Nova Senha"
                      value={values.novasenha}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      type="password"
                      name="confsenha"
                      label="Confirmar Nova Senha"
                      value={values.confsenha}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <div
                    className="d-flex justify-content-end mt-2"
                    style={{ flex: 1 }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => onSend(values)}
                    >
                      ATUALIZAR
                    </Button>
                  </div>
                </Grid>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Perfil);
