import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { setDecimal, transformData } from 'util/index';
import SelectAuto from 'components/SelectAuto';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as ProdutoActions } from '../../../store/ducks/Produto';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const AjusteVolumeModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [volume, setVolume] = useState(null);
  const [producao, setProducao] = useState(null);
  const { putAjustaVolumeLoading } = useSelector(({ produto }) => produto);

  const onSubmit = () => {
    let fator;
    if (!selected?.volume) {
      fator = 0;
    } else {
      fator =
        transformData(setDecimal(selected?.volume, 10), 'number') /
        transformData(volume, 'number');
    }
    const data = {
      volume: transformData(volume, 'number'),
      fator,
      estrutura: producao?.value,
    };
    dispatch(ProdutoActions.putAjustaVolume({ id: selected?.id, data }));
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      fullWidth
      onClose={() => setOpen(!open)}
    >
      <DialogTitle>Ajustar Volume - {selected?.descricao}</DialogTitle>
      <DialogContent>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <TextField
            label="Volume"
            value={volume || ''}
            onChange={(e) => setVolume(e.target.value)}
            onBlur={(e) => setVolume(setDecimal(e.target.value, 10))}
            fullWidth
          />
        </Grid>
        <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
          <SelectAuto
            label="Ajustar Volume em Produção"
            items={yn}
            value={producao}
            onChange={(v) => setProducao(v)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={putAjustaVolumeLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(AjusteVolumeModal);
