import socketIO from 'socket.io-client';
import { getUrl, getToken } from 'util/index';

const socketUrl = getUrl()?.replace('/api', '');

const socket = 
{
  sk : getToken() ? socketIO(socketUrl, 
    {
      //transports: ['websocket'],
      //jsonp: false,
      query: { token : getToken() }
    }) : null
};

export default socket;
