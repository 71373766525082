import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  CardContent,
  TextField,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import HeaderPage from 'components/HeaderPage';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import LancDetalhesModal from 'components/Modals/LancDetalhesModal';
import api from 'util/api';
import { findOnArray, transformData, useQuery, setDecimal } from 'util/index';
import { Creators as DocumentoActions } from 'store/ducks/Documento';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const yn = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

const NovoLancamento = ({ match, classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery(useLocation);
  const conta = query.get('conta');
  const [localLoading, setLocalLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);
  const [Detalhes, setDetalhes] = useState([]);
  const [fill, setFill] = useState(false);
  const {
    planoConta,
    postLancamentoLoading,
    postLancamentoSuccess,
  } = useSelector(({ documento }) => documento);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    loadItems([607, 658, 709, 710]);
  }, []);

  useEffect(() => {
    if (postLancamentoSuccess) {
      dispatch(DocumentoActions.postLancamentoReset());
      history.goBack();
    }
  }, [postLancamentoSuccess]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();
    useEffect(() => {
      if (items.length && conta && !fill) {
        setFill(true);
        let ct = findOnArray(Number(conta), items[0]);
        setValues({
          ...values,
          conta: ct,
          conciliado: findOnArray(ct?.conciliacao, yn),
        });
        dispatch(
          DocumentoActions.getPlanoConta({
            Parametros: [
              {
                nome: ':filial_id',
                valor: `${ct?.entidade_id}`,
              },
            ],
          })
        );
      }
    }, [items]);
    return null;
  };

  const submitForm = (values) => {
    let data = {
      conta_id: values?.conta?.value,
      conciliado: values?.conciliado?.value,
      dtemis: moment(values?.dtemissao, 'DD/MM/YYYY').isValid()
        ? moment(values?.dtemissao, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      dtlanc: moment(values?.dtlancamento, 'DD/MM/YYYY').isValid()
        ? moment(values?.dtlancamento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      documentocx: values?.documento,
    };
    data = {
      ...data,
      Detalhes,
    };
    dispatch(DocumentoActions.postLancamento(data));
  };

  if (localLoading) {
    return <Loading />;
  }

  return (
    <div className="app-wrapper">
      <HeaderPage title="Novo Lançamento" history={history} />
      <Formik
        initialValues={{
          conta: null,
          dtemissao: moment().format('DD/MM/YYYY'),
          dtlancamento: moment().format('DD/MM/YYYY'),
          conciliado: null,
          documento: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <SetFields />
            <Card className="shadow">
              <CardContent>
                <Grid container>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Conta"
                      items={items[0]}
                      value={values.conta}
                      onChange={(v) => setFieldValue('conta', v)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className="shadow mt-4">
              <CardContent>
                <Grid container>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <InputMask
                      name="dtemissao"
                      label="Data Emissão"
                      mask="99/99/9999"
                      value={values.dtemissao}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <InputMask
                      name="dtlancamento"
                      label="Data Lançamento"
                      mask="99/99/9999"
                      value={values.dtlancamento}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Conciliado"
                      items={yn}
                      value={values.conciliado}
                      onChange={(v) => setFieldValue('conciliado', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <TextField
                      name="documento"
                      label="Documento"
                      value={values.documento}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className="shadow mt-4">
              <CardContent>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h3 className="mb-0">Detalhes</h3>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="jr-btn"
                    color="primary"
                    onClick={() => setOpenModal(!openModal)}
                  >
                    ADICIONAR DETALHES
                  </Button>
                </div>
                {Detalhes.map((d, index) => (
                  <ExpansionPanel elevation={3}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <h4
                        className="d-flex mb-0 align-items-center"
                        style={{ flex: 3 }}
                      >
                        {d.historico}
                      </h4>
                      <h4
                        className="d-flex mb-0 align-items-center"
                        style={{ flex: 1 }}
                      >
                        {setDecimal(d?.valor, 2)}
                      </h4>
                      <DeleteIcon
                        style={{ opacity: 0.5 }}
                        onClick={() =>
                          setDetalhes(Detalhes.filter((f, i) => i !== index))
                        }
                      />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        <h4>
                          <h4 className="mb-0 font-weight-bold">
                            Conta Contábil:{' '}
                          </h4>
                          {findOnArray(d?.planoconta_id, planoConta)?.label}
                        </h4>
                        <h4>
                          <h4 className="mb-0 font-weight-bold">
                            {' '}
                            Centro Custo:{' '}
                          </h4>
                          {findOnArray(d?.ccusto_id, items[1])?.label}
                        </h4>
                        <h4>
                          <h4 className="mb-0 font-weight-bold">Valor: </h4>
                          {setDecimal(d?.valor, 2)}
                        </h4>
                        <h4>
                          <h4 className="mb-0 font-weight-bold">Aplicação: </h4>
                          {findOnArray(d?.aplicacao_id, items[2])?.label}
                        </h4>
                        <h4>
                          <h4 className="mb-0 font-weight-bold">Frota: </h4>
                          {findOnArray(d?.frota_id, items[3])?.label}
                        </h4>
                        <h4>
                          <h4 className="mb-0 font-weight-bold">Histórico: </h4>
                          {d?.historico}
                        </h4>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="d-flex w-100 justify-content-end mt-4">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn"
                    color="primary"
                    onClick={() => submitForm(values)}
                    disabled={postLancamentoLoading}
                  >
                    FINALIZAR
                  </Button>
                </div>
              </CardContent>
            </Card>
            <LancDetalhesModal
              open={openModal}
              setOpen={setOpenModal}
              items={items}
              planoConta={planoConta}
              Detalhes={Detalhes}
              setDetalhes={setDetalhes}
              contacp={values?.conta?.value}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(NovoLancamento);
