import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import SelectAuto from 'components/SelectAuto';
import api from 'util/api';
import { setDecimal, transformData } from 'util/index';
import { Creators as ProdutoActions } from 'store/ducks/Produto';
import { useDispatch, useSelector } from 'react-redux';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
  button: {
    margin: '0 5px',
  },
});

const EtiquetaModal = ({ open, setOpen, produtos, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { postEtiquetaLoading } = useSelector(({ produto }) => produto);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([912, 910]);
    }
  }, [open]);

  const onSend = (values) => {
    const prods = { ...values };
    delete prods.modelo;
    delete prods.impressora;
    const data = {
      modelo_id: values?.modelo?.value,
      impressora_id: values?.impressora?.value,
      dado_impressao: [],
    };
    Object.entries(prods).forEach(([key, value]) => {
      data.dado_impressao.push({
        id: Number(key),
        qtde: transformData(value, 'number'),
      });
    });
    dispatch(ProdutoActions.postEtiqueta(data));
  };

  const setInitialValues = () => {
    const initialValues = {
      modelo: null,
      impressora: null,
    };
    produtos.map((i) => {
      initialValues[i?.id] = setDecimal('1', 2);
    });
    return initialValues;
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <Formik initialValues={setInitialValues()}>
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogTitle>Impressão de Etiquetas</DialogTitle>
            <DialogContent>
              <Grid container className="my-4">
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Modelo Etiqueta"
                    items={items[0] || []}
                    value={values?.modelo}
                    onChange={(v) => setFieldValue('modelo', v)}
                    fullWidth
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Impressora"
                    items={items[1] || []}
                    value={values?.impressora}
                    onChange={(v) => setFieldValue('impressora', v)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produto</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!produtos.length &&
                    produtos.map((i) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {i.descricao}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          <TextField
                            name={i?.id}
                            value={values[i?.id]}
                            onChange={handleChange}
                            onBlur={(e) =>
                              setFieldValue(
                                i?.id,
                                setDecimal(e.target.value, 2)
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => onSend(values)}
                disabled={postEtiquetaLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(EtiquetaModal);
