import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Creators as SettingActions } from '../../store/ducks/Setting';

import { Button, ButtonGroup } from 'reactstrap';

const Customizer = () => {
  const dispatch = useDispatch();

  const drawerType = useSelector(({ settings }) => settings.drawerType);
  const navigationStyle = useSelector(
    ({ settings }) => settings.navigationStyle
  );
  const horizontalNavPosition = useSelector(
    ({ settings }) => settings.horizontalNavPosition
  );

  const setFixedDrawer = () => {
    dispatch(SettingActions.setDrawerType('fixed_drawer'));
  };

  const setCollapsedDrawer = () => {
    dispatch(SettingActions.setDrawerType('collapsible'));
  };

  const setMiniDrawer = () => {
    dispatch(SettingActions.setDrawerType('mini_drawer'));
  };

  return (
    <div className="side-nav-option">
      <div className="mb-1">
        <h3 className="mb-1 mt-4">Menu</h3>
        <div className="text-left py-3">
          <FormControl className="d-block" component="fieldset" required>
            <RadioGroup
              className="sidenav-dir"
              aria-label="nav-style"
              name="navStyle"
              value={navigationStyle}
              onChange={(event) => {
                dispatch(
                  SettingActions.changeNavigationStyle(event.target.value)
                );
              }}
            >
              <FormControlLabel
                control={<Radio />}
                value={'horizontal_navigation'}
                label="Horizontal"
              />
              <FormControlLabel
                control={<Radio />}
                value="vertical_navigation"
                label="Vertical"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {navigationStyle === 'horizontal_navigation' ? (
        <ButtonGroup>
          <Button
            color="default"
            className={`jr-btn  ${horizontalNavPosition ===
              'inside_the_header' && 'active'} `}
            onClick={() => {
              dispatch(
                SettingActions.setHorizontalMenuPosition('inside_the_header')
              );
            }}
          >
            Incluso
          </Button>
          {/* <Button
            color="default"
            className={`jr-btn ${horizontalNavPosition === ABOVE_THE_HEADER &&
              'active'} `}
            onClick={() => {
              dispatch(
                SettingActions.setHorizontalMenuPosition(ABOVE_THE_HEADER)
              );
            }}
          >
            Acima
          </Button> */}
          <Button
            color="default"
            className={`jr-btn ${horizontalNavPosition === 'below_the_header' &&
              'active'} `}
            onClick={() => {
              dispatch(
                SettingActions.setHorizontalMenuPosition('below_the_header')
              );
            }}
          >
            Abaixo
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          <Button
            color="default"
            className={`jr-btn  ${drawerType === 'fixed_drawer' && 'active'} `}
            onClick={setFixedDrawer}
          >
            Fixo
          </Button>
          <Button
            color="default"
            className={`jr-btn ${drawerType === 'collapsible' && 'active'} `}
            onClick={setCollapsedDrawer}
          >
            Fechado
          </Button>
          {/* <Button
            color="default"
            className={`jr-btn ${drawerType === 'mini_drawer' && 'active'} `}
            onClick={setMiniDrawer}
          >
            Mini
          </Button> */}
        </ButtonGroup>
      )}
    </div>
  );
};

export default Customizer;
