import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import InputMask from 'components/InputMask';
import { Formik } from 'formik';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import api from 'util/api';
import moment from 'moment';
import { setDecimal, transformData } from 'util/index';
import { Creators as LancamentoActions } from 'store/ducks/Lancamento';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const HaverModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { postBaixaHaverLoading } = useSelector(({ lancamento }) => lancamento);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    if (open) {
      loadItems([867, 633, 607, 778]);
    }
  }, [open]);

  const onSubmit = (values) => {
    const data = {
      dtbaixa: moment(values?.dtbaixa, 'DD/MM/YYYY').format('YYYY-MM-DD'),         
      especie_id: values?.especie_id?.value,
      entidade_id: values?.entidade_id?.value,
      cxconta_id: values?.cxconta_id?.value,
      planoconta_id: values?.planoconta_id?.value,
      valor: transformData(values?.valor, 'number'),
    };
    dispatch(LancamentoActions.postBaixaHaver(data));
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <Formik
        initialValues={{
          dtbaixa: moment().format('DD/MM/YYYY'),
          especie_id: null,
          entidade_id: null,
          cxconta_id: null,
          planoconta_id: null,
          valor: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogTitle>Baixar Haver</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <InputMask
                      mask="99/99/9999"
                      name="dtbaixa"
                      label="Data da Baixa"
                      value={values.dtbaixa}
                      onChange={handleChange}
                   />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Espécie"
                    items={items[0]}
                    value={values.especie_id}
                    onChange={(v) => setFieldValue('especie_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Entidade"
                    items={items[1]}
                    value={values.entidade_id}
                    onChange={(v) => setFieldValue('entidade_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Conta Caixa"
                    items={items[2]}
                    value={values.cxconta_id}
                    onChange={(v) => setFieldValue('cxconta_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <SelectAuto
                    label="Plano de Conta"
                    items={items[3]}
                    value={values.planoconta_id}
                    onChange={(v) => setFieldValue('planoconta_id', v)}
                  />
                </Grid>
                <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                  <TextField
                    name="valor"
                    label="Valor"
                    value={values.valor}
                    onChange={handleChange}
                    onBlur={(e) =>
                      setFieldValue('valor', setDecimal(e.target.value, 2))
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => onSubmit(values)}
                disabled={postBaixaHaverLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
export default withStyles(styles)(HaverModal);
