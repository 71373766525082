import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as LancamentoActions } from '../../../store/ducks/Lancamento';

const styles = () => ({
  paper: {
    minWidth: '60%',
  },
});

const CalendarioModal = ({ open, setOpen, classes }) => {
  const dispatch = useDispatch();
  const [ano, setAno] = useState('');
  const { postCalendarioLoading } = useSelector(({ lancamento }) => lancamento);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Gerar Calendário</DialogTitle>
      <DialogContent>
        <TextField
          label="Ano"
          value={ano}
          onChange={(e) => setAno(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => dispatch(LancamentoActions.postCalendario({ ano }))}
          disabled={postCalendarioLoading}
        >
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(CalendarioModal);
