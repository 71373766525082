import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField } from '@material-ui/core';
import SelectAuto from 'components/SelectAuto';
import Loading from 'components/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as TablesActions } from '../../../store/ducks/Tables';

const items = [
  { value: 'Paisagem', label: 'Paisagem' },
  { value: 'Retrato', label: 'Retrato' },
];

const ExportModal = ({
  open,
  setOpen,
  boxes,
  pdf,
  titulo,
  stringExport,
  dados,
}) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(boxes);
  const [Orientacao, setOrientacao] = useState({
    value: 'Paisagem',
    label: 'Paisagem',
  });
  const [TituloRelatorio, setTituloRelatorio] = useState(titulo);
  const {
    exportPdfSuccess,
    exportXlsSuccess,
    exportData,
    exportLoading,
  } = useSelector(({ tables }) => tables);

  useEffect(() => {
    if (pdf && exportPdfSuccess && exportData !== null) {
      dispatch(TablesActions.exportPdfReset());
      setOpen(!open);
      const file = new Blob([exportData], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    if (!pdf && exportXlsSuccess && exportData !== null) {
      dispatch(TablesActions.exportXlsReset());
      setOpen(!open);
      const file = new Blob([exportData], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }, [exportPdfSuccess, exportXlsSuccess]);

  function onChange(id) {
    let newData = columns.map((e) => {
      if (e.id === id) {
        return { ...e, check: !e.check };
      } else {
        return e;
      }
    });
    setColumns(newData);
  }

  const onSubmit = () => {
    const SelecaoCampos = columns
      .filter((i) => i.check)
      .map((c) => {
        return {
          campo: c.campo,
          titulo: c.label,
          tipo: c.tipo,
          formato: c.formato,
        };
      });
    if (pdf) {
      dispatch(
        TablesActions.exportPdf({
          TituloRelatorio,
          Orientacao: Orientacao?.value || null,
          Filtro: stringExport,
          SelecaoCampos,
          dados,
        })
      );
    } else {
      dispatch(
        TablesActions.exportXls({
          SelecaoCampos,
          dados,
        })
      );
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Exportar {pdf ? 'PDF' : 'XLS'}</DialogTitle>
      {exportLoading ? (
        <Loading />
      ) : (
        <DialogContent>
          {pdf && (
            <>
              <TextField
                name="titulo"
                label="Título"
                value={TituloRelatorio}
                onChange={(e) => setTituloRelatorio(e.target.value(e))}
                className="mb-4"
                fullWidth
              />
              <SelectAuto
                label="Orientação"
                items={items}
                value={Orientacao}
                onChange={(v) => setOrientacao(v)}
                className="mb-4"
              />
            </>
          )}
          {columns?.map((b) => (
            <FormControlLabel
              className="d-flex"
              control={
                <Checkbox
                  color="primary"
                  checked={b.check}
                  onChange={() => onChange(b.id)}
                />
              }
              label={b.label}
            />
          ))}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onSubmit()}
          color="primary"
          disabled={exportLoading}
        >
          Confirmar
        </Button>
        <Button
          onClick={() => setOpen(!open)}
          color="secondary"
          disabled={exportLoading}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ExportModal;
