import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as FiscalActions } from '../../../store/ducks/Fiscal';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import api from 'util/api';
import { findOnArray, setDecimal, transformData } from 'util/index';
import moment from 'moment';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const apuracaoDrop = [
  { value: 0, label: 'Mensal' },
  { value: 1, label: 'Decendial' },
];

const perfilDrop = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
];

const atividadeDrop = [
  { value: 0, label: 'Industrial ou equiparado à industrial' },
  { value: 1, label: 'Outros' },
];

const ConfigFilialIpiModal = ({ open, setOpen, rotina, filtros, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const { apuracao, postApurLoading, postApurSuccess } = useSelector(
    ({ fiscal }) => fiscal
  );

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    if (postApurSuccess) {
      setOpen(!open);
      dispatch(FiscalActions.getApuracaoError());
      dispatch(FiscalActions.postApuracaoReset());
      dispatch(TablesActions.getHeader(rotina));
    }
  }, [postApurSuccess]);

  useEffect(() => {
    loadItems([633]);
    if (open) {
      let dtapuracao = filtros.find(
        (f) => f.titulo.toUpperCase() === 'PERIODO FIM'
      )?.conteudoi;
      dispatch(
        FiscalActions.getApuracao({
          filial_id: filtros.find(
            (f) => f.titulo.toUpperCase() === 'FILIAL INICIO'
          )?.conteudoi,
          tributo_id: 1,
          descricao: 'EFD-ICMSIPI',
          dtapuracao: moment(dtapuracao, 'DD-MM-YYYY').isValid()
            ? moment(dtapuracao, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
        })
      );
    }
  }, [open]);

  const SetFields = () => {
    const { setValues, values } = useFormikContext();

    const getValue = (value) => {
      return apuracao.find((a) => a.identificacao === value)?.conteudo;
    };

    useEffect(() => {
      if (!open) {
        setValues({
          versao: null,
          perfil: null,
          atividade: null,
          contabilista: null,
          apuracao: null,
        });
      }
      if (!!apuracao.length && open) {
        setValues({
          ...values,
          versao: getValue('EFD_VERSAO')
            ? setDecimal(getValue('EFD_VERSAO'), 2)
            : null,
          perfil: getValue('EFD_IND_PERFIL')
            ? findOnArray(getValue('EFD_IND_PERFIL'), perfilDrop)
            : null,
          atividade: getValue('EFD_IND_ATIV')
            ? findOnArray(Number(getValue('EFD_IND_ATIV')), atividadeDrop)
            : null,
          contabilista: getValue('EFD_CONTABILISTA')
            ? findOnArray(Number(getValue('EFD_CONTABILISTA')), items[0])
            : null,
          apuracao: getValue('EFD_IND_APUR')
            ? findOnArray(Number(getValue('EFD_IND_APUR')), apuracaoDrop)
            : null,
        });
      } else {
        setValues({
          ...values,
          versao: null,
          perfil: null,
          atividade: null,
          contabilista: null,
          apuracao: null,
        });
      }
    }, [apuracao, open]);
    return null;
  };

  const getIdentificacao = (key) => {
    if (key === 'versao') {
      return 'EFD_VERSAO';
    }
    if (key === 'perfil') {
      return 'EFD_IND_PERFIL';
    }
    if (key === 'atividade') {
      return 'EFD_IND_ATIV';
    }
    if (key === 'apuracao') {
      return 'EFD_IND_APUR';
    }
    if (key === 'contabilista') {
      return 'EFD_CONTABILISTA';
    }
    return '';
  };

  const onSendPost = (values) => {
    let dtapuracao = filtros.find(
      (f) => f.titulo.toUpperCase() === 'PERIODO FIM'
    )?.conteudoi;
    if (dtapuracao === '#DTFIMMESANT') {
      dtapuracao = moment().format('YYYY-MM-') + moment().daysInMonth();
    } else if (moment(dtapuracao, 'DD-MM-YYYY').isValid()) {
      dtapuracao = moment(dtapuracao, 'DD-MM-YYYY').format('YYYY-MM-DD');
    } else {
      dtapuracao = null;
    }
    let apuracao = [];
    Object.entries(values).forEach(([key, value]) => {
      if (value || value?.value) {
        if (typeof value === 'object') {
          apuracao.push({
            identificacao: getIdentificacao(key),
            conteudo: value?.value,
          });
        } else {
          apuracao.push({
            identificacao: getIdentificacao(key),
            conteudo: transformData(value, 'number'),
          });
        }
      }
    });
    const data = {
      filial_id: filtros.find((f) => f.titulo.toUpperCase() === 'FILIAL INICIO')
        ?.conteudoi,
      tributo_id: 1,
      dtapuracao,
      descricao: 'EFD-ICMSIPI',
      apuracao,
    };
    dispatch(FiscalActions.postApuracao(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
      onExited={() => dispatch(FiscalActions.getApuracaoError())}
    >
      <Formik
        initialValues={{
          versao: null,
          perfil: null,
          atividade: null,
          contabilista: null,
          apuracao: null,
        }}
      >
        {({ values, handleChange, setFieldValue, setValues }) => (
          <>
            <SetFields />
            <DialogTitle>Configurar Filial</DialogTitle>
            {localLoading ? (
              <Loading />
            ) : (
              <DialogContent>
                <Grid container>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <TextField
                      name="versao"
                      label="Versão"
                      value={values?.versao || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Perfil"
                      items={perfilDrop}
                      value={values.perfil}
                      onChange={(v) => setFieldValue('perfil', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="Indicador de Atividade"
                      items={atividadeDrop}
                      value={values.atividade}
                      onChange={(v) => setFieldValue('atividade', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Contabilista"
                      items={items[0] || []}
                      value={values.contabilista}
                      onChange={(v) => setFieldValue('contabilista', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
                    <SelectAuto
                      label="Apuração do IPI"
                      items={apuracaoDrop}
                      value={values.apuracao}
                      onChange={(v) => setFieldValue('apuracao', v)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={() => onSendPost(values)}
                color="primary"
                disabled={postApurLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(ConfigFilialIpiModal);
