import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { findOnArray, setDecimal, transformData } from 'util/index';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as TablesActions } from 'store/ducks/Tables';
import SelectAuto from 'components/SelectAuto';
import InputMask from 'components/InputMask';
import api from 'util/api';
import moment from 'moment';

const styles = () => ({
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  paper: {
    minWidth: '90%',
  },
});

const FormaEnvioModal = ({ open, setOpen, selected, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [values, setValues] = useState({
    forma_envio_id: null,
    freteenv: null,
    fretecob: null,
    freterec: null,
    whatsapp: null,
  });
  const { editLoading, editSuccess } = useSelector(({ tables }) => tables);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
  }

  useEffect(() => {
    loadItems([731]);
  }, []);

  useEffect(() => {
    if (editSuccess) {
      if (Boolean(values?.whatsapp)) {
        const phone =
          '+55' + values?.whatsapp?.replace(/([()-])/g, '')?.replace(' ', '');
        let message = `Prezado cliente, segue detalhes do envio referente sua Nota fiscal`;
        if (selected?.numero) message += ` ${selected?.numero}`;
        if (selected?.emitente)
          message += `, emitida por ${selected?.emitente}`;
        if (moment(selected?.dtemissao).isValid())
          message += ` em ${moment(selected?.dtemissao).format('DD/MM/YYYY')}`;
        if (Boolean(values?.freteenv)) {
          message += `, ${values?.freteenv}`;
        }
        const url = `https://api.whatsapp.com/send?phone=${phone}&text=${message}&app_absent=0`;
        window.open(url);
      }
      dispatch(TablesActions.editDataReset());
      setOpen(false);
    }
  }, [editSuccess]);

  const onSubmit = () => {
    dispatch(
      TablesActions.editData({
        table: 'documento_saida',
        campos: {
          forma_envio_id: values?.forma_envio_id?.value,
          freteenv: values?.freteenv,
          fretecob: transformData(values?.fretecob, 'number') || null,
          freterec: transformData(values?.freterec, 'number') || null,
          whatsapp: values?.whatsapp,
        },
        id: selected?.documento_id,
      })
    );
  };

  useEffect(() => {
    if (selected) {
      setValues({
        forma_envio_id: findOnArray(selected?.forma_envio_id, items[0]),
        freteenv: selected?.freteenv,
        fretecob: setDecimal(selected?.fretecob, 2),
        freterec: setDecimal(selected?.freterec, 2),
        whatsapp: selected?.whatsapp,
      });
    }
  }, [selected]);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} fullWidth>
      <DialogTitle>Forma de Envio</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <SelectAuto
              label="Forma de Envio"
              items={items[0] || []}
              value={values.forma_envio_id}
              onChange={(v) => setValues({ ...values, forma_envio_id: v })}
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <TextField
              label="Detalhes do Envio"
              value={values.freteenv || ''}
              onChange={(e) =>
                setValues({ ...values, freteenv: e?.target?.value })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Valor Frete Cobrado"
              value={values.fretecob || ''}
              onChange={(e) =>
                setValues({ ...values, fretecob: e?.target?.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  fretecob: setDecimal(e.target.value, 2),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="6" md="6" lg="6" className={classes.grid}>
            <TextField
              label="Valor Frete Recebido"
              value={values.freterec || ''}
              onChange={(e) =>
                setValues({ ...values, freterec: e?.target?.value })
              }
              onBlur={(e) =>
                setValues({
                  ...values,
                  freterec: setDecimal(e.target.value, 2),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
            <InputMask
              label="WhatsApp"
              mask="(99) 99999-9999"
              value={values.whatsapp || ''}
              onChange={(e) =>
                setValues({ ...values, whatsapp: e?.target?.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit} disabled={editLoading}>
          Confirmar
        </Button>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(FormaEnvioModal);
