import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Loading from 'components/Loading';
import SelectAuto from 'components/SelectAuto';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as FiscalActions } from '../../../store/ducks/Fiscal';
import { Creators as TablesActions } from '../../../store/ducks/Tables';
import api from 'util/api';
import { transformData } from 'util/index';

const styles = () => ({
  paper: {
    minWidth: '80%',
  },
  grid: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
});

const GerarRegrasModal = ({ open, setOpen, rotina, classes }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const { postTribSuccess, postTribLoading } = useSelector(
    ({ fiscal }) => fiscal
  );

  useEffect(() => {
    if (postTribSuccess) {
      dispatch(FiscalActions.postTributacaoReset());
      dispatch(TablesActions.getHeader(rotina));
      setOpen(!open);
    }
  }, [postTribSuccess]);

  async function loadItems(sqls) {
    const { data } = await api.post(`/getDrops`, { sqls });
    const drops = (data?.drops || [])?.map((d) => d?.dados);
    setItems(drops);
    setLocalLoading(false);
  }

  useEffect(() => {
    if (open) {
      loadItems([625, 644, 611, 654, 614, 880, 621, 610, 616, 966]);
    }
  }, [open]);

  const onSend = (values) => {
    const Natureza_Operacao = values.natureza.map((n) => ({
      natureza_operacao_id: n?.value,
    }));

    const Classificacao_Fiscal = values.cFiscal.map((f) => ({
      cfiscal_id: f?.value,
    }));

    const Origem_Tributaria = values.origem.map((r) => ({
      origem_tributaria_id: r?.value,
    }));

    const Contribuinte = values.contribuinte.map((c) => ({
      contribuinte_id: c?.value,
    }));

    const UsoFinal = values.usofinal.map((u) => ({
      usofinal: u?.value,
    }));

    const Tributos = values.tributos.map((t) => ({
      tributo_id: t?.value,
      tributo: t?.label,
      percentual: transformData(t?.percentual, 'number'),
      situacao_tributaria_id: t?.situacao?.value,
      dadosadc: t?.dadosadc,
    }));

    let data = {
      uforg: values?.uforg?.value,
      Natureza_Operacao,
      Classificacao_Fiscal,
      Origem_Tributaria,
      Contribuinte,
      UsoFinal,
      Tributos,
      filial_id: values?.filial_id?.value,
    };
    dispatch(FiscalActions.postTributacao(data));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      classes={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{
          uforg: null,
          natureza: [],
          contribuinte: [],
          usofinal: [],
          origem: [],
          cFiscal: [],
          tributos: [],
          filial_id: null,
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <DialogTitle>Gerar Regras</DialogTitle>
            {localLoading ? (
              <Loading />
            ) : (
              <DialogContent>
                <Grid container>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                      label="UF Origem"
                      items={items[0]}
                      value={values.uforg}
                      onChange={(v) => setFieldValue('uforg', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                    <SelectAuto
                      multiple
                      label="Natureza da Operação"
                      items={items[1]}
                      value={values.natureza}
                      onChange={(v) => setFieldValue('natureza', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                    <SelectAuto
                      multiple
                      label="Classificação Fiscal"
                      items={items[3]}
                      value={values.cFiscal}
                      onChange={(v) => setFieldValue('cFiscal', v)}
                    />
                  </Grid> 
                  <Grid xs="12" sm="12" md="12" lg="12" className={classes.grid}>
                    <SelectAuto
                    multiple
                      label="Origem Tributária"
                      items={items[2]}
                      value={values.origem}
                      onChange={(v) => setFieldValue('origem', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="8" md="8" lg="8" className={classes.grid}>
                    <SelectAuto
                    multiple
                      label="Contribuinte"
                      items={items[8]}
                      value={values.contribuinte}
                      onChange={(v) => setFieldValue('contribuinte', v)}
                    />
                  </Grid>
                  <Grid xs="12" sm="4" md="4" lg="4" className={classes.grid}>
                    <SelectAuto
                    multiple
                      label="Uso final"
                      items={items[9]}
                      value={values.usofinal}
                      onChange={(v) => setFieldValue('usofinal', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      label="Filial"
                      items={items[6]}
                      value={values.filial_id}
                      onChange={(v) => setFieldValue('filial_id', v)}
                    />
                  </Grid>
                  <Grid
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className={classes.grid}
                  >
                    <SelectAuto
                      multiple
                      label="Tributos"
                      items={items[4]}
                      value={values.tributos}
                      onChange={(v) => setFieldValue('tributos', v)}
                    />
                  </Grid>
                  {values.tributos.map((t, index) => (
                    <>
                      <Grid xs="12" sm="12" md="12" lg="12">
                        <h4 className="my-2">{t?.label}</h4>
                      </Grid>
                      <Grid
                        xs="12"
                        sm="2"
                        md="2"
                        lg="2"
                        className={classes.grid}
                      >
                        <TextField
                          label="Percentual"
                          onChange={(v) => {
                            let newTributos = values.tributos;
                            newTributos[index] = {
                              ...newTributos[index],
                              percentual: v?.target?.value,
                            };
                            setFieldValue('tributos', newTributos);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Situação Tributária"
                          items={items[5]}
                          value={values.tributos[index]?.situacao}
                          onChange={(v) => {
                            let newTributos = values.tributos;
                            newTributos[index] = {
                              ...newTributos[index],
                              situacao: v,
                            };
                            setFieldValue('tributos', newTributos);
                          }}
                        />
                      </Grid>
                      <Grid
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        className={classes.grid}
                      >
                        <SelectAuto
                          label="Dados Adicionais"
                          items={items[7]}
                          value={values.tributos}
                          onChange={(v) => {
                            let newTributos = values.tributos;
                            newTributos[index] = {
                              ...newTributos[index],
                              dadosadc: v?.label,
                            };
                            setFieldValue('tributos', newTributos);
                          }}
                        />
                      </Grid>
                    </>
                  ))}
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              {!!postTribLoading && <CircularProgress size={25} />}
              <Button
                onClick={() => onSend(values)}
                color="primary"
                disabled={postTribLoading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setOpen(!open)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(styles)(GerarRegrasModal);
