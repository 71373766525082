import axios from 'axios';
import { parseSuccess, parseError } from 'util/index';

const apiUrl = ( process.env.REACT_APP_AMBIENTE === 'azure' ) ? 
               { baseURL: 'https://epromti-node-autorizacao.azurewebsites.net/api' } :
               ( process.env.REACT_APP_AMBIENTE === 'homologacao' ) ?
               { baseURL: 'https://hom-esolution.eprom2.com.br/ws-auth/api' } :
               ( process.env.REACT_APP_AMBIENTE === 'local' ) ?
               { baseURL: 'https://localhost:8443/api' } :
               { baseURL: 'https://epromti-node-autorizacao.azurewebsites.net/api' }

const apiLogin = axios.create( apiUrl );

apiLogin.interceptors.response.use(
  (response) => {
    if (response?.status !== 200) {
      parseSuccess(response);
    }
    return response;
  },
  (error) => {
    parseError(error);
    return Promise.reject(error);
  }
);

export default apiLogin;
